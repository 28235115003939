<template>
  <BaseSvg viewBox="0 0 20 20" width="20" height="20" fill="none" class="sprout-icon">
    <path
      id="Vector"
      d="m 9.41468,10.191 c 0,0 -1.11409,0.9893 -1.7366,3.4459 C 7.0247,16.2154 7.56258,17.5 7.56258,17.5"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      id="Vector_2"
      d="M 18.8221,7.38102 C 19.2578,8.36494 17.7155,10.2867 15.2118,11.1078 12.8082,11.8989 9.98129,10.7077 9.32634,10.2239 10.0517,8.09189 11.8839,6.56009 14.012,6.19882 c 1.9931,-0.34035 4.3659,0.15898 4.8101,1.1822 z"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      id="Vector_3"
      d="M 2.36369,3.14544 C 1.51324,3.79839 1.95233,6.23002 3.75886,8.1603 5.49173,10.016 8.53928,10.3365 9.34426,10.2283 9.72203,8.0069 8.84295,5.77737 7.14736,4.43212 5.56019,3.17042 3.24035,2.46214 2.36369,3.14544 Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </BaseSvg>
</template>

<script>
import BaseSvg from './BaseSvg.vue';

export default {
  name: 'SproutIcon',
  components: {
    BaseSvg,
  },
};
</script>

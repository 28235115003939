<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { Button } from '@clovyr/bed';
import ImageMedia from '@clovyr/bed/components/media/ImageMedia.vue';
import type { ComputeProviderRegion } from '@clovyr/pollen';

import EditIcon from '@/components/icons/EditIcon.vue';
import { useLauncherStore } from '@/stores/launcher_store';

import { useUserFlagsStore } from '../../stores/user_flags';
import ExpandedDropdown from '../blocks/ExpandedDropdown.vue';

const { isInternal } = storeToRefs(useUserFlagsStore());

const launcherStore = useLauncherStore();
const { selectedHost, deployment } = storeToRefs(launcherStore);

// Computed property for regionOptions
const regionOptions = computed<ComputeProviderRegion[]>(() => {
  if (selectedHost.value === undefined) {
    throw new Error('selectedHost is undefined');
  }
  return selectedHost.value.regions;
});

// FIXME: defining temporarily until backend is finished

type ComputeProviderSize = {
  size: string;
  memory: string;
  storage: string;
};

const sizes = [
  {
    size: 'Small',
    memory: '2GB',
    storage: '25GB',
  },
  {
    size: 'Medium',
    memory: '2GB',
    storage: '50GB',
  },
  {
    size: 'Large',
    memory: '4GB',
    storage: '100GB',
  },
  {
    size: 'X-large',
    memory: '150GB',
    storage: '25GB',
  },
];

// Computed property for sizeOptions
const sizeOptions = computed<ComputeProviderSize[]>(() => {
  // if (selectedHost.value === undefined) {
  //   throw new Error('selectedHost is undefined');
  // }
  // return selectedHost.value.size;

  return sizes;
});

// Computed property for selectedRegion
const selectedRegion = computed(() => {
  return regionOptions.value.find((x) => x.value === deployment.value.region)!;
});
// Computed property for selectedSize
const selectedSize = computed(() => {
  // return sizeOptions.value.find((x) => x.size === deployment.value.size)!;
  return sizeOptions.value[0];
});

// holds the selected region while editing until saved
const tempRegion = ref<ComputeProviderRegion>({ ...selectedRegion.value });
// holds the selected size while editing until saved
const tempSize = ref<ComputeProviderSize>({ ...selectedSize.value });

async function onChangeRegion(option) {
  tempRegion.value = option;
}
async function onChangeSize(option) {
  tempSize.value = option;
}

const isEditingRegion = ref(false);
const isEditingSize = ref(false);

const onClickEdit = (option) => {
  if (option === 'region') {
    tempRegion.value = { ...selectedRegion.value };
    isEditingRegion.value = true;
  }
  if (option === 'size') {
    tempSize.value = { ...selectedSize.value };
    isEditingSize.value = true;
  }
};

const onClickButton = (button) => {
  if (button === 'cancel') {
    if (isEditingRegion.value) {
      tempRegion.value = { ...selectedRegion.value };
      isEditingRegion.value = false;
    }
    if (isEditingSize.value) {
      tempSize.value = { ...selectedSize.value };
      isEditingSize.value = false;
    }
  }
  if (button === 'save') {
    deployment.value.region = tempRegion.value.value;
    deployment.value.size = tempSize.value.size;
    isEditingRegion.value = false;
    isEditingSize.value = false;
  }
};
const selectedRegionFlag = computed(() => {
  if (!selectedRegion.value || !selectedRegion.value.flag) {
    return '';
  }
  return `${selectedRegion.value.flag}-flag.svg`;
});
</script>

<template>
  <div class="regions">
    <div v-if="!isEditingRegion" class="region-display">
      <div class="selectinput-block__label-holder">
        <div class="selectinput-block__label">Server Location:</div>
        <div class="regions-spread">
          <div class="regions-details">
            <div class="regions-flag"><ImageMedia :filename="selectedRegionFlag" /></div>
            <div class="regions-name">{{ selectedRegion.name }}</div>
          </div>
          <div class="edit-icon" @click="onClickEdit('region')"><EditIcon /></div>
        </div>
      </div>
    </div>
    <!-- dropdown when editing -->
    <ExpandedDropdown
      v-if="isEditingRegion"
      :model-value="tempRegion"
      :options="regionOptions"
      @selected="onChangeRegion"
    >
      <template #value="{ value }: { value: ComputeProviderRegion }">
        <div class="selectinput-block">
          <div class="selectinput-block__label-holder">
            <div class="selectinput-block__label">Server Location:</div>
          </div>
          <div v-if="isEditingRegion" class="selectinput-block__input">
            <ImageMedia :filename="`${value.flag}-flag.svg`" class="regions-flag" />{{ value.name }}
          </div>
        </div>
      </template>

      <template #options="{ option }: { option: ComputeProviderRegion }">
        <div class="regions-option">
          <ImageMedia :filename="`${option.flag}-flag.svg`" class="regions-flag" />{{ option.name }}
        </div>
      </template>
    </ExpandedDropdown>
  </div>
  <div class="size" v-if="isInternal">
    <!-- read-only review: show selected size -->
    <div v-if="!isEditingSize" class="region-display">
      <div class="selectinput-block__label-holder">
        <div class="selectinput-block__label">Server Size:</div>
        <div class="regions-spread">
          <div class="regions-details">
            <div class="size-info">
              <p>{{ selectedSize.size }}</p>
              <div>{{ selectedSize.memory }} / {{ selectedSize.storage }}</div>
            </div>
          </div>
          <div class="edit-icon" @click="onClickEdit('size')"><EditIcon /></div>
        </div>
      </div>
    </div>
    <!-- dropdown when editing -->
    <ExpandedDropdown
      v-if="isEditingSize"
      :model-value="tempSize"
      :options="sizeOptions"
      @selected="onChangeSize"
    >
      <template #value="{ value }: { value: ComputeProviderSize }">
        <div class="selectinput-block">
          <div class="selectinput-block__label-holder">
            <div class="selectinput-block__label">Server Size:</div>
          </div>
          <div v-if="isEditingSize" class="selectinput-block__input">
            <div class="size-info">
              <p>{{ value.size }}</p>
              <div>{{ value.memory }} / {{ value.storage }}</div>
            </div>
          </div>
        </div>
      </template>

      <template #options="{ option }: { option: ComputeProviderSize }">
        <div class="regions-option">
          <div class="size-info">
            <p>{{ option.size }}</p>
            <div>{{ option.memory }} / {{ option.storage }}</div>
          </div>
        </div>
      </template>
    </ExpandedDropdown>
    <div v-if="isEditingRegion || isEditingSize" class="save-buttons">
      <Button label="cancel" type="submit" @click="onClickButton('cancel')" />
      <Button label="save" type="submit" reversed @click="onClickButton('save')" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.regions,
.size {
  margin-top: 1rem;
  padding: 0.5rem;

  .selectinput-block__label-holder {
    margin-bottom: 0.25rem;
    padding-left: 1.5rem;
  }
  .selectinput-block__input {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    cursor: pointer;
  }
  .region-option {
    padding: 0.1rem 0.75rem;
  }
  .region-option:hover {
    background-color: color(grey, primary);
  }
  :deep(.dropdown-container) {
    margin-top: 0.15rem;
    transform: translate(5px);
    border: unset;
    padding: unset;
    cursor: pointer;
  }
  :deep(.dropdown-items) {
    width: 23rem;
    max-height: 21.5rem;
    overflow-y: scroll;
    padding-right: unset;
    scrollbar-width: none;
  }
  .regions-spread {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;

    .edit-icon {
      cursor: pointer;
    }
  }
  .regions-details {
    display: flex;
  }
  .regions-flag {
    margin-right: 0.75rem;
  }
  .regions-option {
    display: flex;
    padding: 0.25rem 0 0.25rem 1rem;
  }
  .save-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  }
}

.regions {
  position: relative;
  z-index: 2;
}

.size {
  margin-top: 1rem;

  .size-info {
    display: flex;

    p {
      font-weight: bold;
      margin-right: 0.5rem;
    }
    div {
      color: color(grey, secondary);
    }
  }
}
</style>

<script setup lang="ts">
import type { PublisherAppImageVersion } from '@clovyr/pollen';
import { dumpYaml, parseManifest } from '@clovyr/pollen/manifest';

import FormField from '@/components/blocks/FormField.vue';
import { usePublisher } from '@/composables/usePublisher';

const props = defineProps<{
  publisherID: string;
  appID: string;
  versionID: string;
  image: PublisherAppImageVersion;
}>();

const { publisherID, appID, versionID } = toRefs(props);

const { pubAppVersion } = usePublisher(publisherID, appID, versionID);

async function onChange(e) {
  // Find the image config in the manifest, update the value, and write new yaml back to version
  if (!pubAppVersion.value?.manifest) {
    return;
  }
  const manifest = parseManifest(pubAppVersion.value.manifest);
  if (!manifest?.config?.deployment) {
    return;
  }
  Object.keys(manifest.config.deployment).forEach((key) => {
    if (key === props.image.id) {
      manifest.config!.deployment![key].value.default = e.target.value;
    }
  });
  pubAppVersion.value.manifest = dumpYaml(manifest);
}
</script>

<template>
  <div class="flex-row double-input">
    <div class="column">
      <FormField
        :name="'service-' + image.name"
        label="Service"
        disabled
        :model-value="image.name"
      />
    </div>
    <div class="column">
      <FormField
        :name="'image-' + image.name"
        label="Image"
        :model-value="image.image"
        @change="onChange"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.double-input {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }

  .column {
    width: col-width(12);
    @include media-breakpoint-up(xl) {
      width: col-width(5.5);
      margin-right: 1rem;
      flex-basis: 50%;
    }
  }
}
</style>

<script setup lang="ts">
defineProps<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modelValue: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  selected?: boolean;
  tabindex?: number;
}>();

const open = ref(false);
const emit = defineEmits(['selected']);

function onClickSelectedOption() {
  open.value = !open.value;
}

function onClickOption(option) {
  open.value = false;
  emit('selected', option);
}

// Close dropdown when clicking outside
function closeOnOutsideClick(event) {
  if (!event.target.closest('.dropdown-select')) {
    open.value = false;
  }
}

onMounted(() => {
  document.addEventListener('click', closeOnOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener('click', closeOnOutsideClick);
});
</script>

<template>
  <div class="dropdown-select" :tabindex="tabindex" @blur="open = false">
    <div :class="{ open: open }" @click="onClickSelectedOption">
      <slot name="value" :value="modelValue"></slot>
    </div>
    <div v-if="open && options.length" class="dropdown-container" :class="{ selectHide: !open }">
      <div class="dropdown-items">
        <div v-for="(option, i) of options" :key="i" @click="onClickOption(option)">
          <slot name="options" :option="option" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dropdown-select {
  position: relative;

  .selectHide {
    display: none;
  }

  .dropdown-container {
    position: absolute;
    background-color: #221a33;
    margin-top: 0.7px;
    padding: 0.35rem 0.25rem 0.35rem 0;
    z-index: 1;
    @include borders-rounded;
    transform: translateX(-4px);
  }
  .dropdown-items {
    position: relative;
    max-height: 21.5rem;
    width: 26.25rem;
    overflow-y: scroll;
    z-index: 1;
    @include borders-rounded;
    scrollbar-color: color(grey, primary) #221a33;
    scrollbar-width: thin;
    scroll-behavior: smooth;
  }

  .dropdown-items > * {
    border-radius: 0;
    border: none;
    background: color(negative, primary);
  }
  .dropdown-items > * :hover {
    background: color(grey, primary);
  }
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import debounce from 'lodash.debounce';

import { ImageMedia } from '@clovyr/bed';
import AppLibraryUi from '@clovyr/bed/assets/images/app-library-ui.webp';
import ClovyrCodeScreenshot from '@clovyr/bed/assets/images/clovyr-code-screenshot.webp';
import ClovyrIslandFaded from '@clovyr/bed/assets/images/clovyr-island-faded.webp';
import ClovyrIslandOverlayDark from '@clovyr/bed/assets/images/clovyr-island-overlay-dark.svg';
import HeroDevelop from '@clovyr/bed/assets/images/hero-develop.webp';
import HeroRun from '@clovyr/bed/assets/images/hero-run.webp';
import MediaWikiLaunch from '@clovyr/bed/assets/images/mediawiki-launch.webp';
import Button from '@clovyr/bed/components/controls/Button.vue';
import HeroBackground from '@clovyr/bed/components/elements/HeroBackground.vue';
import Hero from '@clovyr/bed/components/tiers/Hero.vue';
import { AuthState } from '@clovyr/pollen/pollen';

import PageSection from '@/components/blocks/PageSection.vue';
import { useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';

import { ShowAuthForm } from '../Settings/types';

const { eventBus } = useEventBus();
const { authState, isUserAuthenticated } = storeToRefs(usePollenStore());
const router = useRouter();

const bg = ref<InstanceType<typeof HeroBackground> | null>(null);
const bgHeight = ref<string | undefined>(undefined);

// debounce height recalculation
const recalcHeight = debounce(
  () => {
    if (bg.value) {
      const rects = (bg.value.$el as HTMLElement).getClientRects();
      if (rects.length > 0) {
        bgHeight.value = `${rects[0].height}px`;
        return;
      }
    }
    bgHeight.value = undefined;
  },
  50,
  { trailing: true },
);

const imageOneisActive = ref(true);
const imageTwoisActive = ref(false);
const imageThreeisActive = ref(false);

const toggleImageSlider = () => {
  if (imageOneisActive.value === true) {
    imageTwoisActive.value = true;
    imageOneisActive.value = false;
  } else if (imageTwoisActive.value === true) {
    imageThreeisActive.value = true;
    imageTwoisActive.value = false;
  } else if (imageThreeisActive.value === true) {
    imageOneisActive.value = true;
    imageThreeisActive.value = false;
  }
};

onMounted(() => {
  document.getElementsByTagName('body')[0].classList.add('index');
  window.addEventListener('resize', recalcHeight);
  setTimeout(recalcHeight, 100);

  setInterval(() => {
    toggleImageSlider();
  }, 2500);
});

onUnmounted(() => {
  document.getElementsByTagName('body')[0].classList.remove('index');
  window.removeEventListener('resize', recalcHeight);
});

const classNames = computed(() => {
  return [
    imageOneisActive.value === true && 'imageOneActive',
    imageTwoisActive.value === true && 'imageTwoActive',
    imageThreeisActive.value === true && 'imageThreeActive',
  ];
});

const continueToPublisherNew = () => {
  eventBus.off('modal:login:close', continueToPublisherNew);
  if (isUserAuthenticated.value && authState.value === AuthState.LoggedIn) {
    void router.push({
      path: '/publishers/new',
    });
  }
};

const onClickCreatePublisher = () => {
  if (isUserAuthenticated.value && authState.value === AuthState.LoggedIn) {
    return router.push({
      path: '/publishers/new',
    });
  }
  eventBus.emit('modal:login:show', { form: ShowAuthForm.Signup, opts: { isPubFlow: true } });
  eventBus.on('modal:login:close', continueToPublisherNew);
  return undefined;
};
</script>

<template>
  <div class="homepage">
    <Hero class="hero">
      <template v-slot:top>
        <div class="hero-images">
          <ImageMedia :filename="HeroRun" class="hero-run"></ImageMedia>
          <ImageMedia :filename="HeroDevelop" class="hero-dev"></ImageMedia>
        </div>
        <div class="sub-hero">
          <div class="grid__col-md-6">
            <h1 class="heading">Publish on Clovyr</h1>
            <div class="sub-heading">
              <h4>
                Publishers on Clovyr get tools like clean UI widgets that help users run apps, or a
                custom VSCode environment for contributing developers.
              </h4>
            </div>
          </div>

          <div class="grid__col-md-6 sub-hero__buttons">
            <Button label="create a publisher account" reversed @click="onClickCreatePublisher" />
            <Button
              :label="'learn more on clovyr.io'"
              :link="'https://www.clovyr.io'"
              :linkTarget="'__blank'"
              translucent
            />
          </div>
        </div>
      </template>
      <template v-slot:background>
        <HeroBackground
          ref="bg"
          :background-image="ClovyrIslandFaded"
          :overlay-image="ClovyrIslandOverlayDark"
        />
      </template>
    </Hero>
  </div>
  <div class="homepage page">
    <PageSection class="learn-clovyr">
      <div class="left">
        <div class="text">
          <h3>
            Clovyr UI helps users run apps with launch wizards and a personalized library. For
            contributors, provide a custom VSCode environment.
          </h3>
        </div>
        <Button label="create a publisher account" reversed @click="onClickCreatePublisher" />
        <Button
          :label="'learn more on clovyr.io'"
          :link="'https://www.clovyr.io'"
          :linkTarget="'__blank'"
          translucent
        />
      </div>
      <div class="right" @click="toggleImageSlider" :class="classNames">
        <div class="slide-1 img-gradient">
          <ImageMedia :filename="MediaWikiLaunch" />
        </div>
        <div class="slide-2 img-gradient">
          <ImageMedia :filename="AppLibraryUi" />
        </div>
        <div class="slide-3 img-gradient">
          <ImageMedia :filename="ClovyrCodeScreenshot" />
        </div>
      </div>
    </PageSection>
  </div>
</template>
<style lang="scss" scoped>
.homepage {
  :deep(.button__label) {
    letter-spacing: 0.16em;
  }

  @extend .grid;

  :deep(.hero-background) {
    + .hero__inner {
      bottom: unset;
      position: absolute;
    }
  }

  :deep(.hero__inner) {
    padding: 0 0 0 1rem;
    margin: 12rem 0 0 0;

    @include media-breakpoint-up(sm) {
      margin-top: 12rem;
    }
  }

  :deep(.hero__top) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
    margin-bottom: 0px;

    .hero-images {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .hero-run {
      transform: translate(5%, 4%);
      z-index: 2;
    }
    .hero-dev {
      transform: translate(-10%, -11%);
      z-index: 1;
    }
  }

  .sub-hero {
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3rem 1.5rem 0 1.5rem;

    @include media-breakpoint-up(lg) {
      padding-top: 5rem;
      flex-direction: row;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @include media-breakpoint-up(lg) {
        align-items: center;
      }
    }

    .sub-heading {
      color: color(grey, secondary);
      padding: 1rem 0 1.5rem 0;
    }

    .button {
      margin-top: 1rem;
      width: fit-content;
    }
  }

  :deep(.hero-background img) {
    height: 57rem;

    @include media-breakpoint-up(sm) {
      height: 60rem;
    }
    @include media-breakpoint-up(lg) {
      height: 70rem;
    }
    @include media-breakpoint-up(xl) {
      height: 77rem;
    }
    @include media-breakpoint-up(xxl) {
      height: 75rem;
    }
  }

  :deep(.background__overlay-image) {
    background-size: auto;
    background-position: bottom;
    transform: translateY(0.1rem);
    background-size: contain;
  }

  :deep(.hero--has-background) {
    @include media-breakpoint-up(md) {
      padding-top: unset;
    }
  }

  .learn-clovyr {
    @include media-breakpoint-up(md) {
      padding-top: unset;
    }

    :deep(.page-section__inner) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: unset;
      padding-top: 6rem;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
        align-items: center;
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      padding: 0 2rem;
      justify-content: flex-start;

      @include media-breakpoint-up(sm) {
        padding: 0 3rem;
        width: 72%;
      }
      @include media-breakpoint-up(lg) {
        width: 60%;
      }
      @include media-breakpoint-up(xl) {
        width: 70%;
      }
      @include media-breakpoint-up(xxl) {
        width: 50%;
      }

      .text {
        color: color(grey, secondary);

        @include media-breakpoint-up(xl) {
          width: 90%;
        }
        @include media-breakpoint-up(xxl) {
          width: 80%;
        }
      }

      .button {
        margin-top: 2rem;
        width: fit-content;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      padding: 0 2rem;
      margin-top: 5rem;

      @include media-breakpoint-up(sm) {
        margin-left: 2rem;
        margin-left: 2rem;
      }
      @include media-breakpoint-up(md) {
        margin-left: 4%;
      }
      @include media-breakpoint-up(lg) {
        margin-left: 10%;
      }
      @include media-breakpoint-up(xl) {
        margin-left: unset;
      }

      :deep(.image-media) {
        img {
          border-radius: 1rem;
        }
      }

      .img-gradient {
        position: relative;
        max-width: 600px;
      }
      .img-gradient::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        background: linear-gradient(0deg, rgba(69, 57, 96, 0.6) 0%, rgba(69, 57, 96, 0.6) 100%);
      }

      .slide-1 {
        z-index: 3;
        cursor: pointer;
        margin-left: 6rem;

        @include media-breakpoint-up(xl) {
          margin-left: 1rem;
        }
      }

      .slide-2 {
        z-index: 2;
        transform: translateY(-30%);
        cursor: pointer;
        margin-left: 6rem;

        @include media-breakpoint-up(xl) {
          margin-left: 1rem;
        }
      }

      .slide-3 {
        z-index: 1;
        transform: translateY(-70%);
        cursor: pointer;
        margin-left: 6rem;

        @include media-breakpoint-up(xl) {
          margin-left: 1rem;
        }
      }
    }

    .imageOneActive {
      .slide-1 {
        transform: translateX(-20%);
        transition: 0.5s ease-in;
      }
      .slide-3 {
        transition: 0.5s ease-out;
      }
      .slide-1.img-gradient::after {
        background: none;
      }
    }
    .imageTwoActive {
      .slide-1 {
        z-index: 2;
        transition: 0.5s ease-out;
      }
      .slide-2 {
        z-index: 3;
        transform: translate(-20%, -30%);
        transition: 0.5s ease-in;
      }
      .slide-3 {
        z-index: 1;
        transition: 0.5s ease-out;
      }
      .slide-2.img-gradient::after {
        background: none;
      }
    }
    .imageThreeActive {
      .slide-1 {
        z-index: 1;
        transition: 0.5s ease-out;
      }
      .slide-2 {
        z-index: 2;
        transition: 0.5s ease-out;
      }
      .slide-3 {
        z-index: 3;
        transform: translate(-20%, -70%);
        transition: 0.5s ease-in;
      }
      .slide-3.img-gradient::after {
        background: none;
      }
    }
  }
}
</style>

<script setup lang="ts">
import { TextInputBlock } from '@clovyr/bed';
import apiTokenInstructions1 from '@clovyr/bed/assets/images/api-token-instructions-1.png';
import apiTokenInstructions2 from '@clovyr/bed/assets/images/api-token-instructions-2.png';
import FoxAndClover from '@clovyr/bed/assets/images/fox-and-clover.png';

import InstructionSection from '@/components/blocks/InstructionSection.vue';
import SlideIcon from '@/components/icons/SlideIcon.vue';
import AppLauncherHero from '@/components/tiers/AppLauncherHero.vue';

defineProps(['metadata']);

const detailsToggled = ref(false);

const handleDetailsToggle = () => {
  detailsToggled.value = !detailsToggled.value;
};
</script>

<template>
  <div class="host-setup host-setup--specific">
    <AppLauncherHero
      :app-logo="metadata"
      :app-title="metadata.name"
      :right-image-src="FoxAndClover"
      heading-text="Connect to your account."
    />

    <div class="setup-main">
      <div class="setup-main__top">
        <div class="setup-main__left">
          <div class="specific-setup">
            <TextInputBlock
              :label="'Enter API Token'"
              :placeholder="'ie. eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtZXNzYWdl4...'"
              class="api-token-input"
            />
          </div>
        </div>

        <div class="setup-main__right">
          <div class="info-blurb">
            <div class="info-blurb__background-image" />

            <div class="info-blurb__content">
              <h2 class="info-heading">Clovyr works differently.</h2>
              <p>
                Providing your API token here creates a link between your wNext account and the
                Clovyr platform, which runs locally in your browser. Your credentials are never
                transmitted to or stored on Clovyr’s servers. Automatic backups are end-to-end
                encrypted, so the only person with access to your application data is you.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="setup-main_bottom">
        <div
          :class="{ 'instructions-toggle--toggled': detailsToggled }"
          @click="handleDetailsToggle"
          class="instructions-toggle"
        >
          <span class="instructions-toggle__label"> How to create an API token </span>

          <SlideIcon :class="detailsToggled ? 'slide-icon--up' : 'slide-icon--down'" />
        </div>

        <div v-show="detailsToggled" class="specific-setup-instructions">
          <InstructionSection
            :headingText="'Generating an API Token'"
            :imageFilenames="[apiTokenInstructions1, apiTokenInstructions2]"
          >
            <ol>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
              </li>
              <li>
                Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur.
              </li>
            </ol>
          </InstructionSection>

          <InstructionSection
            :headingText="'Right-sizing your instance'"
            :imageFilenames="[apiTokenInstructions1]"
          >
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </p>
          </InstructionSection>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash.debounce';

import CLink from '@clovyr/bed/components/elements/CLink.vue';
import { getAllQueryParams } from '@clovyr/pollen/http/qs';
import { PublisherAPI } from '@clovyr/pollen/publisher';
import { SubscriptionAPI } from '@clovyr/pollen/subscription';
import type { Publisher } from '@clovyr/pollen/types/Publisher';
import type { UserSubscription } from '@clovyr/pollen/types/UserSubscription';
import { UserAPI } from '@clovyr/pollen/user';
import { retry } from '@clovyr/pollen/util/retry';
import { slugify } from '@clovyr/pollen/util/slugify';

import CheckboxField from '../../components/blocks/CheckboxField.vue';
import FormField from '../../components/blocks/FormField.vue';
import FormWrapper from '../../components/forms/FormWrapper.vue';
import { usePayments } from '../../composables/usePayments';
import { usePublisher } from '../../composables/usePublisher';
import { usePollenStore } from '../../stores/pollen_store';

const router = useRouter();
const { pollen } = usePollenStore();
const props = defineProps({
  publisherID: {
    type: String,
    required: false,
    default: '',
  },
  paymentIncomplete: {
    type: Boolean,
    required: false,
    default: false,
  },
});

let formState: 'creating' | 'joining' | 'paying' = 'creating';

// on initial load w/ invite token
const params = getAllQueryParams();
const inviteToken = computed(() => params.get('invite'));
const invitePub = ref<Publisher>({} as Publisher);
const nameExists = ref(false);

// subsequent load if payment did not succeed
const publisherID = toRef(props, 'publisherID');
const { publisher } = usePublisher(publisherID);

const schema = {
  name: (val) => {
    if (!(val && val.length)) {
      return 'You must enter a publisher name.';
    }
    if (nameExists.value) {
      return 'This publisher name is already taken.';
    }
    return true;
  },
  contact_email: (val) => {
    if (!(val && val.length)) {
      return 'You must enter a contact email.';
    }
    return true;
  },
  contact_name: (val) => {
    if (!(val && val.length)) {
      return 'You must enter a contact name.';
    }
    return true;
  },
  accept_terms: (val) => {
    if (!(val && val.length)) {
      return 'You must accept the terms of the Publisher Services Agreement to continue.';
    }
    return true;
  },
};

onMounted(async () => {
  if (inviteToken.value) {
    formState = 'joining';
    // fetch pub
    invitePub.value = await new PublisherAPI().get({
      id: props.publisherID,
      invite: inviteToken.value,
    });
  } else {
    // check to see if we can access this publisher already
    // if so, we already created/joined but need to complete payment
    await pollen.loadPublisherData();
    if (publisher.value) {
      // completing payment
      formState = 'paying';
      invitePub.value = publisher.value;
    } else {
      formState = 'creating';
      invitePub.value = {
        name: '',
        contact_name: '',
        contact_email: '',
      } as Publisher;
    }
  }
});

const onChangePubName = debounce(
  async () => {
    if (formState !== 'creating') {
      return;
    }
    if (invitePub.value.name) {
      invitePub.value.slug = slugify(invitePub.value.name);
    }
    nameExists.value = await retry(async () => {
      return new PublisherAPI().checkNameExists(invitePub.value);
    });
  },
  500,
  { leading: false, trailing: true },
);

watch(() => invitePub.value.name, onChangePubName);

const submitLabel = computed(() => {
  return invitePub.value?.payment_captured ? 'SUBMIT' : 'SUBMIT & ENTER PAYMENT DETAILS';
});

/**
 * Create or join the publisher using the invite token
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function createOrJoinPublisher(values: Record<string, any>) {
  await new UserAPI().acceptPublisherTerms({
    publisherID: publisherID.value,
    invite: inviteToken.value,
  });
  const slug = slugify(values.name);
  // eslint-disable-next-line @typescript-eslint/no-shadow
  let publisher: Publisher;
  // create or update publisher
  if (!publisherID.value) {
    publisher = await new PublisherAPI().create({
      name: values.name,
      contact_name: values.contact_name,
      contact_email: values.contact_email,
      slug,
    });
  } else {
    publisher = await new PublisherAPI().update({
      id: publisherID.value,
      contact_name: values.contact_name,
      contact_email: values.contact_email,
    });
  }

  // refresh pub data after joining publisher
  await pollen.loadPublisherData();
  return publisher;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onSubmit = async (values: Record<string, any>) => {
  let pubID: string;
  let pubSlug: string;
  if (formState === 'creating' || formState === 'joining') {
    const pub = await createOrJoinPublisher(values);
    pubID = pub.id;
    pubSlug = pub.slug;
  } else if (publisher.value) {
    pubID = props.publisherID;
    pubSlug = publisher.value.slug;
  } else {
    throw new Error('publisher not found');
  }

  if (publisher.value?.payment_captured === true) {
    // already captured for this pub, nothing left to do
    return router.push({ name: 'Publisher', params: { publisherID: pubSlug } });
  }

  // create a new subscription + item, for this user account
  const payments = usePayments();

  let sub: UserSubscription | undefined;
  try {
    sub = await payments.getSubscription(true);
  } catch (e) {
    throw new Error(`failed to create subscription: ${e}`);
  }
  if (!sub) {
    throw new Error('failed to get subscription');
  }

  // might already exist, check
  const subItems = await new SubscriptionAPI().listSubscriptionItems(sub.id);
  let subItem = subItems.find((item) => item.resource_id === `publisher_${pubID}`);
  if (!subItem) {
    subItem = await new SubscriptionAPI().addItem({
      subscriptionID: sub.id,
      resourceID: `publisher_${pubID}`,
      appSlug: 'publisher',
      isTrial: false,
    });
  }

  // capture payment if needed
  if (!sub.payment_captured_at) {
    // redirect to payment
    return payments.capturePayment('stripe', subItem, {
      successURL: `/publishers/${pubID}/complete`,
      // TODO: figure out what to do here. show message, retry, etc?
      errorURL: `/publishers/${pubID}/accept`,
      cancelURL: `/publishers/${pubID}/accept`,
    });
  }

  // send to pub page
  return router.push({ name: 'Publisher', params: { publisherID: pubSlug } });
};
</script>

<template>
  <div class="container">
    <div class="row">
      <!-- <div class="progress-bar grid__col-md-11">
          <span class="progress-bar-fill"></span>
        </div> -->
      <div class="grid__col-md-11 column-content form">
        <div v-if="paymentIncomplete" class="heading">
          <h1>Finish Registering for a Publisher Account</h1>
          <h3>Welcome to Clovyr Publishing. Enter payment details to get started.</h3>
        </div>
        <div v-else class="heading">
          <h1>Create a Publisher Account</h1>
          <h3>Welcome to Clovyr Publishing. Enter some basic details to get started.</h3>
        </div>
        <FormWrapper
          :submit-label="submitLabel"
          :show-second-button="paymentIncomplete === false"
          :validation-schema="schema"
          @submit="onSubmit"
          class="grid__col-md-12"
        >
          <template v-slot:fields>
            <div class="input-container">
              <div class="inputs name">
                <FormField
                  name="name"
                  label="Publisher Name:"
                  v-model="invitePub.name"
                  :disabled="!!publisherID && !!inviteToken"
                />
              </div>
              <div class="inputs contact">
                <FormField
                  name="contact_name"
                  label="Contact Name:"
                  v-model="invitePub.contact_name"
                />
              </div>
              <div class="inputs email">
                <FormField
                  name="contact_email"
                  label="Contact Email:"
                  v-model="invitePub.contact_email"
                />
              </div>
            </div>

            <div class="check">
              <CheckboxField name="accept_terms">
                I have read and accept the
                <c-link
                  label="Publisher Services Agreement"
                  target="_blank"
                  to="/publishers/terms"
                />
              </CheckboxField>
            </div>
          </template>
        </FormWrapper>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.textinput-block__label) {
  padding-left: 1.5rem !important;
}
:deep(.error-message) {
  font-size: 14px;
  color: #ff957d;
  padding-left: space(1);
}

.container {
  padding: 7rem 2rem 0 2rem;

  @include media-breakpoint-up(md) {
    padding: 3rem;
  }
  .row {
    align-items: center;
    display: flex;
    overflow-y: scroll;
    justify-content: center;

    .column-content {
      display: flex;
      flex-direction: column;
      z-index: 1;
      background: linear-gradient(180deg, rgba(48, 39, 68, 0.5) 0%, #48415f 100%);
      border-radius: 16px;
      padding: 3rem 2rem;
      width: 100%;

      @include media-breakpoint-up(md) {
        align-items: flex-start;
      }

      .heading {
        display: flex;
        flex-direction: column;

        h1 {
          margin-bottom: 1.5rem;
        }

        h3 {
          color: color(grey, primary);
          margin-bottom: 4rem;
        }
      }

      .input-container {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          justify-content: space-around;

          > *:not(:last-child) {
            margin-right: 1.5rem;
          }
        }
      }

      .inputs {
        margin-bottom: 1rem;
        width: 100%;
      }
      .terms {
        margin-top: space(2);
        width: 100%;
        padding: 15px;
      }

      a {
        text-decoration: underline;
        color: color(action, primary);
      }

      .terms-notice {
        margin-top: space(5);
        color: color(gray, secondary);
        width: 80%;
        margin-left: space(2);
      }

      .check {
        margin-top: 3rem;
        margin-bottom: 1rem;
      }
    }
  }

  .row::-webkit-scrollbar {
    width: 5px;
  }

  .row::-webkit-scrollbar-track {
    background-color: #221a33 !important;
  }
}

// .progress-bar {
//   border-radius: 8px 8px 0 0;
//   background-color: transparentize(color(grey, tertiary), 0.8);
//   padding: none;

//   .progress-bar-fill {
//     display: block;
//     height: 8px;
//     background-color: #33e672;
//     transition: width 500ms ease-in-out;
//     border-radius: 8px 8px 0 0;

//     &__0 {
//       width: 0%;
//     }

//     &__25 {
//       width: 25%;
//     }

//     &__50 {
//       width: 50%;
//     }

//     &__75 {
//       width: 75%;
//     }

//     &__33 {
//       width: 33.33%;
//     }

//     &__66 {
//       width: 66.66%;
//     }
//   }
// }
</style>

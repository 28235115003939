import type { FeatureFlag } from './stores/user_flags';
import { createApp } from './main';

const flagsEncoded = window.localStorage.getItem('clovyr_flags');
let flags: FeatureFlag[] = [];
if (flagsEncoded) {
  flags = JSON.parse(flagsEncoded);
}

void createApp(flags).then(({ app, router }) => {
  void router.isReady().then(() => {
    app.mount('#app');
  });
});

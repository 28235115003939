<script setup lang="ts">
import { storeToRefs } from 'pinia';

import FoxAndClover from '@clovyr/bed/assets/images/fox-and-clover.png';
import LinodeToken1 from '@clovyr/bed/assets/images/Linode-token-1.webp';
import LinodeToken2 from '@clovyr/bed/assets/images/Linode-token-2.webp';
import LinodeToken3 from '@clovyr/bed/assets/images/Linode-token-3.webp';
import LinodeToken4 from '@clovyr/bed/assets/images/Linode-token-4.webp';
import LinodeToken5 from '@clovyr/bed/assets/images/Linode-token-5.webp';
import { GridItem, GridSection } from '@clovyr/bed/components/blocks';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import type { OAuthSupport } from '@clovyr/pollen';
import { getComputeProvider } from '@clovyr/pollen/compute/util';
import { ComputeProviderName } from '@clovyr/pollen/types';

import FormField from '@/components/blocks/FormField.vue';
import InstructionSection from '@/components/blocks/InstructionSection.vue';
import SpinnerCover from '@/components/elements/SpinnerCover.vue';
import HowToIcon from '@/components/icons/HowToIcon.vue';
import PrivacyLockIcon from '@/components/icons/PrivacyLockIcon.vue';
import AppLauncherHero from '@/components/tiers/AppLauncherHero.vue';
import { useLauncherStore } from '@/stores/launcher_store';

defineProps(['metadata']);

const detailsToggled = ref(false);
const signInOption = ref(true);

const launcherStore = useLauncherStore();
const { selectedComputeProviderConfig, selectedHost } = storeToRefs(launcherStore);
const { doOauth, setComputeProviderConfig } = launcherStore;

const tempAuthToken = ref<string>('');

if (selectedComputeProviderConfig.value?.providerID === ComputeProviderName.Linode) {
  tempAuthToken.value = selectedComputeProviderConfig.value.credentials.accessToken;
}

const handleDetailsToggle = () => {
  detailsToggled.value = !detailsToggled.value;
};

async function saveToken() {
  if (selectedHost.value) {
    // set temp token into provider config
    const provider = getComputeProvider(selectedHost.value) as OAuthSupport;
    provider.setOAuthToken(tempAuthToken.value || '');
    const providerConfig = provider.getConfig();
    if (providerConfig === undefined) {
      throw new Error('Linode provider returned an undefined config');
    }
    await setComputeProviderConfig(providerConfig);
  }
}

async function onFormSubmit() {
  await saveToken();
}

// need better error handling to dismiss spinner in case of error
const showAwaitingSpinner = ref(false);

async function onDoAuth() {
  const { token } = await doOauth();

  if (token) {
    tempAuthToken.value = token;
    await saveToken();
    launcherStore.next();
  }
}

watch(tempAuthToken, async () => {
  await saveToken();
});
</script>

<template>
  <div class="host-setup host-setup--specific">
    <AppLauncherHero
      :app-logo="metadata"
      :app-title="metadata.name"
      :right-image-src="FoxAndClover"
      heading-text="Connect to your account."
    />

    <GridSection :spacing="3">
      <GridItem :md="6" highlighted>
        <div class="grid-item-header">
          <AppLogo class="app-logo-block" :logo="selectedHost!" pad>
            <template #title>
              <div class="text--h4">{{ selectedHost!.title }}</div>
            </template>
          </AppLogo>
        </div>

        <div v-if="signInOption" class="form-item">
          <Button class="signin-btn" label="Sign In" :reversed="true" @click="onDoAuth" />

          <button class="button--link text--color-action" @click="signInOption = false">
            Use Personal Access Token instead
          </button>
        </div>

        <div v-else class="form-item">
          <form @submit.prevent="onFormSubmit">
            <FormField
              v-model="tempAuthToken"
              name="apiToken"
              label="Enter API Token"
              shape="square"
              size="large"
              placeholder="e.g., eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtZXNzYWdl4..."
              class="api-token-input"
            />
          </form>

          <button
            class="button--link button--signin text--color-action"
            @click="signInOption = true"
          >
            Sign in instead
          </button>
        </div>
      </GridItem>

      <GridItem :md="6" highlighted>
        <div class="privacy-statement-block">
          <div class="block-icon">
            <PrivacyLockIcon />
          </div>
          <h3 class="title">Clovyr works differently.</h3>
          <p>
            Providing your API token here creates a link between your Linode account and the Clovyr
            platform, which runs locally in your browser. Your credentials are never transmitted to
            or stored on Clovyr's servers. Automatic backups are end-to-end encrypted, so the only
            person with access to your application data is you.
          </p>
        </div>
      </GridItem>
    </GridSection>

    <div class="instructions-section" v-if="signInOption === false">
      <button
        :class="{ 'instructions-toggle--toggled': detailsToggled }"
        class="instructions-toggle"
        @click="handleDetailsToggle"
      >
        <HowToIcon />

        How to create an API token
      </button>

      <div v-show="detailsToggled" class="section-highlighted background-gradient--2">
        <div class="specific-setup-instructions">
          <InstructionSection
            :headingText="'Generating an API Token'"
            :imageFilenames="[LinodeToken1]"
          >
            <ol class="linode-list">
              <li>Sign into your Linode account.</li>
              <li>Select "API Tokens" from dropdown menu in top right-hand corner.</li>
            </ol>
          </InstructionSection>

          <InstructionSection :imageFilenames="[LinodeToken2]">
            <ol class="linode-list">
              <li>Select "Create Personal Access Token" button.</li>
            </ol>
          </InstructionSection>

          <InstructionSection :imageFilenames="[LinodeToken3]">
            <ol class="linode-list">
              <li>Give the token a name&nbsp;&nbsp;-&nbsp;&nbsp;"Clovyr" works well &#128522;</li>
              <li>Set token expiration to "Never."</li>
              <li>Toggle "Select All" to "None."</li>
              <li>
                Select (at minimum) the following permissions (see image to right):
                <ul class="permissions">
                  <li>
                    1.)&nbsp;&nbsp;Accounts, Databases, Domains, Events, Kubernetes, IPs, Longview,
                  </li>
                  <li>
                    NodeBalancers, Object Storage, StackScripts:&nbsp;&nbsp;<b class="green"
                      >None</b
                    >
                  </li>
                  <li>
                    2.)&nbsp;&nbsp;Firewalls, Linodes, Volumes:&nbsp;&nbsp;<b class="green"
                      >Read/Write</b
                    >
                  </li>
                  <li>3.)&nbsp;&nbsp;Images:&nbsp;&nbsp;<b class="green">Read Only</b></li>
                </ul>
              </li>
              <li>Select "Create Token" to save permissions and create the token.</li>
            </ol>
          </InstructionSection>

          <InstructionSection :imageFilenames="[LinodeToken4]">
            <ol class="linode-list">
              <li>Copy the Personal Access Token.</li>
            </ol>
          </InstructionSection>

          <InstructionSection :imageFilenames="[LinodeToken5]">
            <ol>
              <li>Paste the Personal Access Token into the Clovyr launch wizard.</li>
              <li>Select “Review” to review your settings and host before deploying your app.</li>
            </ol>
          </InstructionSection>
        </div>
      </div>
    </div>
  </div>

  <SpinnerCover v-if="showAwaitingSpinner" />
</template>

<style scoped lang="scss">
.permissions {
  margin-left: space(2);
  font-size: 14px;
}

.green {
  color: #33e672;
}

.linode-list {
  list-style-type: disc;
  padding: 0 space(5) 0 space(2);

  li {
    padding: space(0.5);
  }
}
</style>

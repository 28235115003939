export class HTTPError extends Error {
  status: number;

  body: string;

  constructor(msg: string, status: number, body: string) {
    super(msg);
    this.status = status;
    this.body = body;
  }
}

import { DEFAULT_DEPLOYMENT_METHOD, DeploymentMethod } from '../deployment';
import type { Manifest } from '../manifest';

import { chart } from './chart';
import { chartRemote } from './chart-remote';
import type { ConfigureBuilder } from './configure-builder';
import { dockerCompose } from './docker-compose';

const methods: { [key in DeploymentMethod]?: ConfigureBuilder } = {
  [DeploymentMethod.Chart]: chart,
  [DeploymentMethod.ChartRemote]: chartRemote,
  [DeploymentMethod.DockerCompose]: dockerCompose,
};

/**
 * Get builder for the given deployment method
 *
 * @param deploymentMethod
 * @returns
 */
export function getBuilderByMethod(deploymentMethod: DeploymentMethod): ConfigureBuilder {
  const fn = methods[deploymentMethod];
  if (fn) {
    return fn;
  }
  throw new Error(`unsupported deployment method '${deploymentMethod}'`);
}

/**
 * Get supported builder implementation for the given Manifest
 *
 * @param manifest
 * @param method
 * @returns
 */
export function getBuilderForManifest(
  manifest: Manifest,
  method?: DeploymentMethod
): ConfigureBuilder {
  if (!manifest.deployment?.methods) {
    // TODO: throw err here instead? we expect this to always be present
    return getBuilderByMethod(DEFAULT_DEPLOYMENT_METHOD);
  }

  let m = method;
  if (m && !manifest.deployment.methods.includes(m)) {
    // if requested method is not supported, return default
    // throw error instead?
    m = undefined;
  }
  if (!m) {
    if (manifest.deployment.methods.includes(DEFAULT_DEPLOYMENT_METHOD)) {
      m = DEFAULT_DEPLOYMENT_METHOD;
    } else {
      // get for first available method
      [m] = manifest.deployment.methods;
    }
  }
  return getBuilderByMethod(m);
}

export type { ConfigureBuilder };

<template>
  <BaseSvg class="clovyr-logo" viewBox="0 0 107 26">
    <g class="clovyr-logo__clover">
      <path
        d="m 12.448,11.3815 c -0.5297,0 -1.0329,-0.1591 -1.4302,-0.4244 L 3.20471,5.99583 c 0,0 -0.79455,-0.42449 -0.79455,-1.11429 0,-0.66326 0.90049,-1.14081 0.90049,-1.14081 L 8.55471,0.636645 C 8.63417,0.583584 8.68714,0.557053 8.74011,0.530522 8.89902,0.45093 9.08441,0.371339 9.26981,0.291747 9.53466,0.185624 9.79951,0.106032 10.0644,0.0529713 10.3292,-8.99604e-5 10.5676,-0.0266206 10.8324,-8.99594e-5 11.1238,0.0264407 11.3092,0.0795019 11.5475,0.185624 c 0.2384,0.106123 0.5033,0.159184 0.7681,0.212245 0,0 0.0795,0 0.1589,0.026531 z"
        fill="#00bf74"
        id="path2693"
      />
      <path
        d="M 11.1765,13.4775 C 10.9117,12.9999 10.5144,12.655 10.0906,12.4428 L 1.90672,8.09175 c 0,0 -0.76807,-0.47755 -1.377228,-0.13265 -0.5826734,0.31837 -0.529703108,1.37959 -0.529703108,1.37959 v 6.12861 c 0,0.0796 0,0.1591 0,0.2122 0,0.1857 0.026485108,0.3714 0.052970308,0.5572 0.0529698,0.2653 0.1059408,0.5571 0.1853958,0.7959 0.079456,0.2387 0.185396,0.4775 0.344307,0.6898 0.158911,0.2387 0.317822,0.3714 0.529708,0.5306 0.21188,0.1592 0.39727,0.3714 0.55618,0.5571 0,0 0.05297,0.0531 0.07946,0.1327 z"
        fill="#00bf74"
        id="path2695"
      />
      <path
        d="m 12.4482,11.3815 c 0.5297,0 1.033,-0.1591 1.4302,-0.4244 L 21.718,5.99583 c 0,0 0.7946,-0.42449 0.7946,-1.11429 0,-0.66326 -0.9005,-1.14081 -0.9005,-1.14081 L 16.3416,0.636645 C 16.2886,0.583584 16.2091,0.557053 16.1562,0.530522 15.9973,0.45093 15.8119,0.371339 15.6265,0.291747 15.3881,0.212155 15.0968,0.106032 14.8584,0.0529713 14.5935,-8.99604e-5 14.3552,-0.0266206 14.0903,-8.99594e-5 13.799,0.0264407 13.6136,0.0795019 13.3752,0.185624 c -0.2383,0.106123 -0.5032,0.159184 -0.768,0.212245 0,0 -0.0795,0 -0.159,0.026531 z"
        fill="#00e874"
        id="path2697"
      />
      <path
        d="m 13.7461,13.4775 c 0.2648,-0.4776 0.6621,-0.8225 1.0859,-1.0347 l 8.1574,-4.35105 c 0,0 0.7681,-0.47755 1.3772,-0.13265 0.5827,0.31837 0.5297,1.37959 0.5297,1.37959 v 6.12861 c 0,0.0796 0,0.1591 0,0.2122 0,0.1857 -0.0264,0.3714 -0.0529,0.5572 -0.053,0.2653 -0.106,0.5571 -0.1854,0.7959 -0.0795,0.2387 -0.1854,0.4775 -0.3443,0.6898 -0.1589,0.2387 -0.3179,0.3714 -0.5297,0.5306 -0.2119,0.1592 -0.3973,0.3714 -0.5562,0.5571 0,0 -0.053,0.0531 -0.0795,0.1327 z"
        fill="#00e874"
        id="path2699"
      />
      <path
        d="m 11.1765,13.4775 c 0.2649,0.4776 0.3708,0.9817 0.3443,1.4858 l -0.3443,9.3122 c 0,0 0.0265,0.9286 -0.5562,1.2735 -0.5562,0.3449 -1.43016,-0.2123 -1.43016,-0.2123 L 3.89311,22.2592 C 3.84014,22.2326 3.76068,22.1796 3.70771,22.1531 3.5488,22.0469 3.38989,21.9408 3.25746,21.8082 3.04558,21.6224 2.8337,21.4367 2.67479,21.251 2.51588,21.0653 2.35697,20.853 2.25103,20.6143 2.14509,20.349 2.09212,20.1367 2.06563,19.8714 2.03915,19.6061 1.95969,19.3408 1.85375,19.102 c 0,0 -0.02648,-0.0796 -0.05297,-0.1326 z"
        fill="#00a774"
        id="path2701"
      />
      <path
        d="m 13.7457,13.4775 c -0.2648,0.4776 -0.3708,0.9817 -0.3443,1.4858 l 0.3443,9.3122 c 0,0 -0.0265,0.9286 0.5562,1.2735 0.5562,0.3449 1.4302,-0.2123 1.4302,-0.2123 l 5.2705,-3.051 c 0.053,-0.0265 0.1325,-0.0796 0.1854,-0.1061 0.159,-0.1061 0.3179,-0.2123 0.4503,-0.3449 0.2119,-0.1857 0.4238,-0.3714 0.5827,-0.5572 0.1589,-0.1857 0.3178,-0.3979 0.4237,-0.6367 0.106,-0.2653 0.1589,-0.4775 0.1854,-0.7429 0.0265,-0.2653 0.106,-0.5306 0.2119,-0.7693 0,0 0.0265,-0.0796 0.053,-0.1327 z"
        fill="#00a774"
        id="path2703"
      />
    </g>

    <g class="clovyr-logo__text">
      <path
        d="m 105.967,8.86131 0.954,-1.85714 c 0.185,-0.3449 0.026,-0.63674 -0.371,-0.63674 -0.08,0 -0.133,0 -0.212,0 -0.185,0 -0.371,0 -0.556,0 -0.239,0 -0.503,0 -0.742,0 -0.265,0 -0.53,0 -0.794,0 -0.239,0 -0.477,0 -0.715,0 -0.159,0 -0.345,0 -0.504,0 -0.053,0 -0.132,0 -0.185,0 -0.901,0.05306 -1.642,0.58368 -2.172,1.3 -0.238,0.29184 -0.636,0.79592 -1.0593,1.59184 -0.2383,-2.06939 -1.0859,-2.65306 -2.0923,-2.67959 -0.4502,0 0.2384,0 -0.1854,0 -0.6886,0 -0.7681,0.07959 -0.7681,0.76938 0,2.78574 0,5.54494 0,8.33064 0,1.7245 0,3.449 0,5.1469 0,0.5306 0.106,0.6368 0.6357,0.6368 0.6621,0 1.3242,0 1.9599,0 0.5827,0 0.6886,-0.1062 0.6886,-0.7164 0,-2.6 0,-5.2 0,-7.7734 0,-0.3449 0.0265,-0.7164 0.1059,-1.0613 0.159,-0.8224 0.583,-1.5653 1.298,-2.0163 0.556,-0.34489 1.192,-0.45102 1.827,-0.47755 0.636,-0.05306 1.298,-0.05306 1.934,0 -0.027,-0.02653 0.662,0.05306 0.953,-0.55714 z"
        fill="#00e874"
        id="path2705"
      />
      <path
        d="m 58.4794,21.7021 c -4.1582,0.0796 -7.7867,-3.4755 -7.7337,-7.8265 0.053,-4.24495 3.5755,-7.69393 7.8661,-7.64086 4.2641,0.05306 7.7601,3.47551 7.7336,7.74696 -0.0264,4.2714 -3.5754,7.7469 -7.866,7.7204 z m 4.4759,-7.6143 c 0.0265,-0.7429 -0.1059,-1.4592 -0.3972,-2.1225 -1.3243,-2.91832 -4.9528,-3.36934 -6.9656,-1.4857 -1.4832,1.4062 -1.8805,3.6347 -1.0065,5.5715 1.0065,2.2551 3.7874,3.2102 5.9592,2.0428 1.5891,-0.849 2.3307,-2.2551 2.4101,-4.0061 z"
        fill="#00e874"
        id="path2707"
      />
      <path
        d="m 84.3816,25.2571 c -0.1853,0.5041 -0.0264,0.7164 0.5033,0.7164 0.7151,0 1.4302,0 2.1453,0 0.3708,0 0.5826,-0.1857 0.7151,-0.5041 1.0594,-2.6796 2.1453,-5.3327 3.2047,-8.0123 0.9005,-2.2551 1.8274,-4.5367 2.7279,-6.8183 0.4768,-1.16737 0.9535,-2.36124 1.4037,-3.52859 0.2119,-0.50408 0.053,-0.74286 -0.4767,-0.76939 -0.7945,0 0.053,0 -0.7151,0 -2.1453,0 -2.6485,1.37959 -2.8339,1.80408 -0.3443,0.90204 -0.6886,1.80408 -1.0329,2.7061 -0.6092,1.6449 -1.2183,3.2898 -1.854,4.9347 -0.0529,0.1592 -0.1324,0.2919 -0.2119,0.451 -0.0264,0 -0.0529,0 -0.1059,0 -0.5032,-1.3265 -0.9799,-2.653 -1.4832,-3.9796 -0.6621,-1.804 -1.3507,-3.63465 -2.0128,-5.43873 -0.106,-0.2653 -0.2914,-0.45102 -0.5827,-0.45102 -0.7416,0 -1.5097,-0.02653 -2.2513,0 -0.4767,0.02653 -0.6356,0.29184 -0.4502,0.74286 0.2649,0.66326 0.5297,1.35306 0.7946,2.01633 1.2977,3.18366 2.569,6.39386 3.9198,9.55106 0.3972,0.9285 0.7151,1.2734 0.2383,2.2551 z"
        fill="#00e874"
        id="path2709"
      />
      <path
        d="m 38.7213,21.7551 c -3.3901,-0.1061 -6.1976,-2.0694 -7.2835,-5.2531 -0.821,-2.4673 -0.5032,-4.8551 1.033,-7.0306 1.1918,-1.67143 2.7809,-2.78571 4.7938,-3.10408 2.3307,-0.37143 4.2641,0.21224 6.171,1.72449 0.2913,0.23877 0.2384,0.55714 0.053,0.87551 -1.1918,1.88368 -3.4166,0.45102 -4.3965,0.3449 -2.0659,-0.21225 -3.5226,0.90208 -4.2906,2.52038 -1.0595,2.1755 -0.4238,5.0939 2.0128,6.3143 0.98,0.5041 1.9599,0.5837 2.9399,0.3714 0.5827,-0.1326 2.569,-0.9816 3.8668,0.7164 0.1589,0.2653 0.1059,0.4775 -0.1324,0.6898 -0.4768,0.4245 -0.98,0.7428 -1.5362,1.0347 -0.9799,0.504 -2.1453,0.7693 -3.2311,0.7959 z"
        fill="#00e874"
        id="path2711"
      />
      <path
        d="m 73.3901,21.4368 c 0.4238,0 0.6357,-0.1326 0.7946,-0.5571 0.3178,-0.7694 0.6356,-1.5123 0.9535,-2.2817 0.9004,-2.1755 1.8274,-4.3775 2.7279,-6.553 0.6886,-1.6714 1.4037,-3.36941 2.0923,-5.04083 0.1854,-0.42449 0.053,-0.63674 -0.4237,-0.63674 -0.7946,0 -0.0265,0 -0.8211,0 -2.1453,0 -2.7544,1.59184 -2.9398,2.12245 -0.053,0.18571 -0.1324,0.37143 -0.1854,0.55714 -0.7151,1.96328 -1.4302,3.95308 -2.1188,5.91628 -0.1589,0.4511 -0.3443,0.9286 -0.5297,1.4592 -0.0795,0.0266 -0.1324,-0.1592 -0.1324,-0.1857 C 72.2778,14.7776 71.748,13.3184 71.2183,11.8593 70.6357,10.2144 70.0265,8.56947 69.4438,6.95111 69.3114,6.57968 69.0466,6.39396 68.6493,6.39396 c -0.6886,0 -1.3507,0 -2.0394,0 -0.6621,0 -0.8475,0.29184 -0.6091,0.87551 0.7945,1.91021 1.5891,3.82043 2.3572,5.75713 0.6886,1.6714 1.3772,3.3163 2.0393,4.9878 0.3973,0.9551 0.7946,1.9367 1.1918,2.8918 0.1589,0.398 0.4238,0.5837 0.8741,0.5571 z"
        fill="#00e874"
        id="path2713"
      />
      <path
        d="m 45.4751,11.7264 c 0,-2.9714 0,-5.25304 0,-8.22447 0,-0.68979 1.5626,-1.06122 2.7809,-1.00816 0.4768,0 0.6092,0.13265 0.6357,0.6102 0,0.10613 0,0.21225 0,0.31837 0,5.86327 0,11.24896 0,17.11226 0,0.1326 0,0.2653 0,0.3979 -0.0265,0.3184 -0.2119,0.5041 -0.5297,0.5041 -0.7946,0 -1.5626,0 -2.3572,0 -0.3443,0 -0.5032,-0.1857 -0.5032,-0.5836 0,-1.0613 0,-2.1225 0,-3.2102 -0.0265,-1.9633 -0.0265,-3.9531 -0.0265,-5.9164 z"
        fill="#00e874"
        id="path2715"
      />
    </g>

    <defs id="defs2722">
      <clipPath id="clip0">
        <rect width="107" height="26" fill="white" id="rect2719" />
      </clipPath>
    </defs>
  </BaseSvg>
</template>

<script>
import BaseSvg from './BaseSvg.vue';

export default {
  name: 'ClovyrLogo',
  components: {
    BaseSvg,
  },
};
</script>

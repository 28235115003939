import { type ComputeProviderInfo, ComputeProviderName } from '../types';

import { AWSEC2 } from './aws-ec2';
import { DigitalOcean } from './digitalocean';
import { Linode } from './linode';
import { WNext } from './wnext';
import {
  ComputeInstanceImage,
  ComputeInstanceRegion,
  ComputeInstanceSize,
  type ComputeProvider,
  type CreateInstanceRequest,
  type OAuthSupport,
} from '.';

export function isDigitalOceanProvider(provider?: ComputeProvider): provider is DigitalOcean {
  return provider instanceof DigitalOcean;
}

export function isLinodeProvider(provider?: ComputeProvider): provider is Linode {
  return provider instanceof Linode;
}

export function isWNextProvider(provider?: ComputeProvider): provider is WNext {
  return provider instanceof WNext;
}

export function isAWSEC2Provider(provider?: ComputeProvider): provider is AWSEC2 {
  return provider instanceof AWSEC2;
}

/**
 *
 * @param provider
 * @returns
 */
export function supportsOAuth(provider?: ComputeProvider): provider is OAuthSupport {
  return !!provider && 'setOAuthToken' in provider;
}

export function getComputeProvider(host: ComputeProviderInfo | string): ComputeProvider {
  const h: string = (host as ComputeProviderInfo).id || host;
  let provider: ComputeProvider;
  switch (h) {
    case ComputeProviderName.WNext:
      provider = new WNext();
      break;

    case ComputeProviderName.DigitalOcean:
      provider = new DigitalOcean();
      break;

    case ComputeProviderName.Linode:
      provider = new Linode();
      break;

    case ComputeProviderName.AWSEC2:
      provider = new AWSEC2();
      break;

    default:
      throw new Error(`unknown compute provider: ${h}`);
  }

  return provider;
}

/**
 * prepare CreateInstanceRequest for a given provider
 *
 * @param provider
 */
export function prepareCreateInstanceRequest(
  provider: ComputeProvider,
  region: string,
  size?: ComputeInstanceSize,
  image?: ComputeInstanceImage
): CreateInstanceRequest {
  function instanceType(): string {
    return provider.getInstanceType(size || ComputeInstanceSize.Small)[0];
  }

  function instanceImage() {
    return provider.getInstanceImage(image || ComputeInstanceImage.UbuntuLTS, region);
  }

  return {
    instanceType: instanceType(),
    instanceRegion: region,
    instanceImage: instanceImage(),
  };
}

<script setup lang="ts">
import { AppLogo, type Logo } from '@clovyr/bed';
import AppLogoPlaceholder from '@clovyr/bed/assets/images/app-logo-placeholder.svg';
import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import Button from '@clovyr/bed/components/controls/Button.vue';
import { Invoice } from '@clovyr/pollen/types/Invoice';
import { shortDate } from '@clovyr/pollen/util/date';

import SettingOption from '@/components/blocks/SettingOption.vue';
import {
  formatAmount,
  type PaymentCallbackState,
  PaymentCallbackStateKey,
  usePayments,
} from '@/composables/usePayments';
import { usePollenStore } from '@/stores/pollen_store';
import { rehydrate } from '@/stores/util';

const props = defineProps<{
  invoice: Invoice;
}>();

const payments = usePayments();
const { sortedDeployments } = payments;
const { getDeploymentApp } = usePollenStore();

const dep = computed(() => {
  return sortedDeployments.value.find((deployment) => {
    return !!props.invoice.line_items.some(
      (item) => item.subscription_item_id === deployment.subscriptionItemID,
    );
  });
});

// Deployments whose subscriptionItemID matches any subscription_item_id in props.invoice.line_items
const appData = computed(() => {
  if (!dep.value) {
    return undefined;
  }
  return getDeploymentApp(dep.value);
});

// app logo
const invoiceItemLogo = computed((): Logo => {
  if (!appData.value) {
    return {
      logoUrl: AppLogoPlaceholder,
      logoBackgroundColor: '#453960',
    };
  }
  return {
    logoUrl: appData.value?.metadata.logoUrl,
    logoBackgroundColor: appData.value?.metadata.logoBackgroundColor,
  };
});

async function onClickPay() {
  try {
    const cb: PaymentCallbackState = {
      invoiceID: props.invoice.id,
      successURL: '/#settings/invoices',
      errorURL: '/#settings/invoices',
      cancelURL: '/#settings/invoices',
    };

    const state = rehydrate<PaymentCallbackState>(PaymentCallbackStateKey);
    if (state && state.invoiceID === props.invoice.id) {
      // use stored values
      cb.deploymentID = state.deploymentID;
      cb.subscriptionItemID = state.subscriptionItemID;
      cb.invoiceID = state.invoiceID;
    } else if (props.invoice.line_items.length === 1 && dep.value) {
      // try to figure it out (fallback method)
      // add if we are paying for a single deployment upgrade
      cb.deploymentID = dep.value?.id;
      cb.subscriptionItemID = dep.value?.subscriptionItemID;
      cb.invoiceID = props.invoice.id;
    }
    if (cb.deploymentID) {
      // use urls for the deployment instead
      cb.successURL = `/library/${cb.deploymentID}`;
      cb.errorURL = `/library/${cb.deploymentID}`;
      cb.cancelURL = `/library/${cb.deploymentID}`;
    }

    window.history.pushState({}, '', '/#settings/invoices');
    await payments.capturePaymentForInvoice(props.invoice, cb);
  } catch (e) {
    // TODO: handle err
    console.error(e);
  }
}

const isOverdue = computed(() => {
  const currentDate = new Date();
  return props.invoice.due_date < currentDate && props.invoice.paid_at === null;
});

const classNames = computed(() => {
  return [props.invoice.paid_at !== null && `invoice-paid`, isOverdue.value && `invoice-overdue`];
});

// FIXME: move this code to useDetectUserOS composable once Sharing UI branch is merged
const isTablet = ref(false);
const isMobile = ref(false);
const isDesktop = ref(false);
const { userAgent } = navigator;

// Define patterns for different device types
const mobilePattern = /Mobile|Android|iPhone|iPod|BlackBerry|Windows Phone/i;
const tabletPattern = /iPad|Android|Tablet/i;

// Check if the user agent matches mobile or tablet pattern.
if (mobilePattern.test(userAgent)) {
  if (tabletPattern.test(userAgent)) {
    // If it matches both mobile and tablet, it's likely a tablet
    isTablet.value = true;
  } else {
    isMobile.value = true;
  }
} else {
  isDesktop.value = true;
}

const trimmedName = (d: string) => {
  if ((isDesktop.value || isTablet.value) && d.length > 30) {
    return `${d.slice(0, 29)}...`;
  }
  if (isMobile.value && d.length > 20) {
    return `${d.slice(0, 19)}...`;
  }
  return d;
};

const invoiceStatus = computed(() => {
  // Cases: Paid, unpaid & due, unpaid & overdue
  if (isOverdue.value && !props.invoice.paid_at) {
    return `Overdue invoice #${props.invoice.invoice_number} due ${shortDate(props.invoice.invoice_date, 'UTC')}`;
  }
  if (props.invoice.paid_at) {
    return `Invoice #${props.invoice.invoice_number} paid on ${shortDate(props.invoice.paid_at!, 'UTC')}`;
  }
  return `Invoice #${props.invoice.invoice_number} due ${shortDate(props.invoice.invoice_date, 'UTC')}`;
});
</script>

<template>
  <div class="section">
    <SettingOption>
      <template #left>
        <div class="invoice">
          <div class="invoice-content">
            <div class="invoice-details" :class="classNames">
              <div class="details-left">
                <!-- Cases: Paid, unpaid & due, unpaid & overdue -->
                {{ invoiceStatus }}
              </div>
              <div class="details-right">
                <Button
                  v-if="!invoice.paid_at"
                  :label="`Pay ${formatAmount(invoice.amount, invoice.currency)}`"
                  reversed
                  @click="onClickPay"
                />
                <p v-if="invoice.paid_at">
                  Total: {{ formatAmount(invoice.amount, invoice.currency) }}
                </p>
              </div>
            </div>
            <div class="invoice-item" v-for="line in invoice.line_items" :key="line.id">
              <AppLogo class="app-logo" size="x-small" :logo="invoiceItemLogo" />
              <Tooltip
                :disabled="isMobile"
                placement="right"
                theme="narrow-fixed"
                :arrow="false"
                :content="`${trimmedName(line.description)} ${formatAmount(line.amount, line.currency)}`"
              >
                <h4>{{ trimmedName(line.description) }}</h4>
                <p>{{ formatAmount(line.amount, line.currency) }}</p>
              </Tooltip>
            </div>
          </div>
        </div>
      </template>
    </SettingOption>
  </div>
</template>

<style lang="scss" scoped>
.section {
  padding: 0.5rem 2rem 0.01rem 2rem;
}
.invoice {
  display: flex;
  align-items: center;

  .invoice-item-logo {
    filter: drop-shadow(0 2px 10px transparentize(color(black), 0.75));
  }
  .invoice-content {
    width: 100%;
    margin-left: space(1.5);

    .invoice-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      .details-left {
        display: flex;
        color: color(grey, secondary);

        > * {
          margin-right: 0.2rem;
        }
      }
      .details-right {
        color: color(grey, secondary);
      }
    }

    .invoice-paid {
      height: 2.85rem;
    }

    .invoice-overdue {
      .details-left {
        color: color(warning, primary);
      }
      .details-right {
        .button {
          background-color: color(warning, primary);
        }
      }
    }

    .invoice-item {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      :deep(.tooltip) {
        > * {
          display: flex;
        }
      }

      h4 {
        color: color(grey, tertiary);
        margin-left: 0.5rem;
      }

      p {
        margin-left: 0.5rem;
        color: color(grey, secondary);
      }
    }
  }
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { usePollenStore } from '@/stores/pollen_store';
import AccountSettings from '@/views/Settings/AccountSettings.vue';
import DeviceList from '@/views/Settings/DeviceList.vue';
import ForgetSavedHosts from '@/views/Settings/ForgetSavedHosts.vue';
import Subscriptions from '@/views/Settings/Subscriptions.vue';

import Invoices from './Invoices.vue';

const pollenStore = usePollenStore();
const { computeProviderConfigs, isLocked } = storeToRefs(pollenStore);
</script>

<template>
  <div class="settings">
    <div class="settings__heading">Settings</div>

    <AccountSettings />
    <ForgetSavedHosts
      v-if="!isLocked && computeProviderConfigs"
      :saved-hosts="computeProviderConfigs"
    />

    <!-- TODO: we don't currently track devices anywhere. need some mechanism to report & manage them -->
    <DeviceList v-if="false" />

    <Subscriptions v-if="!isLocked" />

    <Invoices v-if="!isLocked" />
  </div>
</template>

import type { App as VueApp } from 'vue';
import { createHead, type MergeHead, useHead, type VueHeadClient } from '@unhead/vue';

export function initHead(app: VueApp<Element>): VueHeadClient<MergeHead> {
  const head = createHead();
  app.use(head);

  const jsonSchema = {
    '@context': 'http://www.schema.org',
    '@type': 'Organization',
    name: 'Clovyr',
    sameAs: ['https://clovyr.io', 'https://twitter.com/growclovyr', 'https://github.com/clovyr'],
    image: {
      '@type': 'ImageObject',
      inLanguage: 'en-US',
      url: 'https://clovyr.app/logo.png',
      name: 'logo',
      width: 125,
      height: 131,
    },
    description:
      'Clovyr is a company dedicated to building friendly, flexible tools that bring the usability of modern applications to decentralized ones. We prioritize privacy, security, and choice, without compromising convenience and user experience.',
    email: 'support@clovyr.io',
  };

  // global head tags
  useHead({
    title: 'Clovyr',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
    link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }],
    script: [
      {
        type: 'application/ld+json',
        children: JSON.stringify(jsonSchema),
      },
    ],
  });

  return head;
}

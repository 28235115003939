<script setup lang="ts">
import Module from '@/components/elements/Module.vue';
import { useAppStatus } from '@/composables/useAppStatus';

const props = defineProps<{ id: string }>();

const deploymentID = toRef(props, 'id');
const { isRunning } = useAppStatus(deploymentID);
</script>
<template>
  <Module class="module--appessentials app-essentials" :bigHead="true" hed="Troubleshooting">
    <template #default>
      <div class="row">
        <div class="grid__col">
          <div class="module__statinline row">
            <Button
              label="Console Access & Logs"
              :link="`/library/${id}/troubleshoot`"
              :disabled="!isRunning"
            />
          </div>
        </div>
      </div>
    </template>
  </Module>
</template>

<style scoped lang="scss">
.app-essentials {
  :deep(.module__head) {
    background-color: rgba(132, 115, 152, 0.1);
  }
  :deep(.module__body) {
    .module__statinline {
      margin-top: space(2);
      margin-bottom: space(1);

      .button__label {
        @include media-breakpoint-only(md) {
          font-size: 11px;
        }
      }
    }
  }
}
</style>

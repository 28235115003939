import { deepCopy } from '../../util/deep-copy';
import type { DefinitionsService } from '../docker-compose';
import {
  type Dict,
  dockerComposeToString,
  dumpEnv,
  dumpYaml,
  findSetting,
  formatHostRules,
  parseDockerCompose,
  replaceVariables,
} from '../util';
import {
  type AdditionalDNSOptions,
  CLOVYR_ACCESS_KEY,
  CLOVYR_BACKUP_PASSWORD,
  CLOVYR_CONFIGURE_KEY,
  CLOVYR_CUSTOM_DOMAIN,
  CLOVYR_FQDN,
  CLOVYR_S3_URL,
  CLOVYR_SECRET_KEY,
  CLOVYR_TREFOIL_URL,
  DeploymentAddons,
  DeploymentMethod,
  type DockerCompose,
  type Files,
  type Manifest,
  type Values,
} from '..';

import type { ConfigureBuilder } from '.';
import { firewall } from './common';
import type { PostProcessFilesFn } from './configure-builder';
import traefikAuth from './traefik-auth.yaml?raw';
import traefikCompose from './traefik-compose.yaml?raw';
import traefikConfig from './traefik-config.yaml?raw';
import { isDNSProxied, writeAllFilesBase64, writeBase64File } from './util';

const TRAEFIK_CERTS_PATH = '/opt/clovyr/traefik/certs';

type DependsOnCondition = 'service_started' | 'service_healthy' | 'service_completed_successfully';

type TraefikRule = {
  serviceName: string;
  isCustom: boolean;
  labels: Dict;
};

/**
 * Add a traefik router+service to the Docker Compose service for each exposed
 * service in the manifest
 *
 * @param manifest
 * @param dc
 */
function addTraefikLabels(manifest: Manifest, dc: DockerCompose, values: Values) {
  const proxyDNS = isDNSProxied(manifest, values[CLOVYR_CUSTOM_DOMAIN] as string);
  const dcConfig = manifest.deployment?.['docker-compose'];
  const services = dcConfig?.services;
  if (!services || !dc.services) {
    return;
  }

  let defaultRules: TraefikRule[] = [];
  let defaultRuleAdded = false;
  Object.entries(services).forEach(([serviceName, portOpts]) => {
    const port = typeof portOpts === 'number' ? portOpts : portOpts.port;
    const svc = dc.services![serviceName];
    svc.labels ||= {};

    // Use custom routing rule if available
    let isCustom = true;
    let rule = dcConfig.proxy?.rules?.[serviceName];
    if (!rule) {
      isCustom = false;
      rule = `Host({{ HOST_RULES }})`; // use default host-based routing
    }

    // traefik auth middleware is disabled for now, due to complications
    // with setting our auth cookie across different browsers. Firefox,
    // for example, blocks third-party cookies on an XHR as part of it's
    // tracking protection feature.
    // cors is needed here in order to make an XHR to check if the app is
    // up (returning 200)
    const middlewares = 'leaf_cors@file'; // , leaf_auth@file

    if (!defaultRuleAdded) {
      // create rule but defer adding until we look at all services
      if (typeof portOpts !== 'number' && portOpts.primary) {
        // clear non-custom rules so only the primary service routed at the root domain
        defaultRules = defaultRules.filter((r) => r.isCustom);
        defaultRuleAdded = true;
      }
      defaultRules.push({
        serviceName,
        isCustom,
        labels: createTraefikConfig(serviceName, rule, middlewares, port, proxyDNS),
      });
    }

    // add separate labels for each additional host
    if (manifest.deployment?.dns?.additional_hosts) {
      Object.entries(manifest.deployment.dns.additional_hosts).forEach(([host, service]) => {
        const opts: AdditionalDNSOptions = {} as AdditionalDNSOptions;

        if (typeof service === 'string') {
          const matches = service.match(new RegExp(`^${serviceName}(:\\d+)?$`));
          if (!matches) {
            return;
          }
          const additionalPortStr = matches?.[1];
          opts.port = additionalPortStr ? parseInt(additionalPortStr.slice(1), 10) : port;
        } else {
          if (serviceName !== service.service) {
            return;
          }
          Object.assign(opts, service);
          opts.port ||= port;
        }

        if (opts.proxy === false) {
          // skip adding labels for this service
          return;
        }

        const hosts = formatHostRules(
          values[CLOVYR_FQDN] as string,
          values[CLOVYR_CUSTOM_DOMAIN] as string,
          host
        );
        const additionalHostRule = `Host(${hosts})`;
        Object.assign(
          svc.labels!,
          createTraefikConfig(`${host}-extra`, additionalHostRule, middlewares, opts.port, proxyDNS)
        );
      });
    }
  });

  // add default rule
  defaultRules.forEach((rule) => {
    Object.assign(dc.services![rule.serviceName].labels!, rule.labels);
  });
}

/**
 * Create standard traefik labels for the given service
 *
 * @param serviceName
 * @param rule
 * @param middlewares
 * @param port
 * @returns
 */
function createTraefikConfig(
  serviceName: string,
  rule: string,
  middlewares: string,
  port: number,
  proxyDNS: boolean
): Dict {
  const labels = {
    'traefik.enable': 'true',
    [`traefik.http.routers.${serviceName}.tls`]: 'true',
    [`traefik.http.routers.${serviceName}.rule`]: rule,
    [`traefik.http.routers.${serviceName}.middlewares`]: middlewares,
    [`traefik.http.routers.${serviceName}.service`]: serviceName,
    [`traefik.http.services.${serviceName}.loadbalancer.server.port`]: `${port}`,
  };

  if (!proxyDNS) {
    // when not proxying (via cdn/cloudflare), ensure that lets encrypt is enabled in traefik
    labels[`traefik.http.routers.${serviceName}.tls.certresolver`] = 'default';
  }

  return labels;
}

/**
 * Attach addon services as requested by manifest
 *
 * @param manifest
 * @param dc
 */
function addDeploymentAddons(manifest: Manifest, dc: DockerCompose, values: Values) {
  const proxyDNS = isDNSProxied(manifest, values[CLOVYR_CUSTOM_DOMAIN] as string);
  if (manifest.deployment && manifest.deployment.addons) {
    if (manifest.deployment.addons[DeploymentAddons.MailHog]) {
      addMailhog(dc, proxyDNS);
    }
  }
}

function addMailhog(dc: DockerCompose, proxyDNS: boolean) {
  // synapse: Host(`{{ HOSTNAME }}`)
  const rule = 'Host(`{{ HOSTNAME }}`) && PathPrefix(`/_leaf/mail/`)';
  const middlewares = 'leaf_cors@file,leaf_auth@file';
  const labels = createTraefikConfig('clovyr-mailhog', rule, middlewares, 8025, proxyDNS);
  labels[`traefik.http.routers.clovyr-mailhog.priority`] = '1001';
  const origin = typeof window !== 'undefined' ? window.location.hostname : 'clovyr.app';
  dc.services!['clovyr-mailhog'] = {
    image: 'clovyr/mailhog:latest',
    restart: 'unless-stopped',
    logging: {
      driver: 'none', // disable saving logs
    },
    environment: {
      MH_CORS_ORIGIN: `https://${origin}`,
      MH_UI_WEB_PATH: '_leaf/mail',
    },
    labels,
  };
}

/**
 * Process the App Config Values. Adds vars to either the service's environment
 * var map or to a shared clovyr.env file.
 *
 * @param manifest
 * @param values
 * @param dc
 * @returns
 */
export function addConfigEnv(manifest: Manifest, values: Values, dc: DockerCompose): Dict {
  const clovyrEnv: Dict = {};
  if (!values || Object.keys(values).length === 0) {
    return clovyrEnv;
  }

  // add to each service, if key is present in declared env
  const { services } = dc;
  if (!services) {
    return clovyrEnv;
  }

  Object.keys(values).forEach((valueName) => {
    let found = false;
    const cfg = findSetting(valueName, manifest);
    Object.keys(services).forEach((svcName) => {
      const sv = services[svcName];
      const env = sv.environment;
      if (typeof env === 'object') {
        const envKeys = Object.keys(env);
        if (envKeys.includes(valueName)) {
          env[valueName] = values[valueName];
          // always replace directly in env vars
          // but only skip adding to clovyr.env if
          if (cfg && cfg.secure) {
            found = true;
          }
        }
      }
    });
    if (!found || (cfg && !cfg.secure)) {
      // add remaining keys to clovyrEnv
      // TODO: add all keys here no matter what?
      // this is currently to avoid passing sensitive vars to all services,
      // e.g., don't pass postgres root pw to all services.
      clovyrEnv[valueName] = values[valueName];
    }
  });

  Object.keys(services).forEach((svcName) => {
    const sv = services[svcName];
    if (Array.isArray(sv.env_file)) {
      sv.env_file.push('.env');
    }
  });

  return clovyrEnv;
}

/**
 * Create and assign a unique bridge network for this compose app
 *
 * @param networkName
 * @param dc
 */
function addNetworks(manifest: Manifest, dc: DockerCompose) {
  const dcConfig = manifest.deployment?.['docker-compose'];
  if (dcConfig) {
    const { networks } = dcConfig;
    if (networks && networks.length > 0) {
      // add defined networks to our addon containers (so far just mailhog??)
      const mh = dc.services?.['clovyr-mailhog'];
      if (mh) {
        mh.networks ||= [];
        networks.forEach((name) => {
          if (Array.isArray(mh.networks)) {
            mh.networks.push(name);
          }
        });
      }
      return; // don't do anything when explicitly set
    }

    // add network using app id name if it doesn't  exist
    const networkName = manifest.metadata.id;
    addNetwork(networkName, dc);
    dcConfig.networks = [networkName];
  }
}

function addNetwork(networkName: string, dc: DockerCompose) {
  dc.networks ||= {};
  if (dc.networks[networkName]) {
    return;
  }
  dc.networks[networkName] = {
    name: networkName,
    driver: 'bridge',
  };

  // assign network to each service
  // @ts-ignore
  Object.values(dc.services).forEach((s) => {
    s.networks ||= {};
    if (!s.network_mode || s.network_mode.length === 0) {
      s.networks[networkName] ||= {};
    }
  });
}

/**
 * Add bind volume to the given service
 *
 * @param svc
 * @param source
 * @param target
 */
function addVolume(svc: DefinitionsService, source: string, target: string) {
  if (!svc.volumes) {
    svc.volumes = [];
  }
  const vols = svc.volumes!;
  if (vols.length === 0 || typeof vols[0] === 'string') {
    // add volume as string type
    vols.push(`${source}:${target}`);
  } else {
    // add volume as object type
    vols.push({ type: 'bind', source, target });
  }
}

function addTLSVolume(manifest: Manifest, dc: DockerCompose) {
  if (!(manifest.deployment?.dns?.proxy === false && manifest.deployment?.dns?.tls?.mount)) {
    return;
  }

  Object.entries(manifest.deployment.dns.tls.mount).forEach(([serviceName, path]) => {
    // add as mount on service
    if (!dc.services![serviceName].volumes) {
      dc.services![serviceName].volumes = [];
    }
    addVolume(dc.services![serviceName], TRAEFIK_CERTS_PATH, path);
  });
}

/**
 * Add traefik-related network and label config to compose file
 *
 * @param manifest Application manifest
 * @param composeFile The contents of the docker-compose.yml file for the given application
 * @returns Modified DockerCompose object
 */
export function mkCompose(manifest: Manifest, values: Values, composeFile: string): DockerCompose {
  const dc = parseDockerCompose(composeFile);

  addDeploymentAddons(manifest, dc, values);
  addNetworks(manifest, dc);
  addTraefikLabels(manifest, dc, values);
  addTLSVolume(manifest, dc);
  attachRestic(manifest, values, dc);

  return dc;
}

/**
 * Attach Restic sidecar if required by application manifest
 *
 * @param dc
 * @param appId
 */
function attachRestic(manifest: Manifest, values: Values, dc: DockerCompose) {
  if (!manifest.backup?.locations?.length) {
    return;
  }

  // initialize restic svc and add to compose file
  const backupSvc = newResticService(manifest, values);

  // attach volumes to svcs and collect paths
  const resticPaths: Array<string> = [];
  const seenVolumes = {};
  manifest.backup.locations.forEach((backupLocation) => {
    resticPaths.push(`/restic/${backupLocation.volume}${backupLocation.path}`);
    if (seenVolumes[backupLocation.volume]) {
      return;
    }
    seenVolumes[backupLocation.volume] = 1;
    backupSvc.volumes!.push(`${backupLocation.volume}:/restic/${backupLocation.volume}`);
  });
  (backupSvc.environment as Dict)['RESTIC_PATHS'] = resticPaths.join(' ');
  backupSvc.healthcheck = {
    test: ['CMD', 'curl', '-f', 'http://127.0.0.1:5134/healthz'],
    start_period: '30s',
    interval: '5s',
  };

  // create restore service
  // basic config is identical to backup with a couple changes
  const restoreWaitSvc = newResticService(manifest, values);
  restoreWaitSvc.command = ['restore-wait'];
  restoreWaitSvc.restart = 'no';
  restoreWaitSvc.depends_on = {
    'clovyr-restic-backup': {
      condition: 'service_healthy' as DependsOnCondition,
    },
  };

  // add restore container as dep to all services
  const restoreDep = {
    'clovyr-restic-restore': {
      condition: 'service_completed_successfully' as DependsOnCondition,
    },
  };
  Object.values(dc.services!).forEach((svc) => {
    svc.depends_on = { ...svc.depends_on, ...deepCopy(restoreDep) };
  });

  // add restic services
  dc.services!['clovyr-restic-backup'] = backupSvc;
  dc.services!['clovyr-restic-restore'] = restoreWaitSvc;
}

function newResticService(manifest: Manifest, values: Values): DefinitionsService {
  return {
    image: 'clovyr/restic:api-latest',
    restart: 'always',
    init: true,
    network_mode: 'host',
    environment: {
      // TODO: instead of using manifest id, use fqdn name for repo?
      RESTIC_REPOSITORY: `${values[CLOVYR_S3_URL]}/${manifest.metadata.id}`,
      AWS_ACCESS_KEY_ID: values[CLOVYR_ACCESS_KEY],
      AWS_SECRET_ACCESS_KEY: values[CLOVYR_SECRET_KEY],
      RESTIC_PASSWORD: values[CLOVYR_BACKUP_PASSWORD],
      FQDN: values[CLOVYR_FQDN],
      CONFIGURE_KEY: values[CLOVYR_CONFIGURE_KEY],
      TREFOIL_URL: values[CLOVYR_TREFOIL_URL],
    },
    volumes: [],
  };
}

/**
 * Attach service network to traefik compose file
 *
 * @param manifest
 * @returns {DockerCompose} traefik compose file
 */
export function mkTraefikCompose(manifest: Manifest): DockerCompose {
  const tc = parseDockerCompose(traefikCompose);
  tc.networks ||= {};

  // Give traefik access to each network used by the app as defined in the manifest
  const networks = manifest.deployment?.['docker-compose']?.networks;
  if (networks) {
    networks.forEach((network) => {
      // @ts-ignore
      tc.networks[network] = {
        name: network,
        external: true,
      };
      // @ts-expect-error
      tc.services['traefik'].networks.push(network);
    });
  }
  return tc;
}

/**
 * Prepare data needed to build final configure script
 *
 * This exported method exists for easier testability (and composability) of
 * this codebase.
 *
 * @param manifest
 * @param values
 * @returns
 */
export function prepareConfigureData(
  manifest: Manifest,
  values: Values
): [DockerCompose, Dict, Files, string] {
  if (!manifest.deployment?.contents?.[DeploymentMethod.DockerCompose]) {
    throw new Error('manifest error: missing deployment contents');
  }

  const manifestCopy = deepCopy(manifest);

  // create a copy of files so we can replace docker-compose.yml with a modified version
  const files: Files = {
    // @ts-ignore
    ...manifest.deployment.contents[DeploymentMethod.DockerCompose],
  };

  let dc = mkCompose(manifestCopy, values, files['docker-compose.yml']);
  const clovyrEnv = addConfigEnv(manifestCopy, values, dc);
  files['.env'] = dumpEnv(clovyrEnv);
  files['docker-compose.yml'] = dockerComposeToString(dc);

  // replace supported variables in all files
  Object.keys(files).forEach((file) => {
    files[file] = replaceVariables(files[file], values);
  });

  files['clovyr-values.yml'] = dumpYaml(values);

  const traefikComposeFile = dockerComposeToString(mkTraefikCompose(manifestCopy));

  // return modified compose file
  dc = parseDockerCompose(files['docker-compose.yml']);

  return [dc, clovyrEnv, files, traefikComposeFile];
}

function mkConfigureScript(manifest: Manifest, values: Values, fn?: PostProcessFilesFn): string {
  const [, , files, traefikComposeFile] = prepareConfigureData(manifest, values);

  if (fn) {
    fn(files);
  }

  let customBootstrap = '';
  if (manifest.deployment?.bootstrap?.custom_script) {
    customBootstrap = `
    (cat <<-'EOF'

${manifest.deployment.bootstrap.custom_script}

EOF
    ) > /tmp/clovyr_custom_bootstrap.sh
    bash /tmp/clovyr_custom_bootstrap.sh
    rm -f /tmp/clovyr_custom_bootstrap.sh
    `;
  }

  const script = `#!/bin/bash
set -euxo pipefail

${firewall}

# ---
# install docker
# GPG key
sudo mkdir -p /etc/apt/keyrings
if [ ! -f /etc/apt/keyrings/docker.gpg ]; then
  curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo gpg --dearmor -o /etc/apt/keyrings/docker.gpg
fi
# add apt repo
echo \
  "deb [arch=$(dpkg --print-architecture) signed-by=/etc/apt/keyrings/docker.gpg] https://download.docker.com/linux/ubuntu \
  $(lsb_release -cs) stable" | sudo tee /etc/apt/sources.list.d/docker.list > /dev/null
# install
sudo apt-get update
sudo apt-get install -y docker-ce docker-ce-cli containerd.io docker-compose-plugin
# ---

${customBootstrap}

# disable web ssh access, if needed (ec2, lightsail)
dpkg -l ec2-instance-connect 2>&1 >/dev/null \
  && ( apt-get remove -qqy ec2-instance-connect \
    && sed -e '/^TrustedUserCAKeys/s/^/#/' -i /etc/ssh/sshd_config \
    && systemctl restart sshd.service ) &

# install traefik
mkdir -p /opt/clovyr/traefik/conf/dynamic
${writeBase64File('/opt/clovyr/traefik/docker-compose.yml', traefikComposeFile)}
${writeBase64File('/opt/clovyr/traefik/conf/traefik.yml', traefikConfig)}
${writeBase64File(
  '/opt/clovyr/traefik/conf/dynamic/forward-auth.yml',
  replaceVariables(traefikAuth, values)
)}

pushd /opt/clovyr/traefik
docker compose pull --quiet &
popd

# install app
mkdir -p /opt/clovyr/apps/${manifest.metadata.id}
pushd /opt/clovyr/apps/${manifest.metadata.id}
${writeAllFilesBase64(files)}
docker compose up --quiet-pull --detach
popd

# start traefik
# wait for instance-configure to exit before we start traefik
# to avoid issues listening on 80/443
bash -c 'sleep 1; cd /opt/clovyr/traefik; docker compose up --quiet-pull --detach' &
`;

  return script;
}

export const dockerCompose = {
  mkConfigureScript,
  prepareConfigureData,
} as ConfigureBuilder;

export default dockerCompose;

/**
 * Retries a function a number of times before throwing an error
 *
 * @param fn
 * @param retries
 * @returns Value returned by the original function (fn)
 */
export async function retry<T>(fn: () => Promise<T>, retries = 3): Promise<T> {
  try {
    return await fn();
  } catch (e) {
    if (retries > 0) {
      return retry(fn, retries - 1);
    }
    throw e;
  }
}

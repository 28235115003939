<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { NavigationFailure } from 'vue-router';
import * as Sentry from '@sentry/core';
import yaml from 'yaml';

import { Button, ImageMedia } from '@clovyr/bed';
import GearIconGray from '@clovyr/bed/assets/images/gear-icon-gray.svg';
import GearIconWhite from '@clovyr/bed/assets/images/gear-icon-white.svg';
import SparkleIconGray from '@clovyr/bed/assets/images/sparkle-icon-gray.svg';
import SparkleIconWhite from '@clovyr/bed/assets/images/sparkle-icon-white.svg';
import { IconPosition } from '@clovyr/bed/components/controls/types';
import { type PublisherApp, type PublisherAppVersion, type Website } from '@clovyr/pollen';
import { getAllQueryParams } from '@clovyr/pollen/http/qs';
import type { Manifest } from '@clovyr/pollen/manifest';
import { PublisherAPI } from '@clovyr/pollen/publisher';
import { deepCopy } from '@clovyr/pollen/util/deep-copy';

import Banner from '@/components/blocks/Banner.vue';
import FormField from '@/components/blocks/FormField.vue';
import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import ArrowForwardsIcon from '@/components/icons/ArrowForwardsIcon.vue';
import { useEditStore } from '@/stores/edit_store';
import { useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';

import { usePublisher } from '../../composables/usePublisher';

import DevelopBuild from './Develop/DevelopBuild.vue';
import DevelopDetails from './Develop/DevelopDetails.vue';
import DevelopPreview from './Develop/DevelopPreview.vue';
import DevelopSetup from './Develop/DevelopSetup.vue';
import RunDetails from './Run/RunDetails.vue';
import RunPreview from './Run/RunPreview.vue';
import RunSetup from './Run/RunSetup.vue';
import PubHero from './PubHero.vue';
import PublisherFormWrapper from './PublisherFormWrapper.vue';

const { eventBus } = useEventBus();

const props = defineProps<{
  publisherID: string;
  appID?: string;
  versionID?: string;
  backRoute?: string;
}>();
const router = useRouter();

const showPreview = ref(false);

const pollenStore = usePollenStore();
const { publisherID, appID, versionID } = toRefs(props);
const {
  publisher,
  pubApp: originalPubApp,
  pubAppVersion: originalPubAppVersion,
} = usePublisher(publisherID, appID, versionID);

const editStore = useEditStore();
const { pubApp, pubAppVersion, manifest, appLogo, appBackground } = storeToRefs(editStore);
const { reset, createSlug } = editStore;

const params = getAllQueryParams();
const appType = computed(() => params.get('appType') || pubApp.value?.app_type);

const isClovyrCode = computed(() => appType.value === 'clovyr_code');

const editing = computed(() => !!props.appID);

const submitLabel = computed(() => {
  if (editing.value) {
    return 'Save';
  }
  return 'Create';
});

const schema = {
  name: (val) => {
    if (!(val && val.length)) {
      return 'You must enter an application name.';
    }
    return true;
  },
  // 'pubApp.description': (val) => {
  //   if (!(val && val.length)) {
  //     return 'You must enter a description.';
  //   }
  //   return true;
  // },
  BaseImage: (val) => {
    if (!isClovyrCode.value) {
      return true;
    }
    if (!(val && val.length)) {
      return 'You must select a base image.';
    }
    return true;
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function valuesToApp(app: PublisherApp, version: PublisherAppVersion, values: Record<string, any>) {
  app.slug = createSlug(values.name, isClovyrCode.value);
  app.name = values.name;

  version.description = values.pubApp?.description;
  version.logo_url = values.logo_url;
  if (isClovyrCode.value) {
    version.base_image = values.BaseImage;
    version.build_script = values.BuildScript;
    version.init_script = values.InitScript;
    version.example_repo = values.example_repo;
  }

  // copy into manifest
  manifest.value.metadata.id = app.slug;
  manifest.value.metadata.name = app.name;
  version.manifest = yaml.stringify(manifest.value);
}

if (editing.value) {
  watchEffect(() => {
    if (pubApp.value?.name) {
      const slug = createSlug(pubApp.value.name, isClovyrCode.value);
      manifest.value.metadata.name = pubApp.value.name;
      manifest.value.metadata.id = slug;
      pubApp.value.slug = slug;
    }
    if (isClovyrCode.value) {
      manifest.value.metadata.description = pubAppVersion.value?.description || '';
      manifest.value.metadata.logoUrl = pubAppVersion.value?.logo_url;
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function doEdit(values: Record<string, any>) {
  try {
    const app: PublisherApp = deepCopy(toRaw(pubApp.value));
    const version: PublisherAppVersion = deepCopy(toRaw(pubAppVersion.value));
    valuesToApp(app, version, values);

    await new PublisherAPI().updateApp(app);
    await new PublisherAPI().updateAppVersion({ publisherID: publisherID.value, ...version });
    // Success toast
    eventBus.emit('toast:show', {
      message: 'Your changes have been saved.',
      type: 'success',
      duration: 3000,
      data: values,
    });

    if (app.slug !== props.appID) {
      await router.replace({
        name: 'PublisherAppsVersionEdit',
        params: { publisherID: publisherID.value, appID: app.slug, versionID: version.id },
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    // Error toast
    eventBus.emit('toast:show', {
      message: 'Your changes could not be saved. Please try again.',
      type: 'error',
      duration: 3000,
      data: values,
    });
  }
}

async function doCreate(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>,
): Promise<void | NavigationFailure | undefined> {
  // create
  const app: PublisherApp = deepCopy(toRaw(pubApp.value));
  const version: PublisherAppVersion = deepCopy(toRaw(pubAppVersion.value));
  valuesToApp(app, version, values);
  app.publisher_id = publisher.value!.id;
  app.app_type = appType.value as 'clovyr_code' | 'custom';
  app.version = version;
  try {
    const newPubApp = await new PublisherAPI().createApp(app);
    await pollenStore.pollen.loadPublisherData();
    // Success toast
    eventBus.emit('toast:show', {
      message: 'Your app has been created.',
      type: 'success',
      duration: 3000,
      data: values,
    });

    if (newPubApp.app_type === 'custom') {
      await router.replace({
        name: 'Publisher',
        params: { publisherID: publisherID.value },
      });
    }
    await router.replace({
      name: 'PublisherAppsVersionEdit',
      params: { publisherID: publisherID.value, appID: app.slug, versionID: newPubApp.version.id },
    });
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    eventBus.emit('toast:show', {
      message: 'Your app could not be created. Please try again.',
      type: 'error',
      duration: 3000,
      data: values,
    });
  }
}

const onSubmit = async (values) => {
  if (editing.value) {
    return doEdit(values);
  }
  return doCreate(values);
};

const handlePortalClick = async () => {
  return router.push({
    name: 'Publisher',
    params: { publisherID: props.publisherID },
  });
};

const query = getAllQueryParams();
const backLabel = query.get('back');

const handleBackClick = async () => {
  if (editing.value) {
    switch (backLabel) {
      case 'versions':
        return router.push({
          name: 'PublisherAppVersions',
          params: { publisherID: props.publisherID, appID: props.appID },
        });
      case 'app':
        return router.push({
          name: 'PublisherApp',
          params: { publisherID: props.publisherID, appID: props.appID },
        });
      default:
        break;
    }
  }

  // default back to publisher
  return router.push({
    name: 'Publisher',
    params: { publisherID: props.publisherID },
  });
};

const backButtonLabel = computed(() => {
  if (!editing.value) return 'back';
  return backLabel === 'versions' ? 'back to versions' : 'back to app';
});

onMounted(async () => {
  if (originalPubApp.value && originalPubAppVersion.value) {
    // update the app we're editing
    pubApp.value = originalPubApp.value;
    pubAppVersion.value = originalPubAppVersion.value;
    if (!isClovyrCode.value) {
      if (pubAppVersion.value?.manifest) {
        manifest.value = yaml.parse(pubAppVersion.value.manifest) as Manifest;
      } else if (pubApp.value?.id && manifest.value) {
        manifest.value.metadata.id = pubApp.value.id;
      }
      if (manifest.value) {
        manifest.value.metadata.website ||= {} as Website;
      }
    }
  } else {
    // new app, reset state
    reset(isClovyrCode.value);
  }
});

const setupTabActive = ref(true);
const profileTabActive = ref(false);
const handleTabClick = () => {
  setupTabActive.value = !setupTabActive.value;
  profileTabActive.value = !profileTabActive.value;
};

const classNames = computed(() => {
  return [
    profileTabActive.value && 'profile-tab',
    setupTabActive.value && 'setup-tab',
    isClovyrCode.value && 'code-app',
    !isClovyrCode.value && 'run-app',
  ];
});

const togglePreview = () => {
  showPreview.value = !showPreview.value;
};
</script>

<template>
  <!-- App header -->
  <!--app-detail section is also being used in PublisherApp; need to abstract to its own component-->
  <div>
    <PubHero
      :appLogo="appLogo"
      :appBackground="appBackground"
      :app="pubApp"
      @portal-click="handlePortalClick"
    >
      <template v-slot:buttons>
        <Button @click="handleBackClick">{{ backButtonLabel }}</Button>
      </template>
    </PubHero>
  </div>

  <div>
    <div class="container" :class="classNames">
      <div class="section grid__col-sm-11">
        <div
          :class="{
            'no-preview': !showPreview,
            'preview-left': showPreview,
          }"
        >
          <div class="publisher-app">
            <!-- Preview button -->
            <div class="top-preview">
              <div class="show-preview" :class="showPreview ? 'hide' : 'show'">
                <Button
                  :label="showPreview ? 'hide' : 'live preview'"
                  translucent
                  class="dash-button"
                  @click="showPreview = !showPreview"
                  :icon-position="showPreview ? IconPosition.Left : IconPosition.Right"
                >
                  <ArrowForwardsIcon v-if="!showPreview" />
                  <ArrowBackIcon v-else />
                </Button>
              </div>
            </div>

            <!-- Tabs -->
            <div class="tab-group">
              <button
                @click="handleTabClick"
                :disabled="setupTabActive"
                :class="setupTabActive ? 'active' : ''"
                class="setup"
              >
                <ImageMedia :filename="GearIconWhite" v-if="setupTabActive" />
                <ImageMedia :filename="GearIconGray" v-else />
                <h4>Settings</h4>
              </button>
              <button
                @click="handleTabClick"
                :disabled="profileTabActive"
                :class="profileTabActive ? 'active' : ''"
                class="profile"
              >
                <ImageMedia :filename="SparkleIconWhite" v-if="profileTabActive" />
                <ImageMedia :filename="SparkleIconGray" v-else />
                <h4>Details</h4>
              </button>
            </div>
            <!-- Forms -->
            <PublisherFormWrapper
              :submit-label="submitLabel"
              :validation-schema="schema"
              keep-values
              @submit="onSubmit"
              :profileTabActive="profileTabActive"
              :showPreview="showPreview"
              @toggle-preview="togglePreview"
            >
              <template v-slot:fields>
                <div class="row" v-if="setupTabActive">
                  <div class="column">
                    <FormField name="name" label="Name:*" v-model="pubApp.name">
                      <template #tooltip>
                        The name of your app. This will be displayed in the app store.
                      </template>
                    </FormField>
                  </div>
                  <!-- Clovyr Code Setup tab -->
                  <DevelopSetup v-if="isClovyrCode" />

                  <!-- Run App Setup tab -->
                  <RunSetup v-else class="run-setup" />
                </div>

                <!-- Profile/metadata details -->
                <div v-else class="app-form">
                  <DevelopDetails v-if="isClovyrCode" />
                  <RunDetails v-else />
                </div>

                <!-- Bottom preview button -->
              </template>
            </PublisherFormWrapper>
            <div class="banner">
              <Banner status="info" v-if="!editing"
                >You will receive an email with a direct link to your new app after it is created;
                it will not be added directly to the Garden page.</Banner
              >
            </div>

            <!-- Code specific: build action & output modules -->
            <DevelopBuild
              v-if="setupTabActive && isClovyrCode && editing"
              :publisherID="publisherID"
            />
          </div>
        </div>

        <!-- Previews -->
        <div v-if="showPreview" class="preview-right">
          <DevelopPreview v-if="isClovyrCode && pubApp?.slug" />
          <RunPreview v-if="!isClovyrCode" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* General Styles */
.app {
  overflow: hidden;
}

/* Header Styles */
.app-detail {
  position: relative;

  :deep(.hero) {
    .hero__inner {
      align-items: flex-start;
      flex-direction: column-reverse;
      margin-bottom: 5rem;

      @include media-breakpoint-up(md) {
        padding-top: 5rem;
      }

      .hero__right {
        align-self: flex-end;
      }

      .hero__left {
        display: flex;
        margin-top: 2rem;

        @include media-breakpoint-down(md) {
          height: space(13);
        }

        .app-icon {
          display: block;

          .image-media {
            padding: 1rem;
          }
        }
      }

      .hero__top {
        margin-right: space(3);
      }

      .hero__middle {
        margin-bottom: 0;

        .hero__heading {
          margin-top: 2rem;

          @include media-breakpoint-up(md) {
            margin-top: 1.5rem;
          }

          @include media-breakpoint-up(xl) {
            margin-top: 0.5rem;
          }

          .app-type {
            letter-spacing: 0.03em;
            margin-bottom: space(0.5);
            font-size: 13px;

            @include media-breakpoint-up(md) {
              margin-left: space(0.75);
            }
          }
        }
      }
    }
  }

  .app-background-gradient {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: space(60);
    background: linear-gradient(180deg, rgb(20 20 20 / 80%) 0%, color(negative) 100%);
  }

  .app-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-index);
    width: 100%;
    height: space(60);
    background: var(--bg-url);
    @include background-image;
  }

  .app-logo-detail {
    background-color: transparent;
    border-radius: 50px;
  }
}

/* Layout Styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-bottom: 5rem;

  @include media-breakpoint-up(xxl) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(xxl) {
      flex-direction: row;
    }
  }

  .show-preview {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2rem;

    &.hide svg {
      margin-right: space(1);
    }

    &.show svg {
      margin-left: space(1);
    }

    .button.live-preview {
      flex-basis: 100%;
    }

    .button.dash-button {
      margin-right: 2rem;
    }
  }

  .top-preview {
    margin-bottom: 1.5rem;

    .show-preview {
      margin-top: 0;

      @include media-breakpoint-only(xs) {
        margin-bottom: 1rem;
      }

      .button {
        margin-right: 0;
      }
    }
  }

  .tab-group {
    display: flex;
    margin-left: 0.5rem;

    button {
      display: flex;
      margin: 0 1rem 2rem 0;
    }

    h4 {
      margin: 0 0 0 0.5rem;
    }

    .active {
      border-bottom: 1.5px solid white;
      padding-bottom: 0.5rem;
    }
  }

  .break {
    border-top: 1px solid #847398;
    margin: 1rem 0;
    padding: 0.5rem;
  }

  /* Preview Styles */
  .no-preview {
    width: col-width(12);
    margin-bottom: 1rem;

    .double-input {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(xxl) {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
      }

      .column {
        width: col-width(12);

        @include media-breakpoint-up(xxl) {
          width: col-width(5.5);
          margin-right: 1rem;
          flex-basis: 50%;
        }
      }
    }
  }

  .preview-left {
    width: 100%;

    :deep(.double-input) {
      flex-direction: column;

      @include media-breakpoint-only(xl) {
        flex-direction: row;
      }

      .column {
        margin-right: 0;

        @include media-breakpoint-only(xl) {
          margin-right: 1rem;
        }
      }
    }
  }

  .preview-right {
    width: 100%;
  }

  /* Component-Specific Styles */
  .publisher-app {
    background-color: #41355b;
    border-radius: 16px;
    padding: 2rem 1.5rem;

    .title {
      padding-bottom: 2rem;
    }

    .banner {
      width: 100%;
      display: flex;
      justify-content: center;
      :deep(.banner-alert) {
        width: fit-content;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        .content-wrapper {
          font-size: 14px;

          @include media-breakpoint-up(md) {
            font-size: unset;
          }
        }
      }
    }
  }

  .row {
    margin-bottom: space(2);
  }

  /* Form Styles */
  :deep(.textinput-block__input),
  :deep(.selectinput-block__input),
  :deep(.textarea-block__input) {
    background-color: #211a33;
    padding-left: 1.5rem;
  }

  :deep(.textinput-block__label),
  :deep(.selectinput-block__label),
  :deep(.textarea-block__label) {
    padding-left: 1.5rem !important;
  }

  :deep(.selectinput-block__label-holder),
  :deep(.textarea-block__label-holder) {
    margin-bottom: space(0.5);
    display: flex;

    svg {
      height: 15px;
      width: 15px;
      margin-top: 0.25rem;
      margin-left: 0.25rem;
    }
  }

  :deep(.textarea-block) {
    margin-bottom: space(2);
  }

  :deep(.form__submit) {
    margin-bottom: space(2);
  }

  :deep(.error-message) {
    font-size: 14px;
    color: #ff957d;
    padding-left: space(1);
  }
  .run-setup {
    margin: 2rem 0 0 0;
  }
}

.code-app {
  .section {
    width: col-width(11);
  }
  .no-preview {
    width: 100%;
  }
  .preview-left {
    width: col-width(11);

    @include media-breakpoint-up(xxl) {
      width: 50%;
    }
  }

  .preview-right {
    width: col-width(11);

    @include media-breakpoint-up(xxl) {
      width: 50%;
    }
  }
}
</style>

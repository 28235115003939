<template>
  <div class="instruction-step">
    <div class="step-bullet">{{ stepNumber }}</div>

    <h2 class="instruction-step__heading">
      {{ headingText }}
    </h2>

    <div class="instruction-step__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstructionStep',
  props: {
    headingText: {
      type: String,
      default: '',
    },
    stepNumber: {
      type: Number,
      default: 0,
    },
  },
};
</script>

import { randomBytes, secretbox } from 'tweetnacl';
import * as v from 'valibot';

import { argon2d } from '@clovyr/hash-wasm';

import { APIError } from '../../api/APIError';
import { AuthState, AuthType } from '../../pollen/types';
import { decodeBase32, encodeBase32 } from '../../util/base32';
import { base64ToBytes, bytesToBase64 } from '../../util/base64';
import { type Nostr } from '../nostr';

import type { EncryptedVaultItem } from './api';
import { decryptMonolithicState, KeychainJSONSchema, migrateLocalStorage } from './migrations';
import {
  type Keychain,
  LATEST_KEYCHAIN_VERSION,
  type LockedKeychain,
  type NoSecretKeyPasswordKeychain,
  type NostrKeychain,
  type OpenPasswordKeychain,
  type UserAPI,
  type Vault,
  type VaultAPI,
  type VaultItem,
  type WebStorage,
} from './types';
import { xorKeys } from './util';

export * from './types';

export const ANONYMOUS_ITEM_PREFIX = 'clovyr.anonymousItem/';

export interface StorageMigrator {
  migrateState(state: any): VaultItem[];
}

export function newVault(
  storage: WebStorage,
  userAPI: UserAPI,
  vaultAPI: VaultAPI,
  nostr: Nostr,
  migrator: StorageMigrator
): Vault {
  const env: VaultEnv = {
    anonymousKey: new Uint8Array(0),
    storage,
    userAPI,
    vaultAPI,
    nostr,
    migrator,
  };

  let state: VaultState = {
    ...baseState('uninitialized'),

    async init() {
      migrateLocalStorage(storage);

      const keychainJSON = storage.getItem('clovyr/keychain');
      if (!keychainJSON) {
        env.anonymousKey = openAnonymousKey(storage);
        const items = loadAnonymousItems(storage, env.anonymousKey, migrator);
        return [stateAnonymous(), items];
      }

      return openKeychain(keychainJSON, env);
    },

    authType() {
      throw new Error('uninitialized');
    },

    authState() {
      throw new Error('uninitialized');
    },
  };

  return {
    async init() {
      const [stateFn, items] = await state.init();
      state = stateFn(env);
      return items;
    },

    async signUp(email, password, items) {
      const stateFn = await state.signUp(email, password, items);
      state = stateFn(env);
    },

    async signUpWithNostr(email: string, items) {
      const stateFn = await state.signUpWithNostr(email, items);
      state = stateFn(env);
    },

    async logIn(email, password, secretKey) {
      const [stateFn, items] = await state.logIn(email, password, secretKey);
      state = stateFn(env);
      return items;
    },

    async logInWithNostr() {
      const [stateFn, items] = await state.logInWithNostr();
      state = stateFn(env);
      return items;
    },

    async lock() {
      const stateFn = await state.lock();
      state = stateFn(env);
    },

    async unlock(password) {
      const [stateFn, items] = await state.unlock(password);
      state = stateFn(env);
      return items;
    },

    async addSecretKey(secretKey) {
      const [stateFn, items] = await state.addSecretKey(secretKey);
      state = stateFn(env);
      return items;
    },

    async setKeychain(keychainJSON) {
      const [stateFn, items] = await openKeychain(keychainJSON, env);
      state = stateFn(env);
      return items;
    },

    async putItem(item) {
      await state.putItem(item);
    },

    async deleteItem(itemID) {
      await state.deleteItem(itemID);
    },

    async shareItem(itemID, extraParams) {
      return state.shareItem(itemID, extraParams);
    },

    async acceptShare(itemID, shareKey) {
      return state.acceptShare(itemID, shareKey);
    },

    async items() {
      return state.items();
    },

    secretKey() {
      return state.secretKey();
    },

    email() {
      return state.email();
    },

    authType() {
      return state.authType();
    },

    authState() {
      return state.authState();
    },
  };
}

interface VaultState {
  init(): Promise<[StateFunc, VaultItem[]]>;
  signUp(email: string, password: string, items: VaultItem[]): Promise<StateFunc>;
  signUpWithNostr(email: string, items: VaultItem[]): Promise<StateFunc>;
  logIn(email: string, password: string, secretKey?: string): Promise<[StateFunc, VaultItem[]]>;
  logInWithNostr(): Promise<[StateFunc, VaultItem[]]>;
  lock(): Promise<StateFunc>;
  unlock(password: string): Promise<[StateFunc, VaultItem[]]>;
  addSecretKey(secretKey: string): Promise<[StateFunc, VaultItem[]]>;
  putItem(item: VaultItem): Promise<void>;
  deleteItem(itemID: string): Promise<void>;
  shareItem(itemID: string, extraParams: string): Promise<string>;
  acceptShare(itemID: string, shareKey: string): Promise<VaultItem[]>;
  items(): Promise<VaultItem[]>;
  secretKey(): string | undefined;
  email(): string | undefined;
  authType(): AuthType;
  authState(): AuthState;
}

interface VaultEnv {
  anonymousKey: Uint8Array;
  storage: WebStorage;
  userAPI: UserAPI;
  nostr: Nostr;
  vaultAPI: VaultAPI;
  migrator: StorageMigrator;
}

type StateFunc = (env: VaultEnv) => VaultState;

function baseState(stateName: string) {
  const invalidOp = (op) => () => {
    throw new Error(`invalid operation: cannot ${op} in in state ${stateName}`);
  };
  return {
    init: invalidOp('init'),
    signUp: invalidOp('signUp'),
    signUpWithNostr: invalidOp('signUpWithNostr'),
    logIn: invalidOp('logIn'),
    logInWithNostr: invalidOp('logInWithNostr'),
    lock: invalidOp('lock'),
    unlock: invalidOp('unlock'),
    addSecretKey: invalidOp('addSecretKey'),
    putItem: invalidOp('putItem'),
    deleteItem: invalidOp('deleteItem'),
    shareItem: invalidOp('shareItem'),
    acceptShare: invalidOp('acceptShare'),
    items: invalidOp('items'),
    secretKey: () => undefined,
    email: () => undefined,
  };
}

interface ItemKeys {
  [key: string]: Uint8Array;
}

function stateAnonymous(): StateFunc {
  return ({ nostr, storage, userAPI, vaultAPI, anonymousKey, migrator }) => ({
    ...baseState('anonymous'),

    async signUp(email, password, items) {
      const secretKey = randomBytes(32);
      const salt = randomBytes(32);
      const passwordKey = await derivePasswordKey(password, salt);

      await userAPI.signUp({
        authType: 'password',
        salt: bytesToBase64(salt),
        password,
        email,
      });

      const keychain: OpenPasswordKeychain = {
        state: 'passwordOpen',
        passwordKey,
        secretKey,
        email,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(keychain));

      const auk = xorKeys(passwordKey, secretKey);

      const itemKeys = await createItems(vaultAPI, items, auk);

      // remove anonymous items and key (no longer needed)
      Object.keys(itemKeys).forEach((key) => {
        storage.removeItem(ANONYMOUS_ITEM_PREFIX + key);
      });
      storage.removeItem('clovyr/anonymous-key');

      return stateOpenRemote(keychain, itemKeys);
    },

    async signUpWithNostr(email: string, items: VaultItem[]) {
      // TODO: add auth challenge tag
      const authEvent = await nostr.signEvent({
        created_at: Math.floor(Date.now() / 1000),
        kind: 22242,
        tags: [],
        content: JSON.stringify({
          // TODO: scope this to the current domain
          aud: 'clovyr.app',
        }),
      });

      const auk = randomBytes(32);

      const pubkey = await nostr.getPublicKey();
      const encryptedAuk = await nostr.nip44.encrypt(pubkey, bytesToBase64(auk));

      await userAPI.signUp({
        authType: 'nostr',
        authEvent,
        encryptedAuk,
        email,
      });

      const newKeychain: NostrKeychain = {
        state: 'nostr',
        auk,
        pubkey,
        email,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(newKeychain));

      const itemKeys = await createItems(vaultAPI, items, auk);
      return stateNostr(newKeychain, itemKeys);
    },

    async logIn(email, password, secretKey) {
      const { salt } = await userAPI.authChallenge({ email });
      if (!salt) {
        throw new Error('no salt returned by server and no salt in local keychain');
      }

      const { encryptedState } = await userAPI.signIn({
        email,
        password,
      });

      const saltBytes = base64ToBytes(salt);
      const passwordKey = await derivePasswordKey(password, saltBytes);

      if (!secretKey) {
        const keychain: NoSecretKeyPasswordKeychain = {
          state: 'passwordNoSecretKey',
          email,
          passwordKey,
        };

        storage.setItem('clovyr/keychain', encodeKeychain(keychain));
        return [statePasswordNoSecretKey(keychain), []];
      }

      const secretKeyBytes = decodeBase32(secretKey);
      const auk = xorKeys(secretKeyBytes, passwordKey);

      let items: VaultItem[];
      let itemKeys: ItemKeys;
      if (encryptedState) {
        [items, itemKeys] = await migrateMonolithicState(
          encryptedState,
          auk,
          userAPI,
          vaultAPI,
          migrator
        );
      } else {
        [items, itemKeys] = await getVaultItems(vaultAPI, auk, email);
      }

      const keychain: OpenPasswordKeychain = {
        state: 'passwordOpen',
        email,
        secretKey: secretKeyBytes,
        passwordKey,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(keychain));

      return [stateOpenRemote(keychain, itemKeys), items];
    },

    async logInWithNostr() {
      const authEvent = await nostr.signEvent({
        created_at: Math.floor(Date.now() / 1000),
        kind: 22242,
        tags: [],
        content: JSON.stringify({
          aud: 'clovyr.app',
        }),
      });

      const pubkey = await nostr.getPublicKey();
      const { email, encryptedAuk } = await userAPI.signInWithNostr({
        authEvent,
      });

      const auk = base64ToBytes(await nostr.nip44.decrypt(pubkey, encryptedAuk));
      const [items, itemKeys] = await getVaultItems(vaultAPI, auk, email);

      const keychain: NostrKeychain = {
        state: 'nostr',
        pubkey,
        auk,
        email,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(keychain));

      return [stateNostr(keychain, itemKeys), items];
    },

    async putItem(item) {
      putAnonymousItem(storage, anonymousKey, item);
    },

    async deleteItem(itemID) {
      storage.removeItem(ANONYMOUS_ITEM_PREFIX + itemID);
    },

    authType() {
      return AuthType.Anonymous;
    },

    authState() {
      return AuthState.LoggedOut;
    },

    async items() {
      return loadAnonymousItems(storage, anonymousKey, migrator);
    },
  });
}

function putAnonymousItem(storage: WebStorage, anonymousKey: Uint8Array, item: VaultItem) {
  const data = new TextEncoder().encode(item.data);
  const nonce = randomBytes(24);

  const itemJSON = JSON.stringify({
    data: bytesToBase64(secretbox(data, nonce, anonymousKey)),
    nonce: bytesToBase64(nonce),
  });

  storage.setItem(ANONYMOUS_ITEM_PREFIX + item.id, itemJSON);
}

function statePasswordNoSecretKey(keychain: NoSecretKeyPasswordKeychain): StateFunc {
  return ({ storage, vaultAPI, userAPI, migrator }) => ({
    ...baseState('passwordNoSecretKey'),

    async addSecretKey(secretKey) {
      const secretKeyBytes = decodeBase32(secretKey);
      const auk = xorKeys(keychain.passwordKey, secretKeyBytes);

      const readStateResponse = await userAPI.readState(keychain.email);
      // @ts-expect-error TODO: fix when we have better API err typing
      if (readStateResponse.code === 403) {
        throw new Error('not authorized');
      }
      let items: VaultItem[];
      let itemKeys: ItemKeys;
      if (readStateResponse.encryptedState) {
        [items, itemKeys] = await migrateMonolithicState(
          readStateResponse.encryptedState,
          auk,
          userAPI,
          vaultAPI,
          migrator
        );
      } else {
        [items, itemKeys] = await getVaultItems(vaultAPI, auk, keychain.email);
      }

      const newKeychain: OpenPasswordKeychain = {
        state: 'passwordOpen',
        email: keychain.email,
        secretKey: secretKeyBytes,
        passwordKey: keychain.passwordKey,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(newKeychain));

      return [stateOpenRemote(newKeychain, itemKeys), items];
    },

    email() {
      return keychain.email;
    },

    authType() {
      return AuthType.Password;
    },

    authState() {
      return AuthState.LoggedInWithoutSecretKey;
    },
  });
}

function loadAnonymousItems(
  storage: WebStorage,
  anonymousKey: Uint8Array,
  migrator: StorageMigrator
): VaultItem[] {
  const oldAnonState = storage.getItem('clovyr/anonymous-state');
  if (oldAnonState) {
    const state = decryptMonolithicState(oldAnonState, anonymousKey);
    const items = migrator.migrateState(state);
    items.forEach((item) => putAnonymousItem(storage, anonymousKey, item));
    storage.removeItem('clovyr/anonymous-state');
    return items;
  }

  const items: VaultItem[] = [];
  for (let i = 0; i < storage.length; i++) {
    const key = storage.key(i);
    if (!key) {
      throw new Error('unreachable: out of bounds');
    }
    if (!key.startsWith(ANONYMOUS_ITEM_PREFIX)) {
      continue;
    }

    const itemJSON = storage.getItem(key);
    if (!itemJSON) {
      throw new Error('unreachable: no item found');
    }

    const obj = JSON.parse(itemJSON);
    const id = key.slice(ANONYMOUS_ITEM_PREFIX.length);
    const encData = base64ToBytes(obj.data);
    const nonce = base64ToBytes(obj.nonce);

    const data = secretbox.open(encData, nonce, anonymousKey);
    if (!data) {
      throw new Error('failed to decrypt anonymous vault item');
    }
    items.push({
      id,
      data: new TextDecoder().decode(data),
    });
  }
  return items;
}

function stateNostr(keychain: NostrKeychain, itemKeys: ItemKeys): StateFunc {
  return ({ vaultAPI }) => ({
    ...baseState('nostr'),

    async putItem(item) {
      return putVaultItem(vaultAPI, itemKeys, keychain.auk, item);
    },

    async deleteItem(itemID) {
      await vaultAPI.deleteItem(itemID);
      delete itemKeys[itemID];
    },

    async items() {
      const [items, newItemKeys] = await getVaultItems(vaultAPI, keychain.auk, keychain.email);
      itemKeys = newItemKeys;
      return items;
    },

    authType() {
      return AuthType.Nostr;
    },

    async shareItem(itemID, extraParams) {
      return shareItem(vaultAPI, keychain.auk, itemID, itemKeys, extraParams);
    },

    async acceptShare(itemID: string, shareSecret: string) {
      const [items, newItemKeys] = await acceptShare(
        vaultAPI,
        keychain.auk,
        itemID,
        shareSecret,
        keychain.email
      );
      itemKeys = newItemKeys;
      return items;
    },

    authState() {
      return AuthState.LoggedIn;
    },

    email() {
      return keychain.email;
    },
  });
}

function stateOpenRemote(keychain: OpenPasswordKeychain, itemKeys: ItemKeys): StateFunc {
  const auk = xorKeys(keychain.secretKey, keychain.passwordKey);
  return ({ storage, vaultAPI, userAPI }) => ({
    ...baseState('openRemote'),

    async putItem(item) {
      return putVaultItem(vaultAPI, itemKeys, auk, item);
    },

    async deleteItem(itemID) {
      await vaultAPI.deleteItem(itemID);
      delete itemKeys[itemID];
    },

    async items() {
      const [items, newItemKeys] = await getVaultItems(vaultAPI, auk, keychain.email);
      itemKeys = newItemKeys;
      return items;
    },

    async lock() {
      await userAPI.signOut();
      const newKeychain: LockedKeychain = {
        state: 'passwordLocked',
        secretKey: keychain.secretKey,
        email: keychain.email,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(newKeychain));
      return stateLocked(newKeychain);
    },

    async shareItem(itemID, extraParams) {
      return shareItem(vaultAPI, auk, itemID, itemKeys, extraParams);
    },

    async acceptShare(itemID: string, shareSecret: string) {
      const [items, newItemKeys] = await acceptShare(
        vaultAPI,
        auk,
        itemID,
        shareSecret,
        keychain.email
      );
      itemKeys = newItemKeys;
      return items;
    },

    secretKey() {
      return encodeBase32(keychain.secretKey);
    },

    authType() {
      return AuthType.Password;
    },

    authState() {
      return AuthState.LoggedIn;
    },

    email() {
      return keychain.email;
    },
  });
}

function stateLocked(keychain: LockedKeychain): StateFunc {
  return ({ storage, vaultAPI, userAPI, migrator }) => ({
    ...baseState('locked'),

    async unlock(password) {
      const { salt } = await userAPI.authChallenge({ email: keychain.email });
      if (!salt) {
        throw new Error('no salt returned by server and no salt in local keychain');
      }
      const saltBytes = base64ToBytes(salt);

      const { encryptedState } = await userAPI.signIn({
        email: keychain.email,
        password,
      });

      const passwordKey = await derivePasswordKey(password, saltBytes);
      const auk = xorKeys(passwordKey, keychain.secretKey);

      let items: VaultItem[];
      let itemKeys: ItemKeys;
      if (encryptedState) {
        [items, itemKeys] = await migrateMonolithicState(
          encryptedState,
          auk,
          userAPI,
          vaultAPI,
          migrator
        );
      } else {
        [items, itemKeys] = await getVaultItems(vaultAPI, auk, keychain.email);
      }

      const newKeychain: OpenPasswordKeychain = {
        state: 'passwordOpen',
        secretKey: keychain.secretKey,
        email: keychain.email,
        passwordKey,
      };
      storage.setItem('clovyr/keychain', encodeKeychain(newKeychain));

      return [stateOpenRemote(newKeychain, itemKeys), items];
    },

    email() {
      return keychain.email;
    },

    authType() {
      return AuthType.Password;
    },

    authState() {
      return AuthState.Locked;
    },
  });
}

function decryptVaultItem(auk: Uint8Array, encItem: EncryptedVaultItem): DecryptItemResult {
  const encData = base64ToBytes(encItem.encData);
  const nonce = base64ToBytes(encItem.encDataNonce);
  const encKey = base64ToBytes(encItem.encItemKey);
  const keyNonce = base64ToBytes(encItem.encItemKeyNonce);

  const key = secretbox.open(encKey, keyNonce, auk);
  if (!key) {
    throw new Error('failed to decrypt vault item key');
  }
  const data = secretbox.open(encData, nonce, key);
  if (!data) {
    throw new Error('failed to decrypt vault item');
  }

  return {
    item: {
      id: encItem.id,
      data: new TextDecoder().decode(data),
    },
    key,
  };
}

interface DecryptItemResult {
  item: VaultItem;
  key: Uint8Array;
}

function openAnonymousKey(storage: WebStorage): Uint8Array {
  const storedKey = storage.getItem('clovyr/anonymous-key');
  if (storedKey) {
    return base64ToBytes(storedKey);
  }

  const key = randomBytes(32);
  storage.setItem('clovyr/anonymous-key', bytesToBase64(key));
  return key;
}

async function derivePasswordKey(password: string, salt: Uint8Array): Promise<Uint8Array> {
  const normalizedPassword = password.normalize('NFKC');
  return argon2Digest(normalizedPassword, salt);
}

async function argon2Digest(password: string | Uint8Array, salt: Uint8Array): Promise<Uint8Array> {
  // TODO: switch to argon2id
  const key = argon2d({
    password,
    salt,
    parallelism: 1,
    iterations: 1, // time
    memorySize: 16, // 2 ^ 16 == 65536 kibibytes == 64 mebibytes
    hashLength: 32,
    outputType: 'binary',
  });
  return key;
}

function encodeKeychain(keychain: Keychain): string {
  const { state } = keychain;

  const toJSON = (obj: any) =>
    JSON.stringify({
      ...obj,
      state,
      version: LATEST_KEYCHAIN_VERSION,
    });

  if (state === 'nostr') {
    return toJSON({
      pubkey: keychain.pubkey,
      auk: bytesToBase64(keychain.auk),
      email: keychain.email,
    });
  }
  if (state === 'passwordOpen') {
    return toJSON({
      passwordKey: bytesToBase64(keychain.passwordKey),
      secretKey: bytesToBase64(keychain.secretKey),
      email: keychain.email,
    });
  }
  if (state === 'passwordLocked') {
    return toJSON({
      secretKey: bytesToBase64(keychain.secretKey),
      email: keychain.email,
    });
  }
  if (state === 'passwordNoSecretKey') {
    return toJSON({
      email: keychain.email,
      passwordKey: bytesToBase64(keychain.passwordKey),
    });
  }
  throw new Error(`unreachable: unknown keychain state: ${state}`);
}

async function putVaultItem(
  vaultAPI: VaultAPI,
  itemKeys: ItemKeys,
  auk: Uint8Array,
  item: VaultItem
): Promise<void> {
  const data = new TextEncoder().encode(item.data);

  if (itemKeys[item.id]) {
    const key = itemKeys[item.id];
    const nonce = randomBytes(24);
    const encData = secretbox(data, nonce, key);

    await vaultAPI.updateItem({
      id: item.id,
      encDataNonce: bytesToBase64(nonce),
      encData: bytesToBase64(encData),
    });
  } else {
    const key = randomBytes(32);
    const keyNonce = randomBytes(24);
    const nonce = randomBytes(24);
    const encData = secretbox(data, nonce, key);
    const encKey = secretbox(key, keyNonce, auk);

    await vaultAPI.createItem({
      id: item.id,
      encData: bytesToBase64(encData),
      encDataNonce: bytesToBase64(nonce),
      encItemKey: bytesToBase64(encKey),
      encItemKeyNonce: bytesToBase64(keyNonce),
    });
    itemKeys[item.id] = key;
  }
}

async function getVaultItems(
  vaultAPI: VaultAPI,
  auk: Uint8Array,
  email: string
): Promise<[VaultItem[], ItemKeys]> {
  const encItems = await vaultAPI.listItems(email);
  const itemKeys = {};
  const items: VaultItem[] = [];

  for (let i = 0; i < encItems.length; i++) {
    const result = decryptVaultItem(auk, encItems[i]);
    items.push(result.item);
    itemKeys[result.item.id] = result.key;
  }

  return [items, itemKeys];
}

export async function deriveBits(shareKey: Uint8Array, info: string): Promise<Uint8Array> {
  const { subtle } = window.crypto;
  const subtleShareKey = await subtle.importKey('raw', shareKey, 'HKDF', false, ['deriveBits']);

  const shareToken = await subtle.deriveBits(
    {
      name: 'HKDF',
      hash: 'SHA-256',
      salt: new Uint8Array(32),
      info: new TextEncoder().encode(info),
    },
    subtleShareKey,
    256
  );
  return new Uint8Array(shareToken);
}

async function openKeychain(
  keychainJSON: string,
  env: VaultEnv
): Promise<[StateFunc, VaultItem[]]> {
  const { userAPI, vaultAPI, migrator } = env;

  const keychainObj = v.parse(KeychainJSONSchema, JSON.parse(keychainJSON));
  if (keychainObj.state === 'passwordOpen') {
    const keychain: OpenPasswordKeychain = {
      state: 'passwordOpen',
      email: keychainObj.email,
      passwordKey: base64ToBytes(keychainObj.passwordKey),
      secretKey: base64ToBytes(keychainObj.secretKey),
    };
    const auk = xorKeys(keychain.passwordKey, keychain.secretKey);

    try {
      const readStateResponse = await userAPI.readState(keychain.email);
      if (readStateResponse.encryptedState) {
        const [items, itemKeys] = await migrateMonolithicState(
          readStateResponse.encryptedState,
          auk,
          userAPI,
          vaultAPI,
          migrator
        );
        return [stateOpenRemote(keychain, itemKeys), items];
      }

      const [items, itemKeys] = await getVaultItems(vaultAPI, auk, keychain.email);
      return [stateOpenRemote(keychain, itemKeys), items];
    } catch (e) {
      // either userAPI.readState or vaultAPI.listItems can throw from a 403
      // this will happen if the cookie suddenly expired or for some reason did not match (i.e.,
      // represented a different user than the one we know of in our keychain)
      if (e instanceof APIError && e.code === 403) {
        // throw new Error('not authorized');
        // return locked vault
        const newKeychain: LockedKeychain = {
          state: 'passwordLocked',
          secretKey: keychain.secretKey,
          email: keychain.email,
        };
        return [stateLocked(newKeychain), []];
      }
    }
  }
  if (keychainObj.state === 'nostr') {
    const keychain: NostrKeychain = {
      state: 'nostr',
      auk: base64ToBytes(keychainObj.auk),
      pubkey: keychainObj.pubkey,
      email: keychainObj.email,
    };

    // TODO: migrate from local storage

    const [items, itemKeys] = await getVaultItems(vaultAPI, keychain.auk, keychain.email);
    return [stateNostr(keychain, itemKeys), items];
  }
  if (keychainObj.state === 'passwordNoSecretKey') {
    const keychain: NoSecretKeyPasswordKeychain = {
      state: 'passwordNoSecretKey',
      email: keychainObj.email,
      passwordKey: base64ToBytes(keychainObj.passwordKey),
    };
    return [statePasswordNoSecretKey(keychain), []];
  }
  if (keychainObj.state === 'passwordLocked') {
    const keychain: LockedKeychain = {
      state: 'passwordLocked',
      email: keychainObj.email,
      secretKey: base64ToBytes(keychainObj.secretKey),
    };
    return [stateLocked(keychain), []];
  }
  throw new Error(`unreachable: unknown keychain state: ${keychainObj as any}.state`);
}

async function createItems(
  vaultAPI: VaultAPI,
  items: VaultItem[],
  auk: Uint8Array
): Promise<ItemKeys> {
  const itemKeys = {};
  await Promise.all(
    items.map(async (item) => {
      const data = new TextEncoder().encode(item.data);
      const nonce = randomBytes(24);
      const itemKey = randomBytes(32);
      const itemKeyNonce = randomBytes(24);

      const encData = secretbox(data, nonce, itemKey);
      const encItemKey = secretbox(itemKey, itemKeyNonce, auk);

      await vaultAPI.createItem({
        id: item.id,
        encData: bytesToBase64(encData),
        encDataNonce: bytesToBase64(nonce),
        encItemKey: bytesToBase64(encItemKey),
        encItemKeyNonce: bytesToBase64(itemKeyNonce),
      });
      itemKeys[item.id] = itemKey;
    })
  );
  return itemKeys;
}

async function migrateMonolithicState(
  encryptedState: string,
  auk: Uint8Array,
  userAPI: UserAPI,
  vaultAPI: VaultAPI,
  migrator: StorageMigrator
): Promise<[VaultItem[], ItemKeys]> {
  const state = decryptMonolithicState(encryptedState, auk);
  const items = migrator.migrateState(state);
  const itemKeys: ItemKeys = {};
  await Promise.all(items.map((item) => putVaultItem(vaultAPI, itemKeys, auk, item)));
  await userAPI.removeMonolithicState();
  return [items, itemKeys];
}

async function shareItem(
  vaultAPI: VaultAPI,
  auk: Uint8Array,
  itemID: string,
  itemKeys: ItemKeys,
  extraParams: string
): Promise<string> {
  const itemKey = itemKeys[itemID];
  if (!itemKey) {
    throw new Error(`cannot share unknown item: ${itemID}`);
  }

  const shareSecret = randomBytes(32);
  const shareToken = await deriveBits(shareSecret, 'clovyr.share-token');
  const shareKey = await deriveBits(shareSecret, 'clovyr.share-key');

  const itemNonce = randomBytes(24);
  const encItemKey = secretbox(itemKey, itemNonce, shareKey);

  const shareSecretNonce = randomBytes(24);
  const encShareSecret = secretbox(shareSecret, shareSecretNonce, auk);

  const shareTokenBase64 = bytesToBase64(shareToken);
  const resp = await vaultAPI.shareItem({
    id: itemID,
    encShareSecret: bytesToBase64(encShareSecret),
    encShareSecretNonce: bytesToBase64(shareSecretNonce),
    shareToken: shareTokenBase64,
    encItemKey: bytesToBase64(encItemKey),
    encItemKeyNonce: bytesToBase64(itemNonce),
  });

  if (!resp) {
    return `${window.location.origin}/library/accept-share/${itemID}?${extraParams}#${bytesToBase64(shareSecret)}`;
  }

  const existingEncShareKey = base64ToBytes(resp.encShareKey);
  const existingShareNonce = base64ToBytes(resp.encShareKeyNonce);
  const existingShareKey = secretbox.open(existingEncShareKey, existingShareNonce, auk);
  if (!existingShareKey) {
    throw new Error('share link already exists but we cannot decrypt the share secret');
  }

  return `${window.location.origin}/library/accept-share/${itemID}?${extraParams}#${bytesToBase64(existingShareKey)}`;
}

async function acceptShare(
  vaultAPI: VaultAPI,
  auk: Uint8Array,
  itemID: string,
  shareSecret: string,
  email: string
): Promise<[VaultItem[], ItemKeys]> {
  const shareSecretBytes = base64ToBytes(shareSecret);
  const shareToken = bytesToBase64(await deriveBits(shareSecretBytes, 'clovyr.share-token'));
  const shareKey = await deriveBits(shareSecretBytes, 'clovyr.share-key');

  const resp = await vaultAPI.getItemShare({ id: itemID, shareToken });
  const encItemKey = base64ToBytes(resp.encItemKey);
  const itemNonce = base64ToBytes(resp.encItemKeyNonce);
  const itemKey = secretbox.open(encItemKey, itemNonce, shareKey);
  if (!itemKey) {
    throw new Error('cannot accept share: failed to decrypt item key');
  }

  const myItemNonce = randomBytes(24);
  const myEncItemKey = secretbox(itemKey, myItemNonce, auk);

  await vaultAPI.acceptShare({
    id: itemID,
    shareToken,
    encItemKey: bytesToBase64(myEncItemKey),
    encItemKeyNonce: bytesToBase64(myItemNonce),
  });

  // return all items we now have access to (not just the new ones)
  return getVaultItems(vaultAPI, auk, email);
}

import mitt, { type Emitter, type Handler, type WildcardHandler } from 'mitt';

import { HTTPError } from '../http/error';
import { execWithAsyncOutput } from '../remote_exec';
import type { Deployment, PublisherApp, PublisherAppVersion } from '../types';
import type { DockerCredentials } from '../types/DockerCredentials';

import { kcp, kexec, podName, writeFileCmd } from './util';

export type StatusPayload = { status: BuilderStatus };
export type ScriptPayload = { script: string };
export type ErrorPayload = { message: string; body: string };
export type BuildEventPayload = StatusPayload | ScriptPayload | ErrorPayload;

export type BuilderStatus = 'inactive' | 'building' | 'pulling' | 'deploying';
export type BuildEvents = {
  status: StatusPayload;
  script: ScriptPayload;
  error: ErrorPayload;
};

export abstract class Builder {
  private builder: Deployment;

  private status: BuilderStatus = 'inactive';

  private emitter: Emitter<BuildEvents>;

  constructor(d: Deployment) {
    this.builder = d;
    this.emitter = mitt();
  }

  private setStatus(s: BuilderStatus) {
    this.status = s;
    this.emitter.emit('status', { status: s });
  }

  // proxy emitter methods
  on<Key extends keyof BuildEvents>(
    type: Key | '*',
    handler: Handler<BuildEvents[Key]> | WildcardHandler<BuildEvents>
  ): void {
    this.emitter.on(type as Key, handler as Handler<BuildEvents[Key]>);
  }

  off<Key extends keyof BuildEvents>(type: Key | '*', handler?: Handler<BuildEvents[Key]>): void {
    this.emitter.off(type as Key, handler);
  }

  private async execCmd(script: string): Promise<string> {
    const res = await execWithAsyncOutput(this.builder.fqdn!, this.builder.accessList[0], script);
    return res.output;
  }

  protected abstract buildScript(
    appVersion: PublisherAppVersion,
    imageName: string,
    dockerCredentials?: DockerCredentials
  ): string;

  protected abstract runScript(appVersion: PublisherAppVersion, imageName: string): string;

  async reset(app: PublisherApp): Promise<string | null> {
    if (!(this.builder && app)) {
      return null;
    }

    this.setStatus('deploying');
    const script = `
set -eo pipefail
set -x
mkdir -p /opt/clovyr/cicd/test
cd /opt/clovyr/cicd/test

${writeFileCmd(
  'reset.sh',
  `
#!/bin/bash
set -ex
cd /home/clovyr/cicd/test
[[ ! -f docker-compose.yaml ]] && exit 0
docker compose down -v
`
)}

chmod 755 *.sh
${podName}
${kexec('code', 'mkdir -p /home/clovyr/cicd/test')}
${kcp('code', '/opt/clovyr/cicd/test/reset.sh', '/home/clovyr/cicd/test/reset.sh')}
${kexec('code', '/home/clovyr/cicd/test/reset.sh')}
    `;

    this.emitter.emit('script', { script });

    try {
      const output = await this.execCmd(script);
      return output;
    } catch (e) {
      console.error('error running remote command:', e);
      if (e instanceof HTTPError) {
        console.error(e.body);
        this.emitter.emit('error', e);
        throw e; // re-throw to stop running app
      }
    } finally {
      this.setStatus('inactive');
    }
    return null;
  }

  /**
   * Build the application
   *
   * @param app
   * @param baseImage
   * @returns
   */
  async build(
    appVersion: PublisherAppVersion,
    imageName: string,
    dockerCredentials?: DockerCredentials
  ): Promise<string | null> {
    if (this.status !== 'inactive') {
      return null;
    }
    if (!(this.builder && appVersion)) {
      return null;
    }

    this.setStatus('building');

    const script = this.buildScript(appVersion, imageName, dockerCredentials);
    if (!script) {
      // nothing to do
      this.setStatus('inactive');
      return null;
    }

    this.emitter.emit('script', { script });

    try {
      const output = await this.execCmd(script);
      return output;
    } catch (e) {
      console.error('error running remote command:', e);
      if (e instanceof HTTPError) {
        console.error(e.body);
        this.emitter.emit('error', e);
      }
    } finally {
      this.setStatus('inactive');
    }
    return null;
  }

  /**
   * Run the application
   *
   * @param app
   * @returns
   */
  async run(appVersion: PublisherAppVersion, imageName: string): Promise<string | null> {
    if (!(this.builder && appVersion)) {
      return null;
    }

    this.setStatus('deploying');

    const script = this.runScript(appVersion, imageName);

    this.emitter.emit('script', { script });

    try {
      const output = await this.execCmd(script);
      return output;
    } catch (e) {
      console.error('error running remote command:', e);
      if (e instanceof HTTPError) {
        console.error(e.body);
        this.emitter.emit('error', e);
      }
    } finally {
      this.setStatus('inactive');
    }

    return null;
  }
}

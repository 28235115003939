<script setup lang="ts">
import 'prismjs';
import '../../styles/components/blocks/_prism-theme.css'
import Prism from 'prismjs';

const props = defineProps<{
  language: string;
  code: string;
}>();

const html = computed(() => {
  const prismLanguage = Prism.languages[props.language];
  return Prism.highlight(props.code, prismLanguage);
});

const className = computed(() => `language-${props.language}`);
</script>

<template>
  <pre :class="className"><code v-html="html" :class="className" /></pre>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import debounce from 'lodash.debounce';
import { Field } from 'vee-validate';
import yaml from 'yaml';

import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import type { Deployment } from '@clovyr/pollen/manifest';

import FormField from '@/components/blocks/FormField.vue';
import ClovyrEditor from '@/components/forms/ClovyrEditor.vue';
import FieldInfoIcon from '@/components/icons/FieldInfoIcon.vue';
import { useEditStore } from '@/stores/edit_store';

const { pubApp, manifest } = storeToRefs(useEditStore());

const configYAML = ref('');
const features = ref('');
const deploymentYAML = ref('');
const backupYAML = ref('');

watch(
  features,
  debounce(
    (newVal) => {
      if (newVal) {
        manifest.value.metadata.features = newVal.split('\n').map((f) => {
          f = f.trim();
          const m = f.match(/^-\s*/);
          if (m && m.length >= 1) {
            f = f.slice(m[0].length);
          }
          return f;
        });
      } else {
        manifest.value.metadata.features = [];
      }
    },
    100,
    { leading: false, trailing: true },
  ),
);

watch(
  configYAML,
  debounce(
    (newVal) => {
      if (newVal) {
        try {
          manifest.value.config = yaml.parse(newVal);
        } catch (e) {
          console.warn(e);
          manifest.value.config = {};
        }
      } else {
        manifest.value.config = {};
      }
    },
    100,
    { leading: false, trailing: true },
  ),
);

watch(
  deploymentYAML,
  debounce(
    (newVal) => {
      if (newVal) {
        try {
          manifest.value.deployment = yaml.parse(newVal);
        } catch (e) {
          console.warn(e);
          manifest.value.deployment = {} as Deployment;
        }
      } else {
        manifest.value.deployment = {} as Deployment;
      }
    },
    100,
    { leading: false, trailing: true },
  ),
);

watch(
  backupYAML,
  debounce(
    (newVal) => {
      if (newVal) {
        try {
          manifest.value.backup = yaml.parse(newVal);
        } catch (e) {
          console.warn(e);
          manifest.value.backup = {};
        }
      } else {
        manifest.value.backup = {};
      }
    },
    100,
    { leading: false, trailing: true },
  ),
);

onMounted(() => {
  // set initial values
  if (manifest.value.config) {
    configYAML.value = yaml.stringify(manifest.value.config);
  } else {
    configYAML.value = '';
  }

  if (manifest.value.metadata.features?.map) {
    features.value = manifest.value.metadata.features.map((f) => `- ${f}`).join('\n');
  } else {
    features.value = '- ';
  }

  if (manifest.value.deployment) {
    deploymentYAML.value = yaml.stringify(manifest.value.deployment);
  } else {
    deploymentYAML.value = `compute:
  size: small
methods:
  - docker-compose
    `;
  }

  if (manifest.value.backup) {
    backupYAML.value = yaml.stringify(manifest.value.backup);
  } else {
    backupYAML.value = `locations:
# - `;
  }
});
</script>

<template>
  <div class="flex-row">
    <div class="column">
      <FormField name="tagline" label="Tagline:" v-model="manifest.metadata.tagline">
        <template #tooltip>
          What’s the nature of your app? Appears beneath your app’s name.
        </template>
      </FormField>
    </div>
  </div>
  <div class="flex-row double-input">
    <div class="column">
      <FormField
        name="logoUrl"
        label="Logo URL (SVG, PNG, WEBP):"
        v-model="manifest.metadata.logoUrl"
        file-upload
      >
        <template #tooltip>
          Add a brand logo url. Only SVG, PNG, or WEBP formats are supported.
        </template>
      </FormField>
    </div>
    <div class="column">
      <FormField
        name="logoBackgroundColor"
        label="Logo Background Color:"
        v-model="manifest.metadata.logoBackgroundColor"
      >
        <template #tooltip>
          Must be given as a HEX value. Will appear behind your logo wherever it’s present.
        </template>
      </FormField>
    </div>
  </div>
  <div class="flex-row double-input">
    <div class="column">
      <FormField
        name="heroImageUrl"
        label="Product Hero Image URL (SVG, PNG, WEBP):"
        v-model="manifest.metadata.heroImageUrl"
        file-upload
      >
        <template #tooltip>
          This is the image that will appear at the top of your app’s page. It can be anything, but
          is traditionally a screenshot of your app that shows off its best features.
        </template>
      </FormField>
    </div>
    <div class="column">
      <FormField
        name="backgroundImageUrl"
        label="Background Image URL (SVG, PNG, WEBP):"
        v-model="manifest.metadata.backgroundImageUrl"
        file-upload
      >
        <template #tooltip>
          This is the image that will appear as the background of your app’s page. It can be a
          pattern or texture that complements your app’s design.
        </template>
      </FormField>
    </div>
  </div>
  <div class="flex-row">
    <div class="column">
      <FormField name="huginnSays" label="Huginn Says:" v-model="manifest.metadata.huginnSays">
        <template #tooltip>
          This might be a short anecdote about why this app was built or the problem it’s going to
          solve, or how it’s set apart from its peers.
        </template>
      </FormField>
    </div>
  </div>
  <div class="flex-row double-input">
    <div class="column">
      <FormField name="docsUrl" label="Docs URL:" v-model="manifest.metadata.docsUrl">
        <template #tooltip>
          Link to your app’s documentation. This could be a GitHub repo, a Notion page, or a
          dedicated website.
        </template>
      </FormField>
    </div>
    <div class="column">
      <FormField name="website.url" label="Website URL:" v-model="manifest.metadata.website!.url">
        <template #tooltip>
          Link to your app’s website. This could be a GitHub repo, a Notion page, or a dedicated
          website.
        </template>
      </FormField>
    </div>
  </div>
  <div class="flex-row double-input">
    <div class="column">
      <!-- TODO: Version will go way with app updates -->
      <FormField name="version" label="Version:" v-model="manifest.metadata.version">
        <template #tooltip>
          The version of your app. This will be automatically updated when you publish a new
          version.
        </template>
      </FormField>
    </div>
    <div class="column">
      <FormField name="license" label="License:" v-model="manifest.metadata.license">
        <template #tooltip>
          The license under which your app is distributed. This could be MIT, GPL, or any other
          open-source license.
        </template>
      </FormField>
    </div>
  </div>
  <!-- Descrip & Features -->
  <div class="flex-row double-input">
    <div class="col">
      <Field
        type="text"
        name="description"
        v-slot="{ errorMessage }"
        v-model="manifest.metadata.description"
      >
        <div class="textarea-block" :class="{ 'textarea-block--has-error': !!errorMessage }">
          <div class="textarea-block__label-holder">
            <label for="Manifest" class="textarea-block__label">Description:*</label>
            <Tooltip content="Give brief description of your app."><FieldInfoIcon /></Tooltip>
          </div>

          <ClovyrEditor
            class="textarea-block__input"
            language="markdown"
            v-model="manifest.metadata.description"
          />

          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
      </Field>
    </div>
    <div class="col">
      <Field type="text" name="features" v-slot="{ errorMessage }" :model-value="features">
        <div class="textarea-block" :class="{ 'textarea-block--has-error': !!errorMessage }">
          <div class="textarea-block__label-holder">
            <label for="Manifest" class="textarea-block__label">Features:</label>
            <Tooltip content="List the features of your app."><FieldInfoIcon /></Tooltip>
          </div>

          <ClovyrEditor
            class="textarea-block__input"
            language="yaml"
            :filename="'file:///' + pubApp.slug + '/string-array.yaml'"
            v-model="features"
          />

          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
      </Field>
    </div>
  </div>

  <!-- Getting Started -->
  <div class="flex-row double-input">
    <div class="col">
      <Field
        type="text"
        name="gettingStarted"
        v-slot="{ errorMessage }"
        :model-value="manifest.metadata.gettingStarted"
      >
        <div class="textarea-block" :class="{ 'textarea-block--has-error': !!errorMessage }">
          <div class="textarea-block__label-holder">
            <label for="gettingStarted" class="textarea-block__label">Getting Started:</label>
            <Tooltip content="Instructions for getting started with your app."
              ><FieldInfoIcon
            /></Tooltip>
          </div>

          <ClovyrEditor
            class="textarea-block__input"
            language="markdown"
            v-model="manifest.metadata.gettingStarted"
          />

          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
      </Field>
    </div>
    <div class="col">
      <Field type="text" name="deployment" v-slot="{ errorMessage }" :model-value="deploymentYAML">
        <div class="textarea-block" :class="{ 'textarea-block--has-error': !!errorMessage }">
          <div class="textarea-block__label-holder">
            <label for="deployment" class="textarea-block__label">Deployment:</label>
            <Tooltip content="Instructions for deploying your app."><FieldInfoIcon /></Tooltip>
          </div>

          <ClovyrEditor
            class="textarea-block__input"
            language="yaml"
            :filename="'file:///' + pubApp.slug + '/deployment.yaml'"
            v-model="deploymentYAML"
          />

          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
      </Field>
    </div>
  </div>

  <!-- Backup -->
  <div class="flex-row double-input textarea">
    <div class="col">
      <Field type="text" name="backup" v-slot="{ errorMessage }" :model-value="backupYAML">
        <div class="textarea-block" :class="{ 'textarea-block--has-error': !!errorMessage }">
          <div class="textarea-block__label-holder">
            <label for="backup" class="textarea-block__label">Backup:</label>
            <Tooltip content="Instructions for backing up your app."><FieldInfoIcon /></Tooltip>
          </div>

          <ClovyrEditor
            class="textarea-block__input"
            language="yaml"
            :filename="'file:///' + pubApp.slug + '/backup.yaml'"
            v-model="backupYAML"
          />

          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
      </Field>
    </div>
    <div class="col">
      <Field type="text" name="config" v-slot="{ errorMessage }" :model-value="configYAML">
        <div class="textarea-block" :class="{ 'textarea-block--has-error': !!errorMessage }">
          <div class="textarea-block__label-holder">
            <label for="config" class="textarea-block__label">Config:</label>
            <Tooltip content="Instructions for configuring your app."><FieldInfoIcon /></Tooltip>
          </div>

          <ClovyrEditor
            class="textarea-block__input"
            language="yaml"
            :filename="'file:///' + pubApp.slug + '/config.yaml'"
            v-model="configYAML"
          />

          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
      </Field>
    </div>
  </div>
</template>

<style scoped lang="scss">
/* Deep Styles */
//Textinput
:deep(.textinput-block) {
  margin-bottom: unset;
}
:deep(.textinput-block__input) {
  background-color: #211a33;
  padding-left: 20px;
}
:deep(.textinput-block__label) {
  padding-left: space(2) !important;
}

//Selectinput
:deep(.selectinput-block__label) {
  padding-left: space(2) !important;
}
:deep(.selectinput-block__input) {
  background-color: #211a33;
}
:deep(.selectinput-block__label-holder) {
  margin-bottom: space(0.5);
}

//Textarea
:deep(.textarea-block) {
  margin-bottom: 0 !important;
}
:deep(.textarea-block__label) {
  padding-left: space(2) !important;
}
:deep(.textarea-block__label-holder) {
  margin-bottom: space(0.5);
  display: flex;

  svg {
    height: 15px;
    width: 15px;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
  }
}

:deep(.textarea-block__input) {
  background-color: #211a33;
}

:deep(.form__submit) {
  margin-bottom: space(2);
}

//Error message
:deep(.error-message) {
  font-size: 14px;
  color: #ff957d;
  padding-left: space(1);
}

// tooltip
.tooltip-text {
  color: color(grey, primary);
  font-size: 13px;
  margin: 0.25rem 0 0 1.5rem;
}

.column,
.col {
  margin-bottom: 1.5rem;
}
.double-input {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }

  .column {
    flex-basis: 50%;
  }
  .col {
    @include media-breakpoint-up(xl) {
      width: col-width(5.5);
      flex-basis: 100%;
    }
  }

  .column:nth-child(1),
  .col:nth-child(1) {
    @include media-breakpoint-up(xl) {
      margin-right: 1rem;
    }
  }
}
</style>

<script lang="ts" setup>
import type { CSSProperties } from 'vue';

import ImageMedia from '../media/ImageMedia.vue';
import type { Logo, LogoSize } from './AppLogo';

const props = withDefaults(defineProps<{ logo: Logo; size?: LogoSize; pad?: boolean }>(), {
  size: 'medium',
});

const logoBackgroundColor = computed<CSSProperties>((): CSSProperties => {
  if (!props.logo.logoBackgroundColor) {
    return {};
  }
  return { background: props.logo.logoBackgroundColor };
});
</script>

<template>
  <div class="app-icon" :class="`app-icon--${size}`">
    <ImageMedia :filename="logo.logoUrl" :style="logoBackgroundColor" :class="{ pad: pad }" />

    <div v-if="$slots.title" class="app-title">
      <slot name="title" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-icon {
  display: flex;
  align-items: center;

  .app-title {
    margin-left: space(2);
  }

  &--x-small {
    :deep(.image-media) {
      width: space(3);
      height: space(3);
    }
  }

  &--small {
    :deep(.image-media) {
      width: space(6);
      height: space(6);
    }
  }

  &--medium {
    :deep(.image-media) {
      width: space(8);
      height: space(8);
    }
  }

  &--large {
    :deep(.image-media) {
      width: space(10);
      height: space(10);
    }
  }

  :deep(.image-media) {
    @include borders-sphere;

    display: flex;
    align-items: center;
    justify-content: center;

    // padding: space(1);
    overflow: hidden;

    // background-color: color(offwhite);
    // box-shadow: inset 0 0 15px rgb(0 0 0 / 10%);
  }

  .pad {
    padding: space(1);
  }
}
</style>

<template>
  <div :class="CSSClasses" class="page-section">
    <PageDivider
      v-if="dividerTop"
      :class="[!!dividerTopTransform && `page-section__divider--${dividerTopTransform}`]"
      class="page-section__divider page-section__divider--top"
    />

    <div class="page-section__outer">
      <div class="page-section__inner">
        <slot />
      </div>
    </div>

    <div v-if="hasSlot('background') || !!backgroundImageFilename" class="page-section__background">
      <slot v-if="hasSlot('background')" name="background" />

      <ImageMedia v-else-if="!!backgroundImageFilename" :filename="backgroundImageFilename" />
    </div>

    <PageDivider
      v-if="dividerBottom"
      :class="[!!dividerBottomTransform && `page-section__divider--${dividerBottomTransform}`]"
      class="page-section__divider page-section__divider--bottom"
    />
  </div>
</template>

<script>
export default {
  name: 'PageSection',
  props: {
    dividerTop: {
      type: Boolean,
      default: false,
    },
    dividerTopTransform: {
      type: String,
      default: '',
    },
    dividerBottom: {
      type: Boolean,
      default: false,
    },
    dividerBottomTransform: {
      type: String,
      default: '',
    },
    dividersPosition: {
      type: String,
      default: 'outside',
    },
    backgroundImageFilename: {
      type: String,
      default: '',
    },
  },
  computed: {
    CSSClasses() {
      return [
        this.dividerTop && `page-section--divider-top`,
        this.dividerBottom && `page-section--divider-bottom`,
        `page-section--dividers-${this.dividersPosition}`,
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.page-section {
  $self: &;

  position: relative;
  overflow-y: hidden;

  &__inner {
    @include max-content-width;

    padding: vert-space(xxxl) 0;
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .page-section__divider {
    &--top {
      margin-top: -2px;
    }

    &--bottom {
      margin-bottom: -2px;
    }
  }

  &__divider {
    // left: 0;
    // position: absolute;
    // right: 0;
    position: relative;
    z-index: 2;

    &--flip-vertical {
      transform: rotate(180deg);
    }

    &--flip-horizontal {
      transform: scaleX(-1);
    }

    :deep(svg) {
      width: 100%;
    }
  }
}
</style>

import type { ComputeInstanceSize } from '../compute';

export enum DeploymentMethod {
  /**
   * Inline Helm Chart YAML files (i.e., stored directly in the manifest)
   * TODO: rename to ChartInline? Default should be remote
   */
  Chart = 'chart',

  /**
   * Helm Chart installed from a remote repository
   */
  ChartRemote = 'chart-remote',

  /**
   * Kubernetes Operator
   */
  Operator = 'operator',

  /**
   * Manifest implemented using Clovyr Leaf engine (dhall-based)
   */
  Leaf = 'leaf',

  /**
   * Raw Kubernetes files (lower-level than a Chart)
   */
  Kubernetes = 'kubernetes',

  /**
   * Docker Compose
   */
  DockerCompose = 'docker-compose',

  /**
   * WNext Instant Launch
   *
   * Instant launch on WNext is generally available for all applications, by default. This method
   * indicates that only this deployment method is available and that custom deployment in WNext or
   * BYOH is not available for this application. Those options should only be available if one or
   * more other deployment methods are available.
   */
  WNextInstant = 'wnext-instant',
}

/**
 * If multiple deployment methods are available, default to use Docker Compose.
 */
export const DEFAULT_DEPLOYMENT_METHOD = DeploymentMethod.DockerCompose;

/**
 * Map of files and their contents
 */
export type Files = { [k: string]: string };
export type Contents = { [key in DeploymentMethod]?: Files };

/**
 * Available addons for DockerCompose based installations.
 */
export enum DeploymentAddons {
  MailHog = 'mailhog',
}

export type ServiceOptions = {
  /**
   * Port number this service is listening on (for traefik to connect to).
   */
  port: number;

  /**
   * When multiple services are being exposed, one of them must be marked as the primary endpoint
   * for routing via the primary hostname.
   */
  primary?: boolean;
};

/**
 *
 */
export type DockerComposeOptions = {
  /**
   * List of docker networks to attach traefik to so it can route internally to
   * the service without exposing it to the host.
   *
   * Note that the network name can be set in one of two ways in the
   * docker-compose file. If set using the "simple" format like so:
   *
   * networks:
   *   gitea: {}
   *
   * Then the string set in the manifest must be "gitea_gitea" as Docker Compose
   * will automatically prepend the "project" (i.e., the directory name) to the
   * network name separated by an underscore.
   *
   * If empty, will automatically create a new network using the app `id` and
   * add it to each service.
   */
  networks: string[];

  /**
   * Map of exposed services to the port number which the application listens
   * on. This is used for routing http and https traffic to the container via
   * the Traefik reverse-proxy/load balancer.
   *
   * e.g., nextcloud => 80, nodejs => 3000
   */
  services: {
    [key: string]: number | ServiceOptions;
  };

  proxy?: {
    /**
     * Custom traefik routing rules for services.
     *
     * See traefik docs for reference:
     * https://doc.traefik.io/traefik/v2.11/routing/routers/#rule
     *
     * ex:
     * rules:
     *   web: "Host(`web.example.com`)"
     *   api: "Host(`web.example.com`) && PathPrefix(`/api`)"
     */
    rules?: {
      [key: string]: string;
    };
  };
};

export type ComputeOptions = {
  /**
   * Compute instance size. Generic clovyr sizes are mapped to provider-specific
   * sizes in the various implementations.
   *
   * If not given, defaults to 'small'.
   */
  size?: ComputeInstanceSize;
};

/**
 * Options for mapping an additional hostname to a service (container).
 */
export type AdditionalDNSOptions = {
  /**
   * Name of the service/container
   */
  service: string;

  /**
   * Port number this hostname to route to (when using a reverse proxy, i.e., traefik)
   */
  port?: number;

  /**
   * Whether or not to proxy the service (via global reverse proxy, i.e., cloudflare, or local reverse proxy, i.e., traefik)
   */
  proxy?: boolean;

  /**
   * If proxying, the service type to use (not currently used)
   */
  type?: 'http' | 'tcp' | 'udp';

  /**
   * When true, this endpoint will be used as the default URL to open for the service.
   */
  default_url?: boolean;
};

export type TLSOptions = {
  mount?: {
    /**
     * mount let's encrypt provisioned certs at the given path in the service's container
     * e.g., xmpp: /prosody_data/certs
     * would mount the certs at /prosody_data/certs in the 'xmpp' container.
     *
     * *note* this is only supported when dns.proxy === false (explicitly set to false)!
     */
    [serviceName: string]: string;
  };
};

export type DNSOptions = {
  /**
   * Whether or not to proxy primary service endpoint when not using a custom domain, which is never
   * proxied. This should be used for services which require running on ports other than http/s
   * which can safely be proxied via Cloudflare (or other CDN).
   *
   * If not set (undefined), defaults to true.
   */
  proxy?: boolean;

  tls?: TLSOptions;

  /**
   * List of additional DNS hostnames required by this application.
   *
   * For each additional name, a DNS record and corresponding route will be created.
   *
   * Map of hostname to service. Service can be a string of the form 'service[:port]' or an object
   * if more control is needed.
   *
   * Leave service as empty string to simply map the hostname without any setup, if app already
   * takes care of the routing or all additional hosts should point to the main service.
   *
   * Hostname will be added as a prefix to the application's FQDN. For example:
   *
   * additional host: 'foo'
   * fqdn: 'dim-oyster-ppr72c2vhfc75kvzxlti.wbefornext.app'
   * result: 'foo-dim-oyster-ppr72c2vhfc75kvzxlti.wbefornext.app'
   */
  additional_hosts?: { [hostname: string]: string | AdditionalDNSOptions };
};

export type BootstrapOptions = {
  /**
   * When given, this script will run during the server bootstrap process, before the application is
   * deployed. It will always run, regardless of the deployment method or target environment. Care
   * should be taken to support all environments, as needed.
   */
  custom_script?: string;
};

/**
 * Deployment describes the application should be deployed, including available deployment
 * methods/implementations.
 */
export interface Deployment {
  /**
   * Compute requirements for this application.
   */
  compute?: ComputeOptions;

  /**
   * DNS requirements for this application.
   */
  dns?: DNSOptions;

  bootstrap?: BootstrapOptions;

  addons?: {
    [key in DeploymentAddons]: any; // value TBD
  };

  /**
   * List of available deployment methods
   */
  methods: Array<DeploymentMethod>;

  /**
   * For each deployment method, a list of files. This is *not* directly part of
   * the manifest (clovyr.yaml).
   */
  contents?: Contents;

  /**
   * The HTTP path to use to check if the app is healthy. Defaults to `/`.
   */
  checkPath?: string;

  // Deployment method specific options below

  /**
   * Options for customizing a Docker Compose based deployment.
   */
  [DeploymentMethod.DockerCompose]?: DockerComposeOptions;
}

export const useImageAsset = () => {
  // Import all image assets so that we can translate relative paths to absolute
  // * NOTE: This import will be _cached_ in dev, which means adding or renaming
  // * assets requires a restart of the dev server.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const images: Record<string, string | any | undefined> = import.meta.glob(
    '../assets/images/**/*',
    {
      query: '?url',
      eager: true,
    }
  );

  /**
   * Convert relative asset path to an absolute path for serving via either vite dev server or prod
   * url, e.g.:
   *
   * about-hero.png -> /@fs/Users/chetan/work/clovyr/clovyrapp-ui/js/bed/assets/images/about-hero.png
   *
   * @param name
   * @param qualify If given, will prepend the base URL string to the returned URL as needed
   */
  function getURL(name: string, qualify?: string): string | undefined {
    if (!name) {
      return undefined;
    }
    if (name.match(/^https?:\/\//)) {
      // already a fully-qualified url, return it
      return name;
    }
    const filename = Object.keys(images).find((path) => {
      return path.endsWith(name);
    });
    if (filename) {
      let i = images[filename];
      if (!i) {
        return i;
      }
      if (typeof i === 'object' && i.default) {
        i = i.default;
      }
      if (qualify && !(i as string).startsWith('http')) {
        i = `${qualify}${i}`;
      }
      return i as string;
    }
    return undefined;
  }

  return {
    getURL,
  };
};

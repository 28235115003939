<script setup lang="ts">
import SettingOption from '@/components/blocks/SettingOption.vue';
import { useEventBus } from '@/stores/event_bus';
import { ShowAuthForm } from '@/views/Settings/types';

defineProps({
  displayPlainButton: {
    type: Boolean,
  },
  isUserAuthenticated: {
    type: Boolean,
  },
});

const { eventBus } = useEventBus();
</script>

<template>
  <SettingOption
    v-if="!displayPlainButton"
    @signup="eventBus.emit('modal:login:show', { form: ShowAuthForm.Signup })"
    :highlighted="true"
    :buttons="[
      {
        label: 'Sign Up',
        eventName: 'signup',
        reversed: true,
      },
    ]"
  >
    <template #left>
      Want to enable automatic backups and access it from multiple devices, login or create a Clovyr
      Account.
    </template>

    <template #button-content>
      <p>
        Have an account?
        <button
          class="button-inline button-primary"
          @click="eventBus.emit('modal:login:show', { form: ShowAuthForm.Login })"
        >
          Log in
        </button>
      </p>
    </template>
  </SettingOption>

  <div v-if="displayPlainButton">
    <Button
      v-if="!isUserAuthenticated"
      class="button"
      @click="eventBus.emit('modal:login:show', { form: ShowAuthForm.Signup })"
      :reversed="true"
      >Sign up</Button
    >
    <Button
      v-if="isUserAuthenticated"
      class="button"
      @click="eventBus.emit('modal:login:show', { form: ShowAuthForm.Login })"
      >Log in</Button
    >
  </div>
</template>

<script setup lang="ts">
import type { PublisherAppFile } from '@clovyr/pollen';

import DevelopFile from './DevelopFile.vue';
import FilePill from './FilePill.vue';

defineProps<{
  files: PublisherAppFile[];
  dropping: boolean;
}>();

const emit = defineEmits(['delete-file']);

async function onDelete(fileID: string) {
  emit('delete-file', fileID);
}
</script>

<template>
  <div class="list">
    <DevelopFile v-for="file in files" :key="file.id" :file="file" @delete-file="onDelete" />
    <FilePill v-if="dropping" class="dropping">Drop New File</FilePill>
  </div>
</template>

<style scoped lang="scss">
.list {
  display: flex;
  gap: 10px;
}
</style>

import { WNextBase } from '../api/wnext-base';
import { wnextHTTP } from '../http';
import { Invoice } from '../types/Invoice';
import { UserSubscription } from '../types/UserSubscription';
import { UserSubscriptionItem } from '../types/UserSubscriptionItem';

export enum SubscriptionStatus {
  Trial = 'trial',
  PaymentInProgress = 'payment_in_progress',
  UpgradeRequested = 'upgrade_requested',
  Active = 'active',
  CancelRequested = 'cancel_requested',
  Canceled = 'canceled',

  // Maybe?
  Renew = 'renew',
  Expiring = 'expiring',
  Free = 'free',

  // should use
  Expired = 'expired',
}

type AddUserSubscriptionItemReq = {
  subscriptionID: string;
  resourceID: string;
  appSlug: string;
  isTrial: boolean;
};

export type PaymentMethod = 'stripe' | 'opennode';

type CapturePaymentReq = {
  method: PaymentMethod;
  subscriptionID: string;
  subscriptionItemID: string;
};

type CapturePaymentRes = {
  url: string;
};

type PortalSessionRes = CapturePaymentRes;

export type UpgradeSubscriptionItemRes = {
  subscription: UserSubscription;
  subscriptionItem: UserSubscriptionItem;
  invoice?: Invoice;
};

export class SubscriptionAPI extends WNextBase {
  /**
   * Record an application launch
   *
   * @param appSlug
   * @param computeProvider
   * @param dnsProvider
   */
  async recordAppLaunch(
    appSlug: string,
    computeProvider: string,
    dnsProvider: string
  ): Promise<void> {
    void wnextHTTP.post('/m/launch', {
      app_slug: appSlug,
      compute_provider: computeProvider,
      dns_provider: dnsProvider,
    });
  }

  async createSubscription(): Promise<UserSubscription> {
    const res = await this.unwrapRes(this.client.post('/subscriptions', {}));
    return new UserSubscription(res.data.subscription);
  }

  async getSubscription(): Promise<UserSubscription> {
    const res = await this.unwrapRes(this.client.get('/subscriptions'));
    return new UserSubscription(res.data.subscription);
  }

  async listSubscriptionItems(subID: string): Promise<UserSubscriptionItem[]> {
    const res = await this.unwrapRes(this.client.get(`/subscriptions/${subID}/items`));
    if (res.data?.subscriptionItems) {
      return res.data.subscriptionItems.map((item) => new UserSubscriptionItem(item));
    }
    return [];
  }

  async getSubscriptionItem(subID: string, subItemID: string): Promise<UserSubscriptionItem> {
    const res = await this.unwrapRes(this.client.get(`/subscriptions/${subID}/items/${subItemID}`));
    return new UserSubscriptionItem(res.data.subscriptionItem);
  }

  async addItem(req: AddUserSubscriptionItemReq): Promise<UserSubscriptionItem> {
    const res = await this.unwrapRes(
      this.client.post(`/subscriptions/${req.subscriptionID}/items`, req)
    );
    return new UserSubscriptionItem(res.data.subscriptionItem);
  }

  async upgradeSubscriptionItem(
    subID: string,
    subItemID: string
  ): Promise<UpgradeSubscriptionItemRes> {
    const res = await this.unwrapRes(
      this.client.post(`/subscriptions/${subID}/items/${subItemID}/upgrade`, {})
    );
    if (res && res.status === 'success') {
      return {
        subscription: new UserSubscription(res.data.subscription),
        subscriptionItem: new UserSubscriptionItem(res.data.subscriptionItem),
        invoice: new Invoice(res.data.invoice),
      };
    }
    throw new Error(`Failed to upgrade subscription item: ${res.message}`);
  }

  async cancelSubscriptionItem(
    subID: string,
    subItemID: string,
    cancelAt: 'now' | 'end_of_period'
  ): Promise<void> {
    await this.unwrapRes(
      this.client.post(`/subscriptions/${subID}/items/${subItemID}/cancel`, {
        cancelAt,
      })
    );
  }

  /**
   * Create a new checkout session
   */
  async capturePayment(req: CapturePaymentReq): Promise<CapturePaymentRes> {
    const res = await this.unwrapRes(
      this.client.post(
        `/subscriptions/${req.subscriptionID}/items/${req.subscriptionItemID}/checkout`,
        { method: req.method }
      )
    );
    return res.data;
  }

  async createPortalSession(): Promise<PortalSessionRes> {
    const res = await this.unwrapRes(this.client.post(`/payments/invoice_portal`, {}));
    return res.data;
  }
}

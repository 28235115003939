/**
 * Extremely simple Object to Query String encoder implementation.
 *
 * For a more robust impl, may need to use one of the following packages:
 * - qs
 * - query-string
 * - querystring-es3
 *
 * @param obj Object with key/value data
 * @returns Query string
 */
export function toQS(obj?: Object): string {
  if (!obj) {
    return '';
  }
  const s: string[] = [];
  Object.getOwnPropertyNames(obj).forEach((key) => {
    s.push(`${key}=${obj[key]}`);
  });
  if (!s.length) {
    return '';
  }
  return `?${s.join('&')}`;
}

export type Params = {
  [k: string]: string;
};

export function fromQS(qs: string): Params {
  if (!qs) {
    return {};
  }
  return searchParamsToObj(new URLSearchParams(qs));
}

/**
 * All query and hash parameters in a single object.
 *
 * Hash params override query params of the same name.
 *
 * @returns URLSearchParams object with all query params from the current URL
 */
export function getAllQueryParams(): URLSearchParams {
  if (import.meta.env.SSR) {
    // window not avail, use env workaround (see server.mjs)
    return new URLSearchParams(process.env['FASTIFY_URL']);
  }
  const query = new URLSearchParams(window.location.search);
  const hash = window.location.hash ? new URLSearchParams(window.location.hash.substring(1)) : null;
  if (hash) {
    hash.forEach((val, key) => {
      query.append(key, val);
    });
  }
  return query;
}

export function searchParamsToObj(params: URLSearchParams): Params {
  const obj = {};
  params.forEach((val, key) => {
    obj[key] = val;
  });
  return obj;
}

import AWSLogo from '@clovyr/bed/assets/images/aws-logo.svg';
import ClovyrHostingLogo from '@clovyr/bed/assets/images/clovyrhost-icon.svg';
import ClovyrHostingFullLogo from '@clovyr/bed/assets/images/clovyrhost-lockup.svg';
import DigitalOceanLogoSmall from '@clovyr/bed/assets/images/digitalocean-logo.svg';
import LinodeLogoSmall from '@clovyr/bed/assets/images/linode-logo.svg';

import { ComputeInstanceSize } from "../compute";
import type { ComputeProviderInfo } from '../types';
import { ComputeProviderName } from '../types/Host';

export const firstPartyHosts: ComputeProviderInfo[] = [
  {
    id: ComputeProviderName.WNext,
    title: 'Clovyr Hosting',
    description:
      '$PRICE/mo after free trial ends. No credit card or account required for trial. Automatic backup service included.',
    logoUrl: ClovyrHostingLogo,
    fullLogoUrl: ClovyrHostingFullLogo,
    logoBackgroundColor: '#ffffff',
    logoPad: true,
    defaultRegion: 'us-east',
    regions: [
      { name: 'US East', value: 'us-east', flag: 'USA' },
      { name: 'US West', value: 'us-west', flag: 'USA' },
      { name: 'United Kingdom', value: 'uk', flag: 'UK' },
      { name: 'Canada', value: 'canada', flag: 'Canada' },
      { name: 'Singapore', value: 'singapore', flag: 'Singapore' },
      { name: 'India', value: 'india', flag: 'India' },
      { name: 'Germany', value: 'germany', flag: 'Germany' },
      { name: 'Sydney', value: 'sydney', flag: 'Australia' },
    ],
    instanceTypes: [
      { name: ComputeInstanceSize.Micro, value: ComputeInstanceSize.Micro },
      { name: ComputeInstanceSize.Small, value: ComputeInstanceSize.Small },
      { name: ComputeInstanceSize.Medium, value: ComputeInstanceSize.Medium },
      { name: ComputeInstanceSize.Large, value: ComputeInstanceSize.Large },
      { name: ComputeInstanceSize.XLarge, value: ComputeInstanceSize.XLarge },
      { name: ComputeInstanceSize.XLargeAI, value: ComputeInstanceSize.XLargeAI },
    ],
  },
  // {
  //   id: 1,
  //   name: 'custom',
  //   title: 'Custom Host',
  //   description: 'Setup requires manual provisioning the required amount of space and command line use to invoke deployment. ',
  //   logo: CustomHostLogo,
  // },
];

export const thirdPartyHosts: ComputeProviderInfo[] = [
  {
    id: ComputeProviderName.Linode,
    title: 'Linode',
    description:
      'Run an app in your existing Linode account. No Clovyr account required. Automated backup service available.', //  The estimated cost to run an app on DigitalOcean is $18/mo.
    logoUrl: LinodeLogoSmall,
    logoBackgroundColor: 'white',
    logoPad: true,
    defaultRegion: 'us-east',
    regions: [
      { name: 'Mumbai, IN', value: 'ap-west', flag: 'India' },
      { name: 'Toronto, CA', value: 'ca-central', flag: 'Canada' },
      { name: 'Sydney, AU', value: 'ap-southeast', flag: 'Australia' },
      { name: 'Washington, DC', value: 'us-iad', flag: 'USA' },
      { name: 'Chicago, IL', value: 'us-ord', flag: 'USA' },
      { name: 'Paris, FR', value: 'fr-par', flag: 'France' },
      { name: 'Seattle, WA', value: 'us-sea', flag: 'USA' },
      { name: 'Sao Paulo, BR', value: 'br-gru', flag: 'Brazil' },
      { name: 'Amsterdam, NL', value: 'nl-ams', flag: 'Netherlands' },
      { name: 'Stockholm, SE', value: 'se-sto', flag: 'Sweden' },
      { name: 'Madrid, ES', value: 'es-mad', flag: 'Spain' },
      { name: 'Chennai, IN', value: 'in-maa', flag: 'India' },
      { name: 'Osaka, JP', value: 'jp-osa', flag: 'Japan' },
      { name: 'Milan, IT', value: 'it-mil', flag: 'Italy' },
      { name: 'Miami, FL', value: 'us-mia', flag: 'USA' },
      { name: 'Jakarta, ID', value: 'id-cgk', flag: 'Indonesia' },
      { name: 'Los Angeles, CA', value: 'us-lax', flag: 'USA' },
      { name: 'Dallas, TX', value: 'us-central', flag: 'USA' },
      { name: 'Fremont, CA', value: 'us-west', flag: 'USA' },
      { name: 'Atlanta, GA', value: 'us-southeast', flag: 'USA' },
      { name: 'Newark, NJ', value: 'us-east', flag: 'USA' },
      { name: 'London, UK', value: 'eu-west', flag: 'UK'},
      { name: 'Singapore, SG', value: 'ap-south', flag: 'Singapore' },
      { name: 'Frankfurt, DE', value: 'eu-central', flag: 'Germany' },
      { name: 'Tokyo, JP', value: 'ap-northeast', flag: 'Japan' },
    ],
    instanceTypes: [
      { name: 'g6-nanode-1', value: ComputeInstanceSize.Micro },
      { name: 'g6-standard-1', value: ComputeInstanceSize.Small },
      { name: 'g6-standard-2', value: ComputeInstanceSize.Medium },
      { name: 'g6-standard-4', value: ComputeInstanceSize.Large },
      { name: 'g6-standard-8', value: ComputeInstanceSize.XLarge },
      { name: 'g7-premium-64', value: ComputeInstanceSize.XLargeAI },
    ],
  },
  {
    id: ComputeProviderName.DigitalOcean,
    title: 'DigitalOcean',
    description:
      'Run an app in your existing DigitalOcean account. No Clovyr account required. Automated backup service available.', //  The estimated cost to run an app on DigitalOcean is $18/mo.
    logoUrl: DigitalOceanLogoSmall,
    logoBackgroundColor: 'white',
    defaultRegion: 'nyc3',
    regions: [
      { name: 'New York City, United States (nyc1)', value: 'nyc1', flag: 'USA' },
      { name: 'New York City, United States (nyc3)', value: 'nyc3', flag: 'USA' },
      { name: 'Amsterdam, the Netherlands (ams3)', value: 'ams3', flag: 'Netherlands'},
      { name: 'San Francisco, United States (sfo2)', value: 'sfo2', flag: 'USA' },
      { name: 'San Francisco, United States (sfo3)', value: 'sfo3', flag: 'USA' },
      { name: 'Singapore (sgp1)', value: 'sgp1', flag: 'Singapore' },
      { name: 'London, United Kingdom (lon1)', value: 'lon1', flag: 'UK' },
      { name: 'Frankfurt, Germany (fra1)', value: 'fra1', flag: 'Germany' },
      { name: 'Toronto, Canada (tor1)', value: 'tor1', flag: 'Canada' },
      { name: 'Bangalore, India (blr1)', value: 'blr1', flag: 'India' },
      { name: 'Sydney, Australia (syd1)', value: 'syd1', flag: 'Australia' },
    ],
    instanceTypes: [
      { name: 's-1vcpu-512mb-10gb', value: ComputeInstanceSize.Micro },
      { name: 's-1vcpu-1gb', value: ComputeInstanceSize.Small },
      { name: 's-1vcpu-2gb', value: ComputeInstanceSize.Medium },
      { name: 's-4vcpu-8gb', value: ComputeInstanceSize.Large },
      { name: 's-8vcpu-16gb', value: ComputeInstanceSize.XLarge },
      { name: 'c2-48vcpu-96gb-intel', value: ComputeInstanceSize.XLargeAI },
    ],
  },
  {
    id: ComputeProviderName.AWSEC2,
    title: 'Amazon Web Services',
    description:
      'Run an app in your existing AWS account. Simple instructions to connect will be shown on the next screen - no Clovyr account sign up required. Automated backup service available.', //  The estimated cost to run an app on DigitalOcean is $18/mo.
    logoUrl: AWSLogo,
    logoBackgroundColor: 'white',
    logoPad: true,
    defaultRegion: 'us-east-1',
    regions: [
      { name: 'US East (Ohio)', value: 'us-east-2', flag: 'USA' },
      { name: 'US East (Virginia)', value: 'us-east-1', flag: 'USA' },
      { name: 'US West (N. California)', value: 'us-west-1', flag: 'USA' },
      { name: 'US West (Oregon)', value: 'us-west-2', flag: 'USA' },
      { name: 'Asia Pacific (Mumbai)', value: 'ap-south-1', flag: 'India' },
      { name: 'Asia Pacific (Osaka)', value: 'ap-northeast-3', flag: 'Japan' },
      { name: 'Asia Pacific (Seoul)', value: 'ap-northeast-2', flag: 'NorthKorea' },
      { name: 'Asia Pacific (Singapore)', value: 'ap-southeast-1', flag: 'Singapore' },
      { name: 'Asia Pacific (Sydney)', value: 'ap-southeast-2', flag: 'Australia' },
      { name: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1', flag: 'Japan' },
      { name: 'Canada (Central)', value: 'ca-central-1', flag: 'Canada' },
      { name: 'Europe (Frankfurt)', value: 'eu-central-1', flag: 'Germany' },
      { name: 'Europe (Ireland)', value: 'eu-west-1', flag: 'Ireland' },
      { name: 'Europe (London)', value: 'eu-west-2', flag: 'London' },
      { name: 'Europe (Paris)', value: 'eu-west-3', flag: 'France' },
      { name: 'Europe (Stockholm)', value: 'eu-north-1', flag: 'Sweden' },
      { name: 'South America (São Paulo)', value: 'sa-east-1', flag: 'Brazil' },
    ],
    instanceTypes: [
      { name: 't3.micro', value: ComputeInstanceSize.Micro },
      { name: 't3.small', value: ComputeInstanceSize.Small },
      { name: 't3.medium', value: ComputeInstanceSize.Medium },
      { name: 't3.large', value: ComputeInstanceSize.Large },
      { name: 't3.xlarge', value: ComputeInstanceSize.XLarge },
      { name: 'g4dn.xlarge', value: ComputeInstanceSize.XLargeAI }, // FIXME: get largest baddest type for the buck
    ],
  },
  // {
  //   id: 3,
  //   name: 'azure_virtual_machines',
  //   title: 'Azure Virtual Machines',
  //   description: 'Lorem ipsum',
  //   logo: AzureLogoSmall,
  // },
];

/**
 * All first and third party hosts
 */
export const allHosts = firstPartyHosts.concat(thirdPartyHosts);

/**
 * Reference to the wNext host provider
 */
export const wNextHost: ComputeProviderInfo = firstPartyHosts.find(
  (host) => host.id === ComputeProviderName.WNext
)!;

/**
 * Get host by ID
 *
 * @param id
 * @returns
 */
export function getHostByID(id: ComputeProviderName): ComputeProviderInfo | undefined {
  return allHosts.find((h) => h.id === id);
}

import type { RouteRecordRaw } from 'vue-router';

import { AuthState } from '@clovyr/pollen/pollen';

import { usePollenStore } from '../stores/pollen_store';
import { ShowAuthForm } from '../views/Settings/types';

export const userRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'main',
    },
    props: () => {
      const { authState } = usePollenStore();
      let form;
      if (authState === AuthState.Locked) {
        form = ShowAuthForm.Locked;
      }
      if (authState === AuthState.LoggedInWithoutSecretKey) {
        form = ShowAuthForm.SecretKeyForm;
      }

      return { form }; // Return form as a prop
    },
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: {
      layout: 'main',
    },
    props: {
      form: ShowAuthForm.Signup,
    },
    component: () => import('../views/Login.vue'),
  },
  {
    // this route is currently 'hidden' - not linked anywhere. useful for debugging the print view
    path: '/recovery',
    name: 'RecoveryKitPDF',
    meta: {
      layout: 'blank',
      title: 'Recovery Kit',
      description: 'View or print your Clovyr account recovery kit.',
      canonical: 'https://clovyr.app/recovery',
      disableSSR: true,
    },
    props: () => {
      const { email, secretKey, formattedSecretKey } = usePollenStore();

      return { email, secretKey, formattedSecretKey };
    },
    component: () =>
      import(/* webpackChunkName: "recoveryKitPdf" */ '../views/RecoveryKit/RecoveryKitPDF.vue'),
  },
];

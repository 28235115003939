import type { App } from 'vue';

import { Background, Button, ImageMedia, SproutIcon, TextInputBlock } from '@clovyr/bed/components';

import EyebrowVue from '@/components/elements/Eyebrow.vue';
import PageDivider from '@/components/elements/PageDivider.vue';
import BlankLayout from '@/global/BlankLayout.vue';
import CodeLayout from '@/global/CodeLayout.vue';
import InstantLayout from '@/global/InstantLayout.vue';
import LibraryDetailLayout from '@/global/LibraryDetailLayout.vue';
import Main from '@/global/Main.vue';

export function addComponents(app: App) {
  // Layouts
  app.component('blank-layout', BlankLayout);
  app.component('main-layout', Main);
  app.component('code-layout', CodeLayout);
  app.component('instant-layout', InstantLayout);
  app.component('library-detail-layout', LibraryDetailLayout);

  // Common
  app.component('Background', Background);
  // eslint-disable-next-line vue/no-reserved-component-names
  app.component('Button', Button);
  app.component('Eyebrow', EyebrowVue);
  app.component('PageDivider', PageDivider);
  app.component('ImageMedia', ImageMedia);
  app.component('SproutIcon', SproutIcon);
  app.component('TextInputBlock', TextInputBlock);
}

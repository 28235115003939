<!-- eslint-disable import/no-unresolved -->
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { AuthType } from '@clovyr/pollen/pollen';

import SettingsSection from '@/components/blocks/SettingsSection.vue';
import { usePollenStore } from '@/stores/pollen_store';
import AccountLoggedIn from '@/views/Settings/AccountLoggedIn.vue';
import AccountLoggedOut from '@/views/Settings/AccountLoggedOut.vue';

const pollenStore = usePollenStore();
const { isUserAuthenticated, authType } = storeToRefs(pollenStore);

const headingText = computed(() => {
  if (authType.value === AuthType.Nostr) {
    return 'Account';
  }

  return 'Account & Recovery Kit';
});
</script>

<template>
  <SettingsSection :heading-text="headingText" :default-closed="false">
    <template v-slot:content>
      <AccountLoggedIn v-if="isUserAuthenticated" />
      <AccountLoggedOut v-else />
    </template>
  </SettingsSection>
</template>

import { WNextBase } from '../api/wnext-base';
import { FetchWrapper } from '../http';

import { addScheme, execWithOutput } from '.';

export class AppServerAPI extends WNextBase {
  baseURL: string;

  configureKey: string;

  /**
   * Manifest app ID (e.g., 'kanboard')
   */
  appID: string;

  constructor(baseURL: string, configureKey: string, appID: string) {
    super(
      addScheme(baseURL),
      new FetchWrapper({
        baseURL: addScheme(baseURL),
        headers: {
          Authorization: `Bearer: ${configureKey}`,
        },
      })
    );
    this.baseURL = addScheme(baseURL);
    this.configureKey = configureKey;
    this.appID = appID;
  }

  async exec(command: string): Promise<string> {
    const res = await execWithOutput(
      this.baseURL,
      this.configureKey,
      `
  cd /opt/clovyr/apps/${this.appID}
  ${command}
  `
    );
    return res.text();
  }
}

<script setup lang="ts">
import { useImageAsset } from '../../composables/useImageAsset';

const imageAsset = useImageAsset();

const props = defineProps<{
  filename?: string;
}>();

const url = computed(() => {
  if (!props.filename) {
    return '';
  }

  if (props.filename.length < 5 || props.filename.match(/^https?:\/\//)) {
    // If the filename is a URL or too short to be a filename, just return it
    return props.filename;
  }

  const u = imageAsset.getURL(props.filename);
  if (u) {
    return u;
  }

  return props.filename;
});
</script>

<template>
  <div v-if="filename" class="image-media">
    <img :src="url" />
  </div>
</template>

import { LexoRank } from 'lexorank';

import { ANONYMOUS_ITEM_PREFIX, deriveBits, type Vault, type VaultItem } from '../crypto/vault';
import { VaultAPI } from '../crypto/vault/api';
import { PublisherAPI } from '../publisher';
import type { ComputeProviderConfig, Deployment, Instance, PublisherAppVersion } from '../types';
import type { RegistryKey } from '../types/RegistryKey';
import type { UserSubscription } from '../types/UserSubscription';
import { base64ToBytes, bytesToBase64 } from '../util/base64';

import {
  type AuthenticatedUser,
  AuthState,
  AuthType,
  LATEST_PERSISTENT_STATE_VERSION,
  type PersistentState,
  type Pollen,
  type PollenState,
  type PublisherState,
} from './types';

export * from './types';

/**
 * A callback to update the UI when changes to state occur.
 */
export interface ChangeHandler {
  (state: PollenState): void;
}

type VaultItemData = PersistentStateItemData | DeploymentItemData;

interface PersistentStateItemData {
  kind: 'userState';
  value: PersistentState;
}
interface DeploymentItemData {
  kind: 'deployment';
  value: Deployment;
}

/**
 * Creates a new Pollen instance.
 */
export function newPollen(
  newVault: () => Vault,
  onStateChange: ChangeHandler,
  randomID: () => string
): Pollen {
  const vault = newVault();

  // By merging with an empty state, we ensure that any fields added in the
  // future are filled in with default values.
  let persistentState = emptyPersistentState();
  let userStateID = randomID();

  const publisherState: PublisherState = {
    publishers: [],
    publisherApps: {},
    publisherAppVersions: {},
  };

  let publisherLoading: Promise<void> | undefined;

  const deploymentItems: { [itemID: string]: Deployment } = {};

  // if (persistentState) {
  //   persistentState.subscription = undefined;
  //   vault.setState(persistentState);
  // }

  const gatherVaultItems = () => {
    const items: VaultItem[] = [
      {
        id: userStateID,
        data: JSON.stringify({
          kind: 'userState',
          value: persistentState,
        }),
      },
    ];
    const itemIDs = Object.keys(deploymentItems);
    for (let i = 0; i < itemIDs.length; i++) {
      const itemID = itemIDs[i];
      const deployment = deploymentItems[itemID];
      items.push({
        id: itemID,
        data: JSON.stringify({
          kind: 'deployment',
          value: deployment,
        }),
      });
    }
    return items;
  };

  /**
   * Unpacks the given vault items and updates them in our local pollen state.
   *
   * @param items
   */
  const unpackVaultItems = (items: VaultItem[]): VaultItemData[] => {
    const unpackedItems: VaultItemData[] = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const { kind, value } = JSON.parse(item.data);
      if (kind === 'userState') {
        persistentState = value;
        userStateID = item.id;
      } else if (kind === 'deployment') {
        deploymentItems[item.id] = value;
      } else {
        throw new Error(`unknown item kind: ${kind}`);
      }
      unpackedItems.push({ kind, value });
    }
    return unpackedItems;
  };

  const emitChanges = () => {
    let authenticatedUser: AuthenticatedUser | undefined;
    const email = vault.email();
    if (email) {
      authenticatedUser = { email };
    }

    const deploymentsByID: { [id: string]: Deployment } = {};
    const deployments = Object.values(deploymentItems);
    for (let i = 0; i < deployments.length; i++) {
      const deployment = deployments[i];
      deploymentsByID[deployment.id] = deployment;
    }

    onStateChange({
      ...persistentState,
      deployments: deploymentsByID,
      publisherState,
      secretKey: vault.secretKey(),
      authenticatedUser,
      authState: vault.authState(),
      authType: vault.authType(),
    });
  };

  const saveAndEmitUserState = async () => {
    await vault.putItem({
      id: userStateID,
      data: JSON.stringify({
        kind: 'userState',
        value: persistentState,
      }),
    });

    emitChanges();
  };

  const pollen: Pollen = {
    async init() {
      unpackVaultItems(await vault.init());

      emitChanges(); // early and often (as data is loaded)

      let v = persistentState.version;
      if (!v) {
        // initialize to latest (add registry keys obj)
        persistentState = {
          ...persistentState,
          version: LATEST_PERSISTENT_STATE_VERSION,
          registryKeys: {},
          computeProviderConfigs: {},
        };
        return saveAndEmitUserState();
      }

      if (v === LATEST_PERSISTENT_STATE_VERSION) {
        return Promise.resolve();
      }

      // do upgrades

      if (v === 1) {
        // upgrade to v2 (modifies ComputeProviderConfig structure)
        const newConfigs = {};
        Object.entries(persistentState.computeProviderConfigs).forEach(([id, old]) => {
          const newConfig = {
            id,
            name: old.name,
            // @ts-expect-error using old type signature to migrate
            ...old.config!,
          };
          newConfigs[id] = newConfig;
        });
        persistentState = {
          ...persistentState,
          version: 2,
          computeProviderConfigs: newConfigs,
        };
        v = 2;
      }

      // other upgrades go here

      return saveAndEmitUserState();
    },

    async putDeployment(deployment: Deployment) {
      let itemID = randomID();
      const entries = Object.entries(deploymentItems);
      for (let i = 0; i < entries.length; i++) {
        if (entries[i][1].id === deployment.id) {
          [itemID] = entries[i];
          break;
        }
      }

      await vault.putItem({
        id: itemID,
        data: JSON.stringify({
          kind: 'deployment',
          value: deployment,
        }),
      });
      deploymentItems[itemID] = deployment;

      await emitChanges();
    },

    async deleteDeployment(id: string) {
      let itemID: string | undefined;
      const entries = Object.entries(deploymentItems);
      for (let i = 0; i < entries.length; i++) {
        if (entries[i][1].id === id) {
          [itemID] = entries[i];
          break;
        }
      }
      if (!itemID) {
        throw new Error(`cannot find item ID for deployment: ${id}`);
      }

      await vault.deleteItem(itemID);
      delete deploymentItems[itemID];
      emitChanges();
    },

    async putInstance(instance: Instance) {
      // TODO: we should make the fqdn field non-optional so this cannot happen.
      if (!instance.fqdn) {
        throw new Error('cannot store an instance without an FQDN');
      }

      persistentState = {
        ...persistentState,
        instances: {
          ...persistentState.instances,
          [instance.fqdn]: instance,
        },
      };
      await saveAndEmitUserState();
    },

    async deleteInstance(fqdn: string) {
      const { [fqdn]: deleted, ...instances } = persistentState.instances;
      persistentState = {
        ...persistentState,
        instances,
      };
      await saveAndEmitUserState();
    },

    async putRegistryKey(key: RegistryKey) {
      persistentState = {
        ...persistentState,
        registryKeys: {
          ...persistentState.registryKeys,
          [key.publisherAppID]: key,
        },
      };
      await saveAndEmitUserState();
    },

    async deleteRegistryKey(publisherAppID: string) {
      const { [publisherAppID]: deleted, ...registryKeys } = persistentState.registryKeys;
      persistentState = {
        ...persistentState,
        registryKeys,
      };
      await saveAndEmitUserState();
    },

    async signUp(email: string, password: string) {
      await vault.signUp(email, password, gatherVaultItems());
      emitChanges();
    },

    async signUpWithNostr(email: string) {
      await vault.signUpWithNostr(email, gatherVaultItems());
      emitChanges();
    },

    async logIn(email: string, password: string, secretKey?: string) {
      const items = await vault.logIn(email, password, secretKey);
      unpackVaultItems(items);

      // refresh additional data which is not persisted locally but requires auth
      await this.loadPublisherData();

      emitChanges();
    },

    async logInWithNostr() {
      const items = await vault.logInWithNostr();
      unpackVaultItems(items);

      // refresh additional data which is not persisted locally but requires auth
      await this.loadPublisherData();

      emitChanges();
    },

    async logOut() {
      await vault.lock();

      persistentState = emptyPersistentState();
      emitChanges();
    },

    async unlock(password: string) {
      await vault.unlock(password);
      emitChanges();
    },

    async addSecretKey(secretKey: string) {
      const items = await vault.addSecretKey(secretKey);
      unpackVaultItems(items);
      emitChanges();
    },

    async saveComputeProviderConfig(config: ComputeProviderConfig) {
      persistentState = {
        ...persistentState,
        computeProviderConfigs: {
          ...persistentState.computeProviderConfigs,
          [config.id]: config,
        },
      };
      await saveAndEmitUserState();
    },

    async deleteComputeProviderConfig(id: string) {
      const { [id]: deleted, ...computeProviderConfigs } = persistentState.computeProviderConfigs;
      persistentState = {
        ...persistentState,
        computeProviderConfigs,
      };
      await saveAndEmitUserState();
    },

    async saveSubscription(sub: UserSubscription) {
      persistentState = {
        ...persistentState,
        subscription: sub,
      };
      await saveAndEmitUserState();
    },

    /**
     * Load user's publisher data from the server.
     *
     * Uses a promise to prevent being called multiple times from different components
     * simultaneously.
     */
    async loadPublisherData() {
      if (publisherLoading) {
        return publisherLoading;
      }

      publisherLoading = new PublisherAPI()
        .list()
        .then(async (pubs) => {
          if (pubs) {
            publisherState.publishers = pubs;
            const pubApps = {};
            const loadVersions: Promise<void>[] = [];
            const loadApps = pubs.map((pub) =>
              new PublisherAPI()
                .listApps({ publisher_id: pub.id })
                .then((apps) => {
                  if (apps && Array.isArray(apps)) {
                    pubApps[pub.id] = apps;
                    apps.forEach((app) => {
                      loadVersions.push(
                        new PublisherAPI()
                          .listAppVersions({ publisher_id: pub.id, app_id: app.id })
                          .then((versions) => {
                            if (versions) {
                              // ensure its sorted by version
                              versions.sort((a, b) => a.rank.localeCompare(b.rank));
                              publisherState.publisherAppVersions[app.id] = versions;
                            }
                            return Promise.resolve();
                          })
                          .catch((/* err */) => {})
                      );
                    });
                  }
                })
                .catch((/* err */) => {})
                .finally(() => {
                  publisherLoading = undefined;
                })
            );
            await Promise.all(loadApps);
            await Promise.all(loadVersions); // TODO: this might be slow since it runs on every page load for publisher routes
            publisherState.publisherApps = pubApps;
          }
          return Promise.resolve();
        })
        .catch((/* err */) => {
          // not a publisher, just ignore for now
        })
        .finally(() => {
          publisherLoading = undefined;
          emitChanges();
        });

      return publisherLoading;
    },

    addNewAppVersion(pubAppID: string) {
      const top = publisherState.publisherAppVersions[pubAppID]?.[0];
      if (!top) {
        throw new Error("can't add a new version without an existing version");
      }

      // copy of top without IDs
      const { id, ...newTop } = top;
      newTop.files.forEach((f) => {
        f.id = '';
      });

      const newVer: PublisherAppVersion = {
        // include top except for id
        ...newTop,
        publisher_app_id: pubAppID,
        rank: LexoRank.parse(top.rank).genPrev().toString(),
        status: 'created',
        version: '0.0.0-next',
      } as PublisherAppVersion;

      publisherState.publisherAppVersions[pubAppID] ||= [];
      publisherState.publisherAppVersions[pubAppID].unshift(newVer);

      emitChanges();
    },

    async handleStorageEvent(event: StorageEvent) {
      if (!event.newValue) {
        return;
      }

      if (event.key === 'clovyr/keychain') {
        unpackVaultItems(await vault.setKeychain(event.newValue));
        emitChanges();
      } else if (event.key?.startsWith(ANONYMOUS_ITEM_PREFIX)) {
        unpackVaultItems(await vault.items());
        emitChanges();
      }
    },

    async shareDeployment(id) {
      let itemID: string | undefined;
      let deployment: Deployment | undefined;
      const itemIDs = Object.keys(deploymentItems);
      for (let i = 0; i < itemIDs.length; i++) {
        if (deploymentItems[itemIDs[i]].id === id) {
          itemID = itemIDs[i];
          deployment = deploymentItems[itemID];
          break;
        }
      }
      if (!(itemID && deployment)) {
        throw new Error(`cannot find item ID for deployment: ${id}`);
      }
      const extraParams = `appID=${deployment.id}&appName=${deployment.instanceName}`;
      return vault.shareItem(itemID, extraParams);
    },

    async acceptShare(itemID, shareSecret) {
      const unpackedItems = unpackVaultItems(await vault.acceptShare(itemID, shareSecret));

      const shareSecretBytes = base64ToBytes(shareSecret);
      const shareToken = bytesToBase64(await deriveBits(shareSecretBytes, 'clovyr.share-token'));

      await Promise.all(
        unpackedItems.map((item) => {
          if (item.kind === 'deployment') {
            // fire off an event to join the instance directly as well
            return new VaultAPI().acceptSharedData({
              id: itemID,
              shareToken,
              kind: 'instance',
              dataID: item.value.fqdn!,
            });
          }
          return Promise.resolve();
        })
      );
      emitChanges();
    },
  };

  return pollen;
}

export function emptyPersistentState(): PersistentState {
  return {
    version: LATEST_PERSISTENT_STATE_VERSION,
    computeProviderConfigs: {},
    instances: {},
    registryKeys: {},
  };
}

export function emptyState(): PollenState {
  return {
    ...emptyPersistentState(),
    deployments: {},
    publisherState: {
      publishers: [],
      publisherApps: {},
      publisherAppVersions: {},
    },
    // Generally speaking, this is unnecessary, but it makes testing easier.
    secretKey: undefined,
    authenticatedUser: undefined,
    authState: AuthState.LoggedOut,
    authType: AuthType.Anonymous,
  };
}

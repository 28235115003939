<script setup lang="ts">
import { storeToRefs } from 'pinia';

import FoxAndClover from '@clovyr/bed/assets/images/fox-and-clover.png';
import Copy from '@clovyr/bed/components/elements/Copy.vue';
import ImageMedia from '@clovyr/bed/components/media/ImageMedia.vue';

import Module from '@/components/elements/Module.vue';
import ShareIcon from '@/components/icons/ShareIcon.vue';
import { useAppStatus } from '@/composables/useAppStatus';
import { usePollenStore } from '@/stores/pollen_store';

const props = defineProps<{ id: string; link: string | (() => Promise<string>) }>();

const deploymentID = toRef(props, 'id');
const { isRunning } = useAppStatus(deploymentID);
const pollenStore = usePollenStore();
const { isUserAuthenticated } = storeToRefs(pollenStore);

// const emit = defineEmits<{
//   (e: 'showShareModal'): void;
//   (e: 'close'): void;
// }>();
//
// const handleShowShareModal = () => {
//   emit('showShareModal');
// };

const isShared = computed(() => {
  return false;
});
</script>
<template>
  <Module class="module--appessentials">
    <template #default>
      <div class="sharing">
        <div class="sharing-left">
          <h3>Sharing is caring.</h3>
          <p class="module__head__dek">
            Need help troubleshooting your app? You can share view and management permissions on
            this app with other trusted parties.
          </p>
          <div class="btn">
            <!-- TODO: Uncomment when ready to use share modal -->
            <!-- <Copy
              :icon="ShareIcon"
              hasModal
              @openModal="handleShowShareModal"
              :text="link"
              :disabled="!isRunning || !isUserAuthenticated || isShared"
            /> -->
            <Copy
              label="share link"
              :icon="ShareIcon"
              :text="link"
              :translucent="false"
              :disabled="!isRunning || !isUserAuthenticated || isShared"
            />
          </div>
        </div>
        <div class="sharing-right">
          <ImageMedia :filename="FoxAndClover" />
        </div>
      </div>
    </template>
  </Module>
</template>

<style scoped lang="scss">
.sharing {
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-only(md) {
    flex-direction: column;
  }

  .sharing-left {
    margin-right: 2rem;

    h3 {
      margin-bottom: 1rem;
    }

    p {
      font-size: 14px;

      @include media-breakpoint-up(xxl) {
        font-size: 16px;
      }
    }

    .btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      .icon {
        margin-left: 0.25rem;
      }

      :deep(.check-icon) {
        margin-left: 0.25rem;
        height: 1rem;
        path {
          stroke-width: 3px;
        }
      }

      @include media-breakpoint-only(md) {
        justify-content: flex-start;
      }

      .copy-result {
        svg {
          margin-left: 0.5rem;
          height: 1rem;
        }
      }

      :deep(.copy-btn) {
        .label {
          @include media-breakpoint-only(md) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .sharing-right {
    display: flex;

    @include media-breakpoint-only(md) {
      justify-content: flex-end;
    }

    :deep(.image-media) {
      img {
        margin-top: 1rem;
        width: 20rem;

        @include media-breakpoint-only(md) {
          width: 7.5rem;
        }

        @include media-breakpoint-only(lg) {
          width: 30rem;
        }
      }
    }
  }
}
</style>

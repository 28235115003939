<script setup lang="ts">
import Module from '@/components/elements/Module.vue';
import { useAppEvents } from '@/composables/useAppEvents';
import { useAppStatus } from '@/composables/useAppStatus';
import { usePollenStore } from '@/stores/pollen_store';

interface LibraryAppDetail {
  id: string;
}

const props = defineProps<LibraryAppDetail>();

const pollenStore = usePollenStore();

const deploymentID = toRef(props, 'id');
const { emailCount } = useAppEvents(deploymentID);
const { isRunning } = useAppStatus(deploymentID);

const deployment = computed(() => {
  return pollenStore.getDeploymentByID(props.id)!;
});

const mailStatus = computed(() => {
  if (!emailCount.value || emailCount.value === 0) {
    return {
      label: 'View Mail',
      reversed: false,
    };
  }
  return {
    label: 'View',
    title: `You have ${emailCount.value} new messages from ${deployment.value.appName}.`,
    reversed: false,
    cssClass: 'mail',
  };
});
</script>

<template>
  <Module :bigHead="true" :hed="'App Mail Proxy'" :class="`${mailStatus?.cssClass}`">
    <template #heading>
      <div class="right">
        <div class="title">
          <template v-if="!isRunning">
            {{ mailStatus?.title }}
          </template>
        </div>
        <div class="mail-btn">
          <router-link
            :to="'/library/' + deployment.id + '/mail'"
            :class="isRunning ? '' : 'disabled'"
          >
            <Button
              class="button"
              :disabled="!isRunning"
              :reversed="mailStatus?.reversed"
              :label="mailStatus?.label"
            />
          </router-link>
        </div>
      </div>
    </template>
  </Module>
</template>

<style scoped lang="scss">
.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .title {
    margin-right: 1rem;
  }
}
.mail {
  background: rgba(41, 189, 123, 0.2);
}
.disabled {
  pointer-events: none;
}
:deep(.module__head__grid) {
  display: flex;
  align-items: center;
}
</style>

<script setup lang="ts">
import { Form } from 'vee-validate';

import Button from '@clovyr/bed/components/controls/Button.vue';
import { IconPosition } from '@clovyr/bed/components/controls/types';

import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import ArrowForwardsIcon from '@/components/icons/ArrowForwardsIcon.vue';

const props = defineProps({
  headingText: {
    type: String,
    default: '',
  },
  submitLabel: {
    type: String,
    default: 'submit',
  },
  theme: {
    type: String,
    default: 'base',
  },
  validationSchema: {
    type: Object,
    default: () => ({}),
  },
  initialValues: {
    type: Object,
    default: undefined,
  },
  keepValues: {
    type: Boolean,
    default: false,
  },
  reversed: {
    type: Boolean,
    default: true,
  },
  translucent: {
    type: Boolean,
    default: false,
  },
  showSubmit: {
    type: Boolean,
    default: true,
  },
  profileTabActive: {
    type: Boolean,
    default: true,
  },
  showPreview: {
    type: Boolean,
    default: true,
  },
});

const { headingText, submitLabel, theme } = toRefs(props);
const emit = defineEmits(['toggle-preview']);
</script>

<template>
  <Form
    class="form"
    :class="theme"
    :validation-schema="validationSchema"
    :initial-values="initialValues"
    :keep-values="keepValues"
  >
    <div v-if="!!headingText" class="form__heading">{{ headingText }}</div>

    <slot name="fields" />
    <slot name="disclaimer" />

    <div
      v-if="showSubmit"
      class="form__submit"
      ref="submitBtn"
      :class="profileTabActive ? 'details' : ''"
    >
      <div class="show-preview" :class="showPreview ? 'hide' : 'show'">
        <Button
          v-if="profileTabActive"
          class="live-preview"
          :label="showPreview ? 'hide preview' : 'live preview'"
          translucent
          @click="emit('toggle-preview')"
          :icon-position="showPreview ? IconPosition.Left : IconPosition.Right"
        >
          <ArrowForwardsIcon v-if="!showPreview" />
          <ArrowBackIcon v-else />
        </Button>
      </div>
      <Button :label="submitLabel" type="submit" :reversed="reversed" :translucent="translucent" />
    </div>
  </Form>
</template>
<style lang="scss" scoped>
.details {
  gap: 1rem;
  > * {
    width: 50%;
  }
  .show-preview {
    .button {
      width: 100%;
    }
  }
}
</style>

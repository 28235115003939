<script setup lang="ts">
import { PublisherAPI } from '@clovyr/pollen/publisher';

import Spinner from '../../components/elements/Spinner.vue';
import { usePublisher } from '../../composables/usePublisher';

const router = useRouter();

const props = defineProps({
  publisherID: {
    type: String,
    required: false,
    default: '',
  },
});

onMounted(async () => {
  // finish the signup process, whatever is left
  const { publisherID } = props;
  const { publisher } = usePublisher(ref(publisherID));

  if (!publisher.value) {
    // error
  }

  // tie the payment capture to the publisher
  await new PublisherAPI().capturePayment({ publisher_id: publisherID });

  // send to pub page
  return router.push({ name: 'Publisher', params: { publisherID: publisher.value?.slug } });
});
</script>

<template>
  <Spinner />
</template>

export default {
  methods: {
    hasSlot(slotName) {
      return !!this.$slots[slotName];
    },
    scrollToTop(el) {
      if (!el.scrollTop) return;
      el.scrollTop = 0;
    },
  },
};

/* eslint-disable max-classes-per-file */
/**
 * TODO: doesn't really belong in this package, but leaving here for now
 */

import { dateWithoutSeconds } from '../util/date';

import { exec, execWithOutput } from '.';

export interface DockerComposeImageJSON {
  ID: string;
  ContainerName: string;
  Repository: string;
  Tag: string;
  Size: number;
}

export interface DockerTagJSON {
  name: string;
  images: string[];
  digest: string;
  last_updated: string;
  full_size: number;
  v2: boolean;
}

export interface DockerInspectImageJSON {
  Id: string;
  RepoTags: string[];
  RepoDigests: string[];
  Parent: string;
  Comment: string;
  Created: string;
  DockerVersion: string;
  Metadata: {
    LastTagTime: string;
  };
}

export class DockerTag implements DockerTagJSON {
  name: string;

  images: string[];

  digest: string;

  last_updated: string;

  full_size: number;

  v2: boolean;

  constructor(json: DockerTagJSON) {
    this.name = json.name;
    this.images = json.images;
    this.digest = json.digest;
    this.last_updated = json.last_updated;
    this.full_size = json.full_size;
    this.v2 = json.v2;
  }

  timestamp(): Date {
    return new Date(this.last_updated);
  }

  digestString(): string {
    if (this.digest) {
      return `digest ${this.digest}`;
    }
    return '';
  }
}

export class DockerInspectImage implements DockerInspectImageJSON {
  Id: string;

  RepoTags: string[];

  RepoDigests: string[];

  Parent: string;

  Comment: string;

  Created: string;

  DockerVersion: string;

  Metadata: { LastTagTime: string };

  constructor(json: DockerInspectImageJSON) {
    this.Id = json.Id;
    this.RepoTags = json.RepoTags;
    this.RepoDigests = json.RepoDigests;
    this.Parent = json.Parent;
    this.Comment = json.Comment;
    this.Created = json.Created;
    this.DockerVersion = json.DockerVersion;
    this.Metadata = json.Metadata;
  }

  timestamp(): Date {
    return new Date(this.Created);
  }

  digest(): string {
    if (this.RepoDigests.length >= 1) {
      return this.RepoDigests[0].split('@')[1];
    }
    return '';
  }

  digestString(): string {
    if (this.RepoDigests.length >= 1) {
      return `digest ${this.digest()}`;
    }
    return '';
  }
}

/**
 * Check if the given tag both has a newer timestamp & a different digest.
 *
 * We need to check the digest as well because unfortunately, the timestamp after pulling the image
 * does not exactly match the timestamp returned from the registry (docker hub).
 *
 * @param tag
 * @param image
 * @returns
 */
export function isTagNewer(tag: DockerTag, image: DockerInspectImage): boolean {
  if (!tag || !image) {
    return false;
  }
  const latestAvailableVersion = dateWithoutSeconds(tag.timestamp());
  const currentlyRunningVersion = dateWithoutSeconds(image.timestamp());

  const digestDiffers = !!image.digest() && !!tag.digest && image.digest() !== tag.digest;

  // TODO: check if timestamp differs by more than a few minutes? this
  // comparison isn't great as digest is often missing and the timestamps never
  // match for whatever reason
  return digestDiffers && latestAvailableVersion > currentlyRunningVersion;
}

export async function getComposeImages(
  host: string,
  accessKey: string,
  appName: string
): Promise<DockerComposeImageJSON[]> {
  try {
    const out = await execWithOutput(
      host,
      accessKey,
      `
cd /opt/clovyr/apps/${appName}
docker compose images --format json 2>/dev/null
    `
    );
    return (await out.json()) as DockerComposeImageJSON[];
  } catch (e) {
    console.warn('error fetching image list:', e);
    return [];
  }
}

/**
 * Get the latest available tag metadata from Docker Registry API
 *
 * @param host
 * @param accessKey
 * @param image
 * @param tag
 * @param registry optional, defaults to Docker Hub
 * @returns
 */
export async function getLatestTag(
  host: string,
  accessKey: string,
  image: string,
  tag: string,
  registry?: string
): Promise<DockerTag | null> {
  if (!image.includes('/')) {
    image = `library/${image}`;
  }

  registry ||= 'https://hub.docker.com/v2';
  const url = `${registry}/repositories/${image}/tags/${tag}`;

  try {
    const out = await execWithOutput(host, accessKey, `curl -s "${url}"`);
    return new DockerTag(await out.json());
  } catch (e) {
    console.warn('error fetching tag metadata:', e);
    return null;
  }
}

/**
 * Get metadata for a running container on the app server
 *
 * @param host
 * @param accessKey
 * @param image
 * @param tag
 * @returns
 */
export async function getCurrentlyRunningTag(
  host: string,
  accessKey: string,
  image: string,
  tag: string
): Promise<DockerInspectImage | null> {
  try {
    const out = await exec(host, accessKey, `docker image inspect ${image}:${tag}`, true, true);
    return new DockerInspectImage((await out.json())[0]);
  } catch (e) {
    console.warn('error fetching currently running tag:', e);
    return null;
  }
}

/**
 * Pull the given image on the app server
 *
 * @param host
 * @param accessKey
 * @param image
 * @param tag
 */
export async function pullImage(
  host: string,
  accessKey: string,
  image: string,
  tag: string
): Promise<void> {
  await exec(host, accessKey, `docker pull ${image}:${tag}`, true, true);
}

export async function pullAllImages(
  host: string,
  accessKey: string,
  appName: string
): Promise<void> {
  await execWithOutput(
    host,
    accessKey,
    `
cd /opt/clovyr/apps/${appName}
docker compose pull
    `
  );
}

export async function stopApp(
  host: string,
  accessKey: string,
  appName: string,
  includeClovyrServices?: boolean
): Promise<void> {
  const services = await getServices(host, accessKey, appName);
  const servicesToStop = includeClovyrServices
    ? services
    : services.filter((s) => !s.startsWith('clovyr'));

  await execWithOutput(
    host,
    accessKey,
    `
  cd /opt/clovyr/apps/${appName}
  docker compose stop ${servicesToStop.join(' ')}
  `
  );
}

export async function restartApp(host: string, accessKey: string, appName: string): Promise<void> {
  await execWithOutput(
    host,
    accessKey,
    `
  cd /opt/clovyr/apps/${appName}
  docker compose up -d
  `
  );
  // TODO: return the output or log it for debugging?
  // console.log('out:', await out.text());
}

export async function getServices(
  host: string,
  accessKey: string,
  appName: string
): Promise<string[]> {
  const out = await execWithOutput(
    host,
    accessKey,
    `
cd /opt/clovyr/apps/${appName}
docker compose config --services 2>/dev/null`
  );

  return (await out.text()).split('\n').filter((s) => s && s.length > 0);
}

export async function getServiceLogs(
  host: string,
  accessKey: string,
  appName: string,
  svcName: string
): Promise<string> {
  const out = await execWithOutput(
    host,
    accessKey,
    `
cd /opt/clovyr/apps/${appName}
docker compose logs --no-color --timestamps ${svcName}`
  );

  return out.text();
}

/**
 * Upgrades all clovyr services on an app host
 *
 * @param host
 * @param accessKey
 * @param appName
 */
export async function upgradeClovyrServices(
  host: string,
  accessKey: string,
  appName: string
): Promise<void> {
  const ret = [] as Promise<any>[];
  const services = await getServices(host, accessKey, appName);
  ret.push(
    ...services
      .filter((s) => s.startsWith('clovyr-'))
      .map(async (svc) =>
        (
          await execWithOutput(
            host,
            accessKey,
            `#!/bin/bash
set -eo pipefail
cd /opt/clovyr/apps/${appName}
docker compose pull ${svc} 2>/dev/null
docker compose up --wait -d ${svc} 2>/dev/null
      `
          )
        ).text()
      )
  );

  // also traefik
  // TODO: write latest traefik config (image tag, etc)
  ret.push(
    (
      await execWithOutput(
        host,
        accessKey,
        `#!/bin/bash
set -eo pipefail
cd /opt/clovyr/traefik
docker compose pull 2>/dev/null
docker compose up --wait -d 2>/dev/null &
      `
      )
    ).text()
  );

  await Promise.allSettled(ret);
  // eslint-disable-next-line no-console
  console.log('refreshed clovyr services');
}

export const protocols = ['webtty'];

export const msgInputUnknown = '0';
export const msgInput = '1';
export const msgPing = '2';
export const msgResizeTerminal = '3';

export const msgUnknownOutput = '0';
export const msgOutput = '1';
export const msgPong = '2';
export const msgSetWindowTitle = '3';
export const msgSetPreferences = '4';
export const msgSetReconnect = '5';

export const cmdShell = 'shell';
export const cmdDockerShell = 'docker-shell';
export const cmdDockerLogs = 'docker-logs';

export interface Terminal {
  info(): { columns: number; rows: number };
  output(data: string): void;
  showMessage(message: string, timeout: number): void;
  removeMessage(): void;
  setWindowTitle(title: string): void;
  setPreferences(value: object): void;
  onInput(callback: (input: string) => void): void;
  onResize(callback: (colmuns: number, rows: number) => void): void;
  reset(): void;
  deactivate(): void;
  close(): void;
}

export interface Connection {
  open(): void;
  close(): void;
  send(data: string): void;
  isOpen(): boolean;
  onOpen(callback: () => void): void;
  onReceive(callback: (data: string) => void): void;
  onClose(callback: () => void): void;
}

export interface ConnectionFactory {
  create(): Connection;
}

export interface TTYArgs extends Record<string, string | undefined> {
  workdir?: string;
  container?: string;
  shell?: string;
}

type InitMessage = {
  AuthToken: string;
  Command: string;
  Args?: TTYArgs;
};

export class WebTTY {
  term: Terminal;

  connectionFactory: ConnectionFactory;

  cmd: string;

  args?: TTYArgs;

  authToken: string;

  reconnect: number;

  constructor(
    term: Terminal,
    connectionFactory: ConnectionFactory,
    authToken: string,
    cmd: string,
    args?: TTYArgs,
  ) {
    this.term = term;
    this.connectionFactory = connectionFactory;
    this.cmd = cmd;
    this.args = args;
    this.authToken = authToken;
    this.reconnect = -1;
  }

  open(): () => void {
    let connection = this.connectionFactory.create();
    let pingTimer: NodeJS.Timeout;
    let reconnectTimeout: NodeJS.Timeout;

    const setup = () => {
      connection.onOpen(() => {
        const termInfo = this.term.info();

        // FIXME: customize for our use-case
        const init: InitMessage = {
          Command: this.cmd,
          Args: this.args,
          AuthToken: this.authToken,
        };
        connection.send(JSON.stringify(init));

        const resizeHandler = (colmuns: number, rows: number) => {
          connection.send(
            msgResizeTerminal +
              JSON.stringify({
                columns: colmuns,
                rows,
              }),
          );
        };

        this.term.onResize(resizeHandler);
        resizeHandler(termInfo.columns, termInfo.rows);

        this.term.onInput((input: string) => {
          connection.send(msgInput + input);
        });

        pingTimer = setInterval(() => {
          connection.send(msgPing);
        }, 30 * 1000);
      });

      connection.onReceive((data) => {
        const payload = data.slice(1);
        switch (data[0]) {
          case msgOutput:
            this.term.output(atob(payload));
            break;
          case msgPong:
            break;
          case msgSetWindowTitle: {
            this.term.setWindowTitle(payload);
            break;
          }
          case msgSetPreferences: {
            const preferences = JSON.parse(payload);
            this.term.setPreferences(preferences);
            break;
          }
          case msgSetReconnect: {
            const autoReconnect = JSON.parse(payload);
            // console.log(`Enabling reconnect: ${autoReconnect} seconds`);
            this.reconnect = autoReconnect;
            break;
          }
          default:
            console.warn('Unknown message type', data[0], data, payload);
        }
      });

      connection.onClose(() => {
        clearInterval(pingTimer);
        this.term.deactivate();
        this.term.showMessage('Connection Closed', 0);
        if (this.reconnect > 0) {
          reconnectTimeout = setTimeout(() => {
            connection = this.connectionFactory.create();
            this.term.reset();
            setup();
          }, this.reconnect * 1000);
        }
      });

      connection.open();
    };

    setup();
    return () => {
      clearTimeout(reconnectTimeout);
      connection.close();
    };
  }
}

export enum ButtonShape {
  Pill = 'pill',
  Circle = 'circle',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum IconPosition {
  Right = 'right',
  Left = 'left',
}

// type ButtonProps in Button.vue

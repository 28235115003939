import { useRouter } from 'vue-router';

export const usePrinter = () => {
  const router = useRouter();
  const route = router.resolve({ name: 'RecoveryKitPDF' });

  return {
    printRecoveryKit() {
      // TODO: check if iframe is properly destroyed, if not then
      // switch to ref object and clear that

      const printFrame: HTMLIFrameElement = document.createElement('iframe');

      Object.assign(printFrame.style, {
        position: 'fixed',
        right: 0,
        bottom: 0,
        width: 0,
        height: 0,
        border: 0,
      });

      printFrame.src = new URL(route.href, window.location.origin).href;

      function printCallback() {
        printFrame?.parentNode?.removeChild(printFrame);
      }

      function printFrameOnLoad() {
        if (printFrame && printFrame.contentWindow) {
          printFrame.contentWindow.onbeforeunload = printCallback;
          printFrame.contentWindow.onafterprint = printCallback;

          // Without the delay Chrome doesn't fully render document by the time
          // the print dialog opens. This shouldn't be the case, but we have
          // not figured out why this happens yet. Moving these calls into an
          // `onMounted` hook, does not solve the issue at this time.
          setTimeout(() => {
            printFrame.contentWindow?.focus();
            printFrame.contentWindow?.print();
          }, 500);
        }
      }

      printFrame.onload = printFrameOnLoad;
      document.body.appendChild(printFrame);
    },
  };
};

<!--
SECURITY Note:

When loading another host, e.g. foo.bar.com, the allow-same-origin
sandbox attribute allows the script on that domain to access bar.com,
but NOT to inspect the parent iframe, e.g. with
parent.localStorage.getItem("leaf")

That is still blocked by the same-origin policy:
"DOMException: Blocked a frame with origin 'https://foo.bar.com' from
accessing a cross-origin frame."

In other words, these iframes should offer sufficient protection for
Leaf's storage, although it is crucial that we never implement any kind
of method for communicating across frames that does not also authenticate
the origin of messages (or not perform any sensitive action because of
them.)

The attribute is necessary to allow VSCode to read the other domain's
cookies and to register a ServiceWorker for the other domain
(ServiceWorkers are entirely disabled otherwise).

allow={"clipboard-read " ^ "https://" ^ host ^ " https://8080-" ^ host ^ "; clipboard-write https://" ^ host ^ " https://8080-" ^ host ^ ";"}

-->

<script lang="ts" setup>
import * as Sentry from '@sentry/core';

import { ImageMedia } from '@clovyr/bed';

import { useClovyrCodeHead } from '@/composables/useClovyrCodeHead';
import { useInstanceResume } from '@/composables/useInstanceResume';

const props = defineProps<{
  deploymentName: string;
}>();

const { frameState, startResume } = useInstanceResume(props.deploymentName);

useClovyrCodeHead();

onMounted(async () => {
  try {
    const userAgent = window?.navigator.userAgent;
    if (userAgent && userAgent.match(/iPad/i)) {
      const browser = userAgent.match(/CriOS/i) ? 'Chrome' : 'Safari';
      frameState.value = { name: 'cookie-warning', device: 'iPad', browser };
      return;
    }
  } catch (e) {
    console.warn(e);
    Sentry.captureException(e);
  }
  void startResume();
});
</script>

<template>
  <iframe
    v-if="frameState.name === 'active'"
    :src="frameState.frameURL"
    :allow="frameState.allow"
    sandbox="allow-forms allow-popups allow-popups-to-escape-sandbox allow-scripts allow-same-origin"
    class="vscode"
    frameborder="0"
  />
  <div v-else-if="frameState.name === 'loading'" class="max">
    <div>
      <ImageMedia filename="otter_yawning.webp" />
      <h2>{{ frameState.msg }}</h2>
    </div>
  </div>
  <div v-else-if="frameState.name === 'failed'" class="max">
    <h1>Error</h1>
    <p>{{ frameState.msg }}</p>
  </div>
  <div v-else-if="frameState.name === 'cookie-warning'" class="max">
    <div class="inner">
      <h1>Warning</h1>
      <p>
        It looks like you are visiting from an {{ frameState.device }} running
        {{ frameState.browser }}. If cross-site tracking prevention is enabled in settings, Clovyr
        Code may not function properly on your device.
      </p>
      <p>Click below to proceed anyway.</p>
      <div class="btn">
        <Button reversed @click="startResume()">Continue to Clovyr Code</Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vscode {
  position: absolute;
  width: calc(100% - var(--sidebar-current-width));
  height: 100%;
}
.max {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    text-align: center;
  }

  .inner {
    max-width: 600px;
    p {
      margin-top: 30px;
    }
    .btn {
      margin-top: 15px;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}
</style>

<template>
  <BaseSvg class="info-icon" width="12" height="10" viewBox="0 0 6 10" fill="none">
    <path
      id="Vector"
      d="M2.3763 0.768229C2.3763 0.709261 2.4241 0.661458 2.48307 0.661458C2.54204 0.661458 2.58984 0.709261 2.58984 0.768229C2.58984 0.827197 2.54204 0.875 2.48307 0.875C2.4241 0.875 2.3763 0.827197 2.3763 0.768229Z"
      fill="#29BD7B"
      stroke="#29BD7B"
      stroke-width="1.5"
    />
    <path
      id="Vector_2"
      d="M0.875 3.66284H2.80469V8.80868"
      stroke="#29BD7B"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      id="Vector_3"
      d="M0.875 8.80859H4.73437"
      stroke="#29BD7B"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'InfoIcon',
  components: {
    BaseSvg,
  },
};
</script>

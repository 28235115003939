<script setup lang="ts">
import { Hero, HeroBackground } from '@clovyr/bed';
import MascotLineup from '@clovyr/bed/assets/images/mascot-lineup.webp';
import mascotLineupFg from '@clovyr/bed/assets/images/mascot-lineup-fg.svg';
import PricingBlock from '@clovyr/bed/components/blocks/PricingBlock.vue';
import { useImageAsset } from '@clovyr/bed/composables/useImageAsset';
import type { Manifest } from '@clovyr/pollen/manifest';
import type { Bundle } from '@clovyr/pollen/types';
import { slugify } from '@clovyr/pollen/util/slugify';

import Breadcrumbs from '@/components/elements/Breadcrumbs.vue';
import AppSummary from '@/components/tiers/AppSummary.vue';

const props = defineProps<{ collection: Bundle; apps: Manifest[] }>();
const router = useRouter();
const imageAsset = useImageAsset();

const subMenu = computed(() => {
  const { tags } = props.collection;
  if (tags.length > 0) {
    const items = tags.map((tag) => ({
      label: tag,
      slug: slugify(tag),
    }));
    return [{ label: 'All Apps', slug: 'all' }].concat(items);
  }
  return [];
});

const crumbs = [
  {
    name: 'garden',
    routeTo: '/',
  },
  {
    name: props.collection.name,
  },
];

const colorThemeVar =
  props.collection.themeColor && `--collection-color-theme: ${props.collection.themeColor}`;

// TODO: add handler for collection list filter
const onSubMenuSelect = (idx: number) => {
  const slug = subMenu.value[idx]?.slug;
  if (slug) {
    void router.push({ name: 'Tag', params: { name: slug } });
  }
};

const formatImg = computed(() => {
  const hero = imageAsset.getURL(props.collection.heroImage || '');
  const overlay = imageAsset.getURL(props.collection.heroOverlay || '');

  return { hero, overlay };
});

const route = useRoute();

const jsonSchema = {
  '@context': 'http://www.schema.org',
  '@type': 'Organization',
  name: `${props.collection.name || 'Clovyr Collection'}`,
  description: props.collection.tagline,
  url: `https://clovyr.app${route.fullPath}`,
};

useHead({
  title: `${props.collection.name || 'Clovyr Collection'} | Collection | Clovyr`,
  link: [{ rel: 'canonical', href: `https://clovyr.app${route.fullPath}` }],
  meta: [
    {
      name: 'description',
      content: props.collection.tagline,
    },
    {
      property: 'og:title',
      content: `${props.collection.name || 'Clovyr Collection'} | Collection | Clovyr`,
    },
    {
      property: 'og:description',
      content: props.collection.tagline,
    },
    {
      property: 'og:url',
      content: `https://clovyr.app${route.fullPath}`,
    },
    {
      property: 'og:image',
      content: formatImg.value.hero,
    },
    {
      property: 'og:image:alt',
      content: `Collection background image`,
    },
  ],
  script: [
    {
      type: 'application/ld-json',
      children: JSON.stringify(jsonSchema),
    },
  ],
});
</script>

<template>
  <div :style="colorThemeVar" class="collection-section">
    <Hero class="collection-hero" subheading-text-shade="dark">
      <template #heading>{{ collection.name }}</template>

      <template #subheading>{{ collection.tagline }}</template>

      <template #top>
        <Breadcrumbs :crumbs="crumbs" />
      </template>

      <!-- <template v-slot:bottom>
        <SubMenu
          v-if="subMenu && subMenu?.length > 0"
          :items="subMenu"
          :flavor="'tag'"
          class="collection__sub-menu"
          @select="onSubMenuSelect"
        />
      </template> -->

      <template #background>
        <HeroBackground
          :background-image="formatImg.hero"
          :overlay-image="formatImg.overlay"
          :gradient="15"
        />
      </template>
    </Hero>

    <!-- <Embed :src="app.metadata.media" /> -->
    <!-- TODO: we need varied media in the fixture data -->
    <AppSummary v-for="(app, idx) in apps" :key="idx" :app="app" />

    <PricingBlock :image="MascotLineup" :overlay-image="mascotLineupFg" />
  </div>
</template>

<style scoped lang="scss">
.collection-section {
  :deep(.hero) {
    .background {
      .background__overlay-image {
        background-position: center bottom;
      }
    }

    .breadcrumb--root {
      color: rgb(var(--collection-color-theme));
    }
  }
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useEditStore } from '@/stores/edit_store';
import InstantCode from '@/views/custom/InstantCode.vue';

const editStore = useEditStore();
const { pubApp, manifest } = storeToRefs(editStore);
</script>

<template>
  <div class="develop-preview">
    <h3>Garden Page Example</h3>
    <InstantCode :instant-launch-app="pubApp.slug" :manifest="manifest"></InstantCode>
  </div>
</template>

<style scoped lang="scss">
.develop-preview {
  width: 100%;
  background-color: #453960;
  border-radius: 16px;
  padding: 1.5rem;

  h3 {
    margin: 1rem 0 2rem 1rem;
  }
}

:deep(.instant) {
  .inner {
    padding: 1rem;
    background: linear-gradient(180deg, rgb(33 28 46 / 50%) 0%, #615087 100%);
    border-radius: 1rem;
    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
    > * {
      max-width: fit-content;
    }

    @include media-breakpoint-up(sm) {
      padding: 2rem;
    }

    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;

      .app-icon {
        height: unset;
      }

      .image-media {
        padding: 1rem;
        max-width: 5rem;
        max-height: 5rem;
        @include media-breakpoint-up(sm) {
          max-width: 10rem;
          max-height: 10rem;
        }
      }

      svg {
        max-width: 12rem;
        @include media-breakpoint-up(sm) {
          max-width: 20rem;
        }
      }
    }
    .cta {
      .button {
        font-size: 10px;

        @include media-breakpoint-up(sm) {
          font-size: unset;
        }
      }
    }
    .instance-list {
      .instance {
        .button {
          font-size: 10px;

          @include media-breakpoint-up(sm) {
            font-size: unset;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="settings-section">
    <Accordion :defaultClosed="defaultClosed">
      <template v-slot:heading-left>
        <div class="settings-section__heading">
          {{ headingText }}
        </div>

        <div class="settings-section__line" />
      </template>

      <template v-slot:content>
        <div class="settings-section__content">
          <slot name="content" />
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import Accordion from '@/components/blocks/Accordion.vue';

export default {
  name: 'SettingsSection',
  components: {
    Accordion,
  },
  props: {
    headingText: {
      type: String,
      default: '',
    },
    defaultClosed: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

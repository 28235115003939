/**
 * User APIs - sign up, log in, log out
 */

import { WNextBase } from '../api/wnext-base';
import type { SignedNostrEvent } from '../crypto/nostr';
import { IncorrectLoginError } from '../pollen/errors';

export type SignUpRequest = PasswordSignUpRequest | NostrSignUpRequest;

export interface PasswordSignUpRequest {
  authType: 'password';
  email: string;
  password: string;
  encryptedState?: string;
  salt: string;
}

export interface NostrSignUpRequest {
  authType: 'nostr';
  authEvent: SignedNostrEvent;
  encryptedAuk: string;
  email: string;
}

export interface SignInRequest {
  email: string;
  password: string;
}

export interface NostrSignInRequest {
  authEvent: SignedNostrEvent;
}

export interface NostrSignInResponse {
  encryptedAuk: string;
  email: string;
}

export interface SignInResponse {
  encryptedState?: string;
}

export interface AuthChallengeRequest {
  email: string;
}

export interface AuthChallengeResponse {
  salt?: string;
}

export interface ReadStateResponse {
  encryptedState?: string;
}

export interface WriteStateRequest {
  encryptedState: string;
}

type AcceptPublisherTermsReq = {
  publisherID?: string;
  invite: string | null | undefined;
};

export class UserAPI extends WNextBase {
  async signUp(req: SignUpRequest): Promise<void> {
    return this.unwrap(await this.client.post('/user', req));
  }

  async signIn(req: SignInRequest): Promise<SignInResponse> {
    const res = await this.client.post('/user/signin', { authType: 'password', ...req });
    if (res instanceof Error) {
      throw res;
    }
    if (res.status === 204) {
      return {};
    }
    if (res.status === 200) {
      return res.json();
    }

    throw new IncorrectLoginError();
  }

  async signInWithNostr(req: NostrSignInRequest): Promise<NostrSignInResponse> {
    const res = await this.client.post('/user/signin', { authType: 'nostr', ...req });
    if (res instanceof Error) {
      throw res;
    }
    if (res.status === 200) {
      return res.json();
    }

    throw new IncorrectLoginError();
  }

  /**
   * Sign out forces user session to be cleared, regardless if one exists or
   * not.
   *
   * TODO: add local state/data clearing, etc.
   *
   * @returns
   */
  async signOut(): Promise<void> {
    return this.unwrap(await this.client.post('/user/signout', {}));
  }

  /**
   * Returns the auth challange for a given user.
   */
  async authChallenge(req: AuthChallengeRequest): Promise<AuthChallengeResponse> {
    return this.unwrap(await this.client.post('/user/authinfo', req));
  }

  /**
   * Reads the latest version of encrypted state from remote storage.
   */
  async readState(email: string): Promise<ReadStateResponse> {
    const res = await this.unwrapRes(this.client.post('/user/state/read', { email }));
    return res.data;
  }

  async removeMonolithicState(): Promise<void> {
    await this.unwrapRes(this.client.delete('/user/state'));
  }

  async acceptDayPassTerms(): Promise<void> {
    await this.unwrap(await this.client.post('/user/accept-daypass-terms', {}));
  }

  async acceptPublisherTerms(req: AcceptPublisherTermsReq): Promise<void> {
    await this.unwrap(await this.client.post('/user/accept-publisher-terms', req));
  }
}

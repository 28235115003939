import { strToBase64 } from '../../util/base64';
import type { Files, Manifest } from '..';

export function writeBase64File(name: string, contents: string): string {
  try {
    const encoded = strToBase64(contents);
    return `base64 --decode <<< '${encoded}' > "${name}"`;
  } catch (e) {
    if (e instanceof Error) {
      // TODO: btoa does not support unicode
      throw new Error(`failed to encode ${name}: ${e.message}`);
    }
    throw e;
  }
}

/**
 * Converts the contents of the Files bag to a shell-safe string by running each
 * file through base64.
 *
 * @param files
 * @returns
 */
export function writeAllFilesBase64(files: Files): string {
  // pass each file into bash w/ base64
  let s = '';
  Object.entries(files).forEach(([name, contents]) => {
    s += `mkdir -p $(dirname ${name})
${writeBase64File(name, contents)}
`;
    if (name.match(/\.sh$/i)) {
      s += `chmod 755 "${name}";\n`;
    }
  });
  return s;
}

/**
 * Whether or not DNS should be proxied for this app.
 *
 * @param manifest
 * @param customDomain
 * @returns
 */
export function isDNSProxied(manifest: Manifest, customDomain?: string): boolean {
  // DNS is proxied unless:
  // - we are using a custom domain, or
  // - the app explicitly requests not to be proxied.
  let proxyDNS = true;
  if (customDomain) {
    proxyDNS = false;
  } else if (manifest.deployment?.dns?.proxy === false) {
    proxyDNS = false;
  }
  return proxyDNS;
}

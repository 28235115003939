<script setup lang="ts">
const props = defineProps<{
  /**
   * Can be an absolute path (e.g., '/publishers/starflaker') or fully-qualified external URL (e.g.,
   * 'https://clovyr.io')
   */
  to: string;

  /**
   * Text to display in the link, if no slot given.
   */
  label?: string;
  target?: '_self' | '_blank' | '_parent' | '_top' | string;
}>();

const emit = defineEmits(['click']);

const isExternal = computed(() => {
  return props.to && props.to.startsWith('http');
});

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

function onClick() {
  scrollToTop();
  emit('click');
}
</script>

<template>
  <router-link v-if="!isExternal" :to="to" @click="onClick" :target="target">
    <slot>{{ label }}</slot>
  </router-link>

  <a v-else :href="to" :target="target" @click="$emit('click')">
    <slot>{{ label }}</slot>
  </a>
</template>

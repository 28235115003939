<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import type { editor as MonacoEditor } from 'monaco-editor';
// the top-level import automatically includes all languages and features.
// import the editor api directly to reduce bundle size by including only the languages and features we want.
// import * as monaco from 'monaco-editor';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';

import { useDrag } from '@/composables/useDrag';

// type MonacoEditor = typeof import('monaco-editor');
// load only the languages we need
import 'monaco-editor/esm/vs/basic-languages/markdown/markdown.contribution';
import 'monaco-editor/esm/vs/basic-languages/shell/shell.contribution';
import 'monaco-editor/esm/vs/basic-languages/yaml/yaml.contribution';
import 'monaco-editor/esm/vs/basic-languages/html/html.contribution';
import 'monaco-editor/esm/vs/basic-languages/handlebars/handlebars.contribution';
// features
import 'monaco-editor/esm/vs/editor/contrib/anchorSelect/browser/anchorSelect'; // anchorSelect
import 'monaco-editor/esm/vs/editor/contrib/bracketMatching/browser/bracketMatching'; // bracketMatching
import 'monaco-editor/esm/vs/editor/browser/coreCommands'; // browser
import 'monaco-editor/esm/vs/editor/contrib/caretOperations/browser/caretOperations'; // caretOperations
import 'monaco-editor/esm/vs/editor/contrib/caretOperations/browser/transpose'; // caretOperations
import 'monaco-editor/esm/vs/editor/contrib/clipboard/browser/clipboard'; // clipboard
import 'monaco-editor/esm/vs/editor/contrib/codeAction/browser/codeActionContributions'; // codeAction
import 'monaco-editor/esm/vs/editor/contrib/codelens/browser/codelensController'; // codelens
// import 'monaco-editor/esm/vs/editor/contrib/colorPicker/browser/colorContributions'; // colorPicker
// import 'monaco-editor/esm/vs/editor/contrib/colorPicker/browser/standaloneColorPickerActions'; // colorPicker
import 'monaco-editor/esm/vs/editor/contrib/comment/browser/comment'; // comment
import 'monaco-editor/esm/vs/editor/contrib/contextmenu/browser/contextmenu'; // contextmenu
import 'monaco-editor/esm/vs/editor/contrib/cursorUndo/browser/cursorUndo'; // cursorUndo
// import 'monaco-editor/esm/vs/editor/browser/widget/diffEditor/diffEditor.contribution'; // diffEditor
import 'monaco-editor/esm/vs/editor/contrib/dnd/browser/dnd'; // dnd
import 'monaco-editor/esm/vs/editor/contrib/documentSymbols/browser/documentSymbols'; // documentSymbols
import 'monaco-editor/esm/vs/editor/contrib/dropOrPasteInto/browser/copyPasteContribution'; // dropOrPasteInto
import 'monaco-editor/esm/vs/editor/contrib/dropOrPasteInto/browser/dropIntoEditorContribution'; // dropOrPasteInto
import 'monaco-editor/esm/vs/editor/contrib/find/browser/findController'; // find
// import 'monaco-editor/esm/vs/editor/contrib/folding/browser/folding'; // folding
import 'monaco-editor/esm/vs/editor/contrib/fontZoom/browser/fontZoom'; // fontZoom
import 'monaco-editor/esm/vs/editor/contrib/format/browser/formatActions'; // format
// import 'monaco-editor/esm/vs/editor/contrib/gotoError/browser/gotoError'; // gotoError
// import 'monaco-editor/esm/vs/editor/standalone/browser/quickAccess/standaloneGotoLineQuickAccess'; // gotoLine
// import 'monaco-editor/esm/vs/editor/contrib/gotoSymbol/browser/goToCommands'; // gotoSymbol
// import 'monaco-editor/esm/vs/editor/contrib/gotoSymbol/browser/link/goToDefinitionAtPosition'; // gotoSymbol
import 'monaco-editor/esm/vs/editor/contrib/hover/browser/hover'; // hover
// import 'monaco-editor/esm/vs/editor/standalone/browser/iPadShowKeyboard/iPadShowKeyboard'; // iPadShowKeyboard
import 'monaco-editor/esm/vs/editor/contrib/inPlaceReplace/browser/inPlaceReplace'; // inPlaceReplace
import 'monaco-editor/esm/vs/editor/contrib/indentation/browser/indentation'; // indentation
import 'monaco-editor/esm/vs/editor/contrib/inlayHints/browser/inlayHintsContribution'; // inlayHints
import 'monaco-editor/esm/vs/editor/contrib/inlineCompletions/browser/inlineCompletions.contribution'; // inlineCompletions
import 'monaco-editor/esm/vs/editor/contrib/inlineProgress/browser/inlineProgress'; // inlineProgress
import 'monaco-editor/esm/vs/editor/standalone/browser/inspectTokens/inspectTokens'; // inspectTokens
import 'monaco-editor/esm/vs/editor/contrib/lineSelection/browser/lineSelection'; // lineSelection
import 'monaco-editor/esm/vs/editor/contrib/linesOperations/browser/linesOperations'; // linesOperations
import 'monaco-editor/esm/vs/editor/contrib/linkedEditing/browser/linkedEditing'; // linkedEditing
import 'monaco-editor/esm/vs/editor/contrib/links/browser/links'; // links
import 'monaco-editor/esm/vs/editor/contrib/longLinesHelper/browser/longLinesHelper'; // longLinesHelper
// import 'monaco-editor/esm/vs/editor/contrib/multicursor/browser/multicursor'; // multicursor
import 'monaco-editor/esm/vs/editor/contrib/parameterHints/browser/parameterHints'; // parameterHints
import 'monaco-editor/esm/vs/editor/standalone/browser/quickAccess/standaloneCommandsQuickAccess'; // quickCommand
import 'monaco-editor/esm/vs/editor/standalone/browser/quickAccess/standaloneHelpQuickAccess'; // quickHelp
import 'monaco-editor/esm/vs/editor/standalone/browser/quickAccess/standaloneGotoSymbolQuickAccess'; // quickOutline
import 'monaco-editor/esm/vs/editor/contrib/readOnlyMessage/browser/contribution'; // readOnlyMessage
import 'monaco-editor/esm/vs/editor/standalone/browser/referenceSearch/standaloneReferenceSearch'; // referenceSearch
import 'monaco-editor/esm/vs/editor/contrib/rename/browser/rename'; // rename
import 'monaco-editor/esm/vs/editor/contrib/semanticTokens/browser/documentSemanticTokens'; // semanticTokens
import 'monaco-editor/esm/vs/editor/contrib/semanticTokens/browser/viewportSemanticTokens'; // semanticTokens
import 'monaco-editor/esm/vs/editor/contrib/smartSelect/browser/smartSelect'; // smartSelect
// import 'monaco-editor/esm/vs/editor/contrib/snippet/browser/snippetController2'; // snippet
// import 'monaco-editor/esm/vs/editor/contrib/stickyScroll/browser/stickyScrollContribution'; // stickyScroll
import 'monaco-editor/esm/vs/editor/contrib/suggest/browser/suggestController'; // suggest
import 'monaco-editor/esm/vs/editor/contrib/suggest/browser/suggestInlineCompletions'; // suggest
import 'monaco-editor/esm/vs/editor/standalone/browser/toggleHighContrast/toggleHighContrast'; // toggleHighContrast
// import 'monaco-editor/esm/vs/editor/contrib/toggleTabFocusMode/browser/toggleTabFocusMode'; // toggleTabFocusMode
import 'monaco-editor/esm/vs/editor/contrib/tokenization/browser/tokenization'; // tokenization
import 'monaco-editor/esm/vs/editor/contrib/unicodeHighlighter/browser/unicodeHighlighter'; // unicodeHighlighter
import 'monaco-editor/esm/vs/editor/contrib/unusualLineTerminators/browser/unusualLineTerminators'; // unusualLineTerminators
import 'monaco-editor/esm/vs/editor/contrib/wordHighlighter/browser/wordHighlighter'; // wordHighlighter
import 'monaco-editor/esm/vs/editor/contrib/wordOperations/browser/wordOperations'; // wordOperations
import 'monaco-editor/esm/vs/editor/contrib/wordPartOperations/browser/wordPartOperations'; // wordPartOperations
import './monaco';
import './snippets';

import { activateClovyrTheme } from './monacoTheme';

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    allowDrop?: boolean;
    language?: string;
    filename?: string;
    readonly?: boolean;
    resize?: boolean;
    minimap?: boolean;
    wordWrap?: boolean;
    scrollToBottom?: boolean;
    tail?: boolean;
    options?: any;
  }>(),
  { minimap: false, resize: true, wordWrap: true },
);
const opts = computed(() => {
  const defaults = {
    theme: 'clovyr',
    foldingStrategy: 'indentation',
    wordWrap: props.wordWrap ? 'on' : 'off',
    selectOnLineNumbers: true,
    tabSize: 2,
    automaticLayout: true,
    quickSuggestions: {
      other: true,
      comments: false,
      strings: true,
    },
    scrollbar: {
      alwaysConsumeMouseWheel: false,
    },
  } as MonacoEditor.IStandaloneEditorConstructionOptions;
  const d: MonacoEditor.IStandaloneEditorConstructionOptions = {
    ...defaults,
    ...(props.options || {}),
  };
  if (!props.minimap) {
    d.minimap = { enabled: false };
  }
  if (props.readonly) {
    d.readOnly = true;
  }
  return d;
});

const root = ref<HTMLDivElement>();
let editor: MonacoEditor.IStandaloneCodeEditor;

const resizeObserver = new ResizeObserver(() => {
  if (editor) {
    editor.layout();
  }
});

/**
 * Whether or not an on-hover widget is visible in monaco
 */
const isHovering = ref(false);

const hoverCallback = (mutationList /* , observer */) => {
  mutationList.forEach((mutation) => {
    const el = mutation.target as HTMLElement;
    if (
      mutation.type === 'attributes' &&
      mutation.attributeName === 'monaco-visible-content-widget'
    ) {
      // while tooltip is open (hovering over yaml issue, etc)
      const vis = el.getAttribute('monaco-visible-content-widget');
      isHovering.value = vis === 'true';
    } else if (
      mutation.type === 'attributes' &&
      mutation.attributeName === 'class' &&
      el.classList.contains('suggest-widget')
    ) {
      // when code completion is open
      isHovering.value = el.classList.contains('visible');
      // } else {
      //   console.log('mutation:', mutation.type, mutation);
    }
  });
};

const observer: MutationObserver = new MutationObserver(hoverCallback);

function scrollEditorToBottom() {
  if (editor) {
    editor.revealLine(editor.getModel()!.getLineCount());
  }
}

defineExpose({
  scrollToBottom: scrollEditorToBottom,
});

function addHoverEvents() {
  const targetNode = root.value!.querySelector('.overflowingContentWidgets');
  if (targetNode) {
    observer.observe(targetNode, { attributes: true, childList: false, subtree: true });
  }
}

function createEditor() {
  try {
    const options: MonacoEditor.IStandaloneEditorConstructionOptions = {
      ...opts.value,
      language: props.language,
    };
    if (props.filename) {
      const uri = monaco.Uri.parse(props.filename);
      let model: MonacoEditor.ITextModel | null = monaco.editor.getModel(uri);
      if (model) {
        model.setValue(props.modelValue || '');
      } else {
        model = monaco.editor.createModel(props.modelValue || '', props.language, uri);
      }
      options.model = model;
    } else {
      options.value = props.modelValue || '';
    }

    editor = monaco.editor.create(root.value!, options);

    editor.onDidChangeModelContent((/* event */) => {
      const value = editor.getValue();
      if (props.modelValue !== value) {
        emit('update:modelValue', value);
      }
    });

    if (props.resize) {
      addHoverEvents();
    }
  } catch (e) {
    console.error('err creating monaco editor', e);
  }
}

onMounted(() => {
  activateClovyrTheme(monaco);

  if (!root.value) {
    return;
  }
  resizeObserver.observe(root.value);
  createEditor();
  if (props.scrollToBottom || props.tail) {
    scrollEditorToBottom();
  }
});

onUnmounted(() => {
  resizeObserver.disconnect();
  if (observer) {
    observer.disconnect();
  }
});

watch(
  () => props.modelValue,
  (value) => {
    if (editor && editor.getValue() !== value) {
      editor.setValue(value || '');
      if (props.tail) {
        scrollEditorToBottom();
      }
    }
  },
);

// empty string is a file with no extension (probably a shell script)
const allowedFileTypes = ['application/x-yaml', 'application/x-sh', ''];

async function onDropFile(file: File): Promise<void> {
  if (file.type.indexOf('text/') >= 0 || allowedFileTypes.includes(file.type)) {
    const t = await file.text();
    emit('update:modelValue', t);
    return undefined;
  }

  console.warn('Invalid file type: ', file.type);
  return undefined;
}

const { isDragging, onDragOver, onDragEnter, onDragLeave, onDrop } = useDrag(
  props.allowDrop,
  onDropFile,
);
</script>

<template>
  <div
    class="editor"
    :class="{ resizable: resize && !isHovering, upload: isDragging }"
    @dragover="onDragOver"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @drop="onDrop"
  >
    <div ref="root" class="root">
      <div class="drop-target">Drop file here</div>
    </div>
  </div>
</template>

<style scoped>
.editor {
  position: relative;
  width: 100%;
  min-height: 233px;
  height: 233px;
  .root {
    width: 100%;
    height: 100%;
  }
  &.resizable {
    resize: vertical;
    overflow: auto;
  }

  .drop-target {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 500;
    background-color: #211a33c9;
    border-radius: calc(var(--space) * 2);
  }

  &.upload {
    border: 2px dashed #d375ff;
    .drop-target {
      display: flex;
    }
  }
}
</style>

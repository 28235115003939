<script setup lang="ts">
import type MonacoEditorVue from './MonacoEditor.vue';

defineOptions({
  inheritAttrs: false,
});

const editor = ref<InstanceType<typeof MonacoEditorVue> | null>(null);

const ssr = import.meta.env.SSR;

const MonacoEditor = import.meta.env.SSR
  ? null
  : defineAsyncComponent(() => import('./MonacoEditor.vue'));

function scrollToBottom() {
  if (editor.value) {
    editor.value.scrollToBottom();
  }
}

defineExpose({
  scrollToBottom,
});
</script>

<template>
  <textarea v-if="ssr" v-bind="$attrs"></textarea>
  <div v-else>
    <component :is="MonacoEditor" v-bind="$attrs" ref="editor" />
  </div>
</template>

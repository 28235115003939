<script setup lang="ts">
import { ConnectionFactory } from './websocket';
import { cmdDockerLogs, cmdDockerShell, protocols, type TTYArgs, WebTTY } from './webtty';
import { Xterm } from './xterm';

import '@xterm/xterm/css/xterm.css';

const props = defineProps<{
  host: string;
  token: string;
  cmd: string;
  args: TTYArgs;
}>();

const termEl = ref<HTMLElement | null>(null);
let term: Xterm;
let closer: () => void;

function cleanupMessages() {
  const messages = document.querySelectorAll('.xterm-overlay');
  messages.forEach((m) => {
    if (m.textContent === 'Connection Closed') {
      m.remove();
    }
  });
}

function closeTerm() {
  if (closer) {
    closer();
  }
  if (term) {
    term.close();
  }
  cleanupMessages();
}

function createTerm() {
  closeTerm();

  if (!termEl.value) {
    return;
  }

  if ((props.cmd === cmdDockerLogs || props.cmd === cmdDockerShell) && !props.args.container) {
    // probably on first load, no need to connect
    return;
  }

  term = new Xterm(termEl.value);
  term.setPreferences({
    'enable-webgl': true, // always use webgl, if we can
  });

  const url = `wss://${props.host}/_leaf/api/exec/ssh`;
  const factory = new ConnectionFactory(url, protocols);
  const wt = new WebTTY(term, factory, props.token, props.cmd, props.args);

  closer = wt.open();
}

onMounted(() => {
  createTerm();
});

onUnmounted(() => {
  closeTerm();
});

watch(props, () => {
  createTerm();
});
</script>

<template>
  <div class="wrap">
    <div ref="termEl"></div>
  </div>
</template>

<style scoped lang="scss">
.wrap {
  background-color: color(negative, primary);
  padding: 1rem;
  border-radius: 0 0 16px 16px;
  & > div {
    height: 100%;
  }
}
:deep(.xterm) {
  .xterm-viewport {
    overflow: scroll;
    scrollbar-color: color(grey, primary) #221a33;
    scrollbar-width: thin;
  }
}
</style>

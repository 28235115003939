<!-- eslint-disable vue/no-mutating-props -->
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';

import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import UploadIcon from '@/components/icons/UploadIcon.vue';
import { useDrag } from '@/composables/useDrag';
import { type PublisherAppFileNew, useEditStore } from '@/stores/edit_store';

import PublisherAppFileList from './PublisherAppFileList.vue';
import PublisherAppsFileEdit from './PublisherAppsFileEdit.vue';

const { pubApp, pubAppVersion } = storeToRefs(useEditStore());
const files = computed(() => pubAppVersion.value?.files?.filter((f) => !f.deleted_at) || []);

const file = ref<PublisherAppFileNew | null>(null);
const showFileEditor = ref<boolean>(false);

onMounted(() => {
  if (pubApp.value && !pubAppVersion.value.files) {
    pubAppVersion.value.files = [];
  }
});

function addFile(name: string, content: string) {
  // @ts-expect-error missing props (not needed when creating new file)
  const f: PublisherAppFileNew = {
    _id: crypto.randomUUID(),
    name,
    content,
  };

  if (!pubAppVersion.value.files) {
    pubAppVersion.value.files = [f]; // Create a new array if it's empty
  } else {
    pubAppVersion.value.files.push(f); // Push into the existing array
  }

  file.value = f;
  showFileEditor.value = true;
}

async function onDropFile(droppedFile: File): Promise<void> {
  addFile(droppedFile.name, await droppedFile.text());
}

const { isDragging, onDragOver, onDragEnter, onDragLeave, onDrop } = useDrag(true, onDropFile);

function onClickAddNewFile() {
  addFile('new file', '');
}

function fileClicked(f) {
  file.value = f;
  showFileEditor.value = true;
}
</script>

<template>
  <div class="pub-file">
    <div class="controls">
      <h3>Files</h3>
      <Button v-if="showFileEditor" @click="showFileEditor = false" :shape="ButtonShape.Circle"
        ><ArrowBackIcon class="arrow-back"
      /></Button>
    </div>

    <div v-if="showFileEditor">
      <PublisherAppsFileEdit
        :key="file?.id || (file as PublisherAppFileNew)?._id"
        :fileID="file?.id || (file as PublisherAppFileNew)?._id"
      />
    </div>

    <div
      v-else
      class="file-list-container"
      @dragover="onDragOver"
      @dragenter="onDragEnter"
      @dragleave="onDragLeave"
      @drop="onDrop"
    >
      <Button @click.stop.prevent="onClickAddNewFile" :shape="ButtonShape.Circle">
        <UploadIcon />
      </Button>
      <PublisherAppFileList
        :files="files"
        :dropping="isDragging"
        @existingFileClicked="fileClicked"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.pub-file {
  margin-top: 2rem;
}
h3 {
  margin: 0 1rem 0 0;
  color: color(grey, secondary);
}
.button:hover {
  background-color: color(grey, primary);
}
.controls {
  display: flex;
  align-items: center;
}
.file-list-container {
  display: flex;
  margin: 1rem 0;
}
.arrow-back {
  margin-right: 0.5rem;
}
</style>

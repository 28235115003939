<template>
  <HelpPage
    :headingText="'Getting Started with Clovyr Code'"
    :nextPage="{
      page: 'launching-clovyr-code',
      name: 'Launching Clovyr Code',
    }"
    @navigate="(page) => $emit('navigate', page)"
    class="getting-started-page"
  >
    <p>
      Clovyr Code is remote development made simple, flexible, and open. Run full-featured VSCode in
      your browser, connected to any public or private git repository. Work on hefty projects from
      lightweight devices and do it all with a novel framework that prioritizes privacy and
      extensibility.
    </p>

    <p>
      Code is in beta right now, lots of new features will arrive over the rest of this year. From
      deploying anywhere in a few clicks to sharing reproducible, project-specific builds with a
      simple docker file, we’re making decentralized, self-hosted serverless a thing.
    </p>

    <p class="regular-margin">Tips and Tricks:</p>

    <ul>
      <li>
        Within clovyr.app, click the hamburger icon Clovyr Docs navigation icon at the bottom of the
        Help panel to view the Docs Table of Contents.
      </li>
      <li>
        Open <a href="https://help.clovyr.io">Clovyr Docs</a> in a new tab to browse Docs
        full-screen.
      </li>
    </ul>
  </HelpPage>
</template>

<script>
import HelpPage from '@/views/Help/HelpPage.vue';

export default {
  name: 'GettingStartedPage',
  components: {
    HelpPage,
  },
};
</script>

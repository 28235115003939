import { useRoute } from 'vue-router';
import { useHead } from '@unhead/vue';

export const useRouteHead = () => {
  const route = useRoute();
  if (!route) {
    console.warn('route undefined; not changing head');
    return;
  }
  const jsonSchema = {
    '@context': 'http://www.schema.org',
    '@type': 'Organization',
    name: route.meta.title || '',
    sameAs: ['https://clovyr.io', 'https://twitter.com/growclovyr', 'https://github.com/clovyr'],
    logo: 'https://clovyr.app/logo.png',
    description: (route.meta.description as string) || '',
    email: 'support@clovyr.io',
    url: `https://clovyr.app${route.fullPath}`,
  };

  const title = `${route?.meta?.title ? `${route.meta.title} | ` : ''}Clovyr`;

  useHead({
    title,
    link: [{ rel: 'canonical', href: `${route.meta.canonical || ''}` }],
    meta: [
      {
        name: 'description',
        content: (route.meta.description as string) || '',
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: (route.meta.description as string) || '',
      },
      {
        property: 'og:url',
        content: `https://clovyr.app${route.fullPath}`,
      },
    ],
    script: [
      {
        type: 'application/ld-json',
        children: JSON.stringify(jsonSchema),
      },
    ],
  });
};

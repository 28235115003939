import { createPinia } from 'pinia';
import { type App as VueApp, createSSRApp } from 'vue';
import type { Router } from 'vue-router';
import * as Sentry from '@sentry/core';
import type { MergeHead, VueHeadClient } from '@unhead/vue';
import pino from 'pino';

import { initMatomo } from '@clovyr/bed/composables/useMatomo';
import general from '@clovyr/bed/mixins/general';
import { newGarden } from '@clovyr/pollen/garden/impl';

import { usePayments } from './composables/usePayments';
import { addComponents } from './init/components';
import { initConfig } from './init/config';
import { initDebug } from './init/debug';
import { initHead } from './init/head';
import { initSentry } from './init/sentry';
import { usePollenStore } from './stores/pollen_store';
import { FeatureFlag, useUserFlagsStore } from './stores/user_flags';
import App from './App.vue';
import { createAppRouter } from './router';

export async function createApp(flags: FeatureFlag[]): Promise<{
  app: VueApp<Element>;
  router: Router;
  head: VueHeadClient<MergeHead>;
}> {
  const logger = pino();
  const app = createSSRApp(App);
  const router = createAppRouter();
  const pinia = createPinia();

  const userFlagStore = useUserFlagsStore(pinia);
  userFlagStore.setFlags(flags);

  app.config.errorHandler = (err) => {
    // global error handler for uncaught exceptions
    // better local logging than sentry (includes stack)
    let msg = `caught vue err: ${err}`;
    if ((err as Error).stack) {
      msg += `\n${(err as Error).stack}`;
    }
    logger.error(msg);
  };

  addComponents(app);
  // initFormbricks();
  await initConfig().then(async (cfg) => {
    // init items which require config to load but should not block app startup
    initSentry(app, router);
    if (!import.meta.env.SSR) {
      setTimeout(() => {
        // load analytics after a short delay to avoid blocking app startup
        let matomoSiteID = 0;
        if (import.meta.env.VITE_MATOMO_SITE_ID) {
          matomoSiteID = parseInt(import.meta.env.VITE_MATOMO_SITE_ID, 10);
        } else if (cfg.VITE_APP_ENV === 'production') {
          matomoSiteID = 2;
        }
        if (matomoSiteID > 0) {
          void initMatomo(app, router, matomoSiteID);
        }
      }, 100);
    }

    // set garden instance now that config is available
    const garden = newGarden(
      cfg.VITE_GARDEN_URL,
      userFlagStore.userHasFeatureFlag(FeatureFlag.Internal),
    );

    // add publisher platform apps
    const pubURL = `${cfg.VITE_PUBLIC_URL}/api/garden/catalog`;
    await garden.addIndex(pubURL).catch((e) => {
      logger.error(`failed to add garden index ${pubURL}: ${e}`);
      Sentry.captureException(e);
    });

    const pollenStore = usePollenStore(pinia);
    await pollenStore.pollen.init();
    pollenStore.setGarden(garden);

    if (typeof localStorage !== 'undefined') {
      void pollenStore.migrateCodeDeployments();
    }

    // immediately load global data
    void usePayments().refreshSubscription();
  });

  app.use(pinia);
  app.use(router);
  const head = initHead(app);

  app.mixin(general);

  initDebug(pinia, router);

  return { app, router, head };
}

import type { Language } from '../types';

const languages = [
  { name: 'Elm', icon: 'logo-elm.svg' },
  { name: 'Go', icon: 'logo-golang.svg' },
  { name: 'Haskell', icon: 'logo-haskell.svg' },
  { name: 'JavaScript', icon: 'logo-javascript.svg' },
  { name: 'Python', icon: 'logo-python.svg' },
  { name: 'Rust', icon: 'logo-rust.svg' },
  { name: 'Ubuntu', icon: 'logo-ubuntu.svg' },
] as Array<Language>;

export default languages;

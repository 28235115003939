import { storeToRefs } from 'pinia';

import { getAllQueryParams } from '@clovyr/pollen/http/qs';
import { getServices } from '@clovyr/pollen/remote_exec/docker';

import { useUserFlagsStore } from '@/stores/user_flags';

import { useAppStatus } from './useAppStatus';

const serviceCache: Record<string, string[]> = {};

export const useDocker = (deploymentID: Ref<string>, addRoot?: boolean) => {
  const { deployment } = useAppStatus(deploymentID);
  const userFlagStore = useUserFlagsStore();
  const { isInternal } = storeToRefs(userFlagStore);

  const services = ref<string[]>([]);
  const selectedService = ref('');

  const cleanServiceName = computed(() => {
    const v = selectedService.value;
    if (v.startsWith('service: ')) {
      return v.slice(9);
    }
    return v;
  });

  const serviceOptions = computed(() => {
    let svcs = services.value;
    if (addRoot) {
      svcs = ['<instance>', ...svcs];
    }
    return svcs.map((s) => ({ name: s, value: s }));
  });

  function setDefaultService() {
    if (!selectedService.value && services.value.length) {
      // check for a service in the URL
      const q = getAllQueryParams();
      const target = q.get('target');
      if (target && services.value.includes(target)) {
        selectedService.value = target;
      } else {
        selectedService.value = serviceOptions.value[0].name;
      }
    }
  }

  async function refreshServices() {
    let svcs = await getServices(
      deployment.value.fqdn!,
      deployment.value.accessList[0],
      deployment.value.appID,
    );

    if (!isInternal.value) {
      svcs = svcs.filter((s) => !s.startsWith('clovyr-'));
    }

    services.value = svcs.map((s) => `service: ${s}`);

    setDefaultService();
    serviceCache[deploymentID.value] = services.value;
  }

  watch(selectedService, (v, ov) => {
    if (ov && v) {
      const q = getAllQueryParams();
      q.set('target', v);
      window.location.hash = `#${q.toString().replace('=&', '&')}`;
    }
  });

  onMounted(async () => {
    if (serviceCache[deploymentID.value]) {
      services.value = serviceCache[deploymentID.value];
      setDefaultService();
    } else {
      void refreshServices();
    }
  });

  return {
    services,
    selectedService,
    serviceOptions,
    cleanServiceName,

    refreshServices,
  };
};

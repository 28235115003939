<script setup lang="ts">
import { Button, Hero, SproutIcon, Tips } from '@clovyr/bed';
import bg from '@clovyr/bed/assets/images/clovyr-code-bg.jpg';
import bgOverlay from '@clovyr/bed/assets/images/press-fg.svg';
import { IconPosition } from '@clovyr/bed/components/controls/types';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import { slugify } from '@clovyr/pollen/util/slugify';
import { stringToHTML } from '@clovyr/pollen/util/string';

import AppList from '@/components/blocks/AppList.vue';
import Breadcrumbs from '@/components/elements/Breadcrumbs.vue';
import Tag from '@/components/elements/Tag.vue';
import { useCloudType } from '@/composables/useCloudType';
import { useClovyrCodeHead } from '@/composables/useClovyrCodeHead';
import { useDeploymentStore } from '@/stores/deployment_store';
import { usePollenStore } from '@/stores/pollen_store';

defineProps<{ err?: string }>();
const router = useRouter();
const deploymentStore = useDeploymentStore();
const { garden } = usePollenStore();
const { cloudName } = useCloudType();

onMounted(() => {
  void deploymentStore.loadDeployments();
});

const app = garden.getAppByID('clovyr-code')!;
const data = app.metadata;

const crumbs = [
  {
    name: 'garden',
    routeTo: '/',
    color: '1',
  },
  // {
  //   name: 'remote work',
  //   routeTo: '/collection/remote-work',
  //   color: '2',
  // },
  {
    name: 'Clovyr Code',
  },
];

const handleLaunch = async () => {
  return router.push({ name: 'LaunchSetup', params: { id: 1 } });
};

const logo = {
  logoUrl: app.metadata.logoUrl,
};

useClovyrCodeHead();
</script>

<template>
  <div class="app-detail app-detail-clovyr-app">
    <div class="app-detail__top">
      <div class="app-background"></div>

      <div class="app-detail-masthead">
        <div class="app-detail-masthead__left">
          <Hero subheadingTextShade="light" class="app-detail__hero">
            <template #heading>{{ app.metadata.name }}</template>
            <template #subheading>
              {{ app.metadata.title }}
              <div class="subtitle">{{ app.metadata.subtitle }}</div></template
            >
            <template #bottom>
              <Background :overlay-image="bgOverlay" :gradient="13" :image="bg" />

              <div class="app-controls button-group">
                <AppList :err="err" />
              </div>
            </template>

            <template #top>
              <AppLogo :logo="logo" size="large" class="app-logo-detail" />

              <Breadcrumbs :crumbs="crumbs" />
            </template>
          </Hero>
        </div>
      </div>
    </div>

    <Tips>
      <template #heading> huginn says: </template>

      <template #body>
        {{ app.metadata.huginnSays }}
      </template>
    </Tips>

    <section class="app-info">
      <div class="container container-common">
        <div class="app-info-top row">
          <div class="app-info-top__left grid__col-md-6">
            <div class="app-info__copy">
              <h2 class="app-info__heading">Overview</h2>
              <p v-html="stringToHTML(app.metadata.description)"></p>
            </div>

            <div class="app-tags" v-if="app.metadata.tags?.length">
              <div class="app-tags__heading">Tagged in:</div>

              <Tag
                v-for="tag in app.metadata.tags"
                :key="slugify(tag)"
                :label="tag"
                :slug="slugify(tag)"
              />
            </div>
          </div>

          <div class="app-info-top__right grid__col-md-6">
            <div class="app-info__copy">
              <h2 class="app-info__heading">Features</h2>
              <ul>
                <li>
                  <h4>On-demand, web-based development environments without the tradeoffs</h4>
                  <p>
                    Clovyr Code runs entirely in your browser and connects directly to a VPS that’s
                    just for you, so you can manage your Clovyr Code instances with ease.
                  </p>
                </li>
                <li>
                  <h4>
                    The seamlessness of serverless, with the privacy and control of local
                    development
                  </h4>
                  <p>
                    Your code is your business, not ours. Clovyr Code requires only a web browser,
                    and connects directly to your personal instance without any information passing
                    through Clovyr servers.
                  </p>
                </li>
                <li>
                  <h4>Powered by open source and full-featured VSCode, terminal and all</h4>
                  <p>
                    Development environments come pre-loaded with popular tools for working in your
                    favorite languages. Start with a clean Ubuntu or pre-installed tooling like Node
                    and npm.
                  </p>
                </li>
                <li>
                  <h4>Extend and modify your favorite workspaces to make them your own</h4>
                  <p>
                    Install VSCode extensions and configure a Clovyr Code instance to make starting
                    new projects super fast. Soon you’ll be able to share these reproducible setups
                    with your team and your favorite open source community.
                  </p>
                </li>
                <li>
                  <h4>Connect to any git repo: GitHub, GitLab, internal, or elsewhere</h4>
                  <p>
                    Finally, a consistent workflow and seamless switching between all your
                    community, work, and personal projects. Our free plan even includes access to
                    private repos, because privacy shouldn’t come at a premium.
                  </p>
                </li>
                <li>
                  <h4>Make open source contributions a snap</h4>
                  <p>
                    Distribute pre-configured developer environments that deploy instantly to a
                    contributor’s own host. No more writing cloud-specific install scripts or
                    expecting volunteers to navigate a sea of docs. Coming soon.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="app-specs copy">
          <div class="app-specs__row">
            <span class="app-specs__key" v-if="data.website?.text"
              >Website:
              <a :href="data.website?.url" class="app-specs__value app-specs__value--link">{{
                data.website.text
              }}</a>
            </span>
            <span
              v-if="(data.website?.text && data.version) || (data.website?.text && data.repo?.text)"
              class="app-specs__spacer"
              >|</span
            >
            <span class="app-specs__key" v-if="data.repo?.text"
              >Source Code:
              <a :href="data.repo?.url" class="app-specs__value app-specs__value--link">{{
                data.repo?.text
              }}</a>
            </span>
            <span v-if="data.version && data.repo?.text" class="app-specs__spacer">|</span>
            <span class="app-specs__key" v-if="data.version"
              >Version:
              <span class="app-specs__value">{{ data.version }}</span>
            </span>
          </div>
        </div>
      </div>
    </section>

    <div class="app-launch-bar">
      <div class="container container-common">
        <div v-if="false" class="row">
          <div class="grid__col-md-8 app-launch-bar__left">
            <h2 class="app-launch-bar__title">Launch Code with Clovyr on {{ cloudName }}</h2>
          </div>

          <div class="grid__col-md-4 app-launch-bar__right">
            <Button
              label="launch"
              :iconPosition="IconPosition.Right"
              :reversed="true"
              @click="handleLaunch"
              class="launch-button"
            >
              <SproutIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-specs__value {
  padding: 0 0 0 5px;
}

.app-detail {
  .subtitle {
    font-weight: 100;
    font-size: 22px;
  }

  :deep(.hero) {
    .hero__inner {
      align-items: flex-start;
      margin-bottom: space(5);
      padding-top: space(10);

      @include media-breakpoint-up(md) {
        align-items: flex-end;
        margin-bottom: space(9);
      }

      .hero__left {
        .app-icon {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
          }
        }
      }

      .hero__middle {
        margin-bottom: 0;

        .hero__heading {
          @include font-size(32);

          @include media-breakpoint-up(md) {
            @include font-size(42);
          }

          @include media-breakpoint-up(xl) {
            @include font-size(67);
          }

          margin-bottom: 0;
        }
      }
    }
  }

  .app-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    width: 100%;
    height: space(60);
    background: var(--bg-url);

    @include background-image;
  }

  .app-logo-detail {
    margin-bottom: space(3.7);
  }

  .app-controls {
    margin-top: space(3);
  }

  .app-info {
    color: color(grey, tertiary);
    background-color: transparentize(color(background, dark-navy), 0.5);

    &__copy {
      @extend .copy;

      @include font-size(16);

      color: color(white);
      line-height: 1.4;

      li {
        margin-bottom: space(3);

        h4 {
          margin-bottom: space(1);
        }
      }
    }

    &__heading {
      @include font-size(26);

      color: color(white);
      font-weight: 500;
      letter-spacing: -0.03rem;
    }
  }

  .app-tags {
    margin-top: space(3);

    &__heading {
      display: inline-block;
      margin-right: space(0.8);
    }
  }

  .app-info-top {
    padding-top: space(8);
    padding-bottom: space(4);

    @include media-breakpoint-up(md) {
      padding-bottom: space(8);
    }

    .app-info-top__right {
      margin-top: space(3);

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .app-specs {
    &__row:not(:last-child) {
      margin-bottom: space(0.8);
    }

    &__key {
      display: inline-block;
      margin-right: space(0.8);
    }

    &__value {
      @extend .text--mono;

      display: inline-block;

      &--link {
        color: color(action, primary);
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    &__spacer {
      margin: 0 space(2.2);
    }
  }

  .app-launch-bar {
    padding-top: space(4);
    padding-bottom: space(8);
    background-color: transparentize(color(background, dark-navy), 0.5);

    @include media-breakpoint-up(md) {
      padding-top: space(8);
    }

    .row {
      align-items: flex-start;
    }

    &__right {
      display: flex;
      align-items: flex-end;
      margin-top: space(2);

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
    }

    &__title {
      @include font-size(22);

      color: color(grey, tertiary);
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: -0.03rem;

      @include media-breakpoint-up(md) {
        @include font-size(30);

        font-weight: 400;
      }

      @include media-breakpoint-up(xl) {
        @include font-size(36);
      }
    }
  }
}
</style>

<script setup lang="ts">
import type MonacoEditorVue from './editor/MonacoEditor.vue';
import Shim from './editor/Shim.vue';

const editor = ref<InstanceType<typeof MonacoEditorVue> | null>(null);

defineOptions({
  inheritAttrs: false,
});

function scrollToBottom() {
  if (editor.value) {
    editor.value.scrollToBottom();
  }
}

defineExpose({
  scrollToBottom,
});
</script>

<template>
  <Suspense>
    <Shim v-bind="$attrs" ref="editor" />
  </Suspense>
</template>

import { onUnmounted } from 'vue';

import { WNext } from '@clovyr/pollen/compute/wnext';
import {
  type Deployment,
  DeploymentState,
  Instance,
  UserSubscriptionItem,
} from '@clovyr/pollen/types';

import { usePollenStore } from '../stores/pollen_store';

import { usePayments } from './usePayments';

const CHECK_INTERVAL_MILLIS = 5000;
const AUTO_DELETE_APP_IDS = ['ollama-chat', 'otto'];

/**
 * Search for a given target fqdn in the list of active instances
 *
 * @param activeInstances
 * @param target
 * @returns
 */
function hasActiveInstance(activeInstances: Instance[], target: string): boolean {
  return activeInstances.some(({ fqdn }) => target === fqdn);
}

function isWNext(deployment: Deployment): boolean {
  return deployment.hostingProvider === 'wnext';
}

function isRunning(deployment: Deployment) {
  return deployment.state === DeploymentState.Running && deployment.fqdn !== undefined;
}

function isAutoDeleteApp(deployment: Deployment) {
  // For certain apps which are costly to run due to high server costs, we may
  // delete the underlying server instance from the backend. When this happens,
  // the associated deployment should be automatically deleted from the UI.
  //
  // This is really only for demo apps and not generally used.
  return AUTO_DELETE_APP_IDS.includes(deployment.appID);
}

export function useInstanceSleepMonitor() {
  const pollenStore = usePollenStore();
  const wnextAPI = new WNext();
  const payments = usePayments();

  const checkInstances = async () => {
    if (pollenStore.deployments.length === 0 && pollenStore.instances.length === 0) {
      return;
    }

    const activeInstances = await wnextAPI.listInstances({});
    const promises: Promise<void | UserSubscriptionItem>[] = [];
    let refreshing = false;

    for (let i = 0; i < pollenStore.deployments.length; i++) {
      const deployment = pollenStore.deployments[i];

      if (
        deployment.fqdn &&
        isWNext(deployment) &&
        !hasActiveInstance(activeInstances, deployment.fqdn)
      ) {
        if (isAutoDeleteApp(deployment)) {
          // auto-delete some apps
          promises.push(pollenStore.pollen.deleteDeployment(deployment.id));
        } else if (isRunning(deployment)) {
          // instance no longer avail, change to sleeping
          deployment.state = DeploymentState.Sleeping;
          promises.push(pollenStore.pollen.putDeployment(deployment));
          if (!refreshing) {
            promises.push(payments.refreshSubscription());
            refreshing = true;
          }
        }
      }
    }

    // also delete instances
    for (let i = 0; i < pollenStore.instances.length; i++) {
      const instance = pollenStore.instances[i];
      if (instance.fqdn && !hasActiveInstance(activeInstances, instance.fqdn)) {
        promises.push(pollenStore.pollen.deleteInstance(instance.fqdn));
      }
    }

    await Promise.all(promises);
  };

  void checkInstances(); // fire check immediately
  const intervalID = setInterval(checkInstances, CHECK_INTERVAL_MILLIS);

  onUnmounted(() => {
    clearInterval(intervalID);
  });
}

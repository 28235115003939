<script setup lang="ts">
import { storeToRefs } from 'pinia';

import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import {
  firstPartyHosts,
  getHostByID,
  thirdPartyHosts,
  wNextHost,
} from '@clovyr/pollen/fixtures/hosts';
import { type ComputeProviderConfig, ComputeProviderName } from '@clovyr/pollen/types/Host';

import { useLauncherStore } from '@/stores/launcher_store';
import { usePollenStore } from '@/stores/pollen_store';
import { LauncherState } from '@/stores/types';

import RadioBlock from '../blocks/RadioBlock.vue';

import SavedHostsDropdown from './SavedHostDropdown.vue';
import ServerOptionsDropdown from './ServerOptionsDropdown.vue';

const launcherStore = useLauncherStore();
const { selectedHost, status, wnextAppPrice } = storeToRefs(launcherStore);
const { setSelectedHost, setComputeProviderConfig } = launcherStore;

const isWNext = computed(() => selectedHost.value?.id === ComputeProviderName.WNext);

// Logos including clovyr hosting
const combinedHostLogos = computed(() => {
  return [...firstPartyHosts, ...thirdPartyHosts];
});

const { computeProviderConfigs } = storeToRefs(usePollenStore());
const selectedSavedHosts = ref(true);
const haveSavedHosts = computed(
  () => computeProviderConfigs.value && computeProviderConfigs.value.length > 0,
);

const setSelectedOption = async (option: ComputeProviderConfig) => {
  selectedSavedHosts.value = true;
  const hostInfo = getHostByID(option.providerID);
  setSelectedHost(hostInfo);
  await setComputeProviderConfig(option);
};
</script>

<template>
  <div class="host-selector">
    <!-- Review screen (read-only) -->
    <template v-if="status === LauncherState.Review">
      <div>
        <div class="review-host-block options-block">
          <div class="review-host-block__top">
            <template v-if="!isWNext">
              <AppLogo
                :logo="selectedHost!"
                size="medium"
                :pad="
                  selectedHost?.id !== ComputeProviderName.DigitalOcean &&
                  selectedHost?.id !== ComputeProviderName.WNext
                "
              />
              <div class="heading">
                <div class="review-host-block-heading text--h4">{{ selectedHost?.title }}</div>
                <div class="review-host-block-subheading">{{ selectedHost?.subtitle }}</div>
              </div>
            </template>
            <template v-else>
              <ImageMedia :filename="selectedHost!.fullLogoUrl" />
            </template>
          </div>
          <div class="review-host-block__bottom">
            <p class="description" v-if="selectedHost?.id === ComputeProviderName.WNext">
              {{ selectedHost?.description?.replaceAll('PRICE', wnextAppPrice[1].toString()) }}
            </p>
            <p class="description" v-if="selectedHost?.id !== ComputeProviderName.WNext">
              {{ selectedHost?.description }}
            </p>
          </div>

          <!-- Server Regions & Server Size Dropdown -->
          <div class="server-options">
            <ServerOptionsDropdown />
          </div>
        </div>
      </div>
    </template>

    <!-- Editing -->
    <template v-else>
      <!-- Clovyr Hosting -->
      <RadioBlock
        v-if="!haveSavedHosts"
        class="hosting-block first-party"
        name="hosting"
        value="clovyr"
        :logos="[]"
        :selected="selectedHost?.id === ComputeProviderName.WNext"
        @change="
          () => {
            setSelectedHost(wNextHost), (selectedSavedHosts = false);
          }
        "
      >
        <template v-slot:heading-right>
          <div class="free-trial">1-week free trial on us!</div>
          <ImageMedia :filename="wNextHost.fullLogoUrl" />
        </template>
        <template v-slot:body-left>
          {{ wNextHost.description?.replaceAll('PRICE', wnextAppPrice[1].toString()) }}
        </template>
      </RadioBlock>

      <!-- Saved hosts -->
      <SavedHostsDropdown
        v-else
        class="hosting-block host-dropdown"
        :selected="selectedSavedHosts"
        @selected="setSelectedOption($event)"
      />

      <!-- Other host options -->
      <RadioBlock
        class="hosting-block third-party"
        name="hosting"
        value="other"
        :logos="haveSavedHosts ? combinedHostLogos : thirdPartyHosts"
        logoSize="small"
        :selected="selectedHost?.id !== ComputeProviderName.WNext && !selectedSavedHosts"
        @change="
          () => {
            setSelectedHost(), (selectedSavedHosts = false);
          }
        "
      >
        <template v-slot:body-heading>Choose another host</template>
      </RadioBlock>
    </template>
  </div>
</template>

<style lang="scss" scoped>
:deep(.radio-block__inner) {
  @include media-query(phone) {
    padding-left: space(2);
    padding-right: space(2);
  }

  @include media-query(tablet) {
    padding-top: space(3);
    padding-left: space(2);
    padding-right: space(2);
  }
}

:deep(.radio-block__right) {
  @include media-query(phone) {
    padding-bottom: space(2);
  }
  @include media-query(tablet) {
    padding-bottom: space(2);
  }
}

:deep(.block-heading__left) {
  @include media-query(phone) {
    //this converts column back to row
    //overriding some styles here
    flex-wrap: nowrap !important;

    .block-icon {
      margin: 2px !important;
    }
  }

  @include media-query(tablet) {
    //this converts column back to row
    //overriding some styles here
    flex-wrap: nowrap !important;

    .block-icon {
      margin: 2px !important;
    }
  }
}
.third-party {
  @include media-query(phone) {
    :deep(.radio-block__left) {
      transform: translateX(5px);
    }
  }

  @include media-query(tablet) {
    :deep(.radio-block__left) {
      transform: translateX(5px);
    }
  }
}

.first-party {
  @include media-query(phone) {
    :deep(.block-heading__left) {
      display: none;
    }
  }
  @include media-query(tablet) {
    :deep(.block-heading__left) {
      display: none;
    }
  }

  :deep(.radio-block__inner) {
    @include media-breakpoint-only(xs) {
      padding-top: space(4);
    }
    @include media-breakpoint-up(lg) {
      padding-top: space(4);
    }
  }
}

.free-trial {
  position: absolute;
  background-color: #383e52;
  color: #33e672;
  border-radius: 8px;
  z-index: 1;
  padding: space(1) space(3);
  transform: translateX(-50%);
  white-space: pre;
  top: 55px;
  left: 50%;

  @include media-query(phone-xs) {
    font-size: 9px;
  }
}
.review-host-block {
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: space(2);

    @include font(work, regular, 24, 1.2);

    .review-host-block-heading {
      margin-left: space(2);
    }

    .review-host-block-subheading {
      margin-left: space(2);
      color: #33e672;
      font-size: 12px;
    }
  }

  &__bottom {
    :deep(.button) {
      width: 100%;
    }
  }

  &__button {
    margin: space(2) 0 0 0;
  }
}
</style>

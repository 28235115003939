<template>
  <div class="help-page">
    <h2 v-if="headingText" class="help-page__heading">{{ headingText }}</h2>

    <div class="help-page__content">
      <slot />
    </div>

    <HelpControls
      :previousPage="previousPage"
      :nextPage="nextPage"
      @navigate="(page) => $emit('navigate', page)"
    />
  </div>
</template>

<script>
import HelpControls from '@/views/Help/HelpControls.vue';

export default {
  name: 'HelpPage',
  props: {
    headingText: {
      type: String,
      default: '',
    },
    nextPage: {
      type: Object,
      default: () => {},
    },
    previousPage: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    HelpControls,
  },
};
</script>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import type { Deployment } from '@clovyr/pollen';

import Modal from '@/components/elements/Modal.vue';
import Module from '@/components/elements/Module.vue';
import RestoreIcon from '@/components/icons/RestoreIcon.vue';

interface LibraryAppDetail {
  deployment: Deployment;
}
const props = defineProps<LibraryAppDetail>();

const deploymentID = computed(() => props.deployment.id);
const parentRoute: RouteLocationRaw = {
  name: 'LibraryAppDetail',
  params: { id: deploymentID.value },
};

const emit = defineEmits(['closeModal']);

const heading = `All ${props.deployment.appName} backups`;
</script>

<template>
  <Modal :onCloseRouteTo="parentRoute" @close="emit('closeModal')">
    <template v-slot:body>
      <Module
        :hed="heading"
        dek="You may start a restoration from any of the backups below."
        class="module"
      >
        <div class="module__blurb__body">
          <div class="row">
            <div class="grid__col-md-11">backup 1</div>
            <div class="grid__col-md-1">
              <Button :shape="ButtonShape.Circle" class="restore-btn"><RestoreIcon /></Button>
            </div>
          </div>
        </div>
      </Module>
    </template>

    <template v-slot:controls-right>
      <Button label="close" :reversed="true" @click="emit('closeModal')" />
    </template>
  </Modal>
</template>
<style scoped lang="scss">
:deep(.module__head__dek) {
  margin-top: 0.5rem;
}
:deep(.module__head__right) {
  display: none;
}
.module {
  margin-top: 5rem;
  max-width: 50rem;
  margin: auto;

  &__blurb__body {
    color: color(grey, tertiary);
    padding: 1rem 2rem;
  }
  &__blurb__body > .row {
    border-bottom: 1px solid color(grey, primary);
  }
  &__blurb__body > .row,
  &__button {
    align-items: center;
    padding: 0.65rem 0;
  }
}
.grid__col-md-11,
.grid__col-md-1 {
  padding-left: 0.1rem;
}
.grid__col-md-1 {
  justify-content: end;
  display: flex;
  transform: translateX(5px);
}
.restore-btn:hover {
  :deep(.icon-restore) {
    path {
      stroke: color(negative, primary);
    }
  }
}
</style>

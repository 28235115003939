import { ref, watchEffect } from 'vue';

type QueryType = 'mobile' | 'tablet' | 'desktop' | 'large';

export default function useMediaQuery(query: QueryType, direction?: 'default' | 'up') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const matches = ref<any>(false);

  const queries = {
    default: {
      mobile: '(max-width: 767.98px)',
      tablet: '(max-width: 991.98px)',
      desktop: '(max-width: 1199.98px)',
    },
    up: {
      mobile: '(min-width: 576px)',
      tablet: '(min-width: 768px)',
      desktop: '(min-width: 992px)',
      large: '(min-width: 1200px)',
    },
  };

  if (import.meta.env.SSR) {
    // server-side: only true for 'desktop'
    // TODO: change this to 'mobile' when we are fully mobile-first
    matches.value = query === 'desktop';
  } else {
    // client-side: watch media query
    watchEffect((onInvalidate) => {
      const media = window.matchMedia(queries[direction || 'default'][query]);

      if (media.matches !== matches.value) {
        matches.value = media.matches;
      }

      const onChange = () => {
        matches.value = media.matches;
      };

      media.addEventListener('change', onChange);

      onInvalidate(() => {
        media.removeEventListener('change', onChange);
      });
    });
  }

  return matches;
}

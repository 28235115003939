import bg from '@clovyr/bed/assets/images/clovyr-code-bg.jpg';
import { useAbsoluteURL } from '@clovyr/bed/composables/useAbsoluteURL';

/**
 * useHead() block for Clovyr Code pages
 *
 * ! NOTE: This __must__ run before any async/await code. If not, the stack will be munged and
 * !       useRoute() will return undefined. See also: https://github.com/vuejs/router/issues/257
 *
 * @param appName
 */
export const useClovyrCodeHead = (appName?: string, customImage?: string) => {
  const route = useRoute();
  appName ||= 'Clovyr Code';

  const jsonSchema = {
    '@context': 'http://www.schema.org',
    '@type': 'Organization',
    name: 'Clovyr Code | Clovyr',
    description:
      "Clovyr Code is VSCode in your browser. It connects and communicates directly with your git host, without sharing any credentials or information with anyone else, including us. The current beta creates ephemeral, private VPS instances on wNext (hosting by Clovyr) with no sign-up required. We'll be releasing the ability to target any cloud or on-prem instance soon. Get that cozy feeling of developing locally on a machine you control, with the freedom to never worry about setting up project-specific environments again.",
    url: `https://clovyr.app${route.fullPath}`,
  };

  const clovyrCodeHead = {
    title: `${appName} | Clovyr Code`,
    link: [{ rel: 'canonical', href: jsonSchema.url }],
    meta: [
      {
        name: 'description',
        content: jsonSchema.description,
      },
      {
        property: 'og:title',
        content: `${appName} | Clovyr Code`,
      },
      {
        property: 'og:description',
        content: jsonSchema.description,
      },
      {
        property: 'og:url',
        content: jsonSchema.url,
      },
      {
        property: 'og:image',
        content: useAbsoluteURL(customImage || bg),
      },
      {
        property: 'og:image:alt',
        content: `${appName} page graphic.`,
      },
    ],
    script: [
      {
        type: 'application/ld-json',
        children: JSON.stringify(jsonSchema),
      },
    ],
  };

  useHead(clovyrCodeHead);
};

<script setup lang="ts">
import languages from '@clovyr/pollen/fixtures/languages';
import type { Language } from '@clovyr/pollen/types';

import { claimClovyrCode } from '@/composables/claimClovyrCode';

import Modal from '../elements/Modal.vue';

const props = defineProps<{ err?: string }>();
const route = useRoute();
const router = useRouter();
const showModal = ref(false);
const errorMessage = ref('');

onMounted(() => {
  if (props.err === 'unavailable') {
    errorMessage.value = 'No instance available. Please try again in a few minutes';
    // edit url so that reloading this page doesn't show the modal again
    window.history.replaceState({}, '', route.path);
  }
  if (errorMessage.value) {
    showModal.value = true;
  }
});

async function onClickLanguage(lang: Language) {
  try {
    // TODO: check for idle instance state and update UI if it is
    const newRoute = await claimClovyrCode(
      'clovyr-code',
      `code-${lang.name.toLowerCase()}`,
      router,
      undefined,
      true,
    );
    void router.push(newRoute);
  } catch (error) {
    if (error instanceof Error) {
      errorMessage.value = error.message;
      showModal.value = true;
    }
  }
}
</script>

<template>
  <div class="app-list">
    <Button
      v-for="lang in languages"
      :key="lang.name"
      :label="lang.name"
      translucent
      @click="onClickLanguage(lang)"
    >
      <img :src="`https://cstatic.app/code/img/${lang.icon}`" class="lang-icon" />
    </Button>
    <Modal v-if="showModal" @close="showModal = false">
      <template v-slot:body>
        <div class="instance-error">
          <h4>{{ errorMessage }}</h4>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
.app-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;

  :deep(.button__label) {
    text-transform: none;
  }

  :deep(.modal__inner) {
    width: 500px;
    height: 150px;
    padding: 50px;
  }

  :deep(.modal__close) {
    top: 25px;
    right: 25px;
  }

  :deep(.modal__body) {
    margin-top: space(1);
  }

  .lang-icon {
    margin-left: 0.5rem;
  }
}
</style>

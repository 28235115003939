import { type Ref } from 'vue';

import {
  Builder,
  type BuilderStatus,
  type BuildEventPayload,
  type BuildEvents,
  type ErrorPayload,
  type ScriptPayload,
  type StatusPayload,
} from '@clovyr/pollen/cicd';
import { CodeBuilder } from '@clovyr/pollen/cicd/code';
import { WNextRegistryKeyAPI } from '@clovyr/pollen/cicd/registry';
import { RunBuilder } from '@clovyr/pollen/cicd/run';
import type {
  Deployment,
  Publisher,
  PublisherApp,
  PublisherAppVersion,
} from '@clovyr/pollen/types';
import type { DockerCredentials } from '@clovyr/pollen/types/DockerCredentials';

import { useInstanceResume } from '@/composables/useInstanceResume';
import { config } from '@/init/config';

import { usePollenStore } from '../stores/pollen_store';

import { claimClovyrCode } from './claimClovyrCode';
import { useKeepalive } from './useKeepalive';

export function useAppBuilder(
  publisher: Ref<Publisher | undefined>,
  pubApp: Ref<PublisherApp | undefined>,
  pubAppVersion: Ref<PublisherAppVersion | undefined>,
) {
  const router = useRouter();
  const pollenStore = usePollenStore();
  const { startKeepalive, stopKeepalive } = useKeepalive();

  const builderInstance: Ref<Deployment | undefined> = ref();
  let builder: Builder;
  const buildStatus: Ref<BuilderStatus> = ref('inactive');
  const showBuildConsole = ref(false);
  const buildOutput = ref('');

  const addBuildOutput = (type: string, output?: string) => {
    // formatted date/time
    const now = new Date();
    const timestamp = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
    let out = `[${timestamp}] `;
    out += type;
    if (output) {
      out += `:\n${output}\n\n`;
    }
    buildOutput.value += out;
    console.log(out);
  };

  const repository = computed(() => {
    if (publisher.value && pubApp.value) {
      return `${publisher.value.slug}/${pubApp.value.slug}`;
    }
    return '';
  });

  const imageName = computed(() => {
    if (publisher.value && pubApp.value) {
      return `${config.VITE_REGISTRY_HOST}/${repository.value}`;
    }
    return '';
  });

  const imageWithTag = computed(() => {
    return `${imageName.value}:latest`;
  });

  const onClickBuild = async () => {
    if (!(publisher.value && pubApp.value && pubAppVersion.value)) {
      // TODO: handle err, don't build
      return;
    }

    let creds: DockerCredentials | undefined;
    if (pubApp.value?.app_type === 'clovyr_code') {
      // get registry creds for building clovyr code apps, if needed
      // currently no build step for run apps, so no need to push to our registry.
      if (pubApp.value?.id && pollenStore.registryKeys[pubApp.value.id]) {
        creds = pollenStore.registryKeys[pubApp.value.id].creds;
      } else {
        try {
          creds = await new WNextRegistryKeyAPI().createKey({
            publisher_id: publisher.value!.id,
            publisher_app_id: pubApp.value!.id,
            repository: repository.value,
          });
        } catch (e) {
          // TODO: handle this better, abort for now
          return;
        }

        await pollenStore.pollen.putRegistryKey({
          publisherID: publisher.value!.id,
          publisherAppID: pubApp.value!.id,
          creds,
        });
      }
    }

    const output = await builder.build(pubAppVersion.value, imageWithTag.value, creds);
    if (output) {
      addBuildOutput('build output', output!);
      addBuildOutput('build command completed successfully');
    } else {
      addBuildOutput('build failed or skipped');
    }
  };

  const onClickRun = async () => {
    if (!pubAppVersion.value) {
      // TODO: show err?
      return;
    }

    try {
      addBuildOutput('reset output', (await builder.reset(pubApp.value!)) || '');
    } catch (e) {
      // already logged, just return for now
      return;
    }
    const output = await builder.run(pubAppVersion.value, imageWithTag.value);
    if (output) {
      addBuildOutput('run output', output!);
    } else {
      addBuildOutput('run failed or skipped', '');
    }
  };

  /**
   * Preview link on port 8080 of the builder instance
   */
  const previewLink = computed(() => {
    if (!builderInstance.value) {
      return '';
    }
    const fqdn = builderInstance.value?.fqdn;
    const configurekey = builderInstance.value?.accessList[0];

    // login required since the app is running inside a code env - traefik in k8s requires auth
    return `https://8080-${fqdn}/_leaf/login?token=${configurekey}&redir=/`;
  });

  function handleBuildEvent(event: keyof BuildEvents, val: BuildEventPayload) {
    switch (event) {
      case 'status':
        buildStatus.value = (val as StatusPayload).status;
        break;

      case 'script':
        addBuildOutput('script', (val as ScriptPayload).script);
        addBuildOutput('executing...');
        break;

      case 'error':
        addBuildOutput('error', `${(val as ErrorPayload).message}\n${(val as ErrorPayload).body}`);
        addBuildOutput('(failed)');
        break;

      default:
        addBuildOutput(
          'error',
          `received unknown build event: ${event}\n${val ? JSON.stringify(val) : ''}`,
        );
    }
  }

  onMounted(async () => {
    // get or claim clovyrbuild instance, if needed
    if (pubApp.value?.app_type !== 'clovyr_code') {
      return;
    }
    const b = pollenStore.deployments.find((d) => d.appID === 'code-clovyrbuild');
    if (b) {
      builderInstance.value = b;
      // wakeup if needed
      const { startResume } = useInstanceResume(b.instanceName);
      await startResume();
    } else {
      await claimClovyrCode('code-clovyrbuild', 'code-clovyrbuild', router, undefined, true);
      builderInstance.value = pollenStore.deployments.find((d) => d.appID === 'code-clovyrbuild')!;
    }
    if (builderInstance.value?.fqdn) {
      if (pubApp.value?.app_type === 'clovyr_code') {
        builder = new CodeBuilder(builderInstance.value);
      } else {
        builder = new RunBuilder(builderInstance.value);
      }
      builder.on('*', handleBuildEvent);
      void startKeepalive(builderInstance.value.id, builderInstance.value.fqdn);
    }
  });

  onUnmounted(() => {
    if (builder) {
      builder.off('*');
    }
    if (builderInstance.value) {
      stopKeepalive(builderInstance.value.id);
    }
  });

  return {
    buildStatus,
    showBuildConsole,
    buildOutput,
    previewLink,

    onClickBuild,
    onClickRun,
  };
}

<script setup lang="ts">
import { AppLogo, type Logo, type LogoSize } from '@clovyr/bed';
import logoPlaceholderImage from '@clovyr/bed/assets/images/logo-placeholder.svg';

const props = withDefaults(
  defineProps<{
    name?: string;
    fieldValue?: string;
    flavor?: string;
    logos: Logo[];
    logoSize?: LogoSize;
    logoPad?: boolean;
    selected?: boolean;
    useInput?: boolean;
  }>(),
  {
    name: '',
    flavor: '',
    logos: () => [{ logoUrl: logoPlaceholderImage }],
    logoSize: 'small',
    selected: false,
    useInput: true,
  },
);

const rootClass = computed(() => {
  return {
    'radio-block--simple': props.flavor === 'simple',
    'radio-block--selected': props.selected,
  };
});

const logoClass = computed(() => {
  if (props.logos.length > 1) {
    return 'block-icon';
  }
  return '';
});
</script>

<template>
  <div :class="rootClass" class="radio-block">
    <label class="radio-block__inner">
      <div class="radio-block__left">
        <div class="block-heading">
          <div class="block-heading__left">
            <AppLogo
              v-for="(logo, index) in logos"
              :key="index"
              :logo="logo"
              :size="logoSize"
              :pad="logoPad || logo.logoPad"
              :class="logoClass"
            />
          </div>

          <div class="block-heading__right">
            <slot name="heading-right" />
          </div>
        </div>

        <div class="block-body">
          <div class="block-body__heading">
            <slot name="body-heading" />
          </div>

          <div class="block-body__left">
            <slot name="body-left" />
          </div>

          <div class="block-body__right">
            <slot name="body-right" />
          </div>
        </div>
      </div>
      <div v-if="useInput" class="radio-block__right">
        <div class="radio-controls">
          <input
            :name="name"
            :fieldValue="fieldValue"
            @change="$emit('input', fieldValue)"
            class="radio-input"
            type="radio"
            :checked="selected"
          />
          <span class="radio-control"></span>
        </div>
      </div>
    </label>
  </div>
</template>

<style scoped lang="scss">
.radio-block {
  &__inner {
    @extend .background-gradient--3;

    @include borders-rounded;

    display: flex;
    border: 1px solid transparentize(color(background, darker), 0.4);
    justify-content: space-between;
    padding: space(2.4) space(3.6);
    cursor: pointer;

    &:focus-within {
      border-color: color(action);
    }
  }

  &__left {
    @at-root .radio-block--simple & {
      display: flex;
      flex-direction: column;
    }
  }

  &__right {
    padding-top: space(1);
  }

  .block-heading {
    display: flex;
    align-items: center;
    justify-content: left;

    &__left {
      display: flex;
      flex-wrap: wrap;
      margin-right: space(1.2);
    }
  }

  .radio-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .radio-control {
    display: block;
    width: space(2);
    height: space(2);
    border: 1px solid color(action);

    @include borders-sphere;
  }

  .radio-input:checked + .radio-control {
    background: radial-gradient(color(action) 50%, rgb(255 0 0 / 0%) 51%);
  }

  &--selected {
    .radio-block__inner {
      border-color: color(action);
    }
  }
}
</style>

<script setup lang="ts">
/**
 * Oauth popup callback
 *
 * The purpose of this view is to to receive the oauth response and show an
 * interstitial to the user while we complete the connection. In this case, we
 * show a brief spinner but we can also add a success message, etc.
 *
 * The same callback handler can be used for any Cloud Provider which supports
 * Oauth, such as DigitalOcean or Linode.
 */
import { useRoute } from 'vue-router';

import { fromQS } from '@clovyr/pollen/http/qs';

import Spinner from '@/components/elements/Spinner.vue';

const route = useRoute();

/**
 * error looks like
 *
 * hash: #error=access_denied&error_description=The+resource+owner+or+authorization+server+denied+the+request.&state=3VMXciYHqbngdoEU9qephRY00I10VMCI
 */

/**
 * success looks like
 *
 * hash: #access_token=doo_v1_<snip>&token_type=bearer&expires_in=2592000&state=Zl3ZLrG7ArXVRKOJZm7BqussZjLzNNIU
 */

const hash = route.hash.replace(/^#/, '');
const parsed = fromQS(hash);

onMounted(() => {
  if (!import.meta.env.SSR && typeof window !== 'undefined') {
    window.opener.postMessage({ source: 'clovyr-oauth', payload: parsed });
  }
});
</script>

<template>
  <Spinner />
</template>

<template>
  <div :class="CSSClasses" class="accordion">
    <div @click="handleHeadingClick" class="accordion-heading">
      <slot name="heading-left" />

      <slot name="heading-right">
        <div class="accordion__icon">
          <SlideIcon />
        </div>
      </slot>
    </div>

    <div v-show="!closed" class="accordion-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import SlideIcon from '@/components/icons/SlideIcon.vue';

export default {
  name: 'Accordion',
  props: {
    disableHeadingClick: {
      type: Boolean,
      default: false,
    },
    defaultClosed: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SlideIcon,
  },
  data() {
    return {
      closed: this.defaultClosed,
    };
  },
  computed: {
    CSSClasses() {
      return {
        'accordion--open': !this.closed,
      };
    },
  },
  methods: {
    handleHeadingClick() {
      if (!this.disableHeadingClick) {
        this.closed = !this.closed;
        this.$emit('toggled', this.closed);
      }
    },
  },
  watch: {
    defaultClosed(newVal) {
      this.closed = newVal;
    },
  },
};
</script>

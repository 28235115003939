<template>
  <div :class="CSSClasses" class="background">
    <div v-if="overlayImage" :style="overlayImageStyles" class="background__overlay-image"></div>
    <div :style="backgroundImageStyles" class="background__image"></div>

    <div v-if="gradient" :class="gradientCSSClass" class="background__gradient"></div>
  </div>
</template>

<script>
export default {
  name: 'Background',
  props: {
    image: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      default: () => [],
    },
    gradient: {
      type: [Number, String],
      default: '',
    },
    blur: {
      type: Boolean,
      default: false,
    },
    overlayImage: {
      type: String,
      default: '',
    },
  },
  computed: {
    CSSClasses() {
      return [this.blur && `background--blur`];
    },
    backgroundImageStyles() {
      let backgroundImagePaths = '';
      if (this.images.length > 0) {
        backgroundImagePaths = this.images
          .map((image) => {
            return `url(${image})`;
          })
          .join(', ');
      } else {
        backgroundImagePaths = `url(${this.image})`;
      }
      return { backgroundImage: `${backgroundImagePaths}` };
    },
    overlayImageStyles() {
      return { backgroundImage: `url(${this.overlayImage})` };
    },
    gradientCSSClass() {
      return `background-gradient--${this.gradient}`;
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  &--blur {
    filter: blur(space(3.4, fixed));
  }

  &__image,
  &__gradient,
  &__overlay-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__image,
  &__overlay-image {
    @include background-image;
  }

  &__overlay-image {
    z-index: 1;
  }
}
</style>

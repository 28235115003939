<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { Field } from 'vee-validate';

import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import { CodeBaseImages } from '@clovyr/pollen/cicd/code';
import { FileAPI } from '@clovyr/pollen/file';

import ClovyrEditor from '@/components/forms/ClovyrEditor.vue';
import FieldInfoIcon from '@/components/icons/FieldInfoIcon.vue';
import UploadIcon from '@/components/icons/UploadIcon.vue';
import { useDrag } from '@/composables/useDrag';
import { type PublisherAppFileNew, useEditStore } from '@/stores/edit_store';

import DevelopFiles from './DevelopFiles.vue';

const editStore = useEditStore();
const { pubAppVersion } = storeToRefs(editStore);
const uploadInput = ref<HTMLInputElement | null>();

const files = computed(() => pubAppVersion.value?.files?.filter((f) => !f.deleted_at) || []);

async function onDropFile(droppedFile: File): Promise<void> {
  const f = await new FileAPI().upload(droppedFile);

  // @ts-expect-error missing props (not needed when creating new file)
  const file: PublisherAppFileNew = {
    _id: crypto.randomUUID(),
    name: f.filename,
    content: f.fileurl,
  };
  if (!pubAppVersion.value.files) {
    pubAppVersion.value.files = [];
  }
  pubAppVersion.value.files.push(file);
}

async function onFileChange(event: Event) {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    void onDropFile(file);
  }
}

function onClickAddNewFile() {
  if (uploadInput.value) {
    uploadInput.value.showPicker();
  }
}

function onDelete(fileID: string) {
  if (!fileID) {
    return;
  }

  const file = pubAppVersion.value.files.find(
    // eslint-disable-next-line no-underscore-dangle
    (f) => (f as PublisherAppFileNew)._id === fileID || f.id === fileID,
  );
  if (file) {
    file.deleted_at = new Date();
  }
}

const { isDragging, onDragOver, onDragEnter, onDragLeave, onDrop } = useDrag(true, onDropFile);
</script>

<template>
  <div class="develop-setup">
    <div class="row">
      <div class="column">
        <div class="selectinput-block">
          <div class="selectinput-block__label-holder">
            <label for="BaseImage" class="selectinput-block__label">Base Image:*</label>
            <Tooltip content="The base image for your app."> <FieldInfoIcon /></Tooltip>
          </div>

          <Field
            type="text"
            name="BaseImage"
            v-slot="{ field, errorMessage }"
            v-model="pubAppVersion.base_image"
          >
            <select class="selectinput-block__input" v-bind="field">
              <option v-for="(option, idx) in CodeBaseImages" :key="idx" :value="option.value">
                {{ option.name }}
              </option>
            </select>

            <div v-if="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>
          </Field>
        </div>
      </div>
    </div>
    <div class="row double-input textarea">
      <div class="column">
        <Field
          type="text"
          name="BuildScript"
          v-slot="{ errorMessage }"
          :model-value="pubAppVersion.build_script"
        >
          <div class="textarea-block" :class="{ 'textarea-block--has-error': !!errorMessage }">
            <div class="textarea-block__label-holder">
              <label for="BuildScript" class="textarea-block__label">Build Script:</label>
              <Tooltip
                content="Enter the script used to build your application. This can include commands for compiling, packaging, and other build steps."
              >
                <FieldInfoIcon />
              </Tooltip>
            </div>

            <div class="textarea-block__input-holder">
              <ClovyrEditor
                class="textarea-block__input"
                language="shell"
                v-model="pubAppVersion.build_script"
                allow-drop
              />
            </div>

            <div v-if="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>
          </div>
        </Field>
      </div>
      <div class="column">
        <Field
          type="text"
          name="InitScript"
          v-slot="{ errorMessage }"
          :model-value="pubAppVersion.init_script"
        >
          <div class="textarea-block" :class="{ 'textarea-block--has-error': !!errorMessage }">
            <div class="textarea-block__label-holder">
              <label for="InitScript" class="textarea-block__label">Init Script:</label>
              <Tooltip
                content="Enter the script used to initialize your application. This can include commands for setting up your application environment, installing dependencies, and other initialization steps."
              >
                <FieldInfoIcon />
              </Tooltip>
            </div>

            <div class="textarea-block__input-holder">
              <ClovyrEditor
                class="textarea-block__input"
                language="shell"
                v-model="pubAppVersion.init_script"
                allow-drop
              />
            </div>

            <div v-if="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>
          </div>
        </Field>
      </div>
    </div>
    <div class="row">
      <div
        class="file-list-container"
        @dragover="onDragOver"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @drop="onDrop"
      >
        <Button @click.stop.prevent="onClickAddNewFile" :shape="ButtonShape.Circle">
          <input type="file" class="file-upload" ref="uploadInput" @change="onFileChange" />
          <UploadIcon />
        </Button>
        <DevelopFiles :files="files" :dropping="isDragging" @delete-file="onDelete" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.develop-setup {
  margin-top: 1rem;
}
.row {
  margin-top: 1rem;
}
.file-list-container {
  display: flex;
  margin: 1rem 0;
}
.button:hover {
  background-color: color(grey, primary);
}
input.file-upload {
  display: none;
}
</style>

import type { Dict } from './util';

/**
 * Values represents the runtime configuration for an application. In addition
 * to those options specified in the Manifest, some options may be internal to
 * clovyr, such as:
 *
 * - clovyr_fqdn
 * - clovyr_access_key
 * - clovyr_secret_key
 */
export type Values = Dict;

// Well-known variables used for internal clovyr-specific settings used in the
// Values map when deploying an application.
export const CLOVYR_FQDN = 'clovyr_fqdn';
export const CLOVYR_CUSTOM_DOMAIN = 'clovyr_custom_domain';
export const CLOVYR_HOST_RULES = 'clovyr_host_rules';
export const CLOVYR_S3_URL = 'clovyr_s3_url';
export const CLOVYR_ACCESS_KEY = 'clovyr_access_key';
export const CLOVYR_SECRET_KEY = 'clovyr_secret_key';
export const CLOVYR_BACKUP_PASSWORD = 'clovyr_backup_password';
export const CLOVYR_CONFIGURE_KEY = 'clovyr_configure_key';
export const CLOVYR_TREFOIL_URL = 'clovyr_trefoil_url';

<script setup lang="ts">
import { type Deployment, DeploymentState } from '@clovyr/pollen/types';

import DestroyedStateIcon from '@/components/icons/DestroyedStateIcon.vue';
import PinnedIcon from '@/components/icons/PinnedIcon.vue';
import RunningIconWShadow from '@/components/icons/RunningIconWShadow.vue';
import SleepingIcon from '@/components/icons/SleepingIcon.vue';
import { AppStatus, useAppStatus } from '@/composables/useAppStatus';

type AppStateProps = {
  deployment: Deployment;
};

const props = defineProps<AppStateProps>();

const deploymentID = computed(() => props.deployment.id);
const { appStatus } = useAppStatus(deploymentID);

const appState = computed(() => {
  switch (appStatus.value) {
    case AppStatus.Sleeping:
    case AppStatus.TrialExpired:
      return {
        icon: SleepingIcon,
        class: DeploymentState.Sleeping,
      };

    // case DeploymentState.Destroyed:
    //   appStateClass.value = DeploymentState.Destroyed;
    //   return DestroyedStateIcon;

    case AppStatus.Running:
    case AppStatus.Launching:
      return {
        icon: RunningIconWShadow,
        class: DeploymentState.Running,
      };

    default:
      return {
        class: DeploymentState.Running,
      };
  }
});

const appStatusClassName = computed(() => [
  appState.value.class && `app-state--${appState.value.class}`,
]);
</script>

<template>
  <div :class="appStatusClassName" class="app-state">
    <component :is="deployment.isPinned ? PinnedIcon : appState.icon" />
  </div>
</template>

<style scoped lang="scss">
.app-state {
  // app status

  &.app-status--active {
    color: color(action);
  }

  &.app-status--expiring {
    color: color(yellow);
  }

  &.app-status--expired,
  &.app-state--destroyed {
    color: color(bad);
  }

  // icon default colors

  &.app-state--sleeping {
    color: color(grey);
  }

  .icon-pin {
    color: color(action);
  }
}
</style>

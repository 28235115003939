export interface Nostr {
  getPublicKey(): Promise<string>;
  signEvent(event: NostrEvent): Promise<SignedNostrEvent>;
  nip44: Nip44;
}

export interface Nip44 {
  encrypt(pubkey: string, plaintext: string): Promise<string>;
  decrypt(pubkey: string, cihpertext: string): Promise<string>;
}

export interface NostrEvent {
  created_at: number;
  kind: number;
  tags: string[][];
  content: string;
}

export interface SignedNostrEvent extends NostrEvent {
  id: string;
  pubkey: string;
  sig: string;
}

/**
 * Check if the browser window supports Nostr NIP's 07 & 44
 *
 * @returns true if supported
 */
export function isNostrPresent(): boolean {
  if (import.meta.env.SSR || typeof window === 'undefined') {
    return false;
  }
  return Boolean((window as any)?.nostr?.nip44);
}

export function newNostr(): Nostr {
  if (!isNostrPresent()) {
    return nullNostr();
  }
  return (window as any).nostr;
}

/**
 * Returns an implementation of Nostr that throws an error when any method is called.
 * This is called the "Null Object Pattern".
 */
function nullNostr(): Nostr {
  return {
    nip44: {
      async encrypt() {
        throw new Error('window.nostr not defined');
      },
      async decrypt() {
        throw new Error('window.nostr not defined');
      },
    },
    async getPublicKey() {
      throw new Error('window.nostr not defined');
    },
    async signEvent() {
      throw new Error('window.nostr not defined');
    },
  };
}

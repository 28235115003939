/* eslint-disable no-bitwise, no-fallthrough, default-case-last, default-case  */

const CROCKFORD_ALPHABET = new TextEncoder().encode('0123456789ABCDEFGHJKMNPQRSTVWXYZ');

const CROCKFORD_DECODE_MAP = (() => {
  const decodeMap = new Uint8Array(256);
  for (let i = 0; i < decodeMap.length; i++) {
    decodeMap[i] = 0xff;
  }
  for (let i = 0; i < CROCKFORD_ALPHABET.length; i++) {
    decodeMap[CROCKFORD_ALPHABET[i]] = i;
  }
  return decodeMap;
})();

/**
 * Encodes the given bytes to base32 using the Crockford alphabet and no
 * padding characters.
 *
 * Algorithm ported from:
 * https://github.com/golang/go/blob/go1.19.2/src/encoding/base32/base32.go
 *
 * More information about the alphabet used.
 * https://www.crockford.com/base32.html
 *
 * @param src - the bytes to encode
 * @returns - the base32 encoded data as an ASCII string
 */
export function encodeBase32(src: Uint8Array): string {
  const alphabet = CROCKFORD_ALPHABET;
  const encodedLen = Math.floor((src.length * 8 + 4) / 5);
  const dst = new Uint8Array(encodedLen);

  let srcRemaining = src.length;
  let dstRemaining = encodedLen;
  while (srcRemaining > 0) {
    const b = new Uint8Array(8);
    const destOffset = dst.length - dstRemaining;
    const srcOffset = src.length - srcRemaining;

    // Unpack 8x 5-bit source blocks into a 5 byte
    // destination quantum
    switch (srcRemaining) {
      default:
        b[7] = src[srcOffset + 4] & 0x1f;
        b[6] = src[srcOffset + 4] >> 5;
      case 4:
        b[6] |= (src[srcOffset + 3] << 3) & 0x1f;
        b[5] = (src[srcOffset + 3] >> 2) & 0x1f;
        b[4] = src[srcOffset + 3] >> 7;
      case 3:
        b[4] |= (src[srcOffset + 2] << 1) & 0x1f;
        b[3] = (src[srcOffset + 2] >> 4) & 0x1f;
      case 2:
        b[3] |= (src[srcOffset + 1] << 4) & 0x1f;
        b[2] = (src[srcOffset + 1] >> 1) & 0x1f;
        b[1] = (src[srcOffset + 1] >> 6) & 0x1f;
      case 1:
        b[1] |= (src[srcOffset + 0] << 2) & 0x1f;
        b[0] = src[srcOffset + 0] >> 3;
    }

    // Encode 5-bit blocks using the base32 alphabet
    const blockSize = Math.min(8, dstRemaining);
    for (let i = 0; i < blockSize; i++) {
      dst[i + destOffset] = alphabet[b[i] & 31];
    }

    // Skip padding
    if (srcRemaining < 5) {
      break;
    }

    srcRemaining -= 5;
    dstRemaining -= 8;
  }

  return new TextDecoder().decode(dst);
}

/**
 * Decodes a base32-encoded string into a byte array using the Crockford
 * alphabet and no padding characters.
 *
 * Algorithm ported from:
 * https://github.com/golang/go/blob/go1.19.2/src/encoding/base32/base32.go
 *
 * More information about the alphabet used.
 * https://www.crockford.com/base32.html
 *
 * @param encoded - the base32 encoded dataa as an ASCII string
 * @returns - the decoded bytes
 */
export function decodeBase32(encoded: string): Uint8Array {
  const decodeMap = CROCKFORD_DECODE_MAP;
  const src = new TextEncoder().encode(encoded);

  const decodedLen = Math.floor((src.length * 5) / 8);
  const dst = new Uint8Array(decodedLen);

  let end = false;
  let dsti = 0;
  let srcRemaining = src.length;
  let srcOffset = 0;
  while (srcRemaining > 0 && !end) {
    // Decode quantum using the base32 alphabet
    const dbuf = new Uint8Array(8);
    let dlen = 8;

    for (let j = 0; j < 8;) {
      if (srcRemaining === 0) {
        // We have reached the end and are not expecting any padding
        dlen = j;
        end = true;
        break;
      }
      const inByte = src[srcOffset];
      srcRemaining -= 1;
      srcOffset += 1;

      if (inByte === 0xff && j >= 2 && srcRemaining < 8) {
        // We've reached the end and there's padding
        if (srcRemaining + j < 8 - 1) {
          // not enough padding
          throw corruptInputError(src.length);
        }
        for (let k = 0; k < 8 - 1 - j; k++) {
          if (srcRemaining > k && src[srcOffset + k] !== 0xff) {
            // incorrect padding
            throw corruptInputError(src.length - srcRemaining + k - 1);
          }
        }
        dlen = j;
        end = true;
        // 7, 5 and 2 are not valid padding lengths, and so 1, 3 and 6 are not
        // valid dlen values. See RFC 4648 Section 6 "Base 32 Encoding" listing
        // the five valid padding lengths, and Section 9 "Illustrations and
        // Examples" for an illustration for how the 1st, 3rd and 6th base32
        // src bytes do not yield enough information to decode a dst byte.
        if (dlen === 1 || dlen === 3 || dlen === 6) {
          throw corruptInputError(src.length - srcRemaining - 1);
        }
        break;
      }
      dbuf[j] = decodeMap[inByte];
      if (dbuf[j] === 0xff) {
        throw corruptInputError(src.length - srcRemaining - 1);
      }
      j++;
    }

    // Pack 8x 5-bit source blocks into 5 byte destination
    // quantum
    switch (dlen) {
      case 8:
        dst[dsti + 4] = (dbuf[6] << 5) | dbuf[7];
      case 7:
        dst[dsti + 3] = (dbuf[4] << 7) | (dbuf[5] << 2) | (dbuf[6] >> 3);
      case 5:
        dst[dsti + 2] = (dbuf[3] << 4) | (dbuf[4] >> 1);
      case 4:
        dst[dsti + 1] = (dbuf[1] << 6) | (dbuf[2] << 1) | (dbuf[3] >> 4);
      case 2:
        dst[dsti + 0] = (dbuf[0] << 3) | (dbuf[1] >> 2);
    }
    dsti += 5;
  }

  return dst;
}

function corruptInputError(offset: number): Error {
  return new Error(`illegal base32 data at input byte ${offset}`);
}

<script setup lang="ts">
import { ImageMedia } from '@clovyr/bed';

const props = defineProps<{
  bigHead?: boolean;
  hed?: string;
  dek?: string;
  img?: string;
  tabs?: string[];
  initialTab?: string;
}>();

const activeTab = ref('tab1');

const activateTab = (tab) => {
  activeTab.value = tab;
};

onBeforeMount(() => {
  if (props.initialTab) {
    activeTab.value = props.initialTab;
  }
});
</script>

<template>
  <div
    class="module"
    :class="{
      'module--bighead': bigHead,
      'module--has-ctas': $slots.ctas,
    }"
  >
    <div class="module__head" v-if="hed || dek">
      <div class="module__head__grid row">
        <div class="module__head__left grid__col">
          <ImageMedia v-if="img" :filename="img" class="image-media" />
          <!--Use either Hed or Tabs, not both-->
          <div class="module__head__sub" v-if="!tabs">
            <h3 v-if="hed" class="module__head__hed">{{ hed }}</h3>
            <slot name="option"></slot>
          </div>
          <div class="tab-group" v-if="tabs">
            <div class="module__head__sub" :class="{ active: activeTab === 'tab1' }">
              <a @click="activateTab('tab1')"
                ><h3 class="module__head__hed">{{ tabs[0] }}</h3></a
              >
            </div>
            <div class="module__head__sub" :class="{ active: activeTab === 'tab2' }">
              <a @click="activateTab('tab2')"
                ><h3 class="module__head__hed">{{ tabs[1] }}</h3></a
              >
            </div>
          </div>
          <div v-if="dek" class="module__head__dek">{{ dek }}</div>
        </div>
        <div class="module__head__right grid__col">
          <slot name="heading"></slot>
        </div>
      </div>
    </div>
    <div class="module__body" v-if="$slots.default">
      <slot></slot>
    </div>
    <div class="module__body default2" v-if="$slots.default2">
      <slot name="default2"></slot>
    </div>
    <div class="module__body default3" v-if="$slots.default3">
      <slot name="default3"></slot>
    </div>
    <div class="module__body" v-if="$slots.tab1 && $slots.tab2">
      <slot name="tab1" v-if="activeTab === 'tab1'"></slot>
      <slot name="tab2" v-if="activeTab === 'tab2'"></slot>
    </div>
    <div class="module__ctas" v-if="$slots.ctas">
      <slot name="ctas"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.image-media {
  height: space(10);
}
.tab-group {
  display: flex;
}
.tab-group > .module__head__sub {
  margin-right: 1rem;
}
.active {
  border-bottom: 1px solid white;
  padding-bottom: 0.5rem;
}
</style>

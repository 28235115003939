<script lang="ts" setup>
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import type { Email } from '@clovyr/pollen/mail/types';

import TrashIcon from '../../components/icons/TrashIcon.vue';

const emit = defineEmits(['view', 'delete']);

defineProps<{ msg?: Email }>();
</script>

<template>
  <!-- TODO: show read/unread status via msg.read boolean? it's ok if it is only
  temporary (does not survive hard refresh, as mailhog doesn't give this info)
  -->
  <div class="msg-item" @click="emit('view', msg)">
    <div class="msg-item__body">
      <div v-if="!!msg" class="grid">
        <div class="grid__col">
          <div class="msg-heading">
            <span>From:</span><span>{{ msg.from }}</span>
          </div>
          <div class="msg-heading">
            <span>To:</span><span>{{ msg.to }}</span>
          </div>
          <!-- Maybe pin this at the bottom-edge of the div? -->
          <div class="msg-heading">
            <span>Subject:</span><span>{{ msg.subject }}</span>
          </div>
          <div class="msg-heading">{{ msg.date }}</div>
        </div>
        <div class="btn-group grid">
          <Button title="click" @click="emit('view', msg)">View</Button>
          <Button @click="emit('delete', msg)" :shape="ButtonShape.Circle">
            <TrashIcon />
          </Button>
        </div>
      </div>
      <div v-else>No messages</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.msg-item {
  cursor: pointer;
  --msg-item-pad-vert: #{space(1)};
  --msg-item-pad-horiz: #{space(2)};
  --msg-item-margin-bottom: #{space(2)};
  --msg-item-border-radius: #{space(2)};

  @include margin(bottom, var(--msg-item-margin-bottom));
  @include margin(left right, calc(var(--msg-item-pad-horiz) * -1));

  background: linear-gradient(180deg, rgb(65 59 79 / 50%) 0%, #413b4f 91.78%);
  border-radius: var(--msg-item-border-radius);

  @supports (backdrop-filter: blur()) {
    backdrop-filter: blur(rem(18));
    /* stylelint-disable scss/no-global-function-names */
    background: linear-gradient(
      180deg,
      rgb(65 59 79 / 40%) 0%,
      transparentize(#413b4f, 0.4),
      91.78%
    );
    /* stylelint-enable scss/no-global-function-names */
  }

  @include media-query(phone) {
    @include margin(left right, 0);
  }

  &__body {
    padding: var(--msg-item-pad-vert) var(--msg-item-pad-horiz);
    border-radius: 0 0 var(--msg-item-border-radius) var(--msg-item-border-radius);
  }

  &__ctas {
    @include flex($justify: flex-end);

    padding: 0 var(--module-pad-horiz) var(--module-pad-vert);

    .button {
      @include media-query(full-sm) {
        @include font-size(14, mono);
      }

      @include media-query(tablet) {
        @include font-size(12, mono);
      }
    }
  }

  .msg-heading {
    color: lightgray;
    padding: 1.5px;
    span:first-child {
      font-weight: bold;
      color: #ffffff;
      padding-right: space(0.75);
    }
  }
  .msg-heading:last-child {
    font-weight: bolder;
  }
  .btn-group {
    padding: space(3);
  }
}
</style>

import { storeToRefs } from 'pinia';

import type { Manifest } from '@clovyr/pollen/manifest';

import { appURL } from '@/router/util';
import { useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';
import { ShowAuthForm } from '@/views/Settings/types';

export const useLaunchButton = (app: Manifest) => {
  const router = useRouter();

  const pollenStore = usePollenStore();
  const { isLoggedInWithoutSecret, isLocked, isVaultLocked } = storeToRefs(pollenStore);
  const { eventBus } = useEventBus();

  function handleLaunch() {
    void router.push({ path: `${appURL(app)}/launch` });
  }

  const handleLaunchClicked = () => {
    if (isLoggedInWithoutSecret.value) {
      eventBus.emit('modal:login:show', {
        form: ShowAuthForm.SecretKeyForm,
        opts: {
          hed: 'In order to launch the app under this account, you need to provide your Secret Key',
          modal: true,
        },
      });
    } else if (isLocked.value) {
      eventBus.emit('modal:login:show', {
        form: ShowAuthForm.Locked,
        opts: {
          hed: 'In order to launch an app under this account, you must unlock your account',
          modal: true,
        },
      });
    } else if (!isVaultLocked.value) {
      handleLaunch();
    }
  };

  function onLoginClose() {
    if (!isVaultLocked.value) {
      handleLaunch();
    }
  }

  onMounted(() => {
    if (isVaultLocked.value) {
      eventBus.on('modal:login:close', onLoginClose);
    }
  });

  onUnmounted(() => {
    eventBus.off('modal:login:close', onLoginClose);
  });

  return {
    handleLaunchClicked,
  };
};

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { usePollenStore } from '../../stores/pollen_store';

import PublisherCard from './PublisherCard.vue';
import PublishersLanding from './PublishersLanding.vue';

const { publishers, isPublisher } = storeToRefs(usePollenStore());
</script>

<template>
  <div class="publisher grid__col-md-12" v-if="isPublisher">
    <div class="intro">
      <h1 class="publisher__title">Publisher Portal</h1>
      <div class="break">
        <div class="break__text">select publisher below</div>
        <div class="break__line grid__col-md-7"></div>
      </div>
    </div>
    <div class="publisher__contents">
      <div class="publishers">
        <PublisherCard v-for="pub in publishers" :key="pub.id" :pub="pub" />
      </div>
    </div>
  </div>
  <PublishersLanding v-else />
</template>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;

  @include media-breakpoint-down(md) {
    display: block;
  }
}
.publisher {
  padding-top: space(10);
  padding-bottom: space(5);
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-left: space(1);

  @include media-breakpoint-up(md) {
    margin-left: space(5);
  }

  .publishers {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-left: space(2);
    }

    :deep(.app-block__inner) {
      cursor: pointer;
    }
  }

  .intro {
    padding-left: space(2.5);
  }

  .break {
    display: flex;
    margin-top: space(2);
    margin-bottom: space(0.5);

    &__text {
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.16em;
      font-family: 'IBM Plex Mono';
      color: color(grey, tertiary);
    }
    &__line {
      border-top: 1px solid #ada6b7;
      margin-left: space(1);
      margin-top: space(1);
    }
  }

  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
  }

  &__subtitle {
    color: color(grey, primary);
    margin-top: space(2);
    margin-bottom: space(5);
  }

  &__contents {
    padding-right: 6rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    padding-bottom: space(3);
    padding-top: space(3);

    .button:first-child {
      margin-right: space(3);
    }

    @extend .grid__col;

    @include media-query(tablet) {
      flex-direction: column;
      align-items: center;

      .button {
        width: col-width(6);
        margin-right: 0 !important;
        margin-bottom: space(2);
      }
    }

    @include media-query(tablet) {
      flex-direction: column;
      align-items: center;

      .button {
        width: col-width(6);
        margin-right: 0 !important;
        margin-bottom: space(2);
      }
    }

    @include media-query(phone) {
      flex-direction: column;
      align-items: center;

      .button {
        width: col-width(6);
        margin-right: 0 !important;
        margin-bottom: space(2);
      }
    }

    @include media-breakpoint-down(md) {
      .button {
        font-size: 12px;
      }
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
      .button {
        width: fit-content;
        font-size: 10px;
      }
    }
  }
}
</style>

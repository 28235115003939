import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';

type Snippet = Omit<monaco.languages.CompletionItem, 'range'>;

const ConfigSnippetYAML: Snippet[] = [
  {
    label: '"config item"',
    kind: monaco.languages.CompletionItemKind.Snippet,
    documentation: 'Config item template',
    insertText: `CONFIG_NAME:
  name: Configuration Name
  random: false # true if value should be randomly generated string
  location: env
  value:
    description: "Description of config item"
    type: string # string, number, boolean
    default: "some-default-value" # can refer to other variables using mustache syntax, e.g., {{ OTHER_VAR_NAME }}
    examples: # can be used for showing enum values
      - "example1"
      - "example2"

  `,
  },
];

function addRangeToSnippet(snippets: Snippet[], position: monaco.Position) {
  return snippets.map((item) => {
    return {
      ...item,
      range: new monaco.Range(
        position.lineNumber,
        position.column,
        position.lineNumber,
        position.column,
      ),
    };
  });
}

/**
 * Add code snippets for our various yaml configs
 *
 * @returns
 */
function createDependencyProposals(path: string, position: monaco.Position) {
  if (path.endsWith('config.yaml')) {
    return addRangeToSnippet(ConfigSnippetYAML, position);
  }

  return [];
}

monaco.languages.registerCompletionItemProvider('yaml', {
  provideCompletionItems(model, position) {
    return {
      suggestions: createDependencyProposals(model.uri.path, position),
    };
  },
});

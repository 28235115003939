<template>
  <BaseSvg viewBox="0 0 20 20" width="20" height="20" fill="none">
    <path
      vector-effect="non-scaling-stroke"
      d="M5.68921 8.7996C5.68921 6.3458 7.66999 4.36502 10.1238 4.36502C12.5776 4.36502 14.5584 6.3458 14.5584 8.7996"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      vector-effect="non-scaling-stroke"
      d="M2.76233 9.40565C3.07275 13.2046 6.25086 16.1906 10.1237 16.1906C11.1585 16.1906 12.1488 15.9688 13.0505 15.5845C12.7401 11.7855 9.56201 8.79959 5.68915 8.79959C4.65441 8.79959 3.66403 9.02132 2.76233 9.40565Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      vector-effect="non-scaling-stroke"
      d="M14.5584 8.79959C12.888 8.79959 11.3655 9.34652 10.1238 10.2778C11.7794 11.5195 12.888 13.4115 13.0654 15.5845C15.5192 14.5202 17.2782 12.1846 17.5 9.40565C16.5983 9.02132 15.6079 8.79959 14.5584 8.79959Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'GardenIcon',
  components: {
    BaseSvg,
  },
};
</script>

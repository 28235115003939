<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { getAllQueryParams } from '@clovyr/pollen/http/qs';
import { AuthState } from '@clovyr/pollen/pollen';

import Modal, { type ModalSize } from '../components/elements/Modal.vue';
import { decodeRoute } from '../router/util';
import { usePollenStore } from '../stores/pollen_store';

import AuthForm from './Settings/AuthForm.vue';
import { ShowAuthForm } from './Settings/types';

const props = defineProps<{
  form?: ShowAuthForm;
}>();

const authForm = ref(props.form ?? ShowAuthForm.Login);

const router = useRouter();
const pollenStore = usePollenStore();
const { isUserAuthenticated, authState } = storeToRefs(pollenStore);

const authTheme: Ref<ModalSize> = ref('narrow');
const handleThemeChange = (theme) => {
  authTheme.value = theme;
};

const showAuthModal = ref(false);

async function doRedirect() {
  if (!isUserAuthenticated.value) {
    return router.push({ path: '/' });
  }
  const query = getAllQueryParams();
  const redir = query.get('redir') || '/';
  return router.push(decodeRoute(redir));
}

onMounted(async () => {
  if (isUserAuthenticated.value && authState.value !== AuthState.LoggedInWithoutSecretKey) {
    // already logged in
    return doRedirect();
  }
  return nextTick(() => {
    // show the modal only after loading, just a brief delay
    // if we don't the teleport component somehow breaks and cannot be cleanly removed from the page
    // but the problem only presents itself in the production build.
    showAuthModal.value = true;
  });
});

const onClickCloseLogin = async () => {
  showAuthModal.value = false;
  void nextTick(doRedirect);
};
</script>

<template>
  <!-- placeholder div so page doesn't break when modal closes -->
  <div>
    <Modal
      v-if="showAuthModal"
      theme="transparent"
      :size="authTheme"
      :has-icon="true"
      @close="onClickCloseLogin"
    >
      <template #body>
        <AuthForm
          :active-option="authForm"
          @update-theme="handleThemeChange"
          @close="onClickCloseLogin"
        />
      </template>
    </Modal>
  </div>
</template>

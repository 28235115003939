<template>
  <BaseSvg class="green-check-icon" viewBox="0 0 24 24" fill="none">
    <path
      d="M6 11.8667L9.66158 17.5322C10.1487 18.2859 11.2641 18.2463 11.6966 17.46L18 6"
      stroke="#33E672"
      stroke-width="1.8"
      stroke-linecap="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '..';

export default {
  name: 'GreenCheckIcon',
  components: {
    BaseSvg,
  },
};
</script>

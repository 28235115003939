import { WNextDeployment } from '@clovyr/pollen/deployment/wnext';

import { usePollenStore } from '@/stores/pollen_store';

import { useAppVersion } from './useAppVersion';

const keepalives: { [deploymentName: string]: NodeJS.Timeout } = {};

export function useKeepalive() {
  const wnextDeploymentAPI = new WNextDeployment();

  function startKeepalive(deploymentName: string, fqdn: string) {
    if (keepalives[deploymentName]) {
      return;
    }
    // fire first ping immediately
    void wnextDeploymentAPI.keepalive(deploymentName, fqdn);
    keepalives[deploymentName] = setInterval(async () => {
      if (!(await wnextDeploymentAPI.keepalive(deploymentName, fqdn))) {
        // clear on 404. no need to endlessly ping a dead instance
        clearInterval(keepalives[deploymentName]);
      }
    }, 120000);
    // run update tasks
    const deploymentID = usePollenStore().deployments.find((d) => d.id === deploymentName)?.id;
    if (deploymentID) {
      void useAppVersion(deploymentID).runPostLaunchTasks();
    }
  }

  function stopKeepalive(deploymentName: string) {
    if (keepalives[deploymentName]) {
      clearInterval(keepalives[deploymentName]);
    }
  }

  return { startKeepalive, stopKeepalive };
}

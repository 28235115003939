import { WNextBase } from '../api/wnext-base';

export interface FileUpload {
  id: string;
  filename: string;
  filepath: string;
  filehash: string;
  filetype: string;
  fileurl: string;
}

export class FileAPI extends WNextBase {
  async upload(file: File): Promise<FileUpload> {
    const formData = new FormData();
    formData.append('file', file);
    const res = await this.unwrapRes(
      this.client.post('/files', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
    );
    return res.data.file;
  }
}

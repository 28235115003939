<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { Button } from '@clovyr/bed';
import type { Deployment } from '@clovyr/pollen/types';

import LibraryAppCard from '@/components/blocks/LibraryAppCard.vue';
import GardenIcon from '@/components/icons/GardenIcon.vue';
import { useRouteHead } from '@/composables/useRouteHead';
import { usePollenStore } from '@/stores/pollen_store';
import { useUserFlagsStore } from '@/stores/user_flags';
import Unlock from '@/views/Settings/AuthForms/Unlock.vue';
import UnlockSecret from '@/views/Settings/AuthForms/UnlockSecret.vue';

type LibraryProps = {
  shareAppId?: string;
  shareAppName?: string;
  itemID?: string;
  shareSecret?: string;
};

const props = defineProps<LibraryProps>();

const pollenStore = usePollenStore();
const { sortedDeployments, isLocked, isLoggedInWithoutSecret } = storeToRefs(pollenStore);
const { garden, getDeploymentApp } = pollenStore;

const router = useRouter();

const userFlagStore = useUserFlagsStore();
const { isInternal } = storeToRefs(userFlagStore);

useRouteHead();

let delCount = 0;
const deleteLabel = ref('Delete All Apps');

const handleFindGarden = async () => {
  return router.push('/');
};

async function onClickDeleteAll() {
  delCount++;
  if (delCount === 1) {
    deleteLabel.value = 'Are you sure?';
  } else if (delCount === 2) {
    // Wait for delete operations to complete so that Playwright tests know when
    // cleanup is complete.
    await Promise.all(
      pollenStore.deployments.map((deployment) => pollenStore.deleteDeployment(deployment)),
    );

    delCount = 0;
    deleteLabel.value = 'Delete All Apps';
  }
}

const shareApp = computed<Deployment | undefined>(() => {
  if (props.shareAppId && props.shareAppName) {
    return {
      appID: props.shareAppId,
      instanceName: props.shareAppName,
    } as Deployment;
  }
  return undefined;
});
</script>

<template>
  <div class="library">
    <div class="library__background" />

    <!-- nav -->
    <div class="container">
      <div class="grid">
        <div class="grid__col">
          <div class="library__header library__header--library">
            <div class="library__header__title">App Library</div>
            <div class="library__header__divider" />
          </div>
        </div>
      </div>

      <div
        v-if="isInternal && !isLoggedInWithoutSecret && !isLocked && sortedDeployments.length"
        class="grid delete-all"
      >
        <Button :label="deleteLabel" @click="onClickDeleteAll" />
      </div>

      <!-- your apps -->
      <div class="library__apps">
        <!--No secret key message-->
        <UnlockSecret v-if="isLoggedInWithoutSecret && !shareApp" />

        <!-- locked (enter password) -->
        <Unlock v-else-if="isLocked && !shareApp" />

        <template v-else>
          <div
            v-for="deployment in sortedDeployments"
            :key="deployment.instanceName"
            class="library__apps__app"
          >
            <LibraryAppCard :app="getDeploymentApp(deployment)!" :deployment="deployment" />
          </div>
          <div v-if="shareApp && shareAppId" class="library__apps__app">
            <LibraryAppCard
              :app="garden.getAppByID(shareAppId)!"
              :deployment="shareApp"
              :itemID="itemID"
              :shareSecret="shareSecret"
              is-shared
            />
          </div>
          <div class="library__apps__app">
            <a class="module-placeholder" @click="handleFindGarden">
              <h2>
                Find something new in the
                <span class="text text--color-action"
                  >Garden<GardenIcon style="margin-top: -15px"
                /></span>
              </h2>
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.form) {
  .form__submit {
    width: fit-content;
  }
  .textinput-block {
    margin-bottom: 0.5rem;
  }
  .textinput-block__input,
  .password-reveal {
    background-color: #251f30;
  }
}
.delete-all {
  padding-bottom: 30px;
}
</style>

<script setup lang="ts">
import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import type { DeploymentBackup } from '@clovyr/pollen';
import { shortDateTime } from '@clovyr/pollen/util/date';

import Modal from '@/components/elements/Modal.vue';
import Module from '@/components/elements/Module.vue';
import RestoreIcon from '@/components/icons/RestoreIcon.vue';
import RollbackIcon from '@/components/icons/RollbackIcon.vue';
import { useAppStatus } from '@/composables/useAppStatus';
import { useAppVersion } from '@/composables/useAppVersion';
import { usePollenStore } from '@/stores/pollen_store';

import BackupsModal from './BackupsModal.vue';

interface LibraryAppDetail {
  id: string;
}

const props = defineProps<LibraryAppDetail>();

const pollenStore = usePollenStore();
const deploymentID = toRef(props, 'id');
const { runAppVersion } = useAppVersion(deploymentID);
const { app, deployment, isRunning } = useAppStatus(deploymentID);
const backups = computed(() => {
  if (!deployment.value.backups) {
    return [];
  }
  // sort, newest first
  return deployment.value.backups.toSorted((a, b) => b.createdAt - a.createdAt);
});

const versionBackups = computed(() => {
  if (!backups.value) {
    return [];
  }
  return backups.value.filter((backup) => backup.name.startsWith('Version'));
});

const showRestoreModal = ref(false);
const selectedRestoreVersion: Ref<DeploymentBackup | undefined> = ref();
const showBackupsModal = ref(false);

const onClickRestore = async () => {
  if (!selectedRestoreVersion.value) {
    return;
  }
  showRestoreModal.value = false;

  // fetch old manifest & deploy it

  const oldApp = await pollenStore.garden.fetchAppVersion(
    app.value,
    selectedRestoreVersion.value.appVersion,
  );

  await runAppVersion(oldApp, selectedRestoreVersion.value);
};
</script>

<template>
  <Module hed="Version Backup & Restore">
    <template v-if="false" #default>
      <!-- not sure the purpose of showing this here -->
      <div class="row">
        <div class="module__blurb__header">Current Version: {{ deployment.appVersion }}</div>
      </div>
    </template>
    <template v-if="false" #default2>
      <div class="row">
        <div class="module__blurb__header">Recent Backups:</div>
        <div class="module__blurb__body">
          <template v-if="backups.length">
            <div class="row versions" v-for="backup in backups" :key="backup.snapshotID">
              <div class="title">
                <span :title="shortDateTime(backup.createdAt)">{{ backup.name }}</span>
              </div>
              <div class="action">
                <Button :shape="ButtonShape.Circle" class="restore-btn" disabled
                  ><RestoreIcon
                /></Button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row">n/a</div>
          </template>
          <div v-if="false" class="module__button">
            <Button
              label="View All"
              @click="showBackupsModal = true"
              class="view-all-btn"
              :disabled="!isRunning"
            />
          </div>
        </div>
      </div>
    </template>
    <template #default3>
      <div class="row">
        <div class="module__blurb__header">Previous Versions:</div>
        <div class="module__blurb__body">
          <template v-if="versionBackups.length">
            <div v-for="backup in versionBackups" :key="backup.snapshotID" class="row versions">
              <div class="title">
                <span :title="shortDateTime(backup.createdAt)">{{ backup.name }}</span>
              </div>
              <div class="action">
                <Button
                  :shape="ButtonShape.Circle"
                  class="rollback-btn"
                  :disabled="!isRunning"
                  @click="
                    () => {
                      selectedRestoreVersion = backup;
                      showRestoreModal = true;
                    }
                  "
                  ><RollbackIcon
                /></Button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row">n/a</div>
          </template>
        </div>
      </div>
    </template>
  </Module>

  <Modal v-if="showRestoreModal" @close="showRestoreModal = false" size="narrow">
    <template #headingText>Restore Version</template>
    <template #body>
      <p>
        Are you sure you want to roll back to version
        <b>{{ selectedRestoreVersion?.appVersion }}</b> of <b>{{ app.metadata.name }}</b
        >?
      </p>
      <p>Both the application and it's data will be restored to the previous state.</p>
    </template>
    <template #controls-left>
      <Button
        @click="
          selectedRestoreVersion = undefined;
          showRestoreModal = false;
        "
        >Cancel</Button
      >
    </template>
    <template #controls-right>
      <Button reversed @click="onClickRestore">Restore</Button>
    </template>
  </Modal>

  <BackupsModal
    v-if="showBackupsModal"
    @close="showBackupsModal = false"
    :deployment="deployment"
  />
</template>

<style scoped lang="scss">
// Deeps
:deep(.default2) {
  background-color: rgba(132, 115, 152, 0.1);
  border-radius: unset;
}
:deep(.default3) {
  .versions {
    > *:not(:last-child) {
      margin-bottom: 1rem;

      @include media-breakpoint-up(sm) {
        margin-bottom: unset;
      }
    }
  }
}
:deep(.module__head) {
  background-color: rgba(132, 115, 152, 0.1);
}
:deep(.module__head__right) {
  display: none;
}
:deep(.module__body) {
  padding-top: 2rem;
}

// local styles
.status {
  margin-top: 8px;
  margin-right: 20px;
}
.module {
  &__blurb__header {
    color: color(grey, secondary);
  }
  &__blurb__body {
    color: color(grey, tertiary);
    padding: 1rem 2rem;
  }
  &__blurb__body > .row:not(:last-child) {
    border-bottom: 1px solid color(grey, primary);
  }

  .versions {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.65rem 0;
    .title {
      width: auto;
    }
    .action {
      width: auto;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }
}

.view-all-btn {
  transform: translateX(15px);
}
.restore-btn:hover {
  :deep(.icon-restore) {
    path {
      stroke: color(negative, primary);
    }
  }
}
.rollback-btn:hover {
  :deep(.icon-rollback) {
    path {
      stroke: color(negative, primary);
    }
  }
}
</style>

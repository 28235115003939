export function useHostname() {
  const host = computed(() => {
    if (!import.meta.env.SSR && typeof window !== 'undefined') {
      return window.location.host;
    }
    return 'clovyr.app'; // default hostname if we can't tell
  });

  return { host };
}

<template>
  <BaseSvg class="library-icon" viewBox="0 0 20 20" fill="none">
    <path
      id="Vector"
      d="M18.3334 3.97132C18.0353 7.61982 14.983 10.4875 11.2635 10.4875C10.2698 10.4875 9.31862 10.2746 8.45264 9.90545C8.75076 6.25696 11.803 3.38927 15.5225 3.38927C16.5162 3.38927 17.4674 3.60221 18.3334 3.97132Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      id="Vector_2"
      d="M3.45544 2.67944C3.59741 4.49659 5.13063 5.94463 6.99037 5.94463C7.48725 5.94463 7.96993 5.84526 8.39582 5.6607C8.25386 3.84355 6.72064 2.39551 4.8609 2.39551C4.36402 2.39551 3.88134 2.49488 3.45544 2.67944Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      id="Vector_3"
      d="M1.66675 9.89126C1.8655 12.3756 3.93819 14.3206 6.47936 14.3206C7.16079 14.3206 7.79963 14.1786 8.39588 13.9231C8.19713 11.4387 6.12445 9.49376 3.58327 9.49376C2.90184 9.49376 2.263 9.63572 1.66675 9.89126Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      id="Vector_4"
      d="M8.44373 6.1418L8.44373 18.5926"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'LibraryIcon',
  components: {
    BaseSvg,
  },
};
</script>

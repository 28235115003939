import type { Deployment } from 'kubernetes-types/apps/v1';
import type { Container, Volume, VolumeMount } from 'kubernetes-types/core/v1';
import type { Ingress } from 'kubernetes-types/networking/v1';
import yaml from 'yaml';

import { deepCopy } from '../../util/deep-copy';
import {
  CLOVYR_ACCESS_KEY,
  CLOVYR_BACKUP_PASSWORD,
  CLOVYR_FQDN,
  CLOVYR_S3_URL,
  CLOVYR_SECRET_KEY,
  DeploymentMethod,
  dumpYaml,
  type Files,
  type Manifest,
  type Values,
} from '..';

// eslint-disable-next-line import/no-extraneous-dependencies
import type { ConfigureBuilder } from '.';
import {
  firewall,
  installKubernetes,
  kubeLeafService,
  kubeTraefikConfigOverride,
  kubeTraefikMiddleware,
  kubeUtils,
} from './common';
import type { PostProcessFilesFn } from './configure-builder';
import resticDeploymentYaml from './restic-deployment.yaml?raw';
import { writeAllFilesBase64, writeBase64File } from './util';

const VALUES_YAML = 'values.yaml';

/**
 * Parse the helm chart values.yaml file, if available. If none exists, returns
 * an empty map.
 *
 * @param files
 * @returns
 */
function loadValuesYaml(files: Files): Values {
  return files[VALUES_YAML] ? (yaml.parse(files[VALUES_YAML]) as Values) : {};
}

/**
 * Attach Restic sidecar if required by application manifest
 *
 * @param manifest
 * @param values
 * @param files
 * @returns
 */
function attachRestic(manifest: Manifest, values: Values, files: Files) {
  if (!manifest.backup?.locations?.length) {
    return;
  }

  const resticDeployment = yaml.parse(resticDeploymentYaml) as Deployment;

  // attach volumes to resticSvc and collect paths
  const resticPaths: Array<string> = [];
  const volumeMounts: Array<VolumeMount> = [];
  const volumes: Array<Volume> = [];
  const seenVolumes = {};
  manifest.backup.locations.forEach((backupLocation) => {
    resticPaths.push(`/restic/${backupLocation.volume}${backupLocation.path}`);
    if (seenVolumes[backupLocation.volume]) {
      return;
    }
    seenVolumes[backupLocation.volume] = 1;
    volumeMounts.push({
      mountPath: `/restic/${backupLocation.volume}`,
      name: backupLocation.volume,
    });
    volumes.push({
      name: backupLocation.volume,
      persistentVolumeClaim: {
        claimName: backupLocation.volume,
      },
    });
  });

  resticDeployment!.spec!.template.spec!.containers[0].volumeMounts = volumeMounts;
  resticDeployment!.spec!.template.spec!.volumes = volumes;

  files['templates/restic-deployment.yaml'] = yaml.stringify(resticDeployment);

  // add init container to each existing service/deployment
  const initContainer: Container = {
    name: 'restic-restore',
    image: 'clovyr/restic:latest',
    command: ['/entrypoint.sh', 'restore-wait'],
    env: [
      {
        name: 'RESTIC_REPOSITORY',
        value: '{{ .Values.RESTIC_REPOSITORY }}',
      },
      {
        name: 'AWS_ACCESS_KEY_ID',
        value: '{{ .Values.AWS_ACCESS_KEY_ID }}',
      },
      {
        name: 'AWS_SECRET_ACCESS_KEY',
        value: '{{ .Values.AWS_SECRET_ACCESS_KEY }}',
      },
      {
        name: 'RESTIC_PASSWORD',
        value: '{{ .Values.RESTIC_PASSWORD }}',
      },
      {
        name: 'RESTIC_PATHS',
        value: '{{ .Values.RESTIC_PATHS }}',
      },
    ],
    volumeMounts: deepCopy(volumeMounts),
  };

  /**
   *
   * Add init container to each deployment

   * Merge extra volumes so they are available to our init container
   * we add the volume, but not volume mounts, so it's not actually
   * visible to the container.

   * This is needed because we may have multiple deployments within the
   * application, with different volumes used by each, but our init container
   * will handle restoring all volumes across all deployments, regardless
   * of which deployment has the 'primary' init container is attached to.
   *
   * For example, nextcloud has two deployments: db, nextcloud. Each deployment
   * has their own volume. In order to run a restore, we attach init containers
   * to both deployments in order to control app startup, however only one of
   * the init containers is responsible for doing the actual restore.
   *
   *
   */

  Object.entries(files).forEach(([filename, contents]) => {
    if (filename === 'templates/restic-deployment.yaml') {
      return;
    }
    const dep = yaml.parse(contents) as Deployment;
    if (dep.kind !== 'Deployment') {
      return;
    }
    if (dep.spec?.template.spec) {
      (dep.spec.template.spec.initContainers ||= []).push(initContainer);
      volumes.forEach((vol) => {
        if (!dep.spec!.template.spec!.volumes?.find((v) => v.name === vol.name)) {
          (dep.spec!.template.spec!.volumes ||= []).push(deepCopy(vol));
        }
      });
      files[filename] = yaml.stringify(dep);
    }
  });

  // add restic config to values
  const chartValues: Values = loadValuesYaml(files);
  Object.assign(chartValues, {
    RESTIC_REPOSITORY: `${values[CLOVYR_S3_URL]}/${manifest.metadata.id}`,
    AWS_ACCESS_KEY_ID: values[CLOVYR_ACCESS_KEY],
    AWS_SECRET_ACCESS_KEY: values[CLOVYR_SECRET_KEY],
    RESTIC_PASSWORD: values[CLOVYR_BACKUP_PASSWORD],
    RESTIC_PATHS: resticPaths.join(' '),
  });
  files[VALUES_YAML] = yaml.stringify(chartValues);
}

/**
 * Add traefik middleware to chart
 *
 * @param manifest
 * @param files
 */
function addIngressMiddleware(manifest: Manifest, files: Files) {
  Object.entries(files).forEach(([name, contents]) => {
    let ingress: Ingress | null;
    try {
      ingress = yaml.parse(contents) as Ingress;
    } catch (e: unknown) {
      if (!(e instanceof Error) || !e.message.match(/Source contains multiple documents/)) {
        throw e;
      }
      return;
    }
    if (!ingress || ingress.kind?.toLowerCase() !== 'ingress') {
      return;
    }
    ingress.metadata ||= {};
    ingress.metadata.annotations ||= {};
    Object.assign(ingress.metadata.annotations, {
      'traefik.ingress.kubernetes.io/router.middlewares':
        'default-leaf-auth@kubernetescrd,default-leaf-csp@kubernetescrd',
    });
    files[name] = yaml.stringify(ingress);
  });
}

/**
 * Add all passed settings to values.yaml
 *
 * @param manifest
 * @param values
 * @param files
 */
function addConfigEnv(manifest: Manifest, values: Values, files: Files) {
  const chartValues: Values = loadValuesYaml(files);
  Object.entries(values).forEach(([key, val]) => {
    if (key.startsWith('clovyr_')) {
      return;
    }
    chartValues[key] = val;
  });
  files[VALUES_YAML] = yaml.stringify(chartValues);
}

// TODO: handle charts with sub-charts (multiple deps, etc) such as Wire
export function mkChart(manifest: Manifest, values: Values, files: Files): Files {
  const filesCopy = { ...files };
  addConfigEnv(manifest, values, filesCopy);
  attachRestic(manifest, values, filesCopy);
  addIngressMiddleware(manifest, filesCopy);
  return filesCopy;
}

function mkChartScript(
  manifest: Manifest,
  values: Values,
  domain: string,
  host: string,
  i: string,
  files: Files,
  fn?: PostProcessFilesFn
): string {
  const filesCopy = mkChart(manifest, values, files);
  if (fn) {
    fn(filesCopy);
  }
  const fqdn = values[CLOVYR_FQDN] as string;

  const s = `
mkdir -p /tmp/leaf-boot/chart-replace-${i}
pushd /tmp/leaf-boot/chart-replace-${i}

mkdir -p '${manifest.metadata.id}'
pushd '${manifest.metadata.id}'
${writeAllFilesBase64(filesCopy)}
popd
perl -p -i -e 's/{{ .Release.Name }}/${fqdn}/g' */values.yaml
perl -p -i -e "s/.wnext.app/.${domain}/g" */*.yaml */**/*.yaml
tar cfz /var/lib/rancher/k3s/server/static/charts/leaf-chart-${i}.tgz *
popd && rm -rf /tmp/leaf-boot/chart-replace-${i}

cat > /var/lib/rancher/k3s/server/manifests/leaf-chart-${i}.yaml <<'EOF'
apiVersion: helm.cattle.io/v1
kind: HelmChart
metadata:
  name: ${host}
  namespace: default
spec:
  chart: https://%{KUBERNETES_API}%/static/charts/leaf-chart-${i}.tgz
EOF
`;

  return s;
}

// mkCharts
//
// Support multiple charts in the chart string in cases where the helm charts must be installed
// independently, such as the Wire chart where the wire-server pre-install hook needs to migrate
// cassandra before proceeding, but if you put cassandra in the same chart it is never deployed
// because the process never gets further than that pre-install hook.
//
// See
//   - https://stackoverflow.com/questions/50492389/helm-subchart-order-of-execution-in-an-umbrella-chart
//   - https://github.com/helm/helm/issues/5723
function mkCharts(manifest: Manifest, values: Values, fn?: PostProcessFilesFn): string {
  if (!manifest.deployment?.contents?.[DeploymentMethod.Chart]) {
    throw new Error('manifest error: missing deployment contents');
  }
  const files = manifest.deployment.contents[DeploymentMethod.Chart];
  const fqdn = values[CLOVYR_FQDN] as string;
  const hostparts = fqdn.split('.');
  const hostname = hostparts[0];
  const domain = hostparts.slice(1).join('.');

  // TODO: currently only supporting a single chart via files
  return mkChartScript(manifest, values, domain, hostname, '0', files || {}, fn);

  // const split = chartTarGzBase64.split(',');
  // const charts = [];
  // split.forEach((base64, i) => {
  //   let host = hostname;
  //   if (i > 0) {
  //     host = `${hostname}-${i.toString()}`;
  //   }
  //   charts.push(mkChart(fqdn, domain, host, i.toString(), base64));
  // });
  // return charts.join('\n');
}

export function createKubeScript(chartsScript: string, appID: string, valuesFile: string): string {
  const b64vals = writeBase64File(`/opt/clovyr/apps/${appID}/clovyr-values.yml`, valuesFile);

  return `#!/bin/bash
set -euxo pipefail

${firewall}

# Users
## chage -I -1 -m 0 -M 99999 -E -1 root
## TODO: Just disable password for root rather than disabling root account altogether?
## i.e. to make it easier to add entries to authorized_keys when a user requests it
## passwd -d -l root
# sed -i 's/^root:.*$/root:*:16231:0:99999:7:::/' /etc/shadow # Disable expired password
# adduser leaf --gecos 'Leaf user' --disabled-password --quiet
# mkdir /home/leaf/leaf

mkdir -p /opt/clovyr/apps/${appID}
${b64vals}

mkdir -p /tmp/leaf-boot

${installKubernetes}

${kubeTraefikConfigOverride}

${kubeLeafService}

${kubeTraefikMiddleware}

mkdir -p /var/lib/rancher/k3s/server/static/charts

${chartsScript}

systemctl enable k3s
systemctl start k3s

${kubeUtils}

## Misc
# apt-get update -qq && apt-get dist-upgrade -qq

rm -rf /tmp/leaf-boot
`;
}

function mkConfigureScript(manifest: Manifest, values: Values, fn?: PostProcessFilesFn): string {
  const chartsScript = mkCharts(manifest, values, fn);
  return createKubeScript(chartsScript, manifest.metadata.id, dumpYaml(values));
}

export const chart = {
  mkConfigureScript,
} as ConfigureBuilder;

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { ClovyrLogo } from '@clovyr/bed';
import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import CLink from '@clovyr/bed/components/elements/CLink.vue';
import useMediaQuery from '@clovyr/bed/composables/useMediaQuery';

import ArrowIcon from '@/components/icons/ArrowIcon.vue';
import ClovyrIcon from '@/components/icons/ClovyrIcon.vue';
import DotsHorizontalIcon from '@/components/icons/DotsHorizontalIcon.vue';
import GardenIcon from '@/components/icons/GardenIcon.vue';
import LibraryIcon from '@/components/icons/LibraryIcon.vue';
import ProfileIcon from '@/components/icons/ProfileIcon.vue';
import PublisherIcon from '@/components/icons/PublisherIcon.vue';
import SlideIcon from '@/components/icons/SlideIcon.vue';
import { useInstanceSleepMonitor } from '@/composables/useInstanceSleepMonitor';
import MobileNavbar from '@/global/Sidebar/MobileNavbar.vue';
import { useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';
import { useUIStateStore } from '@/stores/ui_state_store';
import { useUserFlagsStore } from '@/stores/user_flags';
import Help from '@/views/Help/Help.vue';
import Settings from '@/views/Settings/Settings.vue';
import SideDrawer from '@/views/SideDrawer.vue';

import NavAppList from './Sidebar/NavAppList.vue';
import Modals from './Modals.vue';

const options = {
  Settings,
  Help,
};

const { eventBus } = useEventBus();
const uiStateStore = useUIStateStore();
const { sidenavIsCollapsed: sidenavIsCollapsedStore } = storeToRefs(uiStateStore);
const pollenStore = usePollenStore();
const { deployments, sortedDeployments, isUserAuthenticated } = storeToRefs(pollenStore);
const route = useRoute();
useInstanceSleepMonitor();
const { isPublisher } = storeToRefs(usePollenStore());

const selectedOption = ref('');
const isMobile = ref(useMediaQuery('mobile'));
const mobileNavBarOpen = ref(false);

// Local ref which does not update localstorage state when changed
const localCollapsed = ref<boolean>(true);

const useLocalCollapsed = computed(() => {
  return route.name === 'ViewDeployment';
});

const sidebar = ref<HTMLElement | null>(null);

const { isInternal } = storeToRefs(useUserFlagsStore());

// Use a writable computed so that we can avoid saving state when on the FramedDeployment view
// (clovyr code)
const sidenavIsCollapsed = computed({
  get() {
    if (useLocalCollapsed.value) {
      return localCollapsed.value;
    }
    return sidenavIsCollapsedStore.value;
  },
  set(newValue) {
    if (useLocalCollapsed.value) {
      localCollapsed.value = newValue;
    } else {
      sidenavIsCollapsedStore.value = newValue;
    }
  },
});

/**
 * Should be called whenever the sidebar changes state
 */
const setWidthVars = () => {
  let w = `var(--sidebar-width-${sidenavIsCollapsed.value ? 'closed' : 'open'})`;
  if (isMobile.value) {
    w = '0px';
  }
  document.documentElement.style.setProperty('--sidebar-current-width', w);
};

const handleSidebarToggle = () => {
  sidenavIsCollapsed.value = !sidenavIsCollapsed.value;
  selectedOption.value = '';

  setWidthVars();
};

// Need an extra watch to ensure proper width when changing collapsed state via store
watch([sidenavIsCollapsed, isMobile], () => {
  setWidthVars();
});

const menuItemIsSelected = (view) => {
  if (selectedOption.value === view) {
    return 'sidebar-menu__item--selected';
  }

  return undefined;
};

const handleDrawerClose = () => {
  selectedOption.value = '';
};

const onClickCloseDrawer = () => {
  handleDrawerClose();
  window.location.hash = '';
};

const stateClass = computed(() => {
  return `sidebar--${sidenavIsCollapsed.value ? 'closed' : 'open'}`;
});

const libToggled = ref(false);

const handleLibToggle = () => {
  libToggled.value = !libToggled.value;
};

const libArrowClassNames = computed(() => {
  return [libToggled.value && 'button--toggled'];
});

const libClassNames = computed(() => {
  return [
    deployments.value.length > 0 && 'app-library-listing',
    libToggled.value && 'app-library-expanded',
  ];
});

const toggleBodyLockClass = () => {
  if (document.body.classList.contains('body-locked')) {
    document.body.classList.remove('body-locked');
  } else {
    document.body.classList.add('body-locked');
  }
};

const handleDrawerToggle = (view) => {
  if (mobileNavBarOpen.value) {
    mobileNavBarOpen.value = false;
  }

  selectedOption.value = view;

  toggleBodyLockClass();
};

const checkHashForSettings = () => {
  if (window.location.hash) {
    const hash = window.location.hash.slice(1);
    if (hash.split('/').includes('settings')) {
      selectedOption.value = 'Settings';
    }
  }
};

onMounted(() => {
  setWidthVars();
  eventBus.on('drawer:close', handleDrawerClose);
  checkHashForSettings();
  window.addEventListener('hashchange', checkHashForSettings, false);
  void nextTick(() => {
    if (useLocalCollapsed.value && sidebar.value) {
      // fix hydration mismatch issue w/ class binding when opening via develop button from external site
      sidebar.value.classList.remove('sidebar--open', 'sidebar--closed');
      sidebar.value.classList.add(stateClass.value);
    }
  });
});

onUnmounted(() => {
  // actually this will never fire? maybe if we switch to mobile nav?
  eventBus.off('drawer:close', handleDrawerClose);
  window.removeEventListener('hashchange', checkHashForSettings, false);
});

function toggleDrawer(option) {
  handleDrawerToggle(option);
}

function toggleMobileNavbar() {
  mobileNavBarOpen.value = !mobileNavBarOpen.value;
}

// Define computed property to determine if any publisher-related route is active
const isPublisherRouteActive = computed(() => {
  return {
    'router-link-active': route.path.startsWith('/publishers'),
  };
});
</script>

<template>
  <div :class="stateClass" class="sidebar" ref="sidebar">
    <div class="sidebar__inner">
      <div class="sidebar__backdrop" />

      <div class="sidebar__top">
        <div class="sidebar-logo">
          <div v-show="sidenavIsCollapsed" class="sidebar-logo--abbreviated">
            <router-link to="/">
              <ClovyrIcon />
            </router-link>
          </div>

          <div v-show="!sidenavIsCollapsed" class="sidebar-logo--full">
            <router-link to="/">
              <ClovyrLogo />
            </router-link>
          </div>
        </div>

        <div :class="libClassNames" class="app-library">
          <div class="app-library-heading">
            <router-link :to="{ name: 'Library' }">
              <span class="app-library-heading-label">
                <span class="app-library-heading__icon">
                  <Tooltip
                    :disabled="!sidenavIsCollapsed"
                    placement="right"
                    theme="narrow"
                    :arrow="false"
                    content="Library"
                  >
                    <LibraryIcon />
                  </Tooltip>
                </span>
                <span class="app-library-heading__title">Library</span>
              </span>
            </router-link>

            <span v-if="sortedDeployments.length > 5" class="app-library-arrow">
              <button
                @click="handleLibToggle"
                :class="libArrowClassNames"
                class="button--action button--toggle"
              >
                <Tooltip
                  :disabled="!sidenavIsCollapsed"
                  placement="right"
                  theme="narrow"
                  :arrow="false"
                  content="Collapse"
                >
                  <ArrowIcon :direction="libToggled ? 'top' : 'down'" />
                </Tooltip>
              </button>
            </span>
          </div>

          <NavAppList :closed="sidenavIsCollapsed" :library-toggled="!libToggled" />
        </div>

        <ul class="sidebar-navigation">
          <li class="navigation-item">
            <router-link :to="{ name: 'Garden' }">
              <span class="navigation-item__icon">
                <Tooltip
                  :disabled="!sidenavIsCollapsed"
                  placement="right"
                  theme="narrow"
                  :arrow="false"
                  content="Garden"
                >
                  <GardenIcon />
                </Tooltip>
              </span>
              <span class="navigation-item__title">Garden</span>
            </router-link>
          </li>
          <li
            class="navigation-item"
            :class="isPublisherRouteActive"
            v-if="isPublisher || isInternal"
          >
            <router-link :to="{ name: 'Publishers' }">
              <span class="navigation-item__icon">
                <Tooltip
                  :disabled="!sidenavIsCollapsed"
                  placement="right"
                  theme="narrow"
                  :arrow="false"
                  content="Publishers"
                >
                  <PublisherIcon />
                </Tooltip>
              </span>
              <span class="navigation-item__title">Publishers</span>
            </router-link>
          </li>
          <li
            v-if="!isUserAuthenticated"
            class="navigation-item"
            @click="eventBus.emit('modal:login:show')"
          >
            <span class="navigation-item__icon">
              <Tooltip
                :disabled="!sidenavIsCollapsed"
                placement="right"
                theme="narrow"
                :arrow="false"
                content="Log In / Sign Up"
              >
                <ProfileIcon />
              </Tooltip>
            </span>
            <span class="navigation-item__title">Log In</span>
          </li>
        </ul>
      </div>

      <div class="sidebar-bottom">
        <div class="sidebar-bottom__inner">
          <ul v-show="!sidenavIsCollapsed" class="sidebar-menu">
            <li
              :class="menuItemIsSelected('Settings')"
              @click="handleDrawerToggle('Settings')"
              class="sidebar-menu__item settings"
            >
              <span v-if="isUserAuthenticated" class="icon">
                <ProfileIcon />
              </span>
              <span class="title">Settings</span>
            </li>
            <li
              v-if="isMobile"
              :class="menuItemIsSelected('Feedback')"
              class="sidebar-menu__item"
              id="feedback-button"
            >
              Feedback
            </li>
            <li
              :class="menuItemIsSelected('Help')"
              @click="handleDrawerToggle('Help')"
              class="sidebar-menu__item"
            >
              Help
            </li>
            <li class="sidebar-menu__item">
              <c-link to="https://clovyr.io" label="Clovyr.io" target="_blank" />
            </li>
          </ul>

          <Tooltip
            v-if="sidenavIsCollapsed"
            placement="right"
            theme="narrow sidebar-menu-options"
            :arrow="false"
            class="tooltip-nav"
          >
            <DotsHorizontalIcon />

            <template #content>
              <ul class="sidebar-menu">
                <li
                  v-for="(_, option) in options"
                  :key="option"
                  :class="menuItemIsSelected(option)"
                  @click="handleDrawerToggle(option)"
                  class="sidebar-menu__item"
                >
                  {{ option }}
                </li>
                <li class="sidebar-menu__item" id="feedback-button">Feedback</li>
                <li class="sidebar-menu__item">
                  <a href="https://clovyr.io">Clovyr.io</a>
                </li>
              </ul>
            </template>
          </Tooltip>

          <div @click="handleSidebarToggle" class="slide-icon">
            <Tooltip
              :disabled="!sidenavIsCollapsed"
              placement="right"
              theme="narrow"
              :arrow="false"
              content="Expand"
            >
              <SlideIcon />
            </Tooltip>
          </div>
        </div>
      </div>

      <Modals />

      <SideDrawer v-if="selectedOption" @close="onClickCloseDrawer">
        <component :is="options[selectedOption]" />
      </SideDrawer>
    </div>
  </div>

  <MobileNavbar
    v-if="isMobile"
    @toggle-drawer="toggleDrawer"
    @toggle-mobile-navbar="toggleMobileNavbar"
    :mobile-navBar-open="mobileNavBarOpen"
  />
</template>

<style scoped lang="scss">
:deep(svg) {
  margin: unset !important;
}

.sidebar--closed {
  :deep(.app-library-item__title-abbreviated) {
    padding-left: space(2.25);
  }
  .app-library {
    &__items {
      padding-left: space(1.5) !important;
    }
  }
}

.sidebar--open {
  :deep(.app-library-item__title-abbreviated) {
    padding-left: space(0.5);
  }
  .app-library {
    &__items {
      padding-left: space(2.5) !important;
    }
  }
}
.navigation-item:hover {
  color: color(action, primary) !important;
  :deep(.publisher-icon) {
    path {
      fill: color(action, primary);
    }
  }
}
.router-link-active {
  .navigation-item__title {
    color: color(action, secondary);
  }
  .navigation-item__icon {
    :deep(.publisher-icon) {
      path {
        fill: color(action, secondary);
      }
    }
  }
}
.router-link-active:hover {
  .navigation-item__title {
    color: color(action, primary);
  }
  .navigation-item__icon {
    :deep(.publisher-icon) {
      path {
        fill: color(action, primary);
      }
    }
  }
}
</style>

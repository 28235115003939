import { FetchWrapper } from '../http';

import type { Email, MailHogMessage, Path } from './types';
import { decodeQuotedPrintable } from './util';

function getClient(baseURL: string, configureKey: string) {
  const client = new FetchWrapper({ baseURL: `${baseURL}/_leaf/mail` });
  client.setAuthorization(configureKey);
  return client;
}

function extractBody(part): [string, string] {
  let plain: string = '';
  let html: string = '';

  const ct = part.Headers['Content-Type']?.[0];
  if (ct && ct.includes('multipart/alternative') && part.MIME?.Parts) {
    // mime is fun. look for further nested parts
    part.MIME?.Parts.forEach((prt) => {
      const [h, p] = extractBody(prt);
      html ||= h;
      plain ||= p;
    });
    return [html, plain];
  }
  if (ct && ct.includes('text/plain')) {
    plain = part.Body;
  } else if (ct && ct.includes('text/html')) {
    html = part.Body;
  }

  return [html, plain];
}

function pathToEmailString(path: Path): string {
  let s = '';
  if (!path.Mailbox) {
    return s;
  }
  s = path.Mailbox;
  if (path.Domain) {
    s += `@${path.Domain}`;
  }
  return s;
}

export function mailhogMessagesToEmails(msgs: MailHogMessage[]): Email[] {
  const emails: Email[] = [];
  msgs.forEach((msg: MailHogMessage) => {
    const mail: Email = {
      id: msg.ID,
      date: msg.Created,
    } as Email;

    if (Array.isArray(msg.Content.Headers.Subject) && msg.Content.Headers.Subject.length) {
      [mail.subject] = msg.Content.Headers.Subject;
    } else {
      mail.subject = '';
    }

    if (Array.isArray(msg.Content.Headers.From) && msg.Content.Headers.From.length) {
      [mail.from] = msg.Content.Headers.From;
    } else if (msg.From?.Mailbox) {
      mail.from = pathToEmailString(msg.From);
    }

    if (Array.isArray(msg.Content.Headers.To) && msg.Content.Headers.To.length) {
      [mail.to] = msg.Content.Headers.To;
    } else if (msg.To?.length) {
      mail.to = pathToEmailString(msg.To[0]);
    }

    if (msg.MIME) {
      // prefer html part, else plain
      let html = '';
      let plain = '';
      msg.MIME.Parts.forEach((part) => {
        const [h, p] = extractBody(part);
        html ||= h;
        plain ||= p;
      });
      if (html) {
        mail.contentType = 'text/html';
        mail.body = decodeQuotedPrintable(html);
      } else if (plain) {
        mail.contentType = 'text/plain';
        mail.body = decodeQuotedPrintable(plain);
      }
    } else {
      // only single content part available
      if (msg.Content.Headers['Content-Type']?.length) {
        mail.contentType = msg.Content.Headers['Content-Type'][0]?.split(';')[0];
      }
      mail.body = decodeQuotedPrintable(msg.Content?.Body);
    }
    emails.push(mail);
  });
  return emails;
}

export async function fetchMail(baseURL: string, configureKey: string): Promise<Email[]> {
  // localhost:8025
  const client = getClient(baseURL, configureKey);
  const res = await client.get(`/api/v2/messages`);

  if (!(res instanceof Response)) {
    throw res as Error;
  }
  const mailhogRes = await res.json();
  const { items } = mailhogRes;
  return mailhogMessagesToEmails(items as MailHogMessage[]);
}

export async function deleteMessage(
  baseURL: string,
  configureKey: string,
  messageID: string
): Promise<void> {
  const client = getClient(baseURL, configureKey);
  await client.delete(`/api/v1/messages/${messageID}`, { method: 'DELETE' });
}

export async function deleteAllMessages(baseURL: string, configureKey: string): Promise<void> {
  const client = getClient(baseURL, configureKey);
  await client.delete('/api/v1/messages', { method: 'DELETE' });
}

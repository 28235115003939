<script setup lang="ts">
import Modal from './Modal.vue';
import Spinner from './Spinner.vue';

const emit = defineEmits(['onStop']);

onUnmounted(() => {
  emit('onStop');
});
</script>

<template>
  <Modal theme="spinner" :size="'narrow'">
    <template #body>
      <Spinner theme="none" />
    </template>
  </Modal>
</template>

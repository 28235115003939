<template>
  <BaseSvg class="next-icon" viewBox="0 0 11 21" fill="none">
    <path
      d="M1.5625 20L10 10.6314L1.5625 1.26282"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'NextIcon',
  components: {
    BaseSvg,
  },
};
</script>

/* eslint-disable @typescript-eslint/no-unused-vars */

import { WNextBase } from '../api/wnext-base';
import { type ComputeProviderConfig, ComputeProviderName, type Instance } from '../types';
import { waitFor } from '../util/sleep';

import {
  type ComputeInstanceImage,
  type ComputeInstanceRegion,
  ComputeInstanceSize,
  type ComputeProvider,
  type CreateInstanceRequest,
  type DestroyInstanceRequest,
  type DestroyInstanceResponse,
  type GetInstanceRequest,
  type InstanceType,
  type InstanceTypeMap,
  type ListInstancesRequest,
  type UpdateInstanceRequest,
} from './index';

const sizes: InstanceTypeMap = {
  [ComputeInstanceSize.Micro]: [ComputeInstanceSize.Micro, 4], // $4
  [ComputeInstanceSize.Small]: [ComputeInstanceSize.Small, 6], // $6
  [ComputeInstanceSize.Medium]: [ComputeInstanceSize.Medium, 12], // $12
  [ComputeInstanceSize.Large]: [ComputeInstanceSize.Large, 48], // $48
  [ComputeInstanceSize.XLarge]: [ComputeInstanceSize.XLarge, 48], // $48
  [ComputeInstanceSize.XLargeAI]: [ComputeInstanceSize.XLargeAI, 48], // $48
};

interface WakeupInstanceRequest {
  oldFQDN: string;
  newFQDN: string;
}

export class WNext extends WNextBase implements ComputeProvider {
  getConfig() {
    const config: ComputeProviderConfig = {
      id: crypto.randomUUID(),
      providerID: ComputeProviderName.WNext,
      name: 'Clovyr Hosting',
    };
    return config;
  }

  setConfig(_config: ComputeProviderConfig) {
    // no-op, no config for this provider
  }

  isConfigured(): boolean {
    return true;
  }

  getInstanceType(size: ComputeInstanceSize): InstanceType {
    return sizes[size];
  }

  getInstanceImage(image: ComputeInstanceImage, region: string): string {
    return image;
  }

  /**
   * Create instance using wNext provider. Waits for an IP to be assigned before
   * returning.
   *
   * @param req
   * @returns
   */
  async createInstance(req: CreateInstanceRequest): Promise<Instance> {
    const res = await this.unwrapRes(this.client.post('/instances', req));
    if (!res?.data) {
      throw new Error('failed to create instance');
    }
    const { instance } = res.data;
    return this.#waitForIP(instance.fqdn, 5000, 120);
  }

  async #waitForIP(fqdn: string, initialSleep: number, maxTries: number): Promise<Instance> {
    return waitFor(initialSleep, maxTries, 1000, async () => {
      const res = await this.getInstance({ id: fqdn });
      const ip = res?.ipv4;
      return [!!ip, res];
    });
  }

  async destroyInstance(req: DestroyInstanceRequest): Promise<DestroyInstanceResponse> {
    return this.unwrap(await this.client.delete(`instances/${req.id}`, req));
  }

  async updateInstance(req: UpdateInstanceRequest): Promise<Instance> {
    // TODO: impl
    throw new Error('not implemented');
  }

  // List of instances we have access to
  async listInstances(_req: ListInstancesRequest): Promise<Instance[]> {
    const resp = await this.client.get(`instances`);
    if (resp instanceof Error) {
      throw resp;
    }
    if (resp.status !== 200) {
      throw new Error(`unexpected status code from getInstance endpoint: ${resp.status}`);
    }
    return resp.json();
  }

  // Fetch instance details, if authorized
  async getInstance(req: GetInstanceRequest): Promise<Instance | undefined> {
    const resp = await this.client.get(`instances/${req.id}`);
    if (resp instanceof Error) {
      throw resp;
    }
    if (resp.status === 200) {
      return resp.json();
    }
    if (resp.status === 404) {
      return undefined;
    }
    throw new Error(`unexpected status code from getInstance endpoint: ${resp.status}`);
  }

  async wakeup(req: WakeupInstanceRequest): Promise<void> {
    await this.unwrapRes(
      this.client.post(`/instances/${req.oldFQDN}/wakeup`, { newFQDN: req.newFQDN })
    );
  }
}

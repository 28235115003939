/**
 * hydrate settings from localStorage
 */
export function rehydrate<T>(key: string): T | null {
  if (typeof localStorage !== 'undefined') {
    const json = localStorage.getItem(key);
    if (json) {
      return JSON.parse(json);
    }
  }
  return null;
}

/**
 * persist data to localstorage
 * TODO: move to use pollen for all storage?
 */
export function persist<T>(key: string, obj: T) {
  if (typeof localStorage !== 'undefined' && key) {
    localStorage.setItem(key, JSON.stringify(obj));
  }
}

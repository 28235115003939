<!-- eslint-disable import/no-unresolved -->
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { usePollenStore } from '@/stores/pollen_store';

import UserAccount from './Account/UserAccount.vue';
import UserRecoveryKit from './Account/UserRecoveryKit.vue';
import UserSecretKey from './Account/UserSecretKey.vue';
import Unlock from './AuthForms/Unlock.vue';
import type { User } from './types';

const pollenStore = usePollenStore();
const { isLocked } = storeToRefs(pollenStore);

const user = computed(() => {
  return {
    email: pollenStore.email,
    account: {
      appsInstalled: pollenStore.deployments.length,
      secretKey: pollenStore.formattedSecretKey,
    },
  } as User;
});
</script>

<template>
  <div class="settings-columns">
    <Unlock v-if="isLocked" />
    <div class="row">
      <div class="grid__col-md-6">
        <UserAccount v-if="!isLocked" :user="user" />
      </div>
      <div class="grid__col-md-6">
        <UserRecoveryKit v-if="!isLocked && user.account.secretKey" />
      </div>
    </div>
  </div>

  <UserSecretKey v-if="!isLocked && user.account.secretKey" :secret-key="user.account.secretKey" />
</template>

<style lang="scss" scoped>
$padding: space(3.6);

.settings-columns {
  margin-bottom: 1rem;
  color: color(white);

  .row {
    --cl-gutter-x: 1rem;

    padding-left: 0;
    padding-right: 0;

    [class*='grid__col'] {
      &:not(:first-child) {
        margin-top: space(1.7);

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }
    }
  }
}
</style>

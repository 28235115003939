import type { RouteLocationRaw, Router } from 'vue-router';

import { ComputeProviderName, DeploymentState } from '@clovyr/pollen';
import { WNextAccessAPI } from '@clovyr/pollen/deployment/access';
import { getHostByID } from '@clovyr/pollen/fixtures/hosts';
import { wnextHTTP } from '@clovyr/pollen/http';
import {
  addBackupValues,
  CLOVYR_ACCESS_KEY,
  CLOVYR_BACKUP_PASSWORD,
  CLOVYR_FQDN,
  CLOVYR_S3_URL,
  CLOVYR_SECRET_KEY,
  type Values,
} from '@clovyr/pollen/manifest';
import { claimInstance, type ClaimInstanceResticConfig } from '@clovyr/pollen/remote_exec';
import type { Deployment } from '@clovyr/pollen/types';

import { config } from '@/composables/useInstanceLauncher';
import { config as appConfig } from '@/init/config';
import { useDeploymentStore } from '@/stores/deployment_store';
import { usePollenStore } from '@/stores/pollen_store';

function redirectToDep(dep: Deployment, code?: boolean) {
  // send to framed view or redirect to the app itself
  return {
    name: code ? 'ViewDeployment' : 'OpenDeployment',
    params: { deploymentName: dep.instanceName },
  };
}

function resticConfigFromValues(values: Values): ClaimInstanceResticConfig {
  const getValue = (key: string): string => {
    const value = values[key];
    if (typeof value !== 'string') {
      throw new Error(`expected string for key ${key}, got ${typeof value}`);
    }
    return value;
  };

  return {
    awsAccessKeyID: getValue(CLOVYR_ACCESS_KEY),
    awsSecretAccessKey: getValue(CLOVYR_SECRET_KEY),
    password: getValue(CLOVYR_BACKUP_PASSWORD),
    repository: getValue(CLOVYR_S3_URL),
  };
}

/**
 * Claim a clovyr code instance or show existing instance if they already have one
 *
 * @param appID should be '[publisher]/id', e.g., 'foobar/code-my-develop-app' or 'code-go'
 * @param deploymentName
 * @param router must be passed in to avoid async stack issues
 * @returns
 */
export async function claimClovyrCode(
  appID: string,
  deploymentName: string,
  router: Router,
  fqdn?: string,
  isCode?: boolean,
): Promise<RouteLocationRaw> {
  const deploymentStore = useDeploymentStore();
  const pollenStore = usePollenStore();
  const { garden } = pollenStore;
  let app = garden.getAppByID(appID);
  if (!app && isCode) {
    // fallback. not sure we need this, here for backwards compat
    // since we no longer pass 'clovyr-code' as appID when claiming from instant page.
    app = garden.getAppByID('clovyr-code');
  }
  if (!app) {
    throw new Error('app not found');
  }

  let dep: Deployment | undefined;
  if (fqdn) {
    // got fqdn via some other method, use it to look up existing deployment
    // if we have it, open it immediately or send to library, as needed.
    dep = pollenStore.deployments.find((d) => d.fqdn === fqdn);
    if (dep) {
      return redirectToDep(dep, isCode);
    }
  }

  const [instance, deployment] = await deploymentStore.createDeployment(deploymentName);
  if (!(instance && instance.fqdn)) {
    throw new Error('unable to acquire instance');
  }

  // race a call to the ready endpoint to speed up claim below
  // (starts tls handshake to fqdn as early as possible)
  wnextHTTP.get(`https://${instance.fqdn}/_leaf/ready`).catch(() => {});

  // ensure it is not already in the store by checking for fqdn
  dep = pollenStore.deployments.find((d) => d.fqdn === instance.fqdn);

  if (dep) {
    return redirectToDep(dep, isCode);
  }

  // create new deployment in pollen store
  dep = pollenStore.createDeployment(app.metadata, getHostByID(ComputeProviderName.WNext)!);

  dep.instanceName = deployment.name;
  dep.state = DeploymentState.Running;
  dep.fqdn = instance.fqdn;
  if (instance.configurekey) {
    dep.accessList.push(instance.configurekey);
  }
  // launch.deployment.appSettings = {}; // TODO: get from server

  const values: Values = {
    [CLOVYR_FQDN]: instance.fqdn,
  };
  const accessKey = await new WNextAccessAPI().createAccessKey({ fqdn: instance.fqdn });
  addBackupValues(
    values,
    `${config.s3URL}/${dep.id}`,
    accessKey,
    appConfig.VITE_PUBLIC_URL,
    instance.configurekey!,
  );
  dep.appSettings = values;

  if (isCode) {
    // only for clovyr code path
    await claimInstance({
      host: instance.fqdn,
      oldKey: instance.configurekey!,
      // TODO: randomize new key and save it to pollen
      newKey: instance.configurekey!,
      resticConfig: resticConfigFromValues(values),
    });
  }

  await pollenStore.pollen.putDeployment(dep);
  await pollenStore.pollen.putInstance(instance);

  // show it now
  return redirectToDep(dep, isCode);
}

<script setup lang="ts">
import { LexoRank } from 'lexorank';

import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import { type PublisherAppImageVersion, type PublisherAppVersion } from '@clovyr/pollen';
import { collectImages, dumpYaml, parseManifest } from '@clovyr/pollen/manifest';
import { PublisherAPI } from '@clovyr/pollen/publisher';

import FormField from '@/components/blocks/FormField.vue';
import FormWrapper from '@/components/forms/FormWrapper.vue';
import EditIcon from '@/components/icons/EditIcon.vue';
import SlideIconVue from '@/components/icons/SlideIcon.vue';
import { usePublisher } from '@/composables/usePublisher';

import PublisherAppVersionImage from './PublisherAppVersionImage.vue';

const props = defineProps<{ publisherID: string; appID: string; versionID: string }>();
const { publisherID, appID, versionID } = toRefs(props);

const emit = defineEmits(['saved']);

const { publisher, pubApp: originalPubApp, pubAppVersions } = usePublisher(publisherID, appID);

const pubAppVersion = computed(() => {
  return pubAppVersions.value.find((v) => v.id === versionID.value);
});

const images = computed<PublisherAppImageVersion[]>(() => {
  return collectImages(pubAppVersion.value?.manifest || '');
});

const editLink = computed(() => {
  if (!(publisher.value && originalPubApp.value && pubAppVersion.value)) {
    return '';
  }
  return `/publishers/${publisher.value.slug}/apps/${originalPubApp.value.slug}/versions/${pubAppVersion.value.id}/edit?back=versions`;
});

const activeLabel = computed(() => {
  return pubAppVersion.value?.status === 'published' ? 'Active' : 'Set Active';
});

async function onChangeToplineVersion(e) {
  if (pubAppVersion.value) {
    // also change the manifest version
    if (!pubAppVersion.value?.manifest) {
      return;
    }
    const manifest = parseManifest(pubAppVersion.value.manifest);
    if (!manifest) {
      return;
    }
    manifest.metadata.version = e.target.value;
    pubAppVersion.value.manifest = dumpYaml(manifest);
  }
}

async function onSubmit() {
  if (!pubAppVersion.value) {
    return;
  }

  // update image versions in manifest

  let r: PublisherAppVersion;
  if (pubAppVersion.value.id) {
    r = await new PublisherAPI().updateAppVersion({
      publisherID: publisherID.value,
      ...pubAppVersion.value,
    });
  } else {
    r = await new PublisherAPI().createAppVersion({
      publisherID: publisherID.value,
      ...pubAppVersion.value,
    });
  }
  if (r) {
    Object.assign(pubAppVersion.value, r);
  }
  emit('saved', r);
}

async function onClickDown() {
  if (pubAppVersion.value) {
    const { id } = pubAppVersion.value;
    const idx = pubAppVersions.value.findIndex((v) => v.id === id);
    const newIndex = idx + 1;
    if (newIndex >= pubAppVersions.value.length) {
      return;
    }
    pubAppVersion.value.rank = LexoRank.parse(pubAppVersions.value[newIndex].rank)
      .genNext()
      .toString();
    void onSubmit();
  }
}

async function onClickUp() {
  if (pubAppVersion.value) {
    const { id } = pubAppVersion.value;
    const idx = pubAppVersions.value.findIndex((v) => v.id === id);
    const newIndex = idx - 1;
    if (newIndex < 0) {
      return;
    }
    pubAppVersion.value.rank = LexoRank.parse(pubAppVersions.value[newIndex].rank)
      .genPrev()
      .toString();
    void onSubmit();
  }
}

async function onClickPublish() {
  // mark this one active, all others in-active
  if (pubAppVersion.value) {
    pubAppVersion.value.status = 'published';
    void onSubmit();
    pubAppVersions.value.forEach((v) => {
      if (v.id !== versionID.value) {
        v.status = 'inactive';
        void new PublisherAPI().updateAppVersion({
          publisherID: publisherID.value,
          ...v,
        });
      }
    });
  }
}
</script>

<template>
  <FormWrapper
    class="wrap"
    keep-values
    :show-submit="false"
    :reversed="false"
    translucent
    v-if="pubAppVersion"
  >
    <template v-slot:fields>
      <div class="app-form">
        <div class="flex-row">
          <div class="topline">
            <FormField
              name="version"
              label="Topline Version Number"
              v-model="pubAppVersion.version"
              @change="onChangeToplineVersion"
            />
          </div>
          <div class="actions">
            <Button
              v-if="false"
              class="down"
              :shape="ButtonShape.Circle"
              translucent
              @click.stop="onClickDown"
              :disabled="!pubAppVersion!.id"
            >
              <SlideIconVue />
            </Button>
            <Button
              v-if="false"
              class="up"
              :shape="ButtonShape.Circle"
              translucent
              @click="onClickUp"
              :disabled="!pubAppVersion!.id"
            >
              <SlideIconVue />
            </Button>
            <Button
              class="edit"
              :shape="ButtonShape.Circle"
              translucent
              :disabled="!pubAppVersion.id"
              :link="editLink"
            >
              <EditIcon />
            </Button>
            <Button translucent @click="onSubmit" label="Save" />
            <Button
              class="set-active"
              translucent
              @click="onClickPublish"
              :disabled="!pubAppVersion.id || pubAppVersion.status === 'published'"
              :label="activeLabel"
            />
          </div>
        </div>
        <!-- one for each image -->
        <PublisherAppVersionImage
          v-for="image in images"
          :key="image.image"
          :publisherID="publisherID"
          :appID="appID"
          :versionID="pubAppVersion.id"
          :image="image"
        />
      </div>
    </template>
  </FormWrapper>
</template>

<style scoped lang="scss">
.wrap {
  padding: 1.25rem 1.5rem 1.75rem 1.5rem;
  border-top: 1px solid color(grey, primary);
  border-left: 1px solid color(grey, primary);
  border-right: 1px solid color(grey, primary);
  &:last-child {
    border-bottom: 1px solid color(grey, primary);
  }
}
.flex-row {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.topline {
  flex-basis: 100%;
  margin-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

.actions {
  display: flex;
  align-items: flex-end;
  margin-left: 0.5rem;

  .down {
    transform: rotate(90deg);
  }
  .up {
    transform: rotate(-90deg);
  }
  .edit svg {
    stroke: unset;
  }
  .set-active :deep(span) {
    white-space: nowrap;
  }
}
</style>

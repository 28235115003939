import { type Ref } from 'vue';
import { type Emitter } from 'mitt';

import {
  ComputeInstanceSize,
  type ComputeProviderConfig,
  DeploymentState,
  Instance,
} from '@clovyr/pollen';
import { WNext } from '@clovyr/pollen/compute/wnext';
import { WNextDeployment } from '@clovyr/pollen/deployment/wnext';
import { WNextDNS } from '@clovyr/pollen/dns/wnext';
import { getHostByID } from '@clovyr/pollen/fixtures/hosts';
import { CLOVYR_FQDN } from '@clovyr/pollen/manifest';
import { isDNSProxied } from '@clovyr/pollen/manifest/configure/util';
import { type LaunchEvents } from '@clovyr/pollen/remote_exec';
import { AppServerAPI } from '@clovyr/pollen/remote_exec/api';

import { useInstanceLauncher } from '@/composables/useInstanceLauncher';
import { useLaunchStore } from '@/stores/launch_store';
import { usePollenStore } from '@/stores/pollen_store';

import { useAppStatus } from './useAppStatus';
import { useAppVersion } from './useAppVersion';
import { useBeforeUnload } from './useBeforeUnload';

export function useAppResume(deploymentID: Ref<string>) {
  const pollenStore = usePollenStore();
  const { deployment, app, setDeploymentState, isSleeping } = useAppStatus(deploymentID);

  async function startResume(emitter: Emitter<LaunchEvents>) {
    // create a new instance
    // TODO: use emitter events to show progress in modal text
    console.time(`launch ${deployment.value.id}`);
    useBeforeUnload().addBlocker(`deployment/${deployment.value.id}`);

    emitter.on('launch:instance_up', async () => {
      await setDeploymentState(DeploymentState.LaunchedInstance);
    });

    emitter.on('launch:app_deploying', async () => {
      await setDeploymentState(DeploymentState.Deploying);
    });

    emitter.on('launch:err', (err) => {
      useBeforeUnload().removeBlocker(`deployment/${deployment.value.id}`);
      console.error('launch error', err);
    });

    let instance: Instance | undefined;

    let claimed = false;
    try {
      const res = await new WNextDeployment().createDeployment({
        appName: deployment.value.appID,
      });
      instance = res.instance;
      claimed = true;
    } catch (error) {
      console.warn('failed to claim instance:', error);
    }

    const dnsProvider = new WNextDNS();
    if (!claimed) {
      // providers
      const computeProvider = pollenStore.getComputeProvider(deployment.value);
      const proxyDNS = isDNSProxied(app.value, undefined); // FIXME: what if using custom domain??

      await setDeploymentState(DeploymentState.LaunchingInstance);
      emitter.emit('launch:start');

      instance = await useInstanceLauncher().launchInstance(
        computeProvider,
        dnsProvider,
        proxyDNS,
        deployment.value.region!,
        (deployment.value.size ||
          app.value.deployment.compute?.size ||
          ComputeInstanceSize.Small) as ComputeInstanceSize,
        undefined, // request a new fqdn for the instance
      );

      await setDeploymentState(DeploymentState.WaitingInstance);
      emitter.emit('launch:wait');
    }

    if (!instance) {
      throw new Error('failed to create or claim instance');
    }

    // replace fqdn with the new one
    const oldFQDN = deployment.value.appSettings[CLOVYR_FQDN] as string;
    const newFQDN = instance.fqdn!;
    deployment.value.fqdn = newFQDN;
    deployment.value.appSettings[CLOVYR_FQDN] = newFQDN;
    Object.entries(deployment.value.appSettings).forEach(([key, value]) => {
      if (typeof value === 'string' && value.includes(oldFQDN)) {
        deployment.value.appSettings[key] = value.replace(oldFQDN, newFQDN);
      }
    });

    // update new fqdn on server-side as well
    await new WNext().wakeup({ oldFQDN, newFQDN });

    // save
    await pollenStore.pollen.putDeployment(deployment.value);
    await pollenStore.pollen.putInstance(instance);

    // deploy
    const manifest = await pollenStore.garden.loadDeploymentDetails(app.value);
    if (claimed) {
      // reset app env before deploying from backup
      const api = new AppServerAPI(instance.fqdn!, instance.configurekey!, deployment.value.appID);
      await api.exec(`docker compose down --volumes`);
    }

    // deploy app
    await useInstanceLauncher().deployInstance(
      manifest,
      instance,
      deployment.value.appSettings,
      emitter,
      dnsProvider,
      claimed,
    );

    // rotate key
    const oldKey = deployment.value.accessList[0];
    const newKey = await useInstanceLauncher().rotateKeys(instance);
    if (newKey) {
      // remove old key, add new key
      deployment.value.accessList = deployment.value.accessList.filter((k) => k !== oldKey);
      deployment.value.accessList.push(newKey);
    }

    void useAppVersion(deploymentID).runPostLaunchTasks();

    // update status
    await setDeploymentState(DeploymentState.Running);
    console.timeEnd(`launch ${deployment.value.id}`);
    useBeforeUnload().removeBlocker(`deployment/${deployment.value.id}`);
  }

  /**
   * Wake up the instance if it's currently sleeping.
   *
   * @returns
   */
  async function wakeUpInstance() {
    if (!isSleeping.value) {
      return;
    }

    const launchStore = useLaunchStore();

    const provider = getHostByID(deployment.value.hostingProvider);

    const launch = await launchStore.createLaunch(
      app.value,
      deployment.value,
      provider!,
      {} as ComputeProviderConfig,
      {},
    );

    await setDeploymentState(DeploymentState.Waking);
    await useAppResume(deploymentID).startResume(launch.emitter);
  }

  return {
    startResume,
    wakeUpInstance,
  };
}

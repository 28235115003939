<template>
  <div class="help">
    <Accordion
      :defaultClosed="contentVisible"
      :disable-heading-click="true"
      @toggled="handleTocToggle"
      class="help__accordion"
    >
      <template v-slot:heading-left>
        <div class="help__heading" @click="handleBackArrowClick">
          <ArrowBackIcon v-show="contentVisible" />
          {{ activeChapter ? activeChapter : 'Clovyr Help' }}
        </div>
      </template>

      <template v-slot:content>
        <TableOfContents v-show="!contentVisible" @item-select="handleTocItemSelect" />
      </template>
    </Accordion>

    <div v-show="contentVisible" class="help-content">
      <RecoveryKitHelpPage
        v-if="activePage === 'recovery-kit-help'"
        @navigate="handleNavigation"
      ></RecoveryKitHelpPage>
      <GettingStartedPage v-if="activePage === 'getting-started'" @navigate="handleNavigation" />
      <LaunchingPage v-if="activePage === 'launching-clovyr-code'" @navigate="handleNavigation" />
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/blocks/Accordion.vue';
import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import GettingStartedPage from '@/views/Help/GettingStartedPage.vue';
import LaunchingPage from '@/views/Help/LaunchingPage.vue';
import RecoveryKitHelpPage from '@/views/Help/RecoveryKitHelpPage.vue';
import TableOfContents from '@/views/Help/TableOfContents.vue';

export default {
  name: 'Help',
  components: {
    ArrowBackIcon,
    Accordion,
    TableOfContents,
    GettingStartedPage,
    LaunchingPage,
    RecoveryKitHelpPage,
  },
  data() {
    return {
      activePage: '',
      contentVisible: false,
    };
  },
  methods: {
    handleTocItemSelect(page) {
      this.activePage = page.name;
      this.activeChapter = page.chapter;
      this.contentVisible = true;
    },
    handleTocToggle(isClosed) {
      this.contentVisible = isClosed;
    },
    handleNavigation(page) {
      this.activePage = page;
    },
    handleBackArrowClick() {
      this.activeChapter = '';
      this.activePage = '';
      this.contentVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.help {
  .accordion-heading {
    align-items: flex-start;
    margin-bottom: space(4);
  }

  &__heading {
    @extend .text--mono;

    margin-right: space(1);
    margin-bottom: space(5);
    color: #29bd7b;
    cursor: pointer;
  }

  :deep(.accordion__icon) {
    visibility: hidden;
  }
  :deep(.arrow-back-icon) {
    stroke: #29bd7b;
    display: inline;
    transform: translateY(5px);
    margin-right: space(2);
  }
  :deep(.accordion-heading) {
    cursor: auto;
  }
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { ComputeProviderName } from '@clovyr/pollen';

import AWSEC2 from '@/components/forms/hosts/AWSEC2.vue';
import CustomHost from '@/components/forms/hosts/CustomHost.vue';
import DigitalOcean from '@/components/forms/hosts/DigitalOcean.vue';
import Linode from '@/components/forms/hosts/Linode.vue';
import WNext from '@/components/forms/hosts/WNext.vue';
import { useLauncherStore } from '@/stores/launcher_store';

const launcherStore = useLauncherStore();
const { selectedHost, metadata } = storeToRefs(launcherStore);

const options = {
  custom: CustomHost,
  [ComputeProviderName.DigitalOcean]: DigitalOcean,
  [ComputeProviderName.WNext]: WNext,
  [ComputeProviderName.Linode]: Linode,
  [ComputeProviderName.AWSEC2]: AWSEC2,
};
</script>

<template>
  <component :is="options[selectedHost?.id || 'custom']" :metadata="metadata"></component>
</template>

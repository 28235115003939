import { fetchMail } from '@clovyr/pollen/mail';

import { useAppStatus } from './useAppStatus';
import { useAppVersion } from './useAppVersion';

export const useAppEvents = (deploymentID: Ref<string>) => {
  // App Trial Expiring && App Suspended && Update Avail
  const { app, deployment, isTrial, trialTimeRemaining, isSuspended, isRunning } =
    useAppStatus(deploymentID);
  const { isUpdateAvailable } = useAppVersion(deploymentID);

  // App Has Mail?
  const emailCount = ref(0);

  async function getEmailCount() {
    if (!deployment.value?.fqdn || !isRunning.value || !app.value?.deployment.addons?.mailhog) {
      emailCount.value = 0;
      return;
    }
    emailCount.value = (
      await fetchMail(`https://${deployment.value.fqdn}`, deployment.value.accessList[0])
    ).length;
  }

  watchEffect(() => {
    void getEmailCount();
  });

  const appEventCount = computed<number>(() => {
    let count = 0;
    if (emailCount.value > 0) {
      count++;
    }
    if (
      isTrial.value &&
      Number(trialTimeRemaining.value.charAt(0)) < 7 &&
      Number(trialTimeRemaining.value.charAt(0)) !== 0 // adjusting so there is no notification once trial hits 0 days, otherwise notification will exist indefinitely
    ) {
      count++;
    }
    if (isUpdateAvailable.value === true) {
      count++;
    }
    if (isSuspended.value === true) {
      count++;
    }
    return count;
  });

  return {
    appEventCount,
    emailCount,
  };
};

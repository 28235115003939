<script setup lang="ts">
import type { Option } from '@clovyr/bed';
import SelectInputBlock from '@clovyr/bed/components/blocks/SelectInputBlock.vue';
import TextInputBlock from '@clovyr/bed/components/blocks/TextInputBlock.vue';

import Question from '@/components/forms/Question.vue';
import {
  type AnswerFn,
  answers,
  type AnswerValue,
  type LaunchConfigSetting,
  validateAnswer,
} from '@/stores/launch_settings';

import CheckboxBlock from '../blocks/CheckboxBlock.vue';

type Props = {
  question: LaunchConfigSetting;
  setAnswer: AnswerFn;
  disabled?: boolean;
  parentAnswer?: AnswerValue;
};

const props = defineProps<Props>();

const errorMessage = computed(() => {
  if (props.question.errors) {
    return props.question.errors.map((e) => e.message).join(', ');
  }
  return undefined;
});

const answerValue = computed(() => {
  const answer = answers.value[props.question.id];
  if (answer) {
    return answer.value;
  }
  return props.question.answer;
});

const selectOptions = computed<Option[]>(() => {
  if (props.question.type === 'enum' && props.question.options) {
    return props.question.options.map((o) => ({ name: o, value: o }));
  }
  return [];
});

const showQuestion = computed(() => {
  if (!props.question.condition) {
    return true;
  }
  const e = validateAnswer(props.question, props.parentAnswer, props.question.condition);
  // show if no errors
  return !e;
});
</script>

<template>
  <div v-if="showQuestion" :style="{ marginBottom: '15px' }">
    <TextInputBlock
      v-if="question.type === 'string'"
      type="text"
      :class="'formfield'"
      :errorMessage="errorMessage"
      :label="question.display_name"
      :id="question.id"
      shape="rounded"
      :value="answerValue as string"
      :disabled="disabled"
      @input="
        (e: InputEvent) => setAnswer(question.id, (e.target as HTMLInputElement).value.trim())
      "
    />

    <CheckboxBlock
      v-else-if="question.type === 'boolean'"
      type="checkbox"
      shape="rounded"
      :title="question.display_name"
      :description="question.display_description"
      :id="question.id"
      :value="answerValue"
      :disabled="disabled"
      @input="(e: InputEvent) => setAnswer(question.id, (e.target as HTMLInputElement).checked)"
    />

    <SelectInputBlock
      v-else-if="question.type === 'enum'"
      :select-options="selectOptions"
      :label="question.display_name"
      :id="question.id"
      shape="rounded"
      :value="answerValue as string"
      :disabled="disabled"
      @input="(e: InputEvent) => setAnswer(question.id, (e.target as HTMLSelectElement).value)"
    />

    <template v-if="question.subs">
      <Question
        v-for="(sub, id) in question.subs"
        :key="id"
        :question="sub"
        :parentAnswer="answerValue"
        :setAnswer="setAnswer"
        :disabled="disabled"
      />
    </template>
  </div>
</template>

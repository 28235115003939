import { defineStore } from 'pinia';

import type { UserSubscription, UserSubscriptionItem } from '@clovyr/pollen';
import type { Invoice } from '@clovyr/pollen/types/Invoice';

type SubscriptionItems = {
  [subItemID: string]: UserSubscriptionItem;
};

export const useBillingStore = defineStore('billing', () => {
  const subscription = ref<UserSubscription | undefined>();

  /**
   * Cache of sub item data which is fetched on demand
   */
  const subscriptionItems = ref<SubscriptionItems>({});

  const invoices = ref<Invoice[]>([]);

  return {
    subscription,
    subscriptionItems,
    invoices,
  };
});

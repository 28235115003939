import {
  createMemoryHistory,
  createRouter,
  createWebHistory,
  type Router,
  type RouteRecordRaw,
} from 'vue-router';

import { useMatomo } from '@clovyr/bed/composables/useMatomo';
import { getAllQueryParams } from '@clovyr/pollen/http/qs';

import { useAppResume } from '@/composables/useAppResume';
import { type PaymentCallbackState, PaymentCallbackStateKey } from '@/composables/usePayments';
import { rehydrate } from '@/stores/util';

import PathNotFoundVue from '../views/PathNotFound.vue';

import { gardenRoutes } from './garden';
import { extractUserFlagTokens, redirectToApp, requireUserFlag } from './guards';
import { instantRoutes } from './instant';
import { publisherRoutes } from './publisher';
import { userRoutes } from './user';

const pathNotFoundRoute: RouteRecordRaw = {
  path: '/:pathMatch(.*)*',
  meta: {
    layout: 'main',
    title: 'Page not found | Clovyr',
  },
  beforeEnter: redirectToApp,
  component: PathNotFoundVue,
};

const callbackRoutes: RouteRecordRaw[] = [
  {
    path: '/oauth/callbacks/do',
    name: 'Oauth DigitalOcean Callback',
    component: () =>
      import(/* webpackChunkName: "OauthCallback" */ '../views/AppLauncher/OauthCallback.vue'),
  },
  {
    path: '/oauth/callbacks/linode',
    name: 'Oauth Linode Callback',
    component: () =>
      import(/* webpackChunkName: "OauthCallback" */ '../views/AppLauncher/OauthCallback.vue'),
  },
  {
    path: '/payments/callbacks/:provider',
    name: 'Payments Callback',
    redirect: (to) => {
      // Both Stripe & Opennode will end up here after a payment is completed.
      //
      // For Stripe, it should only happen once per user, as we only need to capture payment one
      // time and then the card is on file.
      //
      // For Opennode, it will happen every time a user pays. A user can then be redirected back to
      // the invoice tab or the library, depending on the flow (initial payment vs subsequent upgrade).

      // read stored state from localStorage
      const state: PaymentCallbackState | null = rehydrate(PaymentCallbackStateKey);
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(PaymentCallbackStateKey);
      }
      // send to correct location
      const params = getAllQueryParams();
      const result = params.get('result');

      if (!state || !result) {
        // send back home. should not enter cb w/o localstorage or a proper result param
        return { path: '/' };
      }
      let path: string = state[`${result}URL`] || '/';
      let hash: string | undefined;
      if (path.includes('#')) {
        // support redirecting with a hash
        const parts = path.split('#');
        [path] = parts;
        hash = `#${parts[1]}`;
      }

      const { provider } = to.params;

      if (result === 'success' && state.deploymentID) {
        // start the wakeup
        void useAppResume(ref(state.deploymentID)).wakeUpInstance();

        // matomo track event
        const matomo = useMatomo();
        matomo.trackEvent('Payment', 'paymentComplete', provider.toString());
      }

      return { path, hash };
    },
  },
];

const routes = ([] as RouteRecordRaw[]).concat(
  gardenRoutes,
  userRoutes,
  publisherRoutes,
  instantRoutes,
  callbackRoutes,
  pathNotFoundRoute,
);

export function createAppRouter(): Router {
  const router = createRouter({
    history: import.meta.env.SSR
      ? createMemoryHistory()
      : createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior() {
      const app = document.getElementById('app');
      if (app) {
        app.scrollIntoView();
      }
    },
  });

  router.beforeEach(extractUserFlagTokens);

  router.beforeEach(requireUserFlag);

  return router;
}

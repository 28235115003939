import Ajv, { type ErrorObject } from 'ajv';
import addFormats from 'ajv-formats';
import type { JSONSchema6 } from 'json-schema';
import yaml from 'yaml';

import { createRandomKey } from '../crypto';
import type { Deployment, PublisherAppImageVersion } from '../types';
import type { AccessKey } from '../types/AccessKey';

import type { Setting } from './config';
import type { AdditionalDNSOptions } from './deployment';
import type { ComposeSpecification as DockerCompose, ListOrDict } from './docker-compose';
import type { Manifest } from './manifest';
import {
  CLOVYR_ACCESS_KEY,
  CLOVYR_BACKUP_PASSWORD,
  CLOVYR_CONFIGURE_KEY,
  CLOVYR_CUSTOM_DOMAIN,
  CLOVYR_FQDN,
  CLOVYR_S3_URL,
  CLOVYR_SECRET_KEY,
  CLOVYR_TREFOIL_URL,
  type Values,
} from './value';

const ajv = new Ajv();
addFormats(ajv);

export type Dict = {
  [k: string]: string | number | boolean | null;
};

interface Validatable {
  required?: boolean;
  random?: boolean;
  condition?: JSONSchema6;
}

type AnswerValue = string | number | boolean | null;

/**
 * Coerce the given input object to a Dict
 *
 * Lists are assumed to be in Docker Compose style key=value pairs.
 *
 * @param input
 * @returns
 */
export function coerceDict(input: ListOrDict | undefined): Dict {
  let t = input;
  if (!Array.isArray(t)) {
    t = t || ({} as Dict);
    return t;
  }
  // convert array
  const m = {} as Dict;
  t.forEach((env) => {
    const eq = env.indexOf('=');
    m[env.slice(0, eq)] = env.slice(eq + 1);
  });
  return m;
}

export function parseManifest(input: string): Manifest {
  return yaml.parse(input) as Manifest;
}

export function collectImages(m: Manifest | string): PublisherAppImageVersion[] {
  const ret: PublisherAppImageVersion[] = [];
  if (!m) {
    return ret;
  }
  const manifest = typeof m === 'string' ? parseManifest(m) : m;
  if (manifest?.config?.deployment) {
    Object.entries(manifest.config.deployment).forEach(([key, value]) => {
      if (value.version === true) {
        ret.push({
          id: key,
          name: value.name || key,
          description: value.value.description || value.description,
          image: (value.value.default as string) || '',
        });
      }
    });
  }
  return ret;
}

/**
 * Parse the given string as a docker compose YAML file.
 *
 * Coerces various fields into their preferred formats when multiple options are
 * available: labels, environment, env_file, depends_on
 *
 * @param input
 * @returns
 */
export function parseDockerCompose(input: string): DockerCompose {
  const dc = yaml.parse(input) as DockerCompose;
  if (dc.services) {
    Object.values(dc.services).forEach((svc) => {
      svc.labels = coerceDict(svc.labels);
      svc.environment = coerceDict(svc.environment);
      if (svc.env_file) {
        // coerce array
        if (!Array.isArray(svc.env_file)) {
          svc.env_file = [svc.env_file];
        }
      } else {
        svc.env_file = [];
      }
      if (svc.depends_on) {
        // ensure object expanded object style
        if (Array.isArray(svc.depends_on)) {
          type DependsOn = Record<
            string,
            { condition: 'service_started' | 'service_healthy' | 'service_completed_successfully' }
          >;
          svc.depends_on = svc.depends_on.reduce((memo, svcName) => {
            memo[svcName] = { condition: 'service_started' };
            return memo;
          }, {} as DependsOn);
        }
      } else {
        svc.depends_on = {};
      }
    });
  }
  return dc;
}

/**
 * Dump the given object as a YAML string
 *
 * @param obj
 * @returns
 */
export function dumpYaml(obj: any): string {
  return yaml.stringify(obj);
}

/**
 * Encode compose object as YAML string
 *
 * @param dc
 * @returns
 */
export function dockerComposeToString(dc: DockerCompose): string {
  return yaml.stringify(dc);
}

/**
 * Dump the given object to a string containing key/val pairs, one per line.
 *
 * This is the format expected by Docker Compose's --env-file option.
 */
export function dumpEnv(obj: any): string {
  let s = '';
  Object.entries(obj).forEach(([key, val]) => {
    const v = val !== null && val !== undefined ? val.toString().replaceAll('\n', '\\n') : ''; // escape newlines to support multiline vals
    s += `${key}=${v.toString()}\n`;
  });
  return s;
}

/**
 * Find the given setting in the application's config
 *
 * @param name
 * @param manifest
 * @returns
 */
export function findSetting(name: string, manifest: Manifest): Setting | undefined {
  return (
    manifest.config?.basic?.[name] ||
    manifest.config?.advanced?.[name] ||
    manifest.config?.deployment?.[name] ||
    undefined
  );
}

export function validateAnswer(
  v: Validatable,
  answer: AnswerValue
): ErrorObject[] | null | undefined {
  if (!v.condition) {
    return null;
  }
  ajv.validate(v.condition, answer);
  return ajv.errors;
}

/**
 * Create a new Values type, including any generated or default values
 *
 * @param values initial set of values (e.g., user input)
 * @param manifest
 * @param fqdn
 * @returns
 */
export function NewValues(
  values: Values,
  manifest: Manifest,
  fqdn: string,
  customDomain?: string
): Values {
  values[CLOVYR_FQDN] = fqdn;
  if (customDomain) {
    values[CLOVYR_CUSTOM_DOMAIN] = customDomain;
  }
  generateRandomValues(manifest, values);
  generateDefaultValues(manifest, values);
  return values;
}

/**
 * Add values required by Backup addon
 *
 * @param values
 * @param s3URL a fully qualified https URL pointing to a `leaf` instance, including the bucket path
 * @param accessKey
 * @param publicURL
 * @param configureKey
 */
export function addBackupValues(
  values: Values,
  s3URL: string,
  accessKey: AccessKey,
  publicURL: string,
  configureKey: string
) {
  // TODO: for now we always enable backups. later, tie this to the backup addons flag for BYOH.
  if (!s3URL.startsWith('s3:')) {
    s3URL = `s3:${s3URL}`;
  }
  values[CLOVYR_S3_URL] = s3URL;
  values[CLOVYR_ACCESS_KEY] = accessKey.AccessKey;
  values[CLOVYR_SECRET_KEY] = accessKey.SecretKey;
  values[CLOVYR_BACKUP_PASSWORD] = createRandomKey();
  values[CLOVYR_TREFOIL_URL] = `${publicURL}/api`;
  values[CLOVYR_CONFIGURE_KEY] = configureKey;
}

/**
 * Process config options with default values (including templated or variables)
 *
 * @param manifest
 * @param values
 */
function generateDefaultValues(manifest: Manifest, values: Values) {
  const getDefaultVal = (setting: Setting): any => {
    if (typeof setting.value?.default !== 'undefined') {
      if (setting.value.type === 'string' && typeof setting.value?.default === 'string') {
        // replace any variables within the default value
        return replaceVariables(setting.value.default, values);
      }
      return setting.value.default;
    }
    return undefined;
  };
  const setDefaultVal = (key: string, setting: Setting): any => {
    if (Object.hasOwn(values, key)) {
      return values[key];
    }
    const val = getDefaultVal(setting);
    if (typeof val !== 'undefined') {
      // only if not already set and val is not undefined
      values[key] = val;
    }
    return val;
  };
  const processSetting = ([key, setting]: [string, Setting]) => {
    if (setting.random) {
      return;
    }
    const val = setDefaultVal(key, setting);
    if (setting.sub_settings) {
      Object.entries(setting.sub_settings).forEach(([k, s]) => {
        if (s.condition) {
          const errors = validateAnswer(s, val);
          if (!errors) {
            processSetting([k, s]);
          }
        } else {
          processSetting([k, s]);
        }
      });
    }
  };

  if (manifest.config) {
    if (manifest.config.deployment) {
      Object.entries(manifest.config.deployment).forEach(processSetting);
    }
    if (manifest.config.basic) {
      Object.entries(manifest.config.basic).forEach(processSetting);
    }
    if (manifest.config.advanced) {
      Object.entries(manifest.config.advanced).forEach(processSetting);
    }
  }
}

/**
 * Process config options which require random values to be generated
 *
 * @param manifest
 * @param values
 */
function generateRandomValues(manifest: Manifest, values: Values) {
  const processSetting = ([key, setting]: [string, Setting]) => {
    if (Object.hasOwn(values, key)) {
      return;
    }
    let val: any;
    if (setting.random) {
      let len = 32;
      if (setting.value && setting.value.minLength) {
        len = setting.value.minLength;
      }
      val = createRandomKey(len);
    }
    if (val) {
      values[key] = val;
    }
  };

  if (manifest.config) {
    if (manifest.config.deployment) {
      Object.entries(manifest.config.deployment).forEach(processSetting);
    }
    if (manifest.config.basic) {
      Object.entries(manifest.config.basic).forEach(processSetting);
    }
    if (manifest.config.advanced) {
      Object.entries(manifest.config.advanced).forEach(processSetting);
    }
  }
}

/**
 * Replace variables in the given string from the values bag.
 *
 * Variables use mustache-style: {{ VARNAME }}
 *
 * In addition, we support some specific variables which are provided by Clovyr:
 * - HOSTNAME -> maps to CLOVYR_FQDN
 * - HOST_RULES -> maps to CLOVYR_FQDN + CLOVYR_CUSTOM_DOMAIN
 *
 * @param str
 * @param values
 * @returns
 */
export function replaceVariables(str: string, values: Values): string {
  const primaryFQDN = values[CLOVYR_CUSTOM_DOMAIN] || values[CLOVYR_FQDN];

  return str.replaceAll(/{{\s*(.*?)\s*}}/g, (m, key) => {
    if (key) {
      if ((key === 'HOSTNAME' || key === 'FQDN') && primaryFQDN) {
        return primaryFQDN.toString();
      }
      if (key === 'HOST_RULES' && values[CLOVYR_FQDN]) {
        return formatHostRules(
          values[CLOVYR_FQDN] as string,
          values[CLOVYR_CUSTOM_DOMAIN] as string
        );
      }
      // Return a value if we have it, or the original string containing the token
      // e.g., if the string is {{ FOO }} and no value is provided for FOO, return '{{ FOO }}'
      const val = Object.hasOwn(values, key) ? (values[key] ?? '').toString() : m;
      return val.replaceAll('\n', '\\n');
    }
    return m;
  });
}

function wrapHost(s: string): string {
  return `\`${s}\``;
}

/**
 * Create a string with one or more host rules, for use by traefik.
 *
 * @param fqdn Primary FQDN for the application
 * @param customDomain Optional custom domain for BYOD
 * @param additionalHost Optional prefix to the fqdn or customDomain
 * @returns
 */
export function formatHostRules(fqdn: string, customDomain?: string, additionalHost?: string) {
  let hostRules = '';
  if (additionalHost) {
    hostRules = wrapHost(`${additionalHost}-${fqdn}`);
  } else {
    hostRules = wrapHost(fqdn);
  }
  if (customDomain) {
    if (additionalHost) {
      hostRules += `, ${wrapHost(`${additionalHost}-${customDomain}`)}`;
    } else {
      hostRules += `, ${wrapHost(customDomain)}`;
    }
  }
  return hostRules;
}

/**
 * Get the primary DNS endpoint, if one is specified
 *
 * @param manifest
 * @returns
 */
export function getPrimaryDNS(
  manifest: Manifest
): [string, string | AdditionalDNSOptions] | undefined {
  if (manifest.deployment?.dns?.additional_hosts) {
    return Object.entries(manifest.deployment.dns.additional_hosts).find(
      ([, val]) => typeof val === 'object' && val.default_url
    );
  }
  return undefined;
}

export function deploymentAppID(deployment: Deployment): string {
  let id = deployment.appID;
  if (deployment.publisherID) {
    id = `${deployment.publisherID}/${id}`;
  }
  return id;
}

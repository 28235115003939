import type { PublisherAppVersion } from '../types';
import type { DockerCredentials } from '../types/DockerCredentials';

import { kcp, kexec, podName, writeFileCmd } from './util';
import { Builder } from '.';

type Option = {
  name: string;
  value: string;
};

/**
 * List of base images available for building new Clovyr Code applications.
 *
 * This list will be used for the dropdown in the UI.
 */
export const CodeBaseImages: Array<Option> = [
  {
    name: '',
    value: '',
  },
  {
    name: 'Bitcoin',
    value: 'clovyr/code-bitcoin:dev',
  },
  {
    name: 'Elm',
    value: 'clovyr/code-elm:dev',
  },
  {
    name: 'Go',
    value: 'clovyr/code-go:dev',
  },
  {
    name: 'Haskell',
    value: 'clovyr/code-haskell:dev',
  },
  {
    name: 'JavaScript/TypeScript',
    value: 'clovyr/code-javascript:dev',
  },
  {
    name: 'Nix',
    value: 'clovyr/code-nix:dev',
  },
  {
    name: 'Python',
    value: 'clovyr/code-python:dev',
  },
  {
    name: 'Ruby',
    value: 'clovyr/code-ruby:dev',
  },
  {
    name: 'Rust',
    value: 'clovyr/code-rust:dev',
  },
  {
    name: 'Ubuntu (Focal)',
    value: 'clovyr/code-ubuntu:focal',
  },
  {
    name: 'Ubuntu (Jammy)',
    value: 'clovyr/code-ubuntu:jammy',
  },
];

export class CodeBuilder extends Builder {
  protected buildScript(
    appVersion: PublisherAppVersion,
    imageName: string,
    dockerCredentials?: DockerCredentials
  ): string {
    if (!dockerCredentials) {
      throw new Error('dockerCredentials is required');
    }

    let script = `
set -eo pipefail

rm -rf /opt/clovyr/cicd/build
mkdir -p /opt/clovyr/cicd/build
cd /opt/clovyr/cicd/build

`;

    // only for code apps, include uploaded files
    if (appVersion.files) {
      appVersion.files.forEach((file) => {
        if (file.deleted_at || !file.content.startsWith('https://')) {
          return;
        }
        script += `
wget -O "${file.name}" "${file.content}"
`;
      });
    }

    script += `
${writeFileCmd(
  'Dockerfile',
  `
FROM ${appVersion.base_image}

USER root

# init scripts
RUN mkdir -p /opt/clovyr/code /data/vscode-settings/extensions
COPY ./*.sh /opt/clovyr/code/
RUN chmod 755 /opt/clovyr/code/*.sh

# extensions
COPY . /tmp/clovyr/
RUN [ -n "$(ls /tmp/clovyr/*.vsix 2>/dev/null)" ] \
      && mv /tmp/clovyr/*.vsix /data/vscode-settings/extensions/ \
      || true
RUN rm -rf /tmp/clovyr

USER clovyr
WORKDIR /opt/clovyr/code
RUN ./build.sh

USER root
RUN rm -f build.sh run.sh

USER clovyr
WORKDIR /home/clovyr
`
)}

${writeFileCmd('build.sh', appVersion.build_script)}

${writeFileCmd('init.sh', appVersion.init_script)}

# run the build
${writeFileCmd(
  'run.sh',
  `
#!/bin/sh
set -eo pipefail
cd /opt/clovyr/cicd/build

docker login \
  --username "${dockerCredentials.login}" \
  --password "${dockerCredentials.pass}" \
  "${imageName}"

docker build -t ${imageName} .
docker push ${imageName}
`
)}

chmod 755 *.sh

# run the build in the dind container
${podName}
${kexec('dind', 'mkdir -p /opt/clovyr/cicd')}
${kexec('dind', 'rm -rf /opt/clovyr/cicd/build')}
${kcp('dind', '/opt/clovyr/cicd/build', '/opt/clovyr/cicd/build')}
${kexec('dind', '/opt/clovyr/cicd/build/run.sh')}
`;

    return script;
  }

  protected runScript(appVersion: PublisherAppVersion, imageName: string): string {
    return `
set -eo pipefail
set -x

rm -rf /opt/clovyr/cicd/test
mkdir -p /opt/clovyr/cicd/test
cd /opt/clovyr/cicd/test

# docker-compose.yaml
${writeFileCmd(
  'docker-compose.yaml',
  `
volumes:
  data: {}
  code: {}

services:
  init-code:
    image: clovyr/code-inner:dev
    entrypoint: /entrypoint.sh
    env_file:
      - .env
    volumes:
      - /home/clovyr/cicd/test/init-code.sh:/entrypoint.sh
      - data:/tmp/data
      - code:/tmp/code
  init-image:
    image: "${imageName}"
    entrypoint: /entrypoint.sh
    user: root
    env_file:
      - .env
    volumes:
      - /home/clovyr/cicd/test/init-image.sh:/entrypoint.sh
      - data:/tmp/data
      - code:/tmp/code
    depends_on:
      init-code:
        condition: service_completed_successfully
  code:
    image: "${imageName}"
    entrypoint: /entrypoint.sh
    restart: unless-stopped
    ports:
      - "8080:8480"
    volumes:
      - /home/clovyr/cicd/test/entrypoint.sh:/entrypoint.sh
      - code:/opt/code
      - data:/home/clovyr
    depends_on:
      init-image:
        condition: service_completed_successfully
    env_file:
      - .env
    environment:
      CS_DISABLE_GETTING_STARTED_OVERRIDE: "1"
      CODE_IMAGE_NAME: "${imageName}"
`
)}

# init-code.sh - copy code-server to volume and set up extensions and config
${writeFileCmd(
  'init-code.sh',
  `#!/usr/bin/env bash

set -euxo pipefail

if [ -f /tmp/data/.config/clovyr-code/init-code-done ]; then
  echo "*** init-code already completed ***"
  exit 0
fi

# init code
cp -a /usr/lib/code-server /tmp/code
mkdir -p /tmp/data/git
echo \${CODE_EXAMPLE_REPO} > /tmp/data/git/repos

SHARE=/tmp/data/.local/share/code-server
mkdir -p $SHARE/User $SHARE/extensions
if [ ! -f "$SHARE/User/settings.json" ]; then
    cp /data/vscode-settings/User/settings.json $SHARE/User/settings.json
fi

# TODO: Temporary third party extensions included; they should
# be fetched via .vscode extension suggestions and marketplace instead.

rm -rf \
    $SHARE/extensions/clovyr-dark \
      $SHARE/extensions/code-extension

cp /usr/local/bin/unzip /tmp/data/unzip
cp -a /data/vscode-settings/extensions/clovyr-dark $SHARE/extensions/clovyr-dark
cp -a /data/vscode-settings/extensions/code-extension $SHARE/extensions/clovyr-code

mkdir -p /tmp/data/.config/clovyr-code
touch /tmp/data/.config/clovyr-code/init-code-done
echo "*** init-code completed successfully ***"
`
)}

# init-image.sh - setup home dir and install code-server into app volume
${writeFileCmd(
  'init-image.sh',
  `
#!/usr/bin/env bash

set -euxo pipefail

if [ -f /tmp/data/.config/clovyr-code/init-image-done ]; then
  echo "*** init-image already completed ***"
  exit 0
fi

# init image
SUDO=""
if [ $(whoami) != "root" ]; then
  echo "This script must be run as root. Trying to use sudo..."
  SUDO="sudo"
fi
if [ ! -f "/tmp/data/.config/clovyr-code/init-image-done" ]; then
  # Copy any existing data from the image into the mount
  $SUDO cp -a /home/clovyr/. /tmp/data || true
  $SUDO mkdir -p /tmp/run # if the volume isn't mounted
  $SUDO chown -R clovyr:clovyr /tmp/data /tmp/code /tmp/run
  $SUDO mkdir -p /tmp/data/.config/clovyr-code
  $SUDO /tmp/data/unzip $(for f in $(ls /data/vscode-settings/extensions); do echo /data/vscode-settings/extensions/$f ; done)
  $SUDO cp -a /data/vscode-settings/extensions/. /tmp/data/.local/share/code-server/extensions/ || true
  $SUDO chmod -R u=rwx,g=rx,o=rx /tmp/data/.local/share/code-server/extensions/
  $SUDO rm /tmp/data/unzip
  $SUDO touch /tmp/data/.config/clovyr-code/init-image-done
fi
$SUDO chown -R clovyr:clovyr /tmp/data /tmp/data/.local/share/code-server

mkdir -p /tmp/data/.config/clovyr-code
touch /tmp/data/.config/clovyr-code/init-image-done
echo "*** init-image completed successfully ***"
`
)}

# entrypoint.sh - run code-server
${writeFileCmd(
  'entrypoint.sh',
  `
#!/usr/bin/env bash

set -euxo pipefail

export CODE_BIN=/opt/code/code-server/bin/code-server
echo \${CODE_IMAGE_NAME} > /tmp/code-image
sudo rm -rf /data/vscode-settings/extensions || true

if [ -n "\${CODE_EXAMPLE_REPO}" ]; then
  repoHumanName=\${CODE_EXAMPLE_REPO}
  repoHumanName=\${repoHumanName%.git}
  repoHumanName=\${repoHumanName##*/}

  # TEMP, preclone for speed/to avoid extension auth. Must be changed before cloning non-clovyr repos or will be weird.
  if [ ! -d "/home/clovyr/git/github.com/clovyr/\${repoHumanName}" ]; then
    mkdir -p /home/clovyr/git/github.com/clovyr && (cd /home/clovyr/git/github.com/clovyr && git clone \${CODE_EXAMPLE_REPO} \${repoHumanName})
  fi
fi

DISABLE_TRUST=--disable-workspace-trust
if [[ "$($CODE_BIN --version | grep -ve '^\\[' | awk '{print $1}')" == "3.12.0" ]]; then
  # don't disable on old versions of code-server
  DISABLE_TRUST=
fi

install_extensions() {
  for ext in "$@"; do
    echo ">>> trying to install extension $ext (up to 10 tries)"
    tries=0
    until $CODE_BIN --install-extension "$ext" || [ $tries -eq 10 ]; do
      sleep 1
      tries=$((tries+1))
    done
  done
}
export -f install_extensions

if [ ! -f /home/clovyr/.config/clovyr-code/.user-init-done ]; then
  if [ -x /opt/clovyr/code/init.sh ]; then
    echo "*** running user init script ***"
    set +e
    bash -l /opt/clovyr/code/init.sh
    set -e
    echo "*** user init script completed successfully ***"
  fi
  touch /home/clovyr/.config/clovyr-code/.user-init-done
fi

$CODE_BIN \
  --bind-addr=0.0.0.0:8480 --auth=none --disable-telemetry --disable-update-check \${DISABLE_TRUST} -vvv
`
)}

${writeFileCmd(
  '.env',
  `
CODE_EXAMPLE_REPO=${appVersion.example_repo || ''}
CODE_IMAGE=${imageName}
`
)}

${writeFileCmd(
  'run.sh',
  `
#!/bin/sh
cd /home/clovyr/cicd/test
sudo docker compose up -d
sudo docker compose logs
`
)}

# run app in code container
chmod 755 *.sh

# copy into pod
${podName}
# copy files to both code & dind containers (so we can mount volumes properly)
${kexec('code', 'rm -rf /home/clovyr/cicd')}
${kexec('dind', 'rm -rf /home/clovyr/cicd')}
${kexec('code', 'mkdir -p /home/clovyr/cicd')}
${kexec('dind', 'mkdir -p /home/clovyr/cicd')}
${kcp('code', '/opt/clovyr/cicd/test', '/home/clovyr/cicd/test')}
${kcp('dind', '/opt/clovyr/cicd/test', '/home/clovyr/cicd/test')}
# run it
${kexec('code', '/home/clovyr/cicd/test/run.sh')}
  `;
  }
}

import { nextTick, onBeforeUnmount } from 'vue';

export default async function useBodyScrollLock(el: HTMLElement) {
  if (import.meta.env.SSR || typeof window === 'undefined') {
    return {};
  }

  const $el = ref(el);

  function handleBodyScrollLock(opened, unmounting = false) {
    if (opened && !unmounting) {
      disableBodyScroll($el.value, { reserveScrollBarGap: true });
    } else {
      enableBodyScroll($el.value);
    }
  }

  function disableScrolling() {
    handleBodyScrollLock(true);
  }

  function enableScrolling() {
    handleBodyScrollLock(false, true);
  }

  onBeforeUnmount(() => {
    enableScrolling();
  });

  const { disableBodyScroll, enableBodyScroll } = await import('body-scroll-lock');

  void nextTick(() => {
    disableScrolling();
  });

  return { handleBodyScrollLock, disableScrolling, enableScrolling };
}

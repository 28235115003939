<script setup lang="ts">
import { Hero } from '@clovyr/bed';

import Breadcrumbs from '@/components/elements/Breadcrumbs.vue';
import AppSummary from '@/components/tiers/AppSummary.vue';
import { usePollenStore } from '@/stores/pollen_store';

const { garden } = usePollenStore();

const apps = garden.getApps();
</script>

<template>
  <div class="collection">
    <AppSummary v-for="(app, idx) in apps" :key="idx" :app="app"> </AppSummary>
  </div>
</template>

import { WNextBase } from '../api/wnext-base';
import { Publisher } from '../types/Publisher';
import { PublisherApp, PublisherAppFile, PublisherAppVersion } from '../types/PublisherApp';

type CreatePublisherReq = {
  name: string;
  contact_name: string;
  contact_email: string;
  slug: string;
};

type UpdatePublisherReq = {
  id: string;
  contact_name: string;
  contact_email: string;
};

type CreatePublisherAppReq = {
  id: string;
  publisher_id: string;
  name: string;
  slug: string;
  description?: string;
  logo_url?: string;
  app_type: string;
  base_image?: string;
  build_script?: string;
  init_script?: string;
  manifest?: string;
};

type ListAppsReq = {
  publisher_id: string;
};

type CapturePaymentReq = {
  publisher_id: string;
};

type GetPublisherReq = {
  id: string;
  invite?: string;
};

type ListPublisherAppVersionsReq = {
  publisher_id: string;
  app_id: string;
};

interface CreateAppVersionReq {
  publisherID: string;

  id: string;
  publisher_app_id: string;
  version: string;
  image_versions?: string;
  release_notes?: string;
  rank: string;
  status: string;
  description?: string;
  logo_url?: string;
  base_image?: string;
  example_repo?: string;
  build_script?: string;
  init_script?: string;
  manifest?: string;
  created_at: Date;
  updated_at: Date;
  files: PublisherAppFile[];
}

type CheckNameReq = {
  name: string;
  slug: string;
};

export class PublisherAPI extends WNextBase {
  async create(req: CreatePublisherReq): Promise<Publisher> {
    const data = await this.unwrap(await this.client.post('/publishers', req));
    if (data) {
      return new Publisher(data);
    }
    return data;
  }

  async checkNameExists(req: CheckNameReq): Promise<boolean> {
    const res = await this.unwrapRes(this.client.post('/publishers/check', req));
    if (res) {
      return res.data?.taken || false;
    }
    return false;
  }

  async update(req: UpdatePublisherReq): Promise<Publisher> {
    const data = await this.unwrap(await this.client.put(`/publishers/${req.id}`, req));
    if (data) {
      return new Publisher(data);
    }
    return data;
  }

  async createInviteToken(publisherID: string): Promise<string> {
    const data = await this.unwrap(
      await this.client.post(`/publishers/${publisherID}/invite_token`)
    );
    if (data) {
      return data.token;
    }
    return data;
  }

  async get(req: GetPublisherReq): Promise<Publisher> {
    let uri = `/publishers/${req.id}`;
    if (req.invite) {
      uri += `?invite=${req.invite}`;
    }
    const data = await this.unwrap(await this.client.get(uri));
    if (data) {
      return new Publisher(data);
    }
    return data;
  }

  async capturePayment(req: CapturePaymentReq): Promise<void> {
    await this.unwrap(await this.client.put(`/publishers/${req.publisher_id}/capture-payment`, {}));
  }

  async list(): Promise<Publisher[]> {
    const data = await this.unwrap(await this.client.get('/publishers'));
    if (data) {
      if ((data.code && data.code >= 300) || !Array.isArray(data)) {
        throw new Error('failed to list publishers');
      }
      return data.map((d) => new Publisher(d));
    }
    return data;
  }

  async listApps(req: ListAppsReq): Promise<PublisherApp[]> {
    const data = await this.unwrap(await this.client.get(`/publishers/${req.publisher_id}/apps`));
    if (data) {
      if (Array.isArray(data)) {
        return data.map((d) => new PublisherApp(d));
      }
      if (data.code >= 300) {
        throw new Error('failed to list apps');
      }
    }
    return data;
  }

  async createApp(req: CreatePublisherAppReq): Promise<PublisherApp> {
    const res = await this.unwrapRes(this.client.post(`/publishers/${req.publisher_id}/apps`, req));
    if (res) {
      return new PublisherApp(res.data.publisherApp);
    }
    throw new Error('failed to create app');
  }

  async getAppStatus(publisherID: string, appID: string): Promise<string> {
    const res = await this.unwrapRes(
      this.client.get(`/publishers/${publisherID}/apps/${appID}/status`)
    );
    if (res.data?.status) {
      return res.data.status;
    }
    throw new Error('failed to get app status');
  }

  async updateApp(req: CreatePublisherAppReq): Promise<PublisherApp> {
    const data = await this.unwrap(
      await this.client.put(`/publishers/${req.publisher_id}/apps/${req.id}`, req)
    );
    if (data) {
      return new PublisherApp(data);
    }
    return data;
  }

  async listAppVersions(req: ListPublisherAppVersionsReq): Promise<PublisherAppVersion[]> {
    const data = await this.unwrap(
      await this.client.get(`/publishers/${req.publisher_id}/apps/${req.app_id}/versions`)
    );
    if (data) {
      return data;
    }
    return data;
  }

  async createAppVersion(req: CreateAppVersionReq): Promise<PublisherAppVersion> {
    const data = await this.unwrap(
      await this.client.post(
        `/publishers/${req.publisherID}/apps/${req.publisher_app_id}/versions`,
        req
      )
    );
    return data;
  }

  async updateAppVersion(req: CreateAppVersionReq): Promise<PublisherAppVersion> {
    const data = await this.unwrap(
      await this.client.put(
        `/publishers/${req.publisherID}/apps/${req.publisher_app_id}/versions/${req.id}`,
        req
      )
    );
    return data;
  }
}

<script setup lang="ts">
import { Button } from '@clovyr/bed';
import type { PublisherAppFile } from '@clovyr/pollen';

import Modal from '@/components/elements/Modal.vue';
import TrashIcon from '@/components/icons/TrashIcon.vue';

import FilePill from './Develop/FilePill.vue';

defineProps<{
  files: PublisherAppFile[];
  dropping: boolean;
}>();

const deleteFileModalVisible = ref<boolean>(false);
const fileToDelete = ref<PublisherAppFile | null>(null);

const emit = defineEmits(['existingFileClicked']);

function onClickExistingFile(f: PublisherAppFile) {
  emit('existingFileClicked', f);
}

function requestDeleteFile(f: PublisherAppFile) {
  fileToDelete.value = f;
  deleteFileModalVisible.value = true;
}

function cancelDeleteFile() {
  deleteFileModalVisible.value = false;
  fileToDelete.value = null;
}
function confirmDeleteFile() {
  if (fileToDelete.value) {
    // Assuming 'deleted_at' is a property of PublisherAppFile
    fileToDelete.value.deleted_at = new Date();
    deleteFileModalVisible.value = false;
    fileToDelete.value = null;
  }
}
</script>

<template>
  <div class="list">
    <FilePill v-for="file in files" :key="file.id" @click="onClickExistingFile(file)"
      >{{ file.name }}<TrashIcon @click.stop.prevent="requestDeleteFile(file)"
    /></FilePill>
    <FilePill v-if="dropping" class="dropping">Drop New File</FilePill>
  </div>

  <Modal
    v-if="deleteFileModalVisible"
    theme="transparent"
    size="narrow"
    :has-icon="true"
    heading-text="Are you sure?"
    @close="cancelDeleteFile"
  >
    <template #body>
      Are you sure you want to delete <b class="file-name">{{ fileToDelete?.name }}</b
      >?
    </template>

    <template #controls-right>
      <div class="button-group">
        <Button label="No" :reversed="true" @click="cancelDeleteFile" />
        <Button label="Yes" @click="confirmDeleteFile" />
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  :deep(.trash-icon) {
    stroke: color(grey, primary);
    height: 1.5rem;
    &:hover {
      stroke: color(action, primary) !important;
    }
  }
}

.file-name {
  color: color(action, primary);
}

.button-group {
  margin: auto;
}
</style>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router'

type SrcsetArray = {
  url: string;
  size?: 'sm' | 'md' | 'lg';
};

type HeroBackgroundProps = {
  imageAlt?: string;
  backgroundImage?: string;
  srcset?: SrcsetArray[];
  gradient?: string | number;
  blur?: boolean;
  overlayImage?: string;
};

const props = withDefaults(defineProps<HeroBackgroundProps>(), {
  srcset: () => [],
});

const CSSClasses = computed(() => [props.blur && `background--blur`]);

const route = useRoute();
const routeName = route?.name?.toString().toLowerCase();

const imgClasses = computed(() => {
  return [routeName && `img-${routeName}`];
});

const overlaysrcsettyles = computed(() => {
  return { backgroundImage: `url(${props.overlayImage})` };
});

const gradientCSSClass = computed(() => {
  return `background-gradient--${props.gradient}`;
});

const queries = {
  sm: '768w',
  md: '992w',
  lg: '1200w',
};

const setSrcset = computed<string>(() => {
  let srcsetImages = '';

  if (props.srcset.length > 0) {
    srcsetImages = props.srcset
      .map((image) => `${image.url} ${image.size && queries[image.size]}`)
      .join(', ');
  }

  return srcsetImages;
});
</script>

<template>
  <div :class="CSSClasses" class="hero-background">
    <div v-if="overlayImage" :style="overlaysrcsettyles" class="background__overlay-image" />

    <img
      :alt="imageAlt"
      :src="backgroundImage"
      sizes="(max-width: 768px) 100vw, (max-width: 992px) 50vw, (max-width: 1200px) 33vw"
      :srcset="setSrcset"
      :class="imgClasses"
    />

    <div v-if="gradient" :class="gradientCSSClass" class="hero-background__gradient"></div>
  </div>
</template>

<style scoped lang="scss">
.hero-background {
  position: relative;
  width: 100%;
  z-index: -1;

  &--blur {
    filter: blur(space(3.4, fixed));
  }

  &__gradient,
  &__overlay-image {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
  }

  &__overlay-image {
    @include background-image;
  }

  &__overlay-image {
    bottom: -1px;
    z-index: 1;
  }

  img {
    aspect-ratio: 3/1;
    object-fit: cover;
    width: 100%;
  }
  .img-category {
    object-position: 0 100%;
  }
  .img-garden {
    object-position: center center;
  }
}
</style>

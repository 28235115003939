<script setup lang="ts">
import { storeToRefs } from 'pinia';

import ClovyrEditor from '@/components/forms/ClovyrEditor.vue';
import { useEditStore } from '@/stores/edit_store';

const { pubAppVersion } = storeToRefs(useEditStore());

const props = defineProps<{ fileID: string }>();

const file = computed(() => {
  /* eslint-disable no-underscore-dangle */
  // @ts-expect-error missing _id
  return pubAppVersion.value.files.find((f) => f.id === props.fileID || f._id === props.fileID)!;
  /* eslint-enable no-underscore-dangle */
});

const language = computed(() => {
  // simple override to render html w/ handlebars
  // for all other files, auto-detect via filename prop
  const { name } = file.value;
  if (!name) {
    return undefined;
  }
  if (name.endsWith('.html')) {
    return 'handlebars';
  }
  return undefined;
});
</script>

<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="textarea-block">
    <div class="textarea-block__label-holder">
      <label class="textarea-block__label">Name: </label>
      <input class="textinput-block__input" v-model="file.name" />
    </div>

    <div class="textarea-block__input-holder">
      <label class="textarea-block__label"> Content: </label>
      <ClovyrEditor
        class="textarea-block__input"
        :filename="file.name"
        :language="language"
        v-model="file.content"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.textarea-block {
  margin: 1rem 1rem 1rem 0;
}
.textarea-block__label-holder {
  display: flex;
  flex-direction: column;
}
.textarea-block__label {
  margin-bottom: space(0.5);
}
.textarea-block__input {
  margin-bottom: space(1);
}

.button--circle {
  margin-top: 1rem;
}
</style>

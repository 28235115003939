<template>
  <BaseSvg class="unpinned-icon" width="45" height="45" viewBox="0 0 45 45" fill="none">
    <circle cx="22" cy="22" r="20" stroke="#ADA6B7" stroke-width=".5" fill="none" />

    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.8975 14.6337C23.6046 14.3408 23.1298 14.3408 22.8369 14.6337C22.544 14.9266 22.544 15.4015 22.8369 15.6944L23.6316 16.4891L16.7945 20.2911L15.6944 19.191C15.4015 18.8981 14.9266 18.8981 14.6337 19.191C14.3408 19.4839 14.3408 19.9588 14.6337 20.2517L16.1218 21.7398C16.1299 21.7483 16.1382 21.7566 16.1467 21.7646L22.2369 27.8548C22.244 27.8623 22.2513 27.8696 22.2588 27.8767L23.7483 29.3663C24.0412 29.6592 24.5161 29.6592 24.809 29.3663C25.1019 29.0734 25.1019 28.5985 24.809 28.3056L23.709 27.2056L27.5109 20.3684L28.3056 21.1631C28.5985 21.456 29.0734 21.456 29.3663 21.1631C29.6592 20.8702 29.6592 20.3954 29.3663 20.1025L27.8821 18.6183C27.8736 18.6094 27.8649 18.6007 27.856 18.5922L25.4064 16.1426C25.3988 16.1347 25.3911 16.1269 25.3832 16.1194L23.8975 14.6337ZM26.4079 19.2655L24.7346 17.5921L17.8974 21.3941L22.606 26.1026L26.4079 19.2655Z"
      fill="#ADA6B7"
    />
    <path d="M27.5 19.5L24.5 16.5L17 21L23 27L27.5 19.5Z" fill="#ADA6B7" />
    <path
      d="M19.7212 24.7344L15.6196 28.8359"
      stroke="#ADA6B7"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'UnpinnedIcon',
  components: {
    BaseSvg,
  },
};
</script>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import { type ComputeProviderInfo } from '@clovyr/pollen';
import { getHostByID } from '@clovyr/pollen/fixtures/hosts';

import SettingOption from '@/components/blocks/SettingOption.vue';
import Modal from '@/components/elements/Modal.vue';
import { usePollenStore } from '@/stores/pollen_store';

const props = defineProps<{
  id: string;
}>();

const showForgetDialog = ref(false);
const pollenStore = usePollenStore();
const { computeProviderConfigs, deployments } = storeToRefs(pollenStore);

const config = computed(() => {
  return computeProviderConfigs.value.find((c) => c.id === props.id)!;
});
const numApps = computed(
  () => deployments.value.filter((d) => d.computeProviderConfigID === config.value.id).length,
);

const hostLogo = computed((): ComputeProviderInfo => {
  return getHostByID(config.value.providerID)!;
});

const handleForgetSavedHost = async () => {
  showForgetDialog.value = false;
  return pollenStore.pollen.deleteComputeProviderConfig(config.value.id);
};
</script>

<template>
  <SettingOption
    @forget="showForgetDialog = true"
    :highlighted="true"
    :buttons="[
      {
        label: 'Forget',
        eventName: 'forget',
      },
    ]"
  >
    <template #left>
      <div class="host-info">
        <AppLogo :logo="hostLogo" :size="'small'" pad />
        <h3>{{ config.name }}</h3>
        <p v-if="numApps > 0">{{ numApps }} apps running on this host.</p>
        <p v-else>No apps currently running on this host.</p>
      </div>
    </template>
  </SettingOption>

  <Modal
    v-if="showForgetDialog"
    theme="transparent"
    :size="'narrow'"
    :has-icon="true"
    @close="showForgetDialog = false"
  >
    <template #body>
      <div class="forget-modal">
        <h3>Forget this host?</h3>
        <p v-if="numApps > 0">
          Clovyr will forget the credentials of this host but your {{ numApps }} apps currently
          running on {{ config.name }} will continue to run on this host (you will need to
          re-authorize in order to destroy instances properly). Are you sure you want Clovyr to
          forget this host?
        </p>
        <p v-else>
          Clovyr will forget the credentials of this host. If you would like to launch apps on this
          host in the future, you will need to re-authorize. Continue?
        </p>
        <div class="button-group">
          <Button class="button" @click="showForgetDialog = false">Cancel</Button>
          <Button class="button" :reversed="true" @click="handleForgetSavedHost">Forget</Button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
:deep(.setting-option__inner) {
  align-items: center;
  padding: 1rem 0 !important;
}
:deep(.setting-option__buttons) {
  justify-content: flex-end !important;
}
:deep(.option-button) {
  flex-grow: 0 !important;
}
:deep(.setting-option__right) {
  width: fit-content;
}
.host-info {
  display: flex;
  align-items: center;
  width: 40rem;

  // :deep(.app-icon) {
  //   width: 5rem !important;
  // }

  h3 {
    margin-left: 1rem;
    width: 10rem;
  }
  p {
    margin-left: 2rem;
  }
}

.forget-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    padding: 0.5rem;
  }

  .button-group {
    margin-top: 1rem;
  }

  p {
    width: 50%;
  }
}
</style>

import { WNextBase } from '../../api/wnext-base';

export interface AcceptShareRequest {
  id: string;
  shareToken: string;
  encItemKey: string;
  encItemKeyNonce: string;
}

export interface AcceptSharedDataRequest {
  id: string;
  shareToken: string;
  kind: string;
  // should be an identifier of some sort (e.g, fqdn for instances)
  dataID: string;
}

export interface GetShareRequest {
  id: string;
  shareToken: string;
}

export interface GetShareResponse {
  id: string;
  encItemKey: string;
  encItemKeyNonce: string;
  encData: string;
  encDataNonce: string;
}

export interface ShareItemRequest {
  id: string;
  encShareSecret: string;
  encShareSecretNonce: string;
  shareToken: string;
  encItemKey: string;
  encItemKeyNonce: string;
}

export interface ShareItemResponse {
  encShareKey: string;
  encShareKeyNonce: string;
}

export interface EncryptedVaultItem {
  id: string;
  encData: string;
  encDataNonce: string;
  encItemKey: string;
  encItemKeyNonce: string;
  policy: string;
}

export interface CreateItemRequest {
  id: string;
  encData: string;
  encDataNonce: string;
  encItemKey: string;
  encItemKeyNonce: string;
}

export interface UpdateItemRequest {
  id: string;
  encData: string;
  encDataNonce: string;
}

export class VaultAPI extends WNextBase {
  async listItems(email: string): Promise<EncryptedVaultItem[]> {
    const res = await this.unwrapRes(this.client.post('/vault-items/list', { email }));
    return res.data.items;
  }

  async createItem(req: CreateItemRequest): Promise<void> {
    await this.unwrapRes(this.client.post('/vault-items', req));
  }

  async updateItem(req: UpdateItemRequest): Promise<void> {
    await this.unwrapRes(
      this.client.put(`/vault-items/${req.id}`, {
        encData: req.encData,
        encDataNonce: req.encDataNonce,
      })
    );
  }

  async deleteItem(id: string): Promise<void> {
    await this.unwrapRes(this.client.delete(`/vault-items/${id}`));
  }

  async shareItem(req: ShareItemRequest): Promise<ShareItemResponse | undefined> {
    const res = await this.unwrapRes(
      this.client.put(`/vault-items/${req.id}/share`, {
        encShareSecret: req.encShareSecret,
        encShareSecretNonce: req.encShareSecretNonce,
        shareToken: req.shareToken,
        encItemKey: req.encItemKey,
        encItemKeyNonce: req.encItemKeyNonce,
      })
    );
    return res.data?.existingLink ?? undefined;
  }

  async getItemShare(req: GetShareRequest): Promise<GetShareResponse> {
    const res = await this.unwrapRes(
      this.client.get(`/vault-items/${req.id}/share`, {
        headers: { 'X-Clovyr-Share-Token': req.shareToken },
      })
    );
    return res.data.share;
  }

  async acceptShare(req: AcceptShareRequest): Promise<void> {
    await this.unwrapRes(
      this.client.post(
        `/vault-items/${req.id}/accept-share`,
        {
          encItemKey: req.encItemKey,
          encItemKeyNonce: req.encItemKeyNonce,
        },
        { headers: { 'X-Clovyr-Share-Token': req.shareToken } }
      )
    );
  }

  async acceptSharedData(req: AcceptSharedDataRequest): Promise<void> {
    await this.unwrapRes(
      this.client.post(
        `/vault-items/${req.id}/accept-shared-data`,
        {
          kind: req.kind,
          dataID: req.dataID,
        },
        { headers: { 'X-Clovyr-Share-Token': req.shareToken } }
      )
    );
  }
}

/**
 * Wrap the given content in a bash heredoc string to write a file on the server.
 *
 * @param path
 * @param content
 * @returns
 */
export function writeFileCmd(path: string, content?: string): string {
  const c = (content || '').trim();
  return `
# write ${path}
(cat <<-'EOF'
${c}
EOF
) > "${path}"
`;
}

export const podName = `podname=$(kubectl get pods | grep Running | grep -v restic | grep -v helm | awk '{print $1}')`;

export function kexec(container: string, script: string): string {
  return `kubectl exec pod/\${podname} --container ${container} -- ${script}`;
}

export function kcp(container: string, src: string, dest: string): string {
  return `kubectl cp ${src} \${podname}:${dest} -c ${container}`;
}

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { ErrorObject, Schema } from 'ajv';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

import Button from '@clovyr/bed/components/controls/Button.vue';
import Copy from '@clovyr/bed/components/elements/Copy.vue';
import CopyIcon from '@clovyr/bed/components/icons/CopyIcon.vue';

import CheckboxBlock from '@/components/blocks/CheckboxBlock.vue';
import FormField from '@/components/blocks/FormField.vue';
import FormWrapper from '@/components/forms/FormWrapper.vue';
import { useLauncherStore } from '@/stores/launcher_store';
import { LauncherState } from '@/stores/types';

import CustomHostname from './CustomHostname.vue';

const setupEl = ref<HTMLDivElement | null>(null);
const launcherStore = useLauncherStore();
const { customDNS } = launcherStore;
const { manifest, status } = storeToRefs(launcherStore);

async function onToggle(val) {
  void customDNS.toggle(val);
  if (val) {
    void nextTick(() => {
      if (setupEl.value) {
        setupEl.value.scrollIntoView({ block: 'end' });
      }
    });
  }
}

const hostnameValidator = addFormats(new Ajv()).compile({
  type: 'string',
  format: 'hostname',
});

const schema = {
  customDomain: (val) => {
    hostnameValidator(val);
    if (hostnameValidator.errors) {
      return 'Please enter a valid hostname';
    }
    return true;
  },
};

const isEditable = computed(() => status.value !== LauncherState.Review);

const additionalHosts = computed(() => manifest.value.deployment.dns?.additional_hosts);

const hostnames = computed(() => {
  if (customDNS.state.status.name === 'enabled') {
    const domain = customDNS.state.status.inputDomain || '<app.example.com>';
    if (!additionalHosts.value) {
      return [domain];
    }
    return [
      domain,
      ...Object.keys(additionalHosts.value).map((hostname) => {
        return `${hostname}-${domain}`;
      }),
    ];
  }
  return [];
});

const title = computed(() => {
  if (!isEditable.value) {
    return 'Custom DNS';
  }
  return 'Set up a custom domain?';
});

const desc = computed(() => {
  if (customDNS.state.status.name === 'enabled' && status.value !== LauncherState.Review) {
    return `If you select yes, you will be able to specify a custom domain name where you and your community can view your ${manifest.value.metadata.name} content.

If you choose not to set up a custom domain, we will generate a domain for you.`;
  }
  return undefined;
});
</script>

<template>
  <div class="setup" ref="setupEl">
    <div class="setup-controls-heading launch-heading--secondary">Custom Domain Name:</div>
    <div class="section-highlighted background-gradient--base">
      <CheckboxBlock
        :isToggleSwitch="true"
        :value="customDNS.state.status.name === 'enabled'"
        type="checkbox"
        shape="rounded"
        :disabled="!isEditable || customDNS.state.status.name === 'loading'"
        :title="title"
        :description="desc"
        @update:modelValue="onToggle"
      />

      <div v-if="customDNS.state.status.name === 'enabled'" class="form-item">
        <FormWrapper :validation-schema="schema" :show-submit="false">
          <template #fields>
            <FormField
              v-model="customDNS.state.status.inputDomain"
              name="customDomain"
              label="Custom Domain Name"
              shape="square"
              placeholder="e.g. app.example.com"
              validateOnInput
              :disabled="!isEditable"
            />
          </template>
        </FormWrapper>

        <template v-if="isEditable">
          <!-- Single hostname -->
          <div>
            <template v-if="!additionalHosts">
              <p>Please create a CNAME DNS record pointing your custom domain name to:</p>
              <div class="dns">
                <div class="copy-item">
                  <Copy
                    class="copy"
                    title="copy"
                    :icon="CopyIcon"
                    :text="customDNS.state.claimedDomain!"
                    :label="customDNS.state.claimedDomain"
                  />
                </div>
              </div>
            </template>
            <!-- With extra hostnames -->
            <template v-else>
              <p>
                Please create a CNAME DNS record pointing your custom domain and each of the
                following additional hostnames to:
              </p>
              <div class="dns">
                <div class="copy-item">
                  <Copy
                    class="copy"
                    title="copy"
                    :icon="CopyIcon"
                    :text="customDNS.state.claimedDomain!"
                    :label="customDNS.state.claimedDomain"
                  />
                </div>
              </div>
            </template>

            <div class="gray">
              The following DNS records should be created:
              <ul>
                <CustomHostname
                  v-for="hostname in hostnames"
                  :key="hostname"
                  :hostname="hostname"
                  :multiple="hostnames.length > 1"
                />
              </ul>

              <br /><strong>Note:</strong> ensure that proxying is disabled if your DNS provider
              supports it.
            </div>
          </div>
        </template>

        <template v-if="isEditable">
          <div class="validate">
            <Button
              label="Validate DNS Configuration"
              @click="customDNS.validate(hostnames)"
              :disabled="!customDNS.state.status.inputDomain"
            />
          </div>
          <div v-if="customDNS.isValid" class="valid">Domain is valid</div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .error {
    margin: space(1) 0 0 space(1);
    color: color(bad, primary);
  }
  .validate {
    :deep(.button__label) {
      font-size: 12px;

      @include media-breakpoint-up(lg) {
        font-size: unset;
      }
    }
  }
  .valid {
    margin: space(1) 0 0 space(1);
    color: color(action, primary);
  }
}

.dns {
  background-color: rgba(34, 26, 51, 0.4);
  width: fit-content;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  border-radius: 8px;
}

.copy-item {
  display: flex;
  > * {
    color: color(action, secondary);
  }
  > *:hover {
    color: color(action, primary);
    text-decoration: underline;

    :deep(.icon-copy) {
      path {
        stroke: color(action, primary);
        stroke-width: 0.125;
      }
    }
    :deep(.green-check-icon) {
      path {
        stroke: color(action, primary) !important;
      }
    }
    :deep(.rex-x-icon) {
      path {
        stroke: color(bad, primary) !important;
      }
    }
  }
  .copy {
    background: transparent;
    padding-right: 0;
  }
  :deep(.copy-btn) {
    padding-left: 0;
    display: flex;
    align-items: flex-start;
  }
  :deep(.label) {
    width: 100%;
    word-break: break-all;
    text-transform: lowercase;
    text-align: left;
  }
}

ul {
  padding-left: 1rem;
  list-style: disc;
}

.setup {
  margin-top: 1.5rem;
  padding-bottom: 3rem;

  @include media-breakpoint-up(md) {
    padding-bottom: unset;
  }
}
.setup-controls {
  &__left:nth-child(2) {
    margin-top: space(5);
  }
}
.setup-controls-heading {
  @extend .text--h4;
  margin: 0 0 0.5rem 0.5rem;
}
.completed-item {
  display: flex;
  justify-content: space-between;
  padding: space(1.8) space(2.4);
  border: 1px solid rgb(132 115 152 / 30%);

  @include borders-rounded;
}

.green {
  color: color(action, primary);
  cursor: pointer;
}

.gray {
  color: color(grey, secondary);
}
</style>

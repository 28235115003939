<script setup lang="ts">
import { useField } from 'vee-validate';

import HiddenFieldReveal from '@/components/icons/HiddenFieldReveal.vue';

import FormField from './FormField.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'password',
  },
  shape: {
    type: String,
    default: 'oval', // square, rounded, cubed
  },
  reveal: {
    type: Boolean,
    default: false,
  },
  passwordValidate: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  subtext: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const data = reactive({
  revealPassword: false,
  fieldType: props.type,
});

const toggleReveal = () => {
  data.revealPassword = !data.revealPassword;
  data.fieldType = data.revealPassword ? 'text' : 'password';
};

const passwordStrength = ref('');
const passwordStrengthMessage = ref('');

const { name, label, reveal, subtext, passwordValidate } = toRefs(props);
const { errorMessage, value } = useField(name);
const changed = ref(false);

if (passwordValidate.value) {
  watch(value, (newValue) => {
    if (!changed.value && newValue) {
      changed.value = true;
    }
    const passwordLength = (newValue as []).length;

    if (passwordLength > 7 && passwordLength < 16) {
      passwordStrength.value = 'is-acceptable';
      passwordStrengthMessage.value = 'Password is acceptable.';
    } else if (passwordLength > 15) {
      passwordStrength.value = 'is-strong';
      passwordStrengthMessage.value = 'Password is strong!';
    } else {
      passwordStrength.value = 'is-weak';
      passwordStrengthMessage.value = 'Password must be at least 8 characters long.';
    }
  });
}

const showPasswordValidation = computed(() => passwordValidate.value && changed.value);
</script>

<template>
  <FormField
    :name="name"
    :label="label"
    :shape="shape"
    :type="data.fieldType"
    :reveal="reveal"
    :subtext="subtext"
    :password-validate="passwordValidate"
    :model-value="modelValue"
  >
    <template v-if="reveal" #reveal>
      <div @click="toggleReveal" class="password-reveal">
        <HiddenFieldReveal :open="data.revealPassword" />
      </div>
    </template>

    <template v-if="showPasswordValidation" #password-strength-indicator>
      <div class="password-strength-indicator" :class="passwordStrength">
        <div class="meter-bars">
          <span class="meter-bar" />
          <span class="meter-bar" />
          <span class="meter-bar" />
        </div>

        <div class="error-message">
          {{ passwordStrengthMessage }}
        </div>
      </div>
    </template>
    <template v-else-if="errorMessage" #password-strength-indicator>
      <div class="error-message">
        {{ errorMessage }}
      </div>
    </template>

    <template v-if="$slots.tooltip" #tooltip>
      <slot name="tooltip" />
    </template>
  </FormField>
</template>

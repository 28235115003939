<script setup lang="ts">
import { storeToRefs } from 'pinia';

import FormField from '@/components/blocks/FormField.vue';
import { useEditStore } from '@/stores/edit_store';

const { manifest } = storeToRefs(useEditStore());
</script>

<template>
  <div class="row">
    <div class="column">
      <FormField
        name="pubApp.description"
        label="Description:*"
        v-model="manifest.metadata.description"
      >
        <template #tooltip>
          A brief description of your app. This will be displayed in the app store.
        </template>
      </FormField>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <FormField
        name="logo_url"
        label="Logo URL (SVG, PNG, WEBP):"
        file-upload
        v-model="manifest.metadata.logoUrl"
      >
        <template #tooltip>
          The logo for your app. This will be displayed in the app store.
        </template>
      </FormField>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <FormField
        name="logo_background_color"
        label="Logo Background Color:"
        v-model="manifest.metadata.logoBackgroundColor"
      >
        <template #tooltip>
          Must be given as a HEX value. Will appear behind your logo wherever it’s present.
        </template>
      </FormField>
    </div>
  </div>
</template>

<style scoped lang="scss">
.row,
.flex-row {
  margin-bottom: space(2);
}

.publisher {
  background-color: #41355b;
  border-radius: 8px;
  padding: space(5);
  margin-top: space(5);
  margin-bottom: space(5);
  .title {
    padding-bottom: space(2);
  }

  .flex-row {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }

    .column {
      @extend .grid__col;
      width: col-width(11);
      padding: space(0.5);

      @include media-breakpoint-up(xxl) {
        width: col-width(3);
      }
    }
  }

  .flex-row-textarea {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xxl) {
      flex-direction: row;
    }

    .column {
      @extend .grid__col;
      padding: space(0.5);
    }
  }
}

:deep(.textarea-block__label-holder) {
  display: flex;

  svg {
    height: 15px;
    width: 15px;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
  }
}
</style>

/**
 * Simple capitalization function.
 *
 * Converts the first character of the given string to uppercase.
 *
 * @param str
 * @returns
 */
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// List of words (acronyms) which should not be humanized (first letter capital)
const noHuman = ['url', 'smtp', 'id', 'db', 'php', 'dns', 'www', 'pw'];

/**
 * Convert the given 'snake-case-string' to a humanized 'Snake Case String'
 *
 * @param str
 * @returns
 */
export function snakeCaseToHuman(str: string): string {
  return str
    .split(/[-_]/)
    .map((word) => {
      const w = word.toLowerCase();
      if (noHuman.includes(w)) {
        return w.toUpperCase();
      }
      return capitalize(w);
    })
    .join(' ');
}

/**
 * Simple conversion from a plain string to HTML markup.
 * TODO: replace w/ markdown
 *
 * @param str
 * @returns
 */
export function stringToHTML(str: string): string {
  return str.replaceAll('\n\n', '<br /><br />');
}

/**
 * Removes all whitespace characters from a string.
 */
export function removeWhitespace(str: string): string {
  return str.replace(/\s/g, '');
}

/**
 * Parse the given date string
 *
 * @param str
 * @returns
 */
export function parseDate(str: string | Date): Date {
  return typeof str === 'string' ? new Date(str) : str;
}

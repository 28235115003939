<script lang="ts" setup>
import { Button, TextInputBlock } from '@clovyr/bed';
import type { Deployment } from '@clovyr/pollen';
import type { Manifest } from '@clovyr/pollen/manifest';

import Modal from '@/components/elements/Modal.vue';
import { usePollenStore } from '@/stores/pollen_store';

interface LibraryAppDetail {
  app: Manifest;
  deployment: Deployment;
}
const props = defineProps<LibraryAppDetail>();

const emit = defineEmits(['close']);
const pollenStore = usePollenStore();
const deploymentName = ref(props.deployment.instanceName);

function handleSave() {
  const { deployment } = props;
  deployment.instanceName = deploymentName.value;
  pollenStore.pollen.putDeployment(deployment);
  emit('close');
}
</script>

<template>
  <Modal headingText="Rename App" @close="() => $emit('close')">
    <template v-slot:body>
      <div class="rename-app">
        <TextInputBlock
          label="App Name"
          class="rename-app__input"
          :value="deploymentName"
          @input="(e) => (deploymentName = e.target.value)"
        />
      </div>
    </template>

    <template v-slot:controls-right>
      <Button label="save" :reversed="true" @click="handleSave()" />
    </template>
  </Modal>
</template>

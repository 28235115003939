<script setup lang="ts">
import { PublisherAPI } from '@clovyr/pollen/publisher';

import { usePublisher } from '../../composables/usePublisher';

import PubHero from './PubHero.vue';
import ShareEmbed from './ShareEmbed.vue';

type AppProps = {
  publisherID: string;
  appID: string;
};
const props = defineProps<AppProps>();

const publisherID = computed(() => props.publisherID);
const appID = computed(() => props.appID);
const {
  publisher,
  pubApp: app,
  pubAppVersions,
  appLogoMetadata: appLogo,
  appBgMetadata: appBg,
} = usePublisher(publisherID, appID);

const router = useRouter();

const handleAppProfileClick = async () => {
  return router.push({
    name: 'AppDetail',
    params: { publisherID: props.publisherID, id: props.appID },
  });
};

const handlePortalClick = async () => {
  return router.push({
    name: 'Publisher',
    params: { publisherID: props.publisherID },
  });
};

const handleVersionsClick = async () => {
  return router.push({
    name: 'PublisherAppVersions',
    params: { publisherID: props.publisherID, appID: props.appID },
  });
};

const latestAppVersion = computed(() => {
  if (!pubAppVersions.value?.length) {
    return null;
  }
  if (pubAppVersions.value.length === 1) {
    return pubAppVersions.value[0];
  }
  const active = pubAppVersions.value.find((v) => v.status === 'published');
  if (active) {
    return active;
  }
  return pubAppVersions.value[0]; // just return top ranked one
});

const editLink = computed(() => {
  if (!(app.value && publisher.value && latestAppVersion.value)) {
    return '';
  }
  return `/publishers/${publisher.value.slug}/apps/${app.value.slug}/versions/${latestAppVersion.value.version}/edit?back=app`;
});

const handlePublishClick = async () => {
  if (pubAppVersions.value.length > 1) {
    console.warn('warning: develop app has more than one version');
  }
  const pubAppVersion = pubAppVersions.value[0];
  pubAppVersion.status = 'published';
  void new PublisherAPI().updateAppVersion({
    publisherID: publisherID.value,
    ...pubAppVersion,
  });
};

const handleRunClick = async () => {
  if (!app.value) {
    return;
  }
  const path = `/apps/${app.value.slug}/launch`;
  void router.push({ path });
};

const isCode = computed(() => app.value && app.value.app_type === 'clovyr_code');
</script>

<template>
  <PubHero :appLogo="appLogo" :appBackground="appBg" :app="app" @portal-click="handlePortalClick">
    <template v-slot:buttons>
      <Button @click="handleAppProfileClick">App Profile</Button>
      <Button v-if="!isCode" @click="handleVersionsClick">Versions</Button>
      <Button :link="editLink">Edit</Button>
      <Button v-if="isCode" @click="handlePublishClick">Publish</Button>
      <Button v-if="false" @click="handleRunClick">Run</Button>
    </template>
  </PubHero>

  <ShareEmbed :publisherID="publisherID" :appID="appID" />
</template>

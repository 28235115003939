<template>
  <BaseSvg class="trash-icon" viewBox="0 0 20 20" fill="none">
    <path
      d="M4.86621 7.6665L5.67354 15.7389C5.70803 16.0843 5.8696 16.4046 6.12692 16.6376C6.38424 16.8706 6.71894 16.9997 7.06608 16.9998H12.933C13.2801 16.9997 13.6148 16.8706 13.8722 16.6376C14.1295 16.4046 14.2911 16.0843 14.3255 15.7389L15.1329 7.6665"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M3 5.7998H17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M7.66699 5.8V3H12.3337V5.8"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'TrashIcon',
  components: {
    BaseSvg,
  },
};
</script>

import type { ComputeProviderConfig, Deployment, Instance } from '../types';
import type { Publisher } from '../types/Publisher';
import type { PublisherApp, PublisherAppVersion } from '../types/PublisherApp';
import type { RegistryKey } from '../types/RegistryKey';
import type { UserSubscription } from '../types/UserSubscription';

export const LATEST_PERSISTENT_STATE_VERSION = 2;

/**
 * StorageEvent with a subset of the fields offered by the browser API.
 *
 * This type only contains fields that we use which aids in testing.
 */
export type StorageEvent = Pick<globalThis.StorageEvent, 'newValue' | 'key'>;

/**
 * The main entry point for interacting with resources managed by pollen.
 */
export interface Pollen {
  /**
   * Initialize state from backend APIs.
   *
   * Reads remote state, if available, and any remote data that may be
   * available.. This must be called before any other methods are used.
   */
  init(): Promise<void>;

  /**
   * Adds or updates a deployment.
   */
  putDeployment(deployment: Deployment): Promise<void>;

  /**
   * Deletes a deployment by its ID.
   */
  deleteDeployment(id: string): Promise<void>;

  /**
   * Adds or updates an instance.
   */
  putInstance(instance: Instance): Promise<void>;

  /**
   * Deletes an instance by its FQDN.
   */
  deleteInstance(fqdn: string): Promise<void>;

  /**
   * Adds or updates a regisgtry key.
   *
   * @param key
   */
  putRegistryKey(key: RegistryKey): Promise<void>;

  /**
   * Delete a registry key
   * @param id
   */
  deleteRegistryKey(publisherAppID: string): Promise<void>;

  /**
   * Signs up a user and encrypts the existing data.
   */
  signUp(email: string, password: string): Promise<void>;

  /**
   * Signs up a user up with Nostr.
   */
  signUpWithNostr(email: string): Promise<void>;

  /**
   * Logs in to the app and decrypts storage.
   */
  logIn(email: string, password: string, secretKey?: string): Promise<void>;

  /**
   * Logs in to the app with Nostr.
   */
  logInWithNostr(): Promise<void>;

  /**
   * Logs out of the app and throws away the vault encryption key.
   *
   * (i.e., removes the password from memory but keeps the secret key)
   */
  logOut(): Promise<void>;

  /**
   * Unlock a locked vault (secret key present, but password needed to decrypt vault items)
   *
   * @param password
   */
  unlock(password: string): Promise<void>;

  /**
   * Adds the secret key after logging in without it.
   */
  addSecretKey(secretKey: string): Promise<void>;

  /**
   * Saves a compute provider config for re-use later.
   */
  saveComputeProviderConfig(config: ComputeProviderConfig): Promise<void>;

  /**
   * Deletes a saved compute provider config.
   */
  deleteComputeProviderConfig(id: string): Promise<void>;

  /**
   * Save the user's subscription
   *
   * TODO: not sure we need the entire type or just the ID, but the date
   * fields maybe useful
   *
   * @param sub
   */
  saveSubscription(sub: UserSubscription): Promise<void>;

  loadPublisherData(): Promise<void>;

  addNewAppVersion(pubAppID: string);

  /**
   * Handles state changes from other tabs.
   *
   * @params event - A storage event raised by the browser
   */
  handleStorageEvent(event: StorageEvent): Promise<void>;

  /*
   * Create a sharing link for a deployment.
   */
  shareDeployment(id: string): Promise<string>;

  /*
   * Accept a sharing link
   */
  acceptShare(id: string, shareSecret: string): Promise<void>;
}

export enum AuthType {
  Anonymous = 'anon',
  Password = 'password',
  Nostr = 'nostr',
}

export enum AuthState {
  /**
   * Either the user is Anonymous or they have an account but are logged out.
   */
  LoggedOut,

  /**
   * The user is logged in but has not provided the secret key.
   */
  LoggedInWithoutSecretKey,

  /**
   * We know the user's email & secret key but password is not present.
   */
  Locked,

  /**
   * Fully logged in and vault is unlocked.
   */
  LoggedIn,
}

/**
 * The application state held by pollen.
 */
export interface PollenState extends PersistentState {
  deployments: Record<DeploymentID, Deployment>;
  publisherState: PublisherState;
  secretKey?: string;
  authenticatedUser?: AuthenticatedUser;
  authState: AuthState;
  authType: AuthType;
}

type DeploymentID = string;
type InstanceID = string;
type PublisherAppID = string;

export interface PersistentState {
  version: number;
  computeProviderConfigs: Record<string, ComputeProviderConfig>;
  instances: Record<InstanceID, Instance>;
  registryKeys: Record<PublisherAppID, RegistryKey>;

  /**
   * User's subscription
   *
   * @deprecated no need to store this in pollen, just makes things messy. we should always pull state from API
   * TODO: remove this
   */
  subscription?: UserSubscription;
}

export type PublisherAppMap = { [pubID: string]: PublisherApp[] };
export type PublisherAppVersionMap = { [appID: string]: PublisherAppVersion[] };

export interface PublisherState {
  initialLoadComplete?: boolean;
  loadingPromise?: Promise<void>;
  publishers: Publisher[];
  publisherApps: PublisherAppMap;
  publisherAppVersions: PublisherAppVersionMap;
}

export interface AuthenticatedUser {
  email: string;
}

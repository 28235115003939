<script setup lang="ts">
import { Form } from 'vee-validate';

import Button from '@clovyr/bed/components/controls/Button.vue';

const props = defineProps({
  headingText: {
    type: String,
    default: '',
  },
  submitLabel: {
    type: String,
    default: 'submit',
  },
  theme: {
    type: String,
    default: 'base',
  },
  validationSchema: {
    type: Object,
    default: () => ({}),
  },
  initialValues: {
    type: Object,
    default: undefined,
  },
  keepValues: {
    type: Boolean,
    default: false,
  },
  reversed: {
    type: Boolean,
    default: true,
  },
  translucent: {
    type: Boolean,
    default: false,
  },
  showSubmit: {
    type: Boolean,
    default: true,
  },
});

const { headingText, submitLabel, theme } = toRefs(props);
</script>

<template>
  <Form
    class="form"
    :class="theme"
    :validation-schema="validationSchema"
    :initial-values="initialValues"
    :keep-values="keepValues"
  >
    <div v-if="!!headingText" class="form__heading">{{ headingText }}</div>

    <slot name="fields" />
    <slot name="disclaimer" />

    <div v-if="showSubmit" class="form__submit" ref="submitBtn">
      <Button :label="submitLabel" type="submit" :reversed="reversed" :translucent="translucent" />
    </div>
  </Form>
</template>

<template>
  <div class="page-divider">
    <svg width="1440" viewBox="0 0 1440 91" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M498 34.5C283 34.5 167 90.5 0 90.5V0H1441.5C1591.33 24.5 1801.1 73.5 1441.5 73.5C992 73.5 713 34.5 498 34.5Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'PageDivider',
};
</script>

<script setup lang="ts">
const props = defineProps({
  xs: {
    type: Number,
    default: 0,
  },
  sm: {
    type: Number,
    default: 0,
  },
  md: {
    type: Number,
    default: 0,
  },
  lg: {
    type: Number,
    default: 0,
  },
  xl: {
    type: Number,
    default: 0,
  },
  highlighted: {
    type: Boolean,
    default: false,
  },
});

const classNames = computed(() => {
  const queryProps = [
    props.xs > 0 && `grid__col-${props.xs}`,
    props.sm > 0 && `grid__col-sm-${props.sm}`,
    props.md > 0 && `grid__col-md-${props.md}`,
    props.lg > 0 && `grid__col-lg-${props.lg}`,
    props.xl > 0 && `grid__col-xl-${props.xl}`,
  ];

  if (queryProps.some(Boolean)) {
    return queryProps;
  }

  return 'grid__col';
});

const gridItemClassNames = computed(() => {
  return [props.highlighted && 'grid__col__highlighted'];
});
</script>

<template>
  <div :class="classNames">
    <div :class="gridItemClassNames" class="grid-item">
      <div v-if="highlighted" class="grid__background"></div>

      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
[class*='grid__col'] {
  .grid-item {
    position: relative;

    &.grid__col__highlighted {
      padding: space(4);
      z-index: 1;
    }

    .grid__background {
      @include borders-rounded;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-color: transparentize(color(negative), 0.7);
      opacity: 0.5;
    }
  }
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import FrogLoading from '@clovyr/bed/assets/images/frog_loading.webp';
import { AuthState } from '@clovyr/pollen/pollen';

import { usePayments } from '@/composables/usePayments';
import { useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';
import {
  totalUpdateSteps,
  type UpdateJob,
  UpdateStatus,
  useUpdateStore,
} from '@/stores/update_store';

import Module from '../../components/elements/Module.vue';
import { useAppStatus } from '../../composables/useAppStatus';
import { useAppVersion } from '../../composables/useAppVersion';
import { useLaunchStore } from '../../stores/launch_store';
import { ShowAuthForm } from '../Settings/types';

interface LibraryAppDetail {
  id: string;
}
const props = defineProps<LibraryAppDetail>();
const deploymentID = toRef(props, 'id');

const { eventBus } = useEventBus();

const {
  deployment,
  sub,
  subItem,
  isClovyrCode,
  isLaunchFailed,
  isLaunching,
  isRunning,
  isSleeping,
  isWaking,
  isUpdating,
  isRestoring,
  isTrial,
  isExpired,
  trialTimeRemaining,
  trialExpired,
  wnextAppPrice,
  isAppSubscribed,
} = useAppStatus(deploymentID);
const { refreshSubscription } = usePayments();
const { isUpdateAvailable } = useAppVersion(deploymentID);
const launchStore = useLaunchStore();
const launch = computed(() => launchStore.getLaunchForDeployment(props.id));
const { updateJobs } = storeToRefs(useUpdateStore());
const { authState, isUserAuthenticated } = storeToRefs(usePollenStore());

const updateJob = computed(() => updateJobs.value[deploymentID.value]);

// While launching, tick the progress bar by small increments up to a given max (based on overall progress)
let progressTimer: NodeJS.Timeout;
const currentProgress = ref(0);
let maxProgress = 25;
const launchProgressWidth = computed(() => {
  if (isUpdating.value && updateJob.value) {
    const p = (updateJob.value.status.valueOf() / totalUpdateSteps) * 100;
    return {
      width: `${p}%`,
    };
  }
  return { width: `${currentProgress.value}%` };
});

function tick() {
  if (progressTimer) {
    clearTimeout(progressTimer);
  }
  const factor = maxProgress === 75 ? 20000 : 5000;
  let interval = Math.floor(Math.random() * factor);
  if (interval < 100) {
    interval = 100;
  }
  progressTimer = setTimeout(() => {
    currentProgress.value += 1;
    if (currentProgress.value < maxProgress) {
      tick();
    }
  }, interval);
}

const statusMap = {
  [UpdateStatus.Started]: 'Starting update...',
  [UpdateStatus.Pulling]: 'Pulling images...',
  [UpdateStatus.Stopping]: 'Stopping services...',
  [UpdateStatus.Snapshotting]: 'Backing up...',
  [UpdateStatus.Restarting]: 'Restarting services...',
  [UpdateStatus.Complete]: 'Done!',
};

const statusToText = (job: UpdateJob) => {
  if (!job) {
    return '';
  }
  return statusMap[job.status];
};

const AppStatus = computed(() => {
  const closeWarning = `Please do not close your browser tab or window during this process, or ${deployment.value.appName} will not launch`;

  let hed: string;
  let title: string;
  let image: string;
  let subtext: string;

  // Failed
  if (isLaunchFailed.value) {
    hed = 'Error';
    title = `${deployment.value.appName} failed to launch`;
    subtext = 'Please try again or contact Clovyr support for help';
    clearTimeout(progressTimer);
    return { hed, title, subtext };
  }

  // Launching
  if (isLaunching.value || isWaking.value) {
    title = '';
    if (launch.value?.tasks) {
      const currentTask = launch.value.tasks.find((task) => task.status === 'working');
      title = currentTask?.description ?? '';

      // also update width
      const currentTaskIndex = launch.value.tasks.findIndex((task) => task.status === 'working');
      const totalTasks = launch.value.tasks.length;

      maxProgress = currentTaskIndex === 0 ? 10 : ((currentTaskIndex + 1) / totalTasks) * 100;
      tick();
    }
    hed = `Launching ${deployment.value.appName}...`;
    image = FrogLoading;
    subtext = closeWarning;
    return { hed, image, subtext, title };
  }

  // Updating
  if (isUpdating.value) {
    hed = 'Updating...';
    image = FrogLoading;
    subtext = closeWarning;
    title = statusToText(updateJob.value);
    return { hed, image, subtext, title };
  }

  // Running, but free trial expired. In the grace period before app is paused.
  if (isRunning.value && trialExpired.value && !isAppSubscribed.value) {
    hed = 'Success!';
    title = `${deployment.value.appName} is up and running!`;
    if (isClovyrCode.value) {
      subtext = '';
    } else {
      subtext = `Your free trial has expired and the app will soon be paused. Love the app? Sign up and keep the fun going for just $${wnextAppPrice.value}/mo`;
    }
    return { hed, title, subtext };
  }

  // Expired
  if (isExpired.value) {
    hed = 'Paused';
    title = `${deployment.value.appName} is paused, but all data remains intact on this device.`;
    subtext = `Your hosting trial has expired. ${deployment.value.appName} and it's data will be deleted in 30 days, unless you upgrade your hosting now.`;
    return { hed, title, subtext };
  }

  // Running, app update available
  if (isUpdateAvailable.value) {
    hed = 'Success!';
    title = `${deployment.value.appName} is up and running!`;
    subtext = `There is an update available for ${deployment.value.appName}. Do you want to update?`;
    return { hed, title, subtext };
  }
  // Restoring backup
  if (isRestoring.value) {
    hed = 'Restoring from DATE backup...';
    subtext = closeWarning;
    return { hed, subtext };
  }
  // Sleeping
  if (isSleeping.value) {
    hed = 'Sleeping';
    title = `${deployment.value.appName} is paused, but all data remains intact on this device. Click "Open" to run the app.`;
    return { hed, title };
  }
  // Waking
  if (isWaking.value) {
    hed = 'Waking up...';
    image = FrogLoading;
    subtext = `${deployment.value.appName} is getting ready to use.`;
    return { hed, subtext, image };
  }

  // checking user authed status here to determine status
  // user authed
  if (isUserAuthenticated.value && authState.value !== AuthState.LoggedOut) {
    // Running, free trial active
    if (isRunning.value && isTrial.value && !trialExpired.value) {
      hed = 'Success!';
      title = `${deployment.value.appName} is up and running!`;
      if (isClovyrCode.value) {
        subtext = '';
      } else {
        subtext = `You have ${trialTimeRemaining.value} remaining on your free trial. Loving the app? Upgrade and keep the fun going for just $${wnextAppPrice.value}/mo`;
      }
      return { hed, title, subtext };
    }

    // Running
    if (isRunning.value && !isTrial.value && isAppSubscribed.value) {
      hed = 'Success!';
      title = `${deployment.value.appName} is up to date and running!`;
      return { hed, title };
    }
  }
  // User not authed
  // Doing this here because isTrial is determined by subItems, which a non-authed user will not have
  if (!isUserAuthenticated.value && authState.value === AuthState.LoggedOut) {
    // Running, free trial active,
    if (isRunning.value && !trialExpired.value) {
      hed = 'Success!';
      title = `${deployment.value.appName} is up and running!`;
      if (isClovyrCode.value) {
        subtext = '';
      } else {
        subtext = `You have ${trialTimeRemaining.value} remaining on your free trial. Loving the app? Sign up and keep the fun going for just $${wnextAppPrice.value}/mo`;
      }
      return { hed, title, subtext };
    }
  }

  // TODO: when app is shared with the user, we will hit this point for a running app. what to do then?
  // just show 'running'? the state above says 'is up to date and running!'

  return {};
});

const classNames = computed(() => {
  return [
    isLaunching.value && 'launching',
    isRunning.value && !isTrial.value && 'success',
    isSleeping.value && 'sleeping',
    isRunning.value &&
      ((isUserAuthenticated.value && isTrial.value) || !isUserAuthenticated.value) &&
      !isUpdateAvailable.value &&
      'success-trial',
    isUpdateAvailable.value && 'success-update',
    isUpdating.value && 'updating',
    isRestoring.value && 'restoring',
    isExpired.value && 'suspended',
  ];
});

const onClickUpdate = async (): Promise<void> => {
  eventBus.emit('app:update', { appID: deploymentID.value });
};

const onClickShowPaymentModal = async (): Promise<void> => {
  // if user not authed, show log in modal
  // TODO: Chetan wants to revisit this logic
  
  // if (!isUserAuthenticated.value && authState.value === AuthState.LoggedOut) {
  //   eventBus.emit('modal:login:show', {
  //     form: ShowAuthForm.Login,
  //     opts: {
  //       hed: 'Please log in to upgrade hosting.',
  //       modal: true,
  //     },
  //   });
  // }

  if (!sub.value || !subItem.value) {
    await refreshSubscription();
    if (!sub.value) {
      throw new Error('subscription not found');
    }
    if (!subItem.value) {
      throw new Error('subscription item not found');
    }
  }
  eventBus.emit('modal:payment:show', {
    id: deploymentID.value,
    name: deployment.value.appName,
    sub: sub.value,
    subItem: subItem.value,
  });
};
</script>

<template>
  <div class="container">
    <!--App Status-->
    <div v-show="false" class="isTrial">{{ isTrial }}</div>
    <div class="app-instance__twoup-modules row">
      <div class="app-status grid__col-md-12">
        <Module
          class="module--app-status"
          :bigHead="true"
          :hed="AppStatus?.hed"
          :img="AppStatus?.image"
          :class="classNames"
        >
          <template #option>
            <div class="launching-instance" v-if="isLaunching || isWaking || isUpdating">
              <template v-if="isLaunching || isUpdating">
                <div class="launch-progress-bg"></div>
                <div class="launch-progress" :style="launchProgressWidth"></div>
              </template>
              <div class="subtext">{{ AppStatus?.subtext }}</div>
            </div>
            <div class="launching-instance" v-else-if="isLaunchFailed">
              <!-- <div class="subtext">Launch Failed</div> -->
            </div>
          </template>
          <template #heading>
            <div class="module__status title">
              {{ AppStatus?.title }}
            </div>
            <span
              class="module__status"
              :class="`module__status--${deployment.state} status-color`"
            ></span>
          </template>
          <template #default v-if="AppStatus?.subtext && !isLaunching && !isWaking && !isUpdating">
            <div class="row" v-if="isUpdateAvailable">
              <div>
                {{ AppStatus?.subtext }}
              </div>
              <div>
                <Button
                  :reversed="true"
                  label="update"
                  v-if="isUpdateAvailable"
                  @click="onClickUpdate"
                />
              </div>
            </div>
            <div class="row" v-else>
              <div class="grid__col-md-7 subtext">
                {{ AppStatus?.subtext }}
              </div>
              <div class="app-status__button grid__col-md-5">
                <Button
                  v-if="
                    !isClovyrCode &&
                    ((isRunning && isTrial) ||
                      isExpired ||
                      (!isUserAuthenticated && isRunning && !trialExpired))
                  "
                  label="upgrade hosting"
                  class="upgrade"
                  :reversed="true"
                  @click="onClickShowPaymentModal"
                />
              </div>
            </div>
          </template>
        </Module>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  padding-top: space(3);
}

.module--app-status {
  :deep(.module__head__grid) {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
  }
  :deep(.module__head__left) {
    display: flex;
  }
}
:deep(.module__head__right) {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;

  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
  }
}

:deep(.image-media) {
  margin-right: space(3);
}

.app-status {
  .title {
    color: #d9d9d9;
    padding-right: 0;
  }

  .status-color {
    transform: translateY(-8px);
  }

  &__button {
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-down(lg) {
      margin-top: 1rem;
    }
  }

  :deep(.module__body) {
    .row {
      @include media-breakpoint-only(md) {
        display: flex;
        flex-direction: column;

        .subtext {
          width: col-width(12);
        }
        .app-status__button {
          width: col-width(12);
        }
      }
    }
  }
}

.launching,
.updating {
  :deep(.image-media) {
    min-width: 75px;
    min-height: 75px;
  }

  :deep(.module__head__right) {
    position: absolute;
    padding: 0.5rem 2.5rem 0 0;
    justify-content: flex-end;
    transform: translateY(-1.5rem);
    min-height: 2rem;

    @include media-breakpoint-up(sm) {
      padding: 0 2.5rem 1rem 0;
      transform: unset;
    }
  }

  :deep(.module__head__left) {
    padding-top: 2rem;

    @include media-breakpoint-up(sm) {
      padding-top: unset;
    }
  }

  :deep(.module__head__sub) {
    width: 100%;
  }
}

.launching,
.updating,
.restoring {
  .subtext {
    color: #ada6b7;
  }
}

.launching-instance {
  position: relative;

  div.launch-progress-bg {
    height: 10px;
    width: 100%;
    border-radius: 8px;
    background-color: #221a33;
    position: absolute;
    top: 0;
  }

  .launch-progress {
    background-color: #57bb7c;
    height: 10px;
    border-radius: 8px;
    // margin-top: space(1);
    position: absolute;
    z-index: 5;
  }

  .subtext {
    margin-top: space(1);
    color: #d9d9d9;
    padding-top: 20px;
  }
}

.success-trial {
  :deep(.module__body) {
    color: #ada6b7;
    background-color: #221a33;
  }

  .row {
    padding-top: space(1.5);
  }

  .grid__col-md-9 {
    padding-top: space(1);
  }
}

.success-update:not(.updating) {
  :deep(.module__body) {
    color: #ada6b7;
    background-color: #a861fc33;
  }

  .row {
    justify-content: space-between;
    div {
      width: auto;
    }
  }
}

.suspended {
  :deep(.module__body) {
    color: #ada6b7;
    background-color: #e76b6d33;
  }

  .button {
    background-color: #fcb173;
  }

  .row {
    padding-top: space(1.5);
  }

  .grid__col-md-9 {
    padding-top: space(1);
    display: flex;
  }

  .space {
    margin-left: space(0.5);
  }
}

.white {
  color: #ffffff;
}
</style>

<script lang="ts" setup>
import CLink from '@clovyr/bed/components/elements/CLink.vue';

const props = defineProps<{ label: string; slug: string }>();

const linkToPath = computed(() => {
  if (props.slug) {
    return `/tag/${props.slug}`;
  }
  return '/';
});
</script>

<template>
  <c-link :to="linkToPath" :label="label" class="tag" />
</template>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  padding: space(1) space(2) space(1);
  margin: space(0.5) space(0.5);
  color: color(grey, tertiary);
  cursor: pointer;
  background-color: color(background);

  @include borders-convex;
  @include font-size(12);

  &:hover {
    background-color: color(grey);
  }
}
</style>

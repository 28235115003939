import { storeToRefs } from 'pinia';
import { type Ref } from 'vue';
import yaml from 'yaml';

import { useImageAsset } from '@clovyr/bed/composables/useImageAsset';
import type { Manifest } from '@clovyr/pollen/manifest';

import { useEditStore } from '@/stores/edit_store';

import { usePollenStore } from '../stores/pollen_store';

export function usePublisher(
  publisherID: Ref<string>,
  appID?: Ref<string | undefined>,
  versionID?: Ref<string | undefined>,
) {
  const pollenStore = usePollenStore();
  const { publishers, publisherApps, publisherAppVersions } = storeToRefs(pollenStore);

  const editStore = useEditStore();
  const { appBackground, appLogo } = storeToRefs(editStore);

  const publisher = computed(() => {
    // match on slug or id
    return publishers.value?.find(
      (p) => p.slug === publisherID.value || p.id === publisherID.value,
    );
  });

  const pubApps = computed(() => {
    if (publisher.value && publisherApps.value) {
      return publisherApps.value[publisher.value.id];
    }
    return [];
  });

  const pubApp = computed(() => {
    if (!appID || !appID.value) {
      return undefined;
    }
    return pubApps.value?.find((a) => a.id === appID.value || a.slug === appID.value);
  });

  const pubAppVersion = computed(() => {
    if (!pubApp.value || !publisherAppVersions.value?.[pubApp.value.id]?.length) {
      return undefined;
    }
    if (versionID?.value) {
      // specific version
      return publisherAppVersions.value[pubApp.value.id].find(
        (v) => v.id === versionID.value || v.version === versionID.value,
      );
    }
    // return most recent version
    return publisherAppVersions.value[pubApp.value.id][0];
  });

  const pubAppVersions = computed(() => {
    if (pubApp.value) {
      const versions = publisherAppVersions.value[pubApp.value.id];
      if (versions) {
        return versions.sort((a, b) => a.rank.localeCompare(b.rank));
      }
    }
    return [];
  });

  const manifest = computed(() => {
    if (pubAppVersion.value?.manifest) {
      return yaml.parse(pubAppVersion.value?.manifest) as Manifest;
    }
    return {} as Manifest;
  });

  const appLogoMetadata = computed(() => {
    const { metadata } = manifest.value;
    if (!metadata) {
      return appLogo.value;
    }
    return {
      logoUrl: metadata.logoUrl,
      logoBackgroundColor: metadata.logoBackgroundColor || 'transparent',
    };
  });

  const imageAsset = useImageAsset();

  const appBgMetadata = computed(() => {
    const { metadata } = manifest.value;
    if (!metadata) {
      return appBackground.value;
    }
    const bg = `url("${imageAsset.getURL(metadata.backgroundImageUrl!)}")`;
    return {
      '--bg-url': bg,
      '--z-index': -2,
    };
  });

  return {
    publisher,
    publisherApps: pubApps,
    pubApp,
    pubAppVersions,
    pubAppVersion,
    appLogoMetadata,
    appBgMetadata,
    manifest,
  };
}

<template>
  <p class="c5"><span class="c2 c9 c10 c8"></span></p>
  <p class="c12"><span class="c2 c9 c10 c8">Clovyr for Publishers</span></p>
  <p class="c12"><span class="c2 c9 c10 c8">Services Agreement</span></p>
  <p class="c5"><span class="c2 c9 c10 c8"></span></p>
  <p class="c24" id="h.gjdgxs">
    <span class="c0">This Services Agreement (together with all Orders, the &ldquo;</span
    ><span class="c9 c10 c8">Agreement</span
    ><span class="c0">&rdquo;) is between Clovyr Co. (&ldquo;</span
    ><span class="c9 c10 c8">Clovyr</span
    ><span class="c0"
      >&rdquo;) and the customer identified in the Order (which may be completed via account
      registration, service sign up, online form submission, or other express election to receive
      the Services) (&ldquo;</span
    ><span class="c9 c10 c8">Publisher</span
    ><span class="c0"
      >&rdquo;). By placing an Order for the Services described in this Agreement, Publisher accepts
      and agrees to be bound by the terms of this Agreement and Clovyr&rsquo;s Terms of Service </span
    ><span class="c15 c10">(</span
    ><span class="c3 c15 c28 c10"
      ><a
        class="c7"
        href="https://www.google.com/url?q=https://clovyr.io/terms-of-service&amp;sa=D&amp;source=editors&amp;ust=1691783095555906&amp;usg=AOvVaw2f7MhhWEvDpHjala9_m2bh"
        >https://clovyr.io/terms-of-service</a
      ></span
    ><span class="c15 c10">), which are incorporated herein by reference</span
    ><span class="c2 c0"
      >. In the event of any conflict between the terms of this Agreement and Clovyr&rsquo;s Terms
      of Service, the terms of this Agreement shall control.
    </span>
  </p>
  <p class="c24 c21"><span class="c2 c0"></span></p>
  <p class="c23">
    <span class="c10 c8">BY CLICKING</span><span class="c10 c15">&nbsp;&ldquo;</span
    ><span class="c10 c8">SUBMIT &amp; ENTER PAYMENT DETAILS</span
    ><sup><a href="#cmnt1" id="cmnt_ref1">[a]</a></sup
    ><sup><a href="#cmnt2" id="cmnt_ref2">[b]</a></sup
    ><span class="c15 c10">,&rdquo; </span
    ><span class="c10 c8"
      >OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICES</span
    ><span class="c15 c10"
      >, PUBLISHER AGREES THAT PUBLISHER HAS READ AND UNDERSTOOD, AND, AS A CONDITION TO
      PUBLISHER&rsquo;S USE OF THE SERVICES, PUBLISHER AGREES TO BE BOUND BY, THE TERMS OF THIS
      AGREEMENT. </span
    ><span class="c15 c10">IF PUBLISHER IS </span
    ><span class="c0"
      >NOT ELIGIBLE, OR DOES NOT AGREE TO THIS AGREEMENT, THEN PUBLISHER DOES NOT HAVE
      CLOVYR&rsquo;S PERMISSION TO USE THE SERVICES.</span
    ><span class="c15 c10"
      >&nbsp;PUBLISHER&rsquo;S USE OF THE SERVICE, AND CLOVYR&rsquo;S PROVISION OF THE SERVICE TO
      PUBLISHER, CONSTITUTES AN AGREEMENT BY CLOVYR AND PUBLISHER TO BE BOUND BY THIS
      AGREEMENT.</span
    ><span class="c2 c0">&nbsp;</span>
  </p>
  <p class="c24 c21"><span class="c2 c9 c10 c8"></span></p>
  <ol class="c1 lst-kix_list_1-0 start" start="1">
    <li class="c4 c13">
      <h1 id="h.30j0zll" style="display: inline"><span class="c10 c8">Services. </span></h1>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-1 start" start="1">
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Permitted Use</span
        ><span class="c2 c0"
          >. During a Subscription Term, subject to Publisher&rsquo;s compliance with the terms of
          this Agreement, Publisher may access and use the Services only for its internal business
          purposes in accordance with the Documentation, this Agreement, and any additional
          limitations (such as Usage Limits) set forth by Clovyr in writing (which may be via email,
          the Services, or Clovyr&rsquo;s website).</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.1fob9te" style="display: inline">
        <span class="c3">Users</span
        ><span>. Only Users, using the mechanisms designated by Clovyr (&ldquo;</span
        ><span class="c8">Log-in Credentials</span
        ><span class="c2 c0"
          >&rdquo;), may access and use the Services. Each User must keep its Log-in Credentials
          confidential and not share them with anyone else. Publisher is responsible for its
          Users&rsquo; compliance with this Agreement and all actions taken through their Log-in
          Credentials (excluding misuse of the Log-in Credentials caused by Clovyr&rsquo;s breach of
          this Agreement). Publisher will promptly notify Clovyr if it becomes aware of any
          compromise of any Log-in Credentials. Clovyr may Process Log-in Credentials in connection
          with Clovyr&rsquo;s provision of the Services or for Clovyr&rsquo;s internal business
          purposes.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.3znysh7" style="display: inline">
        <span class="c3">Restrictions</span><span>.</span><span class="c8">&nbsp;</span
        ><span class="c2 c0"
          >Publisher will not (and will not permit anyone else to) do any of the following: (a)
          provide access to, distribute, sell, or sublicense a Service to a third party (other than
          Users); (b) use a Service on behalf of, or to provide any product or service to, third
          parties; (c) use a Service to develop a similar or competing product or service;
          (d)&nbsp;reverse engineer, decompile, disassemble, or seek to access the source code or
          non-public APIs to a Service, except to the extent expressly permitted by Law (and then
          only with prior notice to Clovyr); (e) modify or create derivative works of a Service or
          copy any element of a Service; (f) remove or obscure any proprietary notices in a Service;
          (g) publish benchmarks or performance information about a Service; (h) interfere with the
          operation of a Service, circumvent any access restrictions, or conduct any security or
          vulnerability test of a Service; (i) transmit any viruses or other harmful materials to a
          Service; (j) take any action that risks harm to others or to the security, availability,
          or integrity of a Service; or (k) access or use a Service in a manner that violates any
          Law. Additionally, Developer must not use a Service with Prohibited Data or for High Risk
          Activities. Developer acknowledges that the Services are not intended to meet any legal
          obligations for these and that notwithstanding anything else in this Agreement, Clovyr has
          no liability for Prohibited Data or use of a Service for High Risk Activities.
        </span>
      </h2>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-0" start="2">
    <li class="c4 c13">
      <h1 id="h.2et92p0" style="display: inline">
        <span class="c8">Support</span
        ><span class="c2 c0"
          >. During a Subscription Term, Clovyr will use commercially reasonable efforts to provide
          the applicable Services in accordance with the applicable Support Policy (for the Service
          tier purchased by Publisher).
        </span>
      </h1>
    </li>
    <li class="c4 c13">
      <h1 id="h.tyjcwt" style="display: inline">
        <span class="c8">Upgrades</span
        ><span class="c2 c0"
          >. Clovyr will make available Updates to Services as Clovyr makes them available to its
          customers of the applicable Services generally. Publisher&rsquo;s purchase of and access
          to Services is not contingent on the delivery of any future functionality or features or
          dependent on any oral or written public or private comments made by Clovyr regarding
          future functionality or features of the Services. From time to time, Clovyr, in its sole
          discretion, may make available Upgrades under additional or different terms. Nothing in
          this Agreement obligates Clovyr to make Upgrades available to Publisher as part of the
          Services or otherwise.</span
        >
      </h1>
    </li>
    <li class="c4 c13">
      <h1 style="display: inline"><span class="c8">Data</span><span class="c2 c0">. </span></h1>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-1 start" start="1">
    <li class="c4">
      <h2 id="h.3dy6vkm" style="display: inline">
        <span class="c3">Use of Publisher Data</span
        ><span class="c2 c0"
          >. Publisher grants Clovyr the non-exclusive, worldwide, sublicensable right to use, copy,
          store, disclose, transmit, transfer, publicly display, modify, and create derivative works
          from Publisher Data only as necessary to: (a) provide any Services and Support;
          (b)&nbsp;derive or generate Usage Data; (c)&nbsp;create and compile Aggregated Data; and
          (d) as otherwise required by Laws or as agreed to in writing between the parties.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.1t3h5sf" style="display: inline">
        <span class="c3">Usage Data; Aggregated Data</span
        ><span class="c2 c0"
          >. Clovyr may Process Usage Data and Aggregated Data for internal business purposes, such
          as to: (a) track use of Services for billing purposes; (b)&nbsp;provide support for
          Services; (c) monitor the performance and stability of the Services; (d) prevent or
          address technical issues with the Services; (e) to improve Services, its other products
          and services, and to develop new products and services; and (f) for all other lawful
          business practices, such as analytics, benchmarking, and reports. Publisher will not
          interfere with the collection of Usage Data.</span
        >
      </h2>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-0" start="5">
    <li class="c4">
      <h1 id="h.4d34og8" style="display: inline">
        <span class="c8">Publisher</span><span>&nbsp;</span><span class="c8">Obligations</span
        ><span
          >. Publisher is responsible for its Publisher Data, including its content and accuracy,
          and will comply with all applicable Laws and Policies when using the Services including
          without limitation the Legal and Acceptable Use requirements under Clovyr&rsquo;s Terms of
          Service (</span
        ><span class="c3 c28"
          ><a
            class="c7"
            href="https://www.google.com/url?q=https://clovyr.io/terms-of-service&amp;sa=D&amp;source=editors&amp;ust=1691783095558776&amp;usg=AOvVaw0jhiFIQKcMpSTuX58CO_oj"
            >https://clovyr.io/terms-of-service</a
          ></span
        ><span class="c2 c0"
          >). Publisher represents and warrants that it has made all disclosures, provided all
          notices, and has obtained all rights, consents, and permissions necessary for Clovyr to
          Process Publisher Data as set forth in this Agreement without violating or infringing
          Laws, third-party rights, or terms or policies that apply to the Publisher Data.</span
        >
      </h1>
    </li>
    <li class="c4 c13">
      <h1 id="h.2s8eyo1" style="display: inline">
        <span class="c8">Suspension of Service</span
        ><span class="c2 c0"
          >. Clovyr may immediately suspend Publisher&rsquo;s access to any or all of the Services
          if: (a) Publisher breaches Section 1.3 (Restrictions) or Section 5 (Publisher
          Obligations); (b) Publisher&rsquo;s account is thirty (30) days or more overdue; (c)
          changes to Laws or new Laws require that Clovyr suspend a Service or otherwise may impose
          additional liability on the part of Clovyr; or (d) Publisher&rsquo;s actions risk harm to
          any of Clovyr&rsquo;s other users or the security, availability, or integrity of a
          Service. Where practicable, Clovyr will use reasonable efforts to provide Publisher with
          prior notice of the suspension (email sufficing). If the issue that led to the suspension
          is resolved, Clovyr will restore Publisher&rsquo;s access to the Service(s) in accordance
          with this Agreement.
        </span>
      </h1>
    </li>
    <li class="c4 c13">
      <h1 style="display: inline">
        <span class="c8">Publisher Systems</span
        ><span class="c2 c0">. Publisher will provide and maintain any Publisher Systems. </span>
      </h1>
    </li>
    <li class="c4 c13">
      <h1 style="display: inline">
        <span class="c8">Third-Party Platforms</span><span>.</span><span class="c8">&nbsp;</span
        ><span class="c2 c0"
          >Use of Third-Party Platforms, to the extent applicable, is subject to Publisher&rsquo;s
          agreement with the relevant provider and not this Agreement. Clovyr does not control and
          has no liability for Third-Party Platforms, including their security, functionality,
          operation, availability, or interoperability with the Services or how the Third-Party
          Platforms or their providers use Publisher Data. By enabling a Third-Party Platform to
          interact with the Services, Publisher authorizes Clovyr to access and exchange Publisher
          Data with such Third-Party Platform on Publisher&rsquo;s behalf.
        </span>
      </h1>
    </li>
    <li class="c4 c13">
      <h1 style="display: inline"><span class="c8">Commercial Terms.</span></h1>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-1 start" start="1">
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Subscription Term</span><span>. Unless</span
        ><span class="c9"
          >&nbsp;otherwise set forth by Clovyr to Publisher in writing (which may be via an Order,
          email, Clovyr&rsquo;s pricing or product pages located at</span
        ><span>&nbsp;<a href="https://clovyr.io/pricing">https://clovyr.io/pricing</a></span
        ><span class="c9">, or through the Services)</span
        ><span class="c2 c0"
          >, each Subscription Term will automatically renew for successive terms of equal length to
          the immediately preceding Subscription Term unless either party gives the other party
          notice of non-renewal before the current Subscription Term ends.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.17dp8vu" style="display: inline">
        <span class="c3">Fees and Taxes</span
        ><span
          >. Fees for the Services are described in the applicable Order or on Clovyr&rsquo;s
          pricing page </span
        ><span>located at <a href="https://clovyr.io/pricing">https://clovyr.io/pricing</a>, </span
        ><span
          >or otherwise communicated by Clovyr to Publisher in writing (which may be via email or
          the Services) (&ldquo;</span
        ><span class="c8">Fees</span
        ><span
          >&rdquo;). Unless otherwise set forth by Clovyr in writing (which may be via the
          Services), all Fees will be paid in US dollars and invoiced upfront in advance; except
          that overage Fees are invoiced in arrears, and all Fees are due within 30 days of the
          invoice date. Late payments are subject to a service charge of 1.5% per month or the
          maximum amount allowed by Law, whichever is less. In the event that Publisher fails to
          make payment within sixty (60) days of the due date, Clovyr may, in its sole discretion,
          suspend Publisher&rsquo;s access to the Service until all outstanding payments, including
          interest, are received.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.3rdcrjn" style="display: inline">
        <span class="c3">Non-Refundable</span
        ><span>. &nbsp;All Fees are non-refundable except as may be set out in Section </span
        ><span class="c8">Error! Reference source not found.</span
        ><span>&nbsp;(Warranty Remedy) and Section </span
        ><span class="c8">Error! Reference source not found.</span><span>&nbsp;(Mitigation). </span
        ><span class="c9"
          >Publisher is responsible for any sales, use, GST, value-added, withholding, or similar
          taxes or levies that apply to Orders, whether domestic or foreign, other than
          Clovyr&rsquo;s income tax (&ldquo;</span
        ><span class="c9 c8">Taxes</span
        ><span class="c2 c0">&rdquo;). Fees are exclusive of all Taxes.</span>
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.26in1rg" style="display: inline">
        <span class="c3">Changes in Fees</span
        ><span class="c2 c0"
          >. &nbsp;Clovyr reserves the right to modify the pricing structure that Clovyr provides to
          Publisher upon thirty (30) days&rsquo; prior written notice to Publisher (which may be via
          email or through the Services), and any such modifications shall apply to all Services
          purchased after the effective date of the modified pricing structure. Publisher&rsquo;s
          continued use of the applicable Service after the effective date of the modified pricing
          structure shall constitute Publisher&rsquo;s acceptance of the new pricing terms.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Disputes</span
        ><span class="c2 c0"
          >. &nbsp;If Publisher disputes any portion of an invoice, Publisher shall provide Clovyr
          with written notice of the disputed amount within fifteen (15) days of receipt of the
          invoice, along with a detailed explanation of the basis for the dispute. The parties shall
          use good faith efforts to resolve the dispute promptly. Any undisputed portion of the
          invoice shall be paid in accordance with the payment terms set forth in this
          Agreement.</span
        >
      </h2>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-0" start="10">
    <li class="c4 c13">
      <h1 id="h.lnxbz9" style="display: inline">
        <span class="c8"
          >Disclaimers. The Services, Support Service, and all other Clovyr services are provided
          &ldquo;AS IS&rdquo;. Clovyr, on its own behalf and on behalf of its suppliers and
          licensors, makes no other warranties, whether express, implied, statutory, or otherwise,
          including warranties of merchantability, fitness for a particular purpose, title, or
          noninfringement. Clovyr does not warrant that Publisher&rsquo;s use of the Services will
          be uninterrupted or error-free, that Clovyr will review Publisher Data for accuracy, or
          that it will maintain Publisher Data without loss. Clovyr is not liable for delays,
          failures, or problems inherent in use of the Internet and electronic communications or
          other systems outside Clovyr&rsquo;s control. Publisher may have other statutory rights,
          but any statutorily required warranties will be limited to the shortest legally permitted
          period.</span
        >
      </h1>
    </li>
    <li class="c4 c13">
      <h1 style="display: inline"><span class="c8">Term and Termination.</span></h1>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-1 start" start="1">
    <li class="c4 c13">
      <h2 id="h.35nkun2" style="display: inline">
        <span class="c3">Term</span><span>. The term of this Agreement (the &ldquo;</span
        ><span class="c8">Term</span
        ><span class="c2 c0"
          >&rdquo;) starts on the date that Publisher places an Order and continues until expiration
          or termination of all Subscription Terms.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.1ksv4uv" style="display: inline">
        <span class="c3">Termination For Convenience</span
        ><span class="c2 c0"
          >. Either party may terminate this Agreement or any Order for any reason or no reason
          before the current Subscription Term ends. Publisher may terminate this Agreement or any
          Order by using the applicable functionality made available via the Services (e.g., a
          &ldquo;cancel account&rdquo; button if made available). In the event Publisher terminates
          this Agreement or any Order pursuant to this Section 11.2, provided that Publisher remains
          in compliance with the terms of this Agreement, Publisher shall continue to (a) receive
          access to and use of the applicable Service as set forth in this Agreement until the end
          of the applicable Subscription Term and (b) be responsible for all payment obligations
          until the end of the applicable Subscription Term.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Termination for Breach. &nbsp;</span
        ><span class="c2 c0"
          >Either party may also terminate this Agreement (including any or all Orders) if the other
          party: (a) fails to cure a material breach of this Agreement (including a failure to pay
          fees) within fifteen (15) days after notice; (b) ceases operation without a successor; or
          (c) seeks protection under a bankruptcy, receivership, trust deed, creditors&rsquo;
          arrangement, composition, or comparable proceeding, or if such a proceeding is instituted
          against that party and not dismissed within sixty (60) days.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.44sinio" style="display: inline">
        <span class="c3">Effect of Termination</span
        ><span class="c2 c0"
          >. Upon expiration or termination of this Agreement, (a) all outstanding Orders will
          automatically terminate and (b) Publisher&rsquo;s access to and Clovyr&rsquo;s obligations
          to provide the Services and Support described in any such Orders and any Software will
          cease. Publisher Data and other Confidential Information, as defined in Section 15, may be
          retained in Recipient&rsquo;s standard backups notwithstanding any obligation to delete
          the applicable Confidential Information but will remain subject to this Agreement&rsquo;s
          confidentiality restrictions.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.2jxsxqh" style="display: inline">
        <span class="c3">Survival</span
        ><span
          >. These Sections survive expiration or termination of this Agreement: 1.3 (Restrictions),
          4.2&nbsp;(Usage Data; Aggregated Data), 5&nbsp;(Publisher Obligations), 9.2 (Fees and
          Taxes), 10&nbsp;(Disclaimers), 11.4 (Effect of Termination), 11.5&nbsp;(Survival),
          12&nbsp;(Ownership), 13&nbsp;(Limitations of Liability), 14&nbsp;(Indemnification),
          15&nbsp;(Confidentiality), 16&nbsp;(Required Disclosures), 19&nbsp;(General Terms),
          and</span
        ><span class="c3 c9">&nbsp;Exhibit A</span><span class="c3">&nbsp;(Definitions)</span
        ><span class="c2 c0">. </span>
      </h2>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-0" start="12">
    <li class="c4">
      <h1 id="h.z337ya" style="display: inline">
        <span class="c8">Ownership</span
        ><span class="c2 c0"
          >. Neither party grants the other any rights or licenses not expressly set out in this
          Agreement. Except as expressly provided in this Agreement, as between the parties,
          Publisher retains all intellectual property rights and other rights in Publisher Data and
          Publisher Materials provided to Clovyr. Except for Publisher&rsquo;s use rights in this
          Agreement, Clovyr and its licensors retain all intellectual property rights and other
          rights in the Services, any Support Service deliverables, Software, Documentation, Usage
          Data, and Clovyr technology, templates, formats, and dashboards, including any
          modifications or improvements to these items made by Clovyr. If Publisher provides Clovyr
          with feedback or suggestions regarding the Services or other Clovyr offerings, Clovyr may
          use the feedback or suggestions without restriction or obligation.
        </span>
      </h1>
    </li>
    <li class="c4 c13">
      <h1 id="h.3j2qqm3" style="display: inline">
        <span class="c8">Limitations of Liability.</span>
      </h1>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-1 start" start="1">
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Consequential Damages Waiver</span><span>. </span
        ><span class="c8"
          >Clovyr (and its suppliers or licensors) will have no liability arising out of or related
          to this Agreement for any loss of use, lost data, lost profits, failure of security
          mechanisms, interruption of business, or any indirect, special, incidental, reliance, or
          consequential damages of any kind, even if informed of their possibility in advance.</span
        ><span class="c2 c0">&nbsp;</span>
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Liability Cap</span><span>. </span
        ><span class="c8"
          >Clovyr&rsquo;s (and its suppliers&rsquo; and licensor&rsquo;s) entire liability arising
          out of or related to this Agreement will not exceed in aggregate the amounts paid or
          payable by Publisher to Clovyr pursuant to this Agreement during the twelve (12) months
          prior to the date on which the applicable claim giving rise to the liability arose under
          this Agreement.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.1y810tw" style="display: inline">
        <span class="c3">Nature of Claims and Failure of Essential Purpose</span
        ><span class="c2 c0"
          >. The waivers and limitations in this Section 13 apply regardless of the form of action,
          whether in contract, tort (including negligence), strict liability or otherwise and will
          survive and apply even if any limited remedy in this Agreement fails of its essential
          purpose.</span
        >
      </h2>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-0" start="14">
    <li class="c4 c13">
      <h1 id="h.4i7ojhp" style="display: inline">
        <span class="c8">Indemnification. </span
        ><span class="c2 c0"
          >To the fullest extent permitted by law, Publisher is responsible for Publisher&rsquo;s
          use of the Services, and Publisher will defend Clovyr from and against any third-party
          claim to the extent resulting from or connected with (a) Publisher&rsquo;s unauthorized
          use of, or misuse of, the Services, (b) Publisher Data or Publisher Materials, (c)
          Publisher&rsquo;s breach or alleged breach of any portion of this Agreement, any
          representation, warranty, or agreement referenced in this Agreement, or applicable law or
          regulation, (d) Publisher&rsquo;s violation of any third-party right, including any
          intellectual property right or publicity, confidentiality, or other property, or privacy
          right, or (e) any dispute or issue between Publisher and a third party, and will indemnify
          and hold harmless Clovyr against any damages and costs awarded against Clovyr (including
          reasonable attorneys&rsquo; fees) or agreed in a settlement by Publisher resulting from
          any such claim. Clovyr reserves the right, at Clovyr&rsquo;s own expense, to assume the
          exclusive defense and control of any matter otherwise subject to indemnification by
          Publisher (without limiting Publisher&rsquo;s indemnification obligations with respect to
          that matter), and in that case, Publisher agrees to cooperate with Clovyr&rsquo;s defense
          of those claims.</span
        >
      </h1>
    </li>
    <li class="c4 c13">
      <h1 id="h.2xcytpi" style="display: inline"><span class="c8">Confidentiality. </span></h1>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-1 start" start="1">
    <li class="c4">
      <h2 id="h.1ci93xb" style="display: inline">
        <span class="c3">Definition</span><span>. &ldquo;</span
        ><span class="c8">Confidential Information</span
        ><span>&rdquo; means information disclosed to the receiving party (&ldquo;</span
        ><span class="c8">Recipient</span
        ><span
          >&rdquo;) under this Agreement that is designated by the disclosing party (&ldquo;</span
        ><span class="c8">Discloser</span
        ><span class="c2 c0"
          >&rdquo;) as proprietary or confidential or that should be reasonably understood to be
          proprietary or confidential due to its nature and the circumstances of its disclosure.
          Clovyr&rsquo;s Confidential Information includes the terms and conditions of this
          Agreement and any technical or performance information about the Services.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Obligations</span
        ><span class="c2 c0"
          >. As Recipient, each party will: (a) hold Confidential Information in confidence and not
          disclose it to third parties except as permitted in this Agreement, including Section 4.1
          (Use of Publisher Data); and (b) only use Confidential Information to fulfill its
          obligations and exercise its rights in this Agreement. At Discloser&rsquo;s request,
          Recipient will delete all Confidential Information, except, in the case where Clovyr is
          the Recipient, Clovyr may retain the Publisher&rsquo;s Confidential Information to the
          extent required to continue to provide the Services. Recipient may disclose Confidential
          Information to its employees, agents, contractors, and other representatives having a
          legitimate need to know (including, for Clovyr, the subcontractors referenced in Section
          19.9), provided it remains responsible for their compliance with this Section&nbsp;15 and
          they are bound to confidentiality obligations no less protective than this Section
          15.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Exclusions</span
        ><span class="c2 c0"
          >. These confidentiality obligations do not apply to information that Recipient can
          document: (a) is or becomes public knowledge through no fault of the receiving party; (b)
          it rightfully knew or possessed prior to receipt under this Agreement; (c) it rightfully
          received from a third party without breach of confidentiality obligations; or (d) it
          independently developed without using Confidential Information.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Remedies</span
        ><span class="c2 c0"
          >. Unauthorized use or disclosure of Confidential Information may cause substantial harm
          for which damages alone are an insufficient remedy. Each party may seek appropriate
          equitable relief, in addition to other available remedies, for breach or threatened breach
          of this Section 15.</span
        >
      </h2>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-0" start="16">
    <li class="c4 c13">
      <h1 id="h.3whwml4" style="display: inline">
        <span class="c8">Required Disclosures</span
        ><span class="c2 c0"
          >. Nothing in this Agreement prohibits either party from making disclosures, including of
          Publisher Data and other Confidential Information, if required by Law, subpoena, or court
          order, provided (if permitted by Law) it notifies the other party in advance and
          cooperates in any effort to obtain confidential treatment.</span
        >
      </h1>
    </li>
    <li class="c4 c13">
      <h1 id="h.2bn6wsx" style="display: inline">
        <span class="c8">Trials and Betas</span><span>.</span><span class="c8">&nbsp;</span
        ><span
          >If Publisher receives access to Services or features thereof on a free or trial basis or
          as an alpha, beta, or early access offering (&ldquo;</span
        ><span class="c8">Trials and Betas</span
        ><span
          >&rdquo;), use is permitted only for Publisher&rsquo;s internal evaluation during the
          period designated by Clovyr (or if not designated, thirty (30) days). Trials and Betas are
          optional and either party may terminate Trials and Betas at any time for any reason.
          Trials and Betas may be inoperable, incomplete, or include features that Clovyr may never
          release, and their features and performance information are Clovyr&rsquo;s Confidential
          Information. </span
        ><span class="c8"
          >Notwithstanding anything else in this Agreement, Clovyr provides no warranty, indemnity,
          service level agreement, or support for Trials and Betas, and its liability for Trials and
          Betas will not exceed US$50</span
        ><span class="c2 c0">.</span>
      </h1>
    </li>
    <li class="c4 c13">
      <h1 id="h.qsh70q" style="display: inline">
        <span class="c8">Publicity</span><span>.</span><span class="c8">&nbsp;</span
        ><span
          >Neither party may publicly announce that the parties have entered into this Agreement,
          except with the other party&rsquo;s prior consent or as required by Laws. However, Clovyr
          may include Publisher and its trademarks in Clovyr&rsquo;s customer lists and promotional
          materials but will cease further use at Publisher&rsquo;s written request.</span
        ><span class="c8">&nbsp;</span>
      </h1>
    </li>
    <li class="c4 c13">
      <h1 id="h.3as4poj" style="display: inline"><span class="c8">General Terms.</span></h1>
    </li>
  </ol>
  <ol class="c1 lst-kix_list_1-1 start" start="1">
    <li class="c4">
      <h2 id="h.1pxezwc" style="display: inline">
        <span class="c3">Assignment</span
        ><span class="c2 c0"
          >. Neither party may assign this Agreement without the prior consent of the other party,
          except that either party may assign this Agreement in connection with a merger,
          reorganization, acquisition, or other transfer of all or substantially all its assets or
          voting securities to the other party involved in such transaction. Any non-permitted
          assignment is void. This Agreement will bind and inure to the benefit of each
          party&rsquo;s permitted successors and assigns.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Governing Law, Jurisdiction and Venue</span
        ><span class="c2 c0"
          >. This Agreement is governed by the laws of the State of New York and the United States
          without regard to conflicts of laws provisions that would result in the application of the
          laws of another jurisdiction and without regard to the United Nations Convention on the
          International Sale of Goods. The jurisdiction and venue for actions related to this
          Agreement will be the state and United States federal courts located in New York, NY and
          both parties submit to the personal jurisdiction of those courts.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Attorneys&rsquo; Fees and Costs</span
        ><span class="c2 c0"
          >. The prevailing party in any action to enforce this Agreement will be entitled to
          recover its attorneys&rsquo; fees and costs in connection with such action.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.49x2ik5" style="display: inline">
        <span class="c3">Notices</span
        ><span class="c2 c0"
          >. Except as set out in this Agreement, any notice or consent under this Agreement must be
          in writing to the addresses on the first page and will be deemed given: (a) upon receipt
          if by personal delivery; (b) upon receipt if by certified or registered U.S. mail (return
          receipt requested); or (c) one day after dispatch if by a commercial overnight delivery
          service. Notices may not be sent via email unless otherwise expressly permitted elsewhere
          in this Agreement. Either party may update its address with notice to the other party.
          Clovyr may also send operational notices to Publisher by email or through the Services.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Entire Agreement</span
        ><span
          >. This Agreement (which includes all Orders, Schedules, the Policies, and Clovyr&rsquo;s </span
        ><span class="c9">Terms of Service (</span
        ><span class="c3 c28"
          ><a
            class="c7"
            href="https://www.google.com/url?q=https://clovyr.io/terms-of-service&amp;sa=D&amp;source=editors&amp;ust=1691783095566185&amp;usg=AOvVaw3z91YcsXJYynIzadSU4Em0"
            >https://clovyr.io/terms-of-service</a
          ></span
        ><span class="c2 c0"
          >)) is the parties&rsquo; entire agreement regarding its subject matter and supersedes any
          prior or contemporaneous agreements regarding its subject matter. In this Agreement,
          headings are for convenience only and &ldquo;including&rdquo; and similar terms are to be
          construed without limitation. This Agreement may be executed in counterparts (including
          electronic copies and PDFs), each of which is deemed an original and which together form
          one and the same agreement.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Amendments</span
        ><span class="c2 c0"
          >. Any amendments, modifications, or supplements to this Agreement must be in writing and
          signed by each party&rsquo;s authorized representatives or, as appropriate, agreed through
          electronic means provided by Clovyr. Nonetheless, with notice to Publisher, Clovyr may
          modify the Policies to reflect new features or changing practices, but the modifications
          will not materially decrease Clovyr&rsquo;s overall obligations during a Subscription
          Term. The terms in any Publisher purchase order or business form will not amend or modify
          this Agreement and are expressly rejected by Clovyr; any of these Publisher documents are
          for administrative purposes only and have no legal effect.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Waivers and Severability</span
        ><span class="c2 c0"
          >. Waivers must be signed by the waiving party&rsquo;s authorized representative and
          cannot be implied from conduct. If any provision of this Agreement is held invalid,
          illegal, or unenforceable, it will be limited to the minimum extent necessary so the rest
          of this Agreement remains in effect.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.2p2csry" style="display: inline">
        <span class="c3">Force Majeure</span
        ><span
          >. Neither party is liable for any delay or failure to perform any obligation under this
          Agreement (except for a failure to pay fees) due to events beyond its reasonable control,
          such as a strike, blockade, war, pandemic, act of terrorism, riot, Internet or utility
          failures, refusal of government license, or natural disaster (&ldquo;</span
        ><span class="c8">Force Majeure Events</span><span class="c2 c0">&rdquo;).</span>
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.147n2zr" style="display: inline">
        <span class="c3">Subcontractors</span
        ><span class="c2 c0"
          >. Clovyr may use subcontractors and permit them to exercise Clovyr&rsquo;s rights, but
          Clovyr remains responsible for their compliance with this Agreement and for its overall
          performance under this Agreement.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Independent Contractors</span
        ><span class="c2 c0"
          >. The parties are independent contractors, not agents, partners, or joint
          venturers.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.3o7alnk" style="display: inline">
        <span class="c3">Export</span
        ><span class="c2 c0"
          >. Publisher will comply with all relevant U.S. and foreign export and import Laws in
          using any Service. Publisher: (a) represents and warrants that it is not listed on any
          U.S. government list of prohibited or restricted parties or located in (or a national of)
          a country that is subject to a U.S. government embargo or that has been designated by the
          U.S. government as a &ldquo;terrorist supporting&rdquo; country; (b) agrees not to access
          or use Services in violation of any U.S. export embargo, prohibition, or restriction; and
          (c) will not submit to the Services any information controlled under the U.S.
          International Traffic in Arms Regulations.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.23ckvvd" style="display: inline">
        <span class="c3">Open Source</span
        ><span>. The Software may incorporate third-party open source software (&ldquo;</span
        ><span class="c8">OSS</span
        ><span class="c2 c0"
          >&rdquo;), as listed in the Documentation or otherwise disclosed by Clovyr in writing. To
          the extent required by the OSS license, that license will apply to the OSS on a
          stand-alone basis instead of this Agreement.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 id="h.ihv636" style="display: inline">
        <span class="c3">Government End-Users</span
        ><span class="c2 c0"
          >. Elements of the Services are commercial computer software. If the user or licensee of
          the Services is an agency, department, or other entity of the United States Government,
          the use, duplication, reproduction, release, modification, disclosure, or transfer of the
          Services or any related documentation of any kind, including technical data and manuals,
          is restricted by the terms of this Agreement in accordance with Federal Acquisition
          Regulation 12.212 for civilian purposes and Defense Federal Acquisition Regulation
          Supplement 227.7202 for military purposes. The Services were developed fully at private
          expense. All other use is prohibited.</span
        >
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Remedies</span
        ><span class="c2 c0"
          >. Except where an exclusive remedy is provided in this Agreement, exercising a remedy
          under this Agreement, including termination, does not limit other remedies a party may
          have.
        </span>
      </h2>
    </li>
    <li class="c4">
      <h2 style="display: inline">
        <span class="c3">Conflicts in Interpretation</span
        ><span class="c2 c0"
          >. If there are inconsistencies or conflicts between the terms of the body of this
          Agreement and the terms of any Schedules, exhibits, attachments, addenda, Policies, and
          other documents attached to or incorporated by reference in this Agreement, the order of
          precedence is as follows: (a) the terms contained in the body of this Agreement; (b) the
          terms of the Schedules, exhibits, attachments, addenda, and Policies to this Agreement;
          and (c) the Documentation.
        </span>
      </h2>
    </li>
  </ol>
  <p class="c13 c19" id="h.32hioqz"><span class="c2 c0"></span></p>
  <p class="c19 c13"><span class="c2 c0"></span></p>
  <a id="id.1hmsyys"></a>
  <p class="c25"><span class="c2 c9 c10 c8">Exhibit A</span></p>
  <p class="c25" id="h.41mghml"><span class="c2 c9 c10 c8">Definitions</span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="1">
    <li class="c6" id="h.2grqrue">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Aggregated Data</span
      ><span class="c2 c0"
        >&rdquo; means Publisher Data that has been deidentified or aggregated with other data such
        that the resulting data no longer reasonably identifies Publisher or a specific individual.
      </span>
    </li>
    <li class="c17" id="h.vx1227">
      <span class="c0">&ldquo;&ldquo;</span><span class="c9 c10 c8">Documentation</span
      ><span class="c0"
        >&rdquo; means the then-current version of Clovyr&rsquo;s usage guidelines and standard
        technical documentation for the Services that Clovyr makes generally available to its
        customers that it provides the applicable Services to, the current version of which are at
        [</span
      ><span class="c0 c3">link</span><span class="c2 c0">].</span>
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">High Risk Activities</span
      ><span class="c2 c0"
        >&rdquo; means activities where use or failure of a Service could lead to death, personal
        injury, or environmental damage, including life support systems, emergency services, nuclear
        facilities, autonomous vehicles, or air traffic control.</span
      >
    </li>
    <li class="c6" id="h.3fwokq0">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Laws</span
      ><span class="c2 c0"
        >&rdquo; means all applicable relevant local, state, federal and international laws,
        regulations and conventions, including those related to data privacy and data transfer,
        international communications, and export of data, including Developer Personal Data and
        Developer Personal Information.</span
      >
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c8 c10">Order</span
      ><span class="c2 c0"
        >&rdquo; means an order (which may be electronic) for the Services that is entered into
        between Clovyr and Publisher (which may be an online order or registration, sign-up form, or
        payment submission form).
      </span>
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Policies</span
      ><span class="c2 c0"
        >&rdquo; means any policies related to the Services that Clovyr may make available to
        Publisher over the course of the Term.</span
      >
    </li>
    <li class="c6" id="h.1v1yuxt">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Process</span
      ><span class="c2 c0"
        >&rdquo; means to collect, access, use, disclose, transfer, transmit, store, host, or
        otherwise process.</span
      >
    </li>
    <li class="c6" id="h.4f1mdlm">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Prohibited Data</span
      ><span class="c0"
        >&rdquo; means any: (a) special categories of data enumerated in European Union Regulation
        2016/679, Article 9(1) or any successor legislation; (b)&nbsp;patient, medical, or other
        protected health information regulated by the Health Insurance Portability and
        Accountability Act (as amended and supplemented) (&ldquo;</span
      ><span class="c9 c10 c8">HIPAA</span
      ><span class="c0"
        >&rdquo;); (c) credit, debit, or other payment card data subject to the Payment Card
        Industry Data Security Standards (&ldquo;</span
      ><span class="c9 c10 c8">PCI DSS</span
      ><span class="c0"
        >&rdquo;); (d) other information subject to regulation or protection under specific Laws
        such as the Children&rsquo;s Online Privacy Protection Act or Gramm-Leach-Bliley Act (or
        related rules or regulations); (e) social security numbers, driver&rsquo;s license numbers,
        or other government ID numbers; or (f) any data similar to the above protected Laws</span
      ><span class="c0 c16">.</span>
    </li>
    <li class="c6">
      <span class="c9 c10 c8">Publisher Data</span
      ><span class="c2 c0"
        >&rdquo; means any data or information that: (a) Publisher (including its Users) submits to
        the Services, including from Third-Party Platforms; and (b) is Processed by Clovyr to
        provide the Services to Publisher.</span
      >
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Publisher Materials</span
      ><span class="c2 c0"
        >&rdquo; means materials, systems, and other resources that Publisher provides to Clovyr in
        connection with Support Service.</span
      >
    </li>
    <li class="c6" id="h.2u6wntf">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Publisher Systems</span
      ><span class="c0"
        >&rdquo; means Publisher&rsquo;s hardware, software, other technology, and infrastructure
        that Publisher is required to provide and maintain in order for Publisher to access and use
        the Services.</span
      ><span class="c0 c16">&nbsp;</span>
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Schedule</span
      ><span class="c2 c0"
        >&rdquo; means a document that adds and/or adjusts certain terms of the Agreement as they
        apply to the purchase of one or more Services. Each Schedule is governed by and a part of
        the terms of this Agreement.
      </span>
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Service</span
      ><span class="c0">&rdquo; or &ldquo;</span><span class="c9 c10 c8">Services</span
      ><span class="c0"
        >&rdquo; means the then-current version of Clovyr&rsquo;s proprietary services that are
        identified in the relevant Order (but excluding Support Service), which are intended to
        facilitate the ability of Publishers to publish and distribute certain software products
        (e.g., access to Fediment + Clovyr Code platform via &ldquo;day pass&rdquo;). Each of the
        Services includes the Software and Documentation for the Service.</span
      ><span class="c9 c16 c10 c8">&nbsp;</span>
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Software</span
      ><span class="c0"
        >&rdquo; means any software, scripts, or other code required by Clovyr to operate a
        Service.</span
      ><span class="c0 c16">&nbsp;</span>
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Subscription Term</span
      ><span class="c2 c0"
        >&rdquo; means the period during which Publisher&rsquo;s subscription to access and use the
        Services is in effect, as identified in the applicable Order.</span
      >
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Support</span
      ><span class="c2 c0"
        >&rdquo; means support for the Services as described in the Support Policy.
      </span>
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Support Policy</span
      ><span class="c2 c0"
        >&rdquo; means the then-current version of Clovyr&rsquo;s customer support policy with
        respect to the Services as provided to Publisher (which may be dependent on
        Publisher&rsquo;s applicable subscription tier).
      </span>
    </li>
    <li class="c6" id="h.19c6y18">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Third-Party Platform</span
      ><span class="c2 c0"
        >&rdquo; means any third-party platform, add-on, service, or product not provided by Clovyr
        that Publisher elects to integrate or enable for use with any Service.
      </span>
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Updates</span
      ><span class="c2 c0"
        >&rdquo; means any updates, modifications, or bug fixes to the Services or Documentation
        that Clovyr provides free of additional charge to its customers using a Service.
      </span>
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Upgrades</span
      ><span class="c2 c0"
        >&rdquo; means additions, enhancements, upgrades, new services, or modules that include new
        features and substantial increases in functionality to the Services that Clovyr makes
        available to its customers for an additional fee.</span
      >
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Usage Data</span
      ><span class="c2 c0"
        >&rdquo; means information generated from the use of the Services, which data does not
        identify Users, any other natural human persons, or Publisher, such as technical logs, data,
        and learnings about Publisher&rsquo;s use of the Services, but excluding any identifiable
        Publisher Data.</span
      >
    </li>
    <li class="c6">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">Usage Limits</span
      ><span class="c0 c2"
        >&rdquo; means any usage limits to the Services as communicated by Clovyr to Publisher in
        writing (which may be via an Order or email or otherwise through the Services). &nbsp;</span
      >
    </li>
    <li class="c18" id="h.3tbugp1">
      <span class="c0">&ldquo;</span><span class="c9 c10 c8">User</span
      ><span class="c2 c0"
        >&rdquo; means any employee or contractor of Publisher that Publisher allows to use the
        Services on Publisher&rsquo;s behalf.</span
      >
    </li>
  </ol>
  <p class="c21 c27"><span class="c2 c0"></span></p>
  <p class="c14 c21"><span class="c2 c10 c11"></span></p>
  <div>
    <p class="c14 c26">
      <span class="c15 c10 c30"
        >Cloud Services
        Agreement&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
    </p>
  </div>
</template>

<style lang="css" scoped>
/* @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98); */
/* inline import */
/* latin */
@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/l/font?kit=J7afnpV-BGlaFfdAhLEY67FIEjg&skey=a1029226f80653a8&v=v15) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/l/font?kit=J7aanpV-BGlaFfdAjAo9_pxqHxIZrCE&skey=cd2dd6afe6bf0eb2&v=v15) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* end import */
a {
  color: #29bd7b !important;
}
ol.lst-kix_list_1-3 {
  list-style-type: none;
}
ol.lst-kix_list_1-4 {
  list-style-type: none;
}
.lst-kix_list_2-6 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, lower-latin) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.'
    counter(lst-ctn-kix_list_2-6, decimal) '. ';
}
.lst-kix_list_2-7 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, lower-latin) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.'
    counter(lst-ctn-kix_list_2-6, decimal) '.' counter(lst-ctn-kix_list_2-7, decimal) '. ';
}
.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
ol.lst-kix_list_1-5 {
  list-style-type: none;
}
ol.lst-kix_list_1-6 {
  list-style-type: none;
}
.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, lower-latin) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '. ';
}
.lst-kix_list_2-5 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, lower-latin) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '. ';
}
.lst-kix_list_2-8 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, lower-latin) '.' counter(lst-ctn-kix_list_2-3, decimal) '.'
    counter(lst-ctn-kix_list_2-4, decimal) '.' counter(lst-ctn-kix_list_2-5, decimal) '.'
    counter(lst-ctn-kix_list_2-6, decimal) '.' counter(lst-ctn-kix_list_2-7, decimal) '.'
    counter(lst-ctn-kix_list_2-8, decimal) '. ';
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
ol.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
ol.lst-kix_list_1-8 {
  list-style-type: none;
}
ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
ol.lst-kix_list_2-2 {
  list-style-type: none;
}
ol.lst-kix_list_2-3 {
  list-style-type: none;
}
ol.lst-kix_list_2-4 {
  list-style-type: none;
}
ol.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
ol.lst-kix_list_2-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
ol.lst-kix_list_2-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
.lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}
ol.lst-kix_list_2-6 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '. ';
}
.lst-kix_list_1-2 > li:before {
  content: '(' counter(lst-ctn-kix_list_1-2, lower-latin) ') ';
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
ol.lst-kix_list_2-7 {
  list-style-type: none;
}
ol.lst-kix_list_2-8 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, lower-latin) '.' counter(lst-ctn-kix_list_1-3, decimal) '. ';
}
.lst-kix_list_1-4 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, lower-latin) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '. ';
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
.lst-kix_list_1-7 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, lower-latin) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
    counter(lst-ctn-kix_list_1-6, decimal) '.' counter(lst-ctn-kix_list_1-7, decimal) '. ';
}
ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.lst-kix_list_1-5 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, lower-latin) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '. ';
}
.lst-kix_list_1-6 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, lower-latin) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
    counter(lst-ctn-kix_list_1-6, decimal) '. ';
}
.lst-kix_list_2-0 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
}
.lst-kix_list_2-1 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '. ';
}
ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
.lst-kix_list_1-8 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.'
    counter(lst-ctn-kix_list_1-2, lower-latin) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
    counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
    counter(lst-ctn-kix_list_1-6, decimal) '.' counter(lst-ctn-kix_list_1-7, decimal) '.'
    counter(lst-ctn-kix_list_1-8, decimal) '. ';
}
.lst-kix_list_2-2 > li:before {
  content: '' counter(lst-ctn-kix_list_2-2, lower-latin) ') ';
}
.lst-kix_list_2-3 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '.' counter(lst-ctn-kix_list_2-1, decimal) '.'
    counter(lst-ctn-kix_list_2-2, lower-latin) '.' counter(lst-ctn-kix_list_2-3, decimal) '. ';
}
.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c4 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 6pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c17 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 2pt;
}
.c19 {
  padding-top: 0pt;
  padding-bottom: 3pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
  margin-right: 1.8pt;
  height: 10pt;
}
.c6 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 1.8pt;
}
.c18 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 3pt;
  line-height: 1;
  text-align: justify;
  margin-right: 1.8pt;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
  height: 10pt;
}
.c25 {
  padding-top: 0pt;
  padding-bottom: 3pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
  margin-right: 2.1pt;
}
.c23 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c12 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c22 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Arial';
  font-style: normal;
}
.c27 {
  padding-top: 0pt;
  padding-bottom: 3pt;
  line-height: 1;
  text-align: center;
  margin-right: 1.8pt;
}
.c2 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-style: normal;
}
.c24 {
  padding-top: 0pt;
  padding-bottom: 3pt;
  line-height: 1;
  text-align: justify;
}
.c14 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c20 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 8pt;
  font-style: normal;
}
.c0 {
  font-family: 'Calibri';
  color: #ada6b7;
  font-weight: 400;
}
.c31 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c3 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}
.c7 {
  color: inherit;
  text-decoration: inherit;
}
.c11 {
  color: #ffffff;
  font-weight: 400;
}
.c29 {
  border: 1px solid black;
  margin: 5px;
}
.c26 {
  orphans: 2;
  widows: 2;
}
.c1 {
  padding: 0;
  margin: 0;
}
.c9 {
  color: #ada6b7;
}
.c30 {
  font-size: 8pt;
}
.c15 {
  font-weight: 400;
}
.c16 {
  font-style: italic;
}
.c28 {
  color: #0000ff;
}
.c10 {
  font-family: 'Calibri';
}
.c21 {
  height: 10pt;
}
.c8 {
  font-weight: 700;
}
.c13 {
  page-break-after: avoid;
}
.title {
  padding-top: 0pt;
  color: #ada6b7;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 6pt;
  font-family: 'Times New Roman';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #ada6b7;
  font-size: 10pt;
  font-family: 'Times New Roman';
}
p {
  margin: 0;
  color: #ada6b7;
  font-size: 10pt;
  font-family: 'Times New Roman';
}
h1 {
  padding-top: 0pt;
  color: #ada6b7;
  font-size: 10pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h2 {
  padding-top: 0pt;
  color: #ada6b7;
  font-size: 10pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h3 {
  padding-top: 0pt;
  color: #ada6b7;
  font-size: 10pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h4 {
  padding-top: 0pt;
  color: #ada6b7;
  font-size: 9pt;
  padding-bottom: 12pt;
  font-family: 'Arial';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h5 {
  padding-top: 0pt;
  color: #ada6b7;
  font-size: 9pt;
  padding-bottom: 12pt;
  font-family: 'Arial';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h6 {
  padding-top: 0pt;
  color: #ada6b7;
  font-size: 9pt;
  padding-bottom: 12pt;
  font-family: 'Arial';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>

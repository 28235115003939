import { storeToRefs } from 'pinia';
import * as Sentry from '@sentry/core';

import { ComputeProviderName, type Deployment, DeploymentAddon } from '@clovyr/pollen';
import { CLOVYR_ACCESS_KEY } from '@clovyr/pollen/manifest';
import {
  type PaymentMethod,
  SubscriptionAPI,
  type UpgradeSubscriptionItemRes,
} from '@clovyr/pollen/subscription';
import { InvoiceAPI } from '@clovyr/pollen/subscription/invoice';
import type { Invoice } from '@clovyr/pollen/types/Invoice';
import type { UserSubscription } from '@clovyr/pollen/types/UserSubscription';
import type { UserSubscriptionItem } from '@clovyr/pollen/types/UserSubscriptionItem';

import { useBillingStore } from '@/stores/billing_store';

import { usePollenStore } from '../stores/pollen_store';
import { persist } from '../stores/util';

export const PaymentCallbackStateKey = 'clovyr/payment-cb-state';

export type PaymentCallbackState = {
  /**
   * Deployment ID that the payment is associated with
   */
  deploymentID?: string;

  /**
   * Subscription item ID that the payment is associated with
   */
  subscriptionItemID?: string;

  /**
   * Invoice ID that the payment is associated with
   */
  invoiceID?: string;

  /**
   * URL to redirect to on successful payment
   */
  successURL: string;

  /**
   * URL to redirect to on failed payment
   */
  errorURL: string;

  /**
   * URL to redirect to on cancelled payment
   */
  cancelURL: string;
};

/**
 * Format the given amount and currency into a human-readable string.
 *
 * @param amount decimal amount as a string, e.g., '15' or '15.00'
 * @param currency ISO code, e.g., 'USD'
 * @returns a formatted string, e.g., '$15.00'
 */
export function formatAmount(amount: string, currency: string): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(parseFloat(amount));
}

export const usePayments = () => {
  const pollenStore = usePollenStore();
  const { sortedDeployments } = storeToRefs(pollenStore);
  const { pollen } = pollenStore;
  const { subscription, subscriptionItems, invoices } = storeToRefs(useBillingStore());

  const isStripe = computed(() => subscription.value?.subscription_provider === 'stripe');

  /**
   * Get the user's subscription, optionally creating one if needed.
   *
   * Creation should likely only happen internal to this composable, i.e., via
   * `addSubscriptionItem`, and not directly via external clients.
   *
   * @param create If user does not have a subscription, create one
   * @returns
   */
  async function getSubscription(create = false): Promise<UserSubscription | undefined> {
    if (!(subscription.value && !!subscription.value.id) && create) {
      // create new subscription
      const sub = await new SubscriptionAPI().createSubscription();
      await pollen.saveSubscription(sub);
      subscription.value = sub;
    }
    return subscription.value;
  }

  /**
   * Get subscription item from store or load via API, if needed.
   *
   * @param subID
   * @param subItemID
   * @returns
   */
  async function getSubscriptionItem(
    subID: string,
    subItemID: string,
    refresh?: boolean,
  ): Promise<UserSubscriptionItem> {
    if (subscriptionItems.value[subItemID] && !refresh) {
      return subscriptionItems.value[subItemID];
    }
    const item = await new SubscriptionAPI().getSubscriptionItem(subID, subItemID);
    subscriptionItems.value[subItemID] = item;
    return item;
  }

  async function listSubscriptionItems(): Promise<UserSubscriptionItem[]> {
    if (!subscription.value?.id) {
      return [];
    }
    return new SubscriptionAPI().listSubscriptionItems(subscription.value!.id);
  }

  async function listNonAppSubscriptionItems(): Promise<UserSubscriptionItem[]> {
    const items = await listSubscriptionItems();
    return items.filter((item) => item.resource_id.startsWith('daypass_'));
  }

  /**
   * Load all subscription data into the store
   *
   * @returns
   */
  async function loadSubscriptionItems(
    refresh?: boolean,
  ): Promise<(void | UserSubscriptionItem)[]> {
    if (!subscription.value) {
      return [];
    }
    const p = sortedDeployments.value.map(async (deployment) => {
      if (deployment.subscriptionItemID) {
        return getSubscriptionItem(subscription.value!.id, deployment.subscriptionItemID, refresh);
      }
      return Promise.resolve();
    });
    return Promise.all(p);
  }

  async function refreshSubscription(): Promise<void> {
    try {
      if (!(subscription.value?.subscription_provider && subscription.value?.payment_captured_at)) {
        // refresh sub if payment not yet captured
        const sub = await new SubscriptionAPI().getSubscription();
        if (sub.payment_captured_at) {
          // only update if it has changed now
          await pollen.saveSubscription(sub);
        }
        subscription.value = sub;
      }
      await loadSubscriptionItems(true);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  /**
   * Add a new item to the user's subscription.
   *
   * This should happen immediately upon launch of a new deployment into
   * Clovyr Hosting or when BYOH backups are enabled.
   *
   * @param deployment
   * @param isTrial Whether or not this item should start as a trial
   * @returns
   */
  async function addSubscriptionItem(
    deployment: Deployment,
    isTrial: boolean,
  ): Promise<UserSubscriptionItem | undefined> {
    // slug should be either the app ID or the addon ID
    let slug = '';
    let resourceID = '';
    if (deployment.hostingProvider === ComputeProviderName.WNext) {
      slug = deployment.appID;
      resourceID = `instance_${deployment.fqdn}`;
    } else {
      // byoh
      // TODO: in the future, a deployment may be able to have multiple subscriptions in the case of multiple addons
      // eslint-disable-next-line no-lonely-if
      if (deployment.addons && deployment.addons.includes(DeploymentAddon.Backups)) {
        slug = 'addons_backup';
        resourceID = `access_${deployment.appSettings[CLOVYR_ACCESS_KEY]}`;
      }
    }
    if (slug === '') {
      // no addon selected for BYOH case, just bail
      return undefined;
    }

    let sub: UserSubscription | undefined;
    try {
      sub = await getSubscription(true);
      subscription.value = sub;
    } catch (e) {
      throw new Error(`failed to create subscription: ${e}`);
    }
    if (!sub) {
      throw new Error('failed to get subscription');
    }

    const subItem = await new SubscriptionAPI().addItem({
      subscriptionID: sub.id,
      resourceID,
      appSlug: slug,
      isTrial,
    });

    // store
    subscriptionItems.value[subItem.id] = subItem;

    // attach to deployment
    deployment.subscriptionItemID = subItem.id;
    await pollenStore.pollen.putDeployment(deployment);

    return subItem;
  }

  /**
   * Initiate the payment capture using the 3rd-party checkout page.
   *
   * When successful, the user will be redirected to the payment provider to complete the payment.
   *
   * After completing payment:
   * - Payment provider redirects to API callback endpoint -
   * - API callback processes result, redirects to UI endpoint - /payments/callbacks/:provider
   * - Router processes result and redirects to success or error URL, given in cbData (which was
   *   stored in localStorage before redirecting to payment provider)
   *
   * @param subItem
   * @throws error if payment capture fails
   */
  async function capturePayment(
    paymentMethod: PaymentMethod,
    subItem: UserSubscriptionItem,
    cbData: PaymentCallbackState,
  ): Promise<void> {
    const sub = await getSubscription();
    if (!sub) {
      throw new Error('failed to get subscription');
    }
    subscription.value = sub;

    if (sub.payment_captured_at) {
      // throw error instead?
      return;
    }

    // store callback state for processing upon redirect back from payment provider
    persist(PaymentCallbackStateKey, cbData);

    // payment flow for a specific subscription item
    const res = await new SubscriptionAPI().capturePayment({
      method: paymentMethod,
      subscriptionID: sub.id,
      subscriptionItemID: subItem.id,
    });
    if (!res.url) {
      throw new Error('failed to initiate payment capture');
    }

    window.location.href = res.url; // send to 3rd-party checkout

    // TODO: cleanup
    // refresh the subscription
    // sub = await new SubscriptionAPI().getSubscription();
    // await pollenStore.pollen.saveSubscription(sub);
  }

  /**
   * Create a payment session for the given invoice and redirect to the payment provider.
   *
   * Works similarly to the `capturePayment` function, but for a specific invoice.
   *
   * @param invoice
   * @param cbData
   */
  async function capturePaymentForInvoice(
    invoice: Invoice,
    cbData: PaymentCallbackState,
  ): Promise<void> {
    persist(PaymentCallbackStateKey, cbData);

    try {
      const url = await new InvoiceAPI().payInvoice(invoice.subscription_id, invoice.id);
      if (!url) {
        throw new Error('failed to initiate payment capture');
      }
      window.location.href = url;
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }
  }

  async function upgradeSubscriptionItem(
    subItem: UserSubscriptionItem,
  ): Promise<UpgradeSubscriptionItemRes> {
    return new SubscriptionAPI().upgradeSubscriptionItem(subItem.subscription_id, subItem.id);
  }

  async function cancelSubscriptionItem(
    subItem: UserSubscriptionItem,
    cancelAt: 'now' | 'end_of_period',
  ): Promise<void> {
    return new SubscriptionAPI().cancelSubscriptionItem(
      subItem.subscription_id,
      subItem.id,
      cancelAt,
    );
  }

  async function getPortalURL(): Promise<string> {
    const res = await new SubscriptionAPI().createPortalSession();
    if (!res.url) {
      throw new Error('failed to initiate payment capture');
    }
    return res.url;
  }

  async function loadInvoices(): Promise<Invoice[]> {
    if (!subscription.value) {
      return [];
    }
    const res = await new InvoiceAPI().listInvoices(subscription.value.id);
    invoices.value = res;
    return res;
  }

  return {
    subscription,
    subscriptionItems,
    invoices,
    isStripe,
    sortedDeployments,

    getSubscription,
    getSubscriptionItem,
    refreshSubscription,
    loadSubscriptionItems,
    listSubscriptionItems,
    listNonAppSubscriptionItems,

    addSubscriptionItem,
    upgradeSubscriptionItem,
    cancelSubscriptionItem,
    capturePayment,
    getPortalURL,

    loadInvoices,
    capturePaymentForInvoice,
  };
};

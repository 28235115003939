/**
 * Returns an absolute URL for the given absolute path. Supports SSR & Browser.
 *
 * @param url
 * @returns
 */
export const useAbsoluteURL = (url: string) => {
  if (!url || url.startsWith('http')) {
    return url;
  }
  if (import.meta.env.SSR) {
    if (url[0] !== '/') {
      // eslint-disable-next-line no-param-reassign
      url = `/${url}`;
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return `${process.env['PUBLIC_URL']}${url}`;
  }
  return new URL(`${url}`, import.meta.url).href;
};

import { createRandomKey } from '../crypto';
import { FetchWrapper } from '../http';
import { type Params, toQS } from '../http/qs';
import { type ComputeProviderConfig, ComputeProviderName, type Instance } from '../types';

import type { APIErrors, CreateLinodeRequest, Linode as LinodeInstance } from './types/linode';
import {
  ComputeInstanceImage,
  ComputeInstanceRegion,
  ComputeInstanceSize,
  type ComputeProvider,
  type CreateInstanceRequest,
  type DestroyInstanceRequest,
  type DestroyInstanceResponse,
  type GetInstanceRequest,
  type InstanceType,
  type InstanceTypeMap,
  type ListInstancesRequest,
  type OAuthConfig,
  type OAuthSupport,
  type OAuthToken,
  type UpdateInstanceRequest,
} from './index';

const LINODE_OAUTH_URL = 'https://login.linode.com/oauth/authorize';

const LINODE_API_URL = 'https://api.linode.com/v4';

/**
 * Generic StackScript for user-data like functionality.
 *
 * It is hosted in the wNext Linode account.
 *
 */
const LINODE_STACKSCRIPT_ID = 675706;

interface OAuthResponse extends OAuthToken {
  scope: string;
  token_type: string;
  expires_in: string;
  state: string;
}

const sizes: InstanceTypeMap = {
  [ComputeInstanceSize.Micro]: ['g6-nanode-1', 5], // $5
  [ComputeInstanceSize.Small]: ['g6-nanode-1', 5], // $5
  [ComputeInstanceSize.Medium]: ['g6-standard-1', 10], // $10
  [ComputeInstanceSize.Large]: ['g6-standard-4', 40], // $40
  [ComputeInstanceSize.XLarge]: ['g6-standard-4', 40], // $40
  [ComputeInstanceSize.XLargeAI]: ['g7-premium-64', 40], // $40
};

const images = {
  [ComputeInstanceImage.UbuntuLTS]: 'linode/ubuntu20.04', // 20.04 for now
  [ComputeInstanceImage.Ubuntu2204]: 'linode/ubuntu22.04',
  [ComputeInstanceImage.Ubuntu2004]: 'linode/ubuntu20.04',
};

/**
 * Linode compute provider implementation.
 *
 * Authentication can be done in one of two ways:
 * - User provided Personal Access Token
 * - OAuth web flow
 *
 * The latter is a simpler flow as it does not require the user to know how to
 * generate a personal access token and we can (and should) revoke it once we
 * are finished using it to avoid potential token leakage scenarios.
 *
 * *NOTE* access tokens retrieved via OAuth expire in 2 hours
 */
export class Linode implements ComputeProvider, OAuthSupport {
  static config: OAuthConfig;

  /**
   * Access token to use for making API requests
   */
  #oAuthAccessToken?: OAuthToken;

  #oAuthCallbackUrl?: string;

  #oAuthState?: string;

  #oAuthHeaders?: HeadersInit;

  #client: FetchWrapper;

  constructor() {
    this.#client = new FetchWrapper({ baseURL: LINODE_API_URL });
  }

  getConfig() {
    const oauthToken = this.getOAuthToken();
    if (oauthToken === undefined) {
      return undefined;
    }

    const config: ComputeProviderConfig = {
      id: crypto.randomUUID(),
      providerID: ComputeProviderName.Linode,
      name: 'Linode',
      credentials: {
        accessToken: oauthToken.access_token,
        refreshToken: oauthToken.refresh_token,
      },
    };

    return config;
  }

  setConfig(config: ComputeProviderConfig) {
    if (config.providerID !== ComputeProviderName.Linode) {
      throw new Error(`Linode provider configured with wrong config type: ${config.providerID}`);
    }

    this.#oAuthAccessToken = {
      access_token: config.credentials.accessToken,
      refresh_token: config.credentials.refreshToken,
    };
  }

  isConfigured(): boolean {
    return !!this.#oAuthAccessToken && !!this.#oAuthAccessToken.access_token;
  }

  getInstanceType(size: ComputeInstanceSize): InstanceType {
    return sizes[size];
  }

  getInstanceImage(image: ComputeInstanceImage, region: string): string {
    return images[image];
  }

  async createInstance(req: CreateInstanceRequest): Promise<Instance> {
    const now = new Date();
    const createReq: CreateLinodeRequest = {
      label: req.name,
      region: req.instanceRegion,
      type: req.instanceType,
      image: req.instanceImage,
      stackscript_id: LINODE_STACKSCRIPT_ID,
      stackscript_data: {
        // stackscript expects a base64 encoded script to execute
        userdata: btoa(req.userData!),
      },
      root_pass: createRandomKey(),
    };

    const res = await this.#client.post('/linode/instances', createReq, {
      headers: this.#getAuthHeaders(),
    });

    if (!(res instanceof Response)) {
      throw res as Error;
    }

    const data: LinodeInstance | APIErrors = await res.json();
    if ('errors' in data) {
      throw new Error(data.errors[0].reason);
    }
    const instance = this.#linodeToInstance(data);
    instance.createstarted = now;
    instance.userdata = req.userData;
    instance.configure_method = createReq.root_pass;
    return instance;
  }

  async destroyInstance(req: DestroyInstanceRequest): Promise<DestroyInstanceResponse> {
    await this.#client.delete(`/linode/instances/${req.id}`, undefined, {
      headers: this.#getAuthHeaders(),
    });
    return {};
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async updateInstance(req: UpdateInstanceRequest): Promise<Instance> {
    throw new Error('not implemented');
  }

  // List of instances we have access to
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async listInstances(req: ListInstancesRequest): Promise<Instance[]> {
    throw new Error('not implemented');
  }

  // Fetch instance details, if authorized
  async getInstance(req: GetInstanceRequest): Promise<Instance> {
    const res = await this.#client.get(`/linode/instances/${req.id}`, {
      headers: this.#getAuthHeaders(),
    });
    if (res instanceof Response) {
      return this.#linodeToInstance((await res.json()) as LinodeInstance);
    }
    throw res as Error;
  }

  #linodeToInstance(linode: LinodeInstance): Instance {
    const instance: Instance = {
      fqdn: linode.label,
      remoteinstanceid: linode.id.toString(),
      ipv4: linode.ipv4?.[0],
      // stub
      managedinstancetemplate: 4,
      id: 0,
      instanceprovider: 0,
      dnsprovider: 0,
    };
    return instance;
  }

  /**
   * Retrieve the oauth token
   *
   * @returns
   */
  getOAuthToken(): OAuthToken | undefined {
    return this.#oAuthAccessToken;
  }

  /**
   * Create the OAuth2 authorization URL.
   *
   * User should be directed to visit the returned URL and complete the OAuth
   * handshake with Linode.
   *
   * We use the implicit (public) Oauth2 flow:
   * https://www.linode.com/docs/api/#oauth-workflow
   *
   * scope requested:
   * linodes:read_write - Allow access to all endpoints related to your Linodes.
   * https://www.linode.com/docs/api/#oauth-reference
   *
   * @param baseCallbackURI string base URL for callback (e.g., 'https://clovyr.app')
   * @param clientID
   * @returns string URL
   */
  createOAuthUrl(baseCallbackURI?: string, clientID?: string): string {
    baseCallbackURI ||= Linode.config.CallbackURI;
    clientID ||= Linode.config.ClientID;

    this.#oAuthState = createRandomKey();
    // TODO: should the entire URL be passed from client? ultimately this route
    // needs to be handled by the UI layer and the fragment passed back in.
    this.#oAuthCallbackUrl = `${baseCallbackURI}/oauth/callbacks/linode`;

    const opts = {
      client_id: clientID,
      redirect_uri: this.#oAuthCallbackUrl,
      state: this.#oAuthState,
      response_type: 'token',
      scope: 'linodes:read_write',
    };

    return `${LINODE_OAUTH_URL}${toQS(opts)}`;
  }

  /**
   * Provide OAuth token, either directly (personal access token) or as hash of
   * params retrieved from callback.
   *
   * Success example:
   * #access_token=<snip>&token_type=bearer&expires_in=7200&scope=linodes:read_write&state=oT5PNw8odZ6hC4JT3cgNKxDSZfLQ35yV&return=http://localhost:3000/oauth/callbacks/linode
   *
   * @param params Hash of key/value pairs or the access token itself as a string
   * @returns
   */
  setOAuthToken(params: Params | string): OAuthToken {
    if (typeof params === 'string') {
      this.#oAuthAccessToken = { access_token: params };
      return this.#oAuthAccessToken;
    }
    if (!params || !params['access_token']) {
      // must have at least an access_token
      throw new Error('invalid token response');
    }
    const token = params as unknown as OAuthResponse;
    if (this.#oAuthState && token.state !== this.#oAuthState) {
      // validate state for the oauth flow
      throw new Error('invalid token response: state mismatch');
    }
    this.#oAuthAccessToken = {
      access_token: token.access_token,
      refresh_token: token.refresh_token,
    };
    return this.#oAuthAccessToken;
  }

  /**
   * De-authorize stored token if no longer needed
   */
  async revokeOAuthToken(): Promise<void> {
    if (!(this.#oAuthAccessToken && this.#oAuthAccessToken.access_token)) {
      return;
    }
    await this.#client
      .post('/revoke', undefined, {
        headers: this.#getAuthHeaders(),
      })
      .then(() => {
        this.#oAuthAccessToken = undefined;
        this.#oAuthHeaders = undefined;
      });
  }

  #getAuthHeaders(): HeadersInit {
    if (!this.#oAuthHeaders) {
      this.#oAuthHeaders = Object.freeze({
        Authorization: `Bearer ${this.#oAuthAccessToken?.access_token}`,
        'Content-Type': 'application/json',
      });
    }
    return this.#oAuthHeaders;
  }
}

<script setup lang="ts">
import { Hero, HeroBackground } from '@clovyr/bed';
import Hero1 from '@clovyr/bed/assets/images/catalog/btclnd-hero.webp';
import Hero2 from '@clovyr/bed/assets/images/catalog/gogs-bg.webp';
import Hero5 from '@clovyr/bed/assets/images/clovyr-island-mascots.webp';
import Hero3 from '@clovyr/bed/assets/images/crypto-hero.webp';
import Hero4 from '@clovyr/bed/assets/images/fediverse-hero.webp';
import type { Manifest } from '@clovyr/pollen/manifest';
import { slugify } from '@clovyr/pollen/util/slugify';

import Breadcrumbs from '@/components/elements/Breadcrumbs.vue';
import AppSummary from '@/components/tiers/AppSummary.vue';

const props = defineProps<{ tag: string; apps: Manifest[] }>();

const tag = (() => {
  // deslugify
  if (!props.apps) {
    return props.tag;
  }
  const name = props.apps[0].metadata.tags?.find((t) => slugify(t) === props.tag);
  return name ?? props.tag;
})();

const crumbs = [
  {
    name: 'garden',
    routeTo: '/',
  },
  {
    name: 'tags',
    routeTo: '/tags',
  },
  {
    name: tag,
  },
];

const colorThemeClass = `collection-color-theme--${props.tag}`;

// compute randomized header image

const bgImages = [Hero1, Hero2, Hero3, Hero4, Hero5];

const randomHeaderImage = computed(() => {
  return bgImages[Math.floor(Math.random() * bgImages.length)];
});

const route = useRoute();

const jsonSchema = {
  '@context': 'http://www.schema.org',
  '@type': 'Organization',
  name: `${tag || ''}`,
  description: `View all Clovyr apps tagged with ${tag || 'tag'}.`,
  url: `https://clovyr.app${route.fullPath}`,
};

useHead({
  title: `${tag} tag | Clovyr`,
  link: [{ rel: 'canonical', href: `https://clovyr.app${route.fullPath}` }],
  meta: [
    {
      name: 'description',
      content: `View all Clovyr apps tagged with ${tag || 'tag'}.`,
    },
    {
      property: 'og:title',
      content: `${tag} | Clovyr`,
    },
    {
      property: 'og:description',
      content: `View all Clovyr apps tagged with ${tag || 'tag'}.`,
    },
    {
      property: 'og:url',
      content: `https://clovyr.app${route.fullPath}`,
    },
    {
      property: 'og:image',
      content: randomHeaderImage.value,
    },
    {
      property: 'og:image:alt',
      content: `Tag image for ${tag} tag`,
    },
  ],
  script: [
    {
      type: 'application/ld-json',
      children: JSON.stringify(jsonSchema),
    },
  ],
});
</script>

<template>
  <div :class="colorThemeClass" class="collection">
    <Hero class="tag-page-hero" subheading-text-shade="dark">
      <template #heading
        >{{ apps.length }} App{{ apps.length > 1 ? 's' : '' }} tagged with &ldquo;{{
          tag
        }}&rdquo;</template
      >

      <template #top>
        <Breadcrumbs :crumbs="crumbs" />
      </template>

      <template #background>
        <HeroBackground :background-image="randomHeaderImage" :gradient="16" />
      </template>
    </Hero>

    <AppSummary v-for="(app, idx) in props.apps" :key="idx" :app="app" />
  </div>
</template>

<style scoped lang="scss">
.tag-page-hero {
  :deep(.hero__middle) {
    width: 100%;

    .hero__heading {
      @include font-size(22);

      line-height: 1.3;

      @include media-breakpoint-up(md) {
        @include font-size(42);
      }
    }
  }

  :deep(.hero-background) {
    img {
      aspect-ratio: 4/1;
    }
  }
}
</style>

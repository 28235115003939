import type { RouteRecordRaw } from 'vue-router';

import type { Bundle } from '@clovyr/pollen';
import type { Manifest } from '@clovyr/pollen/manifest';

import { useEventBus } from '../stores/event_bus';
import { usePollenStore } from '../stores/pollen_store';
import FramedDeploymentVue from '../views/FramedDeployment.vue';

import {
  appExists,
  appFromRoute,
  collectionExists,
  extractUserFlagTokens,
  instantLaunchApp,
  tagExists,
  validDeployment,
} from './guards';

/**
 * Props helper for converting app ID to app object
 *
 * @param param
 * @returns
 */
function appToProps(route) {
  return { app: appFromRoute(route) };
}

function closeDrawer() {
  const { eventBus } = useEventBus();
  eventBus.emit('drawer:close');
}

export const gardenRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Garden',
    meta: {
      layout: 'main',
      title: 'Garden',
      description: 'View what Clovyr has to offer in our app garden.',
      canonical: 'https://clovyr.app/',
    },
    beforeEnter: [closeDrawer],
    // TODO: add support for manual chunks via vite-plugin-webpackchunkname
    component: () => import(/* webpackChunkName: "garden" */ '../views/Garden.vue'),
  },
  {
    path: '/collections/:id',
    alias: '/collection/:id',
    name: 'Collection',
    meta: {
      layout: 'main',
    },
    beforeEnter: collectionExists,
    component: () => import(/* webpackChunkName: "garden" */ '../views/Collection.vue'),
    props: (route) => {
      const { garden } = usePollenStore();
      const bundle: Bundle | undefined = garden
        .getBundles()
        .find((cat) => cat.id === route.params.id);
      const apps = bundle ? garden.getAppsByBundle(bundle.id) : [];
      return { collection: bundle, apps };
    },
  },
  {
    path: '/tags/:name',
    alias: '/tag/:name',
    name: 'Tag',
    meta: {
      layout: 'main',
    },
    beforeEnter: tagExists,
    component: () => import(/* webpackChunkName: "appDetail" */ '../views/Tag.vue'),
    props: (route) => {
      const { garden } = usePollenStore();
      const tag = Array.isArray(route.params.name) ? route.params.name[0] : route.params.name;
      let apps = tag === 'all' ? garden.getApps() : garden.getAppsByTag(tag);

      if (tag === 'fedimint') {
        // custom sort for fedimint (don't want alpha-sort of IDs on tag page)
        const sorted: Manifest[] = [];
        const p = (id: string) => {
          const a = apps.find((app) => app.metadata.id === id);
          if (a) {
            sorted.push(a);
          }
        };
        p('fedimint-guardian');
        p('fedimint-gateway');
        p('fedimint-chat');
        p('fedimint');
        apps = sorted;
      }

      return { tag, apps };
    },
  },
  {
    path: '/tags',
    alias: '/tag',
    name: 'Tags',
    meta: {
      layout: 'main',
      title: 'Clovyr Tags',
      description: 'View all of Clovyr app tags.',
      canonical: 'https://clovyr.app/tags',
    },
    component: () => import(/* webpackChunkName: "tags" */ '../views/Tags.vue'),
  },
  {
    path: '/code',
    alias: '/apps/clovyr-code',
    name: 'AppsClovyrCode',
    meta: {
      layout: 'main',
      title: 'Clovyr Code',
      description:
        "Clovyr Code is VSCode in your browser. It connects and communicates directly with your git host, without sharing any credentials or information with anyone else, including us. The current beta creates ephemeral, private VPS instances on wNext (hosting by Clovyr) with no sign-up required. We'll be releasing the ability to target any cloud or on-prem instance soon. Get that cozy feeling of developing locally on a machine you control, with the freedom to never worry about setting up project-specific environments again.",
      canonical: 'https://clovyr.app/apps/clovyr-code',
    },
    props: (route) => ({ err: route.query.err }),
    component: () => import(/* webpackChunkName: "code" */ '../views/custom/ClovyrCodeGarden.vue'),
  },
  {
    path: '/apps/code-chia',
    alias: ['/app/code-chia', '/apps/chialisp', '/app/chialisp'],
    name: 'AppsInstantChia',
    meta: {
      layout: 'main',
      title: 'Instantly launch code-chia',
      description:
        'Tailormade for Chialisp developers, these environments are pre-built with all the necessary Chia command-line tools and a pre-synced full node on the Chia testnet.',
      canonical: 'https://clovyr.app/apps/code-chia',
    },
    props: (route) => ({ err: route.query.err }),
    component: () => import(/* webpackChunkName: "chia" */ '../views/custom/InstantChiaGarden.vue'),
  } as RouteRecordRaw,
  {
    path: '/apps/:id',
    alias: ['/app/:id', '/apps/:publisher/:id'],
    name: 'AppDetail',
    meta: {
      layout: 'main',
    },
    beforeEnter: appExists,
    component: () => import(/* webpackChunkName: "appDetail" */ '../views/AppDetail.vue'),
    props: appToProps,
    children: [
      {
        path: 'launch',
        name: 'AppLauncher',
        component: () =>
          import(/* webpackChunkName: "appDetail" */ '../views/AppLauncher/AppLauncher.vue'),
        props: appToProps,
        beforeEnter: [instantLaunchApp],
      },
    ],
  },
  {
    path: '/library',
    name: 'Library',
    meta: {
      layout: 'main',
      disableSSR: true,
      title: 'Library',
      description: 'View or manage your Clovyr apps in the library.',
      canonical: 'https://clovyr.app/library',
    },
    beforeEnter: [closeDrawer],
    component: () => import(/* webpackChunkName: "appDetail" */ '../views/Library.vue'),
  },
  {
    path: '/library/accept-share/:itemID',
    name: 'LibraryAcceptShare',
    meta: {
      layout: 'main',
      disableSSR: true,
      title: 'Library',
      description: 'An app was shared with you.',
      canonical: 'https://clovyr.app/library/accept-share',
    },
    beforeEnter: [closeDrawer],
    props: (route) => ({
      shareAppId: route.query.appID,
      shareAppName: route.query.appName,
      itemID: route.params.itemID,
      shareSecret: route.hash.slice(1),
    }),
    component: () => import(/* webpackChunkName: "appDetail" */ '../views/Library.vue'),
  },
  {
    path: '/library/:id',
    name: 'LibraryAppDetail',
    meta: {
      layout: 'main',
      disableSSR: true,
    },
    beforeEnter: [validDeployment, closeDrawer],
    props: true,
    component: () =>
      import(/* webpackChunkName: "appDetail" */ '../views/Library/LibraryAppDetail.vue'),
  },
  {
    path: '/library/:id/mail',
    name: 'LibraryAppMail',
    meta: {
      layout: 'main',
      disableSSR: true,
    },
    beforeEnter: [validDeployment, closeDrawer],
    props: true,
    component: () => import(/* webpackChunkName: "appDetail" */ '../views/Library/AppMail.vue'),
  },
  {
    path: '/library/:id/troubleshoot',
    name: 'LibraryAppConsoleLogs',
    meta: {
      layout: 'library-detail',
      disableSSR: true,
    },
    beforeEnter: [validDeployment, closeDrawer],
    props: true,
    component: () => import(/* webpackChunkName: "appDetail" */ '../views/Library/ConsoleLogs.vue'),
  },
  {
    // for viewing clovyr code in an iframe
    // in the future may include other apps
    path: '/view/:deploymentName',
    name: 'ViewDeployment',
    meta: {
      layout: 'main',
    },
    props: true,
    component: FramedDeploymentVue,
  },

  {
    path: '/open/:deploymentName',
    name: 'OpenDeployment',
    redirect: (to) => {
      const fqdn = to.params.fqdn || to.query.fqdn;
      if (!fqdn) {
        return { path: '/' };
      }
      window.location.href = `https://${fqdn}`;
      return { path: '/' };
    },
  },

  {
    // special internal route for showing all apps
    path: '/all',
    name: 'AllApps',
    component: () => import(/* webpackChunkName: "appDetail" */ '../views/AllApps.vue'),
    meta: {
      layout: 'main',
      flags: ['internal'],
    },
  },

  {
    // internal route for enabling various feature flags via token
    path: '/preview',
    name: 'Preview',
    redirect: (to) => {
      extractUserFlagTokens(to);
      const path = (to.query.redir as string) || '/';
      return { path, query: {} };
    },
  },
];

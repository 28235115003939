<script setup lang="ts">
import type { PublisherAppFile } from '@clovyr/pollen';

import Modal from '@/components/elements/Modal.vue';
import TrashIcon from '@/components/icons/TrashIcon.vue';
import type { PublisherAppFileNew } from '@/stores/edit_store';

import FilePill from './FilePill.vue';

const props = defineProps<{
  file: PublisherAppFile;
}>();

const emit = defineEmits(['delete-file']);

const showDeleteModal = ref<boolean>(false);

const label = ref(props.file.name);

async function onClick() {
  const copy = `/tmp/${props.file.name}`;
  void navigator.clipboard.writeText(copy).then(() => {
    // success
    label.value = 'copied!';
    setTimeout(() => {
      label.value = props.file.name;
    }, 2000);
  });
}

function confirmDeleteFile() {
  // eslint-disable-next-line no-underscore-dangle
  emit('delete-file', (props.file as PublisherAppFileNew)._id || props.file.id);
  showDeleteModal.value = false;
}
</script>

<template>
  <FilePill @click="onClick"
    >{{ label }}<TrashIcon @click.stop.prevent="showDeleteModal = true"
  /></FilePill>

  <Modal
    v-if="showDeleteModal"
    theme="transparent"
    size="narrow"
    :has-icon="true"
    heading-text="Are you sure?"
    @close="showDeleteModal = false"
  >
    <template #body>
      Are you sure you want to delete <b class="file-name">{{ file.name }}</b
      >?
    </template>

    <template #controls-right>
      <div class="button-group">
        <Button label="No" :reversed="true" @click="showDeleteModal = false" />
        <Button label="Yes" @click="confirmDeleteFile" />
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.file-name {
  color: color(action, primary);
}
:deep(.trash-icon) {
  stroke: color(grey, primary);
  height: 1.5rem;
  &:hover {
    stroke: color(action, primary) !important;
  }
}
.button-group {
  margin: auto;
}
</style>

import ClovyrSchemaJSON from '@clovyr/garden/clovyr.schema?raw';

/**
 * Create a new schema for only the given subsection of the Clovyr schema.
 *
 * @param {string} name
 * @returns
 */
export function subSchema(name) {
  const ClovyrSchema = JSON.parse(ClovyrSchemaJSON);
  const def = ClovyrSchema.definitions[name];
  const d = def?.allOf?.[1];
  if (!(d || def)) {
    return ClovyrSchemaJSON;
  }
  const s = { ...ClovyrSchema };
  s.properties = (d || def).properties;
  s.required = (d || def).required;
  return s;
}

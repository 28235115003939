<!-- eslint-disable prettier/prettier -->
<script setup lang="ts">
import { AppLogo, type Logo } from '@clovyr/bed';
import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import { DeploymentState } from '@clovyr/pollen';
import { type PaymentMethod, SubscriptionStatus } from '@clovyr/pollen/subscription';
import type { UserSubscription } from '@clovyr/pollen/types/UserSubscription';
import type { UserSubscriptionItem } from '@clovyr/pollen/types/UserSubscriptionItem';

import SettingOption from '@/components/blocks/SettingOption.vue';
import Modal from '@/components/elements/Modal.vue';
import FieldInfoIcon from '@/components/icons/FieldInfoIcon.vue';
import { useAppResume } from '@/composables/useAppResume';
import { useAppStatus } from '@/composables/useAppStatus';
import { usePayments } from '@/composables/usePayments';
import { useEventBus } from '@/stores/event_bus';

interface HostProps {
  title: string;
}

interface AppSubscriptionProps {
  id?: number | string;
  name: string;
  logo?: Logo;
  host?: HostProps;
  subscription?: UserSubscription;
  subscriptionItem?: UserSubscriptionItem;

  term?: string; // enum TermDefinition, e.g. minute, hour, week, month, year
}

const props = withDefaults(defineProps<AppSubscriptionProps>(), {
  term: 'month',
});

const cancelSubscriptionModal = ref(false);

const deleteSubscription = () => {
  // delete subscription
};

// declaring outside block scope to use elsewhere
const deploymentID = computed(() => props.id as string);
const { deployment, sub, subItem, trialTimeRemaining, doUpgrade, trialExpired, isTrial } =
  useAppStatus(
    // FIXME: don't use this component for non-apps, only accept deployment ID in props.id
    deploymentID as Ref<string>,
  );
const payments = usePayments();
const { loadInvoices, isStripe } = payments;
const { eventBus } = useEventBus();

// Upgrade subscription - same as clicking 'upgrade' on app detail
const subscriptionAddPayment = async () => {
  if (!props.id) {
    // not an app subscription trial which can be upgraded
    return;
  }
  if (!props.subscription?.subscription_provider || !props.subscription.payment_captured_at) {
    eventBus.emit('modal:payment:show', {
      id: deploymentID.value as string,
      name: deployment.value.appName,
      sub: sub.value!,
      subItem: subItem.value!,
    });
    return;
  }

  // upgrade with previous payment method
  if (
    await doUpgrade(
      deploymentID.value as string,
      props.subscription,
      props.subscriptionItem!,
      props.subscription.subscription_provider as PaymentMethod,
    )
  ) {
    void useAppResume(deploymentID).wakeUpInstance();
  }

  if (props.subscription.subscription_provider === 'opennode') {
    // refresh invoices if we're using opennode
    void loadInvoices();
  }
};

const renewSubscription = () => {
  // TODO: renew subscription?
};

const cancelSubscription = () => {
  cancelSubscriptionModal.value = true;
};

const handleCancelSubscription = async () => {
  if (props.subscription && props.subscriptionItem && props.subscription.payment_captured_at) {
    await payments.cancelSubscriptionItem(props.subscriptionItem, 'end_of_period');
    await payments.getSubscriptionItem(
      props.subscriptionItem.subscription_id,
      props.subscriptionItem.id,
      true,
    );
    cancelSubscriptionModal.value = false;
  }
};

const subscriptionExpiring = ref(false);

const price = computed(() => {
  if (!props.subscriptionItem?.price) {
    return 0;
  }
  return Number.parseFloat(props.subscriptionItem.price.replace('$', ''));
});

const subscriptionOption = computed(() => {
  if (!props.subscriptionItem) {
    return [
      {
        label: 'n/a',
      },
    ];
  }
  // TODO: new trial button should say 'Upgrade' & 'Manage' depending on auth state, but need to clarify if there will be a trial management modal or what that will look like
  switch (props.subscriptionItem.status) {
    case SubscriptionStatus.Expiring:
    case SubscriptionStatus.Trial:
      // Only display 'Upgrade' button if instance is running
      if (deployment.value.state === DeploymentState.Running) {
        return [
          {
            label: 'Upgrade',
            eventName: 'addPayment',
            reversed: true,
          },
        ];
      }

      return [];
    case SubscriptionStatus.UpgradeRequested:
      subscriptionExpiring.value = true;
      return [
        {
          label: 'Upgrade Requested',
          disabled: true,
        },
      ];
    case SubscriptionStatus.PaymentInProgress:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (props.subscriptionItem.trial_end_at!.valueOf() < Date.now()) {
        // trial is expired, show cancel button?
        subscriptionExpiring.value = false;
        return [
          {
            label: 'Cancel',
            eventName: 'cancelSubscription',
          },
        ];
      }

      subscriptionExpiring.value = true;

      return [
        {
          label: 'Upgrade',
          eventName: '',
          reversed: true,
        },
      ];
    case SubscriptionStatus.Expired:
      subscriptionExpiring.value = true;

      return [
        {
          label: 'Delete',
          eventName: 'deleteSubscription',
          reversed: true,
        },
      ];
    case SubscriptionStatus.Renew:
      return [
        {
          label: 'Renew',
          eventName: 'renewSubscription',
          reversed: true,
        },
      ];
    case SubscriptionStatus.CancelRequested:
      return [
        {
          label: 'Cancel',
          disabled: true,
        },
      ];
    case SubscriptionStatus.Canceled:
      return [
        {
          label: 'Canceled',
          disabled: true,
        },
      ];
    default:
      return [
        {
          label: 'Cancel',
          eventName: 'cancelSubscription',
        },
      ];
  }
});

// format app price
const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

// app logo
const logo = computed((): Logo => {
  return {
    logoUrl: props.logo?.logoUrl,
    logoBackgroundColor: props.logo?.logoBackgroundColor,
  };
});

const isTrialExpiring = computed(() => {
  if (trialTimeRemaining.value.slice(0, 3) < '3') {
    return true;
  }
  return false;
});

// FIXME: need to clarify logic around what happens when a user does not have a  payment method on file, what tooltip to display?
const tooltipString = computed(() => {
  let string = '';

  // Trial not expired, user not yet subscribed
  if (isTrial.value && !trialExpired.value && !props.subscription?.subscription_provider) {
    string = `You have ${trialTimeRemaining.value} days remaining on your free trial. `;
  }
  // Trial not expired, user is already subscribed
  if (isTrial.value && props.subscription?.subscription_provider === 'opennode') {
    string = `You have ${trialTimeRemaining.value} days remaining on your free trial. App trials are automatically upgraded to the paid subscription after the trial period expires. On the day of the upgrade, you will be charged a prorated fee to support the app from that day until your next subscription invoice date.`;
  }
  if (isTrial.value && trialExpired.value) {
    // Trial expired
    string = `Your trial has expired`;
  }

  // subscription expired
  //  FIXME: should only be used in OpenNode cases?
  if (!isTrial.value && subscriptionExpiring.value) {
    string = `Your subscription has expired.`;
  }

  return string;
});

// FIXME: move this code to useDetectUserOS composable once Sharing UI branch is merged
const isTablet = ref(false);
const isMobile = ref(false);
const isDesktop = ref(false);
const { userAgent } = navigator;

// Define patterns for different device types
const mobilePattern = /Mobile|Android|iPhone|iPod|BlackBerry|Windows Phone/i;
const tabletPattern = /iPad|Android|Tablet/i;

// Check if the user agent matches mobile or tablet pattern.
if (mobilePattern.test(userAgent)) {
  if (tabletPattern.test(userAgent)) {
    // If it matches both mobile and tablet, it's likely a tablet
    isTablet.value = true;
  } else {
    isMobile.value = true;
  }
} else {
  isDesktop.value = true;
}

const trimmedName = computed(() => {
  if (isDesktop.value && props.name.length > 20) {
    return `${props.name.slice(0, 19)}...`;
  }
  if (isTablet.value && props.name.length > 10) {
    return `${props.name.slice(0, 9)}...`;
  }
  if (isMobile.value && props.name.length > 20) {
    return `${props.name.slice(0, 19)}...`;
  }
  return props.name;
});
</script>

<template>
  <SettingOption
    @deleteSubscription="deleteSubscription"
    @renewSubscription="renewSubscription"
    @addPayment="subscriptionAddPayment"
    @cancelSubscription="cancelSubscription"
    :buttons="isStripe && !isTrial ? [] : subscriptionOption"
  >
    <template #left>
      <div class="subscription-item">
        <AppLogo class="subscription-logo" size="small" :logo="logo" />

        <div class="subscription-content">
          <div class="subscription-title" />

          <div class="subscription-host">
            <div class="host-info">
              <h4>{{ trimmedName }}</h4>
              <div>{{ formatPrice.format(price as number) }}/mo</div>
            </div>
            <div class="trial-time" v-if="isTrial">
              <p v-if="!isTrialExpiring">Trial ends in {{ trialTimeRemaining }}</p>
              <p v-if="isTrialExpiring">Trial has expired.</p>

              <Tooltip
                class="icon-tooltip"
                placement="bottom"
                arrow
                theme="narrow"
                :content="tooltipString"
                v-if="(isTrial && isTrialExpiring) || subscriptionExpiring"
              >
                <FieldInfoIcon />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SettingOption>

  <Modal
    v-if="cancelSubscriptionModal"
    theme="transparent"
    :size="'narrow'"
    :has-icon="true"
    heading-text="Are you sure?"
    @close="cancelSubscriptionModal = false"
  >
    <template #body>
      Your subscription will be canceled at the end of the current billing period. If you want to
      cancel immediately, delete the app from your library.
    </template>

    <template #controls-right>
      <div class="button-group">
        <Button label="No" :reversed="true" @click="cancelSubscriptionModal = false" />
        <Button label="Yes" @click="handleCancelSubscription" />
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.subscription-item {
  display: flex;
  align-items: center;

  .subscription-logo {
    filter: drop-shadow(0 2px 10px transparentize(color(black), 0.75));
  }

  .subscription-content {
    margin-left: 1rem;

    .subscription-title {
      @include font-size(16);

      color: color(white);
      font-weight: 600;
      line-height: 1.5;
    }

    .subscription-host {
      display: flex;
      flex-direction: column;
      color: color(grey, secondary);
      @include font-size(16);

      .host-info {
        display: flex;

        h4 {
          margin-right: 0.25rem;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .trial-time {
        display: flex;
        align-items: center;

        .icon-tooltip {
          margin-left: 0.25rem;
        }
      }
    }
  }
}

:deep(.setting-option__inner) {
  padding: 0 0.5rem 0.5rem 0.5rem !important;
}
:deep(.setting-option__right) {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .setting-option__buttons {
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }

    .button {
      &.option-button {
        background: transparentize(color(negative), 0.6);
        color: color(bad, secondary);
        flex-grow: 0;
        padding-left: 3rem;
        padding-right: 3rem;

        &:hover {
          background: transparentize(color(negative), 0.3);
        }
      }
    }
  }
}
.button-group {
  display: flex;
}
</style>

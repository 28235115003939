import { ComputeProviderName } from '@clovyr/pollen';
import { allHosts } from '@clovyr/pollen/fixtures/hosts';
import { sleep } from '@clovyr/pollen/util/sleep';

const initialProvider = 'AWS';

export const useCloudType = () => {
  const cloudName = ref(initialProvider);

  const providers: string[] = allHosts
    .filter((h) => h.id !== ComputeProviderName.AWSEC2)
    .map((h) => h.title);

  providers.unshift(initialProvider);

  let cloudIdx = 0;

  async function typeOut(str: string) {
    for (let i = 0; i <= str.length; i++) {
      cloudName.value = str.substring(0, i);
      // eslint-disable-next-line no-await-in-loop
      await sleep(30);
    }
  }

  let cloudTimer: NodeJS.Timeout;

  onMounted(() => {
    cloudTimer = setInterval(() => {
      cloudIdx++;
      if (cloudIdx > providers.length - 1) {
        cloudIdx = 0;
      }
      void typeOut(providers[cloudIdx]);
    }, 3000);
  });

  onBeforeUnmount(() => {
    if (cloudTimer) {
      clearInterval(cloudTimer);
    }
  });

  return {
    cloudName,
  };
};

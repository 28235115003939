<script setup lang="ts">
import QrcodeVue from 'qrcode.vue';

import TextInputBlock from '@clovyr/bed/components/blocks/TextInputBlock.vue';
import ClovyrLogo from '@clovyr/bed/components/icons/ClovyrLogo.vue';

import SecretKeyWarning from '../../components/elements/SecretKeyWarning.vue';

const props = defineProps<{ email; secretKey; formattedSecretKey }>();

const qrCodeURL = computed(() => {
  const emailParam = encodeURIComponent(props.email);
  const secretKeyParam = encodeURIComponent(props.secretKey);

  let origin = '';

  if (!import.meta.env.SSR && typeof window !== 'undefined') {
    origin = window.location.origin;
  }

  return `${origin}/login#email=${emailParam}&secret=${secretKeyParam}`;
});

const currentDate = (() => {
  const date = new Date();
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${month}/${day}/${year}`;
})();

// password input is not visible when emtpy
const password = ' ';
</script>

<template>
  <section class="pdf-container">
    <div class="logo-container">
      <div class="clovyr-logo">
        <ClovyrLogo />
      </div>
    </div>
    <div class="main-container">
      <div class="pdf-header">
        <p>Recovery Kit</p>
        <span
          >Created for:&nbsp;&nbsp;&nbsp;<span></span><b>{{ email }} on {{ currentDate }}</b></span
        >
      </div>
      <div class="pdf-body">
        <div class="column-left">
          <div class="headline">Account Information</div>
          <div class="form">
            <TextInputBlock
              v-if="email"
              type="text"
              shape="rounded"
              label="Email"
              class="formfield"
              disabled
              :value="email"
            />

            <TextInputBlock
              v-if="formattedSecretKey"
              type="secret-key"
              shape="rounded"
              label="Secret Key"
              class="formfield formfield-textarea"
              disabled
              :value="formattedSecretKey"
            />

            <TextInputBlock
              v-if="email && formattedSecretKey"
              type="text"
              shape="rounded"
              label="Password"
              class="formfield"
              disabled
              :value="password"
            />
          </div>

          <SecretKeyWarning />

          <div class="key-instructions">
            <div class="clovyr-green headline">What to do:</div>
            <ol>
              <li>
                1. Print or download this page and store somewhere safe (USB key or external drive).
              </li>
              <li>2. Fill out your account password.</li>
              <li>3. Connect your personal device by scanning the QR code below.</li>
              <li>
                <div class="qr-container">
                  <div class="qr-left">
                    <div class="text">
                      Use your personal device to scan this QR code and log in.
                    </div>
                  </div>
                  <div class="qr-right">
                    <qrcode-vue
                      class="clovyr-auth-qrcode"
                      :value="qrCodeURL"
                      :size="100"
                      background="#ffffff"
                      foreground="#000000"
                      level="Q"
                      render-as="svg"
                      :margin="1"
                      role="img"
                    />
                  </div>
                </div>
              </li>
              <li>4. Store your kit in secure place where you can find it, e.g. fireproof box.</li>
              <li>
                5. To restore data or add new devices, visit
                <span class="clovyr-green">clovyr.app/settings</span>.
              </li>
            </ol>
          </div>
        </div>
        <div class="column-right">
          <div>
            Your Clovyr Account password protects your account details with Clovyr, like
            notifications and payment settings. For help resetting your password or accessing your
            account, please email support@clovyr.io.
            <!-- If you forget your password, visit
            <span class="clovyr-green">clovyr.app/account/forgotpassword</span> to reset it. -->
          </div>
          <div>
            Your secret key protects data related to your Clovyr apps and helps you keep your data
            privately synced across multiple devices.
          </div>
        </div>
      </div>
      <div class="pdf-footer">
        <div class="footer-left">
          Need Help?
          <span>Contact us <span class="clovyr-green">support@clovyr.io</span></span>
        </div>
        <!-- <div class="footer-right">
          Read more tips
          <span class="clovyr-green">clovyr.io/security</span>
        </div> -->
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
@page {
  size: portrait;
  margin: 5%;
}

.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  :deep(.textinput-block__input) {
    &.formfield-textarea {
      height: auto;
    }
  }

  .logo-container {
    display: flex;
    justify-content: center;
    padding: 15px;

    .clovyr-logo {
      height: 20px;
    }
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #221a33;
    background-color: #fff;

    .clovyr-green {
      color: #14a56f;
    }

    .headline {
      padding: 0 0 5px;
      margin-left: 35%;
      font-weight: 600;
    }

    .pdf-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      border-bottom: 1.5px solid #ada6b74d;

      @media print {
        width: calc(100% - 20px);
      }

      p {
        padding: 50px 0 5px;
        font-family: 'Work Sans';
        font-size: 40px;
        font-weight: 500;
        line-height: 42px;
        color: #14a56f;
        letter-spacing: -0.03em;

        @media print {
          font-size: 20px;
          padding-top: 10px;
          line-height: 1;
        }
      }

      span {
        padding: 0 0 10px;
        font-size: 12px;
        line-height: 18px;
        color: #453960;
        letter-spacing: 0;
      }
    }

    .pdf-body {
      display: flex;
      flex-direction: row;
      padding: 3.5% 5%;
      color: black;
      background-color: #fff;

      @media print {
        padding: 10px;
      }

      .column-left {
        display: flex;
        flex-direction: column;
        width: 65%;
        padding: 0 30px 0 0;

        @media print {
          width: 70%;
        }

        .form {
          padding: 20px;
          background-color: #3832481f;
          border: 1px solid #221a331a;
          border-radius: 14px;

          @media print {
            padding: 10px;
          }

          .formfield {
            padding: 5px;
          }

          @media print {
            :deep(.textinput-block) {
              margin-bottom: 0;
            }
          }

          :deep(.textinput-block__input:not(:placeholder-shown)) {
            color: #453960 !important;
            background-color: #fff;
            border: 1px solid #847398;
          }

          :deep(.textinput-block__label) {
            color: #847398;
          }
        }

        .key-instructions {
          margin: 30px 0 0;

          ol {
            :first-child {
              margin: 0;
            }

            li {
              margin: 10px 0;
            }
          }

          .qr-container {
            display: flex;
            max-width: 280px;
            padding: 10px 15px;
            margin: 0 0 0 15px;
            background-color: #14a56f0d;
            border: 1.5px solid #14a56f;
            border-radius: 16px;

            .clovyr-auth-qrcode {
              fill: color(white);
              stroke: transparent;
            }

            .qr-left {
              width: 50%;

              .text {
                font-weight: 400;
              }
            }

            .qr-right {
              padding-left: 10%;
            }
          }
        }
      }

      .column-right {
        width: 35%;
        padding: 15px 0 0;

        @media print {
          width: 30%;
        }

        :last-child {
          padding: 15px 0 0;
        }
      }
    }

    .pdf-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      padding-top: 1rem;
      padding-bottom: 5%;
      margin-left: auto;
      margin-right: auto;
      color: black;
      background-color: #fff;
      border-top: 1.5px solid #ada6b74d;

      @media print {
        width: calc(100% - 20px);
        padding-bottom: 0;
      }

      span {
        margin-left: 10px;
      }
    }
  }
}
</style>

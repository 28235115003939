<script setup lang="ts">
import Button from '@clovyr/bed/components/controls/Button.vue';
import NostrLogo from '@clovyr/bed/components/icons/NostrLogo.vue';
import { useMatomo } from '@clovyr/bed/composables/useMatomo';
import { getAllQueryParams } from '@clovyr/pollen/http/qs';
import { AuthState } from '@clovyr/pollen/pollen';
import { AlreadySignedUpError } from '@clovyr/pollen/pollen/errors';

import { useNostr } from '@/composables/useNostr';

import Banner from '../../../components/blocks/Banner.vue';
import FormField from '../../../components/blocks/FormField.vue';
import PasswordFormField from '../../../components/blocks/PasswordFormField.vue';
import FormWrapper from '../../../components/forms/FormWrapper.vue';
import { usePollenStore } from '../../../stores/pollen_store';

const props = defineProps<{
  isPubFlow: string;
}>();

const emit = defineEmits<{
  (e: 'authForm', form: string): void;
  (e: 'close'): void;
}>();

const { pollen } = usePollenStore();

const handleLogin = () => {
  emit('authForm', 'showLogin');
};

const alreadySignedUp = ref(false);

const { isNostrAvailable } = useNostr();

const onSubmit = async (values) => {
  try {
    await pollen.signUp(values.email, values.password);
    // matomo track event
    const matomo = useMatomo();
    matomo.trackEvent('Auth', 'Signup', 'Password');

    const query = getAllQueryParams();
    const redir = query.get('redir');
    if ((redir && redir.includes('publishers')) || props.isPubFlow) {
      // skip recovery kit
      emit('close');
    } else {
      emit('authForm', 'showRecoveryKitDownload');
    }
    // no confirmation screen for now, just close the dialog
  } catch (err) {
    if (err instanceof AlreadySignedUpError) {
      alreadySignedUp.value = true;
      return;
    }
    throw err;
  }
};

const signUpWithNostr = async () => {
  emit('authForm', 'showNostrEmailForm');
};
const schema = inject<Record<'email' | 'password', string> | undefined>('validationSchema');
</script>

<template>
  <div class="form-wrapper form-auth">
    <div class="form-heading">Sign Up</div>
    <div class="form-description sub-heading">
      Please provide the following information to create your account. No additional details are
      required.
    </div>
    <banner v-if="isPubFlow" status="info" class="banner">
      In order to access the Publisher Portal, you must first register for a Clovyr account.
    </banner>

    <Banner status="critical" v-if="alreadySignedUp">
      You have already signed up for an account on this device, please
      <span class="button-inline button-primary" @click="handleLogin">log in</span>
      instead.
    </Banner>

    <div class="form">
      <FormWrapper submitLabel="Create Account" :validation-schema="schema" @submit="onSubmit">
        <template v-slot:fields>
          <FormField name="email" label="Email" shape="cubed" />
          <PasswordFormField
            name="password"
            label="Password"
            type="password"
            shape="cubed"
            reveal
            password-validate
          />
        </template>
      </FormWrapper>

      <Button
        v-if="isNostrAvailable"
        class="button nostr-button"
        label="Sign up with Nostr"
        @click="signUpWithNostr"
      >
        <NostrLogo />
      </Button>

      <div class="form-callout">
        <span>Have an account?</span
        ><span class="button-inline button-primary" @click="handleLogin">Log in</span>
        <span v-if="isNostrAvailable" class="button-inline button-secondary" @click="handleLogin"
          >Log in with Nostr</span
        >
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.form-wrapper {
  max-width: 30rem;
}
.form-callout {
  > * {
    margin-left: 0.25rem;
  }
}
.sub-heading {
  max-width: 35rem;
}
.button {
  height: 2.85rem;
}

.nostr-button {
  background-color: #995ada;
  color: white;
  width: 100%;
  margin-top: 1rem;

  svg {
    width: 3rem;
    height: 2rem;
  }

  &:hover {
    background-color: color(active, primary);
  }
}

:deep(.textinput-block--password-validate) {
  padding-bottom: unset;
}
</style>

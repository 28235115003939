import { createRandomKey } from '@clovyr/pollen/crypto';
import { WNextDeployment } from '@clovyr/pollen/deployment/wnext';
import {
  CLOVYR_ACCESS_KEY,
  CLOVYR_BACKUP_PASSWORD,
  CLOVYR_S3_URL,
  CLOVYR_SECRET_KEY,
} from '@clovyr/pollen/manifest';
import { claimInstance, validateAuth, waitForInstance } from '@clovyr/pollen/remote_exec';
import { DeploymentState } from '@clovyr/pollen/types';

import { useDeploymentStore } from '@/stores/deployment_store';
import { useInstanceStore } from '@/stores/instance_store';
import { usePollenStore } from '@/stores/pollen_store';

import { useKeepalive } from './useKeepalive';

interface Loading {
  name: 'loading';
  msg: string;
}

interface Active {
  name: 'active';
  frameURL: string;
  allow: string;
}

interface Failed {
  name: 'failed';
  msg: string;
}

interface CookieWarning {
  name: 'cookie-warning';
  device: string;
  browser: string;
}

export type FrameState = Loading | Active | Failed | CookieWarning;

/**
 * Resume a Clovyr Code instance
 *
 * @param depName
 * @returns
 */
export function useInstanceResume(depName: string) {
  const deploymentStore = useDeploymentStore();
  const instanceStore = useInstanceStore();
  const pollenStore = usePollenStore();
  const wnextDeploymentAPI = new WNextDeployment();
  const { startKeepalive, stopKeepalive } = useKeepalive();

  const frameState = ref<FrameState>({ name: 'loading', msg: 'Loading...' });

  function fail(msg: string) {
    frameState.value = { name: 'failed', msg };
    console.warn(msg);
  }

  async function setActive(fqdn: string, configurekey: string, waitForRestore?: boolean) {
    if (waitForRestore) {
      // wait for restore to complete when resuming
      const readinessURL = `https://${fqdn}/_leaf/ready?type=restic-restore`;
      if (!(await waitForInstance(readinessURL, configurekey, 1000, 200))) {
        frameState.value = {
          name: 'failed',
          msg: 'Timed out waiting for instance',
        };
        return;
      }
    }

    const redirectURL = encodeURIComponent(`/?folder=/home/clovyr/git`);
    frameState.value = {
      name: 'active',
      frameURL: `https://${fqdn}/_leaf/login?token=${configurekey}&redir=${redirectURL}`,
      allow: `clipboard-read https://${fqdn} https://8080-${fqdn}; clipboard-write https://${fqdn} https://8080-${fqdn}`,
    };

    startKeepalive(depName, fqdn);
  }

  /**
   * Get deployment info from props/store
   */
  async function getDepInfo() {
    let fqdn: string | null | undefined;
    let isResumable = false;

    // handle weird urlencoding from urweb urls
    // https://github.com/urweb/urweb/commit/25792a154d53d515917c41256610a03a0a9de5f9
    const deploymentName = depName.replaceAll('.2D', '-');

    // look in pollen
    const dep = pollenStore.getDeploymentByInstanceName(deploymentName);
    if (dep) {
      fqdn = dep.fqdn;
      isResumable = !!dep.isResumable;
    } else {
      // legacy code-path. should not be used post-migration
      // look up fqdn by fetching deployment & instance
      const deployment = await deploymentStore.fetchByName(deploymentName);
      if (deployment) {
        const instance = await instanceStore.fetchByFQDN(deployment.lastInstanceFQDN);
        if (instance) {
          ({ fqdn } = instance);
        }
      }
    }

    return { fqdn, dep, isResumable, deploymentName };
  }

  /**
   * On page load/visibility change, check if instance is alive before showing code iframe.
   *
   * If not alive, automatically claims a fresh instance, restores backup onto it, and then shows the
   * iframe when ready.
   */
  async function resumeInstance() {
    const { fqdn, dep, isResumable, deploymentName } = await getDepInfo();

    if (!fqdn) {
      fail(`Unable to find fqdn for ${deploymentName}`);
      return;
    }

    if (!isResumable) {
      frameState.value = {
        name: 'active',
        frameURL: `https://${fqdn}/?folder=/home/clovyr/git`,
        allow: `clipboard-read https://${fqdn} https://8080-${fqdn}; clipboard-write https://${fqdn} https://8080-${fqdn};`,
      };
      return;
    }

    let configureKey: string;
    const existingInstance = pollenStore.getInstanceByFQDN(fqdn);
    if (existingInstance) {
      const isFirstClaim = dep?.createdAt && Date.now() - dep.createdAt < 90000; // if claimed less than 90 sec ago, shortcircuit some API hits
      const isAlive = isFirstClaim || (await wnextDeploymentAPI.keepalive(deploymentName, fqdn));
      if (isAlive) {
        await setActive(fqdn, existingInstance.configurekey!, false);
        return;
      }
      configureKey = existingInstance.configurekey!;
      await pollenStore.pollen.deleteInstance(fqdn);
    } else {
      configureKey = createRandomKey();
    }

    if (!dep) {
      fail(`Unable to find deployment named ${deploymentName} in storage`);
      return;
    }

    // ensure loader is visible now
    frameState.value = { name: 'loading', msg: 'Loading...' };

    // update internal state at at each step
    // before and after claiming

    // console.log('waking up', toRaw(dep));
    if (dep.state === DeploymentState.Waking) {
      configureKey = dep.keys.configureKey;
    } else {
      dep.state = DeploymentState.Waking;
      dep.keys.configureKey = configureKey;
      dep.accessList[0] = configureKey;
      await pollenStore.pollen.putDeployment(dep);
    }

    const newInstance = await wnextDeploymentAPI.wakeup(deploymentName);

    try {
      await claimInstance({
        host: newInstance.fqdn!,
        oldKey: newInstance.configurekey!,
        newKey: configureKey,
        resticConfig: {
          awsAccessKeyID: dep.appSettings[CLOVYR_ACCESS_KEY] as string,
          awsSecretAccessKey: dep.appSettings[CLOVYR_SECRET_KEY] as string,
          password: dep.appSettings[CLOVYR_BACKUP_PASSWORD] as string,
          repository: dep.appSettings[CLOVYR_S3_URL] as string,
        },
      });
    } catch (e: unknown) {
      if ((e as Error).message.includes('403')) {
        // try to validate auth using new key on 403 (we may have already claimed and gotten interrupted)
        if (
          !(await validateAuth({
            host: newInstance.fqdn!,
            key: configureKey,
          }))
        ) {
          throw e;
        }
      } else {
        throw e;
      }
    }

    newInstance.configurekey = configureKey;

    await pollenStore.pollen.putInstance(newInstance);
    dep.fqdn = newInstance.fqdn;
    dep.state = DeploymentState.Running;
    await pollenStore.pollen.putDeployment(dep);

    await setActive(newInstance.fqdn!, configureKey, true);
  }

  /**
   * When document becomes visible, ensure that our instance is still alive.
   */
  function onVisibilityChange() {
    if (document.visibilityState === 'visible' && frameState.value.name !== 'loading') {
      // frame can be active or failed, we'll retry it here
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      void resumeInstance();
    }
  }

  onUnmounted(() => {
    stopKeepalive(depName);
    document.removeEventListener('visibilitychange', onVisibilityChange);
  });

  async function startResume() {
    document.addEventListener('visibilitychange', onVisibilityChange);
    void resumeInstance();
  }

  return {
    frameState,
    startResume,
  };
}

import type { RouteLocationNormalizedGeneric, RouteRecordRaw } from 'vue-router';

import { usePollenStore } from '@/stores/pollen_store';

import { appFromRoute } from './guards';

/**
 * Props helper for converting app ID to app object
 *
 * @param param
 * @returns
 */
function appToProps(route: RouteLocationNormalizedGeneric) {
  const app = appFromRoute(route);
  const interstitial = route.params.interstitial === 'true';
  return { manifest: app, instantLaunchApp: app?.metadata.id, interstitial };
}

export const instantRoutes: RouteRecordRaw[] = [
  // custom instant pages
  {
    path: '/instant/code-chia',
    name: 'InstantChia',
    meta: {
      layout: 'instant',
      title: 'Instant launch code-chia',
    },
    props: { instantLaunchApp: 'code-chia' },
    component: () => import(/* webpackChunkName: "instant" */ '../views/custom/InstantChia.vue'),
  },
  {
    path: '/instant/code-fedimint',
    name: 'InstantCodeFedi',
    meta: {
      layout: 'instant',
      title: 'Instant launch code-fedimint',
    },
    props: { instantLaunchApp: 'code-fedimint' },
    component: () =>
      import(/* webpackChunkName: "instant" */ '../views/custom/InstantCodeFedi.vue'),
  },
  {
    path: '/instant/code-simbachain',
    name: 'InstantCodeSimbaChain',
    meta: {
      layout: 'instant',
      title: 'Instant launch code-simbachain',
    },
    props: { instantLaunchApp: 'code-simbachain' },
    component: () =>
      import(/* webpackChunkName: "instant" */ '../views/custom/InstantCodeSimbaChain.vue'),
  },

  // generic instant page for apps created via pub portal
  {
    path: '/instant/:publisher/:id',
    name: 'InstantCodePubApps',
    meta: {
      layout: 'instant',
      title: 'Instant launch clovyr-code',
    },
    beforeEnter: [
      (to) => {
        to.meta.title = `Instant launch ${to.params.id}`;
      },
      async () => {
        const { garden } = usePollenStore();
        return garden.isFullyLoaded();
      },
    ],
    props: appToProps,
    component: () => import(/* webpackChunkName: "instant" */ '../views/custom/InstantWrapper.vue'),
  },

  // generic instant page for any app
  {
    path: '/instant/:instantLaunchApp',
    name: 'InstantCode',
    meta: {
      layout: 'instant',
      title: 'Instant launch clovyr-code',
    },
    beforeEnter: [
      (to) => {
        to.meta.title = `Instant launch ${to.params.instantLaunchApp}`;
      },
      async () => {
        const { garden } = usePollenStore();
        return garden.isFullyLoaded();
      },
    ],
    props: true,
    component: () => import(/* webpackChunkName: "instant" */ '../views/custom/InstantWrapper.vue'),
  },

  // 3rd party launch routes
  {
    // show instant launch interstitial page
    path: '/develop/:id',
    alias: ['/develop/:publisher/:id'],
    name: 'LaunchDevelopApp',
    meta: {
      layout: 'instant',
      title: 'Instant launch clovyr-code',
    },
    beforeEnter: (to) => {
      to.meta.title = `Instant launch ${to.params.instantLaunchApp}`;
    },
    props: appToProps,
    component: () => import('../views/custom/InstantWrapper.vue'),
  },
  {
    // redirect to launch wizard
    path: '/run/:app',
    alias: ['/run/:publisher/:app'],
    name: 'LaunchRunApp',
    redirect: (route) => {
      if (route.params.publisher) {
        return { path: `/apps/${route.params.publisher}/${route.params.app}/launch` };
      }
      return { path: `/apps/${route.params.app}/launch` };
    },
  },
];

import { decodeBase64, encodeBase64 } from '@clovyr/hash-wasm/lib/util';

/**
 * Converts a base64 encoded string into an array of binary data.
 */
export function base64ToBytes(sBase64: string): Uint8Array {
  return decodeBase64(sBase64.replace(/[^A-Za-z0-9+/]/g, ''));
}

/**
 * Converts an array of binary data to a base64 encoded string.
 */
export function bytesToBase64(aBytes: Uint8Array): string {
  return encodeBase64(aBytes);
}

/**
 * Convert the given string to Base64. Properly handles UTF8.
 *
 * @param str
 * @returns
 */
export function strToBase64(str: string): string {
  return encodeBase64(new TextEncoder().encode(str));
}

/**
 * Convert the given Base64-encoded data back to a string. Properly handles UTF8.
 *
 * @param encoded
 * @returns
 */
export function base64ToStr(encoded: string): string {
  return new TextDecoder().decode(decodeBase64(encoded));
}

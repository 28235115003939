export const firewall = `
# Firewall
ufw --force enable \
  && ufw logging off \
  && ufw allow proto tcp from any to any port 22 \
  && ufw allow proto tcp from any to any port 80 \
  && ufw allow proto tcp from any to any port 443 \
  && ufw allow in on cni0 from 10.42.0.0/16 comment 'k3s' \
  && ufw allow proto tcp from 172.0.0.0/8 to 172.17.0.1/32 port 7755 comment 'traefik-leaf-auth'
`;

export const installKubernetes = `
# Kubernetes

## k3s
K3S_VER=v1.24.2%2Bk3s2
K3S_SHA=c3c816290461dc0e8e632e70ceb0f771382629860a851ae88545c816beb9797c
K3S_AIRGAP_SHA=c892a56fba9aeb440c40e273dc9ab5ef10cdb371f4cacd5b5f90568703dcc3ef
K3S_SVC_COMMIT=a629db023c565c48394fccb29c3b1f4d7c1541cf
K3S_SVC_SHA=4fc3fa8f79887b44018fddc38449ee6e7dc39851c177b4df77c5e1bee0840610

wget -q https://github.com/k3s-io/k3s/releases/download/\${K3S_VER}/k3s -O /usr/local/bin/k3s
sha256sum -c <<< "\${K3S_SHA}  /usr/local/bin/k3s"
chmod +x /usr/local/bin/k3s
wget -q https://raw.githubusercontent.com/k3s-io/k3s/\${K3S_SVC_COMMIT}/k3s.service -O /etc/systemd/system/k3s.service
sha256sum -c <<< "\${K3S_SVC_SHA}  /etc/systemd/system/k3s.service"
touch /etc/systemd/system/k3s.service.env

mkdir -p /var/lib/rancher/k3s/agent/images
wget -q https://github.com/k3s-io/k3s/releases/download/\${K3S_VER}/k3s-airgap-images-amd64.tar.gz -O /var/lib/rancher/k3s/agent/images/k3s-airgap-images-amd64.tar.gz
sha256sum -c <<< "\${K3S_AIRGAP_SHA}  /var/lib/rancher/k3s/agent/images/k3s-airgap-images-amd64.tar.gz"
(cd /var/lib/rancher/k3s/agent/images && gunzip k3s-airgap-images-amd64.tar.gz)

mkdir -p /var/lib/rancher/k3s/server/manifests
cat > /var/lib/rancher/k3s/server/manifests/leaf-default-admin.yaml <<'EOF'
apiVersion: rbac.authorization.k8s.io/v1
kind: ClusterRoleBinding
metadata:
  name: default-admin
roleRef:
  apiGroup: rbac.authorization.k8s.io
  kind: ClusterRole
  name: cluster-admin
subjects:
- kind: ServiceAccount
  name: default
  namespace: default
EOF
`;

export const kubeTraefikConfigOverride = `
# override traefik log level
# set level to DEBUG for testing
# TODO: make level configurable?
# full list of available values to override:
# https://github.com/traefik/traefik-helm-chart/blob/master/traefik/values.yaml
cat > /var/lib/rancher/k3s/server/manifests/traefik-config.yaml <<'EOF'
apiVersion: helm.cattle.io/v1
kind: HelmChartConfig
metadata:
  name: traefik
  namespace: kube-system
spec:
  valuesContent: |-
    logs:
      access:
        enabled: true
      general:
        level: INFO
EOF
`;

export const kubeLeafService = `
# leaf ingress route/service with external endpoint
cat > /var/lib/rancher/k3s/server/manifests/traefik-leaf-router.yaml <<'EOF'
apiVersion: traefik.containo.us/v1alpha1
kind: IngressRoute
metadata:
  name: leaf-route
spec:
  routes:
  - kind: Rule
    match: 'PathPrefix(\`/_leaf\`)'
    priority: 9999
    services:
    - kind: Service
      name: leaf-service
      port: 7755
  tls: {}
---
apiVersion: v1
kind: Service
metadata:
  name: leaf-service
spec:
  ports:
    - protocol: TCP
      port: 7755
---
apiVersion: v1
kind: Endpoints
metadata:
  name: leaf-service
subsets:
  - addresses:
      - ip: 10.42.0.1
    ports:
      - port: 7755
EOF
`;

export const kubeTraefikMiddleware = `
# traefik auth middleware CRD
cat > /var/lib/rancher/k3s/server/manifests/traefik-auth-middleware.yaml <<'EOF'
apiVersion: traefik.containo.us/v1alpha1
kind: Middleware
metadata:
  name: leaf-auth
spec:
  forwardAuth:
    address: "http://10.42.0.1:7755/_leaf/api/auth/validate"
EOF

# traefik CORS middleware CRD
# used to allow CORS requests into the app itself
# overrides any CORS handling from the app
cat > /var/lib/rancher/k3s/server/manifests/traefik-cors-middleware.yaml <<'EOF'
apiVersion: traefik.containo.us/v1alpha1
kind: Middleware
metadata:
  name: leaf-app-cors
spec:
  headers:
    accessControlAllowMethods:
      - DELETE
      - GET
      - HEAD
      - OPTIONS
      - PATCH
      - POST
      - PUT
    accessControlAllowOriginList:
      - https://clovyrapp.local
      - https://cstaging.app
      - https://clovyr.app
      - http://localhost:8000
      - '*'
    accessControlAllowCredentials: true
    accessControlAllowHeaders:
      # must add any other custom headers here
      # in order to be able to make CORS requests to leaf (instance-configure)
      - content-type
      - authorization
      - cookie
      - cache-control
      - pragma
      - x-nacl-nonce
      - x-verbose
    accessControlMaxAge: 100
    addVaryHeader: true
EOF

# traefik CSP middleware CRD
cat > /var/lib/rancher/k3s/server/manifests/traefik-csp-middleware.yaml <<'EOF'
apiVersion: traefik.containo.us/v1alpha1
kind: Middleware
metadata:
  name: leaf-csp
spec:
  headers:
    contentSecurityPolicy: "default-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline' 'unsafe-eval' https: *; img-src 'self' data: https://cstatic.app *; font-src 'self' https://cstatic.app; connect-src 'self' https://open-vsx.org file:; worker-src 'self' blob:"
EOF
`;

export const kubeUtils = `
## Kubectl
K8S_VER=v1.22.4
K8S_SHA=21f24aa723002353eba1cc2668d0be22651f9063f444fd01626dce2b6e1c568c
HELM_VER=v3.7.1
HELM_SHA=6cd6cad4b97e10c33c978ff3ac97bb42b68f79766f1d2284cfd62ec04cd177f4

mkdir ~/.kube && ln -s /etc/rancher/k3s/k3s.yaml ~/.kube/config
# ln -s /usr/local/bin/k3s /usr/local/bin/kubectl # this version has slow startup because of k3s datadir stuff
wget -q https://dl.k8s.io/release/\${K8S_VER}/bin/linux/amd64/kubectl -O /usr/local/bin/kubectl
sha256sum -c <<< "\${K8S_SHA}  /usr/local/bin/kubectl"
chmod +x /usr/local/bin/kubectl

## Crictl
ln -s /usr/local/bin/k3s /usr/local/bin/crictl

## Helm
wget -q https://get.helm.sh/helm-\${HELM_VER}-linux-amd64.tar.gz -O /tmp/leaf-boot/helm-amd64.tar.gz
sha256sum -c <<< "\${HELM_SHA}  /tmp/leaf-boot/helm-amd64.tar.gz"
(cd /tmp/leaf-boot && tar xfvz helm-amd64.tar.gz && mv linux-amd64/helm /usr/local/bin/helm)
`;

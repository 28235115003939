<script setup lang="ts">
import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import useMediaQuery from '@clovyr/bed/composables/useMediaQuery';

import PlusSignIcon from '@/components/icons/PlusSignIcon.vue';
import { usePublisher } from '@/composables/usePublisher';
import { usePollenStore } from '@/stores/pollen_store';

import PubHero from './PubHero.vue';
import PublisherAppVersion from './PublisherAppVersion.vue';

const props = defineProps<{ publisherID: string; appID?: string }>();
const router = useRouter();

const pollenStore = usePollenStore();
const { publisherID, appID } = toRefs(props);
const {
  pubApp,
  pubAppVersions,
  appBgMetadata: appBg,
  appLogoMetadata: appLogo,
} = usePublisher(publisherID, appID);

const addingVersion = ref(false);

const handleBackClick = async () => {
  return router.push({
    name: 'PublisherApp',
    params: { publisherID: props.publisherID, appID: props.appID },
  });
};

const handlePortalClick = async () => {
  return router.push({
    name: 'Publisher',
    params: { publisherID: props.publisherID },
  });
};

async function onClickAddNewVersion() {
  if (addingVersion.value) {
    return;
  }
  pollenStore.pollen.addNewAppVersion(pubApp.value!.id);
  addingVersion.value = true;
}

function onSavedVersion() {
  // check if all versions have id now
  if (pubAppVersions.value.every((v) => !!v.id)) {
    addingVersion.value = false;
  }
}

const isCode = computed(() => pubApp.value && pubApp.value.app_type === 'clovyr_code');

const isMobile = useMediaQuery('mobile');
const isTablet = ref(useMediaQuery('tablet'));
</script>

<template>
  <!--app-detail section is also being used in PublisherApp; need to abstract to its own component-->
  <div v-if="pubApp">
    <PubHero
      :appLogo="appLogo"
      :appBackground="appBg"
      :app="pubApp"
      @portal-click="handlePortalClick"
    >
      <template v-slot:buttons>
        <Button @click="handleBackClick">back to app</Button>
      </template>
    </PubHero>
  </div>

  <div>
    <div class="container">
      <div class="grid__col-sm-11">
        <div class="versions">
          <div class="header">
            <h2>Manage Versions</h2>
            <Button
              v-if="!(isMobile || isTablet)"
              label="Add New Version"
              @click="onClickAddNewVersion"
              translucent
              :disabled="addingVersion"
            />
            <Button
              v-else
              @click="onClickAddNewVersion"
              translucent
              :disabled="addingVersion"
              :shape="ButtonShape.Circle"
              ><PlusSignIcon
            /></Button>
          </div>

          <PublisherAppVersion
            v-for="pubAppVersion in pubAppVersions"
            :key="pubAppVersion.id"
            :publisherID="publisherID"
            :appID="appID!"
            :versionID="pubAppVersion.id"
            @saved="onSavedVersion"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;

  //Local styles
  .versions {
    background-color: #41355b;
    border-radius: 16px;
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      > * {
        margin-right: 1rem;
      }

      .button:hover {
        :deep(.plus-icon) {
          stroke: color(background, secondary-solid);
        }
      }
    }
  }
}

/* Deep Styles */
//Textinput
:deep(.textinput-block__input) {
  background-color: #211a33;
  padding-left: 1.5rem;
}
:deep(.textinput-block__label) {
  padding-left: 1.5rem !important;
}

.app-detail {
  &__hero {
    .app-type {
      display: flex;
      font-size: 13px;

      &__float-left {
        display: flex;
        margin-left: space(1);
      }
      &__float-right {
        color: color(action, primary);
        margin-left: space(1);
      }
    }
  }

  :deep(.hero) {
    .hero__inner {
      align-items: flex-start;
      flex-direction: column-reverse;
      margin-bottom: space(9);
      padding: 7rem 5rem 0 5rem;
      .hero__right {
        align-self: flex-end;
      }
      .hero__left {
        display: flex;
        margin-top: 2rem;

        @include media-breakpoint-down(md) {
          height: space(13);
        }
        .app-icon {
          display: block;

          .image-media {
            padding: 1rem;
          }
        }
      }

      .hero__top {
        margin-right: space(3);
      }

      .hero__middle {
        margin-bottom: 0;

        .hero__heading {
          margin-top: 2rem;

          @include media-breakpoint-up(md) {
            margin-top: 1.5rem;
          }

          @include media-breakpoint-up(xl) {
            margin-top: 0.5rem;
          }

          .app-type {
            letter-spacing: 0.03em;
            margin-bottom: space(0.5);

            @include media-breakpoint-up(md) {
              margin-left: space(0.75);
            }
          }
        }
      }
    }
  }
  .app-background-gradient {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: space(60);
    background: linear-gradient(180deg, rgb(20 20 20 / 80%) 0%, color(negative) 100%);
  }

  .app-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-index);
    width: 100%;
    height: space(60);
    background: var(--bg-url);

    @include background-image;
  }

  .app-logo-detail {
    margin: 0.5rem 0.75rem 0.75rem 0.5rem;
    background-color: #ffffff;
    border-radius: 50px;
  }
}
</style>

import { AuthState, AuthType } from '../../pollen/types';
import type {
  AuthChallengeRequest,
  AuthChallengeResponse,
  NostrSignInRequest,
  NostrSignInResponse,
  ReadStateResponse,
  SignInRequest,
  SignInResponse,
  SignUpRequest,
} from '../../user';

import type {
  AcceptSharedDataRequest,
  AcceptShareRequest,
  CreateItemRequest,
  EncryptedVaultItem,
  GetShareRequest,
  GetShareResponse,
  ShareItemRequest,
  ShareItemResponse,
  UpdateItemRequest,
} from './api';

/**
 * Backend user API client.
 *
 * Provides a interface over the actual UserAPI class.
 */
export interface UserAPI {
  signUp(req: SignUpRequest): Promise<void>;
  signIn(req: SignInRequest): Promise<SignInResponse>;
  signInWithNostr(req: NostrSignInRequest): Promise<NostrSignInResponse>;
  signOut(): Promise<void>;
  authChallenge(email: AuthChallengeRequest): Promise<AuthChallengeResponse>;
  readState(email: string): Promise<ReadStateResponse>;
  removeMonolithicState(): Promise<void>;
}

/**
 * Backend vault API client.
 *
 * Provides a interface over the actual VaultAPI class.
 */
export interface VaultAPI {
  listItems(email: string): Promise<EncryptedVaultItem[]>;
  createItem(req: CreateItemRequest): Promise<void>;
  updateItem(req: UpdateItemRequest): Promise<void>;
  deleteItem(id: string): Promise<void>;
  shareItem(req: ShareItemRequest): Promise<ShareItemResponse | undefined>;
  getItemShare(req: GetShareRequest): Promise<GetShareResponse>;
  acceptShare(req: AcceptShareRequest): Promise<void>;
  acceptSharedData(req: AcceptSharedDataRequest): Promise<void>;
}

/**
 * A strict subset of the Web Storage API.
 */
export interface WebStorage {
  length: number;
  getItem(key: string): string | null;
  removeItem(key: string): void;
  setItem(key: string, value: string): void;
  key(index: number): string | null;
}

/**
 * A decrypted vault item.
 */
export interface VaultItem {
  id: string;
  data: string;
}

/**
 * The main vault interface.VaultItem
 */
export interface Vault {
  init(): Promise<VaultItem[]>;
  signUp(email: string, password: string, items: VaultItem[]): Promise<void>;
  signUpWithNostr(email: string, items: VaultItem[]): Promise<void>;
  logIn(email: string, password: string, secretKey?: string): Promise<VaultItem[]>;
  logInWithNostr(): Promise<VaultItem[]>;
  lock(): Promise<void>;
  unlock(password: string): Promise<VaultItem[]>;
  addSecretKey(secretKey: string): Promise<VaultItem[]>;
  setKeychain(keychainJSON: string): Promise<VaultItem[]>;
  putItem(item: VaultItem): Promise<void>;
  deleteItem(itemID: string): Promise<void>;
  shareItem(itemID: string, extraParams: string): Promise<string>;
  acceptShare(itemID: string, shareKey: string): Promise<VaultItem[]>;
  items(): Promise<VaultItem[]>;
  email(): string | undefined;
  secretKey(): string | undefined;
  authState(): AuthState;
  authType(): AuthType;
}

/**
 * In memory decoded keychain types.
 */
export type Keychain =
  | OpenPasswordKeychain
  | NoSecretKeyPasswordKeychain
  | LockedKeychain
  | NostrKeychain;

export interface OpenPasswordKeychain {
  state: 'passwordOpen';
  passwordKey: Uint8Array;
  secretKey: Uint8Array;
  email: string;
}

export interface NoSecretKeyPasswordKeychain {
  state: 'passwordNoSecretKey';
  passwordKey: Uint8Array;
  email: string;
}

export interface NostrKeychain {
  state: 'nostr';
  auk: Uint8Array;
  pubkey: string;
  email: string;
}

export interface LockedKeychain {
  state: 'passwordLocked';
  secretKey: Uint8Array;
  email: string;
}

export const LATEST_KEYCHAIN_VERSION = 4;

import { isNostrPresent } from '@clovyr/pollen/crypto/nostr';

export const useNostr = () => {
  const nip07Present = ref(false);

  onMounted(() => {
    // mounted hook only runs client-side (not during SSR) and so will not error out but still gets
    // the correct state
    nip07Present.value = isNostrPresent();
  });

  return {
    isNostrAvailable: nip07Present,
  };
};

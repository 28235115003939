<script setup lang="ts">
import { storeToRefs } from 'pinia';

import AWSToken1 from '@clovyr/bed/assets/images/AWS-token-1.webp';
import AWSToken2 from '@clovyr/bed/assets/images/AWS-token-2.webp';
import AWSToken3 from '@clovyr/bed/assets/images/AWS-token-3.webp';
import FoxAndClover from '@clovyr/bed/assets/images/fox-and-clover.png';
import { GridItem, GridSection } from '@clovyr/bed/components/blocks';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import { ComputeProviderName } from '@clovyr/pollen/types';

import FormField from '@/components/blocks/FormField.vue';
import InstructionSection from '@/components/blocks/InstructionSection.vue';
import SpinnerCover from '@/components/elements/SpinnerCover.vue';
import HowToIcon from '@/components/icons/HowToIcon.vue';
import PrivacyLockIcon from '@/components/icons/PrivacyLockIcon.vue';
import AppLauncherHero from '@/components/tiers/AppLauncherHero.vue';
import { useLauncherStore } from '@/stores/launcher_store';

defineProps(['metadata']);

const detailsToggled = ref(false);

const launcherStore = useLauncherStore();
const { selectedComputeProviderConfig, selectedHost } = storeToRefs(launcherStore);
const { setComputeProviderConfig } = launcherStore;

const handleDetailsToggle = () => {
  detailsToggled.value = !detailsToggled.value;
};

const credentials = reactive({
  accessKeyID: '',
  secretAccessKey: '',
});

if (selectedComputeProviderConfig.value?.providerID === ComputeProviderName.AWSEC2) {
  Object.assign(credentials, selectedComputeProviderConfig.value.credentials);
}

async function saveToken() {
  await setComputeProviderConfig({
    id: crypto.randomUUID(),
    providerID: ComputeProviderName.AWSEC2,
    name: 'Amazon Web Services',
    credentials,
  });
}

async function onFormSubmit() {
  await saveToken();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function onValidateToken() {
  // TODO: https://docs.aws.amazon.com/STS/latest/APIReference/API_GetCallerIdentity.html
  throw new Error('not implemented');
}

// need better error handling to dismiss spinner in case of error
const showAwaitingSpinner = ref(false);

watch(credentials, async () => {
  await saveToken();
});
</script>

<template>
  <div class="host-setup host-setup--specific">
    <AppLauncherHero
      :app-logo="metadata"
      :app-title="metadata.name"
      :right-image-src="FoxAndClover"
      heading-text="Connect your account."
    />

    <GridSection :spacing="3">
      <GridItem :md="6" highlighted>
        <div class="grid-item-header">
          <AppLogo class="app-logo-block" :logo="selectedHost!" pad>
            <template #title>
              <div class="text--h4">{{ selectedHost!.title }}</div>
            </template>
          </AppLogo>
        </div>

        <div class="form-item">
          <form @submit.prevent="onFormSubmit">
            <FormField
              v-model="credentials.accessKeyID"
              name="accessKeyID"
              label="Enter Access Key ID"
              shape="square"
              placeholder="e.g. AKIAIOSFODNN7EXAMPLE"
              class="api-token-input"
            />
            <FormField
              v-model="credentials.secretAccessKey"
              name="secretAccessKey"
              label="Enter Secret Access Key"
              shape="square"
              placeholder="e.g. wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"
              class="api-token-input"
            />
          </form>

          <!-- TODO: validation -->
          <!-- <Button label="Validate Token" @click="onValidateToken" /> -->
        </div>
      </GridItem>

      <GridItem :md="6" highlighted>
        <div class="privacy-statement-block">
          <div class="block-icon">
            <PrivacyLockIcon />
          </div>
          <h3 class="title">Clovyr works differently.</h3>
          <p>
            Providing your API token here creates a link between your Linode account and the Clovyr
            platform, which runs locally in your browser. Your credentials are never transmitted to
            or stored on Clovyr's servers. Automatic backups are end-to-end encrypted, so the only
            person with access to your application data is you.
          </p>
        </div>
      </GridItem>
    </GridSection>

    <div class="instructions-section">
      <button
        :class="{ 'instructions-toggle--toggled': detailsToggled }"
        class="instructions-toggle"
        @click="handleDetailsToggle"
      >
        <HowToIcon />

        How to create an API token
      </button>

      <div v-show="detailsToggled" class="section-highlighted background-gradient--2">
        <div class="specific-setup-instructions">
          <InstructionSection :headingText="'Generating an API Token'">
            <div class="generate-user-token">
              <a
                target="_blank"
                class="button--reversed button--pill button--icon-left button"
                href="https://us-east-1.console.aws.amazon.com/iam/home#/users$new?step=review&accessKey&userNames=clovyr-user&permissionType=policies&policies=arn:aws:iam::aws:policy%2FAmazonEC2FullAccess"
                >Generate User Token
              </a>
            </div>
          </InstructionSection>

          <InstructionSection :imageFilenames="[AWSToken1]">
            <ol class="aws-list">
              <li>
                Click the&nbsp;&nbsp;<b class="green">"Generate User Token"</b>&nbsp;&nbsp;button
                above.
              </li>
              <li>A new window will open to AWS sign-in.</li>
              <li>Log into your AWS account.</li>
              <li>Navigate to "Add User."</li>
              <li>Enter "User Name" in correct field.</li>
              <li>All other fields will be pre-filled and do not need to be changed.</li>
              <li>Select "Create New User."</li>
            </ol>
          </InstructionSection>

          <InstructionSection :imageFilenames="[AWSToken2]">
            <ol class="aws-list">
              <li>Copy the Secret Access Key, and the Access Key ID.</li>
            </ol>
          </InstructionSection>

          <InstructionSection :imageFilenames="[AWSToken3]">
            <ol class="aws-list">
              <li>
                Enter the Secret Access Key, and the Access Key ID into the corresponding fields in
                the Clovyr launch wizard.
              </li>
              <li>Select “Review” to review your settings and host before deploying your app.</li>
            </ol>
          </InstructionSection>
        </div>
      </div>
    </div>
  </div>

  <SpinnerCover v-if="showAwaitingSpinner" />
</template>

<style scoped lang="scss">
.generate-user-token {
  width: 300px;
  padding-top: 20px;
}

.green {
  color: #33e672;
}

.aws-list {
  list-style-type: disc;
  padding-right: space(5);

  li {
    padding: space(0.5);
  }
}
</style>

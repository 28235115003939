<script setup lang="ts">
const props = defineProps({
  theme: {
    type: String,
    default: 'base',
  },
});

const emit = defineEmits(['onStop']);

onUnmounted(() => {
  emit('onStop');
});

const classNames = computed(() => [props.theme && `spinner-${props.theme}`]);
</script>

<template>
  <div :class="classNames" class="spinner-wrapper">
    <div class="spinner">Loading...</div>
  </div>
</template>

<style scoped lang="scss">
.spinner-wrapper {
  align-items: center;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  &.spinner-base {
    background-color: rgba(0, 0, 0, 50%);
    backdrop-filter: blur(0.375rem);
  }

  .spinner {
    $spinner-width: 5rem;
    $spinner-height: 5rem;
    $spinner-base-border-color: transparentize(color(white), 0.2);
    $spinner-main-border-color: color(grey, primary);
    $spinner-border-width: 0.8rem;

    animation: spinner 1.1s infinite linear;
    border-radius: 50%;
    margin: 60px auto;
    font-size: 0.625rem;
    position: relative;
    text-indent: -9999rem;
    border-top: $spinner-border-width solid $spinner-base-border-color;
    border-right: $spinner-border-width solid $spinner-base-border-color;
    border-bottom: $spinner-border-width solid $spinner-base-border-color;
    border-left: $spinner-border-width solid $spinner-main-border-color;
    transform: translateZ(0);
    width: $spinner-width;
    height: $spinner-height;

    &::after {
      border-radius: 50%;
      width: $spinner-width;
      height: $spinner-height;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>

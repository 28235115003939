<script setup lang="ts">
import type { Manifest } from '@clovyr/pollen/manifest';

import { usePollenStore } from '@/stores/pollen_store';

import InstantChia from './InstantChia.vue';
import InstantCode from './InstantCode.vue';
import InstantCodeFedi from './InstantCodeFedi.vue';

const props = defineProps<{
  instantLaunchApp: string;
  manifest?: Manifest;
  hideCodeDesc?: boolean;
  interstitial?: boolean;
}>();

const pollenStore = usePollenStore();

const app = computed(() => {
  return pollenStore.garden.getAppByID(props.instantLaunchApp);
});

const view = computed(() => {
  if (app.value) {
    return InstantCode;
  }
  switch (props.instantLaunchApp) {
    case 'code-chia':
    case 'chialisp':
      return InstantChia;
    case 'code-fedimint':
      return InstantCodeFedi;
    default:
      return InstantCode;
  }
});
</script>

<template>
  <component :is="view" v-bind="props" />
</template>

<template>
  <div v-if="src" class="embed">
    <iframe
      :src="src"
      :title="title"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
/**
 * TODO: this needs to handle
 * - video
 * - youtube
 * - image
 * - missing
 */
export default {
  name: 'Embed',
  props: {
    src: {
      type: String,
      default: '',
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
  },
};
</script>

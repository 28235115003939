import type { App } from 'vue';
import type { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';

import { config } from './config';

export function initSentry(app: App<Element>, router: Router) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const integrations: any[] = [];
  if (!import.meta.env.SSR) {
    // don't enable browser tracing in SSR
    integrations.push(Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration());
  }
  Sentry.init({
    app,
    environment: config.VITE_APP_ENV || 'local',
    dsn: config.VITE_SENTRY_DSN || '',
    logErrors: false,
    integrations,
    tracePropagationTargets: ['clovyr.app', 'cstaging.app', /^\//],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number.parseFloat(config.VITE_SENTRY_SAMPLE_RATE) || 1.0,
  });
}

import { WNextBase } from '../api/wnext-base';
import { Invoice } from '../types/Invoice';

export class InvoiceAPI extends WNextBase {
  async listInvoices(subID: string): Promise<Invoice[]> {
    const res = await this.unwrapRes(this.client.get(`/subscriptions/${subID}/invoices`));
    if (res.status !== 'success') {
      throw new Error('failed to list invoices');
    }
    if (!res.data?.['invoices']) {
      return [];
    }
    return res.data['invoices'].map((i) => new Invoice(i));
  }

  async payInvoice(subID: string, invoiceID: string): Promise<string> {
    const res = await this.unwrapRes(
      this.client.post(`/subscriptions/${subID}/invoices/${invoiceID}/pay`)
    );
    if (res.status !== 'success') {
      throw new Error('failed to pay invoice');
    }
    if (!res.data?.['url']) {
      throw new Error('failed to initiate payment capture');
    }
    return res.data['url'];
  }
}

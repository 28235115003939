<template>
  <div class="table-of-contents">
    <ul class="table-of-contents__chapters">
      <li class="table-of-contents__chapter">
        <div class="chapter-title">Clovyr General Help</div>
        <ul class="table-of-contents__sections">
          <li
            @click="handleSelection({ name: 'recovery-kit-help', chapter: 'Clovyr General Help' })"
            class="table-of-contents__section"
          >
            <div class="section-title">Recovery Kit Help</div>
          </li>
        </ul>
      </li>
      <li class="table-of-contents__chapter">
        <div class="chapter-title">Help with Clovyr Code</div>
        <ul class="table-of-contents__sections">
          <li
            @click="handleSelection({ name: 'getting-started', chapter: 'Help with Clovyr Code' })"
            class="table-of-contents__section"
          >
            <div class="section-title">Help with Clovyr Code</div>
          </li>

          <li
            @click="
              handleSelection({ name: 'launching-clovyr-code', chapter: 'Help with Clovyr Code' })
            "
            class="table-of-contents__section"
          >
            <div class="section-title">Launching Clovyr Code</div>
          </li>
        </ul>
      </li>
      <li class="table-of-contents__chapter">
        <div class="chapter-title">Contact Us</div>
        <ul class="table-of-contents__sections">
          <li class="table-of-contents__section">
            <a href="mailto:support@clovyr.io">
              <div class="section-title">Email Support</div>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TableOfContents',
  methods: {
    handleSelection(page) {
      this.$emit('item-select', page);
    },
  },
};
</script>

<style scoped lang="scss">
$item-spacing: space(2.2);

.table-of-contents {
  &__chapter {
    margin-bottom: space(4);
  }

  .chapter-title {
    @extend .text--h3;

    margin-bottom: $item-spacing;
    color: color(grey);
  }

  .section-title {
    @extend .text--h4;

    cursor: pointer;

    &:hover {
      color: color(action);
    }
  }

  &__section:not(:last-child) {
    .section-title {
      margin-bottom: $item-spacing;
    }
  }
}
</style>

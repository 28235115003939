import type { ComputeInstanceSize } from '../compute';
import type { AWSCredentials } from '../compute/aws-ec2';

export enum ComputeProviderName {
  WNext = 'wnext',
  DigitalOcean = 'digitalocean',
  Linode = 'linode',
  AWSEC2 = 'aws-ec2',
}

export interface ComputeProviderInfo {
  id: ComputeProviderName;
  title: string;
  subtitle?: string;
  description?: string;
  logoUrl: string;
  logoBackgroundColor?: string;
  fullLogoUrl?: string;
  logoPad?: boolean;
  defaultRegion: string;
  regions: ComputeProviderRegion[];
  instanceTypes: ComputeProviderInstanceType[];
}

export interface ComputeProviderRegion {
  name: string;
  value: string;
  flag: string;
}

export interface ComputeProviderInstanceType {
  name: string;
  value: ComputeInstanceSize;
}

/**
 * A saved compute provider configuration.
 */
interface BaseComputeProviderConfig {
  id: string; // uuid
  providerID: ComputeProviderName;
  name: string; // friendly name for this config (defaults to ComputeProviderInfo.title)
}

/**
 * Union of all compute provider config types.
 *
 * Each config variant is distinguished by its providerID.
 */
export type ComputeProviderConfig = WNextConfig | AWSEC2Config | LinodeConfig | DigitalOceanConfig;

/**
 * WNext compute provider configuration.
 */
export interface WNextConfig extends BaseComputeProviderConfig {
  providerID: ComputeProviderName.WNext;
}

/**
 * AWS EC2 compute provider configuration.
 */
export interface AWSEC2Config extends BaseComputeProviderConfig {
  providerID: ComputeProviderName.AWSEC2;
  credentials: AWSCredentials;
}

/**
 * Linode compute provider configuration.
 */
export interface LinodeConfig extends BaseComputeProviderConfig {
  providerID: ComputeProviderName.Linode;
  credentials: OAuthCredentials;
}

/**
 * DigitalOcean compute provider configuration.
 */
export interface DigitalOceanConfig extends BaseComputeProviderConfig {
  providerID: ComputeProviderName.DigitalOcean;
  credentials: OAuthCredentials;
}

/**
 * OAuth credentials.
 */
export interface OAuthCredentials {
  accessToken: string;
  refreshToken?: string;
}

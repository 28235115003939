import { defineStore } from 'pinia';
import * as Sentry from '@sentry/core';

import { APIError } from '@clovyr/pollen/api/APIError';
import { WNext } from '@clovyr/pollen/compute/wnext';
import type { CreateDeploymentResponse, UNamedDeployment } from '@clovyr/pollen/deployment';
import { WNextDeployment } from '@clovyr/pollen/deployment/wnext';
import type { Instance } from '@clovyr/pollen/dts';

import { useInstanceStore } from './instance_store';

const wnextDeployment = new WNextDeployment();

/**
 * Deprecated. Only used by Clovyr Code.
 */
export const useDeploymentStore = defineStore('deployments', () => {
  const instances = useInstanceStore();
  const list = ref<UNamedDeployment[]>([]);

  function getByName(appName: string): UNamedDeployment | undefined {
    return list.value.find((d) => d.name === appName);
  }

  function addDeployment(deployment: UNamedDeployment) {
    list.value.push(deployment);
  }

  function removeDeployment(deployment: UNamedDeployment) {
    list.value = list.value.filter((i) => i !== deployment);
  }

  async function createDeployment(appName: string): Promise<[Instance, UNamedDeployment]> {
    let res: CreateDeploymentResponse | undefined;

    // try fetching existing one by name first
    try {
      const existing = await wnextDeployment.getDeployment({ appName });
      if (existing) {
        // get instance
        const instance = await new WNext().listInstances({}).then((instanceList) => {
          return instanceList.find((i) => i.fqdn === existing.lastInstanceFQDN);
        });
        res = { instance: instance!, deployment: existing };
      }
    } catch (e) {
      Sentry.captureException(e);
      // ignore
    }

    if (!res) {
      try {
        res = await wnextDeployment.createDeployment({ appName });
      } catch (e) {
        if (e instanceof APIError) {
          const error = e as APIError;
          const { code } = error;
          switch (code) {
            // all possible error codes
            // for now, use the same message for each
            case 400:
            case 403:
            case 404:
            case 500:
            case 601:
            default:
              throw new Error('No instance available. Please try again in a few minutes');
          }
        }
        throw e;
      }
    }

    addDeployment(res.deployment);
    instances.addInstance(res.instance);
    return [res.instance, res.deployment];
  }

  async function fetchByName(appName: string): Promise<UNamedDeployment | undefined> {
    let deployment = getByName(appName);
    if (deployment) {
      return deployment;
    }
    deployment = (await wnextDeployment.getDeployment({ appName })) || undefined;
    if (deployment) {
      addDeployment(deployment);
    }
    return deployment;
  }

  async function loadDeployments() {
    return wnextDeployment.listDeployments().then((data) => {
      if (data && Array.isArray(data)) {
        list.value = data;
      } else {
        list.value = [];
      }
    });
  }

  async function destroy(deployment: UNamedDeployment) {
    return wnextDeployment.deleteDeployment({ name: deployment.name });
  }

  return {
    list,

    getByName,
    fetchByName,
    createDeployment,
    removeDeployment,
    addDeployment,
    loadDeployments,
    destroy,
  };
});

<script lang="ts" setup>
import { Button, TextInputBlock } from '@clovyr/bed';
import type { Deployment } from '@clovyr/pollen';
import type { Manifest } from '@clovyr/pollen/manifest';

import Modal from '@/components/elements/Modal.vue';
import { usePollenStore } from '@/stores/pollen_store';

interface LibraryAppDetail {
  app: Manifest;
  deployment: Deployment;

  /**
   * When true, simply closes the modal instead of redirecting to Library
   */
  close?: boolean;
}
const props = defineProps<LibraryAppDetail>();
const emit = defineEmits(['close']);

const pollenStore = usePollenStore();
const router = useRouter();

const heading = `Are you sure you want to delete ${props.deployment.instanceName}?`;

const confirmDelete = ref('');
const errorMessage = ref('');

async function handleDelete() {
  if (!confirmDelete.value || confirmDelete.value.trim().toUpperCase() !== 'DELETE') {
    // TODO: show invalid entry, etc. maybe disable button until it's correct?
    errorMessage.value = "Must type 'delete' in the above box to delete the application.";
    return;
  }
  if (props.close) {
    await pollenStore.deleteDeployment(props.deployment);
    emit('close');
    return;
  }
  void router.push({ name: 'Library' }).then(() => {
    void nextTick(async () => {
      await pollenStore.deleteDeployment(props.deployment);
    });
  });
}
</script>

<template>
  <Modal :headingText="heading" @close="$emit('close')">
    <template v-slot:body>
      <div class="delete-app">
        <TextInputBlock
          label="Type “DELETE” to confirm. The app and all its data will be erased."
          class="delete-app__input"
          :error-message="errorMessage"
          @input="(e) => (confirmDelete = e.target.value)"
          @keyup.enter="handleDelete"
          focus
        />
      </div>
    </template>

    <template v-slot:controls-right>
      <Button label="delete" :reversed="true" @click="handleDelete" />
    </template>
  </Modal>
</template>
<style scoped lang="scss">
:deep(.error-message) {
  color: red;
}
</style>

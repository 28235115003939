import { WNextBase } from '../api/wnext-base';
import type { Request } from '../types';
import type { DockerCredentials } from '../types/DockerCredentials';

export interface CreateKeyRequest extends Request {
  publisher_id: string;
  publisher_app_id: string;
  repository: string;
}

export interface RegistryKeyAPI {
  createKey: (req: CreateKeyRequest) => Promise<DockerCredentials>;
}

export class WNextRegistryKeyAPI extends WNextBase implements RegistryKeyAPI {
  async createKey(req: CreateKeyRequest): Promise<DockerCredentials> {
    return this.unwrap(await this.client.post('/registry', req));
  }
}

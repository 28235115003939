import { defineStore } from 'pinia';

import { WNext } from '@clovyr/pollen/compute/wnext';
import type { Instance } from '@clovyr/pollen/types/Instance';

const wnext = new WNext();

/**
 * Deprecated. Only used by Clovyr Code.
 */
export const useInstanceStore = defineStore('instances', () => {
  const list = ref<Instance[]>([]);

  function getByFQDN(fqdn: string): Instance | undefined {
    return list.value.find((i) => i.fqdn === fqdn);
  }

  function addInstance(instance: Instance) {
    list.value.push(instance);
  }

  function removeInstance(instance: Instance) {
    list.value = list.value.filter((i) => i !== instance);
  }

  async function fetchByFQDN(fqdn: string): Promise<Instance | null> {
    let instance = getByFQDN(fqdn);
    if (instance) {
      return instance;
    }
    instance = await wnext.getInstance({ id: fqdn });
    if (instance) {
      addInstance(instance);
      return instance;
    }
    return null;
  }

  async function destroy(fqdn: string) {
    const res = await wnext.destroyInstance({ id: fqdn });
    list.value = list.value.filter((i) => i.fqdn !== fqdn);
    return res;
  }

  return {
    getByFQDN,
    addInstance,
    removeInstance,
    fetchByFQDN,
    destroy,
  };
});

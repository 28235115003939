export class APIResponse {
  // @ts-expect-error obj in constructor is expected to set status
  status: 'success' | 'fail' | 'error';

  data?: any;

  message?: string;

  code?: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

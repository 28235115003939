<script setup lang="ts">
import { Field, useField } from 'vee-validate';

const props = defineProps({
  theme: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  value: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { name } = toRefs(props);

// eslint-disable-next-line vue/no-setup-props-destructure
const { checked, handleChange, errorMessage } = useField(name, undefined, {
  type: 'checkbox',
  checkedValue: true,
  uncheckedValue: false,
  initialValue: props.value,
});

const CSSClasses = computed(() => {
  return [
    props.theme && `form-checkbox--${props.theme}`,
    props.disabled && `form-checkbox--disabled`,
    errorMessage.value && 'textinput-block--has-error',
  ];
});
</script>

<template>
  <div :class="CSSClasses" class="form-checkbox">
    <Field :name="name" type="checkbox">
      <label :for="name" class="form-checkbox-label-wrapper">
        <input
          @change="handleChange(value)"
          value=""
          :id="name"
          type="checkbox"
          :checked="checked"
          class="form-checkbox-input checkbox"
          :disabled="disabled"
        />

        <div class="form-checkbox-label">
          <slot>
            <template v-if="label">
              {{ label }}
            </template>
          </slot>
        </div>
      </label>
    </Field>
  </div>
  <div v-if="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
</template>

<style lang="scss" scoped>
.form-checkbox {
  min-height: 1.5rem;
  margin-bottom: 0.125rem;

  .form-checkbox-label-wrapper {
    align-items: center;
    display: flex;

    .form-checkbox-input {
      &:not(:checked) {
        border-color: color(grey);
      }
    }

    .form-checkbox-label {
      margin-left: 0.75rem;
    }
  }
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import ComingSoonReversed from '@clovyr/bed/assets/images/coming-soon-reversed.svg';
import GreenPlusSign from '@clovyr/bed/assets/images/green-plus-sign.svg';
import HeroDevelop from '@clovyr/bed/assets/images/hero-develop.svg';
import HeroRun from '@clovyr/bed/assets/images/hero-run.svg';
import PurplePlusSign from '@clovyr/bed/assets/images/purple-plus-sign.svg';
import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import Copy from '@clovyr/bed/components/elements/Copy.vue';
import CopyIcon from '@clovyr/bed/components/icons/CopyIcon.vue';
import ImageMedia from '@clovyr/bed/components/media/ImageMedia.vue';
import { PublisherAPI } from '@clovyr/pollen/publisher';

import { useHostname } from '../../composables/useHostname';
import { usePublisher } from '../../composables/usePublisher';
import { useUserFlagsStore } from '../../stores/user_flags';

import PublisherApps from './PublisherApps.vue';

const props = defineProps<{ publisherID: string }>();

const router = useRouter();
const { isInternal } = storeToRefs(useUserFlagsStore());
const { host } = useHostname();

const publisherID = computed(() => props.publisherID);
const { publisher } = usePublisher(publisherID);

const onClickNewClovyrCodeApp = async (/* values */) => {
  if (!isInternal.value) {
    return undefined;
  }
  return router.push({
    name: 'PublishersAppsNew',
    params: { publisherID: publisher.value!.slug },
    query: { appType: 'clovyr_code' },
  });
};

const onClickNewApp = async (/* values */) => {
  return router.push({
    name: 'PublishersAppsNew',
    params: { publisherID: publisher.value!.slug },
    query: { appType: 'custom' },
  });
};

const createInviteLink = async () => {
  const token = await new PublisherAPI().createInviteToken(publisher.value!.id);
  return `https://${host.value}/publishers/${publisher.value?.slug}/accept?invite=${token}`;
};
</script>

<template>
  <div class="publisher grid__col-md-12">
    <div class="intro">
      <h1 class="publisher__title">Publisher Portal</h1>
      <h4 class="publisher__subtitle">
        Create, manage and edit your apps here.
        <Tooltip
          class="copy"
          content="Quickly grab the link to your Publisher Portal and share it effortlessly."
          arrow
          offsetDistance="5"
          ><Copy :icon="CopyIcon" :text="createInviteLink"
        /></Tooltip>
      </h4>
    </div>
    <div class="new-apps">
      <div class="new-app run" @click="onClickNewApp">
        <div class="top">
          <div class="left">
            <h2>New Run App</h2>
            <p>Put an app up on Clovyr for end users to run.</p>
          </div>
          <div class="right">
            <ImageMedia :filename="HeroRun" />
          </div>
        </div>
        <div class="bottom">
          <ImageMedia :filename="GreenPlusSign" />
        </div>
      </div>
      <div
        class="new-app develop"
        @click="onClickNewClovyrCodeApp"
        :class="{ disabled: !isInternal }"
      >
        <div class="top">
          <div class="left">
            <h2>New Develop App</h2>
            <p>Put a development environment up on Clovyr to help contributors to your project.</p>
          </div>
          <div class="right">
            <ImageMedia :filename="HeroDevelop" />
          </div>
        </div>
        <div class="bottom">
          <ImageMedia :filename="PurplePlusSign" />
        </div>
        <ImageMedia v-if="!isInternal" class="coming-soon" :filename="ComingSoonReversed" />
      </div>
    </div>
    <div class="break">
      <div class="break__text">your apps & environments</div>
      <div class="break__line"></div>
    </div>
    <div class="publisher__contents">
      <PublisherApps :publisherID="publisherID" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.publisher {
  padding: 5rem 0;
  display: flex;
  flex-direction: column;

  .intro {
    margin: 2rem 0 0 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .new-apps {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    margin: auto;
    gap: 2rem;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      justify-content: flex-start;
      padding: 3rem;
    }

    .new-app {
      width: 90%;
      border: 1px dashed color(grey, secondary);
      border-radius: 1.25rem;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      position: relative;

      @include media-breakpoint-up(xl) {
        height: 300px;
      }

      .top {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
          flex-direction: row;
          justify-content: space-between;
          width: unset;
          min-height: 12rem;
        }

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          justify-content: space-between;
        }
        @include media-breakpoint-up(xl) {
          flex-direction: row;
          justify-content: space-between;
        }
      }

      .bottom {
        padding: 1rem 0.25rem 0 0;
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-up(sm) {
          padding: 1rem 0.25rem 0 0;
        }
      }

      .left {
        margin-bottom: 1rem;
        @include media-breakpoint-up(sm) {
          width: 45%;
        }

        p {
          margin: 0.75rem 0;
          color: color(grey, secondary);
        }
      }
      .right {
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(sm) {
          justify-content: flex-end;
        }
      }

      &.disabled {
        opacity: 0.4;
        background: var(--Midnight, #221a33);
      }

      .coming-soon {
        position: absolute;
        top: 80%;
        left: -2.15rem;

        @include media-breakpoint-up(sm) {
          top: 70%;
        }
        @include media-breakpoint-up(md) {
          left: -2rem;
        }
        @include media-breakpoint-up(xxl) {
          left: -1.8rem;
        }
      }
    }

    .new-app:hover {
      background-color: color(background, primary);

      &.disabled {
        opacity: 0.4;
        background: var(--Midnight, #221a33);
        cursor: auto;
      }
    }

    .run {
      h2 {
        color: color(action, primary);
      }
    }
    .develop {
      .left {
        h2 {
          color: color(active, secondary);
        }
      }
    }
  }

  .break {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    justify-content: center;

    &__text {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.16em;
      font-family: 'IBM Plex Mono';
      color: color(grey, tertiary);
      margin-right: 1rem;

      @include media-breakpoint-up(sm) {
        font-size: 14px;
      }
    }
    &__line {
      border-top: 1px solid #ada6b7;
      width: 30%;

      @include media-breakpoint-up(sm) {
        width: 50%;
      }
      @include media-breakpoint-up(md) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 60%;
      }
      @include media-breakpoint-up(xl) {
        width: 70%;
      }
      @include media-breakpoint-up(xxl) {
        width: 75%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    color: color(grey, primary);
    margin: 1rem 0;
    gap: 0.5rem;
    width: 90%;
  }

  &__contents {
    margin: auto;
    width: 95%;
  }
}
</style>

<script setup lang="ts">
import * as Sentry from '@sentry/vue';

import { sleep } from '@clovyr/pollen/util/sleep';

import Module from '@/components/elements/Module.vue';
import { useAppStatus } from '@/composables/useAppStatus';
import { useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';

import { useAppVersion } from '../../composables/useAppVersion';

interface LibraryAppDetail {
  id: string;
}

const props = defineProps<LibraryAppDetail>();

const pollenStore = usePollenStore();
const deploymentID = toRef(props, 'id');

const { isUpdateAvailable, runAppVersion } = useAppVersion(deploymentID);
const { deployment, app } = useAppStatus(deploymentID);

const updateLabel = computed(() => {
  return isUpdateAvailable.value ? 'Update' : 'Up to Date';
});

const forceDisableUpgradeButton = ref(false);

const { eventBus } = useEventBus();

const onClickUpdate = async () => {
  await pollenStore.garden.loadDeploymentDetails(app.value);

  forceDisableUpgradeButton.value = true;
  try {
    await runAppVersion(app.value);
    await sleep(2000);
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  } finally {
    forceDisableUpgradeButton.value = false;
  }
};

onMounted(() => {
  eventBus.on('app:update', ({ appID }) => {
    if (appID === deploymentID.value) {
      void onClickUpdate();
    }
  });
});

onUnmounted(() => {
  eventBus.off('app:update');
});
</script>

<template>
  <Module hed="Version">
    <template #default>
      <div class="row">
        <div class="grid__col">
          <div class="module__blurb">
            <div class="module__blurb__head">Currently Running</div>
            <div class="module__blurb__body">
              {{ deployment.appVersion }}
            </div>
          </div>
        </div>
        <div class="grid__col">
          <div class="module__blurb">
            <div class="module__blurb__head">Latest Version</div>
            <div class="module__blurb__body">
              {{ app.metadata.version }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #ctas>
      <Button
        :label="updateLabel"
        :disabled="!isUpdateAvailable || forceDisableUpgradeButton"
        @click="onClickUpdate"
      />
    </template>
  </Module>
</template>

<style scoped lang="scss">
:deep(.module__ctas) {
  margin-top: 2rem;
}
.status {
  margin-top: 8px;
  margin-right: 20px;
}
</style>

<script setup lang="ts">
import NostrLogo from '@clovyr/bed/components/icons/NostrLogo.vue';
import { useMatomo } from '@clovyr/bed/composables/useMatomo';
import { AlreadySignedUpError } from '@clovyr/pollen/pollen/errors';

import FormField from '../../../components/blocks/FormField.vue';
import FormWrapper from '../../../components/forms/FormWrapper.vue';
import { usePollenStore } from '../../../stores/pollen_store';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { pollen } = usePollenStore();

const alreadySignedUp = ref(false);

const onSubmit = async (values) => {
  try {
    await pollen.signUpWithNostr(values.email);
    // matomo track event
    const matomo = useMatomo();
    matomo.trackEvent('Auth', 'Signup', 'Nostr');
    emit('close');
  } catch (err) {
    if (err instanceof AlreadySignedUpError) {
      alreadySignedUp.value = true;
      return;
    }
    throw err;
  }
};

const schema = {
  email: 'required|email',
};
</script>

<template>
  <div class="form-wrapper form-auth">
    <div class="form-heading">Sign Up With Nostr</div>
    <div class="form-description">Enter your email address below to continue with Nostr.</div>

    <div class="form">
      <FormWrapper :show-submit="false" :validation-schema="schema" @submit="onSubmit">
        <template v-slot:fields>
          <FormField name="email" label="Email" shape="rounded" />
          <div class="form__submit">
            <Button class="nostr-button" label="continue" reversed type="submit">
              <NostrLogo />
            </Button>
          </div>
        </template>
      </FormWrapper>
    </div>
  </div>
</template>

<style scoped>
.nostr-button {
  color: white;

  svg {
    width: 3rem;
    height: 2rem;
  }
}
</style>

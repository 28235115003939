/* Do not change, this code is generated from Golang structs */


export class Publisher {
    id: string;
    name: string;
    slug: string;
    contact_name: string;
    contact_email: string;
    payment_captured: boolean;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.name = source["name"];
        this.slug = source["slug"];
        this.contact_name = source["contact_name"];
        this.contact_email = source["contact_email"];
        this.payment_captured = source["payment_captured"];
        this.created_at = source["created_at"] ? new Date(source["created_at"]) : source["created_at"];
        this.updated_at = source["updated_at"] ? new Date(source["updated_at"]) : source["updated_at"];
        this.deleted_at = source["deleted_at"] ? new Date(source["deleted_at"]) : source["deleted_at"];
    }
}
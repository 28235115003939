import { defineStore, storeToRefs } from 'pinia';

import { useMatomo } from '@clovyr/bed/composables/useMatomo';
import type { ComputeProviderConfig, ComputeProviderInfo, Instance } from '@clovyr/pollen';
import {
  ComputeInstanceSize,
  type ComputeProvider,
  type InstanceType,
} from '@clovyr/pollen/compute';
import { getComputeProvider, isWNextProvider, supportsOAuth } from '@clovyr/pollen/compute/util';
import { getHostByID, wNextHost } from '@clovyr/pollen/fixtures/hosts';
import { getWNextPricing } from '@clovyr/pollen/fixtures/pricing';
import type { Manifest } from '@clovyr/pollen/manifest';
import { ComputeProviderName } from '@clovyr/pollen/types';
import type { Deployment, DeploymentAddon } from '@clovyr/pollen/types/Deployment';

import { useBeforeUnload } from '@/composables/useBeforeUnload';

import { type CustomDNSState, useCustomDNS } from '../composables/useCustomDNS';
import { useInstanceLauncher } from '../composables/useInstanceLauncher';

import {
  advancedSettings,
  type AnswerMap,
  answers,
  basicSettings,
  configErrors,
  hasValidSettings,
  initSettings,
  setAnswer,
} from './launch_settings';
import { useLaunchStore } from './launch_store';
import { usePollenStore } from './pollen_store';
import { LauncherState } from './types';
import { persist, rehydrate } from './util';

type PersistedLauncherState = {
  status: LauncherState;
  answers: AnswerMap;
  deployment: Deployment;
  customDNS?: CustomDNSState;
};

const LAUNCHER_STORE_KEY = 'clovyr/ui/launcher_store';

/**
 * Launcher Store tracks the progress of the launch wizard from init until the actual
 * launch/deployment. The app launch itself is managed in the `Launch Store`.
 */
export const useLauncherStore = defineStore('launcher', () => {
  const router = useRouter();
  const instanceLauncher = useInstanceLauncher();
  const launchStore = useLaunchStore();
  const pollenStore = usePollenStore();
  const customDNS = useCustomDNS();
  const { trackEvent } = useMatomo();

  const { getDeploymentApp } = pollenStore;
  const { computeProviderConfigs } = storeToRefs(pollenStore);

  // primary launch state
  // answers imported from launch_settings
  const status = ref<LauncherState>(LauncherState.ChooseHost); // initialState.status || LauncherState.ChooseHost
  const deployment = ref<Deployment>({} as Deployment);
  // TODO: store this in pollen

  const manifest = ref<Manifest>({} as Manifest); // initialState.app
  const metadata = computed(() => manifest.value?.metadata);

  const selectedHost = ref<ComputeProviderInfo | undefined>();
  const selectedComputeProviderConfig = ref<ComputeProviderConfig>();
  let computeProvider: ComputeProvider | undefined;

  const instance = ref<Instance>();

  function rehydrateState() {
    // first load state from storage
    const initialState: PersistedLauncherState | null = rehydrate(LAUNCHER_STORE_KEY);
    if (initialState && initialState.status) {
      status.value = initialState.status;
      deployment.value = initialState.deployment;
      if (initialState.customDNS) {
        customDNS.state.value = initialState.customDNS;
      }

      if (deployment.value.hostingProvider) {
        selectedHost.value = getHostByID(deployment.value.hostingProvider);
        computeProvider = getComputeProvider(deployment.value.hostingProvider);
      }

      const savedComputeProviderConfig = computeProviderConfigs.value.find(
        (c) => c.id === deployment.value.computeProviderConfigID,
      );
      if (savedComputeProviderConfig) {
        selectedComputeProviderConfig.value = savedComputeProviderConfig;
        computeProvider?.setConfig(savedComputeProviderConfig);
      } else if (deployment.value.hostingProvider === ComputeProviderName.WNext) {
        selectedComputeProviderConfig.value = computeProvider?.getConfig();
      }

      const m = getDeploymentApp(deployment.value);
      if (!m) {
        // TODO: throw error?
      } else {
        manifest.value = m;
      }
      initSettings(m, initialState.answers);
    }
  }
  // must rehydrate before starting watcher, or else persisted state will get
  // overwritten.
  rehydrateState();

  function persistState() {
    const o: PersistedLauncherState = {
      status: status.value,
      answers: answers.value,
      deployment: deployment.value,
      customDNS: customDNS.state.value,
    };
    persist(LAUNCHER_STORE_KEY, o);
  }

  /**
   * persist state to localstorage on changes
   */
  watch(
    [status, answers, deployment, customDNS.state],
    () => {
      if (status.value === LauncherState.Launching) {
        // don't persist when launching. state should be cleared out.
        return;
      }
      persistState();
    },
    {
      deep: true,
    },
  );

  function setState(newState: LauncherState) {
    trackEvent('launcher', 'state', newState);
    status.value = newState;
  }

  /**
   * Reset store back to initial values so that another app can be launched
   */
  function reset() {
    // wipe state so we don't retry on reload of launcher
    persist(LAUNCHER_STORE_KEY, {});
    setState(LauncherState.Settings);
    manifest.value = {} as Manifest;
    deployment.value = {} as Deployment;
    selectedHost.value = undefined;
    computeProvider = undefined;
    selectedComputeProviderConfig.value = undefined;
    customDNS.reset();
    initSettings();
  }

  // ComputeProvider is not a ref to avoid issues with private fields, so we
  // must manually watch selectedComputeProviderConfig for changes.
  // https://github.com/vuejs/core/issues/2981
  const isComputeConfigured = ref(computeProvider?.isConfigured() ?? false);
  watch(selectedComputeProviderConfig, () => {
    if (selectedComputeProviderConfig.value) {
      computeProvider?.setConfig(selectedComputeProviderConfig.value);
    }
    isComputeConfigured.value = computeProvider?.isConfigured() ?? false;
  });

  /**
   * is it possible to navigate to next step in launcher?
   */
  function isNextEnabled() {
    switch (status.value) {
      case LauncherState.Settings:
        return hasValidSettings.value && customDNS.isValid.value;
      case LauncherState.ChooseHost:
        return !!selectedHost.value && customDNS.isValid.value;
      case LauncherState.HostCredentials:
        return isComputeConfigured.value;
      case LauncherState.Review:
        return (
          hasValidSettings.value &&
          !!selectedHost.value &&
          isComputeConfigured.value &&
          customDNS.isValid.value
        );
      case LauncherState.Launching:
        return true;
      default:
        return false;
    }
  }

  /**
   * try to navigate to the next step in the launcher
   */
  function next() {
    if (!isNextEnabled()) {
      return;
    }
    switch (status.value) {
      case LauncherState.Settings:
        if (
          selectedHost.value?.id === ComputeProviderName.WNext ||
          selectedComputeProviderConfig.value !== undefined
        ) {
          setState(LauncherState.Review);
        } else {
          setState(LauncherState.ChooseHost);
        }
        break;
      case LauncherState.ChooseHost:
        if (selectedHost.value?.id === ComputeProviderName.WNext) {
          setState(LauncherState.Review);
        } else {
          setState(LauncherState.HostCredentials);
        }
        break;
      case LauncherState.HostCredentials:
        if (selectedHost.value) {
          trackEvent('launcher', 'savedHostCredentials', selectedHost.value.id);
        }
        setState(LauncherState.Review);
        break;
      case LauncherState.Review:
        // launch & show launching progress window
        setState(LauncherState.Launching);

        deployment.value.customDomain = customDNS.customDomain.value;
        deployment.value.fqdn = customDNS.state.value.claimedDomain;

        trackEvent('launcher', 'launch', manifest.value.metadata.id);
        if (customDNS.customDomain.value) {
          trackEvent('launcher', 'launchWithCustomDomain');
        }

        void launchStore
          .createLaunch(
            manifest.value,
            deployment.value,
            selectedHost.value!,
            selectedComputeProviderConfig.value!,
            answers.value,
          )
          .then((launch) => {
            // start launching immediately in bg thread while we navigate to detail page
            const { id } = deployment.value;
            void launchStore.startLaunch(launch).finally(() => {
              useBeforeUnload().removeBlocker(`deployment/${id}`);
            });
            void router
              .push({ name: 'LibraryAppDetail', params: { id: deployment.value.id } })
              .then(() => {
                reset();
              });
          });
        break;
      default:
    }
  }

  /**
   * is it possible to nav to previous step?
   */
  function isBackEnabled() {
    switch (status.value) {
      case LauncherState.Settings:
        return true;
      case LauncherState.ChooseHost:
        return true;
      case LauncherState.HostCredentials:
        return true;
      case LauncherState.Review:
        return true;
      case LauncherState.Launching:
        return false;
      default:
        return false;
    }
  }

  /**
   * try to navigate to the previous step in the launcher
   */
  function back(newState?: LauncherState) {
    if (!isBackEnabled()) {
      return;
    }
    switch (status.value) {
      case LauncherState.Settings:
        setState(LauncherState.ChooseHost);
        break;
      case LauncherState.ChooseHost:
        setState(LauncherState.Settings);
        break;
      case LauncherState.HostCredentials:
        setState(LauncherState.ChooseHost);
        break;
      case LauncherState.Review:
        if (
          newState &&
          (newState === LauncherState.ChooseHost || newState === LauncherState.HostCredentials)
        ) {
          // allow passing a state directly
          setState(newState);
        } else if (selectedHost.value?.id === ComputeProviderName.WNext) {
          setState(LauncherState.ChooseHost);
        } else {
          setState(LauncherState.HostCredentials);
        }
        break;
      default:
    }
  }

  /**
   * Initialize or reset the launcher to the initial state for a new app.
   *
   * Only a single app can be worked on at a given time.
   *
   * Called from AppLauncher either when clicking 'Launch' or, possibly, when
   * reloading the page.
   *
   * @param app
   */
  /* eslint-disable @typescript-eslint/no-use-before-define */
  function initialize(app?: Manifest) {
    // compare already loaded state (if any) with requested app (obtained via route prop)
    // manifest can be set either during rehydration or below
    if (app && manifest.value && app.metadata.id !== manifest.value?.metadata?.id) {
      setState(LauncherState.Settings);
      manifest.value = app;
      deployment.value = pollenStore.createDeployment(app.metadata, wNextHost);

      // if we have any stored configs, use the first one, otherwise use wnext
      if (computeProviderConfigs.value.length > 0) {
        const firstConfig = computeProviderConfigs.value[0];
        setSelectedHost(getHostByID(firstConfig.providerID));
        void setComputeProviderConfig(firstConfig);
      } else {
        setSelectedHost(wNextHost);
      }

      initSettings(app, {});
      persistState();
    }
    // throw err if no app given? is there a scenario where this is/should be possible?
    // applauncher should probably be checking its props before getting to this point.
  }
  /* eslint-enable @typescript-eslint/no-use-before-define */

  /**
   * Change selected compute provider but zero out config
   *
   * @param host
   */
  function setSelectedHost(host?: ComputeProviderInfo) {
    if (selectedHost.value === host) {
      return;
    }
    if (!host) {
      // null all values - i.e., when 'choose another host' is selected
      selectedHost.value = undefined;
      computeProvider = undefined;
      deployment.value.hostingProvider = ComputeProviderName.WNext; // back to default for now (because not-null)
      deployment.value.computeProviderConfigID = undefined;
      selectedComputeProviderConfig.value = undefined;
      return;
    }

    selectedHost.value = host;
    computeProvider = markRaw(getComputeProvider(host.id));
    deployment.value.hostingProvider = host.id;
    deployment.value.computeProviderConfigID = undefined;
    deployment.value.region = host.defaultRegion;

    if (host.id === ComputeProviderName.WNext) {
      selectedComputeProviderConfig.value = computeProvider.getConfig();
    } else {
      selectedComputeProviderConfig.value = undefined;
    }
  }

  function toggleAddon(addon: DeploymentAddon, selected: boolean) {
    if (selected) {
      if (!deployment.value.addons?.includes(addon)) {
        deployment.value.addons ||= [];
        deployment.value.addons.push(addon);
      }
    } else if (deployment.value.addons) {
      deployment.value.addons = deployment.value.addons.filter((d) => d !== addon);
    }
  }

  /**
   * Get the instance type tuple for the given host
   *
   * @param host
   * @returns
   */
  function getProviderInstanceType(host: ComputeProviderInfo): InstanceType {
    const provider = getComputeProvider(host);
    return provider.getInstanceType(
      manifest.value.deployment.compute?.size || ComputeInstanceSize.Small,
    );
  }

  /**
   * The WNext-specific price for the selected app
   */
  const wnextAppPrice = computed<InstanceType>(() => {
    // default to $20 if not found for whatever reason
    return getWNextPricing(metadata.value?.id, manifest.value?.deployment?.compute?.size);
  });

  /**
   * Instance type tuple for the currently selected host (BYOH or wNext)
   */
  const selectedHostType = computed<InstanceType | undefined>(() => {
    if (selectedHost.value && computeProvider) {
      if (isWNextProvider(computeProvider)) {
        return wnextAppPrice.value;
      }
      return computeProvider.getInstanceType(
        manifest.value.deployment.compute?.size || ComputeInstanceSize.Small,
      );
    }
    return undefined;
  });

  /**
   * kick off oauth flow for current compute provider to get auth token
   */
  async function doOauth(): Promise<{ token: string }> {
    if (!selectedHost.value || !computeProvider) {
      return Promise.reject(new Error('No host selected'));
    }

    const token = await instanceLauncher.doOauth(selectedHost.value.id);
    if (token) {
      if (computeProvider && supportsOAuth(computeProvider) && !token) {
        return Promise.reject(new Error('No auth token'));
      }
    }
    return { token };
  }

  /**
   * Used to pass back compute provider configs, such as oauth or other access
   * tokens.
   *
   * @param config
   */
  async function setComputeProviderConfig(config: ComputeProviderConfig) {
    if (selectedHost.value && computeProvider) {
      selectedComputeProviderConfig.value = config;
      computeProvider.setConfig(config);

      if (computeProvider.isConfigured()) {
        deployment.value.computeProviderConfigID = config.id;
        persistState();
      }
    }
  }

  return {
    status,
    deployment,
    computeProviderConfigs,
    selectedComputeProviderConfig,
    computeProvider,
    manifest,
    metadata,
    instance,

    reset,
    initialize,
    back,
    next,
    isNextEnabled: computed(isNextEnabled),
    isBackEnabled: computed(isBackEnabled),

    answers,
    setAnswer,
    basicSettings,
    advancedSettings,
    hasValidSettings,
    configErrors,

    selectedHost,
    selectedHostType,
    getProviderInstanceType,
    wnextAppPrice,
    setSelectedHost,
    toggleAddon,

    doOauth,
    setComputeProviderConfig,
    customDNS,
  };
});

<template>
  <BaseSvg viewBox="0 0 20 20" width="20" height="20" fill="none">
    <line
      id="Line 86"
      x1="0.75"
      y1="-0.75"
      x2="20.3465"
      y2="-0.75"
      transform="matrix(-0.703174 -0.711018 0.703174 -0.711018 18.0001 17)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      id="Line 87"
      x1="0.75"
      y1="-0.75"
      x2="20.3465"
      y2="-0.75"
      transform="matrix(-0.703174 0.711018 -0.703174 -0.711018 16.8345 2.00002)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'CloseIcon',
  components: {
    BaseSvg,
  },
};
</script>

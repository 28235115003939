<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';

import Button from '@clovyr/bed/components/controls/Button.vue';
import NostrLogo from '@clovyr/bed/components/icons/NostrLogo.vue';
import { useMatomo } from '@clovyr/bed/composables/useMatomo';
import { getAllQueryParams } from '@clovyr/pollen/http/qs';
import { IncorrectLoginError } from '@clovyr/pollen/pollen/errors';
import { removeWhitespace } from '@clovyr/pollen/util/string';

import { useNostr } from '@/composables/useNostr';
import { usePayments } from '@/composables/usePayments';
import { usePollenStore } from '@/stores/pollen_store';

import FormField from '../../../components/blocks/FormField.vue';
import PasswordFormField from '../../../components/blocks/PasswordFormField.vue';
import FormWrapper from '../../../components/forms/FormWrapper.vue';

import { validateSecretKey } from './util';

type LoginProps = {
  isShared?: boolean;
  isPubFlow?: boolean;
};

const props = defineProps<LoginProps>();

const emit = defineEmits<{
  (e: 'authForm', form: string): void;
  (e: 'close'): void;
}>();

const pollenStore = usePollenStore();
const { pollen } = pollenStore;

const { isNostrAvailable } = useNostr();

const query = getAllQueryParams();
const emailParam = query.get('email') || '';
const secretKeyParam = query.get('secret') || '';

const handleSignup = (form?: string) => {
  emit('authForm', form || 'showSignup');
};

const userHasSecretKey = ref(true);

const toggleSecretKey = () => {
  userHasSecretKey.value = !userHasSecretKey.value;
};

// const handleForgotPassword = () => {
//   emit('authForm', 'showResetPassword');
// };
const route = useRoute();
const router = useRouter();

/**
 * Load user-specific data post-login
 */
const doPostLogin = async () => {
  return usePayments().refreshSubscription();
};

const doRedirect = async () => {
  let redirect = '';
  if (props.isShared) {
    redirect = 'Library';
  } else if (typeof route.query.redirect === 'string') {
    redirect = route.query.redirect;
  } else {
    redirect = '/';
  }
  await router.push(redirect);
};

const onSubmit = async (values, actions) => {
  const matomo = useMatomo();
  try {
    values.secretKey &&= removeWhitespace(values.secretKey);
    if (values.secretKey) {
      await pollen.logIn(values.email, values.password, values.secretKey);
      matomo.trackEvent('Auth', 'Login', 'Password & Secret');
    } else {
      await pollen.logIn(values.email, values.password);
      matomo.trackEvent('Auth', 'Login', 'Password Only');
    }
    await doPostLogin();
    if (!props.isPubFlow) {
      await doRedirect();
    }
    emit('close');
  } catch (err) {
    if (err instanceof IncorrectLoginError) {
      actions.setErrors({
        email: ' ', // mark the field as invalid with no message
        password: 'Enter a valid email and password.',
      });
      return;
    }

    throw err;
  }
};

const loginWithNostr = async () => {
  await pollen.logInWithNostr();
  const matomo = useMatomo();
  matomo.trackEvent('Auth', 'Login', 'Nostr');
  await doPostLogin();
  await doRedirect();
  emit('close');
};

const newDeviceSchema = z.object({
  secretKey: validateSecretKey,
});

const normalSchema = z.object({
  email: z.string().email({ message: 'Enter a valid email address.' }),
  password: z.string().min(8, { message: 'Password must be at least 8 characters long.' }),
});

const regularSchema = toTypedSchema(normalSchema);
const mergedSchema = toTypedSchema(normalSchema.merge(newDeviceSchema));
</script>

<template>
  <div class="form-wrapper form-auth">
    <div v-if="userHasSecretKey" class="form-heading">Log in</div>
    <div v-if="userHasSecretKey" class="form-description sub-heading">
      Please log in using the credentials you recieved when you created your account.
    </div>
    <div v-if="!userHasSecretKey">
      <p class="form-heading">Don't have your secret key?</p>
      <p class="form-heading">No Problem.</p>
      <p class="form-description sub-heading">
        You can still access most of Clovyr without it, but you'll need your secret key to connect
        to your library of apps.
      </p>
    </div>

    <div class="form">
      <FormWrapper
        v-if="userHasSecretKey"
        submit-label="Log In"
        :validation-schema="mergedSchema"
        @submit="onSubmit"
      >
        <template #fields>
          <FormField name="email" label="Email" shape="cubed" :model-value="emailParam" />

          <PasswordFormField
            name="password"
            label="Password"
            type="password"
            shape="cubed"
            reveal
          />

          <PasswordFormField
            name="secretKey"
            label="Secret Key"
            type="password"
            shape="cubed"
            :model-value="secretKeyParam"
            reveal
          >
            <template #tooltip>
              Your Secret Key can be found in your <a href="/">Recovery Kit</a>. Clovyr does not
              have access to your Secret Key and cannot help recover it if you lose it.
            </template>
          </PasswordFormField>
        </template>
      </FormWrapper>
      <FormWrapper
        v-else
        submit-label="Log In"
        :validation-schema="regularSchema"
        @submit="onSubmit"
      >
        <template #fields>
          <FormField name="email" label="Email" shape="cubed" :model-value="emailParam" />

          <PasswordFormField
            name="password"
            label="Password"
            type="password"
            shape="cubed"
            reveal
          />
        </template>
      </FormWrapper>

      <Button
        v-if="isNostrAvailable"
        class="button nostr-button"
        label="Log In With Nostr"
        @click="loginWithNostr"
      >
        <NostrLogo />
      </Button>

      <div class="form-callout">
        <!-- <span v-if="false" class="text--underline text--link" @click="handleForgotPassword"
          >Forgot password?</span
        > -->
        <span>Need an account?</span>
        <span class="button-inline button-primary" @click="handleSignup()">Sign up</span>
        <span
          v-if="isNostrAvailable"
          class="button-inline button-secondary"
          @click="handleSignup('showNostrEmailForm')"
          >Sign up with Nostr</span
        >
      </div>
      <div class="form-callout">
        <div v-if="userHasSecretKey">
          <span>Don't have your secret key?</span>
          <span class="button-inline button-primary" @click="toggleSecretKey">Click Here</span>
        </div>
        <div v-else>
          <span>Found your secret key?</span>
          <span class="button-inline button-primary" @click="toggleSecretKey">Click Here</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form-wrapper {
  max-width: 30rem;
}
.form-heading {
  .subtext {
    color: var(--Grey-1, #847398);
    font-size: 15px;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.15px;
    margin-top: 0.5rem;
  }
}
.sub-heading {
  max-width: 35rem;
}
.form-callout {
  > * {
    margin-left: 0.25rem;
  }
  div > * {
    margin-left: 0.25rem;
  }
}
.button {
  height: 2.85rem;
}
.nostr-button {
  background-color: #995ada;
  color: white;
  width: 100%;
  margin-top: 1rem;

  svg {
    width: 3rem;
    height: 2rem;
  }
}
.nostr-button:hover {
  background-color: color(active, primary);
}
</style>

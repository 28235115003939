import type { ErrorObject } from 'ajv';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import type { JSONSchema6 } from 'json-schema';

import { createRandomKey } from '@clovyr/pollen/crypto';
import type { Config, Manifest, Setting, Settings, Values } from '@clovyr/pollen/manifest';
import { SettingType } from '@clovyr/pollen/manifest';

/**
 * Uniquely identifies a LaunchConfigSetting, e.g. basic.setting1.nested_setting1.
 * By definition these are globally unique across all settings
 */
type LaunchConfigSettingId = string;

export type LaunchConfigSetting = {
  id: LaunchConfigSettingId;
  type: SettingType;
  required: boolean;
  random: boolean;
  schema: JSONSchema6;
  condition?: JSONSchema6;
  display_name: string;
  display_description?: string;
  tooltip?: string;
  options?: string[];
  subs?: Omit<LaunchConfigSetting, 'subs'>[];
  isTouched: boolean;
  answer?: AnswerValue;
  errors: ErrorObject[] | null | undefined;
};

export type AnswerValue = string | number | boolean | null;

type Answer = {
  value: AnswerValue;
  errors: ErrorObject[] | null | undefined;
  isTouched: boolean;
};

/**
 * Callback function for setting an answer value from within a component
 */
export type AnswerFn = (id: LaunchConfigSettingId, value: AnswerValue) => void;

export type AnswerMap = Record<LaunchConfigSettingId, Answer>;

export interface ConfigErrors {
  [key: string]: ErrorObject[];
}

const ajv = new Ajv();
addFormats(ajv);

export const manifest = ref<Manifest>();

export const answers = ref<AnswerMap>({});

export const configErrors = ref<ConfigErrors>({});

export const basicSettings = ref<LaunchConfigSetting[]>([]);
export const advancedSettings = ref<LaunchConfigSetting[]>([]);
export const deploySettings = ref<LaunchConfigSetting[]>([]);

/**
 * extract default Answer from Setting, if parsable
 * @param setting
 */
function getDefaultAnswer(setting: Setting): Answer {
  let value: AnswerValue = null;
  switch (setting.value.type) {
    case 'number':
      value = setting.value.default ? Number(setting.value.default) : null;
      break;
    case 'boolean':
      value = setting.value.default ? Boolean(setting.value.default) : false;
      break;
    case 'string':
      if (setting.value.default) {
        value = String(setting.value.default);
      } else if (setting.random) {
        // generate
        value = createRandomKey(setting.value.minLength || 32);
      } else {
        value = '';
      }
      break;
    default:
      break;
  }
  return {
    errors: undefined,
    isTouched: false,
    value,
  };
}

/**
 * Validate Answer against Schema from Setting type
 *
 * @param schema
 * @param answer
 *
 * @returns List of errors or null if valid answer
 */
export function validateAnswer(
  lcs: LaunchConfigSetting,
  answer?: AnswerValue,
  schema?: JSONSchema6,
): ErrorObject[] | null | undefined {
  // check condition
  let isSettingEnabled = true;
  if (lcs.condition && !schema) {
    // setting is only used when condition matches (no errors)
    const parentID = lcs.id.split('.').slice(0, -1).join('.');
    const parentAnswer = answers.value[parentID]?.value;
    const errors = validateAnswer(lcs, parentAnswer, lcs.condition);
    const conditionMatched = !!(!errors || errors.length === 0);
    isSettingEnabled = conditionMatched; // show if no errors (condition is valid)
  }

  let { required, random } = lcs;
  if (schema) {
    required = true;
    random = false;
  }

  if (
    (answer === null || answer === undefined || answer === '') &&
    (!required || random || !isSettingEnabled)
  ) {
    // skip validating empty values
    return null;
  }
  ajv.validate(schema || lcs.schema, answer);
  return ajv.errors;
}

/**
 * convert Setting to LaunchConfigSetting using current Answer, default answer, etc
 * include subsettings if present+valid
 *
 * @param id
 * @param setting
 */
function settingToLaunchConfig(id: string, setting: Setting): LaunchConfigSetting {
  let type: SettingType;
  let options: string[] | undefined;

  if (setting.value.enum && setting.value.enum.length > 0) {
    type = SettingType.enum;
    options = setting.value.enum.map((e) => e!.toString());
  } else {
    switch (setting.value.type) {
      case 'number':
        type = SettingType.number;
        break;
      case 'boolean':
        type = SettingType.boolean;
        break;
      default:
        type = SettingType.string;
        break;
    }
  }

  const lcs: LaunchConfigSetting = {
    id,
    type,
    required: !!setting.required,
    random: !!setting.random,
    schema: setting.value,
    condition: setting.condition,
    display_name: setting.name || setting.id || id,
    display_description: setting.description,
    tooltip: setting.tooltip,
    options,
  } as LaunchConfigSetting;

  const answer: Answer =
    answers.value[id] ||
    (() => {
      // generate and store value
      const defaultAnswer = getDefaultAnswer(setting);
      answers.value[id] = defaultAnswer;
      return defaultAnswer;
    })();

  answer.errors = validateAnswer(lcs, answer.value);
  const { value, errors, isTouched } = answer;
  lcs.answer = value;
  lcs.errors = errors;
  lcs.isTouched = isTouched;

  if (setting.sub_settings) {
    lcs.subs = Object.entries(setting.sub_settings).flatMap(([k, subSetting]) => {
      const subId = `${id}.${k}`;
      const sub = settingToLaunchConfig(subId, subSetting);
      return [sub];
    });
  }

  return lcs;
}

/**
 * Discriminate between Config and Setting types
 *
 * @param obj
 * @returns
 */
function isSetting(obj: Config | Setting): obj is Setting {
  return (obj as Setting).value !== undefined;
}

/**
 * Get the matching Manifest Setting for the given LCS ID
 *
 * @param id dotted ID of the form basic.setting1.nested_setting1
 * @returns
 */
export function getAppSetting(
  app: Manifest | undefined,
  id: LaunchConfigSettingId,
): Setting | undefined {
  if (app?.config) {
    let loc: Config | Setting = app.config;
    const paths = id.split('.');
    while (paths.length > 0) {
      // recursively search for subsettings if present in id
      const p = paths.shift()!;
      loc = isSetting(loc) ? loc.sub_settings?.[p] : loc[p];
    }
    if (isSetting(loc)) {
      return loc;
    }
  }
  return undefined;
}

/**
 * Test if the given LaunchConfigSetting (including children) have any
 * validation errors as defined by the Setting type from clovyr.yaml.
 *
 * TODO: throw err if no setting found? shouldn't happen
 */
export function isValidLaunchConfigSetting(lcs: LaunchConfigSetting): boolean {
  const errors = validateAnswer(lcs, lcs.answer);
  const hasErrors = !!errors && errors.length > 0;
  const hasSubErrors = (lcs.subs || []).some((s) => isValidLaunchConfigSetting(s));

  if (errors) {
    configErrors.value[lcs.id] ||= [];
    configErrors.value[lcs.id].push(...errors.filter((e) => !!e));
  }

  if (hasErrors || hasSubErrors) {
    console.warn(
      "Setting isn't valid. id:",
      lcs.id,
      'value:',
      lcs.answer,
      'errors?',
      hasErrors,
      errors,
      'subErrors?',
      hasSubErrors,
    );
  }

  return hasErrors || hasSubErrors;
}

/**
 * Add collected answers to values map
 *
 * @param values
 */
// eslint-disable-next-line @typescript-eslint/no-shadow
export function addAnswers(app: Manifest | undefined, answers: AnswerMap, values: Values) {
  Object.entries(answers).forEach(([answerID, answer]) => {
    const setting = getAppSetting(app, answerID);
    if (setting?.id && setting?.name && answer.isTouched && answer.value) {
      values[setting.id] = answer.value;
    }
  });
}

/**
 *
 * @param config
 * @param section Configuration section to convert
 * @returns
 */
function convertSettingsToLaunchConfigs(
  config: Config | null | undefined,
  section: string,
): LaunchConfigSetting[] {
  const settings: Settings = config?.[section] || {};
  return Object.entries(settings || {}).map(([k, setting]) => {
    return settingToLaunchConfig(`${section}.${k}`, setting);
  });
}

/**
 * Initialize launch settings for a new app
 *
 * @param app
 */
export function initSettings(app?: Manifest, initAnswers?: AnswerMap) {
  manifest.value = app;
  answers.value = initAnswers || {};
  basicSettings.value = convertSettingsToLaunchConfigs(app?.config, 'basic');
  advancedSettings.value = convertSettingsToLaunchConfigs(app?.config, 'advanced');
  deploySettings.value = convertSettingsToLaunchConfigs(app?.config, 'deployment');
}

/**
 * Find the LaunchConfigSetting with the given ID, including in nested settings
 *
 * @param id
 * @returns
 */
export function findLaunchSetting(id: LaunchConfigSettingId): LaunchConfigSetting | undefined {
  const allSettings = [basicSettings.value, advancedSettings.value, deploySettings.value];
  for (let i = 0; i < allSettings.length; i++) {
    for (let j = 0; j < allSettings[i].length; j++) {
      const setting = allSettings[i][j];
      if (setting.id === id) {
        return setting;
      }
      if (setting.subs) {
        const sub = setting.subs.find((s) => s.id === id);
        if (sub) {
          return sub;
        }
      }
    }
  }
  return undefined;
}

/**
 * True if any settings exist for this app
 */
export const hasSettings = computed(() => {
  return (
    !!basicSettings.value?.length ||
    !!advancedSettings.value?.length ||
    !!deploySettings.value?.length
  );
});

/**
 * returns true if all active settings are valid
 */
export const hasValidSettings = computed(() => {
  return !(
    basicSettings.value.some(isValidLaunchConfigSetting) ||
    advancedSettings.value.some(isValidLaunchConfigSetting)
  );
});

/**
 * returns true if all active settings are valid, including deployment settings.
 *
 * FIXME: do not love this. need to refactor.
 * issue is that during the first launch, we only want to validate basic & advanced. for
 * redeployment (when modifying config via app detail ui), we need to validate everything including
 * deployment configs which can be changed there.
 */
export const hasAllValidSettings = computed(() => {
  return !(
    basicSettings.value.some(isValidLaunchConfigSetting) ||
    advancedSettings.value.some(isValidLaunchConfigSetting) ||
    deploySettings.value.some(isValidLaunchConfigSetting)
  );
});

/**
 * set AnswerValue for a given LaunchConfigSettingId
 */
export function setAnswer(id: LaunchConfigSettingId, value: AnswerValue) {
  const lcs = findLaunchSetting(id);
  if (lcs) {
    const errors = validateAnswer(lcs, value);
    answers.value[id] = {
      value,
      isTouched: true,
      errors,
    };
    lcs.answer = value;
    lcs.errors = errors;
    lcs.isTouched = true;
  }
}

/**
 * returns all configured values -- duplicate keys are overwritten by later values
 * @param fullyQualified
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getAllSettings(fullyQualified = false): Record<string, string> {
  const values: Record<string, string> = {};
  const allSettings = [...basicSettings.value, ...advancedSettings.value];
  allSettings.forEach((s) => {
    const id: string = fullyQualified ? s.id : (s.id.split('.').pop() as string);
    if (s.answer) {
      values[id] = String(s.answer);
    }
  });
  return values;
}

export default {
  initSettings,
  getDefaultAnswer,
  setAnswer,
  settingToLaunchConfig,
};

import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { searchParamsToObj } from '@clovyr/pollen/http/qs';
import type { Manifest } from '@clovyr/pollen/manifest';

/**
 * Convert the given route to string
 *
 * @param to
 * @returns
 */
export function routeToString(to: RouteLocationNormalized): string {
  let url = to.fullPath;
  if (to.query) {
    let q = '';
    Object.entries(to.query).forEach(([k, v]) => {
      q = q === '' ? '?' : `${q}&`;
      q += `${k}=${v}`;
    });
    if (q !== '') {
      url += q;
    }
  }
  if (to.hash) {
    url += to.hash;
  }
  return url;
}

/**
 * Convert the given route to a URL-encoded string, suitable for passing in a query param
 *
 * @param to
 * @returns
 */
export function encodeRoute(to: RouteLocationNormalized): string {
  return encodeURIComponent(routeToString(to));
}

/**
 * Convert the given URL string back to a route object. String can optionally be URL-encoded.
 *
 * @param str
 * @returns
 */
export function decodeRoute(str: string): RouteLocationRaw {
  if (!str.match(/^(https?:\/)?\//)) {
    str = decodeURIComponent(str);
  }
  if (!str.match(/^https?:\/\//)) {
    // not fully-qualified, add scheme/host so we can parse
    str = `${window.location.protocol}//${window.location.host}${str}`;
  }

  const u = new URL(str);
  const s: RouteLocationRaw = { path: u.pathname };
  if (u.search) {
    s.query = searchParamsToObj(u.searchParams);
  }
  if (u.hash) {
    s.hash = u.hash;
  }
  return s;
}

export function appURL(app: Manifest): string {
  if (app.metadata.publisher && app.metadata.publisher !== 'clovyr') {
    return `/apps/${app.metadata.publisher}/${app.metadata.id}`;
  }
  return `/apps/${app.metadata.id}`;
}

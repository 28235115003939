<!-- eslint-disable import/no-unresolved -->
<script setup lang="ts">
import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import CopyIcon from '@clovyr/bed/components/icons/CopyIcon.vue';

import SettingOption from '@/components/blocks/SettingOption.vue';
import SettingsDetailList from '@/components/blocks/SettingsDetailList.vue';
import HiddenFieldReveal from '@/components/icons/HiddenFieldReveal.vue';

const props = defineProps({
  secretKey: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
});

// TODO: find nicer way to do this with proper secret key :D
const isSecretKeyVisible = ref(false);

const toggleSecretKeyView = () => {
  isSecretKeyVisible.value = !isSecretKeyVisible.value;
};

const secretKeyDots = `•••• ••••• ••••
•••• ••••• ••••
•••• ••••• ••••
•••• ••••• ••••`;

const isSecretKeyCopied = ref(false);
const secretKeyCopyFailed = ref(false);
const secretKeyNotificationClassname = computed(() => {
  return [!secretKeyCopyFailed.value ? 'secret-key-warning' : 'secret-key-success'];
});

const copySecretKey = () => {
  navigator.clipboard.writeText(props.secretKey).then(
    () => {
      isSecretKeyCopied.value = true;

      setTimeout(() => {
        isSecretKeyCopied.value = false;
      }, 2000);
    },
    () => {
      secretKeyCopyFailed.value = true;

      setTimeout(() => {
        secretKeyCopyFailed.value = false;
      }, 4000);
    },
  );
};
</script>

<template>
  <SettingOption :highlighted="true">
    <template #left>
      <div class="settings-secret-key">
        <SettingsDetailList class="settings-secret-key-info" label="Secret Key">
          <div class="secret-key">
            <template v-if="!isSecretKeyVisible">
              <pre class="secret-key-value">{{ secretKeyDots }}</pre>
            </template>

            <template v-if="isSecretKeyVisible">
              <pre class="secret-key-value">{{ props.secretKey }}</pre>
            </template>

            <Transition>
              <div
                v-if="isSecretKeyCopied || secretKeyCopyFailed"
                :class="secretKeyNotificationClassname"
                class="secret-key-notification"
              >
                {{ secretKeyCopyFailed ? 'Error' : 'Copied!' }}
              </div>
            </Transition>
          </div>
        </SettingsDetailList>

        <div class="button-group">
          <!-- TODO: Add tooltip for successful copy -->
          <Button :shape="ButtonShape.Circle" aria-label="Copy" @click="copySecretKey">
            <CopyIcon />
          </Button>

          <Button :shape="ButtonShape.Circle" aria-label="View Key" @click="toggleSecretKeyView">
            <HiddenFieldReveal :open="isSecretKeyVisible" />
          </Button>
        </div>
      </div>
    </template>
  </SettingOption>
</template>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.settings-secret-key {
  display: flex;

  .settings-secret-key-info {
    flex: 0 0 60%;

    .secret-key {
      column-gap: 2rem;
      display: flex;
      margin-top: 0.5rem;

      .secret-key-value {
        @include font-size(16);
      }

      .secret-key-notification {
        align-items: flex-end;
        display: flex;

        &.secret-key-warning {
          color: color(bad);
        }

        &.secret-key-success {
          color: color(action);
        }
      }
    }
  }

  .button-group {
    align-items: center;
    margin-left: auto;

    :deep(.button) {
      svg {
        stroke: none;
      }
    }
  }
}
</style>

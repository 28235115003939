<script setup lang="ts">
import { storeToRefs } from 'pinia';

import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';

import ClovyrEditor from '@/components/forms/ClovyrEditor.vue';
import FieldInfoIcon from '@/components/icons/FieldInfoIcon.vue';
import { useAppBuilder } from '@/composables/useAppBuilder';
import { usePublisher } from '@/composables/usePublisher';
import { useEditStore } from '@/stores/edit_store';

const props = defineProps<{ publisherID: string }>();

const { publisherID } = toRefs(props);
const { publisher } = usePublisher(publisherID);

const editStore = useEditStore();
const { pubApp, pubAppVersion } = storeToRefs(editStore);

const { onClickBuild, onClickRun, previewLink, buildStatus, buildOutput } = useAppBuilder(
  publisher,
  pubApp,
  pubAppVersion,
);
</script>

<template>
  <div class="actions">
    <div class="break"></div>
    <h4>App Actions</h4>
    <div class="flex-row button-group">
      <div class="col">
        <Button
          label="Build"
          @click="onClickBuild"
          :disabled="buildStatus !== 'inactive'"
          translucent
        />
      </div>
      <div class="col">
        <Button
          label="Run"
          @click="onClickRun"
          :disabled="buildStatus !== 'inactive'"
          translucent
        />
      </div>
      <div class="col">
        <Button label="Preview it" :link="previewLink" link-target="_blank" translucent />
      </div>
    </div>
  </div>
  <div class="build-output">
    <div class="textarea-block">
      <div class="textarea-block__label-holder">
        <label class="textarea-block__label">Build Console</label>
        <Tooltip
          class="info"
          content="This is the console output from the build process. If you encounter any issues, please review this output for more information."
        >
          <FieldInfoIcon />
        </Tooltip>
      </div>
      <ClovyrEditor
        class="textarea-block__input"
        language="shell"
        :model-value="buildOutput"
        resize
        readonly
        tail
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
/* App Action Buttons */
.actions {
  border-top: 1px solid color(grey, primary);
  margin-top: 3rem;
  padding-top: 2rem;

  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }

    .col {
      padding: space(0.5);
      width: 100% !important;
    }
    :deep(.button) {
      width: 100%;
    }
  }
  h4 {
    margin: 0 0 1rem 0;
  }
}

/* build output modal */
.build-output {
  .textarea-block {
    margin-top: 2rem;

    .textarea-block__input {
      height: 700px;
    }
    .textarea-block__label {
      margin-bottom: 0.5rem;
    }
  }
}
</style>

import type { PublisherAppVersion } from '../types';
import type { DockerCredentials } from '../types/DockerCredentials';

import { kcp, kexec, podName, writeFileCmd } from './util';
import { Builder } from '.';

export class RunBuilder extends Builder {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  protected buildScript(
    appVersion: PublisherAppVersion,
    imageName: string,
    dockerCredentials?: DockerCredentials
  ): string {
    return ``;
  }
  /* eslint-enable @typescript-eslint/no-unused-vars */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected runScript(appVersion: PublisherAppVersion, imageName: string): string {
    let s = `
set -eo pipefail
set -x

rm -rf /opt/clovyr/cicd/test
mkdir -p /opt/clovyr/cicd/test
cd /opt/clovyr/cicd/test

${writeFileCmd(
  'run.sh',
  `
#!/bin/sh
set -e
cd /home/clovyr/cicd/test
sudo docker compose up -d
`
)}

`;

    // append run app files (docker-compose, etc)
    if (appVersion.files) {
      appVersion.files.forEach((f) => {
        s += '\n';
        s += writeFileCmd(f.name, f.content);
        s += '\n';
      });
    }

    s += `

# run app in code container
chmod 755 *.sh

# copy into pod
${podName}
# copy files to both code & dind containers (so we can mount volumes properly)
${kexec('code', 'rm -rf /home/clovyr/cicd')}
${kexec('dind', 'rm -rf /home/clovyr/cicd')}
${kexec('code', 'mkdir -p /home/clovyr/cicd')}
${kexec('dind', 'mkdir -p /home/clovyr/cicd')}
${kcp('code', '/opt/clovyr/cicd/test', '/home/clovyr/cicd/test')}
${kcp('dind', '/opt/clovyr/cicd/test', '/home/clovyr/cicd/test')}
# run it
${kexec('code', '/home/clovyr/cicd/test/run.sh')}
  `;

    return s;
  }
}

import type { Params } from '../http/qs';
import type { ComputeProviderConfig, Instance, Request } from '../types';

export interface CreateInstanceRequest extends Request {
  name?: string;

  // Compute Instance Type
  instanceType?: string;
  // Compute Instance Region
  instanceRegion?: string;
  // Compute Instance Image
  instanceImage?: string;

  userData?: string;
}

export interface ListInstancesRequest extends Request {}
export interface GetInstanceRequest extends Request {
  id: string;
}
export interface UpdateInstanceRequest extends Request {
  instance: Instance;
}

export interface DestroyInstanceRequest extends Request {
  id: string;
  region?: string;
}
export interface DestroyInstanceResponse {}

/**
 * The Compute Provider API is used to manage cloud compute instances (VMs).
 * Implementations of this interface provide the ability to create and destroy
 * instances within a specific cloud provider.
 *
 * Three implementations are currently available: wNext, AWS EC2, and Digital
 * Ocean.
 *
 * Each implementation runs 100% client-side, i.e., in the browser, and will
 * only communicate with the given cloud provider.
 *
 * Returned instances are running the latest version of Ubuntu LTS (currently
 * 20.04) and are bootstrapped with our `instance-configure` service which
 * completes the instance setup via the remote_exec package.
 */
export interface ComputeProvider {
  // Config APIs
  getConfig: () => ComputeProviderConfig | undefined;
  setConfig: (config: ComputeProviderConfig) => void;
  isConfigured: () => boolean;

  // Metadata for creating instances

  /**
   * Get the cloud provider instance type and per-month pricing (in USD).
   */
  getInstanceType: (size: ComputeInstanceSize) => InstanceType;

  /**
   * Get the cloud provider image name/ID
   */
  getInstanceImage: (image: ComputeInstanceImage, region: string) => string;

  // Instance CRUD
  createInstance: (req: CreateInstanceRequest) => Promise<Instance>;
  listInstances: (req: ListInstancesRequest) => Promise<Array<Instance>>;
  getInstance: (req: GetInstanceRequest) => Promise<Instance | undefined>;
  updateInstance: (req: UpdateInstanceRequest) => Promise<Instance>;
  destroyInstance: (req: DestroyInstanceRequest) => Promise<DestroyInstanceResponse>;
}

/**
 * Implemented by providers which support OAuth for provisioning API tokens.
 */
export interface OAuthSupport extends ComputeProvider {
  createOAuthUrl(baseCallbackURI?: string, clientId?: string): string;
  getOAuthToken(): OAuthToken | undefined;
  setOAuthToken(params: Params | string): OAuthToken;
  revokeOAuthToken(): Promise<void>;
}

/**
 * Credentials for accessing Linode APIs
 */
export interface OAuthToken {
  /**
   * Access Token can be retrieved either via OAuth or directly entered by the
   * user using a Personal Access Token generated via the Linode console.
   */
  access_token: string;

  refresh_token?: string;
}

/**
 * Configuration needed for OAuth clients
 */
export interface OAuthConfig {
  CallbackURI: string;
  ClientID: string;
}

export enum ComputeInstanceSize {
  Micro = 'micro',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
  XLargeAI = 'xlarge-ai',
}

export enum ComputeInstanceRegion {
  USEast = 'us-east',
}

export enum ComputeInstanceImage {
  UbuntuLTS = 'ubuntu-lts',
  Ubuntu2204 = 'ubuntu-22.04',
  Ubuntu2004 = 'ubuntu-20.04',
}

/**
 * Map of clovyr sizes to provider-specific types & prices
 */
export type InstanceTypeMap = {
  [key in ComputeInstanceSize]: InstanceType;
};

/**
 * Tuple of provider-specific instance type/name and monthly price in USD
 */
export type InstanceType = [string, number];

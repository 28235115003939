<script setup lang="ts">
import { AppLogo, Button, ImageMedia } from '@clovyr/bed';
import logoPlaceholderImage from '@clovyr/bed/assets/images/logo-placeholder.svg';
import CLink from '@clovyr/bed/components/elements/CLink.vue';
import { WNextDeployment } from '@clovyr/pollen/deployment/wnext';
import { getHostByID } from '@clovyr/pollen/fixtures/hosts';
import { type ComputeProviderInfo, ComputeProviderName } from '@clovyr/pollen/types';

import Module from '@/components/elements/Module.vue';
import { useEventBus } from '@/stores/event_bus';

import { useAppStatus } from '../../composables/useAppStatus';

const props = defineProps<{
  id: string;
}>();

const { eventBus } = useEventBus();

const deploymentID = toRef(props, 'id');
const {
  deployment,
  app,
  sub,
  subItem,
  isClovyrCode,
  isWNext,
  isRunning,
  isSuspended,
  isTrial,
  trialTimeRemaining,
  wnextAppPrice,
  trialExpired,
} = useAppStatus(deploymentID);

const url = computed(() => {
  if (deployment.value?.fqdn) {
    return `http://${deployment.value.fqdn}`;
  }
  return '';
});

const urls = computed(() => {
  const { fqdn } = deployment.value;
  const d: { url: string; title: string }[] = [];
  if (url.value) {
    d.push({ url: url.value, title: fqdn! });
  }
  if (app.value?.deployment?.dns?.additional_hosts) {
    d.push(
      ...Object.keys(app.value.deployment.dns.additional_hosts).map((h) => ({
        url: `https://${h}-${fqdn}`,
        title: h,
      })),
    );
  }
  return d;
});

const host = computed(() => {
  return getHostByID(deployment.value?.hostingProvider || ComputeProviderName.WNext);
});

const hostLogo = computed((): ComputeProviderInfo => {
  return (
    host.value || {
      logoUrl: logoPlaceholderImage,
      id: ComputeProviderName.WNext,
      title: 'n/a',
      defaultRegion: 'n/a',
      regions: [],
      instanceTypes: [],
    }
  );
});

const onClickShowPaymentModal = (): void => {
  eventBus.emit('modal:payment:show', {
    id: deploymentID.value,
    name: deployment.value.appName,
    sub: sub.value!,
    subItem: subItem.value!,
  });
};

async function onClickOpen() {
  void new WNextDeployment().keepalive(deployment.value.id, deployment.value.fqdn!);
}
</script>

<template>
  <Module class="module--appessentials app-essentials" :bigHead="true" hed="App Essentials">
    <template #default>
      <div class="row">
        <!-- <div class="grid__col">
                  <div class="module__statinline">
                    <div class="module__statinline__label">RAM Usage:</div>
                    <div class="module__statinline__value">24MB</div>
                  </div>
                  <div class="module__statinline">
                    <div class="module__statinline__label">Space Remaining on Server Instance:</div>
                    <div class="module__statinline__value">1/16 GB</div>
                  </div>
                  <div class="module__statinline">
                    <div class="module__statinline__label">Time run this month:</div>
                    <div class="module__statinline__value">15/15 hr</div>
                  </div>
                </div> -->
        <div class="grid__col">
          <div class="module__statinline row">
            <div class="module__statinline__label grid__col-md-6">Hosted on:</div>
            <div class="hosting-heading grid__col-md-6">
              <template v-if="!isWNext">
                <div class="hosting-heading__left">
                  <AppLogo :logo="hostLogo" size="small" pad />
                </div>

                <div class="hosting-heading__right">
                  <span class="hosting-title">{{ host?.title || deployment.hostingProvider }}</span>
                </div>
              </template>
              <template v-else>
                <div class="hosting-heading__left">
                  <ImageMedia :filename="hostLogo.fullLogoUrl" />
                </div>
              </template>
            </div>
          </div>
          <div v-if="!isClovyrCode && isRunning && isTrial" class="module__statinline row">
            <div v-if="!trialExpired" class="module__statinline__label grid__col-md-12">
              You have <b>{{ trialTimeRemaining }} remaining</b> on your free trial. Loving the app?
              Sign up and keep the fun going for just ${{ wnextAppPrice }}/mo
            </div>
            <div v-if="trialExpired" class="module__statinline__label grid__col-md-12">
              Your free trial has expired. Loved the app? Sign up and keep the fun going for just
              ${{ wnextAppPrice }}/mo
            </div>
          </div>
          <div v-if="!isClovyrCode && ((isRunning && isTrial) || isSuspended)" class="row">
            <div class="hosting-heading__button">
              <Button
                label="upgrade hosting"
                :translucent="true"
                :reversed="true"
                @click="onClickShowPaymentModal"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #default2 v-if="url">
      <div class="row domain-access">
        <div class="module__statinline__label">Domain Access:</div>
      </div>
      <div class="frontend">
        <div class="row">
          <div class="module__statinline__sub-label">Frontend</div>
        </div>
        <div v-if="urls" class="row">
          <template v-for="u in urls" :key="u.url">
            <c-link
              :to="u.url"
              :label="u.title"
              target="_blank"
              class="module__statinline__sub-text fdqn"
              @click="onClickOpen"
              :class="isRunning ? '' : 'disabled'"
            />
          </template>
        </div>
        <div v-else class="row">
          <!-- TODO: what should we show here for code apps? -->
          <c-link
            :to="url"
            :label="url"
            target="_blank"
            class="module__statinline__sub-text"
            @click="onClickOpen"
            :class="isRunning ? '' : 'disabled'"
          />
        </div>
      </div>
      <!-- <div class="htop">
                <div class="row">
                  <div class="module__statinline__sub-label">HTOP Dashboard</div>
                </div>
                <div class="row">
                  <div class="module__statinline__sub-text">
                    http://lucky-hearing-egd63r7ifbdcg783uf3.wnext.app/explore
                  </div>
                </div>
              </div> -->
    </template>
    <template #default3 v-if="false">
      <div class="row">
        <div class="module__statinline__label">Backups:</div>
      </div>
      <div class="row">
        <div class="module__statinline__sub-text grid__col-md-6">Latest backup DATE</div>
        <div class="grid__col-md-6">
          <Button label="restore from backup" />
        </div>
      </div>
    </template>
  </Module>
</template>

<style scoped lang="scss">
.app-essentials {
  :deep(.module__head) {
    background-color: rgba(132, 115, 152, 0.1);
  }
  :deep(.module__body) {
    .module__statinline {
      margin-top: space(2);
      margin-bottom: space(1);
    }

    .hosting-heading__button {
      margin-top: 1rem;
    }
  }
  :deep(.default2) {
    background-color: rgba(34, 26, 51, 0.3);

    .domain-access,
    .frontend,
    .htop {
      margin-top: space(1);

      .module__statinline__sub-label {
        font-size: 12px;
        color: #ada6b7;
      }
      .module__statinline__sub-text {
        font-size: 14px;
        color: color(action, primary);
        font-family: IBM Plex Mono;
        height: 3rem;
        padding: 0.25rem 0 1rem 1rem;
      }
      .module__statinline__sub-text:hover {
        color: color(action, secondary);
      }

      .module__statinline__sub-text.disabled {
        pointer-events: none;
        color: color(action, tertiary);
      }
    }

    .frontend > .row:last-child {
      padding-bottom: 1rem;
      word-wrap: break-word;
    }
  }
  :deep(.default3) {
    background: var(
      --card-grad-2,
      linear-gradient(180deg, rgba(65, 59, 79, 0.5) 0%, #413b4f 91.78%)
    );
  }

  .hosting-heading {
    &__button {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>

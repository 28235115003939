import type { Values } from '../manifest';

import type { ComputeProviderName } from './Host';

export interface DeploymentBackup {
  snapshotID: string;
  name: string;
  createdAt: number;
  appVersion: string;
}

/**
 * Deployment describes an instance of a running application in the user's library.
 */
export interface Deployment {
  /**
   * ID (slug) for this deployment
   */
  id: string;

  /**
   * Timestamp of creation (ms since epoch, e.g., Date.now())
   */
  createdAt: number;

  /**
   * Timestamp of deletion
   */
  deletedAt?: number;

  /**
   * Name of this deployment
   *
   * TODO: rename to 'name'. Too confusing with 'instance.name'
   */
  instanceName: string;

  /**
   * Application which this is a deployment for
   */
  publisherID?: string;
  appID: string;
  appName: string;
  appVersion?: string;

  /**
   * Subscription item ID
   */
  subscriptionItemID?: string;

  /**
   * DNS Provider
   */
  dnsProvider: string;

  /**
   * Compute Provider
   */
  hostingProvider: ComputeProviderName;
  computeProviderConfigID?: string;

  /**
   * Pinned at start of library collection
   */
  isPinned: boolean;

  /**
   * Something to do with being able to convert to a paid app?
   */
  isUpgradable: boolean;

  /**
   * Runtime state for the app, keys for the server, backups, etc.
   */
  appSettings: Values;
  keys: DeploymentKeys;
  state: DeploymentState;
  fqdn?: string;
  instanceID?: number;

  /**
   * List of selected Clovyr addons. e.g., backups
   */
  addons?: DeploymentAddon[];

  /**
   * List of keys which are allowed to access this application
   */
  accessList: string[];

  /**
   * Whether or not the instance supports being resumed.
   */
  isResumable?: boolean;

  /**
   * Optional custom domain name for the deployment.
   */
  customDomain?: string;

  /**
   * The region the instance is deployed to.
   *
   * Region names are specific to each hosting provider.
   */
  region?: string;
  size?: string;

  backups?: DeploymentBackup[];
}

export interface DeploymentKeys {
  accessKey: string;
  secretKey: string;
  backupPassword: string;
  configureKey: string;
}

export enum DeploymentState {
  Created = 'created', // initial state during launch wizard
  Starting = 'starting', // i.e., resuming from sleep?

  LaunchFailed = 'launch-failed',

  CreatingDNS = 'creating-dns',
  LaunchingInstance = 'launching-instance',
  WaitingInstance = 'waiting-for-instance',
  LaunchedInstance = 'launched',
  UpdatingDNS = 'updating-dns',
  Deploying = 'deploying',

  Running = 'running',
  Updating = 'updating',
  Sleeping = 'sleeping',
  Waking = 'waking',
  Destroying = 'destroying',
  Destroyed = 'destroyed',
}

export enum DeploymentAddon {
  Backups = 'backups',
}

import { APIError } from '../api/APIError';
import { WNextBase } from '../api/wnext-base';
import { toQS } from '../http/qs';
import type { Instance } from '../types';

import type {
  CreateDeploymentRequest,
  CreateDeploymentResponse,
  DeleteDeploymentRequest,
  DeleteDeploymentResponse,
  GetDeploymentRequest,
  ListDeploymentsRequest,
  ServerSideDeployments,
  UNamedDeployment,
} from '.';

/**
 * TODO: this impl will likely go away as a server-side wNext deployment is not
 * really needed. All deployments should be handled 100% by the client.
 */
export class WNextDeployment extends WNextBase implements ServerSideDeployments {
  async listDeployments(req?: ListDeploymentsRequest): Promise<Array<UNamedDeployment>> {
    return this.unwrap(await this.client.get(`deployments${toQS(req)}`));
  }

  async getDeployment(req?: GetDeploymentRequest): Promise<UNamedDeployment | null> {
    if (req?.appId) {
      return this.unwrap(await this.client.get(`deployments/${req.appId}`));
    }
    if (req?.appName) {
      const deployments = await this.listDeployments({ appName: req.appName });
      if (deployments && deployments.length) {
        return deployments[0];
      }
    }
    return null;
  }

  async createDeployment(req: CreateDeploymentRequest): Promise<CreateDeploymentResponse> {
    const res = await this.unwrapRes(this.client.post(`/deployments`, req));
    if (!res?.data) {
      throw new Error('deployment not available');
    }
    if (res.data.instance && res.data.deployment) {
      return res.data as CreateDeploymentResponse;
    }
    throw new APIError('invalid response', 700);
  }

  async deleteDeployment(req: DeleteDeploymentRequest): Promise<DeleteDeploymentResponse> {
    return this.unwrap(await this.client.delete(`/deployments/${req.name}`));
  }

  /**
   * Send keepalive request
   *
   * @param deploymentName
   * @param fqdn
   */
  async keepalive(deploymentName: string, fqdn: string): Promise<boolean> {
    const res = await this.client.get(
      `deployments/${deploymentName}/keepalive?fqdn=${fqdn}&t=${Date.now()}`
    );
    if (res instanceof Error) {
      throw res;
    }
    switch (res.status) {
      case 204:
        return true;

      case 404:
        return false;

      default:
        throw new Error(`unexpected status code from keepalive endpoint: ${res.status}`);
    }
  }

  /**
   * Wakes up a suspended deployment
   *
   * @param instanceName - Server side name of the deployment
   */
  async wakeup(instanceName: string): Promise<Instance> {
    const res = await this.client.post(`deployments/${instanceName}/wakeup`, {});
    if (res instanceof Error) {
      throw res;
    }
    if (res.status !== 200) {
      throw new Error(`unexpected status code from wakeup endpoint: ${res.status}`);
    }
    return res.json();
  }
}

import { WebglAddon } from '@xterm/addon-webgl';
import { type IDisposable, Terminal } from '@xterm/xterm';
import type { IRenderDimensions } from '@xterm/xterm/src/browser/renderer/shared/Types';
import debounce from 'lodash.debounce';
import utf8 from 'utf8';

const theme = {
  foreground: '#F8F8F8',
  background: '#221A33',
  selection: '#5DA5D533',
  black: '#1E1E1D',
  brightBlack: '#262625',
  red: '#CE5C5C',
  brightRed: '#FF7272',
  green: '#5BCC5B',
  brightGreen: '#72FF72',
  yellow: '#CCCC5B',
  brightYellow: '#FFFF72',
  blue: '#5D5DD3',
  brightBlue: '#7279FF',
  magenta: '#BC5ED1',
  brightMagenta: '#E572FF',
  cyan: '#5DA5D5',
  brightCyan: '#72F0FF',
  white: '#F8F8F8',
  brightWhite: '#FFFFFF',
};

const MINIMUM_COLS = 2;
const MINIMUM_ROWS = 1;

export class Xterm {
  elem: HTMLElement;

  term: Terminal;

  resizeListener: () => void;

  message: HTMLElement;

  messageTimeout: number;

  messageTimer: NodeJS.Timeout | undefined;

  disposables: IDisposable[] = [];

  initialHeightDiff: number | undefined;

  constructor(elem: HTMLElement) {
    this.elem = elem;
    const isWindows = ['Windows', 'Win16', 'Win32', 'WinCE'].indexOf(navigator.platform) >= 0;
    this.term = new Terminal({
      theme,
      cursorStyle: 'block',
      cursorBlink: true,
      windowsMode: isWindows,
      fontFamily:
        'DejaVu Sans Mono, Everson Mono, FreeMono, Menlo, Terminal, monospace, Apple Symbols',
      fontSize: 12,
    });

    this.message = elem.ownerDocument.createElement('div');
    this.message.className = 'xterm-overlay';
    this.messageTimeout = 2000;

    // debouncing avoids redrawing too frequently, causing the term to grow vertically
    this.resizeListener = debounce(this.resize, 10);

    this.term.open(elem);

    this.term.focus();
    this.resizeListener();
    window.addEventListener('resize', () => {
      this.resizeListener();
    });
  }

  info(): { columns: number; rows: number } {
    return { columns: this.term.cols, rows: this.term.rows };
  }

  output(data: string) {
    this.term.write(utf8.decode(data));
  }

  resize() {
    if (!this.term.element || !this.term.element.parentElement) {
      return;
    }

    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    const core = (this.term as any)._core;
    // eslint-disable-next-line no-underscore-dangle
    const dims: IRenderDimensions = core._renderService.dimensions;

    if (dims.css.cell.width === 0 || dims.css.cell.height === 0) {
      return;
    }

    const scrollbarWidth = this.term.options.scrollback === 0 ? 0 : core.viewport.scrollBarWidth;

    const htmlRect = document.documentElement.getBoundingClientRect();
    const termRect = this.term.element.getBoundingClientRect();
    const parentRect = this.term.element.parentElement.getBoundingClientRect();

    let parentElementHeight = parentRect.height;
    if (parentRect.bottom > window.innerHeight) {
      // try to stop it from stretching off the bottom of the window
      parentElementHeight -= parentRect.bottom - window.innerHeight;
    }
    const parentElementWidth = Math.max(0, parentRect.width);
    const elementStyle = window.getComputedStyle(this.term.element!);
    const elementPadding = {
      top: parseInt(elementStyle.getPropertyValue('padding-top'), 10),
      bottom: parseInt(elementStyle.getPropertyValue('padding-bottom'), 10),
      right: parseInt(elementStyle.getPropertyValue('padding-right'), 10),
      left: parseInt(elementStyle.getPropertyValue('padding-left'), 10),
    };
    const elementPaddingVer = elementPadding.top + elementPadding.bottom;
    const elementPaddingHor = elementPadding.right + elementPadding.left;

    let availableHeight = parentElementHeight - elementPaddingVer;
    const availableWidth = parentElementWidth - elementPaddingHor - scrollbarWidth;

    if (!this.initialHeightDiff) {
      this.initialHeightDiff = htmlRect.bottom - (termRect.top + availableHeight);
    } else if (termRect.top + availableHeight >= htmlRect.bottom - this.initialHeightDiff) {
      // if new bottom of term will be below the bottom of the window, shrink the term
      availableHeight = htmlRect.bottom - parentRect.top - this.initialHeightDiff;
    }

    const geometry = {
      cols: Math.max(MINIMUM_COLS, Math.floor(availableWidth / dims.css.cell.width)),
      rows: Math.max(MINIMUM_ROWS, Math.floor(availableHeight / dims.css.cell.height)),
    };

    if (this.term.rows !== geometry.rows || this.term.cols !== geometry.cols) {
      // eslint-disable-next-line no-underscore-dangle
      core._renderService.clear();
      this.term.resize(geometry.cols, geometry.rows);
    }

    this.term.scrollToBottom();
    this.showMessage(`${String(this.term.cols)}x${String(this.term.rows)}`, this.messageTimeout);
  }

  showMessage(message: string, timeout: number) {
    this.message.textContent = message;
    this.elem.appendChild(this.message);

    if (this.messageTimer) {
      clearTimeout(this.messageTimer);
    }
    if (timeout > 0) {
      this.messageTimer = setTimeout(() => {
        this.elem.removeChild(this.message);
      }, timeout);
    }
  }

  removeMessage(): void {
    if (this.message.parentNode === this.elem) {
      this.elem.removeChild(this.message);
    }
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  setWindowTitle(title: string) {
    // TODO: do something with title
    // document.title = title;
  }

  setPreferences(value: object) {
    Object.keys(value).forEach((key) => {
      if (key && key === 'enable-webgl') {
        this.term.loadAddon(new WebglAddon());
      }
    });
  }

  onInput(callback: (input: string) => void) {
    this.disposables.push(
      this.term.onData((data) => {
        callback(data);
      }),
    );
  }

  onResize(callback: (colmuns: number, rows: number) => void) {
    this.disposables.push(
      this.term.onResize((data) => {
        callback(data.cols, data.rows);
      }),
    );
  }

  deactivate(): void {
    this.disposables.forEach((d) => d.dispose());
    this.term.blur();
  }

  reset(): void {
    this.removeMessage();
    this.term.clear();
  }

  close(): void {
    window.removeEventListener('resize', this.resizeListener);
    this.term.dispose();
  }
}

<script setup lang="ts">
import { ClovyrLogo, GithubIcon, TwitterIcon } from '@clovyr/bed';
import { GridItem, GridSection } from '@clovyr/bed/components/blocks';
import useMediaQuery from '@clovyr/bed/composables/useMediaQuery';
import { tagline } from '@clovyr/bed/const';

const isTablet = ref(useMediaQuery('tablet'));
</script>

<template>
  <div class="footer">
    <div class="container">
      <div class="top">
        <router-link to="/" class="">
          <ClovyrLogo />
        </router-link>
      </div>
      <!-- end:top -->

      <div class="middle">
        <div v-if="!isTablet" class="left-spacer">&nbsp;</div>

        <div class="summary">
          <div class="heading">What is Clovyr?</div>

          <div class="body">{{ tagline }}</div>
        </div>

        <div class="sitemap">
          <ul class="sitemap__section">
            <li class="sitemap__link">
              <a href="https://clovyr.io/about">about</a>
            </li>

            <li class="sitemap__link">
              <a href="https://clovyr.io/features">features</a>
            </li>

            <li class="sitemap__link">
              <a href="https://clovyr.io/pricing">pricing</a>
            </li>

            <li class="sitemap__link">
              <a href="https://clovyr.io/press">press</a>
            </li>
          </ul>

          <ul class="sitemap__section">
            <li class="sitemap__link">
              <a href="https://clovyr.io/contact">contact</a>
            </li>

            <!-- <li class="sitemap__link">
                <a href="https://clovyr.app/code" target="_blank">try clovyr code beta</a>
              </li> -->

            <li>
              <div class="social-links">
                <div class="social-links__link">
                  <a href="https://github.com/clovyr" target="_blank">
                    <GithubIcon />
                  </a>
                </div>

                <div class="social-links__link">
                  <a href="https://twitter.com/growclovyr" target="_blank">
                    <TwitterIcon />
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- end:middle -->

      <GridSection class="bottom">
        <GridItem class="copyright" :md="7">
          <p class="copyright__line">&copy; 2018-2023 Clovyr Co. All Rights Reserved.</p>

          <p class="copyright__line">
            All trademarks, logos, and brands are the property of their respective owners.
          </p>

          <p class="copyright__line">
            All company, product, and service names used on this website are for identification
            purposes only.
          </p>
        </GridItem>

        <GridItem class="legal-links" :md="5">
          <a class="legal-links__link" href="https://clovyr.io/privacy-policy">Privacy Policy</a>
          <a class="legal-links__link" href="https://clovyr.io/terms-of-service"
            >Terms of Service</a
          >
        </GridItem>
      </GridSection>
      <!-- end:bottom -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  background-color: color(background);
  padding: 3rem 1rem;

  > .container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      padding-left: calc(1.875rem + 8vw);
      padding-right: calc(1.875rem + 8vw);
    }

    .top {
      :deep(.clovyr-logo) {
        width: 120px;
      }
    }

    .middle {
      display: flex;
      flex-direction: row;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }

      .left-spacer {
        max-width: 275px;
        width: 100%;
      }

      .summary {
        padding-top: 2em;
        padding-right: 4rem;

        @include media-breakpoint-up(md) {
          width: 50%;
          padding-right: 1rem;
        }

        @include media-breakpoint-up(lg) {
          padding-top: 0;
          padding-right: 5rem;
        }

        .heading {
          @include font(work, medium, 19, 1.68);

          margin-bottom: 0.75rem;
          font-size: 1.25rem;
          color: color(active);

          @include media-query(phone) {
            margin-bottom: 0.25rem;
          }
        }

        .body {
          font-size: 1rem;
          color: color(grey, tertiary);
        }
      }

      .sitemap {
        margin-top: space(2);

        @include media-breakpoint-up(md) {
          margin-top: 0;
        }

        @include media-breakpoint-up(lg) {
          width: 50%;
        }

        &__link {
          @include font(mono, 400, 15, 1.6, true);

          margin-bottom: 0.75rem;
          color: color(action, secondary);

          &:hover {
            color: color(white);
          }
        }

        .social-links {
          margin-top: space(2);

          @include media-query(tablet) {
            margin-top: space(3);
          }

          &__link {
            display: inline-block;

            :deep(svg) {
              fill: color(action, secondary);
            }

            &:hover {
              :deep(svg) {
                fill: color(white);
              }
            }

            &:not(:last-child) {
              margin-right: space(2.4);
            }
          }
        }
      }
    }

    .bottom {
      margin-top: 4rem;

      :deep(.grid) {
        flex-wrap: wrap;
      }

      .copyright {
        @include font-size(12);

        line-height: 1.5;
        color: color(grey, secondary);
        padding: 0;

        &__line {
          &:not(:last-child) {
            margin-bottom: space(0.5);

            @include media-query(mobile) {
              margin-bottom: space(1);
            }
          }
        }
      }

      .legal-links {
        font-size: 0.75rem;
        color: color(grey, tertiary);
        margin-bottom: space(2.9);
        margin-top: space(3);
        padding-left: 0;

        @include media-breakpoint-up(md) {
          padding-left: 4.5rem;
          margin-bottom: 0;
          margin-top: 0;
        }

        &__link {
          @include font-size(12);

          color: color(grey, tertiary);

          &:not(:last-child) {
            margin-right: space(2);
          }
        }

        &--tablet {
          @include show-on(tablet);

          margin-top: space(2.9);
        }
      }
    }
  }
}
</style>

<template>
  <div class="file-pill">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.file-pill {
  padding: 10px 24px;
  border-radius: 48px;
  background: rgba(34, 26, 51, 0.6);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  width: fit-content;

  &:hover {
    background-color: color(grey, primary);
    & > :deep(.trash-icon) {
      stroke: white;
    }
  }

  &.dropping {
    border: 2px dashed #d375ff;
    background: rgba(34, 26, 51, 0.27);
    color: color(grey, primary);
  }
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { Button, ImageMedia } from '@clovyr/bed';
import { AppLogo, type Logo } from '@clovyr/bed';
import ottoFloating from '@clovyr/bed/assets/images/otto-floating.svg';
import markdown from '@clovyr/bed/components/blocks/Markdown.vue';
import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import Copy from '@clovyr/bed/components/elements/Copy.vue';
import { WNextDeployment } from '@clovyr/pollen/deployment/wnext';
import { getPrimaryDNS } from '@clovyr/pollen/manifest';
import { replaceVariables } from '@clovyr/pollen/manifest/util';

import Pin from '@/components/controls/Pin.vue';
import Trash from '@/components/controls/Trash.vue';
import Module from '@/components/elements/Module.vue';
import Spinner from '@/components/elements/Spinner.vue';
import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import EditIcon from '@/components/icons/EditIcon.vue';
import GardenIcon from '@/components/icons/GardenIcon.vue';
import LibraryIcon from '@/components/icons/LibraryIcon.vue';
import ShareIcon from '@/components/icons/ShareIcon.vue';
import { useAppHead } from '@/composables/useAppHead';
import { useAppVersion } from '@/composables/useAppVersion';
import { usePayments } from '@/composables/usePayments';
import { usePollenStore } from '@/stores/pollen_store';
import { FeatureFlag, useUserFlagsStore } from '@/stores/user_flags';
import AccountLoggedOut from '@/views/Settings/AccountLoggedOut.vue';

import Modal from '../../components/elements/Modal.vue';
import { useAppStatus } from '../../composables/useAppStatus';
import AuthForm from '../Settings/AuthForm.vue';
import { ShowAuthForm } from '../Settings/types';

import AppConfig from './AppConfig.vue';
import AppEssentials from './AppEssentials.vue';
import AppLogs from './AppLogs.vue';
import AppMailProxy from './AppMailProxy.vue';
import AppStatus from './AppStatus.vue';
import DeleteApp from './DeleteApp.vue';
import InternalTools from './InternalTools.vue';
import RenameApp from './RenameApp.vue';
import Sharing from './Sharing.vue';
import Troubleshooting from './Troubleshooting.vue';
import Upgrade from './Upgrade.vue';
import VersionsBackups from './VersionsBackups.vue';

const props = defineProps<{
  id: string;
}>();

const payments = usePayments();
const pollenStore = usePollenStore();
const userFlagStore = useUserFlagsStore();

const { isInternal } = storeToRefs(userFlagStore);

const deploymentID = toRef(props, 'id');
const { deployment, app, sub, refreshSubItem, isClovyrCode, isRunning } =
  useAppStatus(deploymentID);

const { isUserAuthenticated } = storeToRefs(pollenStore);

const gettingStarted = computed(() => {
  if (!(app.value?.metadata?.gettingStarted && deployment.value)) {
    return '';
  }

  return replaceVariables(app.value.metadata.gettingStarted, deployment.value.appSettings);
});

const showAuthDialog = ref(false);
const showDeleteModal = ref(false);
const showRenameModal = ref(false);

const hasMailHog = computed(() => {
  return !!app.value.deployment?.addons?.mailhog;
});

const onClickRenameApp = () => {
  showRenameModal.value = true;
};

const onClickDeleteApp = async () => {
  showDeleteModal.value = true;
};

const logo = computed((): Logo => {
  return {
    logoUrl: app.value.metadata?.logoUrl,
    logoBackgroundColor: app.value.metadata?.logoBackgroundColor,
  };
});

/**
 * Link to the application when clicking the 'open' button.
 *
 * For code environments its san internal URL with the 'view' iframe.
 * For all others, opens the external URL to the server.
 *
 */
const appLink = computed(() => {
  if (isClovyrCode.value) {
    // TODO: fix the link between 'app' & clovyr-code. we know in PublisherApp if it's code or not,
    // perhaps we use that link here? or a new field in the app manifest?
    return `/view/${deployment.value!.instanceName}`;
  }
  let host = deployment.value.customDomain || deployment.value.fqdn;
  const d = getPrimaryDNS(app.value);
  if (d) {
    host = `${d[0]}-${host}`;
  }
  return `https://${host}`;
});

async function onClickOpen() {
  void new WNextDeployment().keepalive(deployment.value.id, deployment.value.fqdn!);
}

// const onClickMail = () => {
//   void router.push({ name: 'LibraryAppMail', params: { id: deployment.value.id } });
// };

async function mounted() {
  useAppHead(app.value.metadata, deployment.value.instanceName);
  sub.value = await payments.getSubscription();
  await refreshSubItem();
}

onMounted(async () => {
  void mounted();

  if (isRunning.value) {
    void useAppVersion(deploymentID).runPostLaunchTasks();
  }
});

function handleTogglePin() {
  if (!deployment.value) {
    return;
  }
  // eslint-disable-next-line vue/no-mutating-props
  deployment.value.isPinned = !deployment.value.isPinned;
  void pollenStore.pollen.putDeployment(deployment.value);
}

const hasAppConfigFlag = computed(() => {
  return userFlagStore.userHasFeatureFlag(FeatureFlag.AppConfig);
});
const isShared = computed(() => {
  return false;
});

const { pollen } = usePollenStore();

async function getSharingLink() {
  const link = await pollen.shareDeployment(deploymentID.value);
  await navigator.clipboard.writeText(link);
  return link;
}

const showUnfollowModal = ref(false);

const handleUnfollow = () => {
  // TODO: implement unfollow
  showUnfollowModal.value = false;
};
</script>

<template>
  <div
    v-if="deployment && app"
    class="app-instance"
    :class="{
      'app--shared': isShared,
    }"
  >
    <div class="app-instance__top">
      <div class="app-background"></div>

      <div class="container">
        <div class="row">
          <div class="grid__col">
            <router-link to="/library" class="app-instance__back text text--mono">
              <ArrowBackIcon />
              Back to App Library
            </router-link>
          </div>
        </div>

        <div class="app-instance__hero">
          <div class="app-instance__hero__grid grid">
            <div class="app-instance__hero__logo">
              <AppLogo size="large" :logo="logo" />
            </div>

            <div class="app-instance__hero__title grid__col">
              <div v-if="!isShared" class="app-header__preheading text text--color-grey-secondary">
                {{ deployment.appName }}
              </div>
              <div v-if="isShared" class="app-header__preheading">
                {{ deployment.appName }} is shared with you
              </div>

              <div class="app-header__heading-container">
                <h2 class="app-header__heading">{{ deployment.instanceName }}</h2>

                <div v-if="!isShared" class="app-edit-icon">
                  <EditIcon @click="onClickRenameApp" />
                </div>
              </div>
            </div>

            <div class="app-instance__hero__buttons grid__col">
              <Button v-if="isShared" @click="showUnfollowModal = true" label="unfollow" />
              <Pin @click="handleTogglePin" :pinned="deployment.isPinned"></Pin>
              <Button @click="onClickDeleteApp" :shape="ButtonShape.Circle">
                <Trash />
              </Button>

              <!-- TODO: Uncomment when ready to use share modal -->
              <!-- <Copy
                :icon="ShareIcon"
                hasModal
                :translucent="false"
                @openModal="handleShowShareModal"
                :disabled="!isRunning || !isUserAuthenticated || isShared"
              /> -->
              <Tooltip
                class="share-tooltip"
                placement="bottom"
                theme="narrow"
                arrow
                content="share link"
              >
                <Copy
                  :icon="ShareIcon"
                  :text="getSharingLink"
                  :translucent="false"
                  :disabled="!isRunning || !isUserAuthenticated || isShared"
                />
              </Tooltip>
              <Button
                class="open"
                label="Open"
                :reversed="true"
                :link="appLink"
                link-target="_blank"
                @click="onClickOpen"
                :disabled="!isRunning"
              />
            </div>
          </div>
        </div>

        <AppStatus v-if="!isClovyrCode" :id="deployment.id" />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="grid__col-md-12">
          <AppMailProxy v-if="hasMailHog" :id="deployment.id" />
        </div>
      </div>
      <div class="app-instance__twoup-modules row">
        <div class="app-instance__twoup-modules__left grid__col-md-6">
          <!-- module: app essentials -->
          <AppEssentials :id="id" />

          <Troubleshooting v-if="!isClovyrCode" :id="id" />

          <!-- TODO: Uncomment when ready to use share modal -->
          <!-- <Sharing
            :id="id"
            v-if="isInternal && !isShared"
            @show-share-modal="handleShowShareModal"
          /> -->
          <Sharing v-if="isUserAuthenticated" :id="id" :link="getSharingLink" />

          <!-- hide for now -->
          <AppLogs v-if="false && isInternal && isRunning && !isClovyrCode" :id="id" />
        </div>

        <div class="app-instance__twoup-modules__right grid__col-md-6">
          <!-- module: app access -->
          <!-- show this only if there is more than one url available? -->
          <!-- <Module class="module--appaccess" hed="App Access">
            <div class="module--stat">
              <div class="module__stat__label">LND Connect:</div>
              <div class="module__stat__value module__stat__value--mono module__stat__value--link">
                <a>
                  https://rpc-url-isverylong/d5678
                  <ArrowForwardsIcon />
                </a>
              </div>
            </div>
          </Module> -->

          <!--Module: Getting Started-->
          <Module
            v-if="gettingStarted"
            :bigHead="true"
            :hed="'Getting Started'"
            class="getting-started"
            ><!--v-if="showGettingStarted"-->
            <template #default>
              <div class="row">
                <div class="grid__col">
                  <div class="module__blurb">
                    <markdown :content="gettingStarted" />
                  </div>
                </div>
              </div>
            </template>
          </Module>

          <!--Module: Versions-->
          <div class="row versions" v-if="isInternal">
            <div class="grid__col">
              <Upgrade :id="deployment.id" />
            </div>
          </div>

          <!--Module: Versions & Backups-->
          <div class="row versions" v-if="isInternal">
            <div class="grid__col">
              <VersionsBackups :id="deployment.id" />
            </div>
          </div>

          <!--Module: Internal Tools -->
          <div class="row versions" v-if="isInternal">
            <div class="grid__col">
              <InternalTools :id="deployment.id" />
            </div>
          </div>

          <!-- module: welcome -->
          <Module
            class="welcome"
            bigHead
            hed="Welcome to Clovyr!"
            dek="One home for all your self-hosted applications, no matter where they run. Take a look at the left-hand sidebar to find permanent links to the Clovyr Garden, your Library of apps, Account settings, and more."
          >
            <template #heading>
              <ImageMedia :filename="ottoFloating" />
            </template>

            <template #default v-if="!isUserAuthenticated">
              <div class="section-1">
                <div class="row module__blurb__body">Create an Account</div>
                <div class="row">
                  To synchronize your account data and enable access from multiple devices, create a
                  Clovyr account.
                </div>
              </div>

              <div class="row">
                <div class="grid__col-md-6"></div>
                <div class="hosting-heading__button grid__col-md-6">
                  <AccountLoggedOut
                    :display-plain-button="true"
                    :is-user-authenticated="isUserAuthenticated"
                  />
                </div>
              </div>
            </template>

            <template #default2>
              <div class="links">Helpful links:</div>
              <div class="row">
                <div class="grid__col">
                  <div class="module__blurb">
                    <div class="module__blurb__head">
                      <router-link to="/" class="module__blurb__head__inner">
                        The Garden
                        <GardenIcon />
                      </router-link>
                    </div>
                    <div class="module__blurb__body">
                      The app marketplace. Find new apps that treat you better.
                    </div>
                  </div>
                </div>
                <div class="grid__col">
                  <div class="module__blurb">
                    <div class="module__blurb__head">
                      <router-link to="/library" class="module__blurb__head__inner">
                        My Library
                        <LibraryIcon />
                      </router-link>
                    </div>
                    <div class="module__blurb__body">
                      Where you access and manage all of your launched applications.
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Module>

          <div class="row" v-if="isInternal || hasAppConfigFlag">
            <div class="grid__col">
              <AppConfig :id="deployment.id" />
            </div>
          </div>

          <!--Module:Event Log-->
          <Module v-if="false" :bigHead="true" :hed="'Event Log'" class="event-log">
            <template #default>
              <div class="gradient"></div>
              <div class="log-text"></div>
            </template>
          </Module>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <Spinner></Spinner>
  </div>

  <Modal v-if="showUnfollowModal" size="medium" hide-close>
    <template #body>
      <div class="unfollow-modal--text">
        <h2>Are you sure you want to unfollow {{ app.metadata.name }}</h2>
        <h4>You will no longer be able to access this app once you unfollow.</h4>
      </div>
    </template>
    <template #controls-right>
      <Button label="cancel" @click="showUnfollowModal = false" />
      <Button label="unfollow" reversed @click="handleUnfollow" />
    </template>
  </Modal>

  <Modal
    v-if="showAuthDialog"
    theme="transparent"
    :size="'narrow'"
    has-icon
    @close="showAuthDialog = false"
  >
    <template #body>
      <AuthForm :active-option="ShowAuthForm.Signup" />
    </template>
  </Modal>

  <DeleteApp
    v-if="showDeleteModal"
    :app="app"
    :deployment="deployment"
    @close="showDeleteModal = false"
  />

  <RenameApp
    v-if="showRenameModal"
    :app="app"
    :deployment="deployment"
    @close="showRenameModal = false"
  />

  <router-view></router-view>
</template>

<style scoped lang="scss">
.getting-started {
  :deep(.module__head) {
    background-color: rgba(132, 115, 152, 0.1);
  }
  :deep(.module__body) {
    color: #e0dde4;
  }
}

.welcome {
  .hosting-heading {
    &__button {
      display: flex;
      justify-content: flex-end;

      :deep(.plain-button) {
        display: flex;
      }
    }
  }
  :deep(.module__head) {
    background-color: rgba(132, 115, 152, 0.1);
  }
  :deep(.module__head__grid) {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-only(md) {
      flex-direction: column;
      align-items: center;
    }

    .module__head__right {
      margin-top: 1rem;
    }
  }

  :deep(.module__head__left) {
    .module__head__sub {
      margin-bottom: 1rem;
    }
  }
  :deep(.module__head__right) {
    flex: none;
    width: space(15);
    padding-right: 0;
    padding-left: 0;
  }

  :deep(.module__body) {
    color: #e0dde4;
    padding-top: space(3);

    .section-1 {
      margin-left: space(1);
      margin-right: space(1);
      margin-bottom: space(3);
      font-size: 16px;

      .module__blurb__body {
        margin-bottom: space(2);
      }
    }
  }
  :deep(.default2) {
    color: #e0dde4;
    background: var(--30-midnight, rgba(34, 26, 51, 0.3));

    .row {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      > * {
        margin-top: 1rem;
      }

      .module__blurb__head svg {
        @include media-breakpoint-down(sm) {
          margin-left: unset;
        }
      }
    }

    .links {
      color: #ada6b7;
      font-size: 16px;
      margin-bottom: space(3);
    }
  }
}
.event-log {
  :deep(.module__head) {
    padding-bottom: space(1);
  }

  :deep(.module__body) {
    color: #ada6b7;
    background-color: #1a122d;
    height: space(20);
    overflow-y: scroll;

    // .gradient {
    //   background: linear-gradient(180deg, #221A33 0%, rgba(34, 26, 51, 0.00) 100%);
    //   height: 20px;
    // }
    .log-text {
      color: #ada6b7;
      font-size: 12px;
      font-family: IBM Plex Mono;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  :deep(.module__head) {
    background-color: #1a122d;
    padding-top: space(3);
  }

  // ::-webkit-scrollbar {
  //   width: 5px;
  //   border-radius: 4px;
  // }
}

.app-instance__hero__buttons {
  height: space(5);
  justify-content: flex-end;

  a {
    margin-top: space(0.2);
    width: fit-content;
  }

  button {
    margin-top: space(0.3);
  }
}

.app--shared {
  .app-background::after {
    background: linear-gradient(180deg, rgba(132, 91, 57, 0.629) 50%, rgb(34 26 51 / 60%) 100%);
    backdrop-filter: blur(10px);
  }
  .app-header__preheading {
    color: color(yellow, primary);
  }
  .open {
    background-color: color(collection-2, primary);
  }
  :deep(.unpinned-icon) {
    circle {
      stroke: rgb(203, 189, 189);
    }
    path {
      fill: #cbbddb;
      stroke: #cbbddb;
    }
  }
}

.share-tooltip {
  margin-right: 0.75rem;
}

//Share modal styles?
.share-btn {
  margin-right: 1rem;
  :deep(.check-icon) {
    margin-left: 0.25rem;
    height: 1rem;
    path {
      stroke-width: 3px;
    }
  }
}
.share-btn:hover {
  :deep(.link-icon) {
    path {
      stroke: #000;
      fill: #000;
    }
  }
}
.link-icon {
  margin-left: 0.5rem;
}
.share-container {
  .header-left {
    display: flex;
    justify-content: space-between;
  }
  .form {
    display: flex;
  }

  .tooltip {
    > :deep(.popper) {
      max-width: 30rem;
    }
  }
}

.unfollow-modal--text {
  h4 {
    color: color(grey, secondary);
    margin-top: 1rem;
  }
}
</style>

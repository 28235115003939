<template>
  <BaseSvg width="16" height="16" viewBox="0 0 16 16" fill="none" class="publisher-icon">
    <path
      d="M1.56524 14.4347C1.59015 14.4596 1.61976 14.4829 1.65211 14.5002C3.79479 16.0095 6.68669 15.7785 8.53868 13.9265L11.7718 10.6933C12.013 10.4522 12.013 10.0798 11.7718 9.83866L10.2408 8.30759L10.4078 8.14056L14.5683 3.98011C14.8922 3.65622 15.0025 3.19473 14.9581 2.74502C14.9137 2.29539 14.7138 1.84453 14.3988 1.52959C14.0835 1.21423 13.6443 1.02667 13.206 0.995362C12.7673 0.964027 12.3206 1.08898 11.9989 1.41072L7.83843 5.57118L7.6714 5.73821L6.16127 4.22808C5.92014 3.98695 5.54776 3.98695 5.30663 4.22808L2.07346 7.46126C0.221908 9.31281 -0.0218347 12.2167 1.49988 14.3481C1.51718 14.3804 1.54041 14.4099 1.56524 14.4347ZM7.68403 13.0718C6.27256 14.4833 4.08846 14.6843 2.42975 13.5697C1.31592 11.9349 1.51648 9.72752 2.9281 8.3159L5.73395 5.51005L8.36207 8.13816L8.19504 8.30519C8.03201 8.46822 7.78626 8.46822 7.62324 8.30519C7.38211 8.06406 7.00973 8.06406 6.7686 8.30519C6.52746 8.54632 6.52746 8.9187 6.7686 9.15983C7.402 9.79323 8.41628 9.79323 9.04968 9.15983L9.21671 8.9928L10.4899 10.266L7.68403 13.0718ZM9.98049 6.8586L9.12039 5.9985L12.8535 2.26537C12.9211 2.19776 13.0382 2.17398 13.1803 2.2008C13.3203 2.22721 13.4589 2.29897 13.5442 2.38424C13.6304 2.47047 13.7154 2.62358 13.7541 2.77901C13.7942 2.94001 13.7759 3.06317 13.7136 3.12547L9.98049 6.8586Z"
      fill="#ADA6B7"
      stroke-width="0.005"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'PublisherIcon',
  components: {
    BaseSvg,
  },
};
</script>

<script setup lang="ts">
import { Button } from '@clovyr/bed';
import type { ButtonProps } from '@clovyr/bed/components/controls/Button.vue';

export interface SettingsItemButton extends ButtonProps {
  eventName?: string;
  class?: string;
}

const props = withDefaults(
  defineProps<{
    buttons: SettingsItemButton[];
    highlighted?: boolean;
    buttonsSeparator?: boolean;
  }>(),
  {
    // @ts-expect-error buttons
    buttons: [] as SettingsItemButton[],
    highlighted: true,
    buttonsSeparator: false,
  },
);

const buttonGroup = computed(() => [props.buttonsSeparator && 'button-group-separator']);
</script>

<template>
  <div class="settings-item">
    <div v-if="highlighted" class="settings-item__background"></div>

    <div v-if="$slots.content" :class="{ 'settings-item-content': buttons?.length > 0 }">
      <slot name="content" />
    </div>

    <div v-if="buttons?.length" :class="buttonGroup" class="button-group button-group-row">
      <Button
        v-for="(button, idx) in buttons"
        :key="idx"
        :label="button.label"
        :reversed="button.reversed"
        @click="button.eventName && $emit(button.eventName)"
        :class="button.class"
        class="option-button"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.settings-item {
  align-self: stretch;
  position: relative;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: space(2.5) space(3.3);
  height: 100%;

  &:first-child {
    margin-left: 0;
  }

  &__background {
    @extend .background-gradient--3;
    @include borders-rounded;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
  }

  .settings-item-content {
    margin-bottom: 1.5rem;
  }

  .button-group {
    .button {
      width: 100%;
    }
  }
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <title v-if="title">{{ title }}</title>
    <slot />
  </svg>
</template>

<script>
export default {
  title: 'BaseSvg',
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<script lang="ts" setup>
import Feedback from '@clovyr/bed/components/blocks/Feedback.vue';

onMounted(() => {
  const app = document.getElementById('app');
  if (app) {
    app.classList.add('app-maxed');
  }
});
onUnmounted(() => {
  const app = document.getElementById('app');
  if (app) {
    app.classList.remove('app-maxed');
  }
});
</script>

<template>
  <RouterView />
  <!-- <Feedback pmf /> -->
</template>

<style lang="scss">
#app.app-maxed,
#app.app-maxed > div {
  width: 100%;
  height: 100%;
}
</style>

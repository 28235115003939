import type { Matomo } from 'matomotypes';
import type { App } from 'vue';
import type { Router } from 'vue-router';

declare global {
  interface Window {
    _paq: Matomo.TrackingCollector | undefined;
    Piwik: any | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

const matomoAPIURL = 'https://mt.clovyr.io';

export async function initMatomo(
  app: App<Element>,
  router: Router,
  matomoSiteID: number
): Promise<void> {
  if (import.meta.env.SSR) {
    return;
  }
  try {
    const { default: VueMatomo } = await import('vue-matomo');
    app.use(VueMatomo, {
      host: matomoAPIURL,
      siteId: matomoSiteID,
      trackerFileName: 'matomo',
      enableLinkTracking: true,
      trackInitialView: true,
      // Enables automatically registering pageviews on the router
      router,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to initialize Matomo:', error);
  }
}

export function useMatomo() {
  const isSSR = import.meta.env.SSR;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _paq, Piwik } = window;

  if (isSSR) {
    return { matomo: undefined, _paq: [], trackEvent: () => undefined };
  }

  const matomo = Piwik ? Piwik.getTracker() : undefined;

  const trackEvent = (category: string, action: string, name?: string, value?: number) => {
    if (!matomo) {
      // eslint-disable-next-line no-console
      console.warn('Matomo tracker not initialized. Event tracking failed.');
      return;
    }
    matomo.trackEvent(category, action, name, value);
  };

  return { matomo, _paq, trackEvent };
}

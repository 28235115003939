import { deepCopy } from '../util/deep-copy';

/**
 * Middleware function which may modify the request before it is sent
 */
export type BeforeFunc = (url: string, request: ApiRequest) => ApiRequest;

/**
 * Middleware function which may modify the response before it is returned
 */
export type AfterFunc = (response: Response, request: ApiRequest, url: string) => Response;

export interface ApiRequest extends RequestInit {
  baseURL?: string;
  middleware?: {
    before?: BeforeFunc[];
    after?: AfterFunc[];
  };
}

export interface ApiResponse extends Response {}

/**
 * Wrap fetch for making RESTful API clients
 *
 * Based on https://github.com/rhysstubbs/fetch-wrapper (MIT)
 */
export class FetchWrapper {
  options: ApiRequest;

  /**
   * @param {Object} options - the options to use when creating a new instance of FetchWrapper.
   */
  constructor(options?: ApiRequest) {
    // Set options with defaults
    this.options = this.mergeOpts(this.optionDefaults, options);
    this.setBaseURL(this.options.baseURL);
  }

  /**
   * Merge the given options bags, including nested headers.
   *
   * @param opts
   * @param override
   * @returns
   */
  mergeOpts(opts: ApiRequest, override?: ApiRequest): ApiRequest {
    if (!override) {
      return opts;
    }
    // merge headers separately
    const h = override.headers;
    delete override.headers;
    const o = { ...opts, ...override };
    o.headers = { ...opts.headers, ...h };
    return o;
  }

  /**
   * Set the base URL for making API requests using this client
   *
   * @param u Base URL
   */
  setBaseURL(u?: string) {
    // always prefer base URLs without a trailing /
    if (u) {
      this.options.baseURL = u;
      if (u.length > 0 && u[u.length - 1] === '/') {
        this.options.baseURL = u.substring(0, u.length - 1);
      }
    }
  }

  /**
   * Set Authorization header with the given bearer token
   *
   * @param token
   */
  setAuthorization(token: string) {
    this.options.headers!['Authorization'] = `Bearer ${token}`;
  }

  /**
   * @param {String} url - the URL to send the request to
   * @param {ApiRequest} r - the configuration of the request, e.g. headers.
   * @returns {Promise<ApiResponse | Error>} - request returns either a response or error
   */
  async request(url: string, r: ApiRequest): Promise<ApiResponse | Error> {
    // Set request with defaults
    const opts = deepCopy(this.options);
    delete opts.baseURL;
    delete opts.middleware;
    let request = this.mergeOpts(opts, r);

    // encode the payload as needed
    if (request.body instanceof FormData && request.headers) {
      // allow fetch to set it automatically when using FormData
      delete request.headers['content-type'];
    }
    request.body = this.encode(request.body, request);

    try {
      // Construct the URL, handles base URL & path or absolute URLs
      let requestURL: string = url;
      if (url.startsWith('http')) {
        requestURL = url;
      } else {
        const path = url[0] === '/' ? url : `/${url}`;
        if (this.options.baseURL?.length) {
          requestURL = this.options.baseURL + path;
        }
      }

      // Apply before request middleware functions
      if (this.options.middleware?.before?.length) {
        for (let func = 0; func < this.options.middleware.before.length; func++) {
          // eslint-disable-next-line no-await-in-loop
          request = await this.options.middleware.before[func](requestURL, request);
        }
      }

      // console.log('fetching ', requestURL, request);
      let response = await fetch(requestURL, request);

      // Apply after request middleware functions
      if (this.options.middleware?.after?.length) {
        for (let i = 0; i < this.options.middleware.after.length; i++) {
          const func = this.options.middleware.after[i];
          if (func) {
            // eslint-disable-next-line no-await-in-loop
            response = await func(response, request, requestURL);
          }
        }
      }

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * get is a helper function to simplify GET requests.
   * Internally it calls request.
   * @param {String} url - the URL to send the request to
   * @returns {Promise} - returns either a response or error
   */
  async get(url: string, options?: ApiRequest) {
    return this.request(url, {
      method: 'GET',
      ...(options || {}),
    });
  }

  /**
   * post is a helper function to simplify POST requests.
   * Internally it calls request.
   * @param {String} url - the URL to send the request to
   * @param {Object} body
   * @param {ApiRequest} options additional fetch options
   * @returns {Promise} - returns either a response or error
   */
  async post(url: string, body?: any, options?: ApiRequest) {
    return this.request(url, {
      method: 'POST',
      body,
      ...(options || {}),
    });
  }

  /**
   * put is a helper function to simplify PUT requests.
   * Internally it calls request.
   * @param {String} url - the URL to send the request to
   * @param {Object} body
   * @returns {Promise} - returns either a response or error
   */
  async put(url: string, body: any, options?: ApiRequest) {
    return this.request(url, {
      method: 'PUT',
      body,
      ...(options || {}),
    });
  }

  /**
   * patch is a helper function to simplify PATCH requests.
   * Internally it calls request.
   * @param {String} url - the URL to send the request to
   * @returns {Promise} - returns either a response or error
   */
  async patch(url: string, body: any, options?: ApiRequest) {
    return this.request(url, {
      method: 'PATCH',
      body,
      ...(options || {}),
    });
  }

  /**
   * delete is a helper function to simplify DELETE requests.
   * Internally it calls request.
   * @param {String} url - the URL to send the request to
   * @returns {Promise} - returns either a response or error
   */
  async delete(url: string, body?: any, options?: ApiRequest) {
    return this.request(url, {
      method: 'DELETE',
      body,
      ...(options || {}),
    });
  }

  /**
   * use allows functions to be added to the before/after middleware pipelines.
   * This is useful for JWT access token refreshes etc.
   *
   * @param {String} pipeline - either before/after request pipelines
   * @param {Function} func - the function to add to the pipeline
   * @returns {Promise} - returns either a response or error
   */
  use(pipeline: 'before' | 'after', func: BeforeFunc | AfterFunc) {
    if (!pipeline) {
      throw new Error('invalid pipeline type');
    }
    // @ts-expect-error
    this.options.middleware![pipeline]!.push(func);
  }

  /**
   * The default options for creating a new FetchWrapper
   */
  get optionDefaults(): ApiRequest {
    return {
      ...this.requestDefaults,

      baseURL: '',
      middleware: {
        before: [],
        after: [],
      },
    };
  }

  /**
   * The default request options
   */
  get requestDefaults(): ApiRequest {
    const req: ApiRequest = {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    };

    return req;
  }

  encode(payload?: any, options?: ApiRequest): any {
    if (!payload) {
      return undefined;
    }
    if (payload.buffer && payload.buffer instanceof ArrayBuffer) {
      return payload;
    }
    if (options && options.headers?.['content-type'] === 'application/json') {
      return JSON.stringify(payload);
    }
    return payload;
  }

  /**
   * Clone this FetchWrapper instance so that its options can be modified
   *
   * @returns {FetchWrapper}
   */
  clone(): FetchWrapper {
    const opts = deepCopy(this.options);
    return new FetchWrapper(opts);
  }
}

export const wnextHTTP = new FetchWrapper({
  baseURL: '/api',

  // We need to include cookies when making requests to wnext (trefoil)
  // endpoints. Other APIs, such as cloud providers, will use auth headers
  // instead of cookies.
  credentials: 'include',
});

import { defineStore } from 'pinia';
import mitt, { type Emitter } from 'mitt';
import yaml from 'yaml';

import {
  type ComputeProviderConfig,
  type ComputeProviderInfo,
  ComputeProviderName,
  type Deployment,
  DeploymentState,
  type Instance,
} from '@clovyr/pollen';
import { getComputeProvider } from '@clovyr/pollen/compute/util';
import type { CreateDeploymentResponse } from '@clovyr/pollen/deployment';
import { WNextAccessAPI } from '@clovyr/pollen/deployment/access';
import { WNextDeployment } from '@clovyr/pollen/deployment/wnext';
import { WNextDNS } from '@clovyr/pollen/dns/wnext';
import { addBackupValues, type Manifest, NewValues, type Values } from '@clovyr/pollen/manifest';
import { isDNSProxied } from '@clovyr/pollen/manifest/configure/util';
import { exec, type LaunchEvents } from '@clovyr/pollen/remote_exec';
import { SubscriptionAPI } from '@clovyr/pollen/subscription';
import { sleep } from '@clovyr/pollen/util/sleep';

import { useAppVersion } from '@/composables/useAppVersion';
import { useBeforeUnload } from '@/composables/useBeforeUnload';
import { config, useInstanceLauncher } from '@/composables/useInstanceLauncher';
import { usePayments } from '@/composables/usePayments';
import { config as appConfig } from '@/init/config';

import { addAnswers, type AnswerMap } from './launch_settings';
import { usePollenStore } from './pollen_store';

export type LaunchStatus = 'todo' | 'working' | 'success';
export type Task = { description: string; status: LaunchStatus };

export function initTasks(computeProviderName?: string): Task[] {
  return [
    {
      description: computeProviderName
        ? `Requesting private server on ${computeProviderName}`
        : 'Requesting private server',
      status: 'todo',
    },
    {
      description: 'Waiting for server to become available',
      status: 'todo',
    },
    {
      description: 'Deploying',
      status: 'todo',
    },
    {
      description: 'Finishing up',
      status: 'todo',
    },
  ];
}

export type Launch = {
  // state
  currentTaskIndex: number;
  tasks: Task[];
  instance: Instance | undefined;
  emitter: Emitter<LaunchEvents>;

  // inputs
  manifest: Manifest;
  deployment: Deployment;
  selectedHost: ComputeProviderInfo;
  selectedComputeProviderConfig: ComputeProviderConfig;
  answers: AnswerMap;
};

/**
 * Manages active launches (deployments)
 */
export const useLaunchStore = defineStore('launch', () => {
  const pollenStore = usePollenStore();

  type Launches = { [id: string]: Launch };
  const launches = ref<Launches>({});

  function getLaunchForDeployment(deploymentID: string): Launch | undefined {
    return launches.value[deploymentID];
  }

  function deleteLaunch(launch: Launch) {
    delete launches.value[launch.deployment.id];
  }

  function completeCurrentStep(launch: Launch) {
    if (launch.currentTaskIndex < launch.tasks.length) {
      launch.tasks[launch.currentTaskIndex].status = 'success';
    }
    launch.currentTaskIndex++;
    if (launch.currentTaskIndex < launch.tasks.length) {
      launch.tasks[launch.currentTaskIndex].status = 'working';
    }
  }

  /**
   * launch instance in compute provider, do not deploy
   */
  async function launchInstance(launch: Launch): Promise<Instance> {
    if (!launch.selectedHost) {
      return Promise.reject(new Error('No host selected'));
    }
    if (!launch.selectedComputeProviderConfig) {
      return Promise.reject(new Error('No compute provider configuration present'));
    }
    if (!launch.deployment.region) {
      return Promise.reject(new Error('No region configured'));
    }

    // providers
    const computeProvider = getComputeProvider(launch.deployment.hostingProvider);
    computeProvider.setConfig(launch.selectedComputeProviderConfig);
    const dnsProvider = new WNextDNS();

    const proxyDNS = isDNSProxied(launch.manifest, launch.deployment.fqdn);

    const instance = await useInstanceLauncher().launchInstance(
      computeProvider,
      dnsProvider,
      proxyDNS,
      launch.deployment.region,
      launch.manifest.deployment.compute?.size,
      launch.deployment.fqdn,
    );

    return instance;
  }

  /**
   * deploy to an existing instance
   */
  async function deployInstance(launch: Launch, values: Values): Promise<Instance> {
    if (!launch.instance) {
      return Promise.reject(new Error('No instance'));
    }

    launch.manifest = await pollenStore.garden.loadDeploymentDetails(launch.manifest);
    return useInstanceLauncher().deployInstance(
      launch.manifest,
      launch.instance,
      values,
      launch.emitter,
      new WNextDNS(),
    );
  }

  /**
   * Try to claim an instant launch instance. Returns true on success.
   */
  async function claimInstance(launch: Launch): Promise<Instance> {
    let res: CreateDeploymentResponse;
    try {
      res = await new WNextDeployment().createDeployment({
        appName: launch.manifest.metadata.id,
      });
    } catch (error) {
      // console.warn('failed to claim instance:', error);
      return Promise.reject(new Error('failed to claim instance'));
    }

    // use it
    launch.instance = res.instance;
    if (launch.instance.configurekey) {
      launch.deployment.accessList.push(launch.instance.configurekey);
    }
    // launch.deployment.appSettings = {}; // TODO: get from server

    return launch.instance;
  }

  /**
   * Start the launch, either instant or on-demand.
   *
   * @param launch
   */
  async function startLaunch(launch: Launch): Promise<void> {
    const payments = usePayments();

    // analytics - not tied to user, currently
    void new SubscriptionAPI().recordAppLaunch(
      launch.manifest.metadata.id,
      launch.deployment.hostingProvider,
      launch.deployment.dnsProvider,
    );

    // see if we can claim an instant-launch instance
    // must have chosen wnext & no answers
    // TODO: check answers to see if filled in?
    let claimed = false;
    if (
      launch.deployment.hostingProvider === ComputeProviderName.WNext &&
      (!launch.answers || !Object.keys(launch.answers).length) &&
      !launch.deployment.customDomain &&
      (!launch.deployment.region || launch.deployment.region === launch.selectedHost.defaultRegion)
    ) {
      try {
        await claimInstance(launch);
        claimed = true;
      } catch {
        // no-op, instance unavailable or other reason, continue with on-demand launch
      }
    }

    if (!claimed || !launch.instance) {
      try {
        // launch new instance
        launch.instance = await launchInstance(launch);
        launch.deployment.state = DeploymentState.WaitingInstance;
        if (launch.deployment.hostingProvider !== ComputeProviderName.WNext) {
          // save config that was used to launch
          await pollenStore.pollen.saveComputeProviderConfig(launch.selectedComputeProviderConfig);
        }
      } catch (e) {
        console.error('error launching instance:', e);
        launch.deployment.state = DeploymentState.LaunchFailed;
        await pollenStore.pollen.putDeployment(launch.deployment);
        return;
      }
    }

    // update with new details
    launch.deployment.fqdn = launch.instance.fqdn;
    await pollenStore.pollen.putDeployment(launch.deployment);
    await pollenStore.pollen.putInstance(launch.instance);
    completeCurrentStep(launch);

    // create subscription item for wnext hosting or backup addons (if selected)
    void payments.addSubscriptionItem(launch.deployment, true);

    if (!claimed || launch.deployment.state === DeploymentState.WaitingInstance) {
      // instance not yet deployed (on-demand instance)

      // generate app config
      const fqdn = launch.instance.fqdn!;
      const accessKey = await new WNextAccessAPI().createAccessKey({ fqdn });
      const values: Values = {};
      addAnswers(launch.manifest, launch.answers, values);
      NewValues(values, launch.manifest, fqdn, launch.deployment.customDomain);
      addBackupValues(
        values,
        `${config.s3URL}/${launch.deployment.id}`,
        accessKey,
        appConfig.VITE_PUBLIC_URL,
        launch.instance.configurekey!,
      );

      launch.deployment.appSettings = values;
      await pollenStore.pollen.putDeployment(launch.deployment);

      // deploy app
      await deployInstance(launch, values);
    } else {
      // instant claim, get app config
      const res = await exec(
        launch.deployment.fqdn!,
        launch.deployment.accessList[0],
        `cat /opt/clovyr/apps/${launch.deployment.appID}/clovyr-values.yml`,
        true,
        true,
      );
      const values = yaml.parse(await res.text());
      launch.deployment.appSettings = values;
      await pollenStore.pollen.putDeployment(launch.deployment);

      // quickly mark all steps complete
      for (let index = 0; index < 4; index++) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(50);
        completeCurrentStep(launch);
      }

      // tell progress to wrap it up
      launch.emitter.emit('launch:app_deployed');
    }

    // common for launch/claim below
    // update details post-deployment
    launch.deployment.state = DeploymentState.Running;
    const oldKey = launch.instance.configurekey;
    const newKey = await useInstanceLauncher().rotateKeys(launch.instance);
    if (newKey) {
      // store updated key, remove old
      launch.deployment.accessList.push(newKey);
      launch.deployment.accessList = launch.deployment.accessList.filter((k) => k !== oldKey);
    }

    await pollenStore.pollen.putDeployment(launch.deployment);
    await pollenStore.pollen.putInstance(launch.instance);

    // after instance is up, try to upgrade clovyr services immediately
    void useAppVersion(launch.deployment.id).runPostLaunchTasks();
  }

  /**
   * Create launch and add deployment to pollen but do not start
   */
  async function createLaunch(
    manifest: Manifest,
    deployment: Deployment,
    selectedHost: ComputeProviderInfo,
    selectedComputeProviderConfig: ComputeProviderConfig,
    answers: AnswerMap,
  ): Promise<Launch> {
    const launch = {
      // state
      currentTaskIndex: 0,
      tasks: initTasks(selectedHost.title),
      instance: undefined,
      emitter: mitt<LaunchEvents>(),

      // inputs
      manifest,
      deployment,
      selectedHost,
      selectedComputeProviderConfig,
      answers,
    };

    useBeforeUnload().addBlocker(`deployment/${deployment.id}`);
    console.time(`launch ${deployment.id}`);

    // const launch = launches.value[deployment.id];
    launch.emitter.on('launch:instance_up', async () => {
      launch.deployment.state = DeploymentState.LaunchedInstance;
      await pollenStore.pollen.putDeployment(launch.deployment);
      completeCurrentStep(launch);
    });

    launch.emitter.on('launch:app_deploying', async () => {
      launch.deployment.state = DeploymentState.Deploying;
      await pollenStore.pollen.putDeployment(launch.deployment);
      completeCurrentStep(launch);
    });

    launch.emitter.on('launch:app_deployed', async () => {
      launch.deployment.state = DeploymentState.Running;
      await pollenStore.pollen.putDeployment(launch.deployment);
      completeCurrentStep(launch);
      useBeforeUnload().removeBlocker(`deployment/${deployment.id}`);
      console.timeEnd(`launch ${deployment.id}`);
    });

    launch.emitter.on('launch:err', (err) => {
      useBeforeUnload().removeBlocker(`deployment/${deployment.id}`);
      console.error('launch error', err);
    });

    launch.deployment.state = DeploymentState.LaunchingInstance;
    launch.tasks[launch.currentTaskIndex].status = 'working';

    await pollenStore.pollen.putDeployment(launch.deployment);
    launches.value[deployment.id] = launch;

    return launch;
  }

  return {
    launches,

    completeCurrentStep,
    getLaunchForDeployment,
    deleteLaunch,
    createLaunch,
    startLaunch,
  };
});

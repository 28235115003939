<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useRouteHead } from './composables/useRouteHead';

const route = useRoute();
const layout = computed(() => {
  return `${route?.meta.layout || 'blank'}-layout`;
});

useRouteHead();
</script>

<template>
  <div>
    <component :is="layout" />
  </div>
</template>

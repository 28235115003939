import { customAlphabet } from 'nanoid';
import { randomBytes, secretbox } from 'tweetnacl';

import { bytesToBase64 } from '../util/base64';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 32);

/**
 * Create a new random key
 *
 * instance-configure expects a 32-byte string-encoded key. In this case, we are
 * hex-encoding.
 *
 * @param len size of key to generate (default=32)
 * @returns string encoded key
 */
export function createRandomKey(len?: number): string {
  return nanoid(len || 32);
}

/**
 * Convert the given key to a string. Assumes that each byte represents a
 * printable ASCII character.
 *
 * @param arr byte array
 * @returns string
 */
export function encodeKey(arr: Uint8Array): string {
  const s = new Array(arr.length);
  for (let i = 0; i < arr.length; i++) {
    s[i] = String.fromCharCode(arr[i]);
  }
  return s.join('');
}

/**
 * Decodes the given key into a byte array as required by nacl.
 *
 * @param key in string format
 * @returns byte array
 */
export function decodeKey(key: string): Uint8Array {
  const b = new Uint8Array(key.length);
  for (let i = 0; i < key.length; i++) {
    b[i] = key.charCodeAt(i);
  }
  return b;
}

export function createNonce(): Uint8Array {
  return randomBytes(secretbox.nonceLength);
}

export function encodeBase64Url(arr: Uint8Array): string {
  // simple replace to convert standard base64 encoding to url-safe encoding
  // which is used by instance-configure
  return bytesToBase64(arr).replaceAll('+', '-').replaceAll('/', '_');
}

export function encrypt(msg: Uint8Array, nonce: Uint8Array, key: Uint8Array) {
  return secretbox(msg, nonce, key);
}

<!-- eslint-disable import/no-unresolved -->
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { AuthType } from '@clovyr/pollen/pollen';

import SettingsDetailList from '@/components/blocks/SettingsDetailList.vue';
import SettingsItem, { type SettingsItemButton } from '@/components/blocks/SettingsItem.vue';
import Modal from '@/components/elements/Modal.vue';
import SpinnerCover from '@/components/elements/SpinnerCover.vue';
import AlertIcon from '@/components/icons/AlertIcon.vue';
import { usePollenStore } from '@/stores/pollen_store';

// 'any' until a proper type is set up
// eslint-disable-next-line @typescript-eslint/no-explicit-any
defineProps<{ user: any }>();

const pollenStore = usePollenStore();
const { pollen } = pollenStore;
const { authType } = storeToRefs(pollenStore);

const showDeleteAccountDialog = ref(false);

const deleteAccountDialog = () => {
  showDeleteAccountDialog.value = true;
};

const showDeletingAccountSpinner = ref(false);

const handleDeleteAccount = () => {
  // deleting user account

  showDeletingAccountSpinner.value = true;

  setTimeout(() => {
    showDeleteAccountDialog.value = false;
    showDeletingAccountSpinner.value = false;
  }, 3000);
};

async function onClickLogOut() {
  return pollen.logOut();
}

const userButtons = computed<SettingsItemButton[]>(() => {
  if (authType.value === AuthType.Nostr) {
    return [];
  }

  // only for password users
  return [
    {
      label: 'Log Out',
      eventName: 'logOut',
    },
    // {
    //   label: 'Delete Account',
    //   eventName: 'deleteAccount',
    //   class: 'button--danger',
    // },
  ];
});
</script>

<template>
  <SettingsItem
    @delete-account="deleteAccountDialog"
    @logOut="onClickLogOut"
    :buttons-separator="false"
    :buttons="userButtons"
  >
    <template #content>
      <div class="settings-account-info">
        <SettingsDetailList label="Email">
          <div class="user-email">{{ user.email }}</div>

          <div class="settings-detail-edit" v-if="false">
            <button class="button-inline button-primary">Change password</button>
          </div>
        </SettingsDetailList>

        <SettingsDetailList label="Apps Installed">
          {{ user.account.appsInstalled }}
        </SettingsDetailList>
      </div>
    </template>
  </SettingsItem>

  <Modal
    v-if="showDeleteAccountDialog"
    theme="transparent"
    :size="'narrow'"
    :has-icon="true"
    heading-text="Permanently delete"
    @close="showDeleteAccountDialog = false"
  >
    <template #body>
      You are about to delete your account and the associated apps and softwares. Are you sure of
      this action?
    </template>

    <template #controls-left>
      <Button label="Delete" class="button--danger" :reversed="true" @click="handleDeleteAccount" />
    </template>

    <template #controls-right>
      <Button label="Cancel" class="button--danger" @click="showDeleteAccountDialog = false" />
    </template>

    <template #icon>
      <AlertIcon />
    </template>
  </Modal>

  <SpinnerCover v-if="showDeletingAccountSpinner" />
</template>
<style>
.user-email {
  word-break: break-all;
}
</style>

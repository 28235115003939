<script setup lang="ts">
import SelectInputBlock from '@clovyr/bed/components/blocks/SelectInputBlock.vue';
import { getAllQueryParams } from '@clovyr/pollen/http/qs';

import Terminal from '@/components/blocks/terminal/Terminal.vue';
import { cmdDockerLogs, type TTYArgs } from '@/components/blocks/terminal/webtty';
import Spinner from '@/components/elements/Spinner.vue';

import { useAppStatus } from '../../composables/useAppStatus';
import { useDocker } from '../../composables/useDocker';

const props = defineProps<{
  id: string;
}>();

const deploymentID = toRef(props, 'id');
const { deployment, app } = useAppStatus(deploymentID);

const { serviceOptions, selectedService, cleanServiceName } = useDocker(deploymentID);

async function onChangeInput(e) {
  selectedService.value = e.target.value;
}

const termCommand = computed(() => {
  return cmdDockerLogs;
});

const termArgs = computed(() => {
  const args: TTYArgs = {};
  args.workdir = `/opt/clovyr/apps/${app.value.metadata.id}`;
  args.container = cleanServiceName.value;
  return args;
});

onMounted(() => {
  if (window.location.hash) {
    const q = getAllQueryParams();
    if (q.get('logs') === null) {
      q.delete('console');
      q.set('logs', '');
    }
    window.location.hash = `#${q.toString().replace('=&', '&')}`;
  } else {
    window.location.hash = '#logs';
  }
});
</script>

<template>
  <div v-if="deployment && app" class="logs">
    <div class="row">
      <SelectInputBlock
        label="Choose Service"
        name="service"
        :selectOptions="serviceOptions"
        :value="selectedService"
        @input="onChangeInput"
        class="form-field"
      />
    </div>
    <div class="row term-row">
      <Terminal
        :host="deployment.fqdn!"
        :token="deployment.accessList[0]"
        :cmd="termCommand"
        :args="termArgs"
      />
    </div>
  </div>

  <div v-else>
    <Spinner></Spinner>
  </div>

  <router-view></router-view>
</template>

<style scoped lang="scss">
.logs {
  height: 100%;
  display: flex;
  flex-direction: column;
  .term-row {
    flex-grow: 1;
  }
}

.body-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  .row-term {
    flex: 1 1 auto;
  }
}

:deep(.selectinput-block) {
  margin-bottom: 1rem;
}
:deep(.selectinput-block__label-holder) {
  margin: 0 0 0.5rem 0.5rem;
}
:deep(.selectinput-block__input) {
  padding-left: 1.25rem;
}
</style>

<template>
  <div class="eyebrow">{{ text }}</div>
</template>

<script>
export default {
  name: 'Eyebrow',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.eyebrow {
  @extend .text--mono;

  margin-bottom: vert-space(xs);
  color: color(active, secondary);
}
</style>

// import * as monaco from 'monaco-editor';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';
import EditorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';

import { configureMonacoYaml } from '../../../vendor/monaco-yaml';
import YamlWorker from '../../../vendor/monaco-yaml/yaml.worker?worker';

import { subSchema } from './schema';

window.MonacoEnvironment = {
  getWorker(moduleId, label) {
    switch (label) {
      case 'editorWorkerService':
        return new EditorWorker();
      case 'yaml':
        return new YamlWorker();
      default:
        throw new Error(`Unknown label ${label}`);
    }
  },
};

// note: URLs below must be unique for each schema/pattern.
// monaco-yaml returns the uri when matching a pattern which can lead to the wrong schema being used
// if the uri is the same.
configureMonacoYaml(monaco, {
  enableSchemaRequest: false,
  schemas: [
    {
      fileMatch: ['**/deployment.yaml'],
      schema: subSchema('Deployment'),
      uri: 'https://cstatic.app/clovyr.schema.json?deployment',
    },
    {
      fileMatch: ['**/backup.yaml'],
      schema: subSchema('Backup'),
      uri: 'https://cstatic.app/clovyr.schema.json?backup',
    },
    {
      fileMatch: ['**/config.yaml'],
      schema: subSchema('Config'),
      uri: 'https://cstatic.app/clovyr.schema.json?config',
    },
    {
      fileMatch: ['**/string-array.yaml'],
      schema: {
        type: 'array',
        description: 'List of features',
        items: {
          type: 'string',
        },
      },
      uri: 'https://cstatic.app/clovyr.schema.json?features',
    },
  ],
});

import { useEventBus } from '@/stores/event_bus';

const blockers: { [k: string]: boolean } = {};

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
let _init = false;

export const useBeforeUnload = () => {
  const { eventBus } = useEventBus();

  function init() {
    if (!import.meta.env.SSR) {
      window.addEventListener('beforeunload', (e) => {
        if (Object.keys(blockers).length > 0) {
          e.preventDefault();
          e.returnValue = true;
          eventBus.emit('modal:unload:show');
        }
        return undefined;
      });
    }
    _init = true;
  }

  if (!_init) {
    // ensure we only add the event listener once, but still have access to event bus
    init();
  }

  function addBlocker(key: string) {
    blockers[key] = true;
  }

  function removeBlocker(key: string) {
    delete blockers[key];
  }

  return {
    addBlocker,
    removeBlocker,
  };
};

<script setup lang="ts">
import { Hero } from '@clovyr/bed';
import type { Logo } from '@clovyr/bed/components';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import type { PublisherApp } from '@clovyr/pollen';

type PubHeroProps = {
  appLogo: Logo;
  appBackground: {
    '--bg-url': string;
    '--z-index': number;
  } | null;
  app: PublisherApp | undefined;
};
const props = defineProps<PubHeroProps>();

const isCode = computed(() => props.app && props.app.app_type === 'clovyr_code');

const appType = computed(() => {
  if (!props.app) {
    return '';
  }
  return isCode.value ? 'Code' : 'Run';
});
</script>

<template>
  <div class="pub-hero" :style="appBackground">
    <div class="app-background-gradient"></div>
    <div class="app-background"></div>
    <Hero subheadingTextShade="light" class="hero">
      <template v-slot:heading>
        <div class="app-type">
          <div class="app-type-left" @click="$emit('portalClick')">Publisher Portal</div>
          <div>|</div>
          <div class="app-type-right">{{ appType }} App</div>
        </div>
        <div>{{ app?.name }}</div>
      </template>

      <template v-slot:top>
        <AppLogo v-if="appLogo" :logo="appLogo" size="large" class="app-logo-detail" />
      </template>

      <template v-slot:right>
        <div class="app-controls button-group">
          <slot name="buttons" />
        </div>
      </template>
    </Hero>
  </div>
</template>
<style lang="scss" scoped>
// Local styles
.pub-hero {
  .hero {
    .app-type {
      display: flex;
      font-size: 13px;
      letter-spacing: 0.03em;
      font-family: Lato;
      margin-left: 0.25rem;

      .app-type-left {
        margin-right: 0.75rem;
        color: color(action, primary);
        cursor: pointer;
      }
      .app-type-right {
        margin-left: 0.75rem;
      }

      > * {
        padding: 0.25rem 0;
      }
    }
  }
  .app-background-gradient {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -4;
    width: 100%;
    height: space(60);
    background: linear-gradient(180deg, rgb(20 20 20 / 80%) 0%, color(negative) 100%);
  }

  .app-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    width: 100%;
    height: space(60);
    background: var(--bg-url);

    @include background-image;
  }

  // hero deep styles
  :deep(.hero) {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }
    .hero__inner {
      display: flex;
      flex-direction: column-reverse;
      padding-top: 8rem;
      align-items: flex-end;

      @include media-breakpoint-up(lg) {
        padding-top: 9rem;
        flex-direction: row;
        align-items: center;
      }

      .hero__left {
        display: flex;
        padding-top: 2rem;
        width: 95%;

        @include media-breakpoint-up(lg) {
          width: 100%;
          margin-top: unset;
        }

        .hero__top {
          margin-right: 2rem;
        }
      }
      .hero__right {
        .app-controls {
          width: max-content;
          display: flex;
          justify-content: flex-end;
        }
      }

      .hero__middle {
        margin-bottom: 0;

        .hero__heading {
          margin-top: 1rem;

          @include media-breakpoint-up(lg) {
            @include font-size(38);
          }

          @include media-breakpoint-up(xl) {
            @include font-size(50);
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
</style>

<template>
  <HelpPage
    :headingText="'Launching Clovyr Code'"
    :previousPage="{
      page: 'getting-started',
      name: 'Getting Started with Clovyr Code',
    }"
    @navigate="(page) => $emit('navigate', page)"
  >
    <ul>
      <ol>
        <li>Visit the Clovyr Code homepage</li>

        <li>
          Paste a repository URL in the repo field or select an environment from the drop-down menu

          <ul>
            <li>
              Selecting a language-specific environment populates the repo field with a relevant
              example repo
            </li>

            <li>
              When a repo URL is entered, an appropriate language environment is automatically
              selected, if possible
            </li>

            <li>To open the environment without a cloned repository, leave the repo field blank</li>
          </ul>
        </li>

        <li>
          Click the "Launch" button

          <ul>
            <li>
              If the repo is private, you will be prompted to enter authentication information.
              (See: Connect to git)
            </li>
          </ul>
        </li>

        <li>
          Have fun!

          <ul>
            <li>
              If the cloned repo is public, you'll be prompted to enter a committer identity and
              authenticate to the git host before committing and pushing changes, respectively
            </li>
          </ul>
        </li>
      </ol>
    </ul>
  </HelpPage>
</template>

<script>
import HelpPage from '@/views/Help/HelpPage.vue';

export default {
  name: 'LaunchingPage',
  components: {
    HelpPage,
  },
};
</script>

<script setup lang="ts">
import { Hero, HeroBackground } from '@clovyr/bed';
import Hero1 from '@clovyr/bed/assets/images/catalog/btclnd-hero.webp';
import Hero2 from '@clovyr/bed/assets/images/catalog/gogs-bg.webp';
import Hero5 from '@clovyr/bed/assets/images/clovyr-island-mascots.webp';
import Hero3 from '@clovyr/bed/assets/images/crypto-hero.webp';
import Hero4 from '@clovyr/bed/assets/images/fediverse-hero.webp';
import type { Bundle } from '@clovyr/pollen/types';
import { slugify } from '@clovyr/pollen/util/slugify';

import AppBlock from '@/components/blocks/AppBlock.vue';
import Breadcrumbs from '@/components/elements/Breadcrumbs.vue';
import { usePollenStore } from '@/stores/pollen_store';

const { garden } = usePollenStore();

const bundles = garden.getBundles();

const currentTag = ref('all');
const apps = computed(() => {
  return currentTag.value === 'all' ? garden.getApps() : garden.getAppsByTag(currentTag.value);
});

const setcurrentTag = (tag) => {
  currentTag.value = tag || 'all';
};

const getAppsCount = (tag) => {
  return tag === 'all' ? garden.getApps().length : garden.getAppsByTag(tag).length;
};

const tags = garden.getTags();

const getBundleApps = (bundle: Bundle) =>
  garden.getAppsByBundle(bundle.id).map((manifest) => manifest.metadata);

const crumbs = [
  {
    name: 'garden',
    routeTo: '/',
  },
  {
    name: 'tags',
  },
];

// compute randomized header image

const bgImages = [Hero1, Hero2, Hero3, Hero4, Hero5];

const randomHeaderImage = computed(() => {
  return bgImages[Math.floor(Math.random() * bgImages.length)];
});
</script>

<template>
  <div class="tags">
    <Hero class="tags-page-hero" subheading-text-shade="dark">
      <template #heading> Explore what Clovyr has to offer </template>

      <template #top>
        <Breadcrumbs :crumbs="crumbs" />
      </template>

      <template #background>
        <HeroBackground :background-image="randomHeaderImage" :gradient="16" />
      </template>
    </Hero>

    <div class="tags-list row">
      <div class="tags-filter grid__col-lg-3">
        <h3 class="title">Tags</h3>

        <ul class="options-list">
          <li>
            <button @click="setcurrentTag(slugify('all'))">
              <span class="tags-title">All</span>
              <span class="tags-count">({{ getAppsCount('all') }})</span>
            </button>
          </li>
          <li v-for="cat in tags" :key="slugify(cat)">
            <button @click="setcurrentTag(slugify(cat))">
              <span class="tags-title">{{ cat }}</span>
              <span class="tags-count">({{ getAppsCount(cat) }})</span>
            </button>
          </li>
        </ul>

        <h3 class="title">Collections</h3>

        <ul class="options-list">
          <li v-for="bundle in bundles" :key="bundle.id">
            <router-link :to="`/collections/${bundle.id}`">
              <span class="tags-title">{{ bundle.title }}</span>
              <span class="tags-count">({{ getBundleApps(bundle).length }})</span>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="app-list grid__col-lg-9">
        <div class="row">
          <div
            class="app-browser__card grid__col-md-6 grid__col-xl-4"
            v-for="appMetadata in apps"
            :key="appMetadata.metadata.id"
          >
            <AppBlock :appDatum="appMetadata.metadata" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tags {
  .tags-list {
    @include outer-gutters;

    width: 100%;

    .tags-filter {
      padding-right: 2rem;

      @include media-breakpoint-up(lg) {
        border-right: 1px solid color(background);
      }

      .options-list {
        margin-top: 2rem;
        margin-bottom: 2rem;

        li {
          margin-top: 0.5rem;

          &:first-child {
            margin-top: 0;
          }

          button,
          a {
            display: flex;
            justify-content: space-between;
            text-align: left;
            width: 100%;

            .tags-count {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }

    .app-list {
      margin-bottom: 4rem;

      @include media-breakpoint-up(lg) {
        padding-left: 4rem;
      }

      .row {
        row-gap: 3rem;
      }
    }
  }

  .tags-page-hero {
    :deep(.hero__middle) {
      width: 100%;

      .hero__heading {
        @include font-size(22);

        line-height: 1.3;

        @include media-breakpoint-up(md) {
          @include font-size(42);
        }
      }
    }

    :deep(.hero-background) {
      img {
        aspect-ratio: 4/1;
      }
    }
  }
}
</style>

<script setup lang="ts">
import { ImageMedia } from '@clovyr/bed';
import type { Logo } from '@clovyr/bed/components/elements/AppLogo';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import type { Manifest, Metadata } from '@clovyr/pollen/manifest';
import type { Bundle } from '@clovyr/pollen/types';

import { appURL } from '@/router/util';

const props = defineProps<{
  appDatum: Metadata;
  bundle?: Bundle;
  // TODO: remove??
  placeholderBgImage?: string;
  placeholderLogo?: Logo;
}>();
const description = ref<HTMLDivElement>();

const placeholderLogo = computed(() => {
  if (!props.placeholderLogo) {
    return null;
  }
  return props.placeholderLogo;
});

const placeholderBackground = computed(() => {
  if (!props.placeholderBgImage) {
    return null;
  }
  return props.placeholderBgImage;
});

const appDetailLink = computed(() => appURL({ metadata: props.appDatum } as Manifest));

const logoUrlExists = computed(() => {
  if (props.appDatum.logoUrl && props.appDatum.logoUrl !== '') {
    return true;
  }
  return false;
});

const backgroundUrlExists = computed(() => {
  if (props.appDatum.backgroundImageUrl && props.appDatum.backgroundImageUrl !== '') {
    return true;
  }
  return false;
});

const CSSClasses = computed(() => {
  return [
    props.bundle?.id === 'product_and_development' && `bg-overlay-green`,
    props.bundle?.id === 'bitcoin' && `bg-overlay-orange`,
    props.bundle?.id === 'fediverse' && `bg-overlay-blue`,
  ];
});
</script>

<template>
  <router-link :to="appDetailLink">
    <div class="app-block">
      <AppLogo class="app-logo-block" :logo="appDatum" v-if="logoUrlExists" />
      <AppLogo class="app-logo-block" :logo="placeholderLogo!" v-else />

      <div class="app-block__inner">
        <div class="app-block__backdrop background-gradient--1" :class="[CSSClasses]" />
        <div class="app-block-background">
          <ImageMedia :filename="appDatum.backgroundImageUrl" v-if="backgroundUrlExists" />
          <ImageMedia :filename="placeholderBackground!" v-else />

          <div class="app-block-background__image-overlay" />

          <div class="app-block-background__overlay" />
        </div>

        <div class="app-block-content">
          <div class="app-block-content__inner">
            <div class="app-title">
              {{ appDatum.name }}
            </div>
            <div ref="description" class="app-description">
              {{ appDatum.tagline }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.bg-overlay-green {
  background-color: #29bd7b80;
}

.bg-overlay-blue {
  background-color: #00cdeb80;
}

.bg-overlay-orange {
  background-color: #fcb17380;
}

.app-block {
  $self: &;

  position: relative;
  cursor: pointer;

  &__inner {
    position: relative;
    height: space(30);
    overflow: hidden;
    text-align: left;
    background-repeat: no-repeat;

    @include borders-rounded;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .app-background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: space(60);
    z-index: var(--z-index);
  }

  .app-block-background {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, #221a33 0%, rgb(34 26 51 / 30%) 100%);
    }

    :deep(.image-media) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: color;
      opacity: 0.5;
      background: linear-gradient(
        180deg,
        rgb(var(--collection-color-theme)) 0%,
        rgba(var(--collection-color-theme), 0) 100%
      );
    }
  }

  .app-logo-block {
    position: absolute;
    top: space(-2);
    left: space(2.5);
    z-index: 2;

    @include drop-shadow(b);
  }

  .app-block-content {
    width: 100%;

    @include position-absolute-center;

    &__inner {
      padding: 0 space(3.6);
    }

    .app-title {
      @extend .text--subheading;

      margin-bottom: space(0.9);
      letter-spacing: -0.05em;
    }
  }

  .app-description {
    color: color(grey, secondary);
  }

  &:hover {
    #{$self}__backdrop {
      z-index: -1;
      background: color(background);
    }

    .app-block-background {
      z-index: 0;

      &__overlay {
        background: linear-gradient(180deg, rgb(69 57 96 / 30%) 0%, #453960 100%);
      }
    }

    .app-description {
      color: color(white);
    }
  }

  &__bottom {
    // TODO: selector not used??
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: space(2.4);
  }
}
</style>

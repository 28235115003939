<script setup lang="ts">
import { usePublisher } from '../../composables/usePublisher';

import PublisherAppCard from './PublisherAppCard.vue';

const props = defineProps<{ publisherID: string }>();

const publisherID = computed(() => props.publisherID);
const { publisher, publisherApps } = usePublisher(publisherID);
</script>

<template>
  <div class="apps">
    <PublisherAppCard v-for="app in publisherApps" :key="app.id" :pub="publisher!" :pub-app="app" />
  </div>
  <h4 class="zero-state grid__col-md-12">
    <div>
      Contact Clovyr through
      <a href="mailto:support@clovyr.com" class="green">support@clovyr.com</a> to get your app or
      environment published.
    </div>
  </h4>
</template>

<style scoped lang="scss">
.apps {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
}

.zero-state {
  display: flex;
  border: 1px dashed #ada6b7;
  padding: space(3);
  margin-top: space(2);
  border-radius: 20px;
  color: color(grey, secondary);

  .green {
    color: color(action, primary);
  }
}
</style>

import { DNSValidationError } from './DNSValidationError';

export { DNSValidationError };

enum DNSReturnCode {
  NoError = 0,
  FormErr = 1,
  ServFail = 2,
  NXDomain = 3,
  NotImp = 4,
  Refused = 5,
  YXDomain = 6,
  YXRRSet = 7,
  NXRRSet = 8,
  NotAuth = 9,
  NotZone = 10,
  DSOTYPENI = 11,
  BADVERS = 16,
  BADSIG = 16,
  BADKEY = 17,
  BADTIME = 18,
  BADMODE = 19,
  BADNAME = 20,
  BADALG = 21,
  BADTRUNC = 22,
  BADCOOKIE = 23,
}

interface DNSQuestion {
  name: string;
  type: number;
}

interface DNSAnswer {
  name: string;
  type: number;
  TTL: number;
  data: string;
}

interface DNSResponse {
  Status: DNSReturnCode;
  TC: boolean;
  RD: boolean;
  RA: boolean;
  AD: boolean;
  CD: boolean;
  Question: DNSQuestion[];
  Answer?: DNSAnswer[];
  Authority?: DNSAnswer[];
}

async function queryDNS(domain: string, type: string): Promise<DNSResponse> {
  const resp = await fetch(
    `https://cloudflare-dns.com/dns-query?name=${encodeURIComponent(
      domain
    )}&type=${encodeURIComponent(type)}`,
    { headers: { Accept: 'application/dns-json' } }
  );
  return resp.json();
}

export async function checkCNAME(domain: string, targetDomain: string): Promise<void> {
  const record = await queryDNS(domain, 'CNAME');
  if (record.Status !== DNSReturnCode.NoError) {
    const code = DNSReturnCode[record.Status] || `code ${record.Status}`;
    throw new DNSValidationError(`DNS lookup failed, return code: ${code}`);
  }

  if (!record.Answer || record.Answer.length === 0) {
    throw new DNSValidationError(
      'DNS response contains no answers, record is probably not a CNAME'
    );
  }

  const answer = record.Answer[0];
  const cnameTarget = answer.data.replace(/\.$/, '');
  if (cnameTarget !== targetDomain) {
    throw new DNSValidationError(
      `CNAME does not point at ${targetDomain}, instead points at ${cnameTarget}`
    );
  }
}

/**
 * Create a new date without seconds or milliseconds. Useful for comparing timestamps to the minute.
 */
export function dateWithoutSeconds(d: Date): Date {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes());
}

/**
 * Format a date as a short date and time, according to the locale (local timezone).
 *
 * e.g., "May 11, 2023 at 9:28 PM
 *
 * @param d
 * @returns
 */
export function shortDateTime(d: Date | string | number) {
  if (!(d instanceof Date)) {
    d = new Date(d);
  }
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: 'long',
    timeStyle: 'short',
  }).format(d);
}

export function shortDate(d: Date | string | number, timeZone?: 'UTC') {
  if (!(d instanceof Date)) {
    d = new Date(d);
  }
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: 'long',
    timeZone,
  }).format(d);
}

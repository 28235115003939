<script setup lang="ts">
import { storeToRefs } from 'pinia';

import type { ComputeProviderConfig, ComputeProviderInfo } from '@clovyr/pollen';
import { getHostByID } from '@clovyr/pollen/fixtures/hosts';

import { useLauncherStore } from '@/stores/launcher_store';
import { usePollenStore } from '@/stores/pollen_store';

import ExpandedDropdown from '../blocks/ExpandedDropdown.vue';
import RadioBlock from '../blocks/RadioBlock.vue';

type HostOption = ComputeProviderConfig & {
  metadata: ComputeProviderInfo;
  numApps: number;
};

withDefaults(
  defineProps<{
    selected?: boolean;
    tabindex?: number;
  }>(),
  {
    selected: false,
    tabindex: 0,
  },
);

const { deployments, computeProviderConfigs } = storeToRefs(usePollenStore());
const { selectedComputeProviderConfig } = storeToRefs(useLauncherStore());

const options = computed<HostOption[]>(() => {
  return computeProviderConfigs.value.map((config) => {
    const metadata = getHostByID(config.providerID)!;
    const numApps = deployments.value.filter((d) => d.computeProviderConfigID === config.id).length;
    return { ...config, metadata, numApps };
  });
});

const selectedOption = computed<HostOption>(() => {
  if (!selectedComputeProviderConfig.value) {
    return options.value[0];
  }
  const metadata = getHostByID(selectedComputeProviderConfig.value.providerID)!;
  const numApps = deployments.value.filter(
    (d) => d.computeProviderConfigID === selectedComputeProviderConfig.value?.id,
  ).length;
  return { ...selectedComputeProviderConfig.value, metadata, numApps };
});

const selectedOptionFiltered = computed<HostOption[]>(() => {
  const optionsValue = options.value;
  const selectedOptionValue = selectedOption.value;
  return optionsValue.filter((option) => option.id !== selectedOptionValue.id);
});

const numberOfAppsText = (value) => {
  if (value > 0) {
    return `You are running ${value} other apps on this host.`;
  }
  return `You do not currently have any apps running on this host`;
};
</script>

<template>
  <ExpandedDropdown :model-value="selectedOption" :options="selectedOptionFiltered">
    <template #value="{ value }: { value: HostOption }">
      <RadioBlock :logos="[value.metadata]" :use-input="false" :selected="selected">
        <template v-slot:heading-right>
          <!-- <p>Previously Used</p> -->
          <h4>{{ value.name }}</h4>
        </template>
        <template v-slot:body-left>
          <p>{{ numberOfAppsText(value.numApps) }}</p>
        </template>
      </RadioBlock>
    </template>

    <template #options="{ option }: { option: HostOption }">
      <RadioBlock :logos="[option.metadata]" :use-input="false">
        <template v-slot:heading-right>
          <h4>{{ option.name }}</h4>
          <p v-if="option.name === option.metadata.title">{{ option.metadata.title }}</p>
        </template>
        <template v-slot:body-left>
          <p>{{ numberOfAppsText(option.numApps) }}</p>
        </template>
      </RadioBlock>
    </template>
  </ExpandedDropdown>
</template>

<style scoped lang="scss">
:deep(.block-heading__right) {
  h4,
  p {
    color: color(grey, tertiary);
  }
  p {
    font-size: 12px;
  }
}
:deep(.block-body__left) {
  p {
    color: color(grey, tertiary);
  }
}

:deep(.dropdown-items) {
  .radio-block__inner {
    background: color(negative, primary);
    border-radius: 0;
    border-right: unset;
    border-top: unset;
    border-left: unset;
  }
  .block-heading__right {
    p {
      color: color(action, primary);
    }
  }
  .block-body__left {
    p {
      color: color(grey, tertiary);
    }
  }
}
</style>

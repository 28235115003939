import type { RouteRecordRaw } from 'vue-router';

import { isPublisher, mustBeLoggedIn, mustSignUp, onePublisher } from './guards';

export const publisherRoutes: RouteRecordRaw[] = [
  {
    path: '/publishers/:publisherID/accept',
    name: 'AcceptPublisherTerms',
    meta: {
      layout: 'main',
    },
    props: true,
    beforeEnter: [mustSignUp],
    component: () => import('../views/Publisher/PublisherNew.vue'),
  },
  {
    path: '/publishers/:publisherID/complete',
    name: 'PublisherPaymentComplete',
    meta: {
      layout: 'main',
    },
    props: true,
    beforeEnter: [mustSignUp],
    component: () => import('../views/Publisher/PublisherPaymentComplete.vue'),
  },
  {
    path: '/publishers/new',
    name: 'CreateNewPublisher',
    meta: {
      layout: 'main',
    },
    props: true,
    beforeEnter: [mustSignUp],
    component: () => import('../views/Publisher/PublisherNew.vue'),
  },
  {
    path: '/publishers/:publisherID/finish',
    name: 'FinishNewPublisher',
    meta: {
      layout: 'main',
    },
    props: (route) => ({
      publisherID: route.params.publisherID,
      paymentIncomplete: true,
    }),
    beforeEnter: [mustSignUp],
    component: () => import('../views/Publisher/PublisherNew.vue'),
  },
  {
    path: '/publishers/terms',
    name: 'PublisherTerms',
    meta: {
      layout: 'main',
    },
    beforeEnter: [mustBeLoggedIn],
    component: () => import('../views/Publisher/PublisherTerms.vue'),
  },

  {
    path: '/publishers',
    name: 'Publishers',
    meta: {
      layout: 'main',
      disableSSR: true,
    },
    beforeEnter: [onePublisher],
    component: () => import('../views/Publisher/Publishers.vue'),
  },
  {
    path: '/publishers/:publisherID',
    name: 'Publisher',
    meta: {
      layout: 'main',
      disableSSR: true,
    },
    props: true,
    beforeEnter: [mustBeLoggedIn, isPublisher],
    component: () => import('../views/Publisher/Publisher.vue'),
  },
  {
    path: '/publishers/:publisherID/apps/:appID',
    name: 'PublisherApp',
    meta: {
      layout: 'main',
      disableSSR: true,
    },
    props: true,
    beforeEnter: [mustBeLoggedIn, isPublisher],
    component: () => import('../views/Publisher/PublisherApp.vue'),
  },

  {
    path: '/publishers/:publisherID/apps/new',
    name: 'PublishersAppsNew',
    meta: {
      layout: 'main',
      disableSSR: true,
    },
    props: true,
    beforeEnter: [mustBeLoggedIn, isPublisher],
    component: () => import('../views/Publisher/PublisherAppsNew.vue'),
  },
  {
    path: '/publishers/:publisherID/apps/:appID/edit',
    name: 'PublishersAppsEdit',
    meta: {
      layout: 'main',
      disableSSR: true,
    },
    props: true,
    beforeEnter: [mustBeLoggedIn, isPublisher],
    component: () => import('../views/Publisher/PublisherAppsNew.vue'),
  },
  {
    path: '/publishers/:publisherID/apps/:appID/versions',
    name: 'PublisherAppVersions',
    meta: {
      layout: 'main',
      disableSSR: true,
    },
    props: true,
    beforeEnter: [mustBeLoggedIn, isPublisher],
    component: () => import('../views/Publisher/PublisherAppVersions.vue'),
  },
  {
    path: '/publishers/:publisherID/apps/:appID/versions/:versionID/edit',
    name: 'PublisherAppsVersionEdit',
    meta: {
      layout: 'main',
      disableSSR: true,
    },
    props: true,
    beforeEnter: [mustBeLoggedIn, isPublisher],
    component: () => import('../views/Publisher/PublisherAppsNew.vue'),
  },
];

<template>
  <BaseSvg class="arrow-back-icon" viewBox="0 0 20 20" fill="none">
    <path
      d="M17.5 10L10.8333 10"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 15.8335L2.5 10.0002L10 4.16683L10 15.8335Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'ArrowBackIcon',
  components: {
    BaseSvg,
  },
};
</script>

<script setup lang="ts">
import SettingOption from '@/components/blocks/SettingOption.vue';
import SettingsSection from '@/components/blocks/SettingsSection.vue';
import { usePayments } from '@/composables/usePayments';

import InvoiceItem from './InvoiceItem.vue';

const invoicesEl = ref<ComponentPublicInstance | null>(null);

const payments = usePayments();
const { subscription, loadInvoices, invoices } = payments;

const defaultClosed = ref(true);

const showInvoices = computed(
  () => subscription.value?.subscription_provider === 'opennode' || invoices.value?.length > 0,
);

onMounted(async () => {
  void loadInvoices();
  if (window.location.hash) {
    const hash = window.location.hash.slice(1);
    if (hash.split('/').includes('invoices')) {
      defaultClosed.value = false;
      setTimeout(() => {
        if (invoicesEl.value) {
          invoicesEl.value.$el.scrollIntoView();
        }
      }, 100);
    }
  }
});
</script>

<template>
  <SettingsSection
    v-if="showInvoices"
    heading-text="Invoices"
    :default-closed="defaultClosed"
    ref="invoicesEl"
  >
    <template #content>
      <!-- FIXME: add exclamation to invoices.length before committing -->
      <SettingOption v-if="invoices.length > 0">
        <template #left>
          <InvoiceItem
            v-for="(invoice, idx) in invoices"
            :key="idx"
            :invoice="invoice"
            class="item"
          />
        </template>
      </SettingOption>
      <SettingOption v-else highlighted>
        <template #left>You currently have no invoices.</template>
      </SettingOption>
    </template>
  </SettingsSection>
</template>

<style scoped>
.actions {
  margin: 0 0 1rem 0;
}
.item:nth-child(even) {
  background: rgba(132, 115, 152, 0.2);
}
.item:nth-child(odd) {
  background: rgba(34, 26, 51, 0.3);
}
.item:first-child {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.item:last-child {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}
</style>

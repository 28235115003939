import CatalogJSON from '@clovyr/garden/dist/garden/catalog.json?raw';

import type { Deployment, Manifest, Setting } from '../manifest';
import type { Catalog } from '../types/Catalog';
import { snakeCaseToHuman } from '../util/string';

import { BaseGarden, type Garden } from '.';

function qualifyURL(baseURL: string): string {
  if (!baseURL.endsWith('/catalog')) {
    if (!baseURL.endsWith('/')) {
      baseURL += '/';
    }
    baseURL += 'catalog';
  }
  return baseURL;
}

export function parseCatalog(json: string | Catalog, includeInternal: boolean): Catalog {
  const catalog: Catalog = typeof json === 'string' ? (JSON.parse(json) as Catalog) : json;

  catalog.apps ||= [];
  catalog.bundles ||= [];
  catalog.tags ||= [];

  if (!includeInternal) {
    // hide internal apps
    catalog.apps = catalog.apps.filter((app) => !app.metadata.tags?.includes('internal'));
  }

  // clean up catalog entries, if needed
  catalog.apps.forEach((app) => {
    app.catalog_id ||= catalog.id;
    app.metadata.title ||= app.metadata.name;
    // set setting names from key
    ['deployment', 'basic', 'advanced'].forEach((configType) => {
      if (app.config?.[configType]) {
        (Object.entries(app.config[configType]) as SettingTuple[]).forEach(([key, setting]) => {
          setting.id ||= key;
          setting.name ||= snakeCaseToHuman(setting.id);
        });
      }
    });
  });

  if (!includeInternal && catalog.bundles) {
    // hide internal collections
    catalog.bundles = catalog.bundles.filter((collection) => !collection.tags.includes('internal'));
  }

  return catalog;
}

export class GardenImpl extends BaseGarden implements Garden {
  catalogs: { [id: string]: Catalog };

  baseURL: string;

  loading: { [location: string]: Promise<void> };

  constructor(baseURL: string, catalog: Catalog) {
    super(catalog.bundles, catalog.tags, catalog.apps);
    this.catalogs = {};
    this.catalogs[catalog.id] = catalog;
    this.baseURL = baseURL;
    this.loading = {};
  }

  async addIndex(location: string): Promise<void> {
    const promise = this.tryAddIndex(location);
    this.loading[location] = promise;
    return promise.finally(() => {
      delete this.loading[location];
    });
  }

  async tryAddIndex(location: string): Promise<void> {
    const url = `${qualifyURL(location)}`;
    const res = await fetch(`${url}.json?${Date.now()}`);
    const newCatalog = parseCatalog((await res.json()) as Catalog, true);
    newCatalog.url = url;

    if (newCatalog.bundles) {
      this.bundles = this.bundles.concat(newCatalog.bundles);
    }

    if (newCatalog.apps) {
      this.apps = this.apps.concat(newCatalog.apps);
    }

    if (newCatalog.tags) {
      // merge tags
      this.tags = this.tags.concat(newCatalog.tags);
      const tags: { [k: string]: number } = {};
      this.tags.forEach((tag) => {
        tags[tag] = 1;
      });
      this.tags = Object.keys(tags).sort();
    }

    this.catalogs[newCatalog.id] = newCatalog;
  }

  async isFullyLoaded(): Promise<void> {
    return Promise.allSettled(Object.values(this.loading)).then(() => undefined);
  }

  loadDeploymentDetails(app: Manifest): Promise<Manifest> {
    // load app details from web
    // https://cstatic.app/staging/garden/catalog/nextcloud.json
    return new Promise((resolve, reject) => {
      const baseURL = qualifyURL(this.catalogs[app.catalog_id].url || this.baseURL);
      fetch(`${baseURL}/${app.metadata.id}.json?${Date.now()}`)
        .then(async (res) => {
          if (!res.ok) {
            reject(res);
          }
          const remoteManifest: Manifest = await res.json();
          if (remoteManifest?.deployment?.contents) {
            app.deployment ||= {} as Deployment;
            app.deployment.contents = remoteManifest.deployment.contents;
          }
          resolve(app);
        })
        .catch((err) => {
          if (err.status) {
            reject(new Error(`failed to load deployment details: ${err.status} ${err.statusText}`));
          }
          reject(err);
        });
    });
  }

  async fetchAppVersion(app: Manifest, appVersion: string): Promise<Manifest> {
    const baseURL = qualifyURL(this.catalogs[app.catalog_id].url || this.baseURL);
    const appID = app.metadata.id;
    const appVersionURL = `${baseURL}/${appID}/${appVersion}/${appID}.json?${Date.now()}`;
    const res = await fetch(appVersionURL);
    if (!res.ok) {
      throw new Error(`failed to fetch app version: ${res.status} ${res.statusText}`);
    }
    const data = await res.json();
    if (data && (data.status === 'fail' || data.status === 'error')) {
      throw new Error(data.message);
    }
    return data as Manifest;
  }
}

type SettingTuple = [string, Setting];

/**
 * Crates a new Garden with the built-in stable catalog

 * @param gardenURL env-specific catalog url
 * @param includeInternal
 * @returns
 */
export function newGarden(gardenURL: string, includeInternal: boolean): Garden {
  const catalog = parseCatalog(CatalogJSON, includeInternal);
  catalog.url = gardenURL; // replace built-in catalog url with the env-specific one provided
  return new GardenImpl(gardenURL, catalog);
}

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { GridSection } from '@clovyr/bed';
import PlaceholderBgImage from '@clovyr/bed/assets/images/about-hero.png';

import AppBlock from '@/components/blocks/AppBlock.vue';
import AppSummary from '@/components/tiers/AppSummary.vue';
import { useEditStore } from '@/stores/edit_store';
import AppDetail from '@/views/AppDetail.vue';

const editStore = useEditStore();
const { pubApp, pubAppVersion, manifest, appLogo } = storeToRefs(editStore);
</script>

<template>
  <GridSection class="run-preview carousel">
    <h3>Carousel Card Example</h3>
    <AppBlock
      :app-datum="manifest.metadata"
      :placeholder-bg-image="PlaceholderBgImage"
      :placeholder-logo="appLogo!"
    />
  </GridSection>
  <GridSection class="run-preview">
    <h3>Collection Card Example</h3>
    <AppSummary
      :app="manifest"
      :placeholder-bg-image="PlaceholderBgImage"
      :placeholder-logo="appLogo!"
    />
  </GridSection>
  <GridSection class="run-preview garden">
    <h3>Garden Page Example</h3>
    <div class="garden-page">
      <AppDetail :app="manifest" />
    </div>
  </GridSection>
</template>

<style scoped lang="scss">
.preview-right {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  // gap: 1rem;

  h3 {
    margin-bottom: 1rem;
  }

  a {
    margin-top: 2rem;
  }

  :deep(.hero__inner) {
    @include media-breakpoint-up(xl) {
      padding: 0 1rem;
    }
  }

  :deep(.hero__left) {
    min-width: 20rem;
  }

  :deep(.container-common) {
    padding: 1rem;
  }
  :deep(.grid__col-md-6:nth-child(1)) {
    padding: 1rem 0.5rem 1rem 1rem;
  }
  :deep(.grid__col-md-6:nth-child(2)) {
    border: 1px solid red;
    padding: 1rem 1rem 1rem 0.5rem;
  }
}

.run-preview {
  background-color: #41355b;
  border-radius: 16px;
  padding: 2rem;
  margin-bottom: 1rem;
  .title {
    padding-bottom: 1rem;
  }
}

/* app detail styles on run apps only */
.garden {
  background-color: color(background, secondary-solid);
  border: 15px solid #41355b;
  padding: 0;

  :deep(h3) {
    z-index: 2;
    padding: 2rem 0 0 3rem;
  }
}
.carousel {
  background-color: color(background, secondary-solid);
  border: 15px solid #41355b;
}

/* Carousel Card */
:deep(.app-block) {
  z-index: 0 !important;
  max-width: 20rem;

  .app-block-background__image-overlay,
  .app-block-background__overlay {
    background: none;
  }

  .app-icon {
    .image-media {
      padding: 0.7rem;
    }
  }
}

/* Collection Card */
:deep(.app-summary) {
  z-index: 0;
  background-color: #1e172df7;
  padding-left: 1rem;
  padding-right: 1rem;

  .background__gradient {
    background: linear-gradient(180deg, rgba(20, 20, 20, 0.4) 0%, #221a33 100%);
  }

  @include media-breakpoint-up(xxl) {
    .container {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .app-icon {
    .image-media {
      padding: 0.7rem;
    }
  }
}

/* Garden Page */
.garden-page {
  /* padding: 0; */

  :deep(.app-detail) {
    .app-background-gradient {
      z-index: 1;
      background: linear-gradient(180deg, rgba(20, 20, 20, 0.4) 0%, #221a33 100%);
    }
    .app-background {
      z-index: 0;
    }
    .app-detail__hero {
      z-index: 2;

      .hero__inner {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
    .app-info,
    .app-launch-bar {
      padding: 0 3rem;

      .container {
        padding: unset;
      }
    }
    .app-launch-bar {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
    .app-info-top {
      padding-top: 3rem;
      padding-bottom: 2rem;
      margin-top: unset !important;
    }
    .copy {
      padding-top: 2rem;
    }
    .app-icon {
      .image-media {
        padding: 1rem;
      }
    }
  }

  /* Hiding so that the pricing page doesn't show up in the preview, to reduce visual clutter */
  :deep(.pricing) {
    display: none;
  }
  :deep(.app-tags) {
    display: none;
  }
  :deep(.breadcrumbs) {
    display: none;
  }
  :deep(.overview) {
    @include media-breakpoint-up(md) {
      max-width: 20rem;
    }
  }
  :deep(.app-specs__row) {
    > * {
      margin-right: 1rem;
    }
  }
  :deep(.app-hero-image) {
    z-index: 1;
    position: relative;
  }
}

:deep(.app-launch-bar__title) {
  font-size: 2rem !important;
}
</style>

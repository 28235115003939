export const instanceConfigureFile = 'ic/instance-configure-86524d30?1679346067';
export const instanceConfigureSHA256 =
  '86524d30f8765edaade57582190fc239c4504301b56bf473e4f1bdaa09052041';

export function mkUserData(
  configureKey: string,
  icFile: string = instanceConfigureFile,
  icSHA: string = instanceConfigureSHA256,
  icURL?: string,
  extraAllowedOrigins?: string,
  publicTrefoilURL?: string
): string {
  // TODO: figure out a non-hardcoded way to use good defaults for local dev
  // all prod builds should have these vars set during build/deploy time.
  if (!icFile || icFile.length === 0) {
    icFile = instanceConfigureFile;
    icSHA = instanceConfigureSHA256;
  }
  if (!icSHA || icSHA.length === 0) {
    icSHA = instanceConfigureSHA256;
  }
  if (!icURL) {
    icURL = `https://cstatic.app/leaf/dist/${icFile}`;
  }

  // ! NOTE: any changes to the below script *must* also be replicated in
  // ! `go/trefoil/api/userdata.go`
  // !
  // ! New ENV vars required by instance-configure should be exported below
  // ! *and* added to the `service.Config.EnvVars` map in `instance-configure/main.go`

  let script = `#!/bin/bash
set -euxo pipefail
mkdir -p /tmp/leaf-boot /opt/clovyr/leaf/data /opt/clovyr/leaf/logs
ICBIN=/opt/clovyr/leaf/instance-configure
wget -q ${icURL} -O $ICBIN
if [[ ${icSHA} != disable ]]; then
  sha256sum -c <<< "${icSHA} $ICBIN"
fi
chmod +x $ICBIN

# $HOME isn't set in this script, so we have to be explicit about the kubeconfig file
export KUBECONFIG='/root/.kube/config'
export TREFOIL_URL="${publicTrefoilURL}"

# Unattended upgrades
apt-get update
apt-get install -y unattended-upgrades

`;

  if (extraAllowedOrigins) {
    script += `export LEAF_ALLOWED_ORIGINS="${extraAllowedOrigins}"`;
  }

  script += `
# start bootstrap service
(cd /opt/clovyr/leaf && LEAF_CONFIGURE_KEY="${configureKey}" $ICBIN 2>&1 | tee /opt/clovyr/leaf/logs/bootstrap.log)
# respawn API service
(cd /opt/clovyr/leaf && $ICBIN -service install && $ICBIN -service start &)
  `;

  return script;
}

import { checkCNAME, DNSValidationError } from '@clovyr/pollen/dns/query';
import { WNextDNS } from '@clovyr/pollen/dns/wnext';

export interface ValidationNew {
  name: 'new';
}

export interface ValidationInProgress {
  name: 'in-progress';
}

export interface ValidationSucceeded {
  name: 'succeeded';
  domain: string;
}

export interface ValidationFailed {
  name: 'failed';
  error: string;
}

export interface StateEnabled {
  name: 'enabled';

  /**
   * wnext hostname
   */
  claimedDomain: string;

  /**
   * User-entered custom domain hostname
   */
  inputDomain: string;

  /**
   * Map of validation status for each hostname
   */
  validationStatus: {
    [hostname: string]:
      | ValidationNew
      | ValidationInProgress
      | ValidationSucceeded
      | ValidationFailed;
  };
}

export interface StateLoading {
  name: 'loading';
}

export interface StateDisabled {
  name: 'disabled';
}

export type Status = StateEnabled | StateLoading | StateDisabled;

export interface CustomDNSState {
  status: Status;
  claimedDomain?: string;
}

function initialState(): CustomDNSState {
  return { status: { name: 'disabled' } };
}

export const useCustomDNS = () => {
  const state = ref(initialState());
  const wnextDNS = new WNextDNS();

  const customDomain = computed(() => {
    if (
      state.value.status.name === 'enabled' &&
      state.value.status.validationStatus[state.value.status.inputDomain].name === 'succeeded'
    ) {
      return state.value.status.inputDomain;
    }
    return undefined;
  });

  async function validate(hostnames: string[]) {
    const promises = hostnames.map(async (hostname) => {
      if (state.value.status.name !== 'enabled') {
        return;
      }
      try {
        state.value.status.validationStatus[hostname] = { name: 'in-progress' };
        await checkCNAME(hostname, state.value.status.claimedDomain);
        state.value.status.validationStatus[hostname] = {
          name: 'succeeded',
          domain: state.value.status.inputDomain,
        };
      } catch (err) {
        if (err instanceof DNSValidationError) {
          state.value.status.validationStatus[hostname] = {
            name: 'failed',
            error: err.message,
          };
        } else {
          state.value.status.validationStatus[hostname] = {
            name: 'failed',
            error: 'An unknown error occurred',
          };
          throw err;
        }
      }
    });

    await Promise.allSettled(promises);
  }

  const isValid = computed(() => {
    if (state.value.status.name === 'disabled') {
      return true;
    }
    if (state.value.status.name === 'loading') {
      return false;
    }
    if (state.value.status.name === 'enabled') {
      const statuses = Object.values(state.value.status.validationStatus);
      return statuses.length > 0 && statuses.every((s) => s.name === 'succeeded');
    }
    throw new Error('unreachable');
  });

  async function toggle(checked: boolean) {
    // this should be impossible, but guard for it just in case
    if (state.value.status.name === 'loading') {
      return;
    }

    if (checked) {
      state.value.status = { name: 'loading' };

      if (!state.value.claimedDomain) {
        const record = await wnextDNS.createRecord({ allocateOnly: true });
        state.value.claimedDomain = record.name;
      }

      state.value.status = {
        name: 'enabled',
        inputDomain: '',
        validationStatus: {},
        claimedDomain: state.value.claimedDomain,
      };
    } else {
      state.value.status = { name: 'disabled' };
    }
  }

  function reset() {
    state.value = initialState();
  }

  return {
    state,
    isValid,
    toggle,
    validate,
    customDomain,
    reset,
  };
};

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { AuthState } from '@clovyr/pollen/pollen';

import { useEventBus } from '@/stores/event_bus';
import { usePollenStore } from '@/stores/pollen_store';

import SecretKeyWarning from '../../components/elements/SecretKeyWarning.vue';
import { usePrinter } from '../../composables/usePrinter';

const { authState, isUserAuthenticated } = storeToRefs(usePollenStore());

const props = defineProps<{
  isPubFlow: string;
}>();

const { printRecoveryKit } = usePrinter();

const router = useRouter();

const { eventBus } = useEventBus();

const routeToPublisherPortal = () => {
  eventBus.off('modal:login:close');
  if (isUserAuthenticated.value && authState.value === AuthState.LoggedIn) {
    void router.push({
      path: '/publishers/new',
    });
  }
};
</script>

<template>
  <div class="recovery-view">
    <div class="title">Clovyr Account Created.</div>
    <div class="title">Save Your Recovery Kit.</div>
    <div class="headline">
      Your account has been created successfully! Now download your Recovery Kit, it contains your
      Secret Key that you will need to sign in if you get locked out of your account.
    </div>

    <SecretKeyWarning />

    <Button
      label="Download PDF"
      type="submit"
      class="pdf-button"
      :reversed="true"
      @click="printRecoveryKit"
    />
    <Button
      v-if="isPubFlow"
      label="close and go to publisher portal"
      type="submit"
      translucent
      class="pub-button"
      @click="routeToPublisherPortal"
    />
  </div>
</template>

<style scoped lang="scss">
.recovery-view {
  max-width: 30rem;

  .title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.03em;
  }
  .headline {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: color(grey, secondary);
    margin: 20px 0 20px 0;
  }
  .pdf-button {
    width: 100%;
    font-weight: 500;
  }
  .pub-button {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  > .button {
    margin-top: 1rem;
  }
}
</style>

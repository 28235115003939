<template>
  <BaseSvg viewBox="0 0 20 20" width="20" height="20" fill="none">
    <path
      id="Vector"
      d="M8.95837 17.0312L15.9896 10L8.95838 2.96875"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      id="Vector_2"
      d="M3.95837 17.0312L10.9896 10L3.95838 2.96875"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'SlideIcon',
  components: {
    BaseSvg,
  },
};
</script>

<script setup lang="ts">
import { Button } from '@clovyr/bed';
import { AppLogo, type Logo } from '@clovyr/bed';
import { getAllQueryParams } from '@clovyr/pollen/http/qs';
import { getPrimaryDNS } from '@clovyr/pollen/manifest';

import Module from '@/components/elements/Module.vue';
import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import { useAppHead } from '@/composables/useAppHead';
import Console from '@/views/Library/Console.vue';
import Logs from '@/views/Library/Logs.vue';

import { useAppStatus } from '../../composables/useAppStatus';

const props = defineProps<{
  id: string;
}>();

const deploymentID = toRef(props, 'id');
const { deployment, app, isClovyrCode } = useAppStatus(deploymentID);

const initialTab = ref('');

const logo = computed((): Logo => {
  return {
    logoUrl: app.value.metadata?.logoUrl,
    logoBackgroundColor: app.value.metadata?.logoBackgroundColor,
  };
});

/**
 * Link to the application when clicking the 'open' button.
 *
 * For code environments its san internal URL with the 'view' iframe.
 * For all others, opens the external URL to the server.
 *
 */
const appLink = computed(() => {
  if (isClovyrCode.value) {
    // TODO: fix the link between 'app' & clovyr-code. we know in PublisherApp if it's code or not,
    // perhaps we use that link here? or a new field in the app manifest?
    return `/view/${deployment.value!.instanceName}`;
  }
  let host = deployment.value.customDomain || deployment.value.fqdn;
  const d = getPrimaryDNS(app.value);
  if (d) {
    host = `${d[0]}-${host}`;
  }
  return `https://${host}`;
});

onBeforeMount(() => {
  const { hash } = window.location;
  if (hash) {
    const q = getAllQueryParams();
    if (q.get('logs') !== null) {
      initialTab.value = 'tab2';
    } else {
      initialTab.value = 'tab1';
    }
  }
});

onMounted(() => {
  useAppHead(app.value.metadata, deployment.value.instanceName);
});
</script>

<template>
  <div class="app-instance">
    <div class="app-instance__top">
      <div class="app-background"></div>

      <div class="container">
        <div class="row">
          <div class="grid__col">
            <router-link :to="`/library/${id}`" class="app-instance__back text text--mono">
              <ArrowBackIcon />
              Back to App
            </router-link>
          </div>
        </div>

        <div class="app-instance__hero">
          <div class="app-instance__hero__grid grid">
            <div class="app-instance__hero__logo">
              <AppLogo size="large" :logo="logo" />
            </div>

            <div class="app-instance__hero__title grid__col">
              <div class="app-header__preheading text text--color-grey-secondary">&nbsp;</div>

              <div class="app-header__heading-container">
                <h2 class="app-header__heading">{{ deployment.instanceName }}</h2>
              </div>
            </div>

            <div class="app-instance__hero__buttons grid__col">
              <Button label="Open" :reversed="true" :link="appLink" link-target="_blank" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="deployment && app" class="container">
      <div class="row">
        <div class="grid__col-md-12">
          <Module bigHead hed="Logs" :tabs="['Console', 'Logs']" :initial-tab="initialTab">
            <template #tab1>
              <Console :id="deploymentID" />
            </template>
            <template #tab2>
              <Logs :id="deploymentID" />
            </template>
          </Module>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.app-instance {
  height: 100%;
  display: flex;
  flex-direction: column;
  .container {
    flex-grow: 1;
    .row {
      height: 100%;
      .module {
        height: 95%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
:deep(.module__head) {
  padding-bottom: 0.75rem;
}
:deep(.module__body) {
  flex-grow: 1;
  padding-left: 14px;
  padding-right: 13.5px;
  padding-bottom: 0px;
}
:deep(.selectinput-block) {
  margin-bottom: 1rem;
}
:deep(.selectinput-block__label-holder) {
  margin: 0 0 0.5rem 0.5rem;
}
:deep(.selectinput-block__input) {
  padding-left: 1.25rem;
}
</style>

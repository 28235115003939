import type { Emitter } from 'mitt';

import type { ComputeInstanceSize, ComputeProvider, Instance } from '@clovyr/pollen';
import { mkUserData } from '@clovyr/pollen/compute/userdata';
import {
  getComputeProvider,
  isWNextProvider,
  prepareCreateInstanceRequest,
  supportsOAuth,
} from '@clovyr/pollen/compute/util';
import { createRandomKey } from '@clovyr/pollen/crypto';
import type { DnsProvider } from '@clovyr/pollen/dns';
import {
  CLOVYR_CUSTOM_DOMAIN,
  DeploymentMethod,
  type Manifest,
  type Values,
} from '@clovyr/pollen/manifest';
import { getBuilderForManifest } from '@clovyr/pollen/manifest/configure';
import { isDNSProxied } from '@clovyr/pollen/manifest/configure/util';
import {
  authenticateAppHost,
  configureInstance,
  type LaunchEvents,
  rotateKey,
} from '@clovyr/pollen/remote_exec';
import type { ComputeProviderName } from '@clovyr/pollen/types/Host';

import { config as appConfig } from '@/init/config';

/**
 * get oauth url for indicated compute provider
 * @param provider
 * @returns OAuth URL
 */
function getOauthUrl(provider?: ComputeProvider): string | undefined {
  if (supportsOAuth(provider)) {
    return provider.createOAuthUrl();
  }
  return undefined;
}

/**
 * Open a centered popup window
 *
 * @param url
 * @param target
 * @param width
 * @param height
 * @returns
 */
export function openPopup(
  url: string,
  target: string,
  width: number,
  height: number,
): Window | null {
  const { outerWidth, outerHeight, screenY, screenX } = window;
  const x = outerWidth / 2 + screenX - width / 2;
  const y = outerHeight / 2 + screenY - height / 2;

  return window.open(
    url,
    target,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`,
  );
}

/**
 * initiate oauth flow for in popup window for indicated compute provider.
 * returns Promise of auth token
 *
 * @param provider
 * @returns oauth token
 */
async function doOauth(provider: ComputeProviderName): Promise<string> {
  const computeProvider = getComputeProvider(provider);
  const authUrl = getOauthUrl(computeProvider);
  if (!authUrl) {
    return Promise.reject(new Error('Unknown provider'));
  }

  return new Promise((resolve, reject) => {
    let popup: Window | null = null;

    function handleOauthHash(event: MessageEvent) {
      const { data } = event;
      if (event.origin === window.location.origin && data?.source === 'clovyr-oauth') {
        window.removeEventListener('message', (e) => handleOauthHash(e));
        setTimeout(() => {
          popup?.close();

          if (data.payload?.access_token) {
            resolve(data.payload.access_token);
          } else {
            reject(new Error(JSON.stringify(data.payload)));
          }
        }, 1500);
      }
    }

    window.addEventListener('message', (e) => handleOauthHash(e));

    popup = openPopup(authUrl, 'Oauth', 800, 600);
  });
}

async function deployInstance(
  manifest: Manifest,
  instance: Instance,
  values: Values,
  emitter: Emitter<LaunchEvents>,
  dnsProvider: DnsProvider,
  reconfigure?: boolean,
): Promise<Instance> {
  // console.log('using fqdn', instance.fqdn);
  // console.log('going to deploy', manifest);

  const isPrimaryProxied = isDNSProxied(manifest, values[CLOVYR_CUSTOM_DOMAIN] as string);

  if (manifest.deployment.dns?.additional_hosts) {
    // create additional dns names
    // do this before the actual configure call so that we are guaranteed
    // to have an IP address, even for wnext instances.
    Object.entries(manifest.deployment.dns.additional_hosts).forEach(async ([extraHost, opts]) => {
      const name = `${extraHost}-${instance.fqdn}`;
      let proxied = true;
      if (typeof opts !== 'string' && opts.proxy === false) {
        proxied = false;
      } else if (!isPrimaryProxied) {
        proxied = false;
      }
      // TODO: handle dns errors
      // @ts-expect-error missing props
      await dnsProvider.createRecord({ record: { name, content: instance.ipv4!, proxied } });
    });
  }

  const configureScript = getBuilderForManifest(
    manifest,
    DeploymentMethod.DockerCompose,
  ).mkConfigureScript(manifest, values);

  await configureInstance({
    fqdn: instance.fqdn!,
    configureKey: instance.configurekey!,
    configureScript,
    emitter,
    isPrimaryProxied,
    checkPath: manifest.deployment.checkPath,
    reconfigure,
  });

  // console.log('completed deployment');

  return instance;
}

/**
 * Rotate out the configure key on the given server.
 *
 * This is the final step after deploying or claiming a new server.
 *
 * @param instance
 */
async function rotateKeys(instance: Instance): Promise<string> {
  const newKey = createRandomKey();
  await rotateKey(instance.fqdn!, instance.configurekey!, newKey);
  await authenticateAppHost(instance.fqdn!, newKey);
  return newKey;
}

async function launchInstance(
  computeProvider: ComputeProvider,
  dnsProvider: DnsProvider,
  proxyDNS: boolean,
  region: string,
  size?: ComputeInstanceSize,
  reservedFQDN?: string,
): Promise<Instance> {
  // setState(LauncherState.LaunchingCreateDNS);

  const instanceParams = prepareCreateInstanceRequest(computeProvider, region, size);

  if (reservedFQDN) {
    instanceParams.name = reservedFQDN;
  } else {
    // pre-allocate dns before creating instance
    const dns = await dnsProvider.createRecord({
      allocateOnly: true,
      // @ts-expect-error missing props
      record: { proxied: proxyDNS },
    });
    if (!dns?.name) {
      return Promise.reject(new Error('failed to get dns'));
    }
    instanceParams.name = dns.name;
  }

  // setState(LauncherState.LaunchingCreateInstance);

  const key = createRandomKey();
  instanceParams.userData = mkUserData(
    key,
    appConfig.VITE_IC_BIN,
    appConfig.VITE_IC_SHA,
    appConfig.VITE_IC_URL,
    appConfig.VITE_ALLOWED_ORIGINS,
    appConfig.VITE_PUBLIC_URL,
  );

  const instance = await computeProvider.createInstance(instanceParams);
  instance.configurekey = key;
  // console.log('instance has been launched');

  if (instance.ipv4 && !isWNextProvider(computeProvider)) {
    // TODO: we should always do it here, including wnext
    // some compute providers don't immediately return with an IP
    // i.e., wnext, which is an async launch process.
    // in that case, dns will get updated automatically on the backend.
    // setState(LauncherState.LaunchingUpdateDNS);
    await dnsProvider.updateRecord({
      // @ts-expect-error missing props
      record: {
        name: instance.fqdn!,
        content: instance.ipv4,
        proxied: proxyDNS,
      },
    });
  }

  return instance;
}

export const config = {
  s3URL: '',
};

export const useInstanceLauncher = () => {
  return {
    doOauth,
    launchInstance,
    deployInstance,
    rotateKeys,
  };
};

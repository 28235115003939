import { WNextBase } from '../api/wnext-base';
import type { Request } from '../types';
import type { AccessKey } from '../types/AccessKey';

export interface ListAccessKeysRequest extends Request {}

export interface CreateAccessKeyRequest extends Request {
  fqdn: string;
}

export interface DeleteAccessKeyRequest extends Request {
  accessKey: string;
}

export interface DeleteAccessKeyResponse extends Request {}

/**
 * Access API manages S3-like access/secret keypairs for use with Restic.
 */
export interface AccessAPI {
  listAccessKeys: (req?: ListAccessKeysRequest) => Promise<Array<AccessKey>>;
  createAccessKey: (req: CreateAccessKeyRequest) => Promise<AccessKey>;
  deleteAccessKey: (req: DeleteAccessKeyRequest) => Promise<DeleteAccessKeyResponse>;
}

export class WNextAccessAPI extends WNextBase implements AccessAPI {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async listAccessKeys(req?: ListAccessKeysRequest): Promise<AccessKey[]> {
    return this.unwrap(await this.client.get('/access'));
  }

  async createAccessKey(req: CreateAccessKeyRequest): Promise<AccessKey> {
    return this.unwrap(await this.client.post('/access', req));
  }

  async deleteAccessKey(req: DeleteAccessKeyRequest): Promise<DeleteAccessKeyResponse> {
    return this.unwrap(await this.client.delete('/access', req));
  }
}


<template>
  <BaseSvg class="edit-icon" viewBox="0 0 20 20" width="20" height="20" fill="none">
    <path
      vector-effect="non-scaling-stroke"
      d="M15.5 8.5L11.5 4.5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      vector-effect="non-scaling-stroke"
      d="M8 16L3 17L4 12L14 2L18 6L8 16Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </BaseSvg>
</template>

<script>
import { BaseSvg } from '@clovyr/bed';

export default {
  name: 'EditIcon',
  components: {
    BaseSvg,
  },
};
</script>

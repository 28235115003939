<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { usePollenStore } from '../../stores/pollen_store';

import NavAppListItem from './NavAppListItem.vue';

type LibraryItemProps = {
  /**
   * Check if sidebar is open or closed
   */
  closed: boolean;
  /**
   * Check if library is toggled on or not
   */
  libraryToggled: boolean;
};

const props = defineProps<LibraryItemProps>();

const { sortedDeployments } = storeToRefs(usePollenStore());

const deploymentList = computed(() => {
  return props.libraryToggled ? sortedDeployments.value.slice(0, 5) : sortedDeployments.value;
});
</script>

<template>
  <ul v-if="sortedDeployments.length" class="app-library__items">
    <NavAppListItem
      v-for="deployment in deploymentList"
      :key="deployment.instanceName"
      :id="deployment.id"
      :closed="closed"
      :libraryToggled="libraryToggled"
    ></NavAppListItem>
  </ul>
</template>

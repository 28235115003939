import { defineStore } from 'pinia';

import { useImageAsset } from '@clovyr/bed/composables/useImageAsset';
import type { PublisherApp, PublisherAppFile, PublisherAppVersion } from '@clovyr/pollen';
import type { Manifest } from '@clovyr/pollen/manifest';
import { deepCopy } from '@clovyr/pollen/util/deep-copy';
import { slugify } from '@clovyr/pollen/util/slugify';

import { usePollenStore } from './pollen_store';

const DEFAULT_LOGO = 'https://cstatic.app/i/default/logo.svg';
const DEFAULT_HERO = 'https://cstatic.app/i/default/hero.webp';
const DEFAULT_BG = 'https://cstatic.app/i/default/bg.png';

export interface PublisherAppFileNew extends PublisherAppFile {
  _id: string;
}

function createSlug(name: string, isClovyrCode: boolean): string {
  let slug = slugify(name);
  if (isClovyrCode && !slug.startsWith('code-')) {
    slug = `code-${slug}`;
  }
  return slug;
}

function newRunManifest(): Manifest {
  // @ts-expect-error missing fields/mismatch
  return {
    // Generic app metadata
    metadata: {
      id: '',
      name: 'My TODO App',
      title: 'My TODO App - Your Ultimate Task Management Companion',
      subtitle: 'Streamline Your Tasks with Ease',
      tagline: 'Organize. Prioritize. Achieve.',
      description:
        'My TODO App is a robust task management app designed to help individuals and teams stay organized and productive. With intuitive features and seamless integration, managing tasks has never been easier.',
      huginnSays:
        'Your tasks, your way. My TODO App adapts to your workflow, keeping you on track and focused.',
      version: '1.0.0',
      license: 'MIT',
      gettingStarted:
        'Get started in minutes. Sign up on our website and start managing your tasks effortlessly.',
      website: {
        url: 'https://mytodo.example.com',
      },
      url: 'https://mytodo.example.com',
      logoUrl: DEFAULT_LOGO,
      logoBackgroundColor: '#28bd7b',
      heroImageUrl: DEFAULT_HERO,
      backgroundImageUrl: DEFAULT_BG,
      repo: {
        text: 'Github',
        url: 'https://git.example.com/mytodo',
      },
      docsUrl: 'https://mytodo.example.com/developers',
      features: [
        'Create, edit, and organize tasks',
        'Assign tasks to team members',
        'Set due dates and reminders',
        'Track task progress and completion',
        'Integrate with third-party apps like Slack and Google Calendar',
        'Customizable task views and filters',
      ],
      tags: ['examples'],
      media: [
        {
          type: 'video',
          url: '',
          description: 'Watch how My TODO App simplifies task management for teams.',
        },
      ],
      featuredMedia: {
        type: 'image',
        url: '',
        description: 'See My TODO App in action - your all-in-one task management solution.',
      },
    },

    deployment: {
      methods: ['docker-compose'],
      'docker-compose': {
        networks: [],
        services: {
          helloworld: 5555,
        },
      },
    },

    config: {
      basic: {
        NAME: {
          description: 'Name of person to greet',
          required: false,
          value: {
            type: 'string',
            default: 'World',
          },
        },
      },
      advanced: {
        RAND: {
          description: 'Demonstrates a random value',
          random: true,
          value: {
            type: 'string',
          },
        },
      },
    },
  } as Manifest;
}

function newDevelopManifest(): Manifest {
  const { garden } = usePollenStore();
  const manifest = deepCopy(garden.getAppByID('clovyr-code')!);
  manifest.catalog_id = 'clovyr-platform'; // will get set server-side anyway
  Object.assign(manifest.metadata, {
    name: 'My Develop App',
    description: 'App dev SDK for my app',
    version: '1.0.0',
    license: 'unlicensed',
    logoUrl: DEFAULT_LOGO,
    logoBackgroundColor: '#9761f9',
  });
  return manifest;
}

// for lack of a better name
// holds temporary manifest while editing it
export const useEditStore = defineStore('edit_app', () => {
  const pubApp = ref<PublisherApp>({} as PublisherApp);
  const pubAppVersion = ref<PublisherAppVersion>({} as PublisherAppVersion);
  const manifest = ref<Manifest>(newRunManifest());

  function resetClovyrCode() {
    pubApp.value = {
      name: 'My Develop App',
      app_type: 'clovyr_code',
    } as PublisherApp;

    pubAppVersion.value = {
      version: '1.0.0',
      base_image: 'clovyr/code-javascript',
      build_script: `#!/bin/bash

# This script will be executed from within the Dockerfile during the
# \`docker build\` using the base image selected above. Use it to install any
# additional tools or packages needed by your application.
#
# This script will run as the \`clovyr\` user during the build.
# Use \`sudo\` to install any system-wide packages or tools.

set -eo pipefail

# Install jq and Yeoman CLI tools
sudo apt-get update && sudo apt-get install -y jq
npm install -g yo
      `,
      init_script: `#!/bin/bash

# This script will be executed at runtime, when the container starts up for the
# first time. Use it to perform any additional setup or configuration needed by
# your environment, such as downloading external datasets or generating
# scaffolding for your application.
#
# This script will run as the \`clovyr\` user (uid=1000, sudo available).

# Bootstrap additional VS Code extensions
# Extensions are installed from the OpenVSX Registry [https://open-vsx.org/]
install_extensions vue.volar vitest.explorer

npm create -y vue@latest myproject -- --typescript --router --pinia --vitest
      `,
    } as PublisherAppVersion;

    manifest.value = newDevelopManifest();
  }

  function resetRunApp() {
    pubApp.value = {
      name: 'My TODO App',
      app_type: 'custom',
    } as PublisherApp;
    pubAppVersion.value = {
      version: '1.0.0',
    } as PublisherAppVersion;

    pubAppVersion.value.files = [
      {
        _id: crypto.randomUUID(),
        name: 'docker-compose.yml',
        content: `
services:
  helloworld:
    image: clovyr/helloworld:latest
    restart: always
    # our test app listens on port 5555, however we define it in the deployment section
    # (see details tab)
    #expose:
    #  - 5555
    volumes:
      - ./_extra.html:/_extra.html
`,
      },
      {
        _id: crypto.randomUUID(),
        name: '_extra.html',
        content: `<h3>Your random value: {{ RAND }}</h3>`,
      },
    ] as PublisherAppFileNew[];

    manifest.value = newRunManifest();
  }

  /**
   * Reset the store with empty values
   */
  function reset(isClovyrCode: boolean) {
    if (isClovyrCode) {
      resetClovyrCode();
    } else {
      resetRunApp();
    }

    pubApp.value.slug = createSlug(pubApp.value.name, isClovyrCode);
    manifest.value.metadata.id = pubApp.value.slug;
  }

  const imageAsset = useImageAsset();
  // Returns logo placeholder for Run & Develop apps
  const appLogo = computed(() => {
    const { metadata } = manifest.value;
    return {
      logoUrl: metadata.logoUrl,
      logoBackgroundColor: metadata.logoBackgroundColor || 'transparent',
    };
  });

  // Returns background placeholder for Run apps only
  const appBackground = computed(() => {
    if (pubApp.value?.app_type === 'clovyr_code') {
      return null;
    }
    const { metadata } = manifest.value;
    const bg = `url("${imageAsset.getURL(metadata.backgroundImageUrl!)}")`;
    return {
      '--bg-url': bg,
      '--z-index': -2,
    };
  });

  return {
    pubApp,
    pubAppVersion,
    manifest,

    appLogo,
    appBackground,

    reset,
    createSlug,
  };
});

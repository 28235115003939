import { defineStore } from 'pinia';

import { type Deployment } from '@clovyr/pollen';
import { type Manifest } from '@clovyr/pollen/manifest';

export const totalUpdateSteps = 7;

export enum UpdateStatus {
  Started = 1,
  Pulling,
  Stopping,
  Snapshotting,
  Restoring,
  Restarting,
  Complete,
}

export type UpdateJob = {
  manifest: Manifest;
  deployment: Deployment;
  status: UpdateStatus;
};

type UpdateJobs = { [deploymentID: string]: UpdateJob };

export const useUpdateStore = defineStore('update', () => {
  const updateJobs: Ref<UpdateJobs> = ref({});

  /**
   * Add the job to the store and return the reactive instance
   *
   * @param job
   * @returns
   */
  function addJob(job: UpdateJob) {
    updateJobs.value[job.deployment.id] = job;
    return updateJobs.value[job.deployment.id];
  }

  return {
    updateJobs,

    addJob,
  };
});

<template>
  <span class="trash">
    <TrashIcon />
  </span>
</template>

<script>
import TrashIcon from '@/components/icons/TrashIcon.vue';

export default {
  name: 'Trash',
  components: {
    TrashIcon,
  },
};
</script>

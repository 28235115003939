<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { usePollenStore } from '@/stores/pollen_store';

import InstanceItem from './InstanceItem.vue';

const props = defineProps<{
  publisherID?: string;
  appID: string;
}>();

const pollenStore = usePollenStore();
const { sortedDeployments } = storeToRefs(pollenStore);

const codeDeployments = computed(() =>
  sortedDeployments.value.filter(
    (d) => d.appID === props.appID && (!props.publisherID || d.publisherID === props.publisherID),
  ),
);
</script>

<template>
  <InstanceItem
    v-for="deployment in codeDeployments"
    :key="deployment.id"
    :deploymentID="deployment.id"
  />
</template>

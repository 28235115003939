const clovyrCodeAppIDs = ['clovyr-code', 'chialisp', 'code-chia'];

/**
 * Return true if the given App ID is known to be clovyr-code.
 *
 * @param appID
 * @returns
 */
export function isClovyrCode(appID: string): boolean {
  if (!appID) {
    return false;
  }
  const id = appID.toLowerCase();
  return id.startsWith('code-') || id.includes('/code-') || clovyrCodeAppIDs.includes(id);
}

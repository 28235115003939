// Having separate files for all these small classes seems overkill.
/* eslint-disable max-classes-per-file */

export class AlreadySignedUpError extends Error {
  constructor() {
    super('already signed up');
  }
}

export class IncorrectLoginError extends Error {
  constructor() {
    super('incorrect login information');
  }
}

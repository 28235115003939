<script setup lang="ts">
import { Button, Hero, SproutIcon, Tips } from '@clovyr/bed';
import { IconPosition } from '@clovyr/bed/components/controls/types';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import { useImageAsset } from '@clovyr/bed/composables/useImageAsset';
import { slugify } from '@clovyr/pollen/util/slugify';

import Breadcrumbs from '@/components/elements/Breadcrumbs.vue';
import Embed from '@/components/elements/Embed.vue';
import Modal from '@/components/elements/Modal.vue';
import Tag from '@/components/elements/Tag.vue';
import { claimClovyrCode } from '@/composables/claimClovyrCode';
import { useCloudType } from '@/composables/useCloudType';
import { useClovyrCodeHead } from '@/composables/useClovyrCodeHead';
import { useDeploymentStore } from '@/stores/deployment_store';
import { usePollenStore } from '@/stores/pollen_store';

const props = defineProps<{ err?: string }>();
const router = useRouter();
const route = useRoute();
const deploymentStore = useDeploymentStore();
const imageAsset = useImageAsset();
const { cloudName } = useCloudType();
const pollenStore = usePollenStore();
const { garden } = pollenStore;

const showModal = ref(false);
const errorMessage = ref('');

const data = garden.getAppByID('chialisp')!.metadata;

onMounted(async () => {
  await deploymentStore.loadDeployments();
  if (props.err === 'unavailable') {
    errorMessage.value = 'No instance available. Please try again in a few minutes';
    // edit url so that reloading this page doesn't show the modal again
    window.history.replaceState({}, '', route.path);
  }
  if (errorMessage.value) {
    showModal.value = true;
  }
});

const crumbs = [
  {
    name: 'garden',
    routeTo: '/',
    color: '1',
  },
  {
    name: data.name,
  },
];

const handleLaunch = async () => {
  try {
    const newRoute = await claimClovyrCode('chialisp', 'code-chia', router, undefined, true);
    void router.push(newRoute);
  } catch (error) {
    if (error instanceof Error) {
      errorMessage.value = error.message;
      showModal.value = true;
    }
  }
};

const logo = {
  logoUrl: data.logoUrl,
};

const appBackground = (() => {
  if (!data?.backgroundImageUrl) {
    return undefined;
  }

  const bg = `url("${imageAsset.getURL(data?.backgroundImageUrl)}")`;

  return {
    '--bg-url': bg,
  };
})();

useClovyrCodeHead(data.name);
</script>

<template>
  <div class="app-detail app-detail-clovyr-app" :style="appBackground">
    <div class="app-detail__top">
      <div class="app-background-gradient"></div>
      <div class="app-background"></div>

      <div class="app-detail-masthead">
        <div class="app-detail-masthead__left">
          <Hero subheadingTextShade="light" class="app-detail__hero">
            <template #heading>{{ data.title }}</template>
            <template v-if="data.description" #subheading>{{ data.description }}</template>
            <template #top>
              <AppLogo :logo="logo" size="large" class="app-logo-detail" />

              <Breadcrumbs :crumbs="crumbs" />
            </template>

            <template v-slot:right>
              <div class="app-controls button-group">
                <Button
                  :label="'launch'"
                  :iconPosition="IconPosition.Right"
                  :reversed="true"
                  @click="handleLaunch"
                  class="launch-button"
                >
                  <SproutIcon />
                </Button>

                <!-- TODO: bookmark -->
                <!-- <Bookmark /> -->
              </div>
            </template>
          </Hero>
        </div>
      </div>

      <div class="app-hero-image grid">
        <Embed
          :src="`https://www.youtube.com/embed/${data.featuredMedia?.url}`"
          title="YouTube video player"
        />
      </div>
    </div>

    <Tips>
      <template #heading> huginn says: </template>

      <template #body>{{ data.huginnSays }}</template>
    </Tips>

    <section class="app-info">
      <div class="container container-common">
        <div class="app-info-top row">
          <div class="app-info-top__left grid__col-md-6">
            <div class="app-info__copy">
              <h2 class="app-info__heading">Overview</h2>
              <h4>Better tools, better solutions, happier teams</h4>
              <p>
                Clovyr brings the flexibility and ease of use of modern application development to
                next-generation applications: ones that help people and businesses connect in new
                ways while keeping sensitive data safer. At Clovyr, we think differently. We think
                you shouldn't just have API access to someone else's infrastructure, you should
                control your own. We think privacy and security aren't products, they're emergent
                properties of thoughtfully designed systems. We think you'll like it.
              </p>
            </div>

            <div class="app-tags" v-if="data.tags?.length">
              <div class="app-tags__heading">Tagged in:</div>

              <Tag v-for="tag in data.tags" :key="slugify(tag)" :label="tag" :slug="slugify(tag)" />
            </div>
          </div>

          <div class="app-info-top__right grid__col-md-6">
            <div class="app-info__copy">
              <h2 class="app-info__heading">Features</h2>

              <ul>
                <li v-for="(feature, index) in data.features" :key="index">
                  {{ feature }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="app-specs copy">
          <div class="app-specs__row">
            <span class="app-specs__key" v-if="data.website?.text"
              >Website:
              <a :href="data.website?.url" class="app-specs__value app-specs__value--link">{{
                data.website.text
              }}</a>
            </span>
            <span
              v-if="(data.website?.text && data.version) || (data.website?.text && data.repo?.text)"
              class="app-specs__spacer"
              >|</span
            >
            <span class="app-specs__key" v-if="data.repo?.text"
              >Source Code:
              <a :href="data.repo?.url" class="app-specs__value app-specs__value--link">{{
                data.repo?.text
              }}</a>
            </span>
            <span v-if="data.version && data.repo?.text" class="app-specs__spacer">|</span>
            <span class="app-specs__key" v-if="data.version"
              >Version:
              <span class="app-specs__value">{{ data.version }}</span>
            </span>
          </div>
        </div>
      </div>
    </section>

    <div class="app-launch-bar">
      <div class="container container-common">
        <div v-if="false" class="row">
          <div class="grid__col-md-8 app-launch-bar__left">
            <h2 class="app-launch-bar__title">Launch Code with Clovyr on {{ cloudName }}</h2>
          </div>

          <div class="grid__col-md-4 app-launch-bar__right">
            <Button
              label="launch"
              :iconPosition="IconPosition.Right"
              :reversed="true"
              @click="handleLaunch"
              class="launch-button"
            >
              <SproutIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal v-if="showModal" @close="showModal = false">
    <template v-slot:body>
      <div class="instance-error">
        <h4>{{ errorMessage }}</h4>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.app-specs__value {
  padding: 0 0 0 5px;
}

.app-detail {
  :deep(.hero) {
    .hero__inner {
      align-items: flex-start;
      margin-bottom: space(5);
      padding-top: space(10);

      @include media-breakpoint-up(md) {
        align-items: flex-end;
        margin-bottom: space(9);
      }

      .hero__left {
        .app-icon {
          display: none;

          @include media-breakpoint-up(md) {
            display: block;
          }
        }
      }

      .hero__middle {
        margin-bottom: 0;

        .hero__heading {
          @include font-size(32);

          @include media-breakpoint-up(md) {
            @include font-size(42);
          }

          @include media-breakpoint-up(xl) {
            @include font-size(67);
          }

          margin-bottom: 0;
        }
      }
    }
  }

  .app-background-gradient {
    bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -4;
    width: 100%;
    height: space(60);
    background: linear-gradient(180deg, rgb(20 20 20 / 80%) 0%, color(negative) 100%);
  }

  .app-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
    width: 100%;
    height: space(60);
    background: var(--bg-url);

    @include background-image;
  }

  .app-hero-image {
    display: flex;
    justify-content: space-around;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      padding-left: 0;
    }

    .image-media,
    .embed {
      box-shadow: space(1.2) space(1.2) space(20) 0 transparentize(color(black), 0.5);
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      width: 80%;
    }

    .embed {
      position: relative;

      > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &::before {
        display: block;
        padding-top: 56.25%;
        content: '';
      }

      :deep(iframe) {
        border: 0;
        width: 100%;
      }
    }
  }

  .app-logo-detail {
    margin-bottom: space(3.7);
  }

  .app-controls {
    margin-top: space(3);
  }

  .app-tags {
    margin-top: space(3);

    &__heading {
      display: inline-block;
      margin-right: space(0.8);
    }
  }

  .app-info {
    color: color(grey, tertiary);
    background-color: transparentize(color(background, dark-navy), 0.5);

    &__copy {
      @extend .copy;

      @include font-size(16);

      color: color(white);
      line-height: 1.4;

      p {
        margin-top: space(4);
      }
    }

    &__heading {
      @include font-size(26);

      color: color(white);
      font-weight: 500;
      letter-spacing: -0.03rem;
    }
  }

  .app-info-top {
    padding-top: space(8);
    padding-bottom: space(4);

    @include media-breakpoint-up(md) {
      padding-bottom: space(8);
    }

    .app-info-top__right {
      margin-top: space(3);

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .app-specs {
    &__row:not(:last-child) {
      margin-bottom: space(0.8);
    }

    &__key {
      display: inline-block;
      margin-right: space(0.8);
    }

    &__value {
      @extend .text--mono;

      display: inline-block;

      &--link {
        color: color(action, primary);
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    &__spacer {
      margin: 0 space(2.2);
    }
  }

  .app-launch-bar {
    padding-top: space(4);
    padding-bottom: space(8);
    background-color: transparentize(color(background, dark-navy), 0.5);

    @include media-breakpoint-up(md) {
      padding-top: space(8);
    }

    .row {
      align-items: flex-start;
    }

    &__right {
      display: flex;
      align-items: flex-end;
      margin-top: space(2);

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
    }

    &__title {
      @include font-size(22);

      color: color(grey, tertiary);
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: -0.03rem;

      @include media-breakpoint-up(md) {
        @include font-size(30);

        font-weight: 400;
      }

      @include media-breakpoint-up(xl) {
        @include font-size(36);
      }
    }
  }
}
</style>

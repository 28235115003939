<script lang="ts" setup>
// import Bookmark from '@/components/controls/Bookmark.vue';
import { Background, Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import type { Logo } from '@clovyr/bed/components/elements/AppLogo';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import CLink from '@clovyr/bed/components/elements/CLink.vue';
import { useImageAsset } from '@clovyr/bed/composables/useImageAsset';
import { isClovyrCode } from '@clovyr/pollen/garden';
import type { Manifest } from '@clovyr/pollen/manifest';

import ArrowForwardsIcon from '@/components/icons/ArrowForwardsIcon.vue';
import { appURL } from '@/router/util';
import LaunchButton from '@/views/AppLauncher/LaunchButton.vue';

const imageAsset = useImageAsset();

const props = defineProps<{
  app: Manifest;
  placeholderBgImage?: string;
  placeholderLogo?: Logo;
}>();

const appDetailLink = computed(() => appURL(props.app));

const backgroundImage = computed(() => {
  return imageAsset.getURL(props.app.metadata.backgroundImageUrl!);
});

const isCode = computed(() => {
  return props.app.metadata.name && isClovyrCode(props.app.metadata.name);
});

const slots = useSlots();

const slotFilled = computed(() => {
  return !!slots.default;
});

const logo = computed(() => {
  if (props.app.metadata.logoUrl) {
    return props.app.metadata;
  }
  if (props.placeholderLogo) {
    return props.placeholderLogo;
  }
  return { logoUrl: 'https://cstatic.app/i/default/logo.svg', logoBackgroundColor: '#28bd7b' };
});
</script>

<template>
  <div class="app-summary">
    <Background :image="backgroundImage" :gradient="9" class="app-summary__background" />

    <div class="container container-common">
      <div class="app-summary__top">
        <AppLogo class="app-logo-block" :logo="logo" />

        <div class="app-controls button-group">
          <LaunchButton
            v-if="app.metadata.id !== 'clovyr-code'"
            :app="app"
            :label="isCode ? 'Develop' : 'launch'"
          />

          <Button :shape="ButtonShape.Circle" :link="appDetailLink">
            <span class="button__icon"><ArrowForwardsIcon /></span>
          </Button>
        </div>
      </div>

      <div class="app-summary-main row">
        <div class="app-summary-main__left grid__col-lg-6">
          <h2 class="app-summary__heading">
            <c-link :to="appDetailLink" :label="app.metadata.name" />
          </h2>
          <div>
            <!-- TODO: what is design if props lacks subtitle (it's optional in Manifest.metadata) -->
            <div
              v-if="app.metadata.title && app.metadata.title !== app.metadata.name"
              class="app-summary__subheading"
            >
              {{ app.metadata.title }}<br />
            </div>
            <div class="subtitle" v-if="app.metadata.subtitle">
              {{ app.metadata.subtitle }}
            </div>

            <div v-if="slotFilled && app.metadata.description" class="app-description">
              {{ app.metadata.description }}
            </div>
          </div>
        </div>
        <div class="app-summary-main__right grid__col-lg-6">
          <div class="app-description">
            {{ app.metadata.description }}
          </div>
        </div>
      </div>
    </div>
    <div class="container container-common">
      <!-- TODO: what is design if props lacks website (it's optional in Manifest.metadata) -->
      <div v-if="app.metadata.website?.text" class="app-website">
        <!-- currently called website instead of publisher, as all are published by clovyr -->
        <span>Website: </span>
        <c-link
          :to="app.metadata.website.url"
          :label="app.metadata.website.text"
          target="_blank"
          class="app-website__name"
        />
      </div>

      <div v-else-if="app.metadata.url" class="app-website">
        <span>Website: </span>
        <c-link
          :to="app.metadata.url"
          :label="app.metadata.name"
          target="_blank"
          class="app-website__name"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-summary {
  @extend .background-gradient--light-background;

  position: relative;
  padding: space(4) 0 space(12);

  @include media-breakpoint-up(md) {
    padding: space(9.5) 0;
  }

  &__background {
    height: space(24);
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: space(2);

    @include media-breakpoint-up(md) {
      margin-bottom: space(3);
    }
  }

  .app-summary-main {
    &__right {
      iframe {
        width: 100%;
        height: space(37);
      }
    }
  }

  .app-controls {
    position: absolute;
    bottom: space(5);

    @include media-breakpoint-up(md) {
      position: static;
      bottom: auto;
    }

    &.button-group {
      align-items: center;
    }
  }

  &__heading {
    @include font-size(22);

    @include media-breakpoint-up(md) {
      @include font-size(30);
    }

    @include media-breakpoint-up(xl) {
      @include font-size(36);
    }

    font-weight: 500;
    margin-bottom: space(1.5);
  }

  &__subheading {
    @extend .text--subheading;

    @include font-size(20);

    @include media-breakpoint-up(xl) {
      @include font-size(24);
    }

    margin-bottom: space(1.2);
    color: color(grey, secondary);
  }

  .subtitle {
    @include font-size(16);
  }

  .app-description {
    @include font-size(16);

    margin-bottom: space(1);
  }

  .app-website {
    margin-top: space(2);
    margin-bottom: space(2);

    &__name {
      @extend .text--mono;
      @extend .copy--link;

      margin-left: space(1);
      color: rgb(var(--collection-color-theme));
    }
  }

  // theme color overrides

  :deep(.button) {
    background-color: rgba(var(--collection-color-theme), 0.1);
    color: rgb(var(--collection-color-theme));
    stroke: rgb(var(--collection-color-theme));

    &:hover {
      background-color: rgba(var(--collection-color-theme), 0.3);
    }
  }
}
</style>

import { useSeoMeta, type UseSeoMetaInput } from '@unhead/vue';

import { useImageAsset } from '@clovyr/bed/composables/useImageAsset';
import type { Metadata } from '@clovyr/pollen/manifest';

import { config } from '@/init/config';

export const useAppHead = (metadata: Metadata, deploymentName?: string) => {
  const route = useRoute();
  const imageAsset = useImageAsset();

  const baseURL = config.VITE_PUBLIC_URL;

  const jsonSchema = {
    '@context': 'http://www.schema.org',
    '@type': 'Organization',
    name: `${deploymentName || metadata.name || ''}`,
    description: `${metadata.description || ''}`,
    url: `${baseURL}${route.fullPath}`,
    image: [imageAsset.getURL(metadata.heroImageUrl || '')],
  };

  useHead({
    title: `${deploymentName || metadata.name} | Clovyr`,
    link: [{ rel: 'canonical', href: `${baseURL}${route.fullPath}` }],
    script: [
      {
        type: 'application/ld-json',
        children: JSON.stringify(jsonSchema),
      },
    ],
  });

  const meta: UseSeoMetaInput = {
    description: `${metadata.description || ''}`,
    ogTitle: `${deploymentName || metadata.name || ''} | Clovyr`,
    ogDescription: `${metadata.description || 'Clovyr app.'}`,
    ogUrl: `${baseURL}${route.fullPath}`,
    ogImage: imageAsset.getURL(metadata.heroImageUrl || '', baseURL),
  };
  if (meta.ogImage) {
    meta.ogImageAlt = `Hero image for ${metadata.name}`;
  }
  useSeoMeta(meta);
};

<script setup lang="ts">
import GreenCheck from '@clovyr/bed/assets/images/green-check.svg';
import loaderGraphic from '@clovyr/bed/assets/images/loader.gif';
import RedX from '@clovyr/bed/assets/images/red-x.svg';
import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';

import { useLauncherStore } from '@/stores/launcher_store';

const { hostname } = defineProps<{
  hostname: string;
  multiple: boolean;
}>();

const launcherStore = useLauncherStore();
const { customDNS } = launcherStore;

const status = computed(() => {
  if (
    customDNS.state.status.name === 'enabled' &&
    customDNS.state.status.validationStatus[hostname]
  ) {
    return customDNS.state.status.validationStatus[hostname];
  }
  return undefined;
});

const inputHost = computed(() => {
  if (customDNS.state.status.name === 'enabled') {
    return customDNS.state.status.inputDomain;
  }
  return undefined;
});

function escape(str: string) {
  return str.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

const host = computed(() => {
  const input = inputHost.value || '<app.example.com>';
  if (hostname === input) {
    return `<b style='color:white'>${escape(hostname)}</b>`;
  }
  const i = hostname.indexOf(input);
  return `<b style='color:white'>${hostname.slice(0, i)}</b>${escape(input)}`;
});

const isChecked = computed(() => !!status.value);
const inProgress = computed(() => status.value?.name === 'in-progress');
const isValid = computed(() => status.value?.name === 'succeeded');
const errorMessage = computed(() => {
  if (status.value?.name === 'failed') {
    return status.value.error;
  }
  return null;
});
</script>

<template>
  <li>
    <span v-html="host"></span
    ><template v-if="isChecked">
      &nbsp;
      <ImageMedia v-if="inProgress" :filename="loaderGraphic" class="icon invalid" />
      <span v-else-if="isValid"><ImageMedia :filename="GreenCheck" class="icon valid" /></span>
      <span v-else
        ><Tooltip>
          <ImageMedia :filename="RedX" class="icon invalid" />
          <template #content>{{ errorMessage }}</template></Tooltip
        ></span
      >
    </template>
  </li>
</template>

<style scoped>
.icon {
  display: inline;
  vertical-align: middle;
}

.icon :deep(img) {
  display: inline;
  margin-left: 10px;
}
.icon.invalid :deep(img) {
  width: 15px;
}
</style>

<script setup lang="ts">
import { Button } from '@clovyr/bed';
import AppLogo from '@clovyr/bed/components/elements/AppLogo.vue';
import CLink from '@clovyr/bed/components/elements/CLink.vue';
import type { Publisher } from '@clovyr/pollen/types/Publisher';
import type { PublisherApp } from '@clovyr/pollen/types/PublisherApp';

import { usePublisher } from '../../composables/usePublisher';

type AppCardProps = {
  pub: Publisher;
  pubApp: PublisherApp;
};
const props = defineProps<AppCardProps>();
const { pub, pubApp } = toRefs(props);

const publisherId = computed(() => pub.value.id);
const publisherAppId = computed(() => pubApp.value.id);

const { appLogoMetadata, appBgMetadata } = usePublisher(publisherId, publisherAppId);

const appLink = computed(() => {
  return `/publishers/${pub.value.slug}/apps/${pubApp.value.slug}`;
});

const appDetailLink = computed(() => {
  return `/apps/${pub.value.slug}/${pubApp.value.slug}`;
});

const appType = computed(() => {
  if (props.pubApp.app_type === 'clovyr_code') {
    return 'Code';
  }
  return 'Run';
});
</script>

<template>
  <c-link :to="appLink" class="grid__col-md-12">
    <div class="app-block" :style="appBgMetadata">
      <div class="app-block__inner">
        <div class="app-block__backdrop background-gradient--1" />
        <div v-if="appBgMetadata" class="app-block-background">
          <div class="app-block-background__image-overlay" />

          <div class="app-block-background__overlay" />
        </div>

        <div class="app-block-content">
          <AppLogo
            v-if="appLogoMetadata"
            :logo="appLogoMetadata"
            size="medium"
            class="app-logo-block"
          />
          <div class="app-block-content__inner">
            <div class="app-block-content__left">
              <div class="app-type">
                <div>{{ appType }} app</div>
                <!-- TODO: uncomment once it makes sense -->
                <div class="app-type__float-left" v-if="false">
                  |
                  <div class="app-type__float-right">
                    <router-link :to="appDetailLink">In Garden</router-link>
                  </div>
                </div>
              </div>
              <div class="app-title">{{ pubApp.name }}</div>
              <div v-if="false" class="instances">1,203 instances run</div>
            </div>
            <div class="app-block-content__right">
              <Button>
                <!-- TODO: change back to 'view dashboard' when we have stats etc -->
                <c-link :to="appLink" label="view details" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </c-link>
</template>

<style lang="scss" scoped>
.app-block {
  margin-top: 1.25rem;
  $self: &;

  position: relative;
  cursor: pointer;

  &__inner {
    position: relative;
    height: space(15);
    overflow: hidden;
    text-align: left;
    background-repeat: no-repeat;

    @include borders-rounded;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .app-block-background {
    position: relative;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--bg-url);

    @include background-image;

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, #221a33 0%, rgb(34 26 51 / 30%) 100%);
    }

    :deep(.image-media) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: color;
      opacity: 0.5;
      background: linear-gradient(
        180deg,
        rgb(var(--collection-color-theme)) 0%,
        rgba(var(--collection-color-theme), 0) 100%
      );
    }
  }

  .app-block-content {
    width: 100%;

    @include position-absolute-center;

    .app-logo-block {
      position: absolute;
      left: space(2.5);
      z-index: 2;

      @include drop-shadow(b);

      :deep(.image-media) {
        padding: 0.8rem;
      }
    }

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: space(13);
      padding-right: space(5);
      padding-top: space(0.5);

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
    }

    &__left {
      .app-type {
        display: flex;
        font-size: 13px;

        &__float-left {
          display: flex;
          margin-left: space(1);
        }
        &__float-right {
          color: color(action, primary);
          margin-left: space(1);
        }
      }
    }

    &__right {
      margin-top: 0.5rem;

      @include media-breakpoint-up(sm) {
        margin-top: 0.25rem;
      }
    }

    .app-title {
      @extend .text--subheading;
      letter-spacing: -0.05em;
    }

    .instances {
      color: color(grey, secondary);
    }
  }

  &:hover {
    #{$self}__backdrop {
      z-index: -1;
      background: color(background);
    }

    .app-block-background {
      z-index: 0;

      &__overlay {
        background: linear-gradient(180deg, rgb(69 57 96 / 30%) 0%, #453960 100%);
      }
    }

    .app-description {
      color: color(white);
    }
  }

  &__bottom {
    // TODO: selector not used??
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: space(2.4);
  }
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { Component } from 'vue';

import { Button } from '@clovyr/bed';
import { IconPosition } from '@clovyr/bed/components/controls/types';
import type { Manifest } from '@clovyr/pollen/manifest';
import { ComputeProviderName } from '@clovyr/pollen/types/Host';

import Modal, { type ModalSize } from '@/components/elements/Modal.vue';
import Spinner from '@/components/elements/Spinner.vue';
import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import ArrowForwardsIcon from '@/components/icons/ArrowForwardsIcon.vue';
import { useLauncherStore } from '@/stores/launcher_store';
import { LauncherState } from '@/stores/types';
import ChooseHost from '@/views/AppLauncher/ChooseHost.vue';
import HostCredentials from '@/views/AppLauncher/HostCredentials.vue';
import LaunchSettings from '@/views/AppLauncher/LaunchSettings.vue';
import ReviewSetup from '@/views/AppLauncher/ReviewSetup.vue';

const props = defineProps<{ app: Manifest; parentRoute: string }>();

const router = useRouter();
const launcherStore = useLauncherStore();
const {
  status,
  deployment,
  selectedHost,
  selectedComputeProviderConfig,
  isNextEnabled,
  isBackEnabled,
} = storeToRefs(launcherStore);
const { initialize, next, back } = launcherStore;

// initializer launcher state machine
initialize(props.app);

/**
 * Maps the current launcher state to the proper view & button labels
 */
type StateToComponent = {
  component: Component;
  percentComplete?: string;
  backLabel?: string;
  nextLabel?: string;
  btnLabel?: string;
};

let nonWnextHostChosen = false;

const nextLabelReview = computed(() => {
  if (
    selectedComputeProviderConfig.value !== undefined ||
    selectedHost.value?.id === ComputeProviderName.WNext
  ) {
    return true;
  }
  return false;
});

const stc = computed<StateToComponent>(() => {
  switch (status.value) {
    case LauncherState.Settings:
      return {
        component: LaunchSettings,
        percentComplete: nextLabelReview.value ? '50' : '25',
        nextLabel: nextLabelReview.value ? 'Review' : 'Choose Host',
        btnLabel: 'Cancel',
      };
    case LauncherState.ChooseHost:
      // eslint-disable-next-line no-return-assign
      return {
        component: ChooseHost,
        percentComplete: '50',
        backLabel: 'Setup',
        nextLabel:
          selectedHost.value?.id === ComputeProviderName.WNext ? 'Review' : 'Configure Host',
        btnLabel: 'Cancel',
        nonWnextHostChosen: (nonWnextHostChosen = false),
      };
    case LauncherState.HostCredentials:
      // eslint-disable-next-line no-return-assign
      return {
        component: HostCredentials,
        percentComplete: selectedHost.value?.id === ComputeProviderName.WNext ? '' : '60',
        backLabel: 'Choose Host',
        nextLabel: 'Review',
        btnLabel: 'Cancel',
        nonWnextHostChosen: (nonWnextHostChosen = true),
      };
    case LauncherState.Review:
      return {
        component: ReviewSetup,
        percentComplete: selectedHost.value?.id === ComputeProviderName.WNext ? '66' : '75',
        backLabel: nonWnextHostChosen ? 'Configure Host' : 'Choose Host',
        nextLabel: 'Launch',
        btnLabel: 'Cancel',
      };
    case LauncherState.Launching:
      // FIXME: this route should happen some other way
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      void router.push({ name: 'LibraryAppDetail', params: { id: deployment.value.id } });
      return {
        component: Spinner,
        btnLabel: 'Close',
      };

    default:
      return {
        component: LaunchSettings,
      };
  }
});

const isLaunching = computed(() => status.value === LauncherState.Launching);

const showLauncher = ref(false);
const emit = defineEmits(['close']);

const handleClose = () => {
  emit('close');
  void router.push(props.parentRoute);
};

const CSSClasses = computed(() => {
  return [
    LauncherState.ChooseHost && `progress-bar-fill__${stc.value.percentComplete}`,
    LauncherState.HostCredentials && `progress-bar-fill__${stc.value.percentComplete}`,
    LauncherState.Settings && `progress-bar-fill__${stc.value.percentComplete}`,
    LauncherState.Review && `progress-bar-fill__${stc.value.percentComplete}`,
  ];
});

// Tight theme except when showing only the spinner
const modalSize = computed<ModalSize>(() =>
  status.value === LauncherState.Launching ? '' : 'tight',
);

onMounted(async () => {
  return nextTick(() => {
    showLauncher.value = true;
  });
});
</script>

<template>
  <Modal v-if="showLauncher" :size="modalSize" :onCloseRouteTo="parentRoute" maxOnMobile stickyFooter>
    <template v-slot:progress-bar>
      <div v-if="!isLaunching" class="progress-bar">
        <span :class="CSSClasses" class="progress-bar-fill"></span>
      </div>
    </template>

    <template v-slot:body>
      <component :is="stc.component" />
    </template>

    <template v-if="!!modalSize" v-slot:controls-left>
      <Button :label="stc.btnLabel" @click="handleClose"></Button>
    </template>

    <template v-if="!!stc.nextLabel" v-slot:controls-right>
      <Button
        v-if="!!stc.backLabel"
        :disabled="!isBackEnabled"
        :label="stc.backLabel"
        :iconPosition="IconPosition.Left"
        :reversed="true"
        @click="back"
      >
        <ArrowBackIcon />
      </Button>
      <Button
        :disabled="!isNextEnabled"
        :label="stc.nextLabel"
        :iconPosition="IconPosition.Right"
        :reversed="true"
        @click="next"
      >
        <ArrowForwardsIcon />
      </Button>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.progress-bar {
  width: 100%;
  background-color: transparentize(color(grey, tertiary), 0.8);

  @include media-breakpoint-down(sm) {
    position: fixed;
    z-index: 2 !important;
  }

  .progress-bar-fill {
    display: block;
    height: 8px;
    background-color: #33e672;
    transition: width 500ms ease-in-out;

    &__0 {
      width: 0%;
    }

    &__25 {
      width: 25%;
    }

    &__50 {
      width: 50%;
    }

    &__75 {
      width: 75%;
    }

    &__33 {
      width: 33.33%;
    }

    &__66 {
      width: 66.66%;
    }
  }
}
</style>

<script setup lang="ts">
import mitt from 'mitt';

import { ButtonShape, ButtonSize } from '@clovyr/bed/components/controls/types';
import { DeploymentState } from '@clovyr/pollen';
import { BackupAPI, type Snapshot } from '@clovyr/pollen/backup';
import { getHostByID } from '@clovyr/pollen/fixtures/hosts';
import { type LaunchEvents } from '@clovyr/pollen/remote_exec';
import { shortDateTime } from '@clovyr/pollen/util/date';

import FormField from '@/components/blocks/FormField.vue';
import Modal from '@/components/elements/Modal.vue';
import Module from '@/components/elements/Module.vue';
import Spinner from '@/components/elements/Spinner.vue';
import ClovyrEditor from '@/components/forms/ClovyrEditor.vue';
import NextIcon from '@/components/icons/NextIcon.vue';
import { useAppResume } from '@/composables/useAppResume';
import { useAppStatus } from '@/composables/useAppStatus';
import { usePollenStore } from '@/stores/pollen_store';

interface LibraryAppDetail {
  id: string;
}

const props = defineProps<LibraryAppDetail>();

const deploymentID = toRef(props, 'id');

const { deployment, isClovyrCode, isRunning, setDeploymentState } = useAppStatus(deploymentID);

const showSnapshotsModal = ref(false);
const snapshots = ref<Snapshot[]>([]);
const recentSnapshots = computed(() => {
  if (!snapshots.value || snapshots.value.length === 0) {
    return [];
  }
  return snapshots.value.slice(snapshots.value.length - 10).reverse();
});

const showSpinner = ref(false);
const showNewSnapshotModal = ref(false);
const newSnapshotID = ref<string | undefined>();

const showInspectSnapshotModal = ref(false);
const inspectIDInput = ref<string | undefined>();
const inspectID = ref<string | undefined>();
const inspectOutput = ref<string>('');

const showBackupStats = ref(false);
const statsOutput = ref<string>('');

const pollenStore = usePollenStore();
const { destroyInstance } = pollenStore;

const destroying = ref(false);
const resuming = ref(false);
const resumeProgress = ref('');

function getAPI() {
  return new BackupAPI(
    deployment.value.fqdn!,
    deployment.value.accessList[0],
    deployment.value.appID,
  );
}

async function onClickShowSnapshots() {
  showSnapshotsModal.value = true;
  showSpinner.value = true;
  snapshots.value = await getAPI().listSnapshots();
  showSpinner.value = false;
}

async function onClickCreateSnapshot() {
  showNewSnapshotModal.value = true;
  showSpinner.value = true;
  newSnapshotID.value = await getAPI().createSnapshot(['manual', 'keep']);
  showSpinner.value = false;
}

async function inspectSnapshot(id: string) {
  showSnapshotsModal.value = false;
  showInspectSnapshotModal.value = true;
  showSpinner.value = true;
  inspectID.value = id;

  inspectOutput.value = await getAPI().inspectSnapshot(id);

  showSpinner.value = false;
}

async function onClickInspectSnapshot() {
  showInspectSnapshotModal.value = true;
  if (inspectIDInput.value) {
    inspectID.value = inspectIDInput.value.trim();
  }
  if (!inspectID.value) {
    return;
  }
  await inspectSnapshot(inspectID.value);
}

async function onClickShowStats() {
  showBackupStats.value = true;
  showSpinner.value = true;
  statsOutput.value = await getAPI().getStats();
  showSpinner.value = false;
}

async function onClickSuspend() {
  if (deployment.value.state !== DeploymentState.Running) {
    console.warn("Can't suspend a non-running instance");
    return;
  }

  destroying.value = true;
  showSpinner.value = true;

  // take a final backup first
  const snapshotID = await getAPI().createSnapshot(['keep']);
  deployment.value.backups ||= [];
  deployment.value.backups.push({
    snapshotID,
    createdAt: Date.now(),
    name: 'Snapshot before suspend/destroy',
    appVersion: deployment.value.appVersion!,
  });

  // now destroy it
  await destroyInstance(deployment.value);
  await setDeploymentState(DeploymentState.Sleeping);

  showSpinner.value = false;
  destroying.value = false;
}

async function onClickResume() {
  if (deployment.value.state !== DeploymentState.Sleeping) {
    console.warn("Can't resume a non-sleeping instance");
    return;
  }

  const emitter = mitt<LaunchEvents>();

  emitter.on('launch:start', async () => {
    const provider = getHostByID(deployment.value.hostingProvider);
    if (provider) {
      resumeProgress.value = `launching new instance on ${provider.title}...`;
    } else {
      resumeProgress.value = `launching new instance...`;
    }
  });

  emitter.on('launch:wait', async () => {
    resumeProgress.value = 'waiting for instance...';
  });

  emitter.on('launch:instance_up', async () => {
    resumeProgress.value = 'instance up';
  });

  emitter.on('launch:app_deploying', async () => {
    resumeProgress.value = 'deploying...';
  });

  emitter.on('launch:err', (err) => {
    resuming.value = false;
    resumeProgress.value = `Error: ${err}`;
  });

  resuming.value = true;
  await useAppResume(deploymentID).startResume(emitter);
  resuming.value = false;
}
</script>

<template>
  <Module hed="Internal Tools">
    <template #default>
      <div class="module__blurb__header">Instance:<br /></div>
      <div class="module__blurb__body">
        <div class="row">
          <Button
            label="Destroy/Suspend"
            @click="onClickSuspend"
            class="view-all-btn"
            :disabled="isClovyrCode || !isRunning"
            :class="{ destroying }"
          />
          <Button
            label="Create/Resume"
            @click="onClickResume"
            class="view-all-btn"
            :disabled="isClovyrCode || isRunning"
          />
        </div>
      </div>

      <div class="module__blurb__header">Backup/Restore:<br /></div>
      <div class="module__blurb__body">
        <div class="row">
          <Button
            label="List Snapshots"
            @click="onClickShowSnapshots"
            class="view-all-btn"
            :disabled="isClovyrCode || !isRunning"
          />
          <Button
            label="Create Snapshot"
            @click="onClickCreateSnapshot"
            class="view-all-btn"
            :disabled="isClovyrCode || !isRunning"
          />
          <Button
            label="Inspect Snapshot"
            @click="onClickInspectSnapshot"
            class="view-all-btn"
            :disabled="isClovyrCode || !isRunning"
          />
          <Button
            label="Repo Stats"
            @click="onClickShowStats"
            class="view-all-btn"
            :disabled="isClovyrCode || !isRunning"
          />
        </div>
      </div>
    </template>
  </Module>

  <Modal v-if="showSnapshotsModal" @close="showSnapshotsModal = false">
    <template #body>
      <div v-if="showSpinner">
        <h3 class="top">Loading...</h3>
        <Spinner />
      </div>
      <div v-else>
        <h3>{{ snapshots?.length }} snapshots</h3>
        <p>Showing the 10 most recent:</p>
        <div v-for="snapshot in recentSnapshots" :key="snapshot.id">
          <div>
            ID:
            <span :title="snapshot.id"
              >{{ snapshot.short_id }} ({{ shortDateTime(snapshot.time) }})</span
            >

            <Button
              label="Inspect"
              @click="inspectSnapshot(snapshot.id)"
              :size="ButtonSize.Small"
            />
          </div>
          <div></div>
          <hr />
        </div>
      </div>
    </template>
  </Modal>

  <Modal v-if="showNewSnapshotModal" @close="showNewSnapshotModal = false">
    <template #body>
      <div v-if="showSpinner">
        <h3 class="top">Creating new snapshot...</h3>
        <Spinner />
      </div>
      <div v-else>
        <h3>New snapshot: {{ newSnapshotID }}</h3>
      </div>
    </template>
  </Modal>

  <Modal v-if="showInspectSnapshotModal" @close="showInspectSnapshotModal = false">
    <template #body>
      <div v-if="showSpinner">
        <h3 class="top">Loading...</h3>
        <Spinner />
      </div>
      <div v-else-if="!inspectID">
        <h3>Enter a snapshot ID to inspect</h3>
        <div class="row">
          <div class="grid__col-md-11">
            <FormField v-model="inspectIDInput" />
          </div>
          <div class="grid__col-md-1">
            <Button :shape="ButtonShape.Circle" @click="onClickInspectSnapshot"
              ><NextIcon
            /></Button>
          </div>
        </div>
      </div>
      <div v-else>
        <h3>Inspecting snapshot {{ inspectID }}</h3>
        <ClovyrEditor readonly v-model="inspectOutput" />
      </div>
    </template>
  </Modal>

  <Modal v-if="showBackupStats" @close="showBackupStats = false">
    <template #body>
      <div v-if="showSpinner">
        <h3 class="top">Loading...</h3>
        <Spinner />
      </div>
      <div v-else>
        <h3>Restic repository stats</h3>
        <ClovyrEditor readonly v-model="statsOutput" />
      </div>
    </template>
  </Modal>

  <Modal v-if="destroying">
    <template #body>
      <h3 class="top">Destroying/Suspending...</h3>
      <Spinner v-if="showSpinner" />
    </template>
  </Modal>

  <Modal v-if="resuming">
    <template #body>
      <h3 class="top">Creating/Resuming...</h3>
      <h4 class="top" v-if="resumeProgress">{{ resumeProgress }}</h4>
      <Spinner v-if="showSpinner" />
    </template>
  </Modal>
</template>

<style scoped>
.top {
  z-index: 99999999;
  position: relative;
}
:deep(.module__blurb__body) {
  .row > * {
    margin-bottom: 1rem;
  }
}
:deep(.module__blurb__header) {
  margin-bottom: 1rem;
}
</style>

<script setup lang="ts">
import { IconPosition } from '@clovyr/bed/components/controls/types';
import type { Manifest } from '@clovyr/pollen/manifest';

import { useLaunchButton } from '@/composables/useLaunchButton';

const props = withDefaults(
  defineProps<{
    app: Manifest;
    label?: string;
    reversed?: boolean;
  }>(),
  {
    label: 'launch',
    reversed: false,
  },
);

const { handleLaunchClicked } = useLaunchButton(props.app);
</script>

<template>
  <div class="launch-btn">
    <Button
      :label="label"
      :iconPosition="IconPosition.Right"
      :reversed="reversed"
      @click="handleLaunchClicked"
    >
      <SproutIcon />
    </Button>
  </div>
</template>
<style scoped>
.launch-btn {
  :deep(.sprout-icon) {
    min-width: 20px;
    min-height: 20px;
  }
  :deep(.button__label) {
    margin-top: 0.2rem;
  }
}
</style>

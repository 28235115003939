/* Do not change, this code is generated from Golang structs */


export class UserSubscription {
    id: string;
    subscription_provider: string;
    created_at: Date;
    updated_at?: Date;
    canceled_at?: Date;
    payment_captured_at?: Date;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.subscription_provider = source["subscription_provider"];
        this.created_at = source["created_at"] ? new Date(source["created_at"]) : source["created_at"];
        this.updated_at = source["updated_at"] ? new Date(source["updated_at"]) : source["updated_at"];
        this.canceled_at = source["canceled_at"] ? new Date(source["canceled_at"]) : source["canceled_at"];
        this.payment_captured_at = source["payment_captured_at"] ? new Date(source["payment_captured_at"]) : source["payment_captured_at"];
    }
}
<script setup lang="ts">
import { Button } from '@clovyr/bed';
import CLink from '@clovyr/bed/components/elements/CLink.vue';
import type { Publisher } from '@clovyr/pollen/types/Publisher';

const props = defineProps<{ pub: Publisher }>();

const pubLink = computed(() => {
  if (props.pub.payment_captured === false) {
    return `/publishers/${props.pub.id}/finish`;
  }
  return `/publishers/${props.pub.slug}`;
});
</script>

<template>
  <c-link :to="pubLink" class="grid__col-md-12">
    <div class="app-block">
      <div class="app-block__inner">
        <div class="app-block__backdrop background-gradient--1" />

        <div class="app-block-content">
          <!-- TODO: pub logo? -->
          <!-- <AppLogo v-if="metadata" class="app-logo-block" :logo="metadata" /> -->
          <div class="app-block-content__inner">
            <div class="app-block-content__left">
              <div class="app-title">{{ pub.name }}</div>
            </div>
            <div class="app-block-content__right">
              <Button>
                <c-link :to="pubLink" label="view" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </c-link>
</template>

<style lang="scss" scoped>
.app-block {
  margin-top: space(2);

  $self: &;

  position: relative;
  cursor: pointer;

  &__inner {
    position: relative;
    height: space(15);
    overflow: hidden;
    text-align: left;
    background-repeat: no-repeat;

    @include borders-rounded;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .app-block-content {
    width: 100%;

    @include position-absolute-center;

    // .app-logo-block {
    //   position: absolute;
    //   left: space(2.5);
    //   z-index: 2;

    //   @include drop-shadow(b);
    // }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: space(5);
      padding-right: space(5);
      padding-top: space(0.5);
    }

    &__left {
      .app-type {
        display: flex;
        font-size: 13px;

        &__float-left {
          display: flex;
          margin-left: space(1);
        }
        &__float-right {
          color: color(action, primary);
          margin-left: space(1);
        }
      }
    }

    &__right {
      margin-top: space(1);
    }

    .app-title {
      @extend .text--subheading;
      letter-spacing: -0.05em;
    }

    .instances {
      color: color(grey, secondary);
    }
  }

  &:hover {
    #{$self}__backdrop {
      z-index: -1;
      background: color(background);
    }

    .app-block-background {
      z-index: 0;

      &__overlay {
        background: linear-gradient(180deg, rgb(69 57 96 / 30%) 0%, #453960 100%);
      }
    }

    .app-description {
      color: color(white);
    }
  }

  &__bottom {
    // TODO: selector not used??
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: space(2.4);
  }
}
</style>

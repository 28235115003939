<script setup lang="ts">
import Sidebar from '@/global/Sidebar.vue';

import '../styles/main.scss';

onMounted(() => {
  const app = document.getElementById('app');
  if (app) {
    app.classList.add('app-maxed');
  }
});
onUnmounted(() => {
  const app = document.getElementById('app');
  if (app) {
    app.classList.remove('app-maxed');
  }
});
</script>

<template>
  <Sidebar />
  <main class="main">
    <RouterView />
  </main>
  <!-- <Feedback v-if="!isMobile" /> -->
</template>

<style lang="scss">
#app.app-maxed,
#app.app-maxed > div {
  width: 100%;
  height: 100%;
}
main {
  height: 100%;
}
</style>

<script setup lang="ts">
import { Button } from '@clovyr/bed';
import { ButtonShape } from '@clovyr/bed/components/controls/types';
import { deleteMessage, fetchMail } from '@clovyr/pollen/mail';
import type { Email } from '@clovyr/pollen/mail/types';

import ArrowBackIcon from '@/components/icons/ArrowBackIcon.vue';
import { usePollenStore } from '@/stores/pollen_store';

import CloseIcon from '../../components/icons/CloseIcon.vue';
import RefreshIcon from '../../components/icons/RefreshIcon.vue';

import AppMailMessageItem from './AppMailMessageItem.vue';

interface LibraryAppDetail {
  id: string;
}

const props = defineProps<LibraryAppDetail>();
const pollenStore = usePollenStore();

// assume that by the time we reach here, the deployment ID has been validated by the route guard
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const deployment = pollenStore.getDeploymentByID(props.id)!;
const instance = pollenStore.getInstanceByFQDN(deployment.fqdn!)!;

const emails = ref<Email[]>([]);

async function refreshMail() {
  emails.value = await fetchMail(`https://${deployment.fqdn}`, deployment.accessList[0]);
}
refreshMail(); // once on load

const openedMsg = ref<Email>();

const onClickRefresh = () => {
  refreshMail();
};

const onClickViewMsg = (msg) => {
  msg.read = true;
  openedMsg.value = msg;
};

const onClickDeleteMsg = (msg) => {
  deleteMessage(`https://${deployment.fqdn}`, instance.configurekey!, msg.id);
  emails.value = emails.value.filter((m) => m.id !== msg.id);
};

const onClickCloseMsg = () => {
  openedMsg.value = undefined;
};
</script>

<template>
  <div class="app-instance">
    <div class="app-instance__top">
      <div class="app-background"></div>

      <div class="grid">
        <div class="grid__col">
          <router-link :to="'/library/' + deployment.id" class="app-instance__back text text--mono">
            <ArrowBackIcon />
            Back to App
          </router-link>
        </div>
      </div>

      <div class="app-instance__hero">
        <div class="app-instance__hero__grid grid">
          <div class="app-instance__hero__title grid__col">
            <div class="app-header__heading-container">
              <h2 class="app-header__heading">Mailbox</h2>
            </div>
            <div class="app-header__preheading text text--color-grey-secondary">
              View email messages sent by {{ deployment.appName }}
            </div>
          </div>

          <div class="app-instance__hero__buttons grid__col">
            <Button v-if="!openedMsg" @click="onClickRefresh" :shape="ButtonShape.Circle" reversed>
              <RefreshIcon />
            </Button>
            <Button v-else @click="onClickCloseMsg" :shape="ButtonShape.Circle">
              <CloseIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!!openedMsg" class="grid">
      <div class="grid__col">
        <div class="msg">
          <div class="msg__body">
            <div class="msg-heading">
              <span>From:</span><span>{{ openedMsg.from }}</span>
            </div>
            <div class="msg-heading">
              <span>To:</span><span>{{ openedMsg.to }}</span>
            </div>
            <div class="msg-heading">
              <span>Subject:</span><span>{{ openedMsg.subject }}</span>
            </div>
            <div class="msg-heading">{{ openedMsg.date }}</div>
          </div>
        </div>
        <div class="msg">
          <div v-if="openedMsg.contentType == 'text/html'" class="msg__body">
            <iframe :srcdoc="openedMsg.body" id="frame"></iframe>
          </div>
          <div v-else>
            <pre>
              {{ openedMsg.body }}
            </pre>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="grid">
      <div class="grid__col">
        <div v-if="emails.length">
          <AppMailMessageItem
            v-for="msg in emails"
            :key="msg.id"
            :msg="msg"
            @view="() => onClickViewMsg(msg)"
            @delete="() => onClickDeleteMsg(msg)"
          />
        </div>
        <AppMailMessageItem v-else />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.msg {
  --msg-item-pad-vert: #{space(2)};
  --msg-item-pad-horiz: #{space(3)};
  --msg-item-margin-bottom: #{space(2)};
  --msg-item-border-radius: #{space(2)};

  iframe {
    width: 100%;
    height: 800px; // TODO: set dynamically?
  }

  @include margin(bottom, var(--msg-item-margin-bottom));
  @include margin(left right, calc(var(--msg-item-pad-horiz) * -1));

  background: linear-gradient(180deg, rgb(65 59 79 / 50%) 0%, #413b4f 91.78%);
  border-radius: var(--msg-item-border-radius);

  @supports (backdrop-filter: blur()) {
    backdrop-filter: blur(rem(18));
    /* stylelint-disable scss/no-global-function-names */
    background: linear-gradient(
      180deg,
      rgb(65 59 79 / 40%) 0%,
      transparentize(#413b4f, 0.4) 91.78%
    );
    /* stylelint-enable scss/no-global-function-names */
  }

  @include media-query(phone) {
    @include margin(left right, 0);
  }

  &__body {
    padding: var(--msg-item-pad-vert) var(--msg-item-pad-horiz);
    border-radius: 0 0 var(--msg-item-border-radius) var(--msg-item-border-radius);

    iframe {
      padding: 10px 0;
    }
  }

  &__ctas {
    @include flex($justify: flex-end);

    padding: 0 var(--module-pad-horiz) var(--module-pad-vert);

    .button {
      @include media-query(full-sm) {
        @include font-size(14, mono);
      }

      @include media-query(tablet) {
        @include font-size(12, mono);
      }
    }
  }

  .msg-heading {
    color: lightgray;
    padding: 1.5px;

    span:first-child {
      font-weight: bold;
      color: #fff;
      padding-right: space(0.75);
    }
  }

  .msg-heading:last-child {
    font-weight: bolder;
  }
}
</style>

<script setup lang="ts">
import tinymd from 'tinymd';

const props = defineProps<{
  content: string;
}>();

const html = computed(() => {
  if (!props.content) {
    return '';
  }
  return tinymd(props.content);
});
</script>

<template>
  <div class="markdown" v-html="html"></div>
</template>

<style lang="scss" scoped>
.markdown {
  :deep(p:not(:first-child)),
  :deep(div:not(:first-child)),
  :deep(hr:not(:first-child)),
  :deep(h1:not(:first-child)),
  :deep(h2:not(:first-child)),
  :deep(h3:not(:first-child)),
  :deep(h4:not(:first-child)),
  :deep(h5:not(:first-child)),
  :deep(h6:not(:first-child)),
  :deep(code:not(:first-child)),
  :deep(pre:not(:first-child)) {
    margin-top: 16px;
  }

  :deep(ul) {
    list-style-type: disc;
    padding-left: 16px;
  }

  :deep(code),
  :deep(pre) {
    font-family: 'Courier New', Courier, monospace;
  }
  :deep(a) {
    text-decoration: underline;
    color: color(grey, secondary);
  }
}
</style>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import FoxSolo from '@clovyr/bed/assets/images/fox-solo.png';
import { ComputeProviderName } from '@clovyr/pollen';
import { firstPartyHosts, thirdPartyHosts } from '@clovyr/pollen/fixtures/hosts';

import RadioBlock from '@/components/blocks/RadioBlock.vue';
import AppLauncherHero from '@/components/tiers/AppLauncherHero.vue';
import { useLauncherStore } from '@/stores/launcher_store';

const launcherStore = useLauncherStore();
const { selectedHost, metadata, wnextAppPrice } = storeToRefs(launcherStore);
const { setSelectedHost, getProviderInstanceType } = launcherStore;
</script>

<template>
  <div class="choose-host">
    <div class="choose-host__main">
      <AppLauncherHero
        :app-logo="metadata"
        :app-title="metadata.name"
        :right-image-src="FoxSolo"
        heading-text="Choose your host"
      />
      <div class="host-controls">
        <div class="host-list row">
          <RadioBlock
            v-for="host in firstPartyHosts"
            :key="host.id"
            class="hosting-block grid__col-lg-3"
            name="hosting-provider"
            :fieldValue="host.id"
            :logos="[host]"
            logoSize="medium"
            flavor="simple"
            :selected="selectedHost?.id === host.id"
            @change="() => setSelectedHost(host)"
          >
            <template v-slot:body-heading>
              <div class="free-trial">
                <p>1-week free trial!</p>
              </div>
              <span v-if="host.title" class="block-title">{{ host.title }}</span>
              <span v-if="host.subtitle" class="block-subtitle">{{ host.subtitle }}</span>
            </template>
            <template v-slot:body-left>
              <div class="body-group">
                <div v-if="host.description">
                  <div>
                    {{ host.description?.replaceAll('PRICE', wnextAppPrice[1].toString()) }}
                  </div>
                </div>
                <div class="hosts-price green">${{ wnextAppPrice[1].toString() }}/mo</div>
              </div>
            </template>
          </RadioBlock>
          <RadioBlock
            v-for="host in thirdPartyHosts"
            :key="host.id"
            class="hosting-block grid__col-lg-3"
            name="hosting-provider"
            :fieldValue="host.id"
            flavor="simple"
            :logos="[host]"
            logoSize="medium"
            :logoPad="host.id !== ComputeProviderName.DigitalOcean"
            :selected="selectedHost?.id === host.id"
            @change="() => setSelectedHost(host)"
          >
            <template v-slot:body-heading>{{ host.title }}</template>
            <template v-slot:body-left>
              <div class="body-group">
                <div>{{ host?.description }}</div>
                <div class="hosts-price">${{ getProviderInstanceType(host)[1] }}/mo*</div>
              </div>
            </template>
          </RadioBlock>
        </div>
        <div class="subtext">
          <p>
            * Prices are an approximation. Payment is made directly to the host provider and not
            Clovyr.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.choose-host {
  .host-controls {
    flex-wrap: wrap;

    .subtext {
      display: flex;
      justify-content: center;
      padding: space(1.5) 0;
      font-size: 12px;
      color: #ada6b7;
    }
  }

  :deep(.hosting-block) {
    .free-trial {
      position: absolute;
      background-color: #383e52;
      color: #33e672;
      border-radius: 8px;
      z-index: 1;
      padding: space(1) space(3);
      top: -18.4px;
      left: 50%;
      transform: translateX(-50%);
      white-space: pre;
      font-size: 14px;
    }
  }

  .block-subtitle {
    margin-bottom: 5px;
  }

  .body-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .hosts-price {
    padding-top: space(2);
    margin-top: auto;
    color: #ada6b7;
  }

  .green {
    color: #33e672;
  }

  .host-list {
    --cl-gutter-x: 1rem;

    display: flex;
    flex-wrap: wrap;
    align-items: normal;
    margin-top: var(--cl-gutter-x);

    :deep(.radio-block) {
      margin-top: var(--cl-gutter-x);

      &:first-child {
        margin-top: 0;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }

      .radio-block__inner {
        position: relative;
        display: flex;
        height: 100%;

        @include media-breakpoint-up(md) {
          height: 100%;
        }
      }
    }

    :deep(.app-icon) {
      width: 5rem;
      height: 5rem;
    }

    > :deep(.grid__col) {
      @include media-breakpoint-up(md) {
        flex: 1;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
    }
  }
}
</style>

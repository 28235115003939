import type { APIResponse } from './APIResponse';

export class APIError extends Error {
  status: 'success' | 'fail' | 'error' = 'error';

  code: number = 0;

  data?: any;

  constructor(message: string | APIResponse, code?: number) {
    if (typeof message === 'object') {
      super(message.message);
      this.status = message.status;
      this.data = message.data;
      if (message.code) {
        this.code = message.code;
      }
      return;
    }
    super(message);
    if (code) {
      this.code = code;
    }
  }
}

<script setup lang="ts">
import { getWNextPricing } from '@clovyr/pollen/fixtures/pricing';
import type { Manifest } from '@clovyr/pollen/manifest';
import { ImageMedia } from '../media';
import { GridItem, GridSection } from '.';
import Background from '../elements/Background.vue';
import LinodeLogo from '../../assets/images/linode-logo.svg';
import DropletsLogo from '../../assets/images/droplets-logo.png';
import ClovyrHostingFullLogo from '../../assets/images/clovyrhost-lockup.svg';
import AWSLogo from '../../assets/images/aws-logo.svg';
import RedXicon from '../icons/RedXicon.vue';
import GreenCheckIcon from '../icons/GreenCheckIcon.vue';
import AppLogo from '../elements/AppLogo.vue';

const props = defineProps<{
  app?: Manifest;
  image: string;
  overlayImage?: string;
  isMarketingWebsite?: boolean;
}>();

const emit = defineEmits(['launch']);
const router = useRouter();

const wnextAppPrice = computed<string>(() => {
  if (!props.app) {
    return '';
  }
  return getWNextPricing(props.app.metadata.id, props.app.deployment?.compute?.size)[1].toString();
});

const isAppDetail = computed(() => !!props.app);

const CSSClasses = computed(() => {
  return [
    isAppDetail.value && 'app-detail__background',
    !isAppDetail.value && 'collection__background',
  ];
});
const CSSPadding = computed(() => {
  return [!isAppDetail.value && 'extra-padding-1', isAppDetail.value && 'extra-padding-2'];
});
</script>

<template>
  <div class="pricing">
    <Background
      :image="image"
      :overlay-image="overlayImage"
      class="pricing__background"
      :class="CSSClasses"
    />
    <h2>Pricing</h2>
    <div class="container container-common">
      <div class="row">
        <GridSection :spacing="6">
          <GridItem :md="6">
            <div class="free-trial green"><p>1-week free trial!</p></div>
            <div class="item">
              <h3>Host With Us</h3>
              <div class="host-options" :class="CSSPadding">
                <div class="host-1">
                  <ImageMedia :filename="ClovyrHostingFullLogo" />
                </div>
              </div>
              <div class="host-pricing">
                <div class="wnext-price" v-if="isAppDetail && wnextAppPrice">
                  <h1 class="green">${{ wnextAppPrice }}</h1>
                  <p>/mo</p>
                </div>
                <div class="alt-price" v-else>
                  <p>Starting at</p>
                  <div class="wnext-price">
                    <h1 class="green">$9</h1>
                    <p>/mo</p>
                  </div>
                </div>
              </div>
              <ul class="bullets">
                <li>
                  Flat monthly pricing per app
                  <GreenCheckIcon />
                </li>
                <li>No configuration know-how needed<GreenCheckIcon /></li>
                <li>Access and manage apps privately from any browser<GreenCheckIcon /></li>
                <li>Try apps hassle-free, keep the ones you love!<GreenCheckIcon /></li>
                <li>Apps run on a private VPS<GreenCheckIcon /></li>
                <li>
                  <div class="feature-label">
                    Automated, end-to-end encrypted backup and recovery service included
                  </div>
                  <GreenCheckIcon />
                </li>
                <li>Clovyr technical support<GreenCheckIcon /></li>
              </ul>
            </div>
          </GridItem>
          <GridItem :md="6">
            <div class="item">
              <h3>Bring Your Own Host</h3>
              <div class="host-options" :class="CSSPadding">
                <div class="host">
                  <AppLogo
                    size="small"
                    :logo="{ logoUrl: AWSLogo, logoBackgroundColor: '#ffffff' }"
                  />
                </div>
                <div class="host">
                  <AppLogo
                    size="small"
                    :logo="{ logoUrl: DropletsLogo, logoBackgroundColor: '#ffffff' }"
                  />
                </div>
                <div class="host">
                  <AppLogo
                    size="small"
                    :logo="{ logoUrl: LinodeLogo, logoBackgroundColor: '#ffffff' }"
                  />
                </div>
              </div>
              <div class="host-pricing">
                <div class="wnext-price">
                  <h1 class="green">$0</h1>
                </div>
              </div>
              <ul class="bullets byoh-bullets">
                <li>Free<GreenCheckIcon /></li>
                <li>No configuration know-how needed<GreenCheckIcon /></li>
                <li>Access and manage apps privately from any browser<GreenCheckIcon /></li>
                <li>Try apps hassle-free, keep the ones you love!<GreenCheckIcon /></li>
                <li>Apps run in the cloud account you already have<GreenCheckIcon /></li>
                <li>
                  <div>
                    Automated, end-to-end encrypted backup and recovery service not included
                  </div>
                  <RedXicon />
                </li>
                <li>Clovyr technical support not included<RedXicon /></li>
                <li>No Clovyr account required<GreenCheckIcon /></li>
              </ul>
              <div class="backup">
                <p class="green">Add Clovyr secure backup and recovery service for $5/app</p>
              </div>
            </div>
          </GridItem>
          <div class="get-started" v-if="isAppDetail">
            <div class="button button--pill button--reversed cta-btn" @click="emit('launch')">
              Get Started
            </div>
          </div>
          <div class="get-started" v-if="isMarketingWebsite">
            <a href="https://clovyr.app/" class="button button--pill button--reversed cta-btn">
              Browse the Garden
            </a>
          </div>
        </GridSection>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.pricing {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: space(7) 0 space(30) 0;

  .app-detail__background {
    :deep(.background__image) {
      background-size: contain;
      background-position: center top;
    }
  }

  .collection__background {
    :deep(.background__image) {
      background-size: contain;
      background-position: center bottom;
    }

    :deep(.background__overlay-image) {
      background-size: contain;
      background-position: center bottom;
      bottom: -1px;
    }
  }

  h2 {
    padding: space(10) 0;
  }

  .free-trial {
    position: absolute;
    background-color: #383e52;
    border-radius: 8px;
    z-index: 1;
    padding: space(1) space(3);
    top: -3%;
    left: 50%;
    transform: translateX(-50%);
    white-space: pre;
  }

  :deep(.grid-section) {
    [class*='grid__col'] {
      margin-top: space(2);

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  :deep([class*='grid__col']) {
    .grid-item {
      background: linear-gradient(180deg, rgb(48 39 68 / 50%) 0%, #302744 100%);
      backdrop-filter: blur(10px);
      border-radius: 18px;
      padding: space(3) space(3) space(3) space(3);
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: space(3) 0 0 0;

    .extra-padding-1 {
      padding-bottom: space(4);
    }

    .extra-padding-2 {
      padding-bottom: space(1);
    }

    .sub-heading {
      font-size: 13px;
    }

    .host-options {
      @extend .item;

      flex-direction: row;
      position: relative;

      .host-1 {
        :deep(.app-icon) {
          .image-media {
            padding: space(1);
          }
        }
      }

      .host {
        display: flex;
        flex-direction: column;
        align-items: center;

        // loop through 5 hosts, increase if more are added

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            z-index: ($i * -1) + 10;
          }
        }

        &:not(:first-child) {
          margin-left: -0.75rem;
        }

        .price {
          padding: 0;
        }

        p {
          font-size: 12px;
          padding: space(1) 0 0 0;
        }

        :deep(.app-icon) {
          .image-media {
            padding: space(1);

            // border: 1px solid #3027441c;
            box-shadow: 0 0 4px 0 #000;
          }
        }
      }
    }

    .host-pricing {
      display: flex;
      flex-direction: column;
      align-items: center;

      .wnext-price {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        h1 {
          padding: 0 space(0.5);
        }
      }

      .alt-price {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .bullets {
    padding: space(3) 0 0 0;
    max-width: 95%;

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #e0dde4;
      padding: space(1.5) 0;

      &:not(:last-child) {
        border-bottom: 1px solid #453960;
      }

      div {
        max-width: 80%;
      }

      svg {
        margin-left: 20px;
      }

      :deep(.red-x-icon) {
        height: 20px;
        width: 20px;
      }

      :deep(.green-check-icon) {
        height: 25px;
        width: 25px;
      }
    }
  }

  .byoh-bullets {
    margin-top: 14px;
  }

  .backup {
    background-color: #33e6720d;
    border-radius: 8px;
    padding: space(1.5);
    margin: space(1) 0 0 0;
  }

  .get-started {
    display: flex;
    justify-content: center;

    .cta-btn {
      margin: space(3) 0 0 0;
      padding: space(1) 10%;
      white-space: pre;
      cursor: pointer;
    }
  }
}

.green {
  color: #33e672;
}

.gray {
  color: #ada6b7;
}
</style>

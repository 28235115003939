import { defineStore } from 'pinia';

import { persist, rehydrate } from './util';

type UIState = {
  sidenavIsCollapsed: boolean;
};

export const useUIStateStore = defineStore('ui_state', () => {
  const sidenavIsCollapsed = ref(false);

  (() => {
    const s: UIState | null = rehydrate('clovyr/ui/state');
    if (s) {
      sidenavIsCollapsed.value = s.sidenavIsCollapsed;
    }
    watch([sidenavIsCollapsed], () => {
      persist('clovyr/ui/state', {
        sidenavIsCollapsed: sidenavIsCollapsed.value,
      });
    });
  })();

  return {
    sidenavIsCollapsed,
  };
});

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import FoxAndClover from '@clovyr/bed/assets/images/fox-and-clover.png';
import { GridItem, GridSection } from '@clovyr/bed/components/blocks';
import Markdown from '@clovyr/bed/components/blocks/Markdown.vue';
import { ComputeProviderName } from '@clovyr/pollen';

import AppConfiguration from '@/components/forms/AppConfiguration.vue';
import HostSelector from '@/components/forms/HostSelector.vue';
import AppLauncherHero from '@/components/tiers/AppLauncherHero.vue';
import { useLauncherStore } from '@/stores/launcher_store';

import AddonsBackup from './AddonsBackup.vue';
import BYODomain from './BYODomain.vue';

const launcherStore = useLauncherStore();
const { setAnswer } = launcherStore;
const { basicSettings, advancedSettings, metadata, selectedHost } = storeToRefs(launcherStore);

const settingsAvailable = computed(
  () => basicSettings.value.length > 0 || advancedSettings.value.length > 0,
);
</script>

<template>
  <div class="review-setup">
    <div class="review-setup__main">
      <AppLauncherHero
        :app-logo="metadata"
        :app-title="metadata.name"
        :right-image-src="FoxAndClover"
        heading-text="Final Review"
      />

      <GridSection class="review-setup-settings" :spacing="2">
        <GridItem :md="5">
          <div class="setup-controls__right">
            <div class="setup-controls-heading launch-heading--secondary">Hosting On:</div>

            <div class="section-highlighted background-gradient--base">
              <HostSelector />
            </div>
          </div>

          <AddonsBackup v-if="selectedHost?.id !== ComputeProviderName.WNext" :isReview="true" />
        </GridItem>
        <GridItem :md="7">
          <div v-if="settingsAvailable" class="setup-controls__left">
            <div class="setup-controls-heading launch-heading--secondary">App Configuration:</div>

            <div class="section-highlighted background-gradient--base">
              <AppConfiguration
                :basic-settings="basicSettings"
                :advanced-settings="advancedSettings"
                untoggled-button-text="edit settings"
                toggled-button-text="cancel"
                save-button
                :reviewConfigurations="true"
                :setAnswer="setAnswer"
                class="options-block"
              />
            </div>
          </div>
          <div v-else class="section-highlighted background-gradient--base alt-appconfig">
            <markdown :content="metadata.description" />
          </div>
          <BYODomain class="domain" />
        </GridItem>
      </GridSection>
    </div>
  </div>
</template>

<style scoped lang="scss">
.setup-controls-heading {
  @extend .text--h4;

  margin-bottom: space(2.5);
  color: color(white);
}

.alt-appconfig {
  margin-top: space(5.5);
}

.domain {
  margin-top: space(4);
}
</style>

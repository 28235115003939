<script setup lang="ts">
import { ButtonSize } from '@clovyr/bed/components/controls/types';

import { useAppStatus } from '@/composables/useAppStatus';
import DeleteApp from '@/views/Library/DeleteApp.vue';

const props = defineProps<{
  deploymentID: string;
}>();

const deploymentID = toRef(props, 'deploymentID');
const { deployment, app } = useAppStatus(deploymentID);
const showDeleteModal = ref(false);

const appLink = computed(() => `/view/${deployment.value.instanceName}`);
</script>

<template>
  <div class="instance">
    <Button
      :label="'Open ' + deployment.instanceName"
      translucent
      :size="ButtonSize.Small"
      :link="appLink"
    ></Button>
    <Button
      label="Destroy"
      translucent
      :size="ButtonSize.Small"
      class="destroy"
      @click="showDeleteModal = true"
    ></Button>
  </div>

  <DeleteApp
    v-if="showDeleteModal"
    :app="app"
    :deployment="deployment"
    close
    @close="showDeleteModal = false"
  />
</template>

<style scoped lang="scss">
.instance {
  display: flex;
  flex-direction: row;
}

.destroy {
  margin-left: 0.5rem;

  :deep(.button__label) {
    color: red;
  }
}
</style>

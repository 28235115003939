<script setup lang="ts">
import Prism from '@clovyr/bed/components/blocks/Prism.vue';
import Copy from '@clovyr/bed/components/elements/Copy.vue';
import CopyIcon from '@clovyr/bed/components/icons/CopyIcon.vue';

import { useHostname } from '../../composables/useHostname';
import { usePublisher } from '../../composables/usePublisher';

const embedJS = import.meta.env.VITE_BUTTON_JS || 'https://cstatic.app/clovyr.js';
const cdnPath = 'https://cstatic.app/i';

type AppProps = {
  publisherID: string;
  appID: string;
};
const props = defineProps<AppProps>();
const { host } = useHostname();

const publisherID = computed(() => props.publisherID);
const appID = computed(() => props.appID);
const { publisher, pubApp: app } = usePublisher(publisherID, appID);

const scriptPreview = ref<HTMLInputElement | null>(null);
const activeTab = ref<'html' | 'script'>('script');

const appSlug = computed(() => `${publisher.value!.slug}/${app.value?.slug}`);

const scriptTag = computed(() => {
  let dataUrl = '';
  if (host.value !== 'clovyr.app') {
    // only in non-prod envs, so script tag works
    dataUrl = ` data-url="https://${host.value}"`;
  }

  if (app.value?.app_type === 'clovyr_code') {
    // develop button
    // eslint-disable-next-line no-useless-concat
    return `<script src="${embedJS}" data-app="${appSlug.value}"${dataUrl}>` + '<' + '/script>';
  }

  // run button
  // eslint-disable-next-line no-useless-concat
  return `<script src="${embedJS}" data-app="${appSlug.value}"${dataUrl}>` + '<' + '/script>';
});

const scriptBlock = computed(() => {
  return `<!-- This tag should be used in most situations where a script tag is allowed, e.g., on your application's website or documentation page. -->

${scriptTag.value}`;
});

const imageTag = computed(() => {
  if (!app.value) {
    return '';
  }

  if (app.value.app_type === 'clovyr_code') {
    // develop button
    return `<a href="https://${host.value}/develop/${appSlug.value}">
    <img width="133px" src="${cdnPath}/develop.svg" />
</a>`;
  }

  // run button
  return `<a href="https://${host.value}/run/${appSlug.value}">
  <img width="133px" src="${cdnPath}/run.svg" />
</a>`;
});

const imageTagBlock = computed(() => {
  return `<!-- This tag should be used in situations where a script tag
     is NOT allowed, e.g., in a README.md file on GitHub. -->

${imageTag.value}`;
});

const textToCopy = computed(() => (activeTab.value === 'html' ? imageTag.value : scriptTag.value));

function renderScriptPreview() {
  if (!import.meta.env.SSR && scriptPreview.value && !!scriptTag.value && app.value) {
    if (scriptPreview.value.getElementsByClassName('clovyr-embed').length > 0) {
      return;
    }
    const embed = document.createElement('script');
    embed.className = 'clovyr-embed';
    embed.src = embedJS;
    embed.setAttribute('data-app', appSlug.value || '');
    if (host.value !== 'clovyr.app') {
      embed.setAttribute('data-url', `https://${host.value}`);
    }
    scriptPreview.value.appendChild(embed);
  }
}

const unwatchPreview = watch([scriptPreview, app, activeTab], renderScriptPreview);

onMounted(async () => {
  renderScriptPreview();
});

onBeforeRouteLeave(() => {
  // avoid re-rendering while navigating away.
  // sometimes shows the button twice when this happens.
  unwatchPreview();
});
</script>

<template>
  <div class="container">
    <div class="app grid__col-sm-11">
      <div class="header">
        <h2>Share & Embed</h2>
        <p>
          Clovyr offers the following options for integrating the button into your website or
          application:
        </p>
      </div>
      <div class="script-preview grid__col-sm-12">
        <div class="script-preview__script grid__col-sm-12 grid__col-xl-7">
          <div class="tab-group">
            <Button
              label="script tag"
              class="tab-btn"
              @click="activeTab = 'script'"
              translucent
              reversed
              :disabled="activeTab === 'script'"
            />
            <Button
              label="image tag"
              class="tab-btn"
              @click="activeTab = 'html'"
              translucent
              reversed
              :disabled="activeTab === 'html'"
            />
            <Copy class="copy-image" :text="textToCopy" :icon="CopyIcon" />
          </div>
          <div v-if="activeTab === 'script'" class="section">
            <div class="prism">
              <Prism language="html" class="code-block script" :code="scriptBlock" />
            </div>
          </div>
          <div v-if="activeTab === 'html'" class="section">
            <div class="prism">
              <Prism language="html" class="code-block html" :code="imageTagBlock" />
            </div>
          </div>
        </div>
        <div class="script-preview__preview grid__col-xl-5">
          <div class="text">Preview:</div>
          <div v-if="activeTab === 'html'" class="clovyr-button" v-html="imageTag" />
          <div v-if="activeTab === 'script'" class="clovyr-button">
            <div ref="scriptPreview"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  display: flex;
  justify-content: center;

  .app {
    @include borders-rounded;

    background-color: #41355b;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up(xl) {
      height: space(60);
    }

    .script-preview {
      display: flex;
      flex-direction: column;
      gap: 4rem;
      margin-bottom: 4rem;

      @include media-breakpoint-up(xl) {
        flex-direction: row;
        gap: unset;
      }

      &__preview {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        margin-left: 0.5rem;

        @include media-breakpoint-up(xl) {
          justify-content: center;
          margin-top: 3rem;
        }

        .text {
          font-size: 20px;
        }

        .clovyr-button {
          min-width: 11rem;
        }
      }
    }
    .tab-group {
      display: flex;

      .button {
        font-weight: 600;
      }

      :deep(.button--disabled) {
        color: color(negative, primary);
        background-color: #ada6b7;
        opacity: 1;
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;
      width: 100%;

      p {
        width: 85%;

        @include media-breakpoint-only(sm) {
          width: 400px;
        }
      }
    }

    h2 {
      margin-bottom: space(1);
    }

    p {
      margin: space(2) space(1);
      color: color(grey, secondary);
    }

    .tab-group {
      :deep(.button__label) {
        @include media-breakpoint-down(md) {
          font-size: 13px;
        }
      }
    }

    .copy-script,
    .copy-image {
      background: transparent;
    }

    .copy-image:hover,
    .copy-script:hover {
      :deep(svg path) {
        color: color(grey, primary);
        stroke: color(grey, primary);
      }
    }

    :deep(.language-html) {
      min-height: 18rem;
      text-wrap: wrap;

      @include media-breakpoint-up(sm) {
        min-height: 12rem;
      }
    }

    .code-block {
      margin: 0 !important;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      text-wrap: wrap;
    }

    :deep(.button.button--pill) {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      font-size: 8px;

      @include media-breakpoint-up(sm) {
        font-size: unset;
      }
    }
  }
}
</style>

import { AppServerAPI } from '../remote_exec/api';
import { waitFor } from '../util/sleep';

export interface Snapshot {
  time: string;
  parent: string;
  tree: string;
  paths: string[];
  hostname: string;
  username: string;
  id: string;
  short_id: string;
}

enum JobStatus {
  Created = 0,
  Running = 1,
  Complete = 2,
}

interface Job {
  id: string;
  created: string;
  status: JobStatus;
  success: boolean;
  error: string;
}

export class BackupAPI extends AppServerAPI {
  async listSnapshots(): Promise<Snapshot[]> {
    try {
      const res = await this.unwrapRes(this.client.get(this.url('/_leaf/api/backups/snapshots')));
      if (res.data.snapshots) {
        return res.data.snapshots as Snapshot[];
      }
    } catch (e) {
      //
    }
    return [];
  }

  async inspectSnapshot(snapshotID: string): Promise<string> {
    return this.exec(`
docker compose exec clovyr-restic-backup restic ls --long ${snapshotID} 2>&1 | grep -v warning
    `);
  }

  async getStats(): Promise<string> {
    return this.exec(`
docker compose exec clovyr-restic-backup restic stats 2>&1 | grep -v warning
    `);
  }

  /**
   * Wait for the given backup or restore job to complete
   *
   * @param jobID
   * @returns
   */
  async waitForJob(jobID: string): Promise<Job> {
    return waitFor<Job>(500, 120, 1000, async () => {
      const r = await this.unwrapRes(
        this.client.get(`/_leaf/api/backups/restore-wait?jobID=${jobID}&poll=1`)
      );
      const j = r.data.job as Job;
      return [j?.status === JobStatus.Complete, j];
    });
  }

  /**
   * Creates a new snapshot, waits for it to complete, and returns the new snapshot ID
   * @returns New snapshot ID
   */
  async createSnapshot(tags?: string[]): Promise<string> {
    try {
      const res = await this.unwrapRes(
        this.client.post(this.url('/_leaf/api/backups/backup'), { tags })
      );
      if (!res.data?.jobID) {
        throw new Error('Failed to start backup');
      }

      const job = await this.waitForJob(res.data.jobID);
      if (!job.success) {
        throw new Error(`Failed to create backup: ${job.error}`);
      }

      const snaps = await this.listSnapshots();
      if (!snaps?.length) {
        throw new Error('Failed to create backup');
      }
      const newSnap = snaps[snaps.length - 1];
      return newSnap.id;
    } catch (e) {
      throw new Error('Failed to start backup');
    }
  }

  /**
   * Restores the given snapshot and waits for it to complete
   *
   * @param snapshotID
   * @param wipe when true, wipes the volume before restoring
   * @returns
   */
  async restoreSnapshot(snapshotID: string, wipe: boolean): Promise<void> {
    try {
      const res = await this.unwrapRes(
        this.client.post(this.url('/_leaf/api/backups/restore'), {
          snapshotID,
          wipe,
        })
      );
      if (!res.data?.jobID) {
        throw new Error('Failed to start restore');
      }
      const job = await this.waitForJob(res.data.jobID);
      if (!job.success) {
        throw new Error(`Failed to restore backup: ${job.error}`);
      }
    } catch (e) {
      throw new Error('Failed to restore backup');
    }
  }
}

import { defineStore } from 'pinia';
import mitt, { type Emitter } from 'mitt';

import type { UserSubscription, UserSubscriptionItem } from '@clovyr/pollen';

import type { ShowAuthForm } from '@/views/Settings/types';

export type ShowPaymentModalEvent = {
  id: string;
  name: string;
  price?: number;
  sub: UserSubscription;
  subItem: UserSubscriptionItem;
};

type Events = {
  'drawer:close': void;
  'app:update': { appID: string };
  'modal:login:show': { form?: ShowAuthForm; opts?: unknown } | undefined;
  'modal:login:close': void;
  'modal:unload:show': void;
  'modal:payment:show': ShowPaymentModalEvent;
  'toast:show': {
    message?: string;
    type: 'success' | 'info' | 'error';
    duration?: number;
    data: object;
  };
};

/**
 * Simple store for using a shared global event bus for when we need to send events async across the
 * app without a simple parent/child component relationship.
 */
export const useEventBus = defineStore('event_bus', () => {
  const eventBus: Emitter<Events> = mitt();
  return {
    eventBus,
  };
});

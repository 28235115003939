<script setup lang="ts">
import Markdown from '@clovyr/bed/components/blocks/Markdown.vue';

import InfoIcon from '../icons/InfoIcon.vue';

const props = defineProps<{
  isToggleSwitch?: boolean;
  title?: string;
  description?: string;
  infoToggle?: boolean;
  value?: number | string | boolean | undefined | null;
  disabled?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const isChecked = computed(() => {
  return props.value === true || props.value === 'true' || props.value === 1;
});

const pointer = computed(() => (props.disabled ? 'auto' : 'pointer'));

function onChangeInput(e: Event) {
  const target = e.target as HTMLInputElement;
  const value = target.checked;
  emit('update:modelValue', value);
}
</script>

<template>
  <label class="checkbox-block">
    <div class="checkbox-block__left">
      <div class="block-title" :class="{ disabled }">{{ title }}</div>
      <Markdown v-if="description" :content="description" class="block-description" />
    </div>
    <div class="checkbox-block__right">
      <InfoIcon v-if="infoToggle" />
      <input
        v-if="!isToggleSwitch"
        @input="onChangeInput"
        :checked="isChecked"
        class="checkbox"
        type="checkbox"
        :disabled="disabled"
      />
      <div v-if="isToggleSwitch" class="switch-toggle" :class="{ disabled }">
        <label class="switch">
          <input
            @input="onChangeInput"
            :checked="isChecked"
            class="checkbox"
            type="checkbox"
            :disabled="disabled"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </label>
</template>

<style scoped lang="scss">
.checkbox-block {
  display: flex;
  padding: space(1.8) space(2.4);
  cursor: v-bind(pointer);
  border: 1px solid rgb(132 115 152 / 30%);

  @include borders-rounded;

  &:not(:last-child) {
    margin-bottom: space(2.4);
  }

  &__left {
    flex-basis: 80%;
  }

  &__right {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    .switch-toggle {
      transform: translateY(5px);
      .switch {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 10px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: v-bind(pointer);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid color(action, primary);
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: 'N';
        color: color(action, primary);
        height: 20px;
        width: 20px;
        left: -2px;
        bottom: -6px;
        border: 2px solid color(action, primary);
        -webkit-transition: 0.4s;
        transition: 0.4s;
        z-index: 2;
        background-color: color(background, primary);
        padding: 0 0 1px 3.5px;
        font-size: 12px;
      }

      &.disabled {
        .slider {
          border-color: color(grey, primary);
        }
        .slider:before {
          color: color(grey, primary);
          border-color: color(grey, primary);
        }
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
        content: 'Y';
        padding: 0 0 4px 4.5px;
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }

  .block-title {
    color: color(grey, tertiary);
    &.disabled {
      color: color(grey, primary);
    }
  }

  .block-description {
    color: color(grey, secondary);
  }
}
</style>

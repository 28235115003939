<!-- eslint-disable import/no-unresolved -->
<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';

import PasswordFormField from '@/components/blocks/PasswordFormField.vue';
import SettingsItem from '@/components/blocks/SettingsItem.vue';
import Modal from '@/components/elements/Modal.vue';
import FormWrapper from '@/components/forms/FormWrapper.vue';
import { usePrinter } from '@/composables/usePrinter';

defineProps({
  id: {
    type: Number,
    default: null,
  },
});

const { printRecoveryKit } = usePrinter();
const showRegenerateSecretKitDialog = ref(false);

const regenerateSecretKitDialog = () => {
  showRegenerateSecretKitDialog.value = true;
};

// TODO: remove zod dependency
const validationSchema = toTypedSchema(
  z.object({
    password: z.string().min(8, { message: 'Password must be at least 8 characters long.' }),
  }),
);

const onSubmit = () => {
  // submit form
};
</script>

<template>
  <SettingsItem
    @print-recovery-kit="printRecoveryKit"
    @regenerateSecretKey="regenerateSecretKitDialog"
    :buttons="[
      {
        label: 'Print Recovery Kit',
        eventName: 'printRecoveryKit',
        reversed: true,
      },
      // {
      //   label: 'Regenerate Secret Key',
      //   eventName: 'regenerateSecretKey',
      // },
    ]"
  >
    <template #content>
      Your Recovery Kit contains your Secret Key and information you’ll need to sign in from new
      devices or to restore your apps.
    </template>
  </SettingsItem>

  <Modal
    v-if="showRegenerateSecretKitDialog"
    theme="transparent"
    :size="'narrow'"
    :has-icon="true"
    @close="showRegenerateSecretKitDialog = false"
  >
    <template #body>
      <FormWrapper
        submitLabel="Regenerate secret key"
        :validation-schema="validationSchema"
        @submit="onSubmit"
      >
        <template #fields>
          <PasswordFormField
            name="password"
            label="Password"
            shape="rounded"
            type="password"
            reveal
          />
        </template>
      </FormWrapper>
    </template>
  </Modal>
</template>

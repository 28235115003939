<script setup lang="ts">
import { storeToRefs } from 'pinia';

import type { Button, Logo } from '@clovyr/bed';
import ClovyrHostLogo from '@clovyr/bed/assets/images/clovyrhost-lockup.svg';
import logoPlaceholderImage from '@clovyr/bed/assets/images/logo-placeholder.svg';
import Tooltip from '@clovyr/bed/components/blocks/Tooltip.vue';
import ImageMedia from '@clovyr/bed/components/media/ImageMedia.vue';
import type { ComputeProviderInfo, Deployment } from '@clovyr/pollen';
import { getHostByID } from '@clovyr/pollen/fixtures/hosts';
import { SubscriptionStatus } from '@clovyr/pollen/subscription';
import type { UserSubscriptionItem } from '@clovyr/pollen/types/UserSubscriptionItem';

import SettingOption from '@/components/blocks/SettingOption.vue';
import FieldInfoIcon from '@/components/icons/FieldInfoIcon.vue';

import SettingsSection from '../../components/blocks/SettingsSection.vue';
import { usePayments } from '../../composables/usePayments';
import { usePollenStore } from '../../stores/pollen_store';

import AppSubscription from './Subscription/AppSubscription.vue';

const payments = usePayments();
const { subscription, subscriptionItems, isStripe } = payments;
const pollenStore = usePollenStore();
const { sortedDeployments } = storeToRefs(pollenStore);
const { getDeploymentApp } = pollenStore;

interface SubscriptionInfo {
  logo?: Logo;
  name: string;
  subscriptionItem: UserSubscriptionItem;
}

interface AppSubscriptionInfo extends Deployment {
  logo?: Logo;
  host?: ComputeProviderInfo;
  subscriptionItem?: UserSubscriptionItem;
}

function exists<T>(value: T | null): value is T {
  return value === (value ?? !value);
}

const nonAppSubscriptions = ref<SubscriptionInfo[]>([]);

const subscriptions = computed<AppSubscriptionInfo[]>((): AppSubscriptionInfo[] => {
  const subs = sortedDeployments.value.map((deployment) => {
    if (!deployment.subscriptionItemID) {
      return null;
    }

    const d: AppSubscriptionInfo = { ...deployment, host: getHostByID(deployment.hostingProvider) };

    const app = getDeploymentApp(deployment)!;
    if (app) {
      if (app.metadata.logoUrl) {
        d.logo = {
          logoUrl: app.metadata.logoUrl,
          logoBackgroundColor: app.metadata.logoBackgroundColor,
        };
      } else {
        d.logo = {
          logoUrl: logoPlaceholderImage,
          logoBackgroundColor: '',
        };
      }
    }

    d.subscriptionItem = subscriptionItems.value[deployment.subscriptionItemID];

    return d;
  });
  return subs.filter((s) => exists(s)) as AppSubscriptionInfo[];
});

onMounted(async () => {
  const items = await payments.listNonAppSubscriptionItems();
  nonAppSubscriptions.value = items.map((item) => {
    return {
      logo: {
        logoUrl: '/logo.png',
        logoBackgroundColor: '',
      },
      name: 'Clovyr Day Pass', // TODO: get from item?
      subscriptionItem: item,
    };
  });

  return payments.refreshSubscription();
});

const subscriptionsSortedByDate = computed(() => {
  // Make a copy of array to avoid direct mutation
  const sortedSubscriptions = [...subscriptions.value];

  sortedSubscriptions.sort((a, b) => {
    const firstDate = a.subscriptionItem?.created_at?.valueOf() || a.createdAt;
    const secondDate = b.subscriptionItem?.created_at?.valueOf() || b.createdAt;
    return secondDate - firstDate;
  });

  return sortedSubscriptions;
});

const activeSubscriptions = computed(() => {
  const subs = subscriptionsSortedByDate;
  return subs.value.filter(
    (s) =>
      s.subscriptionItem?.status === SubscriptionStatus.Active ||
      s.subscriptionItem?.status === SubscriptionStatus.CancelRequested ||
      s.subscriptionItem?.status === SubscriptionStatus.UpgradeRequested,
  );
});

const activeTrials = computed(() => {
  const subs = subscriptionsSortedByDate;
  return subs.value.filter(
    (s) =>
      s.subscriptionItem?.status === SubscriptionStatus.Trial ||
      s.subscriptionItem?.status === SubscriptionStatus.PaymentInProgress,
  );
});

const hasActiveSubscriptions = computed(() => {
  if (subscriptions.value.length > 0 || nonAppSubscriptions.value.length > 0) {
    return true;
  }
  return false;
});

// TODO: Refactor below using the subscription.next_invoice_date field (chetan will need to expose it to frontend)
const invoiceDates = computed(() => {
  const dateString = subscription.value?.payment_captured_at;

  if (!dateString) {
    return { latestInvoiceDate: undefined, nextInvoiceDate: undefined };
  }

  const date = new Date(dateString);

  const latestDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() % 100}`;

  // add 30 days for next invoice date
  date.setDate(date.getDate() + 30);
  const nextInvoiceDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() % 100}`;

  return { latestInvoiceDate: latestDate, nextInvoiceDate };
});

const hasStripePaymentOnFile = computed(() => {
  if (!subscription.value) {
    return false;
  }
  // payment capture w/ stripe
  return (
    !!subscription.value.payment_captured_at &&
    subscription.value.subscription_provider === 'stripe'
  );
});

async function onClickOpenInvoices() {
  const url = await payments.getPortalURL();
  if (url) {
    window.open(url, '_blank');
  }
  // TODO: else - error?
}

const iconText = computed(() => {
  const icon = isStripe ? 'via Stripe' : 'via Opennode';
  const tooltip = isStripe
    ? 'Manage your ClovyrHost subscriptions, pay invoices and update payment methods with Stripe.'
    : 'Subscriptions paid via OpenNode must be paid manually each month. Clovyr will issue invoices at the beginning of each month, due by the end of each month. Any apps running on clovyrhost will be suspended after 2 months of unpaid invoices.';

  return { icon, tooltip };
});
</script>

<template>
  <SettingsSection heading-text="Subscriptions" :default-closed="true">
    <template #content>
      <SettingOption v-if="!hasActiveSubscriptions" highlighted>
        <template #left>You currently have no active subscriptions.</template>
      </SettingOption>
      <!-- NON-APP SERVICES -->
      <div v-if="hasActiveSubscriptions">
        <SettingOption highlighted v-if="nonAppSubscriptions.length">
          <template #left>
            <AppSubscription
              v-for="(sub, idx) in nonAppSubscriptions"
              :key="idx"
              :name="sub.name"
              :logo="sub.logo"
              :subscription="subscription"
              :subscriptionItem="sub.subscriptionItem"
            />
          </template>
        </SettingOption>

        <!-- APPS -->
        <!--Trials-->
        <SettingOption highlighted v-if="activeTrials.length">
          <template #left>
            <div class="header">
              <div class="header-left">
                <ImageMedia :filename="ClovyrHostLogo" />
                <h4>Trials</h4>
              </div>
            </div>
            <AppSubscription
              v-for="(sub, idx) in activeTrials"
              :key="idx"
              :id="sub.id"
              :name="sub.instanceName"
              :logo="sub.logo"
              :host="sub.host"
              :subscription="subscription"
              :subscriptionItem="sub.subscriptionItem"
            />
          </template>
        </SettingOption>
        <!--Subscriptions-->
        <SettingOption highlighted v-if="activeSubscriptions.length">
          <template #left>
            <div class="header">
              <div class="header-left grid__col-lg-6">
                <ImageMedia :filename="ClovyrHostLogo" />
                <h4>Subscriptions</h4>
              </div>
              <div class="header-right grid__col-lg-6">
                <div class="tooltip opennode-tooltip">
                  <p>{{ iconText.icon }}</p>
                  <Tooltip
                    class="icon-tooltip"
                    placement="bottom"
                    theme="narrow"
                    arrow
                    :content="iconText.tooltip"
                  >
                    <FieldInfoIcon />
                  </Tooltip>
                </div>
                <div v-if="hasStripePaymentOnFile && isStripe">
                  <Button label="go to stripe" @click="onClickOpenInvoices" />
                </div>
              </div>
            </div>
            <AppSubscription
              v-for="(sub, idx) in activeSubscriptions"
              :key="idx"
              :id="sub.id"
              :name="sub.instanceName"
              :logo="sub.logo"
              :host="sub.host"
              :subscription="subscription"
              :subscriptionItem="sub.subscriptionItem"
            />
            <div class="invoice-notif" v-if="false">
              Latest invoice paid {{ invoiceDates.latestInvoiceDate }}. Next invoice
              {{ invoiceDates.nextInvoiceDate }}.
            </div>
          </template>
        </SettingOption>
      </div>
    </template>
  </SettingsSection>
</template>

<style lang="scss" scoped>
.actions {
  padding: 10px;

  button {
    width: 55%;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  h4,
  p {
    color: color(grey, secondary);
  }

  :deep(.image-media) {
    img {
      width: 10rem;
    }
  }

  .header-left {
    display: flex;
    align-items: center;
  }

  .header-right {
    display: flex;
    align-items: center;
    margin: 0.5rem 0 0 0.5rem;

    @include media-breakpoint-up(lg) {
      margin: unset;
      display: flex;
      justify-content: flex-end;
    }

    .tooltip {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      p {
        font-size: 14px;
        margin-right: 0.1rem;
      }

      .icon-tooltip {
        :deep(.info-icon) {
          border-radius: 1rem;
        }

        > :deep(.popper) {
          max-width: 30rem;
        }
      }

      // TODO: adjust tooltip placement for smaller screensizes
      .opennode {
        > :deep(.popper) {
          max-width: 20rem;
        }
      }
    }
  }
}

.invoice-notif {
  color: color(grey, primary);
  text-align: right;
}

:deep(.setting-option--highlighted:first-child) {
  margin-top: unset;
}
:deep(.setting-option__buttons) {
  .button {
    padding: 0.5rem 1.5rem !important;
  }
}
</style>

import type { Manifest } from '../manifest';
import { dumpYaml } from '../util';
import type { Values } from '../value';

import { createKubeScript } from './chart';
import type { ConfigureBuilder } from './configure-builder';

function mkConfigureScript(manifest: Manifest, values: Values): string {
  const extra = `
    # Disable traefik & servicelb
    sed -i -e 's|^ExecStart=.*$|ExecStart=/usr/local/bin/k3s server --disable traefik --disable servicelb|' /etc/systemd/system/k3s.service
  `;
  let script = createKubeScript(extra, manifest.metadata.id, dumpYaml(values));
  script += ` `;
  return script;
}

export const chartRemote = {
  mkConfigureScript,
} as ConfigureBuilder;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { WNextBase } from '../api/wnext-base';
import type { DNSRecord } from '../types';

import type {
  CreateRecordRequest,
  DestroyRecordRequest,
  DestroyRecordResponse,
  DnsProvider,
  GetRecordRequest,
  ListRecordsRequest,
  UpdateRecordRequest,
} from '.';

export class WNextDNS extends WNextBase implements DnsProvider {
  async createRecord(req: CreateRecordRequest): Promise<DNSRecord> {
    return this.unwrap(await this.client.post('/dns', req));
  }

  async listRecords(req: ListRecordsRequest): Promise<DNSRecord[]> {
    throw new Error('method not implemented');
  }

  async getRecord(req: GetRecordRequest): Promise<DNSRecord> {
    return this.unwrap(await this.client.get(`/dns/${req.name}`));
  }

  async updateRecord(req: UpdateRecordRequest): Promise<DNSRecord> {
    return this.unwrap(await this.client.put(`/dns/${req.record.name}`, req));
  }

  async destroyRecord(req: DestroyRecordRequest): Promise<DestroyRecordResponse> {
    return this.unwrap(await this.client.delete(`/dns/${req.name}`, req));
  }
}

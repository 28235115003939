<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import { z } from 'zod';

import LoginForm from './AuthForms/LoginForm.vue';
import NostrEmailForm from './AuthForms/NostrEmailForm.vue';
import SignupForm from './AuthForms/SignupForm.vue';
import Unlock from './AuthForms/Unlock.vue';
import UnlockSecret from './AuthForms/UnlockSecret.vue';
import RecoveryKitDownload from './RecoveryKitDownload.vue';
import { ShowAuthForm } from './types';

const options = {
  showLogin: LoginForm,
  showSignup: SignupForm,
  showNostrEmailForm: NostrEmailForm,
  showRecoveryKitDownload: RecoveryKitDownload,
  showSecretKeyForm: UnlockSecret,
  showLockedForm: Unlock,

  // not currently used anywhere
  // showResetPassword: ResetPasswordForm,
  // showLockedAccount: LockedAccountForm,
  // showLoginNewDevice: LoginNewDevice,
};

const props = withDefaults(
  defineProps<{
    activeOption?: ShowAuthForm | undefined;
    // allows for passing additional props to the auth form
    opts?: unknown | undefined;
  }>(),
  {
    activeOption: ShowAuthForm.Login,
  },
);

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'updateTheme', theme?: string): void;
}>();

const showForm = ref(props.activeOption); // initialize
const formOpts = computed(() => props.opts || {});

watch(
  () => props.activeOption,
  (newVal) => {
    showForm.value = newVal || ShowAuthForm.Login;
  },
);

const handleThemeUpdate = (theme) => {
  emit('updateTheme', theme || 'narrow');
};

const handleFormChange = (form) => {
  showForm.value = form;
};

const handleClose = () => {
  emit('close');
};

const validationSchema = toTypedSchema(
  z.object({
    email: z.string().email({ message: 'Enter a valid email address.' }),
    password: z.string().min(8, { message: 'Password must be at least 8 characters long.' }),
  }),
);

provide('validationSchema', validationSchema);

const cssClasses = computed(() => {
  return [
    showForm.value === ShowAuthForm.Locked && 'show-locked',
    showForm.value === ShowAuthForm.SecretKeyForm && 'show-secret',
  ];
});
</script>

<template>
  <component
    @auth-form="handleFormChange"
    @close="handleClose"
    @update-theme="handleThemeUpdate"
    :is="options[showForm]"
    v-bind="formOpts"
    :class="cssClasses"
  />
</template>
<style scoped lang="scss">
.show-locked,
.show-secret {
  margin: auto;
}
</style>
